import makeStyles from '@mui/styles/makeStyles';
import { colors, fonts } from '../../../../theme';

const style = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: '0px',
    width: '100%',
    '@media (max-width: 1199px)': {
      marginRight: 0,
    },
  },
  fileLabel: {
    width: '70%',
    marginRight: '2%',
    cursor: 'not-allowed',
  },
  addressGrid: {
    '& .MuiInputLabel-root': {
      '@media (max-width: 599px)': {
        transform: 'translate(14px, -1px) scale(0.75)',
        background: 'white !important',
      },
    },
  },
  container: {
    display: 'flex',
    '& label': {
      backgroundColor: '#9cc',
      color: '#fff',
      boxShadow: 'none',
      padding: '8px 24px',
      letterSpacing: 2,
      '& span': {
        fontFamily: fonts.fontType.roboto,
      },
      '&:hover': {
        backgroundColor: '#019491',
        boxShadow: 'none',
      },
    },
  },
  switchText: {
    fontSize: '0.9vw !important',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px !important',
    },
  },
  switchTextRed: {
    fontSize: '0.9vw !important',
    textAlign: 'center',
    color: colors.red,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px !important',
    },
  },
  switchTextGreen: {
    fontSize: '0.9vw !important',
    textAlign: 'center',
    color: colors.green,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px !important',
    },
  },
  switchHeading: {
    fontSize: '1vw !important',
    fontWeight: fonts.fontWeight.bold,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px !important',
    },
  },
  switchUi: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    '& p': {
      margin: '0 8px !important',
      fontSize: '0.9vw',
    },
    '& span': {
      marginTop: '0 !important',
      fontSize: '0.9vw',
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked+.MuiSwitch-track': {
        background: colors.lightGreen,
      },
    },
    '& .MuiSwitch-thumb': {
      width: 10,
      height: 10,
    },
  },
  btnAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logsIconSize: {
    fontSize: '0.9vw',
    color: '#015EEA',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '0.8vw',
    width: '6vw',
    '@media (max-width: 600px)': {
      width: 'auto !important',
    },
    '& span': {
      marginLeft: '0.2vw',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  errorText: {
    color: colors.errorText,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw !important',
    },
    display: 'block',
    margin: '7px 0',
  },
}));

export default style;
