import { getCurrentYear } from '../../utils/methods';
import Constant from '../constant';

const initialState = {
  tshirts: [],
  courses: [],
  academicGrades: [],
  extraCurricularActivites: [],
  locations: [],
  students: [],
  studentsDouble: [],
  movedStudent: [],
  studentLogs: [],
  allAcademicYears: [],
  studentAccountDetails: {},
  siblingData: {},
  studentFilterViews: [],
  newAddedStudentFilterView: [],
  studentFilters: [],
  termsSettingDetails: {},
  allFilters: [],
  filterOptions: [],
  allFilterOptions: [],
  filterChanged: false,
  expandedLabels: [],
  userRole: '',
  selectedFilter: [
    {
      label: getCurrentYear().id,
      groupBy: 'Academic Year',
      filterKey: 'academic_year',
    },
    {
      label: 'true',
      groupBy: 'Status',
      filterKey: 'status',
    },
  ],
  enrollCourseList: [],
  nextEnrollCourseList: [],
  enrollmentDetails: {},
  totalCount: 0,
  studentCourseDetails: [],
  studentsData: [],
  dataExportSheets: [],
  allExpanded: false,
  view: 'cardView',
  loading: false,
};

const auth = (state = initialState, action = {}) => {
  switch (action.type) {
    case Constant.SET_TERMS_SETTINGS:
      return {
        ...state,
        termsSettingDetails: action.payload,
      };
    case Constant.GET_ACADEMIC_YEAR_STUDENT:
      return {
        ...state,
        currentYearStudent: action.payload,
      };

    case Constant.GET_TSHIRTS:
      return {
        ...state,
        tshirts: action.payload,
      };
    case Constant.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case Constant.SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };

    case Constant.GET_STUDENT_LOGS:
      return {
        ...state,
        studentLogs: action.payload,
      };
    case Constant.GET_ALL_YEARS:
      return {
        ...state,
        allAcademicYears: action.payload,
      };
    case Constant.SET_ALL_EXPAND:
      return {
        ...state,
        allExpanded: action.payload,
      };
    case Constant.SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      };
    case Constant.SET_EXPANDED_LABELS:
      return {
        ...state,
        expandedLabels: action.payload,
      };
    case Constant.GET_ALL_COURSES:
      return {
        ...state,
        courses: action.payload,
      };
    case Constant.GET_ACADEMIC_GRADES:
      return {
        ...state,
        academicGrades: action.payload,
      };
    case Constant.GET_REGISTRATION_TEMP_INFO:
      return {
        ...state,
        registrationTempInfo: action.payload,
      };
    case Constant.GET_EXTRA_CURRICULAR_ACTIVITIES:
      return {
        ...state,
        extraCurricularActivites: action.payload,
      };
    case Constant.GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case Constant.GET_ALL_STUDENTS:
      return {
        ...state,
        students: action.payload,
        studentsDouble: action.payload,
      };
    case Constant.TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case Constant.GET_PAGINATED_STUDENTS:
      return {
        ...state,
        students: state.students?.concat(action.payload),
        studentsDouble: state.students?.concat(action.payload),
      };
    case Constant.MOVE_STUDENT:
      return {
        ...state,
        movedStudent: action.payload,
      };
    case Constant.SET_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.payload,
      };
    case Constant.SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    case Constant.SET_FILTER_CHANGED:
      return {
        ...state,
        filterChanged: action.payload,
      };
    case Constant.SET_ALL_FILTER_OPTIONS:
      return {
        ...state,
        allFilterOptions: action.payload,
      };
    case Constant.GET_STUDENT_ACCOUNT_DETAILS:
      return {
        ...state,
        studentAccountDetails: action.payload,
      };
    case Constant.GET_STUDENT_COURSE_DETAILS:
      return {
        ...state,
        studentCourseDetails: action.payload,
      };
    case Constant.ADD_SIBLING_DATA:
      return {
        ...state,
        siblingData: action.payload,
      };
    case Constant.SET_FILTER_VIEWS:
      return {
        ...state,
        studentFilterViews: action.payload,
      };
    case Constant.NEW_ADDED_FILTER_VIEW:
      return {
        ...state,
        newAddedStudentFilterView: action.payload,
      };
    case Constant.SET_FILTERS:
      return {
        ...state,
        studentFilters: action.payload,
      };
    case Constant.SET_ALL_FILTERS:
      return {
        ...state,
        allFilters: action.payload,
      };
    case Constant.SET_NEXT_ENROLL_COURSE:
      return {
        ...state,
        enrollCourseList: action.payload,
      };
    case Constant.SET_NEXT_ENROLL_COURSE_LIST:
      return {
        ...state,
        nextEnrollCourseList: action.payload,
      };
    case Constant.SET_STUDENT_ENROLLMENT_DETAILS:
      return {
        ...state,
        enrollmentDetails: {
          ...state.enrollmentDetails,
          ...action.payload,
        },
      };
    case Constant.CLEAN_STUDENT_ENROLLMENT_DETAILS:
      return {
        ...state,
        enrollmentDetails: {},
      };
    case Constant.GET_EXPORTED_SHEETS:
      return {
        ...state,
        dataExportSheets: action.payload,
      };
    default:
      return state;
  }
};

export default auth;
