import { colors, fonts } from '../../../theme';

const style = () => ({
  errorText: {
    color: colors.errorText,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    display: 'block',
    marginTop: '0.3vw',
    marginLeft: '0.2vw',
  },
  button: {
    justifyContent: 'flex-end',
    margin: '10px 4px 3px 1px',
    width: '98%',
  },
  setGreen: {
    color: colors.lightGreen,
  },
  innerContainer: {
    margin: '0.3vw 0.6vw',
    fontFamily: fonts.fontType.roboto,
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      width: '97%',
      margin: '0 0 0 10px',
      '& .MuiFormControl-root': {
        margin: '10px 0 0 0',
      },
    },
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.692vw',
        borderRadius: '0.4vw',
        width: '100%',
      },
      '& .MuiSelect-select': {
        minHeight: '0vw !important',
      },
    },
    '& .MuiFormControl-root .MuiInputLabel-root': {
      lineHeight: '0.9vw !important',
      fontSize: '0.9vw',
      color: `${colors.primary} !important`,
    },
  },
  form: {
    marginTop: '0 !important',
  },
  changeGridLayout: {
    minWidth: '800px',
    '& .MuiFormControl-root': {
      width: '100%',
      '& .MuiInputLabel-root': {
        fontSize: '0.9vw',
        lineHeight: '1vw !important',
        transform: 'translate(0.7vw, 0.9vw)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(0.7vw, -0.5vw)',
          '@media (max-width: 1200px)': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          fontSize: '0.7vw',
          color: '#104F96',
        },
      },
    },
    '& .MuiGrid-root.MuiGrid-container': {
      maxWidth: 'inherit !important',
      paddingLeft: '1vw',
    },
    '& .MuiGrid-root.MuiGrid-grid-md-3, & .MuiGrid-root.MuiGrid-grid-xs-3': {
      maxWidth: '25% !important',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        height: '1.2vw',
        fontSize: '0.9vw',
        padding: '0.7vw',
        transform: 'translate(0vw, -0.1vw)',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& input.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
        backgroundColor: '#fff',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  switchSection: {
    textAlign: 'center',
    '& .MuiTypography-h6': {
      fontSize: '0.7vw',
    },
  },
  switchUi: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    '& p': {
      margin: '0 8px !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
    },
    '& span': {
      marginTop: '0 !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked+.MuiSwitch-track': {
        background: colors.lightGreen,
      },
    },
    '& .MuiSwitch-thumb': {
      width: 10,
      height: 10,
    },
  },
  marksDropDown: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      width: '100px !important',
      marginRight: '10px !important',
    },
  },
  addStyleHead: {
    '& p': {
      color: colors.primary,
    },
  },
  alignHorCenter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    '& p': {
      marginBottom: 0,
      fontSize: '0.9vw',
    },
  },
  secButtonNew: {
    marginTop: '10px',
  },
  marksTotal: {
    display: 'flex',
    '& span': {
      width: '11.5vw !important',
      fontSize: '16px',
      maxWidth: '13vw !important',
      minWidth: '13vw !important',
      marginRight: '1.8vw',
      paddingTop: '1vw',
      paddingLeft: '2vw',
      '@media (max-width: 1450px)': {
        minWidth: '13.2vw !important',
      },
      '@media (max-width: 1300px)': {
        maxWidth: '13.5vw !important',
        minWidth: '13.5vw !important',
      },
      '@media (max-width: 1250px)': {
        maxWidth: '15.5vw !important',
        minWidth: '14vw !important',
      },
    },
    '& .MuiInputBase-formControl': {
      width: '47% !important',
      '& input': {
        padding: '0.7vw',
        '@media (max-width: 1300px)': {
          padding: '0.4vw',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      '& legend': {
        maxWidth: '48px',
      },
    },
  },

  container: {
    maxWidth: 500,
    margin: '0 auto',
  },

  btnGrp: {
    display: 'flex',
    alignItems: 'flex-start',
  },
});

export default style;
