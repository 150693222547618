/* eslint-disable array-callback-return */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Box, Grid, TextField, Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';

function RenderCategoryField({
  classes,
  questions,
  setTotalQuestions,
  handleSave,
  courseFieldData,
  courseFieldErrors,
  courseFieldTouched,
  changeAcademicPanelMarks,
  showSaveBtn,
  schemaCanBeEdited,
  useGetCourseRows,
  styles,
  restrictKeys,
  refreshSchema,
  setError,
  setSuccessMsg,
  setErrorSchemaRemoved,
  setSaveClicked,
}) {
  const { t } = useTranslation();
  const { rows, setRow } = useGetCourseRows(questions);
  const [showRows, setShowRows] = useState(false);
  const [showCategoryError, setShowCategoryError] = useState(false);
  const [categoryRows, setCategoryRows] = useState([]);

  useEffect(() => {
    const categorySet = new Set();
    const categorySetRows = [];
    let hasDuplicate = false;
    courseFieldData.map((data) => {
      const category = data?.category || '';
      if (categorySet.has(category)) {
        if (category !== '') {
          hasDuplicate = true;
          categorySetRows.push(category);
        }
      } else {
        categorySet.add(category);
      }
    });
    if (hasDuplicate) {
      setShowCategoryError(true);
      setCategoryRows(categorySetRows);
    } else {
      setShowCategoryError(false);
    }
  }, [rows, courseFieldData]);

  useEffect(() => {
    if (!rows || !rows.length) {
      setShowRows(false);
    } else {
      setShowRows(true);
    }
  }, [rows]);

  const deleteRow = (id) => {
    setTotalQuestions(questions - 1, id);
    setRow((oldRows) => oldRows.filter((rowID) => rowID !== id));
  };

  const onCrossClick = (rowNo) => {
    if (!schemaCanBeEdited) {
      return;
    }

    deleteRow(rowNo);
  };

  const handleChange = (e, rowNo) => {
    const { value, name } = e.target;

    changeAcademicPanelMarks(rowNo, name, value);
  };

  return (
    <Box marginTop={2}>
      {showRows && (
        <>
          <Box style={styles.courseTableHeadingCont}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography style={styles.courseTableHeading}>
                  {t('CATEGORY')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={styles.courseTableHeading}>
                  {t('MARKS')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography style={styles.courseTableHeading}>
                  {t('NOTES')}
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
          <Box marginTop={2}>
            {rows.map((id) => (
              <Box key={id} marginTop={2}>
                <Grid container spacing={2}>
                  <Grid item xs={4} lg={3} md={3} className={classes.formControlGrid}>
                    <TextField
                      label={t('CATEGORY')}
                      id="category"
                      required
                      name="category"
                      type="number"
                      disabled={!schemaCanBeEdited}
                      value={courseFieldData[id]?.category || ''}
                      onChange={(e) => handleChange(e, id)}
                      labelId="category"
                      size="small"
                      onKeyPress={(e) => restrictKeys(e, true)}
                      inputProps={{ min: '0', style: styles.textFieldLabel }}
                      InputLabelProps={{ style: styles.textFieldLabel }}
                      error={courseFieldErrors && courseFieldErrors[id]?.category && courseFieldTouched && courseFieldTouched[id]?.category ? courseFieldErrors[id]?.category : false}
                      helperText={courseFieldErrors && courseFieldErrors[id]?.category && courseFieldTouched && courseFieldTouched[id]?.category ? courseFieldErrors[id]?.category : ''}
                    />
                  </Grid>
                  <Grid item xs={3} lg={3} md={3} className={classes.formControlGrid}>
                    <TextField
                      label={t('MARKS')}
                      id="marks"
                      required
                      name="marks"
                      type="number"
                      disabled={!schemaCanBeEdited}
                      value={courseFieldData[id]?.marks || ''}
                      onChange={(e) => handleChange(e, id)}
                      labelId="marks"
                      size="small"
                      onKeyPress={(e) => restrictKeys(e, true)}
                      inputProps={{ min: '0', style: styles.textFieldLabel }}
                      InputLabelProps={{ style: styles.textFieldLabel }}
                      error={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : false}
                      helperText={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : ''}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3} md={3} className={classes.formControlGrid}>
                    <TextField
                      label={t('NOTES')}
                      id="notes"
                      required
                      name="notes"
                      type="text"
                      disabled={!schemaCanBeEdited}
                      value={courseFieldData[id]?.notes || ''}
                      onChange={(e) => handleChange(e, id)}
                      labelId="notes"
                      size="small"
                      inputProps={{ style: styles.textFieldLabel }}
                      InputLabelProps={{ style: styles.textFieldLabel }}
                      error={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : false}
                      helperText={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : ''}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3} md={3} className={classes.formCrossRow}>
                    <Box>
                      <ClearIcon
                        style={!schemaCanBeEdited ? { color: '#bdbbbb' } : {}}
                        onClick={() => onCrossClick(id)}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {showCategoryError && (
                  categoryRows.map((cat, index) => {
                    if (cat === courseFieldData[id]?.category) {
                      const hasDisplayedError = categoryRows.slice(0, index).some((c) => c === cat);
                      if (hasDisplayedError) {
                        return null;
                      }
                      return (
                        <Box style={styles.errorCont}>
                          <Typography style={styles.errorText} variant="text">
                            {t('CATEGORY_NUMBER_ERROR')}
                          </Typography>
                        </Box>
                      );
                    }
                    return null;
                  })
                )}
              </Box>
            ))}
          </Box>
        </>
      )}
      {showSaveBtn && !showCategoryError
        && (
          <Box marginTop={2} alignItems="flex-end" style={styles.saveBtnCont}>
            <ButtonAtom
              btntype={Buttons.SECONDARY}
              onClick={() => {
                setError('');
                setSuccessMsg('');
                setErrorSchemaRemoved('');
                refreshSchema();
              }}
              name={t('CANCEL')}
              type="submit"
            />

            <ButtonAtom
              btntype={Buttons.PRIMARY}
              onClick={() => {
                setSaveClicked(true);
                handleSave();
              }}
              name={t('SAVE')}
              type="submit"
            />
          </Box>
        )}
    </Box>
  );
}

export default RenderCategoryField;
