/* eslint-disable radix */
/* eslint-disable */
import React, { useEffect } from 'react';
import { Grid, Box, Autocomplete } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { FormikProvider, useFormik } from 'formik';
import { saveAcademicAssignment } from '../../../../store/actions/academic-panel';
import DatePicker from '../../../../components/atoms/datepicker';
import { getCurrentYear } from '../../../../utils/methods';
import AttachFile from '@mui/icons-material/AttachFile';
import RenderAttachmentPicker from './attachmentPicker';

const textFieldInlineStyles = {
  '@media (min-width: 1200px)': {
    fontSize: '0.9vw',
  },
};

const restrictKeys = (ev, allowDecimal = false) => {
  const restrictedSymbols = ['+', '-', 'e', '*'];
  if (!allowDecimal) {
    restrictedSymbols.push('.');
  }
  if (restrictedSymbols.includes(ev.key)) {
    ev.preventDefault();
  }
};

function ViewAssignment({
  classes,
  setAddAssignmentOpen,
  setLoading,
  selectedCourseName,
  selectedCourse,
  refreshList,
  handlePopUp,
  selectedAssignmentView,
  resourceData,
  setViewAssignmentOpen,
}) {
  const { t } = useTranslation();

  const onSubmit = async (values, { resetForm }) => {
    setAddAssignmentOpen(false);
    setLoading(true);
    if (values.attachResources === null) {
      values.attachResources = [];
    }
    let scheduled = '';
    if (!values.scheduledDate) {
      scheduled = moment().utc();
    } else {
      scheduled = moment(values.scheduledDate).utc();
    }
    const nowDate = moment().utc();
    const dueDate = moment(scheduled).add(1, 'months');
    let dateScheduledTime;
    if (scheduled <= nowDate) {
      dateScheduledTime = moment(nowDate).add(1, 'hours');
    } else {
      dateScheduledTime = scheduled;
    }
    const aResourcesName = values.attachResources.map((index) => index.name);
    if (values.selectEvent === '') {
      values.selectEvent = null;
    }
    const payload = {
      academicYear: getCurrentYear()?.id,
      topicName: values.selectTopic,
      assignmentId: values.assignmentId,
      attachmentResourceName: aResourcesName.join(','),
      event: values.selectEvent,
      courseId: selectedCourse,
      dueDate: dueDate.toISOString(),
      requestBody: {
        title: values.title,
        description: values.description,
        materials: values.attachResources.map((item) => ({
          link: {
            url: item.url,
            title: item.name,
          },
        })),
        state: 'DRAFT',
        dueDate: {
          year: parseInt(dueDate.format('YYYY')),
          month: parseInt(dueDate.format('M')),
          day: parseInt(dueDate.format('D')),
        },
        dueTime: {
          hours: moment(dueDate).hours(),
          minutes: moment(dueDate).minutes(),
          seconds: moment(dueDate).seconds(),
        },
        scheduledTime: dateScheduledTime.toISOString(),
        maxPoints: values.defaultScore === '' || values.defaultScore === null ? 0 : values.defaultScore,
        workType: 'ASSIGNMENT',
      },
    };
    const loadFalse = () => {
      handlePopUp(false);
      refreshList();
      resetForm();
    };
    await saveAcademicAssignment(payload, selectedCourse, setLoading, loadFalse);
  };

  const assignmentSchema = Yup.object().shape({
    assignmentId: Yup.string().required('Assignment ID Required'),
    title: Yup.string().required('Title Required'),
    description: Yup.string().required('Description Required'),
    selectTopic: Yup.string().required('Topic Required'),
  });

  const formik = useFormik({
    initialValues: {
      assignmentId: '',
      title: '',
      selectEvent: '',
      selectEventName: '',
      description: '',
      selectTopic: '',
      defaultScore: '',
      scheduledDate: '',
      attachResources: [],
      attachResourcesVal: '',
    },
    onSubmit,
    validationSchema: assignmentSchema,
  });

  const prefillViewPopUpData = (data) => {
    formik.setFieldValue('assignmentId', data?.assignmentId || '', false);
    formik.setFieldValue('title', data?.title || '', false);
    if (data?.event) {
      formik.setFieldValue('selectEvent', data.event, false);
    }
    formik.setFieldValue('description', data?.description || '', false);
    formik.setFieldValue('selectTopic', data?.topicName || '', false);
    formik.setFieldValue('defaultScore', data?.defaultScore || '', false);
    formik.setFieldValue('scheduledDate', data?.scheduledTime ? new Date(data.scheduledTime) : '', false);
    formik.setFieldValue('attachResourcesVal', data?.attachmentResourceName || '', false);

    const attachedResource = resourceData.find((item) => item.resourceName === data?.attachmentResourceName);

    formik.setFieldValue('attachResources', attachedResource ? ([{
      name: attachedResource.resourceName,
      url: attachedResource.fileUrl,
    }]) : null, false);

    setViewAssignmentOpen(true);
  };

  useEffect(() => {
    if (selectedAssignmentView) {
      prefillViewPopUpData(selectedAssignmentView);
    } else {
      formik.resetForm();
    }
  }, [selectedAssignmentView]);
  return (
    <Grid className={classes.addDialog}>
      <FormikProvider validationOnChange={false} validateOnBlur={false} value={formik}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className={classes.addPopup}
          validationOnChange={false}
          validateOnBlur={false}
        >
          <Grid container direction="row" sx={{ display: 'flex' }}>
            <Grid container spacing={4} xs={15} sm={8} md={6} className={classes.containerElements} direction="column">
              <Grid container className={classes.formControlGrid}>
                <TextField
                  className={classes.formControlGrid}
                  disabled
                  label={t('LEVEL')}
                  placeholder={t('LEVEL')}
                  id="outlined-required"
                  value={selectedCourseName}
                  size="small"
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  name="title"
                />
              </Grid>
              <Grid container className={classes.formControlGrid}>
                <TextField
                  className={classes.formControlGrid}
                  required
                  disabled
                  id="outlined-required"
                  label={t('TITLE')}
                  value={formik.values.title}
                  size="small"
                  inputProps={{ style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  name="title"
                />
                {formik.errors.title && formik.touched.title && (
                  <span className={classes.errorText}>{formik.errors.title}</span>
                )}
              </Grid>
              <Grid container className={classes.editorClassContainer}>
                <Grid item className={classes.formControlGridLeft}>
                  <span className={classes.textFields}>{t('DESCRIPTION')}</span>
                </Grid>
                <TextField
                  required
                  id="standard-multiline-static-required"
                  multiline
                  disabled
                  variant={"standard"}
                  rows={10}
                  size="small"
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  name="description"
                  value={formik.values.description}
                  validationOnChange={false}
                  validateOnBlur={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.description && formik.touched.description && (
                  <span className={classes.errorText}>{formik.errors.description}</span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4} xs={15} sm={8} md={6} className={classes.containerElements} direction="column">
              <Grid container className={classes.formControlGrid}>
                <TextField
                  className={classes.formControlGrid}
                  required
                  disabled
                  id="outlined-required"
                  label={t('ASSIGNMENT_ID')}
                  value={formik.values.assignmentId}
                  name="assignmentId"
                  size="small"
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                />
                {formik.errors.assignmentId && formik.touched.assignmentId && (
                  <span className={classes.errorText}>{formik.errors.assignmentId}</span>
                )}
              </Grid>
              <Grid container className={classes.formControlGrid}>
                <TextField
                  id="selectEvent"
                  name="selectEvent"
                  disabled
                  label={t('SELECT_EVENT')}
                  size="small"
                  inputProps={{ style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  value={formik.values.selectEvent}
                />
                {formik.errors.selectEvent && formik.touched.selectEvent && (
                  <span className={classes.errorText}>{formik.errors.selectEvent}</span>
                )}
              </Grid>
              <Grid container className={classes.formControlGrid} style={{ marginTop: '5px' }}>
                <Autocomplete
                  className={classes.formControlGrid}
                  freeSolo
                  id="selectTopic"
                  name="selectTopic"
                  disabled
                  value={formik.values.selectTopic}
                  inputProps={{ style: textFieldInlineStyles }}
                  renderInput={(params) => (
                    <TextField
                      required
                      disabled
                      {...params}
                      label={t('SELECT_TOPIC')}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        placeholder: t('CREATE_NEW_TOPIC'),
                      }}
                    />
                  )}
                />
                {formik.errors.selectTopic && formik.touched.selectTopic && (
                  <span className={classes.errorText}>{formik.errors.selectTopic}</span>
                )}
              </Grid>
              <Grid container className={classes.formControlGrid} style={{ marginTop: '5px' }}>
                <TextField
                  className={classes.formControlGrid}
                  disabled
                  type="number"
                  id="outlined-required"
                  label={t('DEFAULT_SCORE')}
                  name="defaultScore"
                  defaultValue={formik.values.defaultScore}
                  value={formik.values.defaultScore}
                  onKeyPress={(e) => restrictKeys(e, true)}
                  inputProps={{ style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  size="small"
                />
                {formik.errors.defaultScore && formik.touched.defaultScore && (
                  <span className={classes.errorText}>{formik.errors.defaultScore}</span>
                )}
              </Grid>
              <Grid container className={classes.formControlGrid}>
                <DatePicker
                  id="outlined-required"
                  disabled="true"
                  label={t('SCHEDULED_DATE')}
                  name="scheduledDate"
                  onChange={() => {
                    formik.setFieldValue('scheduledDate', formik.values.scheduledDate);
                  }}
                  textFieldProps={{
                    sx: {
                      svg: { fill: '#104F96', paddingTop: '0.3vw' },
                    },
                    size: 'small',
                  }}
                  customClass={classes.formControlGrid}
                  onBlur={formik.handleBlur}
                  value={formik.values.scheduledDate}
                />
                {formik.errors.scheduledDate && formik.touched.scheduledDate && (
                  <span className={classes.errorText}>{formik.errors.scheduledDate}</span>
                )}
              </Grid>
              <Grid container className={classes.formControlGrid}>
                <Grid item className={classes.formControlGridLeft}>
                  <span className={classes.textFields}>{t('ATTACH_RESOURCES')}</span>
                </Grid>
                <Grid item className={classes.formControlGridRight}>
                  <RenderAttachmentPicker
                    className={classes.formControlGrid}
                    id="attachResources"
                    name="attachResources"
                    viewOnly={true}
                    options={
                      resourceData.map((resource) => ({
                        id: resource.id,
                        name: resource.resourceName,
                      }))
                    }
                    value={formik.values.attachResourcesVal}
                    IconComponent={AttachFile}
                    selectedCourseName={selectedCourseName}
                    selectedCourse={selectedCourse}
                    studentResources={resourceData}
                  />
                  {formik.errors.attachResourcesVal && formik.touched.attachResourcesVal && (
                    <span className={classes.errorText}>{formik.errors.attachResourcesVal}</span>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </FormikProvider>
    </Grid>
  );
}

export default ViewAssignment;
