import React, { useEffect, useState } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from '../../style';
import useStyles from '../../../../../custom-hooks/useStyles';
import useAdmin from '../../../../../custom-hooks/useAdmin';
import { filteredData, getEnrollQuery } from '../helper';
import userRoles from '../../../../../constant/userRoles';

function EnrollBarData({
  locationIds, selectedFilter,
}) {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const adminData = useAdmin();

  const isRegionalCoordinator = useSelector(
    (reduxState) => reduxState.getStudent.userRole,
  ) === userRoles.REGION_COORDINATOR;

  const selectedOption = adminData?.graphOption;
  let query = '';
  if (isRegionalCoordinator && locationIds?.length > 0) {
    query = getEnrollQuery(selectedOption, selectedFilter, locationIds, true);
  }
  if (isRegionalCoordinator && locationIds?.length === 0) {
    query = getEnrollQuery(selectedOption, selectedFilter, [], true);
  }
  if (!isRegionalCoordinator) {
    query = getEnrollQuery(selectedOption, selectedFilter, [], false);
  }

  const [result, setResult] = useState([]);
  const {
    resultSet, isLoading, error, progress,
  } = useCubeQuery(query);

  let regWeek = filteredData('reg_week', selectedFilter);
  regWeek = regWeek.map((c) => c?.label);
  useEffect(() => {
    const responses = resultSet?.loadResponses.map((item) => item.data.length > 0);
    if ((regWeek.length > 0) && (responses?.includes(true))) {
      let resultSetData = resultSet.decompose().reduce((arr, currentResultSet) => {
        return [...arr, ...currentResultSet.rawData()];
      }, []);
      if (selectedOption !== 'acrossyear') {
        const count = resultSetData.reduce((sum, obj) => {
          // eslint-disable-next-line no-param-reassign
          sum += parseInt(obj['EnrolledCourse.count'], 10);
          return sum;
        }, 0);
        resultSetData = [{ 'EnrolledCourse.count': count }];
      } else {
        const chartData = resultSetData.reduce((data, obj) => {
          if (data[obj['EnrolledCourse.academicYear']]) {
            // eslint-disable-next-line no-param-reassign
            data[obj['EnrolledCourse.academicYear']]['EnrolledCourse.count'] += parseInt(obj['EnrolledCourse.count'], 10);
          } else {
            obj['EnrolledCourse.count'] = parseInt(obj['EnrolledCourse.count'], 10);
            data[obj['EnrolledCourse.academicYear']] = obj;
          }

          return data;
        }, {});
        resultSetData = Object.values(chartData);
      }
      setResult(resultSetData);
    } else if (resultSet?.loadResponses[0]?.data.length === 0) {
      const resultSetData = [{ 'EnrolledCourse.count': 0 }];
      setResult(resultSetData);
    }
  }, [regWeek.length, resultSet]);

  useEffect(() => {
    if (regWeek.length > 0) {
      return;
    }
    const resultSetData = resultSet?.loadResponses[0]?.data?.sort((a, b) => a['EnrolledCourse.academicYear'].localeCompare(b['EnrolledCourse.academicYear']));
    setResult(resultSetData);
  }, [resultSet]);

  if (isLoading) {
    return (
      <div>
        {(progress && progress.stage && progress.stage.stage) || 'loading'}
      </div>
    );
  }

  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return null;
  }
  const selectedIndex = selectedFilter?.findIndex((i) => i.filterKey === 'academic_year');
  const displayAcrossYearBar = () => (
    <Box className={`${classes.boxDiv} ${result?.length === 1 ? classes.centerAlign : ''}`}>
      <Typography variant="Body1" gutterBottom className={classes.headingColor}>
        Total Enrollment:
        {' '}
      </Typography>
      <Box className={classes.enrollmentBar}>
        {result?.map((e, index) => (
          <Box
            key={index}
            className={
              selectedFilter[selectedIndex]?.label === e['EnrolledCourse.academicYear']
                ? classes.activeAcademicYear
                : classes.enrolled
            }
          >
            <Typography
              variant="body1"
              gutterBottom
              className={classes.academicYear}
            >
              {e['EnrolledCourse.academicYear']}
              {' '}
              :
              {' '}
            </Typography>
            <Typography variant="button" display="block" gutterBottom>
              {e['EnrolledCourse.count']}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
  const displayYearMonthEnrollBar = (tran) => (
    <Box className={[classes.boxDiv, classes.centerAlign]}>
      {result?.map((e, index) => (
        <Box className={classes.enrolled} key={index}>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.academicYear}
          >
            {tran('TOTAL_ENROLLMENTS')}
            :
            {' '}
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            {e['EnrolledCourse.count']}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const setEnrollBar = () => {
    if (selectedOption === 'none') {
      return <div />;
    }
    if (selectedOption === 'acrossyear') {
      return displayAcrossYearBar(t);
    }
    return displayYearMonthEnrollBar(t);
  };
  return <div>{setEnrollBar()}</div>;
}

export default EnrollBarData;
