/* eslint-disable no-shadow */
import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DialogAtom } from '../../../../components/atoms';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import { useStyles } from './style';
import CheckboxAtom from '../../../../components/atoms/checkbox';
import { exportCourseColumns } from '../constant';

export default function CourseExportDialogue(props) {
  const { show, setShow, data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();
  const now = moment().format('MM-DD-yyyy');
  const currentTime = moment().format('hh:mm A');
  const name = 'Course';

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const selectedCheck = [];
      exportCourseColumns.forEach((ci) => selectedCheck.push(ci.id));
      setSelectedColumn(selectedCheck);
    } else {
      setSelectedColumn([]);
    }
    setIsCheckAll(e.target.checked);
  };

  const handleCheck = (event, item) => {
    const { checked } = event.target;
    if (event.target.checked) {
      if (item.id === 'fee') {
        setSelectedColumn([...selectedColumn, item.id]);
      } else {
        setSelectedColumn([...selectedColumn, item.id]);
      }
    }
    if (!checked) {
      setSelectedColumn(selectedColumn.filter((value) => value !== item.id));
    }
  };

  const exportCourseList = (e) => {
    e.preventDefault();
    const headers = [];
    const preheaders = [];
    selectedColumn.forEach((head) => {
      const selColumn = exportCourseColumns.find((col) => col?.id === head);
      preheaders.push(selColumn.label);
    });
    preheaders.reverse().forEach((headersData) => {
      if (preheaders.includes(headersData)) {
        headers.unshift(headersData);
      }
    });

    const bodyData = [];
    data?.forEach((elem) => {
      const temp = [];

      if (selectedColumn.includes('name')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'name')] = elem.name;
      }
      if (selectedColumn.includes('baseCourse')) {
        if (elem.baseCourse) {
          temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'baseCourse')] = 'Yes';
        } else {
          temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'baseCourse')] = 'No';
        }
      }
      if (selectedColumn.includes('status')) {
        if (elem.isActive) {
          temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'status')] = 'Active';
        } else {
          temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'status')] = 'Inactive';
        }
      }
      if (selectedColumn.includes('fee')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'fee')] = `$${elem?.fee?.fee}`;
      }
      if (selectedColumn.includes('examFee')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'examFee')] = `$${elem?.fee.examFee}`;
      }
      if (selectedColumn.includes('registrationFee')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'registrationFee')] = `$${elem?.fee.registrationFee}`;
      }
      if (selectedColumn.includes('repeatingDiscount')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'repeatingDiscount')] = `$${elem?.fee.repeatingDiscount}`;
      }
      if (selectedColumn.includes('siblingDiscount')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'siblingDiscount')] = `$${elem?.fee.repeatingDiscount}`;
      }
      if (selectedColumn.includes('nextPossibleCourse')) {
        let nextCourses = '';

        elem.fromCourse.forEach((item, i) => {
          const courseName = data.find((dataItem) => dataItem.id === item.toCourseId)?.name;

          nextCourses += `${courseName}${i === elem.fromCourse.length - 1 ? '' : ' '}`;
        });

        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'nextPossibleCourse')] = nextCourses;
      }
      bodyData.push(temp);
    });
    setDataForDownload([headers, ...bodyData]);
    setDownloadReady(true);
    setSelectedColumn([]);
    setIsCheckAll(false);
    setShow();
  };

  const onCancel = () => {
    setIsCheckAll(false);
    setSelectedColumn([]);
    setShow();
  };

  return (
    <DialogAtom
      isOpen={show}
      dialogHeading={t('EXPORT_CSV')}
      customClass={classes.CourseExportDialogueAtom}
      secHandle={onCancel}
      content={(
        <Grid className={classes.regionBox}>
          <Box
            component="form"
            m={3}
            mt={1}
            mb={0}
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container className={classes.selectAll}>
              <Grid item>
                <CheckboxAtom
                  label={t('SELECT_ALL')}
                  id="acceptCheckbox"
                  checked={isCheckAll}
                  handleChange={handleSelectAll}
                  customClasses="customCheckBox"
                />
              </Grid>
              <div>&nbsp;</div>
            </Grid>
            <Grid className={classes.checkbox}>
              <Grid container className={classes.checkboxContent}>
                {exportCourseColumns?.map((obj) => (
                  <Grid item md={2.4} xs={12} sm={6}>
                    <CheckboxAtom
                      customClasses="customCheckBox"
                      checked={selectedColumn.includes(obj.id)}
                      value={obj.id}
                      label={obj.label}
                      handleChange={(e) => handleCheck(e, obj)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
            <ButtonAtom
              className={classes.popupBtn}
              name={t('CANCEL')}
              onClick={onCancel}
              btntype={Buttons.SECONDARY}
            />
            <ButtonAtom
              className={classes.popupBtn}
              name={t('EXPORT_TO_NEW_SHEET')}
              btntype={Buttons.PRIMARY}
              onClick={(e) => { exportCourseList(e); }}
            />
            <CSVLink
              data={dataForDownload}
              target="_self"
              filename={`${name} - ${now} - ${currentTime}`}
              className="hidden"
              ref={csvLink}
              download={`${name} - ${now} - ${currentTime}.csv`}
            />
          </Grid>
        </Grid>
      )}
    />
  );
}
