import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../../../theme';

const useStyles = makeStyles((theme) => ({
  disabled: {
    opacity: '0.5',
  },
  dataGrid: {
    '.MuiDataGrid-root': {
      outline: 'none !important',
      border: 'none !important',
    },
    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
      width: '0 !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid #025EE6 !important',
      borderTop: 'none',
      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
      fontStyle: 'bold',
      fontSize: '1vw',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
      lineHeight: '131.19%',
      color: '#025EE6',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      '@media (min-width: 1200px)': {
        marginTop: '3vw !important',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '1vw',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell': {
    },
    '& .parentHeader, .parentNameCell': {
      width: '13% !important',
      minWidth: '13% !important',
      maxWidth: '13% !important',
      fontSize: '1vw',

      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
        minHeight: '3vw !important',
        maxHeight: '3vw !important',
      },
    },
    '& .picturesHeader, .picturesCell': {
      width: '7% !important',
      minWidth: '7% !important',
      maxWidth: '7% !important',
      fontSize: '1vw',
      '@media (min-width: 1200px)': {
        minHeight: '3vw !important',
        maxHeight: '3vw !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '60px !important',
        minWidth: '60px !important',
        maxWidth: '60px !important',
      },
    },
    '& .MuiDataGrid-cellCheckbox, .MuiDataGrid-columnHeaderCheckbox': {
      '@media (min-width: 1200px)': {
        minHeight: '3vw !important',
        maxHeight: '3vw !important',
        minWidth: '3vw !important',
        maxWidth: '3vw !important',
      },
    },
    '& .studentNameHeader, .studentNameCell': {
      width: '14% !important',
      minWidth: '14% !important',
      maxWidth: '14% !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .centerHeader, .centerCell': {
      width: '20% !important',
      minWidth: '20% !important',
      maxWidth: '20% !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .classHeader, .classCell': {
      width: '9% !important',
      minWidth: '9% !important',
      maxWidth: '9% !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .sectionHeader, .sectionCell': {
      width: '8% !important',
      minWidth: '8% !important',
      maxWidth: '8% !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '60px !important',
        minWidth: '60px !important',
        maxWidth: '60px !important',
      },
    },
    '& .dateHeader, .dateCell': {
      width: '9% !important',
      minWidth: '9% !important',
      maxWidth: '9% !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .actionHeader, .actionCell': {
      width: '12% !important',
      minWidth: '12% !important',
      maxWidth: '12% !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
      outlineOffset: 0,
    },
    '& .actionCell': {
      paddingLeft: '2vw',
    },
    '& .MuiTablePagination-root': {
      '& .MuiTablePagination-selectLabel, .MuiInputBase-root': {
        display: 'none',
      },
      '& svg': {
        [theme.breakpoints.down('md')]: {
          width: '20px !important',
          height: '20px !important',
        },
      },
      '& .MuiTablePagination-displayedRows': {
        fontSize: '1vw !important',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px !important',
        },
      },
    },
  },
  withoutScroll: {
    fontSize: 0,
    '& .MuiDataGrid-virtualScroller': {
      overflowY: 'hidden !important',
      overflowX: 'auto !important',
      '& .MuiDataGrid-virtualScrollerContent': {
        height: 'auto !important',
        '& .MuiDataGrid-virtualScrollerRenderZone': {
          transform: 'inherit !important',
          position: 'static',
        },
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
    },
  },
  userDetails: {
    textAlign: 'center',
    paddingTop: '0.2vw',
    '& .MuiDataGrid-row': {
      '@media (min-width: 1200px)': {
        minHeight: '5vw !important',
        maxHeight: '5vw !important',
        width: '100%',
      },
      alignItems: 'center',
    },
    '& svg': {
      width: '1vw !important',
      height: '1vw !important',
      [theme.breakpoints.down('md')]: {
        height: '15px !important',
        width: '15px !important',
      },
    },
    '& .MuiDataGrid-cell': {
      border: 'none',
    },
    '& .MuiDataGrid-root': {
      minHeight: '5vw !important',
      border: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      border: 'none',
    },
    '& .fa-sort-down': {
      marginBottom: '0.5vw',
    },
    '& .fa-sort-up': {
      marginTop: '0.5vw',
    },
    '& .MuiDataGrid-columnHeader[data-role="studentName"]': {
      width: '5vw',
    },
  },
  tableFooter: {
    fontSize: '0.9vw',
    textAlign: 'right',
    marginRight: '9px',
  },
  footerButton: {
    marginBottom: '42px',
    padding: '0 0 25px',
    float: 'right',
  },
  check: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  picture: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  studentName: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
  },
  studentNameLabel: {
    color: '#015EEA !important',
    '& .MuiTableSortLabel-icon': {
      width: '40px !important',
    },
  },
  parentName: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      padding: '16px 0',
    },
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
  },
  centerInfo: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
  },
  classLevel: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
  },
  section: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
  },
  regDate: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
  },
  actions: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
  },
  tableCellCheck: {
    borderBottom: 'none',
  },
  tableCellPictures: {
    borderBottom: 'none',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    textAlign: 'center',
  },
  tableCellStudent: {
    borderBottom: 'none',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  tableCellParent: {
    borderBottom: 'none',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  tableCellCenter: {
    borderBottom: 'none',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  tableCellClass: {
    borderBottom: 'none',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  tableCellSection: {
    borderBottom: 'none',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  tableCellDate: {
    borderBottom: 'none',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  tableCellAction: {
    borderBottom: 'none',
    fontSize: '1vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  totalRowCount: {
    borderBottom: 'none',
    marginBottom: '1vw',
    textAlign: 'right',
    fontSize: '0.9vw',
    color: colors.black,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    '& span': {
      marginRight: '1.3vw',
    },
  },
  loadMore: {
    textAlign: 'right',
    '& .MuiButton-root': {
      marginRight: '1vw',
      minWidth: '8vw',
    },
  },
}));
export default useStyles;
