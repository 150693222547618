/* eslint-disable import/prefer-default-export */
import Constant from '../constant';

import {
  getHomeworkSchemaService, saveHomeworkSchemaService,
} from '../services/homework-schema';

const getHomeworkSchema = (requestData) => (dispatch) => {
  getHomeworkSchemaService(requestData)
    .then((response) => {
      dispatch({
        type: Constant.GET_HOMEWORK_SCHEMA,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

const saveHomeworkSchema = async (payload, update, loadFalse, setError, setSuccessMsg, validSemesterYear, setErrorSchemaRemoved, setUpdateSchema) => {
  const errMsg = 'Failed to update Homework Schema - Marks are already assigned';

  try {
    const { success, data } = await saveHomeworkSchemaService(payload, update).catch((e) => {
      const errMatch = 'Failed to update homework panel';

      if (typeof e?.error === 'string' && e?.error?.includes(errMatch) && setError) {
        setError(errMsg);
      }
    });
    setUpdateSchema(data?.id);

    if (success) {
      if (data?.error && setError) {
        setError(errMsg);
      } else if (!data.error && setSuccessMsg) {
        if (data.homework_panel_marks.length === 0) {
          setErrorSchemaRemoved(validSemesterYear ? 'Schema for this Semester was removed by Admin' : 'Schema for this Quarter was removed by Admin');
        } else {
          setSuccessMsg('Homework Schema Created/Updated Successfully');
        }
      }
    }
    loadFalse();
  } catch (error) {
    setError('Some error occured, cannot update schema');
    loadFalse();
  }
};

export {
  getHomeworkSchema,
  saveHomeworkSchema,
};
