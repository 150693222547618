import makeStyles from '@mui/styles/makeStyles';
import { colors, fonts } from '../../../../theme';

const style = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: '0px',
    width: '100%',
    '@media (max-width: 1199px)': {
      marginRight: 0,
    },
  },
  addressCSS: {
    height: '2.3vw !important',
    '@media (max-width:1199px)': {
      height: '70px !important',
    },
    '& .MuiInputLabel-root': {
      '@media (max-width: 599px)': {
        transform: 'translate(8px, 23px) scale(1) !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (max-width: 599px)': {
        transform: 'translate(8px, -10px) scale(1) !important',
        fontSize: '12px !important',
      },
    },
  },
  fileLabel: {
    width: '70%',
    marginRight: '2%',
    cursor: 'not-allowed',
  },
  container: {
    display: 'flex',
    '& label': {
      backgroundColor: '#9cc',
      color: '#fff',
      boxShadow: 'none',
      padding: '8px 24px',
      letterSpacing: 2,
      '& span': {
        fontFamily: fonts.fontType.roboto,
      },
      '&:hover': {
        backgroundColor: '#019491',
        boxShadow: 'none',
      },
    },
  },
  switchText: {
    fontSize: '0.9vw !important',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px !important',
    },
  },
  switchHeading: {
    fontSize: '1vw !important',
    fontWeight: fonts.fontWeight.bold,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px !important',
    },
  },
  switchUi: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    '& p': {
      margin: '0 8px !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px !important',
      },
    },
    '& span': {
      marginTop: '0 !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px !important',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked+.MuiSwitch-track': {
        background: colors.lightGreen,
      },
    },
    '& .MuiSwitch-thumb': {
      width: 10,
      height: 10,
    },
  },
  select: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    bottom: '20',
  },
  error: {
    border: `1px solid ${colors.errorText}`,
    '&:hover, &:focus, &:focus-visible': {
      border: `2px solid ${colors.errorText}`,
      outline: 'none',
    },
  },
  errorText: {
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
  },
  phoneInput: {
    '& .MuiInputLabel-root.Mui-disabled': {
      width: 'initial',
      color: `${colors.placeHolderColor} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        transform: 'translate(0.7vw, 0.7vw)',
      },
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink.Mui-disabled': {
      width: 'initial',
      color: `${colors.primary} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
      },
      transform: 'translate(1vw, -0.7vw)',
      '@media (max-width: 899px)': {
        transform: 'translate(11px, -8px)',
        fontSize: '13px',
      },
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: colors.disabledField,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${colors.primary} !important`,
    },

  },
  dropDownSelect: {
    '& .MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.7vw)',
      },
      color: colors.placeHolderColor,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(1vw, -0.7vw)',
        '@media (max-width: 899px)': {
          transform: 'translate(11px, -8px) !important',
          fontSize: '13px !important',
        },
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
        },
        color: colors.primary,
      },
    },
    '& .MuiAutocomplete-root': {
      color: colors.primary,
      '& .MuiInputLabel-root': {
        '@media (min-width: 1200px)': {
          transform: 'translate(0.7vw, 0.5vw)',
        },
        color: colors.placeHolderColor,
        '&.MuiInputLabel-shrink': {
          transform: 'translate(1vw, -0.5vw)',
          '@media (min-width: 1200px)': {
            fontSize: '0.7vw',
          },
          color: colors.primary,
        },
      },
    },
  },
  timePicker: {
    '& .MuiOutlinedInput-root .MuiSvgIcon-root': {
      '@media (min-width: 1200px)': {
        width: '1.5vw',
        height: '1.5vw',
      },
      padding: 0,
    },
    '& .MuiInputLabel-root': {
      transform: 'translate(1.5vw, -0vw)',
      '@media (max-width: 899px)': {
        transform: 'translate(13px, -4px) !important',
        fontSize: '13px !important',
      },
    },
  },
  logsIconSize: {
    fontSize: '0.9vw',
    color: '#015EEA',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '0.5vw',
    '& div': {
      marginTop: '0.2vw',
      marginLeft: '0.2vw',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  inActiveText: {
    color: colors.redShade,
  },
  activeText: {
    color: colors.greenShade,
  },
  coordinatorHeading: {
    color: colors.primary,
    fontSize: 10,
    padding: '0 5px',
    backgroundColor: colors.white,
    position: 'absolute',
    top: 15,
    left: 32,
  },
  popupBtnDisable: {
    opacity: '.6',
    '& button': {
      cursor: 'not-allowed',
    },
  },
  activeCourseDropdown: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: `${colors.primary} !important`,
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.7vw)',
        fontSize: '0.7vw',
      },
      background: colors.white,
    },
  },
}));

export default style;
