import { colors, fonts } from '../../../../theme';
import listIcon from '../../../../assets/images/listIcon.png';

const addUserstyle = (() => ({
  viewLogs: {
    fontFamily: fonts.fontType.roboto,
    fontWeight: fonts.fontWeight.medium,
    display: 'flex',
    color: `${colors.actionIconsColor} !important`,
    textDecoration: 'underline',
    cursor: 'pointer',
    alignItems: 'center',
  },
  dialogButtons: {
    justifyContent: 'flex-end',
    padding: '0 10px',
    '& .MuiButton-root': {
      '@media (min-width: 1200px)': {
        minWidth: '10vw !important',
        height: '2.7vw !important',
        fontSize: '0.8vw',
      },
    },
  },
  popupButton: {
    display: 'flex',
    justifyContent: 'end',
    '& .MuiButtonBase-root.MuiButton-root': {
      '@media (min-width: 1200px)': {
        height: '3vw !important',
        minWidth: '9vw !important',
      },
      marginRight: '15px',
      borderRadius: '4px',
      marginTop: '20px',
    },
  },
  list: {
    listStyleImage: `url(${listIcon})`,
    paddingLeft: '4%',
    marginBottom: '3px',
    lineHeight: '1.5em',
    color: colors.placeHolderColor,
    fontFamily: 'inherit !important',
    '& li': {
      paddingLeft: '0.7em',
    },
    textAlign: 'start',
  },
  switchText: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw !important',
    },
    textAlign: 'center',
  },
  switchHeading: {
    '@media (min-width: 1200px)': {
      fontSize: '1vw !important',
    },
    fontWeight: fonts.fontWeight.bold,
    textAlign: 'center',
  },
  errorText: {
    color: colors.errorText,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
      marginTop: '0.3vw',
    },
    display: 'block',
  },
  alignGrid: {
    '@media (min-width: 1200px)': {
      height: '5vw',
      padding: 0,
      maxWidth: '33.2%',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px !important',
    },
    '& #addressAutoComplete': {
      width: '100%',
      '@media (min-width: 1200px)': {
        height: '2.7vw',
        padding: '0.7vw',
      },
      '@media (max-width: 599px)': {
        height: '15vw',
        fontSize: '4.5vw',
        paddingLeft: '15px',
      },
      '@media (min-width: 600px) and (max-width: 899px)': {
        height: '56px',
        fontSize: '15px !important',
        paddingLeft: '10px !important',
      },
    },
    '& .PhoneInput': {
      '@media (min-width: 1200px)': {
        width: '100%',
        height: '2.7vw',
        borderRadius: '6px',
        padding: '0 5px',
        border: '0.1vw solid #104F96',
        '& .makeStyles-errorBorder:focus-within, .makeStyles-errorBorder.PhoneInput-focus': {
          border: 'unset',
        },
        '& input': {
          border: 'unset',
          outline: 'unset',
          boxShadow: 'none',
        },
      },
      '@media (max-width: 2560px), @media (min-width: 2100px)': {
        fontSize: '0.9vw',
      },
      '& .PhoneInputInput': {
        '@media (max-width: 600px)': {
          height: '15vw !important',
          fontSize: '4.5vw',
          paddingLeft: '15px',
          border: 'solid 1px',
          borderColor: `${colors.primary}`,
        },
        '@media (min-width: 599px) and (max-width: 899px)': {
          height: '56px',
          border: 'solid 1px',
          fontSize: '15px !important',
          paddingLeft: '5px',
          borderColor: `${colors.primary}`,
        },
      },
    },
    '& .PhoneInputCountry': {
      marginRight: 0,
      paddingLeft: 5,
      display: 'none',
    },
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      '& .MuiFormControl-root': {
        margin: 'auto',
        width: '98%',
      },
      '& div': {
        margin: 0,
        '@media (max-width: 600px)': {
          margin: 2,

        },
      },
    },
    '& .MuiFormControl-root': {
      margin: '0.5vw 0',
      marginBottom: '0px !important',
      borderColor: `${colors.primary} !important`,
      '& .MuiFormControl-root': {
        margin: 0,
        width: '100% !important',
      },
    },
    '& .MuiTextField-root': {
      '@media (min-width: 1200px)': {
        width: '100% !important',
        margin: 0,
      },
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      marginTop: 0,
      '@media (min-width: 1200px)': {
        height: '2.7vw',
      },
      borderRadius: '0.4vw',
      width: '100%',
    },
  },
  '.MuiDialogActions-root': {
    minWidth: '100px !important',
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-input': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      border: `${colors.blue}!important`,
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '12px !important',
        color: `${colors.placeHolderColor}!important`,
        letterSpacing: 1,
      },
    },
  },
  imageAlign: {
    textAlign: 'center !important',
    '& > span': {
      marginLeft: '0px !important',
    },
  },
  userDialogAtom: {
    '&.MuiButton-textPrimary': {
      minWidth: '10vw !important',
    },
  },
  inViewLogButton: {
    '&.MuiButton-textPrimary': {
      minWidth: '40% !important',
    },
  },
  inActiveText: {
    color: colors.redShade,
  },
  activeText: {
    color: colors.greenShade,
  },
}));

export default addUserstyle;
