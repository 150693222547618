/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
  EnrollmentGrowthAcrossYear,
  ReturningBreakdownAcrossYear,
  CourseEnrollmentBreakdown,
  EnrollmentBreakDownByWeek,
  GeoRegionBreakdownGraph,
  ReturningBreakdown,
  CourseEnrollBreakdownAcrossYear,
  GeoRegionBreakdownAcrossYear,
  EnrollmentBreakDownAcrossYear,
  MapView,
} from './index';
import userRoles from '../../../../constant/userRoles';

import useCustomCubeQuery from '../../../../custom-hooks/useCustomCubeQuery';
import useStyles from '../../../../custom-hooks/useStyles';
import style from './style';
import { filteredData, getQuery } from './helper';
import chartTypes from './types';
import useAdmin from '../../../../custom-hooks/useAdmin';

function generateArrayOfYears(yearsData) {
  const year = yearsData[1];
  const currentYear = year;
  const years = [];
  let startYear = currentYear - 4;
  for (let i = startYear; i <= currentYear; i++) {
    years.push(startYear++);
  }
  return years;
}
function getLatestYears(yearsData) {
  const years = generateArrayOfYears(yearsData);
  const data = years?.map((i) => {
    return `${(i - 1).toString()}-${i.toString()}`;
  });
  return data;
}
function RenderChart({
  type, chartData, t, selectedFilter,
}) {
  if (type === chartTypes.ENROLLMENT_GROWTH_ACROSS_YEAR) {
    return (
      <EnrollmentGrowthAcrossYear
        label={t('ENROLLMENT_GROWTH_ACROSS_YEAR')}
        data={chartData?.length && chartData[0]}
      />
    );
  }
  if (type === chartTypes.RETURNING_BREAKDOWN_ACROSS_YEAR) {
    return (
      <ReturningBreakdownAcrossYear
        label={t('RETURNING_BREAKDOWN_ACROSS_YEAR')}
        data={chartData?.length && chartData[0]}
      />
    );
  }
  if (type === chartTypes.COURSE_ENROLLMENT_BREAKDOWN) {
    return (
      <CourseEnrollmentBreakdown
        label={t('COURSE_ENROLLMENT_BREAKDOWN')}
        data={chartData?.length && chartData[0]}
      />
    );
  }

  if (type === chartTypes.ENROLLMENT_BREAKDOWN_BY_WEEK) {
    return (
      <EnrollmentBreakDownByWeek
        label={t('ENROLLMENT_BREAKDOWN_BY_WEEK')}
        data={chartData?.length && chartData[0]}
      />
    );
  }
  if (type === chartTypes.RETURNING_BREAKDOWN) {
    return (
      <ReturningBreakdown
        label={t('RETURNING_BREAKDOWN')}
        data={chartData?.length && chartData[0]}
      />
    );
  }
  if (type === chartTypes.GEO_REGION_BREAK_DOWN) {
    return (
      <GeoRegionBreakdownGraph
        label={t('GEO_REGION_BREAKDOWN')}
        data={chartData?.length && chartData[0]}
      />
    );
  }
  if (type === chartTypes.COURSE_ENROLLMENT_BREAKDOWN_BY_REGION_ACROSS_YEAR) {
    return (
      <GeoRegionBreakdownAcrossYear
        label={t('GEO_REGION_BREAKDOWN')}
        data={chartData?.length && chartData[0]}
      />
    );
  }
  if (type === chartTypes.COURSE_ENROLLMENT_BREAKDOWN_ACROSS_YEAR) {
    return (
      <CourseEnrollBreakdownAcrossYear
        label={t('COURSE_ENROLLMENT_BREAKDOWN')}
        data={chartData?.length && chartData[0]}
      />
    );
  }
  if (type === chartTypes.MAP_VIEW) {
    return (
      <MapView label={t('MAP_VIEW')} data={chartData?.length && chartData[0]} />
    );
  }
  if (type === chartTypes.ENROLLMENT_BREAKDOWN_ACROSS_YEAR) {
    return (
      <EnrollmentBreakDownAcrossYear
        label={t('ENROLLMENT_BREAKDOWN1')}
        data={chartData?.length && chartData[0]}
        selectedFilter={selectedFilter}
      />
    );
  }
  return true;
}
function DisplayChart(props) {
  const {
    type, setLoading, selectedFilter, locationIds, loading,
  } = props;
  const classes = useStyles(style)();
  const containerClass = type === chartTypes.RETURNING_BREAKDOWN_ACROSS_YEAR
    ? clsx(classes.containterLinerChart, classes.container)
    : type === chartTypes.ENROLLMENT_GROWTH_ACROSS_YEAR
      ? clsx(classes.containterLinerChart, classes.container)
      : classes.container;
  const adminData = useAdmin();
  const { graphOption, enrollGraphOption } = adminData;
  const latestYears = getLatestYears(enrollGraphOption?.split('-'));
  let regWeeks = filteredData('reg_week', selectedFilter);
  regWeeks = regWeeks.map((c) => c?.label);
  const isRegionalCoordinator = useSelector(
    (reduxState) => reduxState.getStudent.userRole,
  ) === userRoles.REGION_COORDINATOR;

  const query = getQuery(type, graphOption, latestYears, selectedFilter, locationIds, isRegionalCoordinator);
  const { chartData, resultSet, error } = useCustomCubeQuery(query, type, regWeeks, graphOption);
  const { t } = useTranslation();
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (resultSet) {
    setLoading(false);
  }
  return (
    <div className={containerClass}>
      {chartData?.length > 0 && (
        <RenderChart {...{
          type, chartData, t, selectedFilter,
        }}
        />
      )}

      {(!loading && chartData?.length === 0) && (
        <Grid className={classes.noData}>
          <DoNotDisturbIcon />
          <Grid>
            <Typography variant="subtitle2" color="text.secondary">
              {t('NO_DATA')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default DisplayChart;
