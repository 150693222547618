const checkedData = {
  studentId: 'StudentInfo/id',
  studentFirstName: 'StudentInfo/studentInfo?.first_name',
  studentMbId: 'StudentInfo/studentInfo?.manabadi_email',
  studentDob: 'StudentInfo/dateOfBirth',
  studentGender: 'StudentInfo/studentInfo?.gender',
  studentPic: 'StudentInfo/studentInfo?.profile_photo',
  studentTshirtSize: 'StudentInfo/enrolled_courses?.tShirtSize',
  studentExtracurricular: 'StudentInfo/enrolled_courses?.extraCurricularActivities',
  academicGrade: 'StudentInfo/enrolled_courses?.academic_grade',
  geoRegion: 'GeoRegion/enrolled_courses?.location?.region?.geo_region',
  geoRegionName: 'GeoRegion/enrolled_courses?.location?.region?.name',
  regionCoordinatorName: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.first_name',
  regionCoordinatorPhone: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.contact_number',
  regionCoordinatorManabadiEmail: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.manabadiEmail',
  regionCoordinatorPersonalEmail: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.personalEmail',
  regionCoordinatorNameSupport: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.first_name',
  regionCoordinatorPhoneSupport: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.contact_number',
  regionCoordinatorManabadiEmailSupport: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.manabadiEmail',
  regionCoordinatorPersonalEmailSupport: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.personalEmail',
  centerAddress: 'CenterInfo/enrolled_courses?.location?.locationAddress?.address',
  centerLoc: 'CenterInfo/enrolled_courses?.location?.name',
  centerState: 'CenterInfo/enrolled_courses?.location?.locationAddress?.state',
  locationCoordinatorName: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.first_name',
  locationCoordinatorPhone: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.contact_number',
  locationCoordinatorManabadiEmail: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.manabadiEmail',
  locationCoordinatorPersonalEmail: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.personalEmail',
  locationCoordinatorNameSupport: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.first_name',
  locationCoordinatorPhoneSupport: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.contact_number',
  locationCoordinatorManabadiEmailSupport: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.manabadiEmail',
  locationCoordinatorPersonalEmailSupport: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.personalEmail',
  classTeacherName: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.firstName',
  classTeacherPhone: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.contactNumber',
  classTeacherManabadiEmail: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.manabadiEmail',
  classTeacherPersonalEmail: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.personalEmail',
  classTeacherNameSecondary: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.firstName',
  classTeacherPhoneSecondary: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.contactNumber',
  classTeacherManabadiEmailSecondary: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.manabadiEmail',
  classTeacherPersonalEmailSecondary: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.personalEmail',
  courseName: 'CourseInfo/enrolled_courses?.course?.name',
  section: 'CourseInfo/enrolled_courses?.google_class?.section',
  location: 'ExamDetails/enrolled_courses?.location?.name',
  examLocation: 'ExamDetails/enrolled_courses?.location?.exam_center?.address?.address',
  q1Marks: 'ExamDetails/marks?.Q1?.obtainedMarks',
  q2Marks: 'ExamDetails/marks?.Q2?.obtainedMarks',
  q3Marks: 'ExamDetails/marks?.Q3?.obtainedMarks',
  q1Homework: 'ExamDetails/homeworkMarks?.Q1?.obtainedMarks',
  q2Homework: 'ExamDetails/homeworkMarks?.Q2?.obtainedMarks',
  q3Homework: 'ExamDetails/homeworkMarks?.Q3?.obtainedMarks',
  s1Marks: 'ExamDetails/marks?.S1?.obtainedMarks',
  s2Marks: 'ExamDetails/marks?.S2?.obtainedMarks',
  s3Marks: 'ExamDetails/marks?.S3?.obtainedMarks',
  s1Homework: 'ExamDetails/homeworkMarks?.S1?.obtainedMarks',
  s2Homework: 'ExamDetails/homeworkMarks?.S2?.obtainedMarks',
  s1Quiz: 'ExamDetails/quizMarks?.S1?.obtainedMarks',
  s2Quiz: 'ExamDetails/quizMarks?.S2?.obtainedMarks',
  s1Assignment: 'ExamDetails/assignment?.S1?.obtainedMarks',
  s2Assignment: 'ExamDetails/assignment?.S2?.obtainedMarks',
  s1Coocurricular: 'ExamDetails/cocurricular?.S1?.obtainedMarks',
  s2Coocurricular: 'ExamDetails/cocurricular?.S2?.obtainedMarks',
  bonusMarks: 'ExamDetails/bonus',
  averageScore: 'ExamDetails/enrolled_courses?.averageScore',
  studentHallTicketLink: 'ExamDetails/enrolled_courses?.google_class?.class_room_link',
  studentHallTicketNumber: 'ExamDetails/enrolled_courses?.hallTicketNumber',
  result: 'ExamDetails/enrolled_courses?.result',
  parent1FirstName: 'ParentInfo/parent1Info?.first_name',
  parent1Email: 'ParentInfo/parent1Info?.personal_email',
  parent1Phone: 'ParentInfo/parent1Info?.contact_number',
  parent2FirstName: 'ParentInfo/parent2Info?.first_name',
  parent2Email: 'ParentInfo/parent2Info?.personal_email',
  parent2Phone: 'ParentInfo/parent2Info?.contact_number',
  parent1Company: 'ParentInfo/parent1Info?.user_extended?.name_of_company',
  parent1Profession: 'ParentInfo/parent1Info?.user_extended?.profession',
  parent2Company: 'ParentInfo/parent2Info?.user_extended?.name_of_company',
  parent2Profession: 'ParentInfo/parent2Info?.user_extended?.profession',
  parent1Address: 'ParentInfo/parent1Info?.address?.address',
  s1ClassAttendance: 'ExamDetails/enrolled_courses?.attendanceS1',
  s2ClassAttendance: 'ExamDetails/enrolled_courses?.attendanceS2',
};

export default checkedData;
