import { colors, fonts } from '../../../theme';

const adminDashboardStyle = (theme) => ({
  noData: {
    textAlign: 'center',
  },
  activeAcademicYear: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
    '& p': {
      fontSize: 12,
      margin: '0 4px',
      color: colors.primary,
    },
    '& span': {
      marginBottom: 0,
      color: colors.primary,
    },
    '& b': {
      fontSize: 13,
      marginLeft: 5,
    },
  },
  heading: {
    padding: 0,
    fontWeight: 600,
    color: colors.primaryBlueColor,
    paddingBottom: 20,
    fontSize: 18,
  },

  calenderDiv: {
    display: 'flex',
    padding: '30px 10px 0px 10px',
    alignItems: 'center',
    '@media (max-width: 1100px)': {
    },
    overflow: 'hidden',
    'overflow-x': 'auto',
    'overflow-y': 'hidden',
    'scrollbar-width': 'thin',
    '&::-webkit-scrollbar': {
      width: '300px',
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#00000033',
    },
    '& svg': {
      '& rect': {
        fillOpacity: 'inherit',
      },
    },
  },
  centerAlign: {
    justifyContent: 'center !important',
    textAlign: 'center !important',
    display: 'flex !important',
  },
  alignGrid: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingTop: 0,
      flexBasis: '20%',
    },
    '& .MuiFormControl-root': {
      margin: '10px 0 !important',
      width: '100%',

      '& .MuiFormControl-root': {
        margin: '0 !important',
        width: '100%',
      },
    },
  },
  studentText: {
    fontWeight: 'bold',
    marginLeft: '10px',
    marginTop: '20px',
  },
  rightIcons: {
    textAlign: 'right',
    paddingRight: '1%',
    borderRadius: '0.4vw !important',
    position: 'relative',
    padding: '0.5vw',
    marginLeft: 'auto',
    '& svg': {
      width: '1.5vw',
      height: '1.5vw',
      color: colors.black,
      [theme.breakpoints.down('md')]: {
        width: 16,
        height: 16,
      },
    },
    '&:hover': {
      backgroundColor: 'white !important',
      minHeight: '3px !important',
    },
  },
  toggleButtonGrp: {
    border: '0.1vw solid #ccc',
    borderRadius: '0.4vw',
    maxHeight: '2.5vw',
    paddingRight: 1,
    [theme.breakpoints.down('md')]: {
      maxHeight: 'inherit',
      alignItems: 'baseline',
    },
  },
  toggleButtonCard: {
    borderRadius: '0.8vw 0vw 0vw 0.8vw',
    border: '0 !important',
    '&.MuiButtonBase-root.MuiToggleButton-root': {
      width: '4vw !important',
      padding: '0.5vw !important',
      [theme.breakpoints.down('md')]: {
        width: '35px !important',
        height: '35px !important',
        padding: '0 5px !important',
      },
      '&:hover': {
        background: colors.toggleButtonBG,
        borderRadius: '1vw 0.4vw 0.4vw 1vw',
        '& svg': {
          color: colors.white,
        },
      },
      '&.Mui-selected': {
        background: colors.toggleButtonBG,
        borderRadius: '1vw 0.4vw 0.4vw 1vw',
        '& svg': {
          color: colors.white,
        },
      },
    },
  },
  boxDiv: {
    borderRadius: 15,
    backgroundColor: colors.greyShade,
    padding: '15px 12px 12px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 15,
    border: `solid 1px ${colors.actionIconsColor}`,
    whiteSpace: 'nowrap',
    '@media (max-width:600px)': {
      width: 'fit-content',
      padding: '0px !important',
    },
  },
  titleRow: {
    justifyContent: 'space-between',
    color: colors.actionIconsColor,
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    fontSize: '1.5vw',
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  filterButton: {
    float: 'left',
    width: '100%',
    paddingLeft: '2%',
    marginBottom: 20,
    '& .MuiFormControl-root.MuiFormControl-fullWidth': {
    },
    '& button.MuiButton-filterPrimary': {
      background: '#ededed',
      border: 'solid 2px #ccc',
      padding: '0 12px 0 0',
      [theme.breakpoints.down('md')]: {
        fontSize: 7,

      },
      '@media (max-width: 600px)': {
        height: '10vw',
        borderRadius: '5px',
      },
    },
    '& svg': {
      width: '1vw',
      height: '1vw',
      paddingTop: 0,
      margin: '0 5px',
      [theme.breakpoints.down('md')]: {
        width: 20,
        height: 24,
        margin: 0,
        padding: 4,
      },
    },
    '& .DownLeft': {
      background: ' #ffffff',
      float: 'left',
      padding: '5px',
      borderRight: 'solid 2px #ccc',
      marginRight: '6px',
    },
  },
  ChipSection: {
    '& .MuiOutlinedInput-root': {
      padding: '0 !important',
      '& .MuiButtonBase-root': {
        height: '2vw',
      },
    },
    '& .MuiInputLabel-root': {
      lineHeight: '0.9rem',
    },
  },
  enrollmentBar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  enrolled: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
    '& p': {
      fontSize: 14,
      margin: '0 4px',
    },
    '& span': {
      fontSize: 14,
      marginBottom: 1,
    },
    '& b': {
      fontSize: 13,
      marginLeft: 5,
    },
  },
  headingColor: {
    color: colors.actionIconsColor,
    margin: 0,
  },
  mainContainer: {
    padding: '0 30px 30px',
    backgroundColor: colors.white,
    '@media (max-width:600px)': {
      padding: '0px !important',
    },
  },
  headingStyle: {
    color: colors.primary,
    marginLeft: 20,
    marginBottom: 20,
  },
  yearHead: {
    fontSize: 40,
    marginBottom: 20,
    transform: 'rotate(-90deg)',
    color: colors.lightGrey,
  },
  mainSection: {
    width: 'calc(100% - 240px)',
    '@media (max-width:600px)': {
      padding: 10,
    },
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  filterSection: {
    position: 'relative',
    '& .MuiDrawer-docked .MuiDrawer-paper': {
      position: 'sticky',
      top: 0,
      maxHeight: '100vh',
      overflow: 'hidden',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        background: colors.primary,
      },
    },
  },
});

export default adminDashboardStyle;
