/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable func-names */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import Loader from '../../../components/atoms/loader';
import { getExportedSheetF } from '../../../store/actions/getStudent';
import useStudent from '../../../custom-hooks/useStudent';
import userRoles from '../../../constant/userRoles';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';
import { getLocalStorage } from '../../../utils/localStorageMethod';

export default function ExportedSheet() {
  const encryptedUserRole = getLocalStorage('userRole');
  const userId = getLocalStorage('tempUserId') || getLocalStorage('userId');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const studentData = useStudent();
  const dataExportSheets = studentData?.dataExportSheets;
  const [dataSheet, setDataSheet] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const shortData = dataExportSheets.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
    if (userRole === userRoles.SUPER_USER) {
      const userData = shortData.filter((data) => data.userInfo === userId);
      setDataSheet(userData);
    } else {
      setDataSheet(shortData);
    }
  }, [dataExportSheets]);

  useEffect(() => {
    dispatch(getExportedSheetF(setLoading));
  }, []);

  return (
    <div>
      <Grid style={{ height: window.innerHeight }} className={classes.mainContainer}>
        <Box>
          <Grid container item className={classes.titleRow}>
            <Grid item>
              <Typography className={classes.headerTitle}>
                {t('EXPORTED_SHEET')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {loading ? (
            <Grid>
              <Loader message={t('LOADING')} />
            </Grid>
          ) : (
            <TableContainer sx={{ minWidth: 65 }} style={{ paddingTop: '2rem' }}>
              <Table sx={{ width: 'fit-content' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '20%' }} align="center" className={classes.rowsTitleSheetName}>Sheet Name</TableCell>
                    <TableCell style={{ width: '10%' }} align="center" className={classes.rowsTitle}>Created By</TableCell>
                    <TableCell style={{ width: '10%' }} align="center" className={classes.rowsTitle}>Rows Updated</TableCell>
                    <TableCell style={{ width: '10%' }} align="center" className={classes.rowsTitle}>Columns Updated</TableCell>
                    <TableCell style={{ width: '30%' }} align="center" className={classes.rowsTitle}>Filters</TableCell>
                    <TableCell style={{ width: '10%' }} align="center" className={classes.rowsTitle}>Created On</TableCell>
                    <TableCell style={{ width: '10%' }} align="center" className={classes.rowsTitle}>Local Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSheet.map((row) => (
                    <TableRow
                      key={row.sheetId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" component="th" scope="row">
                        <Link href={row.urlFile} color="black" underline="hover" target="_blank">
                          {row.fileName}
                        </Link>
                      </TableCell>
                      <TableCell align="center">{row.userInfo ? `${row.userInfo?.firstName} ${row.userInfo?.lastName}` : 'N/A'}</TableCell>
                      <TableCell align="center">{row.rowsUpdated}</TableCell>
                      <TableCell align="center">{row.columnsUpdated}</TableCell>
                      <TableCell align="left">{row.filters}</TableCell>
                      <TableCell align="left">{(row.creationDate)}</TableCell>
                      <TableCell align="left">{(row.localHour ? row.localHour : row.creationDate)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Grid>
    </div>
  );
}
