import React from 'react';
import { ReactComponent as Svg } from '../images/adminstration.svg';

function AdminstrationIcon() {
  return (
    <div className="App">
      <Svg />
    </div>
  );
}
export default AdminstrationIcon;
