import { colors, fonts } from '../../../theme';

const style = (() => ({
  formControl: {
    width: '100%',
    '@media (min-width: 1200px)': {
      margin: '0.8vw',
    },
    '@media (max-width: 1199px)': {
      marginRight: 0,
      marginLeft: 0,
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.black} !important`,
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      color: `${colors.placeHolderColor} !important`,
    },
    '& .MuiOutlinedInput-root': {
      background: '#fff',
      color: colors.placeHolderColor,
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },
  performTextField: {
    '& .Mui-error': {
      color: colors.errorText,
      fontFamily: 'inherit',
      letterSpacing: 'inherit',
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
      },
      marginLeft: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      '@media (min-width: 1200px)': {
        color: 'inherit !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        backgroundColor: colors.white,
        color: colors.placeHolderColor,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, -0.5vw)',
        fontSize: '0.7vw',
        color: colors.primary,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.7vw)',
        fontSize: '0.9vw',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '0.7vw',
      },
    },
    '& .MuiOutlinedInput-root': {
      height: '2.9vw !important',
      marginBottom: '5px',
      backgroundColor: 'gainsboro',
      '& .MuiInputLabel-root': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw !important',
        },
        color: `${colors.placeHolderColor} !important`,
      },
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${colors.placeHolderColor} !important`,
      },
      '& input': {
        color: colors.black,
        '@media (min-width: 1200px)': {
          paddingLeft: '1vw',
          paddingRight: '1vw',
          fontSize: '0.9vw',
        },
        '&.Mui-disabled': {
          '@media (min-width: 1200px)': {
            padding: '0.65vw',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
      },
      '& .MuiFormHelperText-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
        },
        marginLeft: 0,
        fontSize: '0.9vw',
      },
    },
    '& fieldset': {
      '& legend span': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  performTextFieldMobile: {
    '& .MuiOutlinedInput-root': {
      marginBottom: '5px',
      backgroundColor: 'gainsboro',
      '& .MuiInputLabel-root': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw !important',
        },
        color: `${colors.placeHolderColor} !important`,
      },
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${colors.placeHolderColor} !important`,
      },
      '& input': {
        color: colors.black,
        '@media (min-width: 1200px)': {
          paddingLeft: '1vw',
          paddingRight: '1vw',
          fontSize: '0.9vw',
        },
        '&.Mui-disabled': {
          '@media (min-width: 1200px)': {
            padding: '0.65vw',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
      },
      '& .MuiFormHelperText-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
        },
        marginLeft: 0,
        fontSize: '0.9vw',
      },
    },
  },
}));

export default style;
