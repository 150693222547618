/* eslint-disable */
import React, { memo, useState } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import { Grid, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import GridViewIcon from '@mui/icons-material/GridView';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import AutoRenewIcon from '@mui/icons-material/Autorenew';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import ListViewIcon from '../../../../assets/svg/listViewIcon';
import ButtonAtom from '../../../../components/atoms/button';
import CheckboxAtom from '../../../../components/atoms/checkbox';
import { DialogAtom, DropdownStudents } from '../../../../components/atoms';
import { filterYearOptions } from '../constants';
import { colors } from '../../../../theme';
import userRoles from '../../../../constant/userRoles';

import {
  CenterInfo,
  CourseInfo,
  ExamDetails,
  ParentInfo,
  ClassTeacher,
  StudentInfo,
  RegionalPanelStudentInfo,
  ExamDetailsSemesterBase,
  RegionCoordinator,
  LocationCoordinator,
  GeoRegion,
  RegionalPanelRegionCoordinator,
  RegionalPanelLocationCoordinator,
  RegionalPanelClassTeacher,
  RegionalPanelParentInfo
} from '../../../admin-dashboard/students/checkboxData';
import { deleteFilterView, getExportedStudentData, postExportedSheetF } from '../../../../store/actions/getStudent';
import { NavigateRoutes, Buttons } from '../../../../constant';
import Constant from '../../../../store/constant';
import { getLocalStorage, setLocalStorage } from '../../../../utils/localStorageMethod';
import MediaUploader from '../../../../utils/exportStudentSheet';
import checkedData from './helper';
import { getCurrentYear } from '../../../../utils/methods';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';
import { setFilterSelectedView } from '../../../../store/actions/getAdmin';

const useStyles = makeStyles((theme) => ({
  containerGrid: {
    marginTop: '4vw',
  },
  titleCheckbox: {
    textAlign: 'flex-start',
    marginLeft: '5px',
    marginRight: '5px',
    fontWeight: 'bold',
  },
  alignGrid: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
    },
    '&.MuiGrid-root.MuiGrid-item:first-child': {
      maxWidth: '12.6%',
      '@media (max-width: 900px)': {
        maxWidth: '100% !important',
      },
      '@media (min-width: 750px) and (max-width: 1200px)': {
        maxWidth: '100% !important',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      borderBottom: `0.1vw solid ${colors.primary} !important`,
      borderRadius: '0 !important',
    },
    '&.Mui-focused.MuiOutlinedInput-notchedOutline': {
      borderBottom: `0.2vw solid ${colors.primary}`,
    },
    '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderBottom: `0.2vw solid ${colors.errorText}`,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderBottom: `0.1vw solid ${colors.errorText}`,
    },
    '& .MuiFormControl-root': {
      margin: '10px 0 !important',
      paddingRight: '20px',
      '@media (min-width: 1200px)': {
        width: '100%',
        maxWidth: '10vw',
      },
      '@media (max-width: 1200px)': {
        width: '100%',
        maxWidth: '300px',
      },
      '& .MuiFormControl-root': {
        margin: '0 !important',
        width: '100%',
      },
    },
  },
  rightIcons: {
    textAlign: 'right',
    borderRadius: '0.4vw !important',
    position: 'relative',
    padding: '1vw 0.5vw',
    marginLeft: 'auto',
    '& svg': {
      width: '1.5vw',
      height: '1.5vw',
      color: colors.black,
      [theme.breakpoints.down('md')]: {
        width: 16,
        height: 16,
      },
    },
    '&:hover': {
      backgroundColor: 'white !important',
      minHeight: '3px !important',
    },
  },
  emailIcon: {
    marginRight: 30,
  },
  toggleButtonGrp: {
    border: '0.1vw solid #ccc',
    borderRadius: '0.4vw',
    maxHeight: '2.5vw',
    paddingRight: 1,
    [theme.breakpoints.down('md')]: {
      maxHeight: 'inherit',
    },
  },
  toggleMainHeaderList: {
    paddingLeft: 52,
    borderRadius: '0.8vw 0vw 0vw 0.8vw',
    border: '0 !important',
    '&.MuiButtonBase-root.MuiToggleButton-root': {
      lineHeight: 'normal',
      width: '4vw !important',
      padding: '0.9vw 0.5vw 0.5vw 0.5vw',
      [theme.breakpoints.down('md')]: {
        width: '35px !important',
        height: '35px !important',
        padding: '0 5px !important',
        lineHeight: '35px !important',
      },
      '&:hover': {
        background: colors.toggleButtonBG,
        borderRadius: '0.4vw 1vw 1vw 0.4vw',
        '& svg': {
          color: colors.white,
        },
      },
      '&.Mui-selected': {
        background: colors.toggleButtonBG,
        borderRadius: '0.4vw 1vw 1vw 0.4vw',
        '& svg': {
          color: colors.white,
        },
      },
    },
  },
  toggleButtonCard: {
    borderRadius: '0.8vw 0vw 0vw 0.8vw',
    border: '0 !important',
    '&.MuiButtonBase-root.MuiToggleButton-root': {
      width: '4vw !important',
      padding: '0.5vw !important',
      [theme.breakpoints.down('md')]: {
        width: '35px !important',
        height: '35px !important',
        padding: '0 5px !important',
      },
      '&:hover': {
        background: colors.toggleButtonBG,
        borderRadius: '1vw 0.4vw 0.4vw 1vw',
        '& svg': {
          color: colors.white,
        },
      },
      '&.Mui-selected': {
        background: colors.toggleButtonBG,
        borderRadius: '1vw 0.4vw 0.4vw 1vw',
        '& svg': {
          color: colors.white,
        },
      },
    },
  },
  studentExportDialogAtom: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        minWidth: '80vw !important',
        maxWidth: '60vw !important',
      },
      '& .MuiFormControl-root': {
        margin: '0 !important',
        width: '0% !important',
      },
    },
  },
  studentBox: {
    maxWidth: 'unset !important',
    '& .MuiGrid-item': {
      maxWidth: 'unset !important',
      textAlign: 'right !important',
    },
  },
  selectAll: {
    marginLeft: '15%',
    display: 'flex !important',
    justifyContent: 'flex-start',
    '& .MuiFormControlLabel-label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      width: 'max-content',
    },
  },
  checkbox: {
    display: 'flex !important',
    marginBottom: '12px',
    '& .MuiFormControlLabel-label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
    },
    '& div>MuiBox-root:first-child': {
      width: '25%',
    },
  },
  checkboxHead: {
    display: 'flex',
    margin: '5px 0 0px 0px !important',
    color: '#104F96',
    minWidth: '15%',
    '@media (max-width: 600px)': {
      minWidth: '50%',
    },
    position: 'relative',
    '& p': {
      margin: '0',
      paddingRight: '15px',
      '& span': {
        position: 'absolute',
        right: '10px',
      },
    },
  },
  checkboxContent: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    '& .MuiFormControlLabel-root': {
      minWidth: 'max-content',
    },
    '& css-1vgzwi7-MuiFormControl-root:last child': {
      width: '0% !important',
      margin: '0 !important',
    },
  },
  dialogCheckbox: {
    '& .MuiDialog-paper': {
      margin: '0 !important',
      width: '100% !important',
    },
  },
  errorText: {
    color: colors.errorText,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
  },
  alertprimary: {
    height: '3vw',
    display: 'flex',
    color: colors.green,
    '@media (max-width: 1199px)': {
      height: '80px',
    },
  },
  downloadIconDisable: {
    opacity: '.6',
    cursor: 'not-allowed',
  },
  downloadIcon: {
    opacity: '.9',
    cursor: 'pointer',
  },
}));

function MainViewHeader(props) {
  const {
    view,
    studentFilterViews,
    refreshSort,
    handleView,
    getFilterViewOptions,
    filterByYearDropDown,
    setError,
    selectedFilter,
    isSelectedCheckbox,
    filterView,
    filterYear,
    setFilterView,
    setFilterYear,
    newAddedFilterView,
    localFilterPayload,
    isSelectAllCheck,
    refUnSelectedStudentIds,
  } = props;
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const academicYear = selectedFilter?.find((i) => i.filterKey === 'academic_year')?.label;
  const studentData = useSelector((state) => state?.getStudent);
  const adminData = useSelector((state) => state?.getAdmin);
  const lastSelectedView = adminData.selectedView;
  const lastAddedView = studentData.newAddedStudentFilterView.name;
  const selectedView = filterView || lastSelectedView || lastAddedView;

  const classes = useStyles();
  const { t } = useTranslation();
  const reduxStore = useSelector((state) => state?.getStudent?.students);
  const [loading, setLoading] = useState(false);
  const [issetopenCSVDialog, setOpenCSVDialog] = useState(false);
  const [issetOkOpenDialog, setOkOpenDialog] = useState(false);
  const [exportSuccesfull, setExportSuccessfull] = useState(false);
  const [issetErrorOpenDialog, setErrorOpenDialog] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [check, setCheck] = useState([]);
  const dispatch = useDispatch();
  const [checkMarks, setCheckMarks] = useState(false);

  const toggleList = () => {
    setError('');
    dispatch({
      type: Constant.SET_VIEW,
      payload: 'listView',
    });
  };
  const onGroupAutoCompleteSelection = (e, val) => {
    setError('');
    if (val?.length <= 0) {
      val.push({
        filterKey: 'academic_year',
        groupBy: 'Academic Year',
        label: getCurrentYear()?.id,
      }, {
        filterKey: 'status',
        groupBy: 'Status',
        label: 'true',
      });
    }
    dispatch({
      type: Constant.SET_SELECTED_FILTER,
      payload: val,
    });
  };
  const toggleCard = () => {
    setError('');
    dispatch({
      type: Constant.SET_VIEW,
      payload: 'cardView',
    });
  };

  const resetSelectedFilter = () => {
    dispatch({
      type: Constant.SET_SELECTED_FILTER,
      payload: [
        {
          label: getCurrentYear().id,
          groupBy: 'Academic Year',
          filterKey: 'academic_year',
        },
        {
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        },
      ],
    });
  };

  const navigate = useNavigate();
  const handleCheck = (event, item) => {
    if (item.id === 'ExamDetails/Q1Marks' || item.id === 'ExamDetails/Q2Marks' || item.id === 'ExamDetails/Q3Marks' || item.id === 'ExamDetails/Q1Homework' || item.id === 'ExamDetails/Q2Homework' || item.id === 'ExamDetails/Q3Homework' || item.id === 'ExamDetails/BonusMarks') {
      setCheckMarks(true);
    }
    const { checked } = event.target;
    if (event.target.checked) {
      setIsCheck([...isCheck, item.id]);
      setCheck([...check, item]);
    }
    if (!checked) {
      setIsCheck(isCheck.filter((value) => value !== item.id));
      setCheck(check.filter((value) => value.id !== item.id));
    }
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setCheckMarks(true);
      const isAllcheck = [];
      const allCheck = [];
      if (isSelectedCheckbox?.length > 0) {
        if (userRole === userRoles.REGION_COORDINATOR) {
          RegionalPanelStudentInfo.map((pi) => { isAllcheck.push(pi.id); allCheck.push(pi); return [isAllcheck, allCheck]; });
        } else {
          StudentInfo.map((pi) => { isAllcheck.push(pi.id); allCheck.push(pi); return [isAllcheck, allCheck]; });
        }
      }
      CenterInfo.map((ci) => { isAllcheck.push(ci.id); allCheck.push(ci); return [isAllcheck, allCheck]; });
      GeoRegion.map((si) => { isAllcheck.push(si.id); allCheck.push(si); return [isAllcheck, allCheck]; });
      CourseInfo.map((ci) => { isAllcheck.push(ci.id); allCheck.push(ci); return [isAllcheck, allCheck]; });
      if (isSelectedCheckbox?.length > 0) {
        if (academicYear.split('-')[0] > 2022) {
          ExamDetailsSemesterBase.map((ed) => { isAllcheck.push(ed.id); allCheck.push(ed); return [isAllcheck, allCheck]; });
        } else {
          ExamDetails.map((ed) => { isAllcheck.push(ed.id); allCheck.push(ed); return [isAllcheck, allCheck]; });
        }
      }
      if (isSelectedCheckbox?.length > 0) {
        if (userRole === userRoles.REGION_COORDINATOR) {
          RegionalPanelParentInfo.map((pi) => { isAllcheck.push(pi.id); allCheck.push(pi); return [isAllcheck, allCheck]; });
        } else {
          ParentInfo.map((pi) => { isAllcheck.push(pi.id); allCheck.push(pi); return [isAllcheck, allCheck]; });
        }
      }
      if (userRole === userRoles.REGION_COORDINATOR) {
        RegionalPanelClassTeacher.map((pi) => { isAllcheck.push(pi.id); allCheck.push(pi); return [isAllcheck, allCheck]; });
      } else {
        ClassTeacher.map((si) => { isAllcheck.push(si.id); allCheck.push(si); return [isAllcheck, allCheck]; });
      }
      if (userRole === userRoles.REGION_COORDINATOR) {
        RegionalPanelRegionCoordinator.map((pi) => { isAllcheck.push(pi.id); allCheck.push(pi); return [isAllcheck, allCheck]; });
      } else {
        RegionCoordinator.map((ci) => { isAllcheck.push(ci.id); allCheck.push(ci); return [isAllcheck, allCheck]; });
      }
      if (userRole === userRoles.REGION_COORDINATOR) {
        RegionalPanelLocationCoordinator.map((pi) => { isAllcheck.push(pi.id); allCheck.push(pi); return [isAllcheck, allCheck]; });
      } else {
        LocationCoordinator.map((ci) => { isAllcheck.push(ci.id); allCheck.push(ci); return [isAllcheck, allCheck]; });
      }
      setIsCheck(isAllcheck);
      setCheck(allCheck);
    } else {
      setIsCheck([]);
      setCheck([]);
      setCheckMarks(false);
    }
    setIsCheckAll(e.target.checked);
  };

  const cancelExport = () => {
    setOpenCSVDialog(false);
    setIsCheck([]);
    setIsCheckAll(false);
    setCheck([]);
  };

  const handleExportToCsvMarks = () => {
    const filterstudents = reduxStore.filter(
      (x) => isSelectedCheckbox.find((id) => id === x?.studentInfo?.id),
    );
    const students = filterstudents.map((item) => {
      return {
        id: item.id,
        courseId: item.enrolled_courses.course.id,
      };
    });

    const unselectedStudents = refUnSelectedStudentIds.current;
    const date = new Date();
    const year = getLocalStorage('academicYear');

    const payload = {
        isSelectAllCheck,
        ...localFilterPayload,
        students,
        academicYear,
        topics: {
          studentInfo: {
            studentId: isCheck?.includes(checkedData.studentId) || false,
            name: isCheck?.includes(checkedData.studentFirstName) || false,
            mbId: isCheck?.includes(checkedData.studentMbId) || false,
            dob: isCheck?.includes(checkedData.studentDob) || false,
            gender: isCheck?.includes(checkedData.studentGender) || false,
            image: isCheck?.includes(checkedData.studentPic) || false,
            studentTshirtSize: isCheck?.includes(checkedData.studentTshirtSize) || false,
            studentExtracurricular: isCheck?.includes(checkedData.studentExtracurricular) || false,
            academicGrade: isCheck?.includes(checkedData.academicGrade) || false,
          },
          geoRegion: {
            geoRegion: isCheck?.includes(checkedData.geoRegion) || false,
            geoRegionName: isCheck?.includes(checkedData.geoRegionName) || false,
          },
          regionCoordinator: {
            regionCoordinatorName: isCheck?.includes(checkedData.regionCoordinatorName) || false,
            regionCoordinatorPhone: isCheck?.includes(checkedData.regionCoordinatorPhone) || false,
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { regionCoordinatorManabadiEmail: isCheck?.includes(checkedData.regionCoordinatorManabadiEmail) || false }),
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { regionCoordinatorPersonalEmail: isCheck?.includes(checkedData.regionCoordinatorPersonalEmail) || false }),
            regionCoordinatorNameSupport: isCheck?.includes(checkedData.regionCoordinatorNameSupport) || false,
            regionCoordinatorPhoneSupport: isCheck?.includes(checkedData.regionCoordinatorPhoneSupport) || false,
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { regionCoordinatorManabadiEmailSupport: isCheck?.includes(checkedData.regionCoordinatorManabadiEmailSupport) || false }),
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { regionCoordinatorPersonalEmailSupport: isCheck?.includes(checkedData.regionCoordinatorPersonalEmailSupport) || false }),
          },
          centerInfo: {
            address: isCheck?.includes(checkedData.centerAddress) || false,
            name: isCheck?.includes(checkedData.centerLoc) || false,
            state: isCheck?.includes(checkedData.centerState) || false,
          },
          locationCoordinator: {
            locationCoordinatorName: isCheck?.includes(checkedData.locationCoordinatorName) || false,
            locationCoordinatorPhone: isCheck?.includes(checkedData.locationCoordinatorPhone) || false,
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { locationCoordinatorManabadiEmail: isCheck?.includes(checkedData.locationCoordinatorManabadiEmail) || false }),
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { locationCoordinatorPersonalEmail: isCheck?.includes(checkedData.locationCoordinatorPersonalEmail) || false }),
            locationCoordinatorNameSupport: isCheck?.includes(checkedData.locationCoordinatorNameSupport) || false,
            locationCoordinatorPhoneSupport: isCheck?.includes(checkedData.locationCoordinatorPhoneSupport) || false,
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { locationCoordinatorManabadiEmailSupport: isCheck?.includes(checkedData.classTeacherManabadiEmailSecondary) || false }),
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { locationCoordinatorPersonalEmailSupport: isCheck?.includes(checkedData.classTeacherPersonalEmailSecondary) || false }),
          },
          classTeacher: {
            classTeacherName: isCheck?.includes(checkedData.classTeacherName) || false,
            classTeacherPhone: isCheck?.includes(checkedData.classTeacherPhone) || false,
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { classTeacherManabadiEmail: isCheck?.includes(checkedData.classTeacherManabadiEmail) || false }),
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { classTeacherPersonalEmail: isCheck?.includes(checkedData.classTeacherPersonalEmail) || false }),
            classTeacherNameSecondary: isCheck?.includes(checkedData.classTeacherNameSecondary) || false,
            classTeacherPhoneSecondary: isCheck?.includes(checkedData.classTeacherPhoneSecondary) || false,
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { classTeacherManabadiEmailSecondary: isCheck?.includes(checkedData.classTeacherManabadiEmailSecondary) || false }),
            ...(userRoles.SYSTEM_ADMIN === 'System Admin' && { classTeacherPersonalEmailSecondary: isCheck?.includes(checkedData.classTeacherPersonalEmailSecondary) || false }),
          },
          courseInfo: {
            courseName: isCheck?.includes(checkedData.courseName) || false,
            section: isCheck?.includes(checkedData.section) || false,
          },
          examDetails: {
            examLocation: isCheck?.includes(checkedData.examLocation) || false,
            q1mark: isCheck?.includes(checkedData.q1Marks) || false,
            q2mark: isCheck?.includes(checkedData.q2Marks) || false,
            q3mark: isCheck?.includes(checkedData.q3Marks) || false,
            q1homeWork: isCheck?.includes(checkedData.q1Homework) || false,
            q2homeWork: isCheck?.includes(checkedData.q2Homework) || false,
            q3homeWork: isCheck?.includes(checkedData.q3Homework) || false,
            s1mark: isCheck?.includes(checkedData.s1Marks) || false,
            s2mark: isCheck?.includes(checkedData.s2Marks) || false,
            s3mark: isCheck?.includes(checkedData.s3Marks) || false,
            s1homeWork: isCheck?.includes(checkedData.s1Homework) || false,
            s2homeWork: isCheck?.includes(checkedData.s2Homework) || false,
            s1quizMarks: isCheck?.includes(checkedData.s1Quiz) || false,
            s2quizMarks: isCheck?.includes(checkedData.s2Quiz) || false,
            s1assignment: isCheck?.includes(checkedData.s1Assignment) || false,
            s2assignment: isCheck?.includes(checkedData.s2Assignment) || false,
            s1coocurricular: isCheck?.includes(checkedData.s1Coocurricular) || false,
            s2coocurricular: isCheck?.includes(checkedData.s2Coocurricular) || false,
            bonusMark: isCheck?.includes(checkedData.bonusMarks) || false,
            averageScore: isCheck?.includes(checkedData.averageScore) || false,
            hallTicketLink: isCheck?.includes(checkedData.studentHallTicketLink) || false,
            hallTicketNumber: isCheck?.includes(checkedData.studentHallTicketNumber) || false,
            result: isCheck?.includes(checkedData.result) || false,
            s1ClassAttendance: isCheck?.includes(checkedData.s1ClassAttendance) || false,
            s2ClassAttendanceS2: isCheck?.includes(checkedData.S2ClassAttendance) || false,
          },
          parentInfo: {
            parent1Name: isCheck?.includes(checkedData.parent1FirstName) || false,
            parent1Email: isCheck?.includes(checkedData.parent1Email) || false,
            parent1Phone: isCheck?.includes(checkedData.parent1Phone) || false,
            parent2Name: isCheck?.includes(checkedData.parent2FirstName) || false,
            parent2Email: isCheck?.includes(checkedData.parent2Email) || false,
            parent2Phone: isCheck?.includes(checkedData.parent2Phone) || false,
            parent1Company: isCheck?.includes(checkedData.parent1Company) || false,
            parent1Profession: isCheck?.includes(checkedData.parent1Profession) || false,
            parent2Company: isCheck?.includes(checkedData.parent2Company) || false,
            parent2Profession: isCheck?.includes(checkedData.parent2Profession) || false,
            address: isCheck?.includes(checkedData.parent1Address) || false,
          },
        },
        fileName: isSelectedCheckbox?.length > 0 ? `student (${year}) - ${date}` : `user (${year}) - ${date}`,
        unselectedStudents,
    };
    if (isCheck?.length > 0) {
      // setLoading(true);
      payload.check = check;
      const userId = getLocalStorage('userId');
      payload.userId = userId;
      payload.checkMarks = checkMarks;
      payload.userRole = userRole;
      setLoading(true);
      dispatch(getExportedStudentData(payload, setLoading, setExportSuccessfull, setOpenCSVDialog));
    }
  };

  React.useEffect(() => {
    setCheck([]);
    setIsCheck([]);
    setIsCheckAll(false);
    setCheckMarks(false);
  }, [view]);

  const handleOkDialog = () => {
    setOkOpenDialog(false);
    setCheck([]);
    setIsCheckAll(false);
    refreshSort();
  };

  const handleExportOk = () => {
    setExportSuccessfull(false);
    setCheck([]);
    setIsCheckAll(false);
    refreshSort();
  };

  const handleErrorDialog = () => {
    setErrorOpenDialog(false);
    setLoading(false);
  };

  const handleCSVDialog = () => {
    setIsCheck([]);
    setCheck([]);
    setOpenCSVDialog(true);
  };
  const renderDownloadCSVFileContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1)': {
            '& .MuiTypography-root': {
              fontSize: '10px !important'
            }
          }
        }}
        noValidate
        autoComplete="off"
      >
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead} />
          <div className={classes.checkboxContent}>
            <Grid xs={12} sm={12} lg={4}>
              <CheckboxAtom
                label={t('SELECT_ALL')}
                id="acceptCheckbox"
                checked={isCheckAll}
                handleChange={(e) => handleSelectAll(e)}
              />
            </Grid>
          </div>
        </div>
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead}>
            <p className={classes.titleCheckbox}>
              {t('STUDENT_INFORMATION')}
              <span>:</span>
            </p>
          </Grid>
          <div className={classes.checkboxContent}>
            {userRole === userRoles.REGION_COORDINATOR ? RegionalPanelStudentInfo.map((obj) => (
              <Grid xs={12} sm={12} lg={4}>
                <CheckboxAtom
                  checked={isCheck.includes(obj.id)}
                  value={obj.id}
                  label={obj.label}
                  handleChange={(e) => handleCheck(e, obj)}
                  disabled={!isSelectedCheckbox?.length > 0}
                />
              </Grid>
            ))
              : StudentInfo.map((obj) => (
                <Grid xs={12} sm={12} lg={4}>
                  <CheckboxAtom
                    checked={isCheck.includes(obj.id)}
                    value={obj.id}
                    label={obj.label}
                    handleChange={(e) => handleCheck(e, obj)}
                    disabled={!isSelectedCheckbox?.length > 0}
                  />
                </Grid>
              ))}
          </div>
        </div>
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead}>
            <p className={classes.titleCheckbox}>
              {t('GEO_REGION')}
              <span>:</span>
            </p>
          </Grid>
          <div className={classes.checkboxContent}>
            {GeoRegion.map((obj) => (
              <Grid xs={12} sm={12} lg={4}>
                <CheckboxAtom
                  checked={isCheck.includes(obj.id)}
                  customClasses="dialogCheckbox"
                  value={obj.id}
                  label={obj.label}
                  handleChange={(e) => handleCheck(e, obj)}
                />
              </Grid>
            ))}
          </div>
        </div>
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead}>
            <p className={classes.titleCheckbox}>
              {t('REGION_COORDINATOR_INFO')}
              <span>:</span>
            </p>
          </Grid>
          <div className={classes.checkboxContent}>
            {userRole === userRoles.REGION_COORDINATOR ? RegionalPanelRegionCoordinator.map((obj) => (
              <Grid xs={12} sm={12} lg={4}>
                <CheckboxAtom
                  checked={isCheck.includes(obj.id)}
                  value={obj.id}
                  label={obj.label}
                  handleChange={(e) => handleCheck(e, obj)}
                />
              </Grid>
            ))
              : RegionCoordinator.map((obj) => (
                <Grid xs={12} sm={12} lg={4}>
                  <CheckboxAtom
                    checked={isCheck.includes(obj.id)}
                    value={obj.id}
                    label={obj.label}
                    handleChange={(e) => handleCheck(e, obj)}
                  />
                </Grid>
              ))}
          </div>
        </div>
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead}>
            <p className={classes.titleCheckbox}>
              {t('LOCATION_INFORMATION')}
              <span>:</span>
            </p>
          </Grid>
          <div className={classes.checkboxContent}>
            {CenterInfo.map((obj) => (
              <Grid xs={12} sm={12} lg={4}>
                <CheckboxAtom
                  checked={isCheck.includes(obj.id)}
                  customClasses="dialogCheckbox"
                  value={obj.id}
                  label={obj.label}
                  handleChange={(e) => handleCheck(e, obj)}
                />
              </Grid>
            ))}
          </div>
        </div>
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead}>
            <p className={classes.titleCheckbox}>
              {t('LOCATION_COORDINATOR_INFORMATION')}
              <span>:</span>
            </p>
          </Grid>
          <div className={classes.checkboxContent}>
            {userRole === userRoles.REGION_COORDINATOR ? RegionalPanelLocationCoordinator.map((obj) => (
              <Grid xs={12} sm={12} lg={4}>
                <CheckboxAtom
                  checked={isCheck.includes(obj.id)}
                  value={obj.id}
                  label={obj.label}
                  handleChange={(e) => handleCheck(e, obj)}
                />
              </Grid>
            ))
              : LocationCoordinator.map((obj) => (
                <Grid xs={12} sm={12} lg={4}>
                  <CheckboxAtom
                    checked={isCheck.includes(obj.id)}
                    value={obj.id}
                    label={obj.label}
                    handleChange={(e) => handleCheck(e, obj)}
                  />
                </Grid>
              ))}
          </div>
        </div>
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead}>
            <p className={classes.titleCheckbox}>
              {t('TEACHER_INFORMATION')}
              <span>:</span>
            </p>
          </Grid>
          <div className={classes.checkboxContent}>
            {userRole === userRoles.REGION_COORDINATOR ? RegionalPanelClassTeacher.map((obj) => (
              <Grid xs={12} sm={12} lg={4}>
                <CheckboxAtom
                  checked={isCheck.includes(obj.id)}
                  value={obj.id}
                  label={obj.label}
                  handleChange={(e) => handleCheck(e, obj)}
                />
              </Grid>
            ))
              : ClassTeacher.map((obj) => (
                <Grid xs={12} sm={12} lg={4}>
                  <CheckboxAtom
                    checked={isCheck.includes(obj.id)}
                    value={obj.id}
                    label={obj.label}
                    handleChange={(e) => handleCheck(e, obj)}
                  />
                </Grid>
              ))}
          </div>
        </div>
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead}>
            <p className={classes.titleCheckbox}>
              {t('COURSE_INFORMATION')}
              <span>:</span>
            </p>
          </Grid>
          <div className={classes.checkboxContent}>
            {CourseInfo.map((obj) => (
              <Grid xs={12} sm={12} lg={4}>
                <CheckboxAtom
                  checked={isCheck.includes(obj.id)}
                  value={obj.id}
                  label={obj.label}
                  handleChange={(e) => handleCheck(e, obj)}
                />
              </Grid>
            ))}
          </div>
        </div>
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead}>
            <p className={classes.titleCheckbox}>
              {t('EXAM_DETAILS')}
              <span>:</span>
            </p>
          </Grid>
          <div className={classes.checkboxContent}>
            {academicYear?.split('-')[0] > 2022 ? ExamDetailsSemesterBase.map((obj) => (
              <Grid xs={12} sm={12} lg={4}>
                <CheckboxAtom
                  checked={isCheck.includes(obj.id)}
                  value={obj.id}
                  label={obj.label}
                  handleChange={(e) => handleCheck(e, obj)}
                  disabled={!isSelectedCheckbox?.length > 0}
                />
              </Grid>
            ))
              : ExamDetails.map((obj) => (
                <Grid xs={12} sm={12} lg={4}>
                  <CheckboxAtom
                    checked={isCheck.includes(obj.id)}
                    value={obj.id}
                    label={obj.label}
                    handleChange={(e) => handleCheck(e, obj)}
                    disabled={!isSelectedCheckbox?.length > 0}
                  />
                </Grid>
              ))}
          </div>
        </div>
        <div className={classes.checkbox}>
          <Grid xs={6} sm={4} lg={2} className={classes.checkboxHead}>
            <p className={classes.titleCheckbox}>
              {t('PARENT_INFORMATION')}
              <span>:</span>
            </p>
          </Grid>
          <div className={classes.checkboxContent}>
            {userRole === userRoles.REGION_COORDINATOR ? RegionalPanelParentInfo.map((obj) => (
              <Grid xs={12} sm={12} lg={4}>
                <CheckboxAtom
                  checked={isCheck.includes(obj.id)}
                  value={obj.id}
                  label={obj.label}
                  handleChange={(e) => handleCheck(e, obj)}
                  disabled={!isSelectedCheckbox?.length > 0}
                />
              </Grid>
            ))
              : ParentInfo.map((obj) => (
                <Grid xs={12} sm={12} lg={4}>
                  <CheckboxAtom
                    checked={isCheck.includes(obj.id)}
                    value={obj.id}
                    label={obj.label}
                    handleChange={(e) => handleCheck(e, obj)}
                    disabled={!isSelectedCheckbox?.length > 0}
                  />
                </Grid>
              ))}
          </div>
        </div>

      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => {
            cancelExport();
          }}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          className={classes.popupBtn}
          name={t('EXPORT_TO_NEW_SHEET')}
          btntype={Buttons.PRIMARY}
          disabled={!isCheck?.length}
          onClick={handleExportToCsvMarks}
        />
      </Grid>
    </Grid>
  );

  const renderOkContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('STUDENT_CSV_EXPORTED_SUCCESSFULLY')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={handleOkDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderExportSuccessfull = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('STUDENT_CSV_EXPORTED_SUCCESSFULLY_NEW')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={handleExportOk}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderErrorContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('TOKEN_EXPIRED')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={handleErrorDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const sendBulkEmail = () => {
    const recipientList = getLocalStorage('adminBulkRecipientList')?.split(',');
    const emailList = getLocalStorage('adminBulkEmailStudents')?.split(',');
    const filtersList = [];
    selectedFilter?.forEach((obj) => {
      filtersList.push(obj);
    });
    let showSelectAll = false;
    if (recipientList?.length === 1 && recipientList?.[0] === '' && emailList?.length === 1 && emailList?.[0] === ''
      && filtersList?.length === 0) {
      showSelectAll = true;
    }
    setLocalStorage('showSelectAllinEmail', showSelectAll);
    setLocalStorage('moduleReturn', 'student');
    localStorage.removeItem('selectedLocation');
    localStorage.removeItem('selectedLocationName');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('showLocationFilterRecipients');
    if (recipientList === undefined) {
      dispatch({ type: Constant.RECIPIENTS, payload: recipientList });
      dispatch({ type: Constant.STUDENT_FILTERS, payload: filtersList });
      dispatch({ type: Constant.MAIL_PARENTS, payload: emailList });
      dispatch({ type: Constant.MAIL_FILTER, payload: 'AcademicYear' });
      navigate(NavigateRoutes.ADMIN_EMAIL);
    } else if (recipientList.filter((value) => value !== '').length) {
      dispatch({ type: Constant.RECIPIENTS, payload: recipientList });
      dispatch({ type: Constant.STUDENT_FILTERS, payload: [] });
      dispatch({ type: Constant.MAIL_PARENTS, payload: emailList });
      dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
      navigate(NavigateRoutes.ADMIN_EMAIL);
    } else if (!recipientList.filter((value) => value !== '').length) {
      dispatch({ type: Constant.RECIPIENTS, payload: recipientList });
      dispatch({ type: Constant.STUDENT_FILTERS, payload: filtersList });
      dispatch({ type: Constant.MAIL_PARENTS, payload: emailList });
      dispatch({ type: Constant.MAIL_FILTER, payload: 'AcademicYear' });
      navigate(NavigateRoutes.ADMIN_EMAIL);
    } else {
      dispatch({ type: Constant.RECIPIENTS, payload: recipientList });
      dispatch({ type: Constant.STUDENT_FILTERS, payload: filtersList });
      dispatch({ type: Constant.MAIL_PARENTS, payload: emailList });
      dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
      navigate(NavigateRoutes.ADMIN_EMAIL);
    }
  };

  const resetFilterView = (event) => {
    setFilterYear(event.target.value);
    if (event.target.value === '') {
      return;
    }
    filterByYearDropDown(event.target.value);
    dispatch(newAddedFilterView([]));
    dispatch(setFilterSelectedView(''));

    if (setFilterView) setFilterView('');
    resetSelectedFilter();
  };

  return (
    <div>
      <Grid>
        <Grid container spacing={2} className={classes.containerGrid}>
          <Grid item xs={5} sm={3} md={3} className={`${classes.alignGrid}`}>
            <DropdownStudents
              minWidth="100%"
              label={t('TIME_PERIOD')}
              id="filterYear"
              name="filterYear"
              value={filterYear}
              adminDashboard
              handleChange={resetFilterView}
              options={filterYearOptions}
            />
          </Grid>
          <Grid item xs={7} sm={9} md={5} className={classes.alignGrid}>
            <DropdownStudents
              adminDashboard
              minWidth="100%"
              label={t('VIEWS')}
              id="filterView"
              canDelete
              name="filterView"
              isStudentsFilter
              onDelete={(id) => {
                dispatch(deleteFilterView(id));
              }}
              className=""
              value={selectedView}
              handleChange={(e) => {
                if (!e.target.value) {
                  resetSelectedFilter();
                }
                setFilterYear('');
                setFilterView(e.target.value);
                dispatch(setFilterSelectedView(e.target.value));
                if (e.target.value === '') {
                  onGroupAutoCompleteSelection({}, []);
                  return;
                }
                const vi = studentFilterViews?.find(
                  (fi) => fi.name === e.target.value,
                );
                if (vi?.queryData) onGroupAutoCompleteSelection({}, JSON.parse(vi?.queryData));
              }}
              options={getFilterViewOptions()}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            className={classes.rightIcons}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Tooltip title={t('REFRESH')}>
              <IconButton onClick={() => { 
                  refreshSort();
                  window.location.reload(false);
                }}>
                <AutoRenewIcon />
              </IconButton>
            </Tooltip>
            <div className={classes.downloadIcon}>
              {userRole !== userRoles.SUPER_USER && (
                <Tooltip title={t('DOWNLOAD')} onClick={handleCSVDialog}>
                  <IconButton>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>

            <DialogAtom
              isOpen={issetopenCSVDialog}
              dialogHeading={t('STUDENT_EXPORT')}
              customClass={classes.studentExportDialogAtom}
              content={renderDownloadCSVFileContent()}
              secHandle={() => {
                cancelExport();
              }}
              loading={loading}
            />
            <DialogAtom
              isOpen={issetOkOpenDialog}
              dialogHeading={t('STUDENT_EXPORT')}
              customClass={classes.DialogAtom}
              content={renderOkContent()}
              secHandle={() => setOkOpenDialog(false)}
            />
            <DialogAtom
              isOpen={exportSuccesfull}
              dialogHeading={t('STUDENT_EXPORT')}
              customClass={classes.DialogAtom}
              content={renderExportSuccessfull()}
              secHandle={() => handleExportOk()}
            />
            <DialogAtom
              isOpen={issetErrorOpenDialog}
              dialogHeading={t('STUDENT_EXPORT')}
              customClass={classes.DialogAtom}
              content={renderErrorContent()}
              secHandle={() => setErrorOpenDialog(false)}
            />
            <Tooltip title={t('SEND_EMAIL')}>
              <IconButton
                className={classes.emailIcon}
                onClick={() => sendBulkEmail()}
              >
                <EmailOutlinedIcon />
              </IconButton>
            </Tooltip>
            <ToggleButtonGroup
              value={view}
              exclusive
              aria-label="text alignment"
              onChange={handleView}
              className={classes.toggleButtonGrp}
            >
              <ToggleButton
                value="listView"
                aria-label="listView"
                className={classes.toggleMainHeaderList}
                onClick={toggleList}
              >
                <ListViewIcon />
              </ToggleButton>
              <ToggleButton
                value="cardView"
                aria-label="cardView"
                className={classes.toggleButtonCard}
                onClick={toggleCard}
              >
                <GridViewIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(MainViewHeader);
