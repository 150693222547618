import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FormikProvider } from 'formik';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import { AntSwitch } from '../../../../utils/commonUiComponent';
import { style } from './style';
import logsIcon from '../../../../assets/images/logs-icon.png';
import { PerformantTextField, PerformantTextFieldCourseManager, PerfromantMultiValueDropdown } from '../../../../components/atoms';
import { limitDecimal } from '../../../../utils/methods';

function AddCourse({
  formik, setShowAddCourseDialog, showAddCourseDialog, getErrorText, status, setStatus,
  baseCourse, setBaseCourse, coursesData, courseExistErrorMessage, isEdit, viewLogs,
}) {
  const { t } = useTranslation();
  const classes = style();

  return (
    <Box className={classes.gridContainer}>
      <FormikProvider value={formik}>
        <form
          name="createCourse"
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item xs={6}>
              <PerformantTextFieldCourseManager
                label={t('COURSE_NAME')}
                allowSpecialCharacter
                id="courseName"
                required
                name="courseName"
                type="text"
                disabled={false}
                value={formik.values.courseName}
                onBlur={formik.handleBlur}
                error={getErrorText('courseName')}
                onChange={formik.handleChange}
                labelId={t('COURSE_NAME')}
              />
              <span className={classes.errorText}>
                {courseExistErrorMessage}
              </span>
            </Grid>
            <Grid item xs={6} md={3} alignItems="center">
              <Typography className={classes.switchHeading}>{t('BASE_COURSE')}</Typography>
              <Stack className={classes.switchUi} spacing={1}>
                <Typography className={`${classes.switchText} ${!baseCourse ? classes.inActiveText : null}`}>{t('NO')}</Typography>
                <AntSwitch checked={baseCourse} onChange={() => setBaseCourse(!baseCourse)} inputProps={{ 'aria-label': 'ant design' }} />
                <Typography className={`${classes.switchText} ${baseCourse ? classes.activeText : null}`}>{t('YES')}</Typography>
              </Stack>
              {getErrorText('Status')}
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography className={classes.switchHeading}>{t('STATUS')}</Typography>
              <Stack className={classes.switchUi} spacing={1}>
                <Typography className={`${classes.switchText} ${!status ? classes.inActiveText : null}`}>{t('INACTIVE_STATUS')}</Typography>
                <AntSwitch checked={status} onChange={() => setStatus(!status)} inputProps={{ 'aria-label': 'ant design' }} />
                <Typography className={`${classes.switchText} ${status ? classes.activeText : null}`}>{t('ACTIVE_STATUS')}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <PerformantTextFieldCourseManager
                label={t('FEE')}
                decimalLimit
                id="fee"
                required
                name="fee"
                type="number"
                textAlign="right"
                value={formik.values.fee}
                onBlur={(e) => {
                  const amount = limitDecimal(e);
                  formik.handleBlur(e);
                  if (
                    formik.values.fee < 0
                    || formik.values.fee === ''
                  ) {
                    formik.setFieldValue('fee', '');
                  } else {
                    formik.setFieldValue(
                      'fee',
                      amount,
                    );
                  }
                }}
                error={getErrorText('fee')}
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value === '-') {
                    formik.handleChange(e);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === '+' || event.key === '-' || event.keyCode === 69) {
                    event.preventDefault();
                  }
                }}
                labelId={t('FEE')}
                allowSpecialCharacter
              />
            </Grid>
            <Grid item xs={6}>
              <PerformantTextFieldCourseManager
                label={t('EXAM_FEE')}
                id="examFee"
                required
                name="examFee"
                type="number"
                textAlign="right"
                value={formik.values.examFee}
                onBlur={(e) => {
                  const amount = limitDecimal(e);
                  formik.handleBlur(e);
                  if (
                    formik.values.examFee < 0
                    || formik.values.examFee === ''
                  ) {
                    formik.setFieldValue('examFee', '');
                  } else {
                    formik.setFieldValue(
                      'examFee',
                      amount,
                    );
                  }
                }}
                error={getErrorText('examFee')}
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value === '-') {
                    formik.handleChange(e);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === '+' || event.key === '-' || event.keyCode === 69) {
                    event.preventDefault();
                  }
                }}
                labelId={t('EXAM_FEE')}
                allowSpecialCharacter
              />
            </Grid>
            <Grid item xs={6}>
              <PerformantTextFieldCourseManager
                label={t('REGISTRATION_FEE')}
                id="registrationFee"
                required
                name="registrationFee"
                type="number"
                textAlign="right"
                value={formik.values.registrationFee}
                onBlur={(e) => {
                  const amount = limitDecimal(e);
                  formik.handleBlur(e);
                  if (
                    formik.values.registrationFee < 0
                    || formik.values.registrationFee === ''
                  ) {
                    formik.setFieldValue('registrationFee', '');
                  } else {
                    formik.setFieldValue(
                      'registrationFee',
                      amount,
                    );
                  }
                }}
                error={getErrorText('registrationFee')}
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value === '-') {
                    formik.handleChange(e);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === '+' || event.key === '-' || event.keyCode === 69) {
                    event.preventDefault();
                  }
                }}
                labelId={t('REGISTRATION_FEE')}
                allowSpecialCharacter
              />

            </Grid>
            <Grid item xs={6}>
              <PerformantTextFieldCourseManager
                label={t('REPEATING_DISCOUNT')}
                id="repeatingDiscount"
                required
                name="repeatingDiscount"
                type="number"
                textAlign="right"
                value={formik.values.repeatingDiscount}
                onBlur={(e) => {
                  const amount = limitDecimal(e);
                  formik.handleBlur(e);
                  if (
                    formik.values.repeatingDiscount < 0
                    || formik.values.repeatingDiscount === ''
                  ) {
                    formik.setFieldValue('repeatingDiscount', '');
                  } else {
                    formik.setFieldValue(
                      'repeatingDiscount',
                      amount,
                    );
                  }
                }}
                error={getErrorText('repeatingDiscount')}
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value === '-') {
                    formik.handleChange(e);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === '+' || event.key === '-' || event.keyCode === 69) {
                    event.preventDefault();
                  }
                }}
                labelId={t('REPEATING_DISCOUNT')}
                allowSpecialCharacter
              />
            </Grid>
            <Grid item xs={6}>
              <PerformantTextFieldCourseManager
                label={t('SIBLING_DISCOUNT')}
                id="siblingDiscount"
                required
                name="siblingDiscount"
                type="number"
                textAlign="right"
                min={0}
                value={formik.values.siblingDiscount}
                onBlur={(e) => {
                  const amount = limitDecimal(e);
                  formik.handleBlur(e);
                  if (
                    formik.values.siblingDiscount < 0
                    || formik.values.siblingDiscount === ''
                  ) {
                    formik.setFieldValue('siblingDiscount', '');
                  } else {
                    formik.setFieldValue(
                      'siblingDiscount',
                      amount,
                    );
                  }
                }}
                error={getErrorText('siblingDiscount')}
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value === '-') {
                    formik.handleChange(e);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === '+' || event.key === '-' || event.keyCode === 69) {
                    event.preventDefault();
                  }
                }}
                labelId={t('SIBLING_DISCOUNT')}
                allowSpecialCharacter
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PerfromantMultiValueDropdown
                minWidth="100%"
                showLabel
                label={t('NEXT_POSSIBLE_COURSE')}
                id="nextPossibleCourse"
                name="nextPossibleCourse"
                value={formik.values.nextPossibleCourse}
                onBlur={formik.handleBlur}
                error={getErrorText('nextPossibleCourse')}
                handleChange={formik.handleChange}
                options={coursesData.map((per) => ({
                  id: per.id,
                  name: per.name,
                }))}
                labelId="nextPossibleCourse"
              />
              <span className={classes.errorText}>{getErrorText('nextPossibleCourse')}</span>
            </Grid>
            {isEdit && (
              <>
                <Grid item xs={12}>
                  <PerformantTextField
                    label={t('CHANGE_LOG')}
                    id="changeLogs"
                    required
                    name="changeLogs"
                    type="text"
                    disabled={false}
                    value={formik.values.changeLogs}
                    onBlur={formik.handleBlur}
                    error={getErrorText('changeLogs')}
                    onChange={formik.changeLogs}
                    labelId={t('CHANGE_LOG')}
                    allowSpecialCharacter
                  />
                </Grid>
                <Grid item xs={5} lg={6.5} md={6}>
                  <div
                    className={classes.logsIconSize}
                    onClick={() => viewLogs()}
                  >
                    <img src={logsIcon} width="20" alt="logsIcon" />
                    <span>{t('VIEW_LOGS')}</span>
                  </div>
                </Grid>
              </>
            )}
            <Grid item flex-end xs={7} md={6} lg={5.5}>
              <ButtonAtom
                name={t('CANCEL')}
                onClick={() => {
                  setShowAddCourseDialog(!showAddCourseDialog);
                  formik.setTouched({}, false);
                  setStatus(false);
                  setBaseCourse(false);
                }}
                btntype={Buttons.SECONDARY}
                className={classes.inViewLogButton}
              />
              <ButtonAtom
                name={t('SAVE')}
                onClick={formik.handleSubmit}
                btntype={Buttons.PRIMARY}
                className={classes.inViewLogButton}
              />
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Box>

  );
}
export default AddCourse;
