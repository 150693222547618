/* eslint-disable radix */
import { useCubeQuery } from '@cubejs-client/react';
import { getWeekOfMonth } from 'date-fns';
import moment from 'moment';
import chartTypes from '../pages/admin-user/dashboard/charts/types';
import { getWeekNumOfMonthOfDate } from '../utils/methods';

function isInRange(itemObj, regWeeks) {
  const date = itemObj.x.split(',')[0];
  const monthWeek = `${moment(date).format('MMMM')} - ${getWeekOfMonth(new Date(date))}`;

  if (regWeeks.includes(monthWeek)) {
    return true;
  }
  return false;
}

function getRegweeksValue(itemObj, chartType) {
  if (!itemObj) {
    return null;
  }
  const date = itemObj.createdAt;
  const monthWeek = getWeekNumOfMonthOfDate(new Date(date));
  let obj;
  if (chartType === chartTypes.ENROLLMENT_BREAKDOWN_BY_WEEK) {
    const truthyValue = itemObj['EnrolledCourse.returningStudent'];
    obj = {
      isReturning: truthyValue ? parseInt(itemObj['EnrolledCourse.count']) : 0,
      isNew: !truthyValue ? parseInt(itemObj['EnrolledCourse.count']) : 0,
      name: `${monthWeek?.month}  ${monthWeek?.week} Week`,
      flag: truthyValue,
      value: parseInt(itemObj['EnrolledCourse.count']),
      year: itemObj['EnrolledCourse.createdAt'],
    };
  } else if (chartType === chartTypes.COURSE_ENROLLMENT_BREAKDOWN) {
    obj = {
      flag: itemObj['EnrolledCourse.academicYear'],
      name: itemObj['Course.name'],
      value: parseInt(itemObj['EnrolledCourse.count']),
    };
  } else if (chartType === chartTypes.GEO_REGION_BREAK_DOWN) {
    obj = {
      name: itemObj['Region.geoRegion'],
      value: parseInt(itemObj['EnrolledCourse.count']),
    };
  } else if (chartType === chartTypes.MAP_VIEW) {
    obj = {
      country: itemObj['Region.country'],
      lat: itemObj['Address.latitude'],
      lng: itemObj['Address.longitude'],
      state: itemObj['Address.state'],
      value: parseInt(itemObj['EnrolledCourse.count']),
    };
  } else if (chartType === chartTypes.ENROLLMENT_GROWTH_ACROSS_YEAR) {
    obj = {
      name: itemObj['EnrolledCourse.academicYear'],
      year: itemObj['EnrolledCourse.academicYear'],
      value: parseInt(itemObj['EnrolledCourse.count']),
    };
  } else if (chartType === chartTypes.RETURNING_BREAKDOWN_ACROSS_YEAR) {
    const isReturningStudent = itemObj['EnrolledCourse.returningStudent'];
    obj = {
      isReturning: isReturningStudent ? parseInt(itemObj['EnrolledCourse.count']) : 0,
      isNew: !isReturningStudent ? parseInt(itemObj['EnrolledCourse.count']) : 0,
      value: parseInt(itemObj['EnrolledCourse.count']),
      flag: isReturningStudent ? 'isReturning' : 'isNew',
      name: itemObj['EnrolledCourse.academicYear'],
      year: itemObj['EnrolledCourse.academicYear'],
    };
  } else if (chartType === chartTypes.ENROLLMENT_BREAKDOWN_ACROSS_YEAR) {
    const current = new Date(itemObj?.createdAt);
    const dateValue = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
    obj = {
      date: dateValue,
      count: parseInt(itemObj['EnrolledCourse.count']),
      year: current.getFullYear(),
    };
  } else if (chartType === chartTypes.COURSE_ENROLLMENT_BREAKDOWN_ACROSS_YEAR) {
    obj = {
      courseName: itemObj['Course.name'],
      value: parseInt(itemObj['EnrolledCourse.count']),
      year: itemObj['EnrolledCourse.academicYear'],
    };
  } else if (chartType === chartTypes.COURSE_ENROLLMENT_BREAKDOWN_BY_REGION_ACROSS_YEAR) {
    obj = {
      region: itemObj['Region.geoRegion'],
      value: parseInt(itemObj['EnrolledCourse.count']),
      year: itemObj['EnrolledCourse.academicYear'],
    };
  } else if (chartType === chartTypes.RETURNING_BREAKDOWN) {
    const truthyValue = itemObj['EnrolledCourse.returningStudent'];
    obj = {
      isReturning: truthyValue ? parseInt(itemObj['EnrolledCourse.count']) : 0,
      isNew: !truthyValue ? parseInt(itemObj['EnrolledCourse.count']) : 0,
      name: itemObj['EnrolledCourse.returningStudent'].toString(),
      value: parseInt(itemObj['EnrolledCourse.count']),
      year: itemObj['EnrolledCourse.returningStudent'],
    };
  }

  return obj;
}

function getValue(itemObj, chartType, regWeeks = null) {
  let obj;
  const mapObj = itemObj?.x?.split(',');
  const mapCountryIndex = mapObj.findIndex((i) => i === 'USA' || i === 'United States' || i === 'Canada');
  if (chartType === chartTypes.ENROLLMENT_BREAKDOWN_BY_WEEK) {
    const xValue = itemObj?.x?.split(',');
    const date = xValue[0];
    const monthWeek = getWeekNumOfMonthOfDate(new Date(date));
    if (regWeeks && (regWeeks.length > 0)) {
      if (isInRange(itemObj, regWeeks)) {
        obj = {
          isReturning: itemObj?.x.includes(',true') ? itemObj.value : 0,
          isNew: itemObj?.x.includes(',false') ? itemObj.value : 0,
          name: `${monthWeek?.month}  ${monthWeek?.week} Week`,
          flag: xValue[1],
          value: itemObj.value,
          year: xValue[0],
        };
      } else {
        obj = null;
      }
    } else {
      obj = {
        isReturning: itemObj?.x.includes(',true') ? itemObj.value : 0,
        isNew: itemObj?.x.includes(',false') ? itemObj.value : 0,
        name: `${monthWeek?.month}  ${monthWeek?.week} Week`,
        flag: xValue[1],
        value: itemObj.value,
        year: xValue[0],
      };
    }
  } else if (
    chartType === chartTypes.MAP_VIEW && mapCountryIndex !== -1
  ) {
    if (regWeeks && (regWeeks.length > 0)) {
      if (isInRange(itemObj, regWeeks)) {
        obj = {
          value: itemObj.value,
          country: mapObj[mapCountryIndex] || '',
          state: mapObj[3],
          lat: Number(mapObj[4]),
          lng: Number(mapObj[5]),
        };
      } else {
        obj = null;
      }
    } else {
      obj = {
        value: itemObj.value,
        country: mapObj[mapCountryIndex] || '',
        state: mapObj[2],
        lat: Number(mapObj[3]),
        lng: Number(mapObj[4]),
      };
    }
  } else if (chartType === chartTypes.ENROLLMENT_BREAKDOWN_ACROSS_YEAR) {
    const current = new Date(itemObj?.x);
    const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
    if (regWeeks && (regWeeks.length > 0) && !isInRange(itemObj, regWeeks)) {
      obj = null;
    } else {
      obj = {
        date,
        count: itemObj.value,
        year: current.getFullYear(),
      };
    }
  } else if (chartType === chartTypes.COURSE_ENROLLMENT_BREAKDOWN_ACROSS_YEAR) {
    const xValue = itemObj?.x.split(',');
    if (regWeeks && (regWeeks.length > 0)) {
      if (isInRange(itemObj, regWeeks)) {
        obj = {
          value: itemObj.value || 0,
          courseName: xValue?.length ? xValue[1] : '',
          year: xValue?.length ? xValue[2] : '',
        };
      } else {
        obj = null;
      }
    } else {
      obj = {
        value: itemObj.value || 0,
        courseName: xValue?.length ? xValue[0] : '',
        year: xValue?.length ? xValue[1] : '',
      };
    }
  } else if (chartType === chartTypes.COURSE_ENROLLMENT_BREAKDOWN_BY_REGION_ACROSS_YEAR) {
    const xValue = itemObj?.x.split(',');
    if (regWeeks && (regWeeks.length > 0)) {
      if (isInRange(itemObj, regWeeks)) {
        obj = {
          year: xValue?.length ? xValue[1] : '',
          region: xValue?.length ? xValue[2] : '',
          value: itemObj?.value || 0,
        };
      } else {
        obj = null;
      }
    } else {
      obj = {
        year: xValue?.length ? xValue[0] : '',
        region: xValue?.length ? xValue[1] : '',
        value: itemObj?.value || 0,
      };
    }
  } else if (chartType === chartTypes.RETURNING_BREAKDOWN_ACROSS_YEAR) {
    const xValue = itemObj?.x.split(',');
    if (regWeeks && (regWeeks.length > 0)) {
      if (isInRange(itemObj, regWeeks)) {
        obj = {
          isReturning: itemObj?.x.includes(',true') ? itemObj.value : 0,
          isNew: itemObj?.x.includes(',false') ? itemObj.value : 0,
          value: itemObj?.value,
          flag: itemObj?.x.includes(',true') ? 'isReturning' : 'isNew',
          name: xValue[1],
          year: xValue[1],
        };
      } else {
        obj = null;
      }
    } else {
      obj = {
        isReturning: itemObj?.x.includes(',true') ? itemObj.value : 0,
        isNew: itemObj?.x.includes(',false') ? itemObj.value : 0,
        value: itemObj?.value,
        flag: itemObj?.x.includes(',true') ? 'isReturning' : 'isNew',
        name: xValue[0],
        year: xValue[0],
      };
    }
  } else if (chartType === 'studentStatus') {
    const xValue = itemObj?.x.split(',');
    obj = {
      name: xValue[0],
      value: itemObj.value,
    };
  } else if (chartType === 'MissingScoreBreakdown') {
    obj = {
      name: 'Q1',
      score: 12,
      notScore: 11,
    };
  } else {
    const xValue = itemObj?.x?.split(',');
    if (regWeeks && (regWeeks.length > 0)) {
      obj = {
        isReturning: itemObj?.x.includes(',true') ? itemObj.value : 0,
        isNew: itemObj?.x.includes(',false') ? itemObj.value : 0,
        name: xValue[1],
        flag: xValue[2],
        value: itemObj.value,
        year: xValue[1],
      };
    } else {
      obj = {
        isReturning: itemObj?.x.includes(',true') ? itemObj.value : 0,
        isNew: itemObj?.x.includes(',false') ? itemObj.value : 0,
        name: xValue[0],
        flag: xValue[1],
        value: itemObj.value,
        year: xValue[0],
      };
    }
  }
  return obj;
}
export default function useCustomCubeQuery(queryFromChart, chartType, regWeeks, createdDate, graphOption) {
  // use hook to grab data from Cube
  const {
    resultSet, isLoading, error, progress,
  } = useCubeQuery(queryFromChart);

  let chartData;
  if ((regWeeks.length > 0) && (createdDate.length === 0)) {
    const test = resultSet?.decompose().reduce((arr, currentResultSet) => {
      const series = currentResultSet.rawData().map((item) => {
        if (!item?.compareDateRange) {
          return null;
        }

        // eslint-disable-next-line prefer-destructuring
        item.createdAt = item.compareDateRange.split(' -')[0];
        return item;
      }).filter((item) => !!item);
      const allSeries = [...arr, ...series];
      return allSeries;
    }, []).flat();
    chartData = resultSet?.decompose().reduce((arr, currentResultSet) => {
      const series = currentResultSet.series(queryFromChart).map((data) => data.series);
      const allSeries = [...arr, ...series];
      return allSeries;
    }, []).flat();

    chartData = [test?.map((item) => {
      return getRegweeksValue(item, chartType);
    })].filter((value) => !!value);
  } else {
    chartData = resultSet
      ?.series(queryFromChart)
      ?.map((item) => item?.series?.map((obj) => getValue(obj, chartType, regWeeks, graphOption))
        .filter((value) => !!value));
  }

  return {
    chartData,
    resultSet,
    isLoading,
    error,
    progress,
  };
}
