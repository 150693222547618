import * as React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function BasicMenu({
  openDeactivateFileDialog,
  openEditFileDialog,
  grabVideoDetails,
  row,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={t('OPTIONS')} placement="top">
        <IconButton
          aria-label="menu"
          onClick={handleClick}
        >
          <MoreHorizIcon onClick={() => grabVideoDetails(row)} style={{ color: '#015EEA' }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          style={{ borderBottom: '0.1vw solid #d0d0d0', padding: '0.6vw' }}
          onClick={openEditFileDialog}
          onClickCapture={handleClose}
        >
          {t('EDIT')}
        </MenuItem>
        <MenuItem
          style={{ padding: '0.6vw' }}
          onClick={() => openDeactivateFileDialog()}
          onClickCapture={handleClose}
        >
          {t('DEACTIVATE')}
        </MenuItem>
      </Menu>
    </div>
  );
}
