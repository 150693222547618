import Constant from '../constant';

const initialState = {
  classrooms: [],
  classroomSections: [],
  assignedYears: [],
  assignedLocations: [],
  teachers: [],
  assignedYearsDetails: [],
  academicYears: [],
  academicYearsStatus: [],
  students: [],
  sectionDetails: [],
  locationCourses: [],
  studentResourceWRT: [],
  studentResourceWRTFolder: [],
  teacherResources: [],
  uploadFile: [],
  uploadVideo: [],
  emailFilters: [],
  announcementDetails: {},
  locationDetails: {},
  helpVideos: [],
  notScoredStudentTeachers: [],
  marksByQuarter: {},
  assignedAcademicYearDatesByYear: {},
  assignedAcademicYearDates: [],
  importSheetTime: false,
  exportSheetTime: false,
  createdAtSheetTime: false,
  totalCount: 0,
  totalHVideosCount: 0,
  teacherResourceInFolder: [],
  selectedYear: [],
  selectedLocations: [],
  selectedCourse: [],
  selectedQuarter: '',
  stuCount: 0,
  totalAnnouncementsCount: 0,
};

const getLocationCoordinator = (state = initialState, action = {}) => {
  switch (action.type) {
    case Constant.GET_CLASSROOMS:
      return {
        ...state,
        classrooms: action.payload,
      };
    case Constant.ADD_CLASSROOMS_SECTIONS:
      return {
        ...state,
        classroomSections: action.payload,
      };
    case Constant.SET_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.payload,
      };
    case Constant.SET_SELECTED_QUARTER:
      return {
        ...state,
        selectedQuarter: action.payload,
      };
    case Constant.SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocations: action.payload,
      };
    case Constant.SET_SELECTED_COURSE:
      return {
        ...state,
        selectedCourse: action.payload,
      };
    case Constant.GET_ASSIGNED_YEARS:
      return {
        ...state,
        assignedYears: action.payload,
      };
    case Constant.GET_ACADEMIC_YEARS:
      return {
        ...state,
        academicYears: action.payload,
      };
    case Constant.GET_ACADEMIC_YEARS_STATUS:
      return {
        ...state,
        academicYearsStatus: action.payload,
      };
    case Constant.POST_ACADEMIC_YEARS_STATUS:
      return {
        ...state,
        academicYearsStatus: action.payload,
      };

    case Constant.GET_ASSIGNED_LOCATIONS:
      return {
        ...state,
        assignedLocations: action.payload,
      };
    case Constant.GET_ALL_TEACHERS:
      return {
        ...state,
        teachers: action.payload,
      };
    case Constant.GET_LOCATION_DETAILS:
      return {
        ...state,
        locationDetails: action.payload,
      };
    case Constant.SET_STUDENTS:
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          students: action.payload,
        },
        stuCount: action.payload,
      };
    case Constant.RESET_STUDENT_COUNT:
      return {
        ...state,
        stuCount: action.payload,
      };
    case Constant.GET_ASSIGNED_YEARS_DETAILS:
      return {
        ...state,
        assignedYearsDetails: action.payload,
      };
    case Constant.GET_STUDENTS_BY_LOCATION:
      return {
        ...state,
        students: action.payload,
      };
    case Constant.GET_TEACHERS:
      return {
        ...state,
        notScoredStudentTeachers: action.payload,
      };
    case Constant.GET_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.payload,
      };
    case Constant.SET_SECTION_DETAILS:
      return {
        ...state,
        sectionDetails: action.payload,
      };
    case Constant.SET_IMPORT_TIME:
      return {
        ...state,
        importSheetTime: action.payload,
      };

    case Constant.SET_EXPORT_TIME:
      return {
        ...state,
        exportSheetTime: action.payload,
      };
    case Constant.SET_CREATED_SHEET_TIME:
      return {
        ...state,
        createdAtSheetTime: action.payload,
      };
    case Constant.SET_EXPORTED_SHEET_TIME:
      return {
        ...state,
        exportedAtSheetTime: action.payload,
      };
    case Constant.GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload,
      };
    case Constant.ANNOUNCEMENTS_COUNT:
      return {
        ...state,
        totalAnnouncementsCount: action.payload,
      };
    case Constant.GET_PAGINATED_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: state.announcements?.concat(action.payload),
      };
    case Constant.GET_ANNOUNCEMENT_BY_ID:
      return {
        ...state,
        announcementDetails: action.payload,
      };
    case Constant.GET_ALL_LOCATION_COURSE:
      return {
        ...state,
        locationCourses: action.payload,
      };
    case Constant.GET_ALL_STUDENT_RECOURCES_WRT:
      return {
        ...state,
        studentResourceWRT: action.payload === null ? [] : action.payload,
      };
    case Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER:
      return {
        ...state,
        studentResourceWRTFolder: action.payload,
      };
    case Constant.GET_PAGINATED_USERS:
      return {
        ...state,
        studentResourceWRT: state.studentResourceWRT?.concat(action.payload),
      };
    case Constant.GET_ALL_HELP_VIDEOS:
      return {
        ...state,
        helpVideos: action.payload,
      };
    case Constant.GET_PAGINATED_HELP_VIDEOS:
      return {
        ...state,
        helpVideos: state.helpVideos?.concat(action.payload),
      };
    case Constant.TOTAL_HVIDEOS_COUNT:
      return {
        ...state,
        totalHVideosCount: action.payload,
      };
    case Constant.GET_ALL_TEACHER_RECOURCES:
      return {
        ...state,
        teacherResources: action.payload,
      };
    case Constant.GET_PAGINATED_TEACHER_RECOURCES:
      return {
        ...state,
        teacherResources: state.teacherResources?.concat(action.payload),
      };
    case Constant.GET_TEACHER_RESOURCE_IN_FOLDER:
      return {
        ...state,
        teacherResourceInFolder: action.payload,
      };
    case Constant.TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case Constant.UPLOAD_FILE:
      return {
        ...state,
        uploadFile: action.payload,
      };
    case Constant.UPLOAD_VIDEO:
      return {
        ...state,
        uploadVideo: action.payload,
      };
    case Constant.GET_EMAIL_FILTERS:
      return {
        ...state,
        emailFilters: action.payload,
      };
    case Constant.GET_MARKS_BY_QUARTER:
      return {
        ...state,
        marksByQuarter: action.payload,
      };
    case Constant.GET_ACADEMIC_YEAR_DATES_BY_YEAR:
      return {
        ...state,
        assignedAcademicYearDatesByYear: action.payload,
      };
    case Constant.ACADEMIC_YEAR_DATES:
      return {
        ...state,
        assignedAcademicYearDates: action.payload,
      };
    default:
      return state;
  }
};

export default getLocationCoordinator;
