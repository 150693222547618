/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable indent */
/* eslint-disable no-restricted-globals */
/* eslint-disable object-curly-newline */
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AttachFile from '@mui/icons-material/AttachFile';
import {
  Box, Checkbox, FormControlLabel, Popover, Typography, Menu, MenuItem, Grid, IconButton, Chip, Tooltip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import FileIcon from '@mui/icons-material/AttachFileOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import VideoIcon from '@mui/icons-material/OndemandVideoOutlined';
import AddToDeiveIcon from '@mui/icons-material/AddToDriveOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import styles from './attachmentPickerStyles';
import mbPng from '../../../../assets/images/mb_png_icon.png';
import mbPdf from '../../../../assets/images/mb_pdf_icon.png';
import mbTxt from '../../../../assets/images/mb_txt_icon.png';
import mbDoc from '../../../../assets/images/mb_doc_icon.png';
import mbVideo from '../../../../assets/images/mb_video_icon.png';
import defaultIcon from '../../../../assets/images/defaultIcon.png';
import useStyles from '../../../../custom-hooks/useStyles';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import { getLocalStorage } from '../../../../utils/localStorageMethod';
import CheckboxesAtom from '../../../../components/atoms/checkbox';
import {
  uploadVideo, createStudentResource, uploadFile, createStudentResourceAssignment, getStudentResourceWRTCourseAP, uploadFileAssignment, uploadVideoAssignment, getStudentResourceCourse,
} from '../../../../store/actions/getResources';
import { DialogAtom } from '../../../../components/atoms';
import { colors } from '../../../../theme';

const textFieldInlineStyles = {
  fontSize: '12px',
};
let auxLetters;

const getFileType = (file, fileResourceName) => {
  const fileNameSplit = fileResourceName?.split('.')[1];
  const defaultName = fileResourceName?.split('.');
  if (fileNameSplit === undefined) {
    switch (file?.split('/')?.[1]) {
      case 'png':
        return file.split('/')[1];
      case 'jpg':
        return file.split('/')[1];
      case 'jpeg':
        return file.split('/')[1];
      case 'pdf':
        return file.split('/')[1];
      case 'Pdf':
        return file.split('/')[1];
      case 'octet-stream':
        return file.split('/')[1];
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        return file.split('/')[1];
      case 'plain':
        return file.split('/')[1];
      case 'msword':
        return file.split('/')[1];
      case 'txt':
        return file.split('/')[1];
      case 'webm':
        return file.split('/')[1];
      case 'doc':
        return file.split('/')[1];
      case 'docx':
        return file.split('/')[1];
      case 'ppt':
        return file.split('/')[1];
      case 'mp4':
        return file.split('/')[1];
      case 'mp3':
        return file.split('/')[1];
      case 'Mp3':
        return file.split('/')[1];
      case 'mov':
        return file.split('/')[1];
      default:
        return 'doc';
    }
  } else {
    switch (fileNameSplit.split(' ')[0]) {
      case 'png':
        return fileNameSplit.split(' ')[0];
      case 'jpg':
        return fileNameSplit.split(' ')[0];
      case 'jpeg':
        return fileNameSplit.split(' ')[0];
      case 'pdf':
        return fileNameSplit.split(' ')[0];
      case 'Pdf':
        return fileNameSplit.split(' ')[0];
      case 'octet-stream':
        return fileNameSplit.split(' ')[0];
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        return fileNameSplit.split(' ')[0];
      case 'plain':
        return fileNameSplit.split(' ')[0];
      case 'msword':
        return fileNameSplit.split(' ')[0];
      case 'txt':
        return fileNameSplit.split(' ')[0];
      case 'webm':
        return fileNameSplit.split(' ')[0];
      case 'doc':
        return fileNameSplit.split(' ')[0];
      case 'docx':
        return fileNameSplit.split(' ')[0];
      case 'ppt':
        return fileNameSplit.split(' ')[0];
      case 'mp4':
        return fileNameSplit.split(' ')[0];
      case 'mp3':
        return fileNameSplit.split(' ')[0];
      case 'Mp3':
        return fileNameSplit.split(' ')[0];
      case 'mov':
        return fileNameSplit.split(' ')[0];
      default:
        return defaultName[defaultName.length - 1];
    }
  }
};

const getCreatedByName = (details) => {
  return details ? `${details?.firstName || ''} ${details?.lastName || ''}` : '';
};

const getVideo = (callback) => {
  const gettoken = getLocalStorage('accessToken');
  const baseUrl = JSON.parse(gettoken);
  const xhr = new XMLHttpRequest();
  xhr.open('GET', 'https://www.googleapis.com/drive/v3/files?pageSize=1000&supportAllDrives=false&includeItemsFromAllDrives=false&q="root" in parents');
  xhr.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
  xhr.onload = () => {
    callback(xhr.responseText);
  };
  xhr.onerror = () => {
    callback(null);
  };
  xhr.send();
};

const RenderAttachmentPicker = React.forwardRef(({
  value, selectedCourseName, studentResources, handleChange, selectedCourse, studentResourceWRTFolder, nextPage, isFetching, setIsFetching, loadingResources, totalCount, totalCountNum, setLoadingResources, resources, viewOnly,
}, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles(styles)();

  const reduxStore = useSelector((state) => state?.getAdmin);
  const uploadFileResponse = reduxStore?.uploadFile;
  const uploadVideoResponse = reduxStore?.uploadVideo;
  const getCreateStudentResourceError = reduxStore?.createStudentResourceError;
  const getStudentResourceInFolderResponse = reduxStore?.studentResourcesByFolder;
  const createStudentResourceSuccess = reduxStore?.createStudentResource;
  const getFolderDetailsResponse = reduxStore?.folderDetails;

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedResouces, setSelectedResources] = useState([]);

  const [pagenum, setPagenum] = useState(1);
  const reduxTotalCount = useSelector((state) => state?.getLocationCoordinator.totalCount);

  const [issetUploadFileSize, setUploadFileSize] = useState();
  const [issetUploadFileSizeMessage, setUploadFileSizeMessage] = useState('');
  const [issetUploadFile, setUploadFile] = useState({});
  const [issetUploadFileName, setUploadFileName] = useState({});
  const [issetUploadFileExt, setUploadFileExt] = useState({});
  const [issetAddFileOpenDialog, setAddFileOpenDialog] = useState(false);
  const [addVideoError, setAddVideoError] = useState('');
  const [ischecked, setIsChecked] = useState(false);
  const [issetAddFolderOpenDialog, setAddFolderOpenDialog] = useState(false);
  const [issetUploadVideoSize, setUploadVideoSize] = useState();
  const [issetUploadVideoSizeMessage, setUploadVideoSizeMessage] = useState('');
  const [issetUploadVideo, setUploadVideo] = useState({});
  const [issetUploadVideoName, setUploadVideoName] = useState({});
  const [issetUploadVideoExt, setUploadVideoExt] = useState({});
  const [issetUploadVOpenDialog, setUploadVideoOpenDialog] = useState(false);
  const [issetSelectVideoFromDriveOpenDialog, setSelectVideoFromDriveOpenDialog] = useState(false);
  const [issetSelectVideoFromDrive, setSelectVideoFromDrive] = useState([]);
  const [issetSelectVideoFromDriveMessage, setSelectVideoFromDriveMessage] = useState('');
  const [isSelectedvalue, setSelectedValue] = useState('');
  const [selectedResource, setSelectedResource] = useState([]);
  const [uploadVideoSizeSuccess, setUploadVideoSizeSuccess] = useState('');
  const [isVideochecked, setIsVideoChecked] = useState(false);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [disabledCheck, setDisabledCheck] = useState(false);
  const [errorLargeVideo, setErrorLargeVideo] = useState(false);
  const [issetSelectVideoFromDriveFile, setSelectVideoFromDriveFile] = useState();

  const [uploadFileSizeSuccess, setUploadFileSizeSuccess] = useState('');
  const [addFileError, setAddFileError] = useState('');

  const [selected, setSelected] = useState([]);

  const [expandedNodes, setExpandedNodes] = useState([]);

  const icons = {
    png: <img src={mbPng} height="15" alt={t('ICON')} />,
    jpg: <img src={mbPng} height="15" alt={t('ICON')} />,
    jpeg: <img src={mbPng} height="15" alt={t('ICON')} />,
    webp: <img src={mbPng} height="15" alt={t('ICON')} />,
    pdf: <img src={mbPdf} height="15" alt={t('ICON')} />,
    Pdf: <img src={mbPdf} height="15" alt={t('ICON')} />,
    'octet-stream': <img src={mbDoc} height="15" alt={t('ICON')} />,
    'vnd.openxmlformats-officedocument.wordprocessingml.document': <img src={mbDoc} height="15" alt={t('ICON')} />,
    plain: <img src={mbTxt} height="15" alt={t('ICON')} />,
    msword: <img src={mbDoc} height="15" alt={t('ICON')} />,
    txt: <img src={mbTxt} height="15" alt={t('ICON')} />,
    webm: <img src={mbTxt} height="15" alt={t('ICON')} />,
    doc: <img src={mbDoc} height="15" alt={t('ICON')} />,
    docx: <img src={mbTxt} height="15" alt={t('ICON')} />,
    ppt: <img src={mbTxt} height="15" alt={t('ICON')} />,
    mp4: <img src={mbVideo} height="15" alt={t('ICON')} />,
    mov: <img src={mbVideo} height="20" alt={t('ICON')} />,
    mp3: <img src={mbVideo} height="15" alt={t('ICON')} />,
    Mp3: <img src={mbVideo} height="15" alt={t('ICON')} />,
    Folder: <img src={mbTxt} height="15" alt={t('ICON')} />,
  };

  const onSelectResourcesClick = () => {
    handleChange(selectedResouces);
    setAnchorEl(null);
    setOpen(false);
  };

  const refreshList = () => {
    setTimeout(() => {
      if (selectedCourse !== '' && selectedCourse !== undefined) {
        const payload = {
          courseId: selectedCourse,
          parentKey: selectedResource.selfKey,
        };
        if (!selectedResource.selfKey) {
          dispatch(getStudentResourceWRTCourseAP(1, 100, nextPage, payload, setIsFetching, setLoadingResources));
        } else {
          dispatch(getStudentResourceWRTCourseAP(1, 100, nextPage, payload, setIsFetching, setLoadingResources));
        }
      }
    }, 2000);
  };

  const loadMoreItems = (length) => {
    setIsFetching(true);
    let page = '';
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    const payload = {
      courseId: selectedCourse,
      parentKey: selectedResource.selfKey,
    };
    dispatch(getStudentResourceWRTCourseAP(page, 20, nextPage, payload, setIsFetching));
  };

  const buttonsResources = () => {
    if (isFetching) {
      return (
        <p className={classes.pText}>{t('FETCHING_RESOURCES')}</p>
      );
    } else if ((totalCountNum < totalCount) && (pagenum * 20 < totalCount)) {
      return (
        <ButtonAtom
          customCss={{
            fontSize: '12px',
            padding: 9,
            marginLeft: 10,
            minWidth: 80,
            height: '1.8vw',
          }}
          name={t('LOAD_MORE')}
          btntype={Buttons.SECONDARY}
          onClick={() => loadMoreItems(studentResources?.length)}
        />
      );
    } else {
      return (
        <ButtonAtom
          customCss={{
            fontSize: '12px',
            padding: 9,
            marginLeft: 10,
            minWidth: 80,
            height: '1.8vw',
          }}
          name={t('LOAD_MORE')}
          btntype={Buttons.DISABLE}
          disabled
          onClick={() => loadMoreItems(studentResources?.length)}
        />
      );
    }
  };

  const findObjectAuxName = (arr, name) => {
    for (const obj of arr) {
      if (obj.name === name) {
        return obj;
      } else if (obj.children !== undefined) {
        const nestedObj = findObjectAuxName(obj.children, name);
        if (nestedObj) return nestedObj;
      }
    }
  };

  const findObjectName = (obj, name) => findObjectAuxName(obj, name);
  const handleClick = (event) => {
    event?.preventDefault();
    if (value !== '') {
      const resourceArr = [];
      const nameResources = value.split(',');
      for (let index = 0; index < nameResources.length; index++) {
        resourceArr.push(findObjectName(studentResources, nameResources[index]));
      }
      setSelectedResources(resourceArr);
    }
    setOpen(true);
    setAnchorEl(event?.currentTarget);
  };
  const handleClickRef = useRef();
  useImperativeHandle(ref, () => ({
    handleClick,
    handleClickRef,
  }));

  const handleClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
    setOpen(false);
    setSelectedResources([]);
  };

  const handleClickAdd = (event) => {
    setAnchorElAdd(event.currentTarget);
  };

  const handleCloseAdd = () => {
    setAnchorElAdd(null);
  };

  useEffect(() => {
    setUploadFileSizeMessage(getCreateStudentResourceError?.msg);
    setUploadVideoSizeMessage(getCreateStudentResourceError?.msg);
    setSelectVideoFromDriveMessage(getCreateStudentResourceError?.msg);
  }, [getCreateStudentResourceError]);

  const openAdd = Boolean(anchorElAdd);

  const findObjectAux = (arr, id) => {
    for (const obj of arr) {
      if (obj.id === id) {
        return obj;
      } else if (obj.children !== undefined) {
        const nestedObj = findObjectAux(obj.children, id);
        if (nestedObj) return nestedObj;
      }
    }
  };

  const findObject = (obj, id) => findObjectAux(obj, id);

  const handleCheck = (checked, resourceId) => {
    setSelectedResources((oldState) => {
      let resourceArr = [...oldState];

      if (!checked) {
        resourceArr = resourceArr?.filter((resource) => resource.id !== resourceId);
        return resourceArr;
      }

      resourceArr.push(findObject(studentResources, resourceId));
      return resourceArr;
    });
  };

  const renderFileIcon = (fileType, name) => {
    if (fileType === 'Folder') {
      return <img src={mbTxt} height="15" alt={t('ICON')} />;
    } else {
      return icons[String(getFileType(fileType, name))];
    }
  };

  const handleAddfile = () => {
    if (issetUploadFileName !== '') {
      const filenames = issetUploadFileName;
      const filenamesScript = filenames.replace(/ /g, '_');
      const formData = new FormData();
      formData.append('file', issetUploadFile, filenamesScript);
      setLoadingResources(true);
      dispatch(uploadFile(formData));
      setLoadingResources(false);
      setIsVideoChecked(false);
    }
  };

  const handleSelectVideoFromDrive = () => {
    setLoadingUploadFile(true);
    const formData = new FormData();
    if (issetSelectVideoFromDriveFile.size > 30000000) {
      setErrorLargeVideo(true);
      setLoadingUploadFile(false);
      setTimeout(() => {
        setErrorLargeVideo(false);
      }, 2000);
      setIsVideoChecked(false);
    } else {
      formData.append('file', issetSelectVideoFromDriveFile);
      setLoadingResources(true);
      dispatch(uploadFile(formData));
      setLoadingUploadFile(false);
      setLoadingResources(false);
      setIsVideoChecked(false);
    }
  };

  const handleVideoCheck = (e) => {
    setIsVideoChecked(e.target.checked);
  };

  const handleAddvideo = () => {
    if (issetUploadVideoName !== '') {
      const videonames = `${issetUploadVideoName}.${issetUploadVideoExt}`;
      const filenamesScript = videonames.replace(/ /g, '_');
      const formData = new FormData();
      formData.append('video', issetUploadVideo, filenamesScript);
      setLoadingResources(true);
      dispatch(uploadVideo(formData, setSelectVideoFromDriveOpenDialog, setLoadingResources, setIsVideoChecked, setErrorLargeVideo));
    }
  };

  const onChangeValue = (e, obj, callback) => {
    setDisabledCheck(true);
    const fileId = e.target.value;
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      try {
        const filedata = new Blob([xhr.response], { type: obj?.mimeType });
        const file = new File([filedata], obj?.name.replace(/ /g, '_'));
        callback(file);
      } catch (err) {
        callback(null);
      }
    };
    xhr.onerror = () => {
      callback(null);
    };
    xhr.send();
  };

  useEffect(() => {
    if (uploadFileResponse?.message) {
      if (getFolderDetailsResponse) {
        const payload = {
          canDownload: ischecked,
          courseId: selectedCourse,
          fileSize: uploadFileResponse?.fileSize,
          fileType: uploadFileResponse?.fileType,
          fileUrl: uploadFileResponse?.url ? uploadFileResponse?.url.split(' ').join('') : '',
          isCoverPage: true,
          isPublished: true,
          resourceKind: 'student',
          resourceName: uploadFileResponse?.resourceName,
          parentKey: getFolderDetailsResponse.selfKey,
        };
        dispatch(createStudentResource(payload));
      } else {
        const payload = {
          canDownload: ischecked,
          courseId: selectedCourse,
          fileSize: uploadFileResponse?.fileSize,
          fileType: uploadFileResponse?.fileType,
          fileUrl: uploadFileResponse?.url ? uploadFileResponse?.url.split(' ').join('') : '',
          isCoverPage: true,
          isPublished: true,
          resourceKind: 'student',
          resourceName: uploadFileResponse?.resourceName,
        };
        dispatch(createStudentResource(payload));
      }
    }
  }, [uploadFileResponse?.message]);

  useEffect(() => {
    setUploadVideoSizeSuccess(uploadVideoResponse?.message);
    setTimeout(() => {
      setUploadVideoOpenDialog(false);
      setSelectVideoFromDriveOpenDialog(false);
      setUploadVideoSizeMessage('');
      refreshList();
      setSelectVideoFromDriveMessage('');
      setUploadVideoSizeSuccess('');
      setLoadingUploadFile(false);
      uploadVideoResponse.message = '';
    }, 1000);
  }, [createStudentResourceSuccess]);

  useEffect(() => {
    setUploadFileSizeSuccess(uploadFileResponse?.message);
    setTimeout(() => {
      setAddFileOpenDialog(false);
      refreshList();
      setUploadFileSizeMessage('');
      setUploadFileSizeSuccess('');
      uploadFileResponse.message = '';
    }, 200);
  }, [createStudentResourceSuccess]);

  useEffect(() => {
    if (uploadVideoResponse?.message) {
      if (getFolderDetailsResponse) {
        const payload = {
          canDownload: isVideochecked,
          courseId: selectedCourse,
          fileSize: uploadVideoResponse?.fileSize,
          fileType: uploadVideoResponse?.fileType,
          fileUrl: uploadVideoResponse?.url ? uploadVideoResponse?.url.split(' ').join('') : '',
          isCoverPage: true,
          isPublished: true,
          resourceKind: 'student',
          resourceName: uploadVideoResponse?.resourceName,
          parentKey: getFolderDetailsResponse.selfKey,
        };
        dispatch(createStudentResource(payload));
      } else {
        const payload = {
          canDownload: isVideochecked,
          courseId: selectedCourse,
          fileSize: uploadVideoResponse?.fileSize,
          fileType: uploadVideoResponse?.fileType,
          fileUrl: uploadVideoResponse?.url ? uploadVideoResponse?.url.split(' ').join('') : '',
          isCoverPage: true,
          isPublished: true,
          resourceKind: 'student',
          resourceName: uploadVideoResponse?.resourceName,
        };
        dispatch(createStudentResource(payload));
      }
    }
  }, [uploadVideoResponse?.message]);

  const handleSelectVideoFromDriveDialog = () => {
    setSelectVideoFromDriveOpenDialog(true);
    getVideo((e) => {
      const fileData = JSON.parse(e).files;
      const mineType = fileData;
      setSelectVideoFromDrive(mineType);
    });
  };

  const renderAddFileContent = () => (
    <Grid className={classes.addDialog}>
      {uploadFileSizeSuccess && (
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {uploadFileSizeSuccess}
          </div>
        </Grid>
      )}
      {issetUploadFileSizeMessage && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {issetUploadFileSizeMessage}
          </div>
        </Grid>
      )}
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className={classes.popupTextFieldFile}
            required
            id="outlined-required"
            label="File Name"
            value={issetUploadFileName}
            onChange={(e) => {
              setAddFileError('');
              if (e.target.value === '') {
                setAddFileError(t('FILE_NAME_CANNOT_BE_EMPTY'));
              }
              setUploadFileName(e.target.value);
            }}
            defaultValue="BalaBadi-I"
          />
        </div>
        <span className={classes.errorText}>{addFileError}</span>
        <div className={classes.checkbox}>
          <CheckboxesAtom
            label={t('USER_DOWNLOAD')}
            id="acceptCheckbox"
            checked={ischecked}
            handleChange={(e) => setIsChecked(e.target.checked)}
          />
          <p className={classes.dialogfooter}>
            {t('FILE_SIZE')}
            :
            &nbsp;
            {issetUploadFileSize}
            MB
          </p>
        </div>
        <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
          <ButtonAtom
            className={classes.popupBtn}
            name={t('CANCEL')}
            onClick={() => {
              setAddFileOpenDialog(false);
              setUploadFileSizeMessage('');
              setUploadVideoSizeMessage('');
              setSelectVideoFromDriveMessage('');
              setIsChecked(false);
              dispatch({
                type: 'UPLOAD_FILE',
                payload: [],
              });
            }}
            btntype={Buttons.SECONDARY}
          />
          <div className={issetUploadFileSize > 15 ? classes.popupBtnDisable : classes.popupBtn}>
            <ButtonAtom
              name={t('ADD')}
              onClick={handleAddfile}
              btntype={Buttons.PRIMARY}
              disabled={issetUploadFileSize > 15}
            />
          </div>
        </Grid>
      </Box>
    </Grid>
  );

  const renderUploadVideoContent = () => (
    <Grid className={classes.addDialog}>
      {uploadVideoSizeSuccess && (
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {uploadVideoSizeSuccess}
          </div>
        </Grid>
      )}
      {issetUploadVideoSizeMessage && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {issetUploadVideoSizeMessage}
          </div>
        </Grid>
      )}
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className={classes.popupTextField}
            required
            id="outlined-required"
            label="File Name"
            value={issetUploadVideoName}
            onChange={(e) => {
              setAddVideoError('');
              if (e.target.value === '') {
                setAddVideoError(t('FILE_NAME_CANNOT_BE_EMPTY'));
              }
              setUploadVideoName(e.target.value);
            }}
            defaultValue="BalaBadi-I"
          />
        </div>
        <span className={classes.errorText}>{addVideoError}</span>
        <div className={classes.checkbox}>
          <CheckboxesAtom
            label={t('USER_DOWNLOAD')}
            id="acceptCheckbox"
            checked={isVideochecked}
            handleChange={(e) => handleVideoCheck(e)}
          />
          <p className={classes.dialogfooter}>
            {t('FILE_SIZE')}
            &nbsp;
            :
            &nbsp;
            {issetUploadVideoSize}
            &nbsp;
            MB
          </p>
        </div>
        <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
          <ButtonAtom
            className={classes.popupBtn}
            name={t('CANCEL')}
            onClick={() => {
              setUploadVideoOpenDialog(false);
              setUploadVideoSizeMessage('');
              setUploadFileSizeMessage('');
              setSelectVideoFromDriveMessage('');
              dispatch({
                type: 'UPLOAD_VIDEO',
                payload: [],
              });
            }}
            btntype={Buttons.SECONDARY}
          />
          <div className={issetUploadVideoSize > 50 ? classes.popupBtnDisable : classes.popupBtn}>
            <ButtonAtom
              name={t('UPDATE')}
              onClick={handleAddvideo}
              btntype={Buttons.PRIMARY}
              disabled={issetUploadFileSize > 50}
            />
          </div>
        </Grid>
      </Box>
    </Grid>
  );

  const renderSelectVideoFromDriveContent = () => (
    <Grid>
      {loadingResources && (
        <Box sx={{
          display: 'flex', position: 'absolute', paddingLeft: '40%', paddingTop: '20%',
        }}
        >
          <CircularProgress />
        </Box>
      )}
      {errorLargeVideo && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {t('SIZE_VIDEO_DRIVE')}
          </div>
        </Grid>
      )}
      {disabledCheck && (
        <Grid>
          <div className={classes.alertFetching} role="alert">
            {t('FETCHING_RESOURCES')}
          </div>
        </Grid>
      )}
      {uploadVideoSizeSuccess && (
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {uploadVideoSizeSuccess}
          </div>
        </Grid>
      )}
      {issetSelectVideoFromDriveMessage && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {issetSelectVideoFromDriveMessage}
          </div>
        </Grid>
      )}
      {!loadingUploadFile && (
        <>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div style={{ height: '14vw', overflowY: 'auto', marginBottom: '0.9vw' }}>
              {issetSelectVideoFromDrive?.map((obj) => (
                <div className={classes.popupTextFieldMap}>
                  <input
                    disabled={disabledCheck}
                    type="radio"
                    value={obj?.id}
                    name="name"
                    onChange={(e) => onChangeValue(e, obj, (file) => {
                      if (file) {
                        setSelectVideoFromDriveFile(file);
                        setSelectVideoFromDriveMessage('');
                        setDisabledCheck(false);
                      }
                    })}
                  />
                  <span style={{ marginLeft: '0.5vw' }}>
                    {obj?.name.replace(/ /g, '_')}
                  </span>
                </div>
              ))}
            </div>
            <div className={classes.checkbox}>
              <CheckboxesAtom
                label={t('USER_DOWNLOAD')}
                id="acceptCheckbox"
                checked={isVideochecked}
                handleChange={(e) => handleVideoCheck(e)}
              />
            </div>
          </Box>
          <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupSelectDriveButton}`}>
            <div>
              <ButtonAtom
                className={classes.popupBtn}
                name={t('CANCEL')}
                onClick={() => {
                  setSelectVideoFromDriveOpenDialog(false);
                  setSelectVideoFromDriveFile();
                  setSelectVideoFromDriveMessage('');
                  setUploadVideoSizeMessage('');
                  setUploadFileSizeMessage('');
                  setIsVideoChecked(false);
                  dispatch({
                    type: 'UPLOAD_VIDEO',
                    payload: [],
                  });
                }}
                btntype={Buttons.SECONDARY}
              />
            </div>
            <div className={issetSelectVideoFromDriveFile ? classes.popupBtn : classes.popupBtnDisable}>
              <ButtonAtom
                name={t('ADD')}
                onClick={handleSelectVideoFromDrive}
                btntype={Buttons.PRIMARY}
                disabled={!issetSelectVideoFromDriveFile}
              />
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
  /* eslint-disable */
  const RenderTree = ({ nodes }) => {
    const handleCheckChange = (e) => handleCheck(e.target.checked, nodes.id);

    const checked = useMemo(
      () => selectedResouces.find((resource) => resource?.id === nodes?.id)?.id,
      [selectedResouces, nodes],
    );

    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={(
          <FormControlLabel
            control={(
              <div>
                {
                  nodes.fileType !== 'Folder' && nodes.fileType !== 'No Data'
                    ? <Checkbox onChange={handleCheckChange} checked={checked} />
                    : null
                }
              </div>
            )}
            label={(
              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: nodes.fileType !== 'Folder' ? '0px' : '10px' }}>
                {
                  nodes.fileType !== 'No Data' ? (
                    <>
                      {renderFileIcon(nodes.fileType, nodes.name)}
                      <Typography className={classes.itemLabels}>{nodes.name}</Typography>
                    </>
                  )
                    :
                    <>
                      <DoNotDisturbIcon color="primary" />
                      <Typography className={classes.itemLabels}>{t('NO_DATA')}</Typography>
                    </>
                }
              </div>
            )}
            key={nodes.id}
          />
        )}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => <RenderTree nodes={node} />)
          : null}
      </TreeItem>
    );
  };

  const handleDelete = (name) => {
    const chipIndex = resources.findIndex(
      (resource) => resource.name === name
    );

    if (chipIndex !== -1) {
      const updatedResources = [...resources];
      updatedResources.splice(chipIndex, 1);
      setSelectedResources(updatedResources);
      handleChange(updatedResources);
    }
  };

  function getFileExtension(fileName) {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      return fileName.slice(lastDotIndex);
    }
    return '';
  }

  function ChipSet() {
    const chipStyle = {
      fontSize: '10px',
      marginBottom: '3px',
    };

    const chips = value && value.split(',').map((chipValue, index) => {
      const trimmedValue = chipValue.trim();
      auxLetters += trimmedValue.length;
      const isTruncated = trimmedValue.length > 30;
    
      return (
        <Tooltip title={trimmedValue}>
          <Chip
            key={index}
            label={isTruncated ? `${trimmedValue.substr(0, 30)}...${getFileExtension(trimmedValue)}` : trimmedValue}
            style={chipStyle}
            onDelete={viewOnly !== true ? () => handleDelete(trimmedValue) : undefined}
          />
        </Tooltip>
      );
    });
    
    return chips ? <div>{chips}</div> : null;
    
  };

  const handleNodeToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  function RenderResourceItem() {
    if (studentResources.length !== 0) {
      return (
        <Box className={classes.resourceItem}>
          <Box className={classes.flexSeprator}>
            {loadingResources ? (
              <Grid>
                <Box
                  style={{
                    width: '519px',
                    height: '28vh',
                    display: 'flex',
                    justifyContent: 'center',
                    top: 0,
                    left: 0,
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress color="inherit" />
                  <h5>{t('LOADING')}</h5>
                </Box>
              </Grid>
            ) : (
              <TreeView
                aria-label="icon expansion"
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
                sx={{
                  height: 240,
                  flexGrow: 1,
                  maxWidth: 400,
                  position: 'relative',
                }}
                expanded={expandedNodes}
                onNodeToggle={handleNodeToggle}
              >
                {studentResources.map((node) => <RenderTree nodes={node} />)}
              </TreeView>
            )}
          </Box>
        </Box>
      );
    } else {
      return (
        <Grid container className={classes.noData}>
          <Grid item xs={12}>
            <DoNotDisturbIcon />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary">
              {t('NO_DATA')}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Box className={classes.attachementFormCont}>
      <Box ml="auto" className={classes.ChipsList}>
          <ChipSet value={value}/>
      </Box>
      <Popover
        id="attachment-resource-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{marginLeft: '58%'}}
      >
        <Box className={classes.popUpCont}>
          <Box className={classes.popUpHeader}>
            <Typography>
              {`${selectedCourseName || 'Course'} ${t('RESOURCES')}`}
            </Typography>
          </Box>
          <Box className={classes.addBtnCont}>
            <Grid container spacing={2} justifyContent="flex-end" direction="row">
              <div className={classes.divButtons}>
                <ButtonAtom
                  className={classes.addButton}
                  name={`${t('Add')}`}
                  btntype={Buttons.PRIMARY}
                  onClick={handleClickAdd}
                />
                {buttonsResources()}
              </div>
              <>
                <Menu
                  className={classes.Menubox}
                  anchorEl={anchorElAdd}
                  id="account-menu"
                  open={openAdd}
                  onClose={handleCloseAdd}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiMenuItem-root': {
                        borderBottom: '0.1vw solid #d0d0d0',
                        '@media (min-width: 1200px)': {
                          padding: '1vw',
                          fontSize: '0.9vw',
                        },
                        '@media (max-width: 600px)': {
                          fontSize: '2.9vw !important',
                        },
                        borderRadius: '0vw',
                        display: 'list-item',
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem
                    className={`${classes.addFileUpload}`}
                    variant="contained"
                    component="label"
                  >
                    <FileIcon />
                    {t('ADDFILE')}
                    <input
                      id="getFile"
                      type="file"
                      accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document/,image/*,.pdf,.txt,*"
                      hidden
                      onChange={(e) => {
                        const filesize = (e.target.files[0].size / (1024 * 1024)).toFixed(2);
                        const fileType = e.target.files[0].type;
                        setUploadFileSize(filesize);
                        if ((filesize > 15) && (fileType.includes('audio') || fileType.includes('.mp3'))) {
                          setUploadFileSizeMessage('Maximum 15 MB allowed on audio files.');
                        } else if ((filesize > 5) && !(fileType.includes('audio') || fileType.includes('.mp3'))) {
                          setUploadFileSizeMessage('Maximum 5 MB allowed on common files.');
                        }
                        setUploadFile(e.target.files[0]);
                        const file = (e.target.files[0].name);
                        const filename = file.split('.');
                        setUploadFileExt(filename.pop());
                        setUploadFileName(file);
                        setAddFileOpenDialog(true);
                        setAddVideoError('');
                        setIsChecked(false);
                        handleCloseAdd();
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    variant="contained"
                    component="label"
                  >
                    <VideoIcon />
                    {t('UPLOADVIDEO')}
                    <input
                      type="file"
                      accept=".mp4,.mp3,audio/*"
                      hidden
                      onChange={(e) => {
                        const videosize = (e.target.files[0].size / (1024 * 1024)).toFixed(2);
                        setUploadVideoSize(videosize);
                        if (videosize >= 30) {
                          setUploadVideoSizeMessage('Maximum 30 MB allowed.');
                        }
                        setUploadVideo(e.target.files[0]);
                        const video = (e.target.files[0].name);
                        const videoName = video.split('.');
                        setUploadVideoExt(videoName.pop());
                        setUploadVideoName(videoName[0]);
                        setUploadVideoOpenDialog(true);
                        setAddVideoError('');
                        setIsChecked(false);
                        handleCloseAdd();
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleSelectVideoFromDriveDialog();
                      handleCloseAdd();
                    }}
                    onClose={handleCloseAdd}
                  >
                    <AddToDeiveIcon />
                    {t('SELECT_FILE_FROM_DRIVE')}
                  </MenuItem>
                </Menu>
                <DialogAtom
                  isOpen={issetAddFileOpenDialog}
                  dialogHeading={t('ADDFILE')}
                  content={renderAddFileContent()}
                  secHandle={() => {
                    setAddFileOpenDialog(false);
                    setUploadFileSizeMessage('');
                    setUploadVideoSizeMessage('');
                    setSelectVideoFromDriveMessage('');
                    setIsChecked(false);
                    dispatch({
                      type: 'UPLOAD_FILE',
                      payload: [],
                    });
                  }}
                />
                <DialogAtom
                  isOpen={issetUploadVOpenDialog}
                  dialogHeading={t('UPLOADVIDEO')}
                  customClass={classes.DialogAtom}
                  content={renderUploadVideoContent()}
                  secHandle={() => {
                    setUploadVideoOpenDialog(false);
                    setUploadVideoSizeMessage('');
                    setUploadFileSizeMessage('');
                    setSelectVideoFromDriveMessage('');
                    dispatch({
                      type: 'UPLOAD_VIDEO',
                      payload: [],
                    });
                  }}
                />
                <DialogAtom
                  isOpen={issetSelectVideoFromDriveOpenDialog}
                  dialogHeading={t('SELECT_FILE_FROM_DRIVE')}
                  customClass={classes.DialogAtom}
                  content={renderSelectVideoFromDriveContent()}
                  secHandle={() => {
                    setSelectVideoFromDriveOpenDialog(false);
                    setSelectVideoFromDriveMessage('');
                    setUploadFileSizeMessage('');
                    setUploadVideoSizeMessage('');
                    setIsVideoChecked(false);
                    dispatch({
                      type: 'UPLOAD_VIDEO',
                      payload: [],
                    });
                  }}
                />
              </>
            </Grid>
          </Box>
          <Box className={classes.resourceList} marginTop={1} padding={3}>
            <RenderResourceItem />
          </Box>
          <Grid container spacing={2} justifyContent="flex-end" direction="row" style={{ paddingTop: '17px' }}>
            <Grid item xs={5}>
              <ButtonAtom
                name={t('CANCEL')}
                btntype={Buttons.SECONDARY}
                onClick={handleClose}
              />
            </Grid>
            <Grid item xs={5}>
              <ButtonAtom
                name={`${t('Select')} ${t('RESOURCES')}`}
                btntype={Buttons.PRIMARY}
                onClick={onSelectResourcesClick}
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
});

export default RenderAttachmentPicker;
