import { colors, fonts } from '../../../theme';

const style = ((theme) => ({
  addressAutoComplete: {
    border: `0.1vw solid ${colors.primary}`,
    background: 'white',
    borderRadius: '0.4vw',
    padding: '0.8vw',
    [theme.breakpoints.down('md')]: {
      borderRadius: '5px',
    },
    '@media (max-width: 1199px)': {
      padding: '16.5px 14px',
    },
    fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      padding: '0.7vw 1vw 0.7vw 0.7vw',
      fontSize: '0.9vw',
      fontFamily: `${fonts.fontType.roboto} !important`,
    },
    '&:hover': {
      border: `0.1vw solid ${colors.primary}`,
      outline: 'none',
    },
    '&:focus, &:focus-visible': {
      border: `0.1vw solid ${colors.primary}`, outline: 'none',
    },
    '&::placeholder': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      color: `${colors.placeHolderColor}!important`,
    },
  },
  customClass1: {
    border: `0.1vw solid ${colors.primary}`,
    background: 'white',
    borderRadius: '0.3vw',
    marginTop: '0px',
    height: '1.8vw',
    fontFamily: `${fonts.fontType.roboto} !important`,
    padding: '0.53vw !important',
    '@media (max-width: 600px)': {
      borderRadius: '1vw',
      height: '45px',
      fontSize: 'inherit',
      paddingLeft: '1.4vw !important',
    },
    [theme.breakpoints.down('md')]: {
    },
    fontSize: '0.9vw',
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      padding: '0.47vw !important',
      height: '1.8vw !important',
      outline: 'none',
    },
    '&:focus, &:focus-visible': {
      border: `0.2vw solid ${colors.primary} !important`,
      height: '1.5vw !important',
      outline: 'none',
    },
    '&::placeholder': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      color: `${colors.placeHolderColor}!important`,
    },
  },
  customClass2: {
    border: `0.1vw solid ${colors.primary}`,
    background: 'white',
    borderRadius: '0.3vw',
    marginTop: '0px',
    height: '1.9vw',
    fontFamily: `${fonts.fontType.roboto} !important`,
    padding: '0.45vw !important',
    '@media (max-width: 600px)': {
      borderRadius: '1vw',
      height: '45px',
      fontSize: 'inherit',
      paddingLeft: '1.4vw !important',
    },
    [theme.breakpoints.down('md')]: {
    },
    fontSize: '0.9vw',
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      height: '1.5vw !important',
      outline: 'none',
    },
    '&:focus, &:focus-visible': {
      border: `0.2vw solid ${colors.primary} !important`,
      height: '1.5vw !important',
      outline: 'none',
    },
    '&::placeholder': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      color: `${colors.placeHolderColor}!important`,
    },
  },
  innerCustomClassMobile: {
    border: `0.1vw solid ${colors.primary}`,
    background: 'white',
    borderRadius: '0.3vw',
    marginTop: '0px',
    height: '42px',
    fontFamily: `${fonts.fontType.roboto} !important`,
    padding: '0.4vw !important',
    '@media (max-width: 600px)': {
      borderRadius: '1vw',
      height: '45px',
      fontSize: 'inherit',
      paddingLeft: '3vw !important',
      '&:hover': {
        border: `0.2vw solid ${colors.primary} !important`,
        height: '46px',
        outline: 'none',
      },
      '&:focus, &:focus-visible': {
        border: `0.2vw solid ${colors.primary} !important`,
        height: '46px',
        outline: 'none',
      },
    },
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      height: '41px',
      outline: 'none',
    },
    '&:focus, &:focus-visible': {
      border: `0.2vw solid ${colors.primary} !important`,
      height: '41px',
      outline: 'none',
    },
    '&::placeholder': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      '@media (min-width: 1200px)': {
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      color: `${colors.placeHolderColor}!important`,
    },
  },
  formControl: {
    width: '100%',
    marginLeft: '0',
    marginRight: '0',
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      color: colors.black,
    },
  },
  addSiblingAddressAutoComplete: {
    border: `0.1vw solid ${colors.outline}`,
    background: 'white',
    borderRadius: '0.4vw',
    padding: '0.8vw',
    [theme.breakpoints.down('md')]: {
      borderRadius: '5px',
    },
    '@media (max-width: 1199px)': {
      padding: '16.5px 14px',
    },
    fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      padding: '0.7vw 1vw 0.7vw 0.7vw',
      fontSize: '0.9vw',
      fontFamily: `${fonts.fontType.roboto} !important`,
      height: '1.37vw',
    },
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',

    },
    '&:focus, &:focus-visible': {
      border: `0.1vw solid ${colors.primary}`, outline: 'none',
    },
    '&::placeholder': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      color: `${colors.placeHolderColor}!important`,
    },
  },
  addressStyle: {
    '& input': {
      borderColor: colors.primary,
    },
  },
  error: {
    border: `1px solid ${colors.errorText}`,
    '&:hover, &:focus, &:focus-visible': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
  },
}));

export default style;
