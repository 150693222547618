/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Dialog, DialogTitle, DialogActions, IconButton,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import SquareLogo from '../../assets/images/square.png';
import StripeLogo from '../../assets/images/stripe-seeklogo.svg';
import { useDispatch } from 'react-redux';
import { getAllCourses, createPaymentOrderStripe, createPaymentOrderSquare } from '../../store/actions/getStudent';
import { NavigateRoutes } from '../../constant';
import useStudent from '../../custom-hooks/useStudent';

// const loadSquareSdk = () => {
//   return new Promise((resolve) => {
//     const script = document.createElement('script');
//     script.src = 'https://sandbox.web.squarecdn.com/v1/square.js';
//     script.async = true;
//     script.onload = resolve;
//     document.body.appendChild(script);
//   });
// };

export default function CourseSelection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  // const [squareInstance, setSquareInstance] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false); // Add a processing state

  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  // useEffect(() => {
  //   // Load the Square script
  //   const script = document.createElement("script");
  //   script.src = "https://sandbox.web.squarecdn.com/v1/square.js";
  //   script.async = true;
  //   script.onload = async () => {
  //     if (window.Square) {
  //       initializeSquarePayment();
  //     }
  //   };
  //   document.body.appendChild(script);

  //   const initializeSquarePayment = async () => {
  //     const payments = window.Square.payments('sandbox-sq0idb--4XH2GdFEP5cLSFwvO5Mzg', 'NCNJ8H0T4Z1V');
  //     const cardInstance = await payments.card();
  //     await cardInstance.attach('#card-container');
  //     setSquareInstance(cardInstance);
  //   };

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  const { courses } = useStudent();

  const handleCourseChange = (courseId) => {
    setSelectedCourses((prevSelected) =>
      prevSelected.includes(courseId)
        ? prevSelected.filter((id) => id !== courseId)
        : [...prevSelected, courseId]
    );
  };

  const calculateTotal = () =>
    selectedCourses.reduce((total, courseId) => {
      const course = courses.find((course) => course.id === courseId);
      return total + (course ? course.fee.fee : 0);
    }, 0);

  const handleSend = () => {
    setOpenPaymentPopup(true);
  };

  const handlePaymentMethod = async (method) => {
    if (isProcessing) return; // Prevent multiple requests during processing
    setIsProcessing(true); // Set processing to true

    const total = calculateTotal();

    if (method === 'Square') {
      // if (!squareInstance) {
      //   alert('Square instance not initialized');
      //   setIsProcessing(false); // Reset processing state
      //   return;
      // }

      try {
        // const result = await squareInstance.tokenize();
        // if (result.status === 'OK') {
          const payload = {
            courses: courses.filter((course) => selectedCourses.includes(course.id)),
            total: total,
            method: method,
            // sourceId: result.token,  // Secure sourceId from Square
          };
          console.log("Sending payload:", payload);
          dispatch(createPaymentOrderSquare(payload, NavigateRoutes, navigate));
          setOpenPaymentPopup(false);
        // } else {
        //   console.error(result.errors);
        // }
      } catch (error) {
        console.error('Square payment failed', error);
      }
    } else {
      const payload = {
        courses: courses.filter((course) => selectedCourses.includes(course.id)),
        total: total,
        method: method,
      };
      console.log("Sending payload:", payload);
      dispatch(createPaymentOrderStripe(payload))
      setOpenPaymentPopup(false);
    }

    setIsProcessing(false); // Reset processing state after the payment is handled
  };

  return (
    <Box p={3}>
      <div id="card-container"></div>
      <Box mb={3}>
        <img
          src={logo}
          width={380}
          alt="ManaBadi SiliconAndhra Logo"
          onClick={() => window.location.href = '/'}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Choose Courses</Typography>
          {courses.map((course) => (
            <Box key={course.id} display="flex" alignItems="center">
              <Checkbox
                checked={selectedCourses.includes(course.id)}
                onChange={() => handleCourseChange(course.id)}
              />
              <Typography>{course.name}</Typography>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Payment Details</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Couse</TableCell>
                <TableCell align="right">Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedCourses.map((courseId) => {
                const course = courses.find((course) => course.id === courseId);
                return (
                  <TableRow key={courseId}>
                    <TableCell>{course.name}</TableCell>
                    <TableCell align="right">{course.fee.fee}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell><strong>Total</strong></TableCell>
                <TableCell align="right"><strong>{calculateTotal()}</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleSend} disabled={isProcessing}>
              {isProcessing ? 'Processing...' : 'Send'}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={openPaymentPopup} onClose={() => setOpenPaymentPopup(false)}>
      <Box textAlign="center">
        <DialogTitle>Choose a payment method</DialogTitle>
        <DialogActions>
          <Box display="flex" justifyContent="center">
            <IconButton onClick={() => handlePaymentMethod('Stripe')}>
              <img src={StripeLogo} alt="Stripe" width={50} height={50} />
            </IconButton>
            <IconButton onClick={() => handlePaymentMethod('Square')}>
              <img src={SquareLogo} alt="Square" width={50} height={50} />
              <span>Square</span>
            </IconButton>
          </Box>
        </DialogActions>
        <Button onClick={() => setOpenPaymentPopup(false)} color="primary">Cancel</Button>
        </Box>
      </Dialog>
      
    </Box>
  );
}
