import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropdown from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import style from './style';
import { toolTipTheme } from '../../../utils/commonUiComponent';

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function LabeledMultiSelect({
  handleChange, label, id, options = [], value, onBlur, error, onFocus, tooltip,
}) {
  const { t } = useTranslation();
  const classes = style();
  const [tooltipValues, setTooltipValues] = React.useState(false);
  const values = [];
  return (
    <div>
      <ThemeProvider theme={toolTipTheme}>
        <Tooltip title={tooltip ? tooltipValues : ''}>
          <FormControl className={classes.formControl} sx={{ m: 1, width: 300 }}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
              labelId={id}
              id={id}
              label={label}
              value={value}
              name={id}
              multiple
              IconComponent={ArrowDropdown}
              error={!!error}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value?.length === 0) {
                  setTooltipValues(false);
                }
              }}
              onBlur={onBlur}
              onFocus={onFocus}
              renderValue={(selected) => {
                selected.forEach((item) => {
                  const selectedItem = options.find((opt) => opt.id === item);
                  if (selectedItem) {
                    values.push(t(selectedItem.name));
                  }
                });
                setTooltipValues(values.join(', '));
                return values.join(', ');
              }}
              MenuProps={MenuProps}
            >
              {options.map((opt) => (
                <MenuItem key={opt.name} value={opt.id} className={classes.menuItem}>
                  <Checkbox checked={value && value.indexOf(opt.id) > -1} />
                  <ListItemText primary={t(opt.name)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>
      </ThemeProvider>
    </div>
  );
}
