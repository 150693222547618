export const exportUserColumns = [
  {
    id: 'name',
    label: 'User Name',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'profilePhoto',
    label: 'Profile Photo',
  },
  {
    id: 'manabadiEmail',
    label: 'Manabadi Email',
  },
  {
    id: 'roleNames',
    label: 'Role Names',
  },
  {
    id: 'personalEmail',
    label: 'Personal Email',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'contactNumber',
    label: 'Contact Number',
  },
  {
    id: 'gender',
    label: 'Gender',
  },
];

export default {
  exportUserColumns,
};
