export default {
  SET_IMPORT_TIME: 'SET_IMPORT_TIME',
  SET_VIEW: 'SET_VIEW',
  RESET_STUDENT_COUNT: 'RESET_STUDENT_COUNT',
  SET_FILTER_SELECTED_VIEW: 'SET_FILTER_SELECTED_VIEW',
  SET_EXPORT_TIME: 'SET_EXPORT_TIME',
  SET_EXPORTED_SHEET_TIME: 'SET_EXPORTED_SHEET_TIME',
  SET_ALL_EXPAND: 'SET_ALL_EXPAND',
  SET_LOADING: 'SET_LOADING',
  SET_SELECTED_QUARTER: 'SET_SELECTED_QUARTER',
  SET_USER_ROLE: 'SET_USER_ROLE',
  SET_USERS_SELECTED_FILTER: 'SET_USERS_SELECTED_FILTER',
  GET_USER_FILTERS: 'GET_USER_FILTERS',
  SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
  SET_FILTER_CHANGED: 'SET_FILTER_CHANGED',
  SET_ALL_FILTER_OPTIONS: 'SET_ALL_FILTER_OPTIONS',
  SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
  SET_ACTIVE_REGIONS: 'SET_ACTIVE_REGIONS',
  SET_CREATED_SHEET_TIME: 'SET_CREATED_SHEET_TIME',
  NEW_ADDED_FILTER_VIEW: 'NEW_ADDED_FILTER_VIEW',
  GET_REGION_MANAGER_LOGS: 'GET_REGION_MANAGER_LOGS',
  GET_REGION_MANAGER_LOGS_DETAILS: 'GET_REGION_MANAGER_LOGS_DETAILS',
  GET_LOCATION_MANAGER_LOGS: 'GET_LOCATION_MANAGER_LOGS',
  GET_LOCATION_MANAGER_LOGS_DETAILS: 'GET_LOCATION_MANAGER_LOGS_DETAILS',
  GET_COURSE_MANAGER_LOGS: 'GET_COURSE_MANAGER_LOGS',
  GET_COURSE_MANAGER_LOGS_DETAILS: 'GET_COURSE_MANAGER_LOGS_DETAILS',
  GET_TSHIRTS: 'GET_TSHIRTS',
  SET_TERMS_SETTINGS: 'SET_TERMS_SETTINGS',
  WELCOME_EMAIL_TEMPLATE: 'WELCOME_EMAIL_TEMPLATE',
  GET_ALL_COURSES: 'GET_ALL_COURSES',
  GET_ALL_YEARS: 'GET_ALL_YEARS',
  GET_EXPORTED_SHEETS: 'GET_EXPORTED_SHEETS',
  GET_COURSES_AGE_LOCATION: 'GET_COURSES_AGE_LOCATION',
  GET_STUDENT_LOGS: 'GET_STUDENT_LOGS',
  GET_STUDENT_LOG_DETAIL: 'GET_STUDENT_LOG_DETAIL',
  GET_ACADEMIC_GRADES: 'GET_ACADEMIC_GRADES',
  GET_ACADEMIC_YEARS: 'GET_ACADEMIC_YEARS',
  GET_CURRENT_ACADEMIC_YEAR: 'GET_CURRENT_ACADEMIC_YEAR',
  GET_CURRENT_DOB_CUTOFF: 'GET_CURRENT_DOB_CUTOFF',
  GET_REGISTRATION_ACADEMIC_YEAR: 'GET_REGISTRATION_ACADEMIC_YEAR',
  GET_REGISTRATION_TEMP_INFO: 'GET_REGISTRATION_TEMP_INFO',
  GET_REGISTRATION_DOB_CUTOFF: 'GET_REGISTRATION_DOB_CUTOFF',
  GET_REGISTRATION_ACADEMIC_YEAR_DATES: 'GET_REGISTRATION_ACADEMIC_YEAR_DATES',
  GET_EXTRA_CURRICULAR_ACTIVITIES: 'EXTRA_CURRICULAR_ACTIVITIES',
  GET_LOCATIONS: 'GET_LOCATIONS',
  GET_STUDENT_ACCOUNT_DETAILS: 'GET_STUDENT_ACCOUNT_DETAILS',
  GET_STUDENT_COURSE_DETAILS: 'GET_STUDENT_COURSE_DETAILS',
  ADD_SIBLING_DATA: 'ADD_SIBLING_DATA',
  GET_EXAM_CENTER_MANAGER_LOGS: 'GET_EXAM_CENTER_MANAGER_LOGS',
  GET_EXAM_CENTER_MANAGER_LOGS_DETAILS: 'GET_EXAM_CENTER_MANAGER_LOGS_DETAILS',
  REMOVE_EXAM_CENTER_MANAGER_LOGS_DETAILS: 'REMOVE_EXAM_CENTER_MANAGER_LOGS_DETAILS',
  GET_GRADES: 'GET_GRADES',
  // Parent info
  GET_VOLUNTEERS: 'GET_VOLUNTEERS',
  // Payment info
  GET_DONATIONS: 'GET_DONATIONS',
  GET_TOKEN_FOR_LOGIN: 'GET_TOKEN_FOR_LOGIN',
  HEAR_ABOUTS_US: 'HEAR_ABOUTS_US',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  POST_REGISTER: 'POST_REGISTER',
  POST_FEE_STRUCTURE: 'POST_FEE_STRUCTURE',
  POST_FORGOT_PASSWORD: 'POST_FORGOT_PASSWORD',
  POST_RESET_PASSWORD: 'POST_RESET_PASSWORD',
  CAPTURE_ORDER: 'CAPTURE_ORDER',
  CAPTURE_DONATION: 'CAPTURE_DONATION',
  // Student info
  GET_ALL_STUDENTS: 'GET_ALL_STUDENTS',
  TOTAL_COUNT: 'TOTAL_COUNT',
  GET_PAGINATED_STUDENTS: 'GET_PAGINATED_STUDENTS',
  GET_PAGINATED_USERS: 'GET_PAGINATED_USERS',
  MOVE_STUDENT: 'MOVE_STUDENT',
  SET_STUDENT_PAYMENT_INFO: 'SET_STUDENT_PAYMENT_INFO',
  SET_FILTER_VIEWS: 'SET_FILTER_VIEWS',
  SET_FILTERS: 'SET_FILTERS',
  SET_ALL_FILTERS: 'SET_ALL_FILTERS',
  SET_EXPANDED_LABELS: 'SET_EXPANDED_LABELS',
  SET_NEXT_ENROLL_COURSE: 'SET_NEXT_ENROLL_COURSE',
  SET_NEXT_ENROLL_COURSE_LIST: 'SET_NEXT_ENROLL_COURSE_LIST',
  SET_FEE_STRUCTURE_FOR_ENROLL: 'SET_FEE_STRUCTURE_FOR_ENROLL',
  SET_STUDENT_ENROLLMENT_DETAILS: 'SET_STUDENT_ENROLLMENT_DETAILS',
  CLEAN_STUDENT_ENROLLMENT_DETAILS: 'CLEAN_STUDENT_ENROLLMENT_DETAILS',
  GET_ALL_USER_FILTERS: 'GET_ALL_USER_FILTERS',
  FROM_MODULE: 'FROM_MODULE',
  // Location- coordinator
  GET_CLASSROOMS: 'GET_CLASSROOMS',
  ADD_CLASSROOMS_SECTIONS: 'ADD_CLASSROOMS_SECTIONS',
  GET_ASSIGNED_YEARS: 'GET_ASSIGNED_YEARS',
  GET_ASSIGNED_LOCATIONS: 'GET_ASSIGNED_LOCATIONS',
  GET_ALL_TEACHERS: 'GET_ALL_TEACHERS',
  GET_LOCATION_DETAILS: 'GET_LOCATION_DETAILS',
  GET_TEACHERS: 'GET_TEACHERS',
  GET_ASSIGNED_YEARS_DETAILS: 'GET_ASSIGNED_YEARS_DETAILS',
  GET_STUDENTS_BY_LOCATION: 'GET_STUDENTS_BY_LOCATION',
  GET_EMAIL_TEMPLATES: 'GET_EMAIL_TEMPLATES',
  SET_SECTION_DETAILS: 'SET_SECTION_DETAILS',
  GET_ANNOUNCEMENTS: 'GET_ANNOUNCEMENTS',
  ANNOUNCEMENTS_COUNT: 'ANNOUNCEMENTS_COUNT',
  GET_PAGINATED_ANNOUNCEMENTS: 'GET_PAGINATED_ANNOUNCEMENTS',
  GET_ANNOUNCEMENT_BY_ID: 'GET_ANNOUNCEMENT_BY_ID',
  GET_EMAIL_FILTERS: 'GET_EMAIL_FILTERS',
  GET_SUBSTITUTION_TAGS: 'GET_SUBSTITUTION_TAGS',
  SET_MAIL_SUBJECT: 'SET_MAIL_SUBJECT',
  SET_MAIL_BODY: 'SET_MAIL_BODY',
  SEND_ANNOUNCEMENTS: 'SEND_ANNOUNCEMENTS',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  RECIPIENTS: 'RECIPIENTS',
  RECIPIENT_OPTIONS: 'RECIPIENT_OPTIONS',
  EMAIL_LIST: 'EMAIL_LIST',
  GET_ALL_LOCATION_COURSE: 'GET_ALL_LOCATION_COURSE',
  GET_ALL_TEACHER_RECOURCES: 'GET_ALL_TEACHER_RECOURCES',
  GET_ALL_STUDENT_RECOURCES_WRT: 'GET_ALL_STUDENT_RECOURCES_WRT',
  GET_ALL_STUDENT_RECOURCES_WRT_FOLDER: 'GET_ALL_STUDENT_RECOURCES_WRT_FOLDER',
  GET_ALL_HELP_VIDEOS: 'GET_ALL_HELP_VIDEOS',
  TOTAL_HVIDEOS_COUNT: 'TOTAL_HVIDEOS_COUNT',
  GET_PAGINATED_HELP_VIDEOS: 'GET_PAGINATED_HELP_VIDEOS',
  SET_SELECTED_YEAR: 'SET_SELECTED_YEAR',
  SET_SELECTED_LOCATION: 'SET_SELECTED_LOCATION',
  SET_SELECTED_COURSE: 'SET_SELECTED_COURSE',
  // Admin - User Manager
  GET_ALL_USER: 'GET_ALL_USER',
  GET_ALL_EMAIL_USERS: 'GET_ALL_EMAIL_USERS',
  GET_ALL_USER_ROLES: 'GET_ALL_USER_ROLES',
  SET_USER_EXPANDED_LABELS: 'SET_USER_EXPANDED_LABELS',
  SET_ALL_USER_EXPAND: 'SET_ALL_USER_EXPANDED',
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_VIDEO: 'UPLOAD_VIDEO',
  ADMIN_GRAPHS: 'ADMIN_GRAPHS',
  CREATE_STUDENT_RESOURCE: 'CREATE_STUDENT_RESOURCE',
  CREATE_STUDENT_RESOURCE_ERROR: 'CREATE_STUDENT_RESOURCE_ERROR',
  CANCEL_ENROLLMENT: 'CANCEL_ENROLLMENT',
  IMPERSONATE_USER: 'IMPERSONATE_USER',
  UPLOAD_HELP_VIDEO: 'UPLOAD_HELP_VIDEO',
  GET_STUDENT_RESOURCE_IN_FOLDER: 'GET_STUDENT_RESOURCE_IN_FOLDER',
  GET_TEACHER_RESOURCE_IN_FOLDER: 'GET_TEACHER_RESOURCE_IN_FOLDER',
  GET_FOLDER_DETAILS: 'GET_FOLDER_DETAILS',
  // Admin- Role Manager
  SET_ALL_ROLE: 'SET_ALL_ROLE',
  SET_ALL_ROLE_PERMISSIONS: 'SET_ALL_ROLE_PERMISSIONS',
  MAIL_FILTER: 'MAIL_FILTER',
  SET_GRAPH_OPTIONS: 'SET_GRAPH_OPTIONS',
  SET_ENROLL_GRAPH_OPTIONS: 'SET_ENROLL_GRAPH_OPTIONS',
  MAIL_PARENTS: 'MAIL_PARENTS',
  STUDENT_FILTERS: 'STUDENT_FILTERS',
  GET_MARKS_BY_QUARTER: 'GET_MARKS_BY_QUARTER',
  GET_ROLE_CHANGELOGS_LIST: 'GET_ROLE_CHANGELOGS_LIST',
  GET_ROLE_CHANGELOGS_DETAILS: 'GET_ROLE_CHANGELOGS_DETAILS',
  GET_REPORTS_LIST: 'GET_REPORTS_LIST',

  // Admin- Region Manager
  SET_ALL_REGION: 'SET_ALL_REGION',
  TOTAL_REGION_COUNT: 'TOTAL_REGION_COUNT',
  GET_PAGINATED_REGIONS: 'GET_PAGINATED_REGIONS',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_REGION_COORDINATOR: 'SET_REGION_COORDINATOR',
  SET_GEO_REGION: 'SET_GEO_REGION',

  // Teacher View
  STUDENTS_BY_YEAR_AND_CLASSID: 'STUDENTS_BY_YEAR_AND_CLASSID',
  GET_COURSES_MANAGER: 'GET_COURSES_MANAGER',
  GET_COURSES_MANAGER_PAGINATION: 'GET_COURSES_MANAGER_PAGINATION',
  TOTAL_COURSES_MANAGER: 'TOTAL_COURSES_MANAGER',
  CLASSES_TIMINGS: 'CLASSES_TIMINGS',
  CLASSES_TIMINGS_STUDENTS: 'CLASSES_TIMINGS_STUDENTS',
  CLASSES_BY_YEAR: 'CLASSES_BY_YEAR',
  GET_TEACHER_PANEL_STUDENTS: 'GET_TEACHER_PANEL_STUDENTS',
  STUDENT_ATTENDANCE_DETAILS: 'STUDENT_ATTENDANCE_DETAILS',
  GET_STUDENTS_BY_LOCATION_MARKS: 'GET_STUDENTS_BY_LOCATION_MARKS',
  SET_SELECTED_CLASSROOMS: 'SET_SELECTED_CLASSROOMS',

  // Location manager
  GET_ALL_LOCATIONS: 'GET_ALL_LOCATIONS',
  SET_STUDENTS: 'SET_STUDENTS',
  GET_ALL_EXAM_CENTERS: 'GET_ALL_EXAM_CENTERS',
  GET_ALL_LOCATION_COORDINATORS: 'GET_ALL_LOCATION_COORDINATORS',

  // Admin Academic Dates
  GET_ACADEMIC_YEAR_DATES_BY_YEAR: 'GET_ACADEMIC_YEAR_DATES_BY_YEAR',
  GET_ACADEMIC_YEAR_DATES: 'GET_ACADEMIC_YEAR_DATES',
  ACADEMIC_YEAR_DATES: 'ACADEMIC_YEAR_DATES',
  POST_ACADEMIC_YEARS_STATUS: 'POST_ACADEMIC_YEARS_STATUS',
  GET_ACADEMIC_YEARS_STATUS: 'GET_ACADEMIC_YEARS_STATUS',
  // Terms and condition
  Get_Editor_details: 'Get_Editor_details',
  // Academic Panel
  GET_ACADEMIC_SCHEMA: 'GET_ACADEMIC_SCHEMA',
  GET_QUARTER_DETAILS: 'GET_QUARTER_DETAILS',
  GET_ALL_TOPICS: 'GET_ALL_TOPICS',
  GET_ASSIGNMENT_PER_COURSE: 'GET_ASSIGNMENT_PER_COURSE',
  SET_FILTER_STATUS: 'SET_FILTER_STATUS',
  // Homework schema
  GET_HOMEWORK_SCHEMA: 'GET_HOMEWORK_SCHEMA',

  // Quiz schema
  SAVE_QUIZ_SCHEMA: 'SAVE_QUIZ_SCHEMA',
  GET_QUIZ_SCHEMA: 'GET_QUIZ_SCHEMA',

  // Exam Center Manager
  GET_EXAM_CENTER_MANAGER: 'GET_EXAM_CENTER_MANAGER',
  TOTAL_EXAM_CENTER_COUNT: 'TOTAL_EXAM_CENTER_COUNT',
  GET_PAGINATED_EXAM_CENTER: 'GET_PAGINATED_EXAM_CENTER',
  UPDATE_CSV_FILE: 'UPDATE_CSV_FILE',
  // Make Up Marks
  MAKE_UP_MARKS: 'MAKE_UP_MARKS',
  // errors
  NO_PERMISSION: "You don't have sufficient permission to access the requested resource.",
  GET_PAGINATED_TEACHER_RECOURCES: 'GET_PAGINATED_TEACHER_RECOURCES',
  GET_CURRICULAR_ACTIVITIES_SCHEMA: 'GET_CURRICULAR_ACTIVITIES_SCHEMA',
  GET_ASSIGNMENTS_SCHEMA: 'GET_ASSIGNMENTS_SCHEMA',
  // PSTU faqs
  GET_PSTU_FAQS: 'GET_PSTU_FAQS',
  // repeat status
  UPDATE_REPEAT_STATUS: 'UPDATE_REPEAT_STATUS',
};
