import { colors } from '../../../../../theme';

const assignmentStatusTableStyle = () => ({
  assignmentStatusTable: {
    '&.MuiTableHead-root': {
      borderBottom: `0.15vw solid ${colors.actionIconsColor}`,
      marginLeft: '15px',
    },
    '& .MuiTableCell-head': {
      '@media (min-width: 1200px)': {
        fontSize: '1vw',
      },
      padding: '1.5vw 0.5vw',
      color: colors.actionIconsColor,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    '& .MuiTableCell-body': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        width: '2vw',
      },
      '@media (max-width: 576px)': {
        minWidth: '190px',
      },
      padding: '1.5vw 0.5vw',
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          width: '1.5vw',
          height: '1.5vw',
        },
      },
    },
  },
  boxTable: {
    marginLeft: '20px',
  },
  descriptionCell: {
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default assignmentStatusTableStyle;
