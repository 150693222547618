/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import CheckboxAtom from '../../../components/atoms/checkbox';
import RegionManagerTable from './region-manager-table';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons, NavigateRoutes } from '../../../constant';
import { useStyles } from './style';
import { getRegions } from '../../../store/actions/getRegion';
import AddRegionDialog from './add';
import RegionExportDialog from './export';
import { Loader, DialogAtom } from '../../../components/atoms';
import useDataTableParams from '../../../custom-hooks/useDataTableParams';
import TextFieldAtom from '../../../components/atoms/textfield';

export default function RegionManager() {
  const {
    nextPage,
  } = useDataTableParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const regionStore = useSelector((state) => state.getRegion);
  const [showExportDialog, setExportDialog] = useState(false);
  const csvLink = useRef();
  const [viewInactive, setViewInactive] = useState(false);
  const [dataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pagenum, setPagenum] = useState(1);
  const reduxStore = useSelector((state) => state?.getRegion);
  const regions = reduxStore?.regions;
  const [totalRows, setTotalRows] = useState(0);
  const [totalActiveResources, setTotalActiveResources] = useState(0);
  const [totalInactiveResources, setTotalInactiveResources] = useState(0);
  const [filteredResources, setFilteredResources] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    setPagenum(1);
    dispatch(getRegions(setLoading, 1, 100, nextPage, searchValue));
  }, []);

  useEffect(() => {
    setPagenum(1);
    dispatch(getRegions(setLoading, 1, 100, nextPage, searchValue));
  }, [searchValue]);

  useEffect(() => {
    if (regions) {
      if (viewInactive) {
        setFilteredResources(regions);
      } else {
        const arr = [];
        const arrInactive = [];
        regions.map((hv) => {
          if (hv?.isActive === true) {
            arr.push(hv);
          }else {
            arrInactive.push(hv);
          }
          return arr;
        });
        setFilteredResources(arr);
        setTotalInactiveResources(arrInactive.length)
        setTotalActiveResources(arr.length);
      }
    }
  }, [regions, viewInactive]);

  useEffect(() => {
    setTableData(filteredResources);
  }, [filteredResources]);

  useEffect(() => {
    setTotalRows(filteredResources?.length);
  }, [filteredResources]);

  const [showAddRegionDialog, setShowAddRegionDialog] = useState('');
  const [selectedRegion, setSelectedRegion] = useState({});
  const navigate = useNavigate();

  const viewLogs = (onClose) => {
    onClose(false);
    navigate(NavigateRoutes.GET_REGION_MANAGER_LOGS, {
      state: {
        id: selectedRegion?.id,
        lastRoute: NavigateRoutes.REGION_MANAGER_VIEW,
      },
    });
  };

  const onAddRegion = () => {
    setShowAddRegionDialog('');
    setPagenum(1);
    dispatch(getRegions(setLoading, 1, 100, nextPage, searchValue));
  };

  const renderSuccessDialog = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('ADD_REGION_SUCCESS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setSuccessMessage(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid className={classes.regionRoot}>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('REGION_MANAGER')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextFieldAtom
            id="locationSearch"
            customFormControlCss={{ margin: '0.8vw 0' }}
            name="locationSearch"
            type="text"
            minWidth="80%"
            placeholder={t('SEARCH')}
            disabled={false}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setViewInactive(false);
              setPagenum(1);
            }}
            value={searchValue}
          />
        </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.dFlex}  item xs={8} lg={10.5} md={10.5}>
            <div>
              <ButtonAtom
                className={classes.addbutton}
                name={t('EXPORT_TO_CSV')}
                btntype={Buttons.PRIMARY}
                type={Buttons.PRIMARY}
                onClick={() => setExportDialog(true)}
              />
              <CSVLink
                data={dataForDownload}
                filename="region-list.csv"
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
          </div>
          <ButtonAtom
            className={classes.addbutton}
            name={t('ADD')}
            btntype={Buttons.PRIMARY}
            type={Buttons.PRIMARY}
            onClick={() => {
              setSelectedRegion(false);
              setShowAddRegionDialog('add');
            }}
            icon={<AddIcon className={classes.addIconSize} />}
          />
        </Grid>
        <Grid item xs={1.5}>
          <div className={classes.deactiveCheckbox}>
            <CheckboxAtom
              label={t('VIEW_INACTIVE')}
              id="acceptCheckbox"
              checked={viewInactive}
              handleChange={(e) => {
                setViewInactive(e.target.checked);
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.regionManagerContainer}>
        <Grid container className={classes.regionManagerList}>
          <Grid className={classes.tableView}>
            <RegionManagerTable
              tableData={tableData}
              onEdit={(row) => {
                setSelectedRegion(row);
                setShowAddRegionDialog('edit');
              }}
              pageNext={nextPage}
              pagenum={pagenum}
              setPagenum={setPagenum}
              totalRows={totalRows}
              setLoading={setLoading}
              totalActiveResources={totalActiveResources}
              isDeactivateChecked={viewInactive}
              totalInactiveResources={totalInactiveResources}
            />
          </Grid>
        </Grid>
      </Grid>
      {showAddRegionDialog && (
        <AddRegionDialog
          open={showAddRegionDialog}
          selectedRegion={showAddRegionDialog !== 'add' ? selectedRegion : {}}
          handleClose={() => setShowAddRegionDialog('')}
          addRegion={onAddRegion}
          {...{ loading, setLoading, viewLogs }}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      <RegionExportDialog
        show={showExportDialog}
        setShow={() => setExportDialog(false)}
        data={tableData}
      />
      {loading ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : null}
      {
        successMessage && (
          <DialogAtom
            isOpen={successMessage}
            dialogHeading={t('ADD_REGION_SUCCESS_TITLE')}
            customClass={classes.DialogAtom}
            content={renderSuccessDialog()}
            secHandle={() => setSuccessMessage(false)}
          />
        )
      }
    </Grid>
  );
}
