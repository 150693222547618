/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DropdownReports, DialogAtom } from '../../../../components/atoms';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';
import useStyles from '../../../../custom-hooks/useStyles';
import { fetchUnregisteredStudentsByYear, postUnregisteredStudentByYear } from '../../../../store/actions/getAdmin';
import { getLocalStorage } from '../../../../utils/localStorageMethod';
import Loader from '../../../../components/atoms/loader';
import styles from './style';
import { getCurrentYear } from '../../../../utils/methods';
import { setSelectedYear, getAssignedLocations } from '../../../../store/actions/getLocationCoordinator';

const CenteredBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '1vw',
  marginLeft: '3vw',
}));

function App() {
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getAdmin);
  const reduxClass = useSelector((state) => state?.getTeacherView);
  const locationCoordinatorData = useSelector((state) => state?.getLocationCoordinator);
  const selectedLocationRedux = locationCoordinatorData?.selectedLocations;
  const assignedLocations = locationCoordinatorData?.assignedLocations;
  const reportsList = reduxStore?.reportsList;
  const selectedYearRedux = locationCoordinatorData?.selectedYear;
  const assignedYears = locationCoordinatorData?.assignedYears;
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const [loading, setLoading] = useState(true);
  const generatedBy = getLocalStorage('username');
  const [reportType, setReportType] = useState('unregisteredStudentsByAcAdemicYear');
  const [tableData, setTableData] = useState(reportsList);
  const [issetErrorOpenDialog, setErrorOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [issetWaitingOpenDialog, setWaitingOpenDialog] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState('');
  const [years, setYearsData] = useState([]);
  const [year, setYear] = useState('');
  const [locations, setLocationData] = useState([]);
  const [selectedLocation, setLocation] = useState('');
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  
  const reportOptions = [
    {
      id: 'unregisteredStudents',
      name: 'Unregistered students',
    },
  ];

  const columns = [
    {
      field: 'spreadsheetLink',
      headerName: 'Report Name',
      flex: 0.7,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const spreadsheetLink = params.row.spreadsheetLink;
        return (
          <Link href={spreadsheetLink} color="black" underline="hover" target="_blank" className={classes.cell}>
            {params.row.spreadsheetName}
          </Link>
        );
      },
    },
    {
      field: 'academicYear',
      headerName: 'Academic Year',
      flex: 0.3,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'generatedBy',
      headerName: 'Generated By',
      flex: 0.3,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'generatedOn',
      headerName: 'Generated On',
      flex: 1,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
    }
  ];


  useEffect(() => {
    setLocationData(assignedLocations);
    if (assignedLocations?.length === 0) {
      
    }
    if (selectedLocationRedux?.value) {
      setLocation(selectedLocationRedux?.value);
    } else {
      setLocation(assignedLocations?.[0]?.id);
    }
    setYearsData(assignedYears);

    const currentYear = getCurrentYear()?.id;
    const selectedYear = assignedYears?.filter((opt) => opt?.id === currentYear.toString());
    if (selectedYearRedux?.id) {
      setYear(selectedYearRedux?.id);
    } else {
      setYear(selectedYear?.[0]?.id);
    }
  }, [assignedYears, assignedLocations]);

  useEffect(() => {
    dispatch(fetchUnregisteredStudentsByYear(reportType, year, userRole));
  }, [year]);

  
  useEffect(() => {
    const loadFalse = () => setLoading(false);
    dispatch(getAssignedLocations(loadFalse));
  }, []);

  useEffect(() => {
    setTableData(reduxStore?.reportsList);
    setLoading(false);
  }, [reduxStore?.reportsList]);

  const handleYearChange = (e) => {
    setYear(e.id);
    const payload = {
      id: e.id,
      name: e.name,
    };
    dispatch(setSelectedYear(payload));
  };

  const refreshPage = async () => {
    dispatch(fetchUnregisteredStudentsByYear(reportType, year, userRole));
    setReportType('unregisteredStudentsByAcAdemicYear');
  }

  const generateReport = async () => {
    setLoading(true);
    let response = false;
    let errorType = [];
    const manabadiEmail = localStorage.getItem('userEmailId');
    const time = reportType === 'all' ? '2 min to 3 min' : reportType === 'students' ? '1 min to 2 min' : '30 sec to 1 min';
    setWaitingMessage(`Please await around ${time} for unregistered sudents by academic year to be finished and refresh or return to page to see all reports`);
    setWaitingOpenDialog(true);
      const reportTypes = ["unregisteredStudentsByAcAdemicYear"];
      for (const type of reportTypes) {
        const payload = {
          report: type,
          generatedBy: generatedBy,
          academicYear: year,
          generatedOn: `${new Date()}`,
          manabadiEmail: manabadiEmail,
          role: userRole
        };
        const res = await postUnregisteredStudentByYear(payload);
        if (!res.success) {
          errorType.push(type);
        }
      }
      response = errorType.length === 0;
    
    if (!response && errorType.length > 0) {
      setErrorOpenDialog(true);
      setErrorMessage(`Error on report type: ${errorType}`);
    }
    refreshPage();
  };

  const renderErrorContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alert} role="alert">
            {errorMessage}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={handleErrorDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const handleErrorDialog = () => {
    setErrorOpenDialog(false);
    setLoading(false);
  };

  const renderWaitingContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertPrimary} role="alert">
            {waitingMessage}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={handleWaitingDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const handleWaitingDialog = () => {
    setWaitingOpenDialog(false);
    setLoading(false);
  };

  return (
    <Box>
      <CenteredBox>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
          <DropdownReports
            label={`${t('ACADEMIC_YEAR')}`}
            required
            id="year"
            name="year"
            labelId="year"
            value={year}
            options={years}
            shrink
            handleChange={(e) => handleYearChange(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ButtonAtom
            btntype={Buttons.PRIMARY}
            onClick={generateReport}
            name="Generate report"
            type="submit"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
      </Grid>
      </CenteredBox>
      {
        loading ? (
          <Grid>
            <Loader message={t('LOADING')} />
          </Grid>
        )
          : (
            <>
              <DataGridPro
                rows={tableData}
                columns={columns}
                autoHeight
                disableColumnFilter
                disableColumnSelector
                disableColumnReorder
                disableColumnMenu
                disableColumnResize
                disableSelectionOnClick
                className={classes.dataGrid}
                pageSize={15}
                pagination
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'white',
                  },
                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                    flexDirection: 'row',
                    justifyContent: 'center',
                  },
                }}
              />
            </>
          )
      }
      <DialogAtom
        isOpen={issetErrorOpenDialog}
        dialogHeading={`${t('ERROR_REPORTS')}`}
        customClass={classes.DialogAtom}
        content={renderErrorContent()}
        secHandle={() => handleErrorDialog()}
      />
      <DialogAtom
        isOpen={issetWaitingOpenDialog}
        dialogHeading={`${t('WAITING_REPORTS')}`}
        customClass={classes.DialogAtom}
        content={renderWaitingContent()}
        secHandle={() => handleWaitingDialog()}
      />
    </Box>
  );
}

export default App;
