import Constant from '../constant';

const initialState = {
  graphOption: 'year',
  selectedView: '',
  enrollGraphOption: '',
  uploadFile: [],
  uploadVideo: [],
  fetchAllPstuFaqs: [],
  createStudentResource: [],
  createStudentResourceError: {},
  impersonateUserEmail: [],
  studentResourcesByFolder: [],
  folderDetails: [],
  termsSettingDetails: {},
  topics: [],
  assignments: [],
  changeLogsList: [],
  reportsList: [],
  changeLogsDetails: [],
  teacherResourcesByFolder: [],
  filterOpened: false,
  welcomeEmailInfo: [],
};

const getAdmin = (state = initialState, action = {}) => {
  switch (action.type) {
    case Constant.GET_REGION_MANAGER_LOGS:
      return {
        ...state,
        regionManagerLogs: action.payload,
      };
    case Constant.SET_FILTER_STATUS:
      return {
        ...state,
        filterOpened: action.payload,
      };
    case Constant.GET_ROLE_CHANGELOGS_DETAILS:
      return {
        ...state,
        changeLogsDetails: action.payload,
      };
    case Constant.GET_REPORTS_LIST:
      return {
        ...state,
        reportsList: action.payload,
      };
    case Constant.GET_ROLE_CHANGELOGS_LIST:
      return {
        ...state,
        changeLogsList: action.payload,
      };
    case Constant.GET_ALL_TOPICS:
      return {
        ...state,
        topics: action.payload,
      };
    case Constant.UPLOAD_FILE:
      return {
        ...state,
        uploadFile: action.payload,
      };
    case Constant.SET_TERMS_SETTINGS:
      return {
        ...state,
        termsSettingDetails: action.payload,
      };
    case Constant.WELCOME_EMAIL_TEMPLATE:
      return {
        ...state,
        welcomeEmailInfo: action.payload,
      };
    case Constant.UPLOAD_VIDEO:
      return {
        ...state,
        uploadVideo: action.payload,
      };
    case Constant.CREATE_STUDENT_RESOURCE:
      return {
        ...state,
        createStudentResource: action.payload,
      };
    case Constant.CREATE_STUDENT_RESOURCE_ERROR:
      return {
        ...state,
        createStudentResourceError: action.payload,
      };
    case Constant.IMPERSONATE_USER:
      return {
        ...state,
        impersonateUserEmail: action.payload,
      };
    case Constant.SET_GRAPH_OPTIONS:
      return {
        ...state,
        graphOption: action.payload,
      };
    case Constant.SET_ENROLL_GRAPH_OPTIONS:
      return {
        ...state,
        enrollGraphOption: action.payload,
      };
    case Constant.GET_STUDENT_RESOURCE_IN_FOLDER:
      return {
        ...state,
        studentResourcesByFolder: action.payload,
      };
    case Constant.GET_TEACHER_RESOURCE_IN_FOLDER:
      return {
        ...state,
        teacherResourcesByFolder: action.payload,
      };
    case Constant.GET_FOLDER_DETAILS:
      return {
        ...state,
        folderDetails: action.payload,
      };
    case Constant.GET_EXAM_CENTER_MANAGER_LOGS:
      return {
        ...state,
        examCenterManagerLogs: action.payload,
      };
    case Constant.REMOVE_EXAM_CENTER_MANAGER_LOGS_DETAILS:
      return {
        ...state,
        examCenterManagerLogs: action.payload,
      };
    case Constant.GET_LOCATION_MANAGER_LOGS:
      return {
        ...state,
        locationManagerLogs: action.payload,
      };
    case Constant.GET_LOCATION_MANAGER_LOGS_DETAILS:
      return {
        ...state,
        locationManagerLogDetails: action.payload,
      };
    case Constant.GET_COURSE_MANAGER_LOGS:
      return {
        ...state,
        courseManagerLogs: action.payload,
      };
    case Constant.GET_COURSE_MANAGER_LOGS_DETAILS:
      return {
        ...state,
        courseManagerLogDetails: action.payload,
      };
    case Constant.GET_ASSIGNMENT_PER_COURSE:
      return {
        ...state,
        assignments: action.payload,
      };
    case Constant.SET_FILTER_SELECTED_VIEW:
      return {
        ...state,
        selectedView: action.payload,
      };
    case Constant.GET_PSTU_FAQS:
      return {
        ...state,
        fetchAllPstuFaqs: action.payload,
      };
    default:
      return state;
  }
};

export default getAdmin;
