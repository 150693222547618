/* eslint-disable */
import Constant from '../constant';
import {
  cancelEnrollService, getRegionManagerLogDetailService, getRegionManagerLogsService, impersonateUserService,
  getTermsConditionsSettingsService, getExamCenterManagerLogsService, getExamCenterManagerLogDetailService,
  getAllTopicsService, getAssignmentPerCourseService, deleteAssignmentService, editAssignmentService, publishAssignmentService, getClassesAmountService,
  getLocationManagerLogsService, getLocationManagerLogDetailService, getCourseManagerLogsService, getCourseManagerLogDetailService, getAssignmentPerCourseAndYearAndLocationService,
  getRoleChangeLogsListService, getRoleChangeLogsDetailsService, editOldAssignmentService, republishOldAssignmentService, getAssignmentByClassroomIdService, editAssignmentFromStatusService,
  deleteAssignmentFromStatusService, deleteSelectedAssignmentsFromStatusService,
  getAllReports, getAllReportsByType, createDailyReport, getAllPstuFaqs, createPstuFaqs, updatePstuFaqs, updateStudentRepeatStatus, getUnregisteredStudentsByYear,
  getWelcomeEmail, postWelcomeEmail
} from '../services/auth';
import { setLocalStorage } from '../../utils/localStorageMethod';

export const setTermsAndConditionSettingDetails = (body) => (dispatch) => {
  getTermsConditionsSettingsService(body)
    .then((response) => {
      const values = {
        id: response?.data?.id,
        message: response?.data?.data,
      };
      dispatch({
        type: Constant.SET_TERMS_SETTINGS,
        payload: values,
      });
    })
    .catch(() => {
    });
};

export const servicePostWelcomeEmail = (payload, setLoading, setIsOpenError) => (dispatch) => {
  postWelcomeEmail(payload)
    .then((response) => {
      const values = {
        id: response?.data?.id,
        subject: response?.data?.subject,
        body: response?.data?.body,
      };
      dispatch({
        type: Constant.WELCOME_EMAIL_TEMPLATE,
        payload: values,
      });
      if (setLoading) {
        setLoading(false);
      }
      if (setIsOpenError) {
        setIsOpenError(true);
      }
    })
    .catch(() => {
    });
};
export const servicegetWelcomeEmail = (type, course) => (dispatch) => {
  getWelcomeEmail(type, course)
    .then((response) => {
      const values = {
        id: response?.data?.id,
        subject: response?.data?.subject,
        body: response?.data?.body,
      };
      dispatch({
        type: Constant.WELCOME_EMAIL_TEMPLATE,
        payload: values,
      });
    })
    .catch(() => {
    });
};

export const getRoleChangeLogsDetails = (logId) => (dispatch) => {
  getRoleChangeLogsDetailsService(logId)
    .then((response) => {
      dispatch({
        type: Constant.GET_ROLE_CHANGELOGS_DETAILS,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export const getRoleChangeLogsList = () => (dispatch) => {
  getRoleChangeLogsListService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ROLE_CHANGELOGS_LIST,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export const fetchAllPstuFaqs = () => (dispatch) => {
  getAllPstuFaqs()
    .then((response) => {
      dispatch({
        type: Constant.GET_PSTU_FAQS,
        payload: response.data,
      });
    }).catch(() => {
    });
};

export const addPstu = (payload) => async () => {
  try {
    const res = await createPstuFaqs(payload);
    return Promise.resolve(res);
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
    const res = {
      success: false,
      data: [],
    };
    return Promise.resolve(res);
  }
};
export const updatePstu = (payload, id, question, answer, updatedData) => async () => {
  try {
    const res = await updatePstuFaqs(payload, id, question, answer, updatedData);
    return Promise.resolve(res);
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
    const res = {
      success: false,
      data: [],
    };
    return Promise.resolve(res);
  }
};
export const updatePstuEdit = (id, updatedData) => async (dispatch) => {
  try {
    const res = await updatePstuFaqs(id, updatedData);
    dispatch({ type: 'UPDATE_PSTU_SUCCESS', payload: res });
    return Promise.resolve(res);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating PSTU:', error);
    const res = {
      success: false,
      data: [],
    };
    return Promise.resolve(res);
  }
};
export const updateStuRepeatStatus = (data, setRepeatStatusOpen, setLoading, refreshList) => (dispatch) => {
  updateStudentRepeatStatus(data).then((response) => {
    dispatch({
      type: Constant.UPDATE_REPEAT_STATUS,
      payload: response.data,
    });
    setRepeatStatusOpen(false);
    setLoading(false);
    refreshList();
  });
};
export const fetchAllReports = () => (dispatch) => {
  getAllReports()
    .then((response) => {
      dispatch({
        type: Constant.GET_REPORTS_LIST,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export const fetchAllReportsByType = (reportType) => (dispatch) => {
  getAllReportsByType(reportType)
    .then((response) => {
      dispatch({
        type: Constant.GET_REPORTS_LIST,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export const fetchUnregisteredStudentsByYear = (reportType, academicYear, userType) => (dispatch) => {
  getUnregisteredStudentsByYear(reportType, academicYear, userType)
    .then((response) => {
      dispatch({
        type: Constant.GET_REPORTS_LIST,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export const dailyReport = async (payload) => {
  try {
    const res = await createDailyReport(payload);
    return Promise.resolve(res);
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
    const res = {
      success: false,
      data: [],
    };
    return Promise.resolve(res);
  }
};

export const postUnregisteredStudentByYear = async (payload) => {
  try {
    const res = await createDailyReport(payload);
    return Promise.resolve(res);
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
    const res = {
      success: false,
      data: [],
    };
    return Promise.resolve(res);
  }
};

export const getAssignmentPerCourse = (courseId, typeNew = false) => async (dispatch) => {
  await getAssignmentPerCourseService(courseId, typeNew)
    .then((response) => {
      dispatch({
        type: Constant.GET_ASSIGNMENT_PER_COURSE,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export const getAssignmentByClassroomInformation = (payload, setAssignmentInfo, setExtraData, setLoading) => async () => {
  await getAssignmentByClassroomIdService(payload)
    .then((response) => {
      if (response?.data?.data?.academic_assignment) {
        setAssignmentInfo(response?.data?.data?.academic_assignment);
      } else {
        const assignmentInfoNew = { // object to be created only with data from classroomsAPI (there isn´t an assignment in the database)
          description: '',
          assignmentitle: '',
          defaultScore: '',
        };
        setAssignmentInfo(assignmentInfoNew);
      }
      setExtraData(response?.data?.extraData);
      setLoading(false);
    })
    .catch(() => {
    });
};

export const getAssignmentByCourseAndYear = (payload, setLoading, setRows) => async () => {
  await getAssignmentPerCourseAndYearAndLocationService(payload)
    .then((response) => {
      setRows(response.data);
      setLoading(false);
    })
    .catch(() => {
    });
};

export const editAssignmentFromStatus = async (payload, setLoading, classroomIds) => {
  try {
    const res = await editAssignmentFromStatusService(payload, classroomIds);
    setLoading(false);
    return Promise.resolve(res);
  } catch (error) {
    setLoading(false);
    return Promise.reject(error);
  }
};

export const deleteAssignmentFromStatus = async (assignmentId, classroomId, setLoading, refreshAssignmentsList) => {
  deleteAssignmentFromStatusService(assignmentId, classroomId)
    .then(async () => {
      refreshAssignmentsList();
    })
    .catch(() => {
      setLoading(false);
    });
};

export const deleteSelectedAssignmentsFromStatus = async (payload, setLoading, refreshAssignmentsList, setShowDeletedMessage, setSelectedAssignments) => {
  deleteSelectedAssignmentsFromStatusService(payload)
    .then(async () => {
      refreshAssignmentsList();
      setSelectedAssignments([]);
      setShowDeletedMessage(true);
    })
    .catch(() => {
      setLoading(false);
    });
};

export const deleteAssignmentById = (assignmentId, courseId, typeNew = false) => (dispatch) => {
  deleteAssignmentService(assignmentId, courseId)
    .then(async () => {
      getAssignmentPerCourseService(courseId, typeNew)
        .then((response) => {
          dispatch({
            type: Constant.GET_ASSIGNMENT_PER_COURSE,
            payload: response?.data,
          });
        })
        .catch(() => {
        });
    })
    .catch(() => {
    });
};

export const deleteAssignmentByIdOld = (assignmentId, courseId) => async () => {
  try {
    const res = await deleteAssignmentService(assignmentId, courseId);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editAssignmentById = async (payload, courseId, loadFalse, selectedAssignmentId) => {
  try {
    const res = await editAssignmentService(payload, courseId, selectedAssignmentId);
    loadFalse();
    return Promise.resolve(res);
  } catch (error) {
    loadFalse();
    return Promise.reject(error);
  }
};

export const editOldAssignmentById = async (payload, courseId, loadFalse, selectedAssignmentId) => {
  try {
    const res = await editOldAssignmentService(payload, courseId, selectedAssignmentId);
    loadFalse();
    return Promise.resolve(res);
  } catch (error) {
    loadFalse();
    return Promise.reject(error);
  }
};

export const getAllTopics = () => (dispatch) => {
  getAllTopicsService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_TOPICS,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export const publishAssignment = (payload, setLoading, setMainCheckBox, setPublishedAssignmentOpen) => async () => {
  try {
    const res = await publishAssignmentService(payload);
    setLoading(false);
    setMainCheckBox(false);
    setPublishedAssignmentOpen(true);
    return Promise.resolve(res);
  } catch (error) {
    setLoading(false);
    setMainCheckBox(false);
    return Promise.reject(error);
  }
};
export const republishOldAssignment = (payload, setLoading, setMainCheckBox, setPublishedAssignmentOpen) => async () => {
  try {
    const res = await republishOldAssignmentService(payload);
    setLoading(false);
    setMainCheckBox(false);
    setPublishedAssignmentOpen(true);
    return Promise.resolve(res);
  } catch (error) {
    setLoading(false);
    setMainCheckBox(false);
    return Promise.reject(error);
  }
};

export const getClassesAmount = (payload) => async () => {
  try {
    const res = await getClassesAmountService(payload);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRegionManagerLogs = (regionId) => (dispatch) => {
  getRegionManagerLogsService(regionId)
    .then((response) => {
      dispatch({
        type: Constant.GET_REGION_MANAGER_LOGS,
        payload: response?.data,
      });
    });
};
export const getRegionManagerLogDetail = (logId) => async (dispatch) => {
  try {
    const res = await getRegionManagerLogDetailService(logId);
    dispatch({
      type: Constant.GET_REGION_MANAGER_LOGS_DETAILS,
      payload: res?.data,
    });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getLocationManagerLogs = (locationId) => (dispatch) => {
  getLocationManagerLogsService(locationId)
    .then((response) => {
      dispatch({
        type: Constant.GET_LOCATION_MANAGER_LOGS,
        payload: response?.data,
      });
    });
};

export const getLocationManagerLogDetail = (logId) => async (dispatch) => {
  try {
    const res = await getLocationManagerLogDetailService(logId);
    dispatch({
      type: Constant.GET_LOCATION_MANAGER_LOGS_DETAILS,
      payload: res?.data,
    });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getCourseManagerLogs = (locationId) => (dispatch) => {
  getCourseManagerLogsService(locationId)
    .then((response) => {
      dispatch({
        type: Constant.GET_COURSE_MANAGER_LOGS,
        payload: response?.data,
      });
    });
};

export const getCourseManagerLogDetail = (logId) => async (dispatch) => {
  try {
    const res = await getCourseManagerLogDetailService(logId);
    dispatch({
      type: Constant.GET_COURSE_MANAGER_LOGS_DETAILS,
      payload: res?.data,
    });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const setGraphOption = (body) => (dispatch) => {
  dispatch({
    type: Constant.SET_GRAPH_OPTIONS,
    payload: body,
  });
};
export const setFilterSelectedView = (body) => (dispatch) => {
  dispatch({
    type: Constant.SET_FILTER_SELECTED_VIEW,
    payload: body,
  });
};

export const setEnrollGraphOption = (body) => (dispatch) => {
  dispatch({
    type: Constant.SET_ENROLL_GRAPH_OPTIONS,
    payload: body,
  });
};

export const cancelEnroll = (body, callback) => async () => {
  try {
    const res = await cancelEnrollService(body);
    await callback();
    return Promise.resolve(res);
  } catch (err) {
    callback();
    return Promise.reject(err);
  }
};

export const impersonateUser = (body) => (dispatch) => {
  impersonateUserService(body)
    .then((response) => {
      setLocalStorage('impersonateToken', response.tokenResponse.JWT);
      dispatch({
        type: Constant.IMPERSONATE_USER,
        payload: response,
      });
    })
    .catch(() => {
    });
};

export const getExamCenterManagerLogs = (examcenterId) => (dispatch) => {
  getExamCenterManagerLogsService(examcenterId)
    .then((response) => {
      dispatch({
        type: Constant.GET_EXAM_CENTER_MANAGER_LOGS,
        payload: response?.data?.reverse(),
      });
    });
};
export const getExamCenterManagerLogDetail = (logId) => async (dispatch) => {
  try {
    const res = await getExamCenterManagerLogDetailService(logId);
    dispatch({
      type: Constant.GET_EXAM_CENTER_MANAGER_LOGS_DETAILS,
      payload: res?.data,
    });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const removeExamCenterManagerLogs = () => async (dispatch) => {
  dispatch({
    type: Constant.REMOVE_EXAM_CENTER_MANAGER_LOGS_DETAILS,
    payload: [],
  });
};

export default {
  cancelEnroll,
  impersonateUser,
  setGraphOption,
  setFilterSelectedView,
  setEnrollGraphOption,
  fetchAllReports,
  fetchAllReportsByType,
};
