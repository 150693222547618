/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Box,
  Autocomplete,
  IconButton,
} from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import AttachFile from '@mui/icons-material/AttachFile';
import moment from 'moment';
import DropdownWithLabel from '../../../../components/atoms/dropdown-with-label';
import RenderAttachmentPicker from '../new-assignment/attachmentPicker';
import DatePicker from '../../../../components/atoms/datepicker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@mui/lab/AdapterDateFns";
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import { isMobile } from 'react-device-detect';
import { colors } from '../../../../theme';

const textFieldInlineStyles = {
  fontSize: '12px',
  '@media (min-width: 600px)': {
    fontSize: '8px',
  },
};

const restrictKeys = (ev, allowDecimal = false) => {
  const restrictedSymbols = ['+', '-', 'e', '*'];

  if (!allowDecimal) {
    restrictedSymbols.push('.');
  }

  if (restrictedSymbols.includes(ev.key)) {
    ev.preventDefault();
  }
};

function EditAssignment({
  classes,
  handlePopUp,
  assignmentTopicsWithId,
  eventsClassData,
  resourceData,
  setIsFetching,
  isFetching,
  nextPage,
  selectedCourse,
  selectedCourseName,
  selectedAssignment,
  setAddAssignmentOpen,
  setDataEdit,
  setLoading,
  assignments,
  editAssignmentOld,
  dataEdit,
  loadingResources,
  totalCount,
  totalCountNum,
  setLoadingResources,
}) {
  const { t } = useTranslation();
  const componentAttachmentPickerRef = useRef();
  const handleIconClick = () => {
    componentAttachmentPickerRef.current.handleClick();
  };

  const [vResourcesName, setVResourcesName] = useState('');
  const [aResources, setAResources] = useState([]);

  const saveAssignment = (assignmentID, data) => {
    let values;

    if (data) {
      values = data;
    } else {
      const selectedAssignmentItem = assignments.find((ele) => ele.id === assignmentID);
      values = selectedAssignmentItem;
    }

    if (values.attachResources === null) {
      values.attachResources = [];
    }
    let scheduled = '';
    let due = '';
    if (!values.scheduledDate) {
      scheduled = moment().utc();
    } else {
      scheduled = moment(values.scheduledDate).utc();
    }
    if (!values.dueDate) {
      due = moment().utc();
    } else {
      due = moment(values.dueDate).utc();
    }
    const nowDate = moment().utc();
    const dueDate = moment(due);
    let dateScheduledTime;
    if (scheduled <= nowDate) {
      dateScheduledTime = moment(nowDate).add(1, 'hours');
    } else {
      dateScheduledTime = scheduled;
    }
    const aResourcesName = values.attachResources.map((index) => index.name);
    if (values.selectEvent === '') {
      values.selectEvent = null;
    }

    const payload = {
      academicYear: dataEdit.academicYear,
      topicName: values.selectTopic,
      assignmentId: values.assignmentId,
      attachmentResourceName: aResourcesName.length !== 0 ? aResourcesName.join(',') : vResourcesName,
      event: values.selectEvent,
      courseId: selectedCourse,
      dueDate: dueDate.toISOString(),
      requestBody: {
        title: values.title,
        description: values.description,
        materials: (values.attachResources.length !== 0
          ? values.attachResources.map((item) => ({
            link: {
              url: item.url,
              title: item.name,
            },
          }))
          : aResources.map((item) => ({
            link: {
              url: item.url,
              title: item.name,
            },
          }))
        ),
        state: 'DRAFT',
        dueDate: {
          year: parseInt(dueDate.format('YYYY')),
          month: parseInt(dueDate.format('M')),
          day: parseInt(dueDate.format('D')),
        },
        dueTime: {
          hours: moment(dueDate).hours(),
          minutes: moment(dueDate).minutes(),
          seconds: moment(dueDate).seconds(),
        },
        scheduledTime: dateScheduledTime.toISOString(),
        maxPoints: values.defaultScore === '' || values.defaultScore === null ? 0 : values.defaultScore,
        workType: 'ASSIGNMENT',
      },
    };

    editAssignmentOld(payload, assignmentID);
  };

  const handlePublish = (assignmentID, values = null, reset = null) => {
    setLoading(true);

    const deleteId = assignmentID || selectedAssignment.id;

    if (!deleteId) {
      return;
    }

    saveAssignment(assignmentID || selectedAssignment.id, values, reset);
  };

  const onSubmit = (values, { resetForm }) => {
    handlePublish(null, values, resetForm);
  };

  const assignmentSchema = Yup.object().shape({
    assignmentId: Yup.string().required('Assignment ID Required'),
    title: Yup.string().required('Title Required'),
    description: Yup.string().required('Description Required'),
    selectTopic: Yup.string().required('Topic Required'),
    scheduledDate: Yup.date().typeError('Invalid Date Format').nullable(),
    dueDate: Yup.date().typeError('Invalid Date Format').nullable(),
  });
  const formik = useFormik({
    initialValues: {
      assignmentId: '',
      title: '',
      selectEvent: '',
      description: '',
      selectTopic: '',
      defaultScore: '',
      scheduledDate: '',
      dueDate: '',
      attachResources: [],
      attachResourcesVal: '',
    },
    onSubmit,
    validationSchema: assignmentSchema,
  });

  const handleAttachmentChange = (attachments) => {
    let val = '';
    const notUndefined = attachments.filter((item) => item !== undefined);
    const resources = notUndefined.map((ele, i) => {
      val += `${ele.name}${i === notUndefined.length - 1 ? '' : ','}`;
      return ({
        name: ele.name,
        url: ele.url,
      });
    });
    setVResourcesName(val);
    setAResources(resources);
    formik.setFieldValue('attachResources', resources);
    formik.setFieldValue('attachResourcesVal', val);
  };

  const prefillPopUpData = (data) => {
    formik.setFieldValue('assignmentId', data?.assignmentId || '', false);
    formik.setFieldValue('title', data?.title || '', false);
    if (data?.event) {
      formik.setFieldValue('selectEvent', data.event, false);
    }
    formik.setFieldValue('description', data?.description || '', false);
    formik.setFieldValue('selectTopic', data?.topicName || '', false);
    formik.setFieldValue('defaultScore', data?.defaultScore || '', false);
    if (data?.scheduledTime) {
      const nowDate = new Date();
      const scheduled = new Date(data?.scheduledTime);
      if (scheduled < nowDate) {
        formik.setFieldValue('scheduledDate', nowDate, false);
      } else {
        formik.setFieldValue('scheduledDate', scheduled, false);
      }
    } else {
      formik.setFieldValue('scheduledDate', '', false);
    }
    if (data?.dueDate) {
      const nowDueDate = new Date();
      const due = new Date(data?.dueDate);
      if (due < nowDueDate) {
        formik.setFieldValue('dueDate', nowDueDate, false);
      } else {
        formik.setFieldValue('dueDate', due, false);
      }
    } else {
      formik.setFieldValue('dueDate', '', false);
    }
    if (typeof (data?.attachmentResourceName) === 'string' || data?.attachmentResourceName === null || data?.attachmentResourceName === undefined) {
      formik.setFieldValue('attachResourcesVal', data?.attachmentResourceName || '', false);
    } else {
      formik.setFieldValue('attachResourcesVal', data?.attachmentResourceName.join() || '', false);
    }

    setVResourcesName(data?.attachmentResourceName);

    const attachedResource = resourceData.find((item) => item.resourceName === data?.attachmentResourceName);

    if (typeof (data?.attachmentResourceName) === 'string') {
      if (data?.attachmentResourceName !== null && data?.attachmentFileUrl !== null) {
        const aNameResources = data?.attachmentResourceName.split(',');
        const aUrlResources = data?.attachmentFileUrl.split(',');
        const aAttachedResources = [];
        for (let index = 0; index < aNameResources.length; index++) {
          aAttachedResources.push({
            name: aNameResources[index],
            url: aUrlResources[index],
          });
        }
        setAResources(aAttachedResources);
      }
    } else if (typeof (data?.attachmentResourceName) === 'object' && data?.attachmentResourceName !== null && data?.attachmentResourceName !== undefined) {
      const aNameResources = data?.attachmentResourceName;
      const aUrlResources = data?.attachmentFileUrl;
      const aAttachedResources = [];
      for (let index = 0; index < aNameResources.length; index++) {
        aAttachedResources.push({
          name: aNameResources[index],
          url: aUrlResources[index],
        });
      }
      setAResources(aAttachedResources);
    }

    formik.setFieldValue('attachResources', attachedResource ? ([{
      name: attachedResource.resourceName,
      url: attachedResource.fileUrl,
    }]) : null, false);

    setAddAssignmentOpen(true);
  };

  useEffect(() => {
    if (selectedAssignment) {
      setDataEdit(selectedAssignment);
      prefillPopUpData(selectedAssignment);
    } else {
      formik.resetForm();
    }
  }, [selectedAssignment]);
  return (
    <Grid className={classes.addDialog}>
      <FormikProvider value={formik}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className={classes.addPopup}
        >
          <Grid container direction="row" sx={{ display: 'flex' }}>
            <Grid container spacing={4} xs={15} sm={8} md={6} className={classes.containerElements} direction="column">
              <Grid container className={classes.formControlGrid}>
                <TextField
                  className={classes.formControlGrid}
                  disabled
                  label={t('LEVEL')}
                  placeholder={t('LEVEL')}
                  id="outlined-required"
                  value={selectedCourseName}
                  size="small"
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  name="title"
                />
              </Grid>
              <Grid container className={classes.formControlGrid}>
                <TextField
                  className={classes.formControlGrid}
                  required
                  label={t('TITLE')}
                  placeholder={t('TITLE')}
                  id="outlined-required"
                  value={formik.values.title}
                  size="small"
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  name="title"
                  validationOnChange={false}
                  validateOnBlur={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.title && formik.touched.title && (
                  <span className={classes.errorText}>{formik.errors.title}</span>
                )}
              </Grid>
              <Grid container className={classes.editorClassContainer}>
                <Grid item className={classes.formControlGridLeft}>
                  <span className={classes.textFieldsDescription}>{t('DESCRIPTION')}</span>
                </Grid>
                <TextField
                  required
                  id="standard-multiline-static-required"
                  multiline
                  variant={"standard"}
                  rows={10}
                  size="small"
                  style={{ marginLeft: '5px' }}
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  name="description"
                  value={formik.values.description}
                  validationOnChange={false}
                  validateOnBlur={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.description && formik.touched.description && (
                  <span className={classes.errorText}>{formik.errors.description}</span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4} xs={15} sm={8} md={6} className={classes.containerElements} direction="column">
              <Grid container className={classes.formControlGrid}>
                <TextField
                  shrink
                  className={classes.formControlGrid}
                  required
                  label={t('ASSIGNMENT_ID')}
                  placeholder={t('ASSIGNMENT_ID')}
                  id="outlined-required"
                  value={formik.values.assignmentId}
                  name="assignmentId"
                  size="small"
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  validationOnChange={false}
                  onChange={formik.handleChange}
                  validateOnBlur={false}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.assignmentId && formik.touched.assignmentId && (
                  <span className={classes.errorText}>{formik.errors.assignmentId}</span>
                )}
              </Grid>
              <Grid container className={isMobile ? classes.dropdownFieldMobile : classes.formControlGrid}>
                <DropdownWithLabel
                  id="selectEvent"
                  name="selectEvent"
                  label={t('SELECT_EVENT')}
                  disabled={false}
                  options={eventsClassData}
                  size="small"
                  placeholder={t('SELECT_EVENT')}
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  customStyles={classes.formControlGrid}
                  handleChange={(e) => {
                    formik.setFieldValue('selectEvent', e.target.value);
                  }}
                  value={formik.values.selectEvent}
                />
                {formik.errors.selectEvent && formik.touched.selectEvent && (
                  <span className={classes.errorText}>{formik.errors.selectEvent}</span>
                )}
              </Grid>
              <Grid container className={isMobile ? classes.dropdownFieldMobile : classes.formControlGrid} style={{ marginTop: '5px' }}>
                <Autocomplete
                  className={isMobile ? null : classes.formControlGrid}
                  freeSolo
                  id="selectTopic"
                  name="selectTopic"
                  disableClearable
                  options={assignmentTopicsWithId.map((option) => option?.id)}
                  onChange={(newValue) => {
                    formik.setFieldValue('selectTopic', newValue.target.innerText);
                  }}
                  value={formik.values.selectTopic}
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  onBlur={formik.topicBlur}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={t('SELECT_TOPIC')}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        placeholder: t('CREATE_NEW_TOPIC'),
                      }}
                      validationOnChange={false}
                      validateOnBlur={false}
                      onChange={(e) => {
                        formik.setFieldValue('selectTopic', e.target.value);
                      }}
                    />
                  )}
                />
                {formik.errors.selectTopic && formik.touched.selectTopic && (
                  <span className={classes.errorText}>{formik.errors.selectTopic}</span>
                )}
              </Grid>
              <Grid container className={classes.formControlGrid}>
                <TextField
                  type="number"
                  id="outlined-required"
                  name="defaultScore"
                  label={t('DEFAULT_SCORE')}
                  placeholder={t('DEFAULT_SCORE')}
                  defaultValue={formik.values.defaultScore}
                  value={formik.values.defaultScore}
                  validationOnChange={false}
                  validateOnBlur={false}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                      formik.setFieldValue('defaultScore', 0);
                    } else {
                      formik.setFieldValue('defaultScore', e.target.value);
                    }
                  }}
                  onKeyPress={(e) => restrictKeys(e, true)}
                  inputProps={{ min: '0', style: textFieldInlineStyles }}
                  InputLabelProps={{ style: textFieldInlineStyles }}
                  size="small"
                  onBlur={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                      formik.setFieldValue('defaultScore', 0);
                    } else {
                      formik.setFieldValue('defaultScore', e.target.value);
                    }
                  }}
                />
                {formik.errors.defaultScore && formik.touched.defaultScore && (
                  <span className={classes.errorText}>{formik.errors.defaultScore}</span>
                )}
              </Grid>
              <Grid container className={isMobile ? classes.dropdownFieldMobile : classes.formControlGrid} style={{ marginTop: '5px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    required
                    name="scheduledDate"
                    label={t('SCHEDULED_DATE')}
                    placeholder={t('SCHEDULED_DATE')}
                    value={formik.values.scheduledDate}
                    onChange={(newDate) => {
                      formik.setFieldValue('scheduledDate', newDate);
                    }}
                    textFieldProps={{
                      sx: {
                        svg: { fill: '#104F96', paddingTop: '0.3vw' },
                      },
                      size: 'small',
                    }}
                    customClass={classes.datePicker}
                    onBlur={formik.handleBlur}
                    minDate={new Date()}
                  />
                </MuiPickersUtilsProvider>
                {formik.errors.scheduledDate && formik.touched.scheduledDate && (
                  <span className={classes.errorText}>{formik.errors.scheduledDate}</span>
                )}
              </Grid>
              <Grid container className={isMobile ? classes.dropdownFieldMobile : classes.formControlGrid} style={{ marginTop: '5px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    required
                    name="dueDate"
                    label={t('DUE_DATE')}
                    placeholder={t('DUE_DATE')}
                    value={formik.values.dueDate}
                    onChange={(newDate) => {
                      formik.setFieldValue('dueDate', newDate);
                    }}
                    textFieldProps={{
                      sx: {
                        svg: { fill: '#104F96', paddingTop: '0.3vw' },
                      },
                      size: 'small',
                    }}
                    customClass={classes.datePicker}
                    onBlur={formik.handleBlur}
                    minDate={new Date()}
                  />
                </MuiPickersUtilsProvider>
                {formik.errors.dueDate && formik.touched.dueDate && (
                  <span className={classes.errorText}>{formik.errors.dueDate}</span>
                )}
              </Grid>
              <Grid container className={classes.formControlClipIcon}>
                <Grid item>
                  <span className={classes.textFields}>{t('ATTACH_RESOURCES')}             
                    <IconButton aria-label="attach resource">
                      <AttachFile style={{ color: colors.primary }} onClick={handleIconClick}/>
                    </IconButton>
                  </span>
                </Grid>
                <Grid item className={classes.formControlClipIcon}>
                  <RenderAttachmentPicker
                    ref={componentAttachmentPickerRef}
                    className={classes.formControlGrid}
                    id="attachResources"
                    name="attachResources"
                    disabled={false}
                    options={
                      resourceData.map((resource) => ({
                        id: resource.id,
                        name: resource.resourceName,
                      }))
                    }
                    handleChange={handleAttachmentChange}
                    value={formik.values.attachResourcesVal}
                    IconComponent={AttachFile}
                    selectedCourseName={selectedCourseName}
                    selectedCourse={selectedCourse}
                    studentResources={resourceData}
                    nextPage={nextPage}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    loadingResources={loadingResources}
                    totalCount={totalCount}
                    totalCountNum={totalCountNum}
                    setLoadingResources={setLoadingResources}
                    resources={aResources}
                  />
                  {formik.errors.attachResourcesVal && formik.touched.attachResourcesVal && (
                    <span className={classes.errorText}>{formik.errors.attachResourcesVal}</span>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid container xs={{ display: 'center' }} className={classes.popupGrid}>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <ButtonAtom
              className={classes.popupBtn}
              name={t('CANCEL')}
              onClick={() => handlePopUp(false)}
              btntype={Buttons.SECONDARY}
            />
          </Grid>
          <Grid item xs={3}>
            <ButtonAtom
              className={classes.popupBtn}
              name={t('SAVE')}
              btntype={Buttons.PRIMARY}
              type="submit"
              onClick={() => formik.handleSubmit()}
            />
          </Grid>
        </Grid>
      </FormikProvider>
    </Grid>
  );
}

export default EditAssignment;
