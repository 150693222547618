/* eslint-disable no-nested-ternary */
import React, {
  useMemo, useState,
  useEffect, useCallback,
} from 'react';
import {
  Grid, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/fontawesome-free-solid';
import { useTranslation } from 'react-i18next';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import { Button } from '../../../../components/atoms';
import { NavigateRoutes, Buttons } from '../../../../constant';
import { getLocalStorage, setLocalStorage } from '../../../../utils/localStorageMethod';
import useStyles from './style';
import Menu from '../helpers/Menu';
import profilePic from '../../../../assets/images/profileUpload.png';
import Loader from '../../../../components/atoms/loader';
import useUser from '../../../../custom-hooks/useUser';
import CommonProfileImage from '../../../../components/molecules/common/CommonProfileImage';
import MailIcon from '../../../../assets/svg/mailIcon';
import UserIcon from '../../../../assets/svg/userIcon';
import Constant from '../../../../store/constant';
import { getAllFilteredUsers } from '../../../../store/actions/getUser';
import { impersonateUser } from '../../../../store/actions/getAdmin';
import userRolesConst from '../../../../constant/userRoles';
import encryptedUserRoles from '../../../../constant/encryptedUserRoles';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';
import { getCurrentYear, hasRole } from '../../../../utils/methods';

function Row(props) {
  const {
    row,
    handleCheck,
    isSelected,
    index,
    onEditUserClick,
    onUserClick,
    recoveryDialogOpen,
    setEditUserProfile,
    onClickMenu,
    onEditMenuClick,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const encryptedUserRole = getLocalStorage('userRole');
  const loggedInUserRole = decryptedUserRoles[encryptedUserRole];
  const isAdmin = loggedInUserRole === userRolesConst.SYSTEM_ADMIN;

  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;

  const impersonateStudentUser = useCallback(
    async (rowInfo) => {
      dispatch(impersonateUser({
        email: rowInfo?.manabadiEmail,
      }));
    },
    [],
  );
  const onImpersonateClick = async (rowInfo) => {
    const currentYear = getCurrentYear();

    dispatch({
      type: Constant.SET_SELECTED_FILTER,
      payload: [
        {
          label: currentYear.id,
          groupBy: 'Academic Year',
          filterKey: 'academic_year',
        },
        {
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        },
      ],
    });

    dispatch({
      type: Constant.GET_ASSIGNED_LOCATIONS,
      payload: [],
    });
    dispatch({
      type: Constant.GET_ASSIGNED_YEARS,
      payload: [],
    });
    dispatch({
      type: Constant.RESET_STUDENT_COUNT,
      payload: 0,
    });
    dispatch({
      type: Constant.GET_TEACHERS,
      payload: [],
    });
    dispatch({
      type: Constant.SET_SELECTED_LOCATION,
      payload: [],
    });
    dispatch({
      type: Constant.GET_ALL_LOCATION_COURSE,
      payload: [],
    });
    dispatch({
      type: Constant.GET_STUDENTS_BY_LOCATION,
      payload: [],
    });
    dispatch({
      type: Constant.GET_CLASSROOMS,
      payload: [],
    });
    dispatch({
      type: Constant.ADD_CLASSROOMS_SECTIONS,
      payload: [],
    });
    dispatch({
      type: Constant.GET_ANNOUNCEMENTS,
      payload: [],
    });
    dispatch({
      type: Constant.GET_STUDENTS_BY_LOCATION_MARKS,
      payload: [],
    });
    dispatch({
      type: Constant.SET_SECTION_DETAILS,
      payload: [],
    });
    dispatch({
      type: Constant.CLASSES_BY_YEAR,
      payload: [],
    });

    await impersonateStudentUser(rowInfo).finally(() => {
      const userName = getLocalStorage('username');
      const adminProfilePic = localStorage.getItem('profilePhoto');
      localStorage.setItem('tempUserId', rowInfo.id);
      localStorage.setItem('impersonateUser', JSON.stringify({
        state: {
          showView: true,
          email: rowInfo?.manabadiEmail,
          adminUserName: userName,
          profilePic: adminProfilePic,
        },
      }));
      dispatch({
        type: Constant.SET_VIEW,
        payload: 'listView',
      });
      setLocalStorage('username', rowInfo?.userName);
      setLocalStorage('profilePhoto', rowInfo?.profileImage);
      setLocalStorage('useFilters', true);
      setLocalStorage('hasCurriculumRole', hasRole(rowInfo?.roles, userRolesConst.CURRICULUM));
      const RCRole = rowInfo?.roles?.filter((obj) => obj.name === userRolesConst.REGION_COORDINATOR);
      const LCRole = rowInfo?.roles?.filter((obj) => obj.name === userRolesConst.LOCATION_COORDINATOR);
      const TeacherRole = rowInfo?.roles?.filter((obj) => obj.name === userRolesConst.TEACHER);
      const CurriculumRole = rowInfo?.roles?.filter((obj) => obj.name === userRolesConst.CURRICULUM);
      const SURole = rowInfo?.roles?.filter((obj) => obj.name === userRolesConst.SUPER_USER);
      const SARole = rowInfo?.roles?.filter((obj) => obj.name === userRolesConst.SYSTEM_ADMIN);
      if (SURole.length > 0) {
        setLocalStorage('userRole', encryptedUserRoles.SUPER_USER);
        setLocalStorage('tempUserRole', encryptedUserRoles.SUPER_USER);
        setTimeout(() => {
          navigate(NavigateRoutes.ADMIN_DASHBOARD, {
            state: {
              showView: true,
              email: rowInfo?.manabadiEmail,
              adminUserName: userName,
              profilePic: adminProfilePic,
            },
          });
        }, 1200);
      } else if (SARole.length > 0) {
        setLocalStorage('userRole', encryptedUserRoles.SYSTEM_ADMIN);
        setLocalStorage('tempUserRole', encryptedUserRoles.SYSTEM_ADMIN);
        setTimeout(() => {
          navigate(NavigateRoutes.ADMIN_DASHBOARD, {
            state: {
              showView: true,
              email: rowInfo?.manabadiEmail,
              adminUserName: userName,
              profilePic: adminProfilePic,
            },
          });
        }, 1200);
      } else if (RCRole.length > 0) {
        setLocalStorage('userRole', encryptedUserRoles.REGION_COORDINATOR);
        setLocalStorage('tempUserRole', encryptedUserRoles.REGION_COORDINATOR);
        setTimeout(() => {
          navigate(NavigateRoutes.ADMIN_DASHBOARD, {
            state: {
              showView: true,
              email: rowInfo?.manabadiEmail,
              adminUserName: userName,
              profilePic: adminProfilePic,
            },
          });
        }, 1200);
      } else if (LCRole.length > 0) {
        setLocalStorage('userRole', encryptedUserRoles.LOCATION_COORDINATOR);
        setLocalStorage('tempUserRole', encryptedUserRoles.LOCATION_COORDINATOR);
        setLocalStorage('isImpersonateLC', true);
        setTimeout(() => {
          navigate(NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_DASHBOARD, {
            state: {
              showView: true,
              email: rowInfo?.manabadiEmail,
              adminUserName: userName,
              profilePic: adminProfilePic,
            },
          });
        }, 1200);
      } else if (TeacherRole.length > 0) {
        setLocalStorage('userRole', encryptedUserRoles.TEACHER);
        setLocalStorage('tempUserRole', encryptedUserRoles.TEACHER);
        setTimeout(() => {
          navigate(NavigateRoutes.TEACHER_DASHBOARD, {
            state: {
              showView: true,
              email: rowInfo?.manabadiEmail,
              adminUserName: userName,
              profilePic: adminProfilePic,
            },
          });
        }, 1200);
      } else if (CurriculumRole.length > 0) {
        setLocalStorage('userRole', encryptedUserRoles.CURRICULUM);
        setLocalStorage('tempUserRole', encryptedUserRoles.CURRICULUM);
        setTimeout(() => {
          navigate(NavigateRoutes.STUDENTS_RESOURCES, {
            state: {
              showView: true,
              email: rowInfo?.manabadiEmail,
              adminUserName: userName,
              profilePic: adminProfilePic,
            },
          });
        }, 1200);
      }
    });
  };

  const onEmailUser = () => {
  };
  return (
    <TableRow
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox" className={classes.tableCellCheck}>
        <Checkbox
          onClick={() => handleCheck(row)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />
      </TableCell>
      <TableCell
        className={classes.tableCellPictures}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
      >
        <CommonProfileImage key={row.id} src={row.profileImage} />
      </TableCell>
      <TableCell className={classes.tableCellStudent}>
        {row.userName}
      </TableCell>
      <TableCell className={classes.tableCellParent}>
        {row.role}
      </TableCell>
      <TableCell className={classes.tableCellCenter}>
        {row.gender}
      </TableCell>
      <TableCell className={classes.tableCellClass}>
        {row.manabadiEmail}
      </TableCell>
      <TableCell className={classes.tableCellSection}>
        {row.addressText}
      </TableCell>
      <TableCell className={classes.tableCellDate}>
        {row.userStatus}
      </TableCell>
      <TableCell className={classes.tableCellAction}>
        <Grid item xs={12} lg={3} display="flex" alignItems="center">
          <Tooltip title={t('SEND_EMAIL')}>
            <IconButton
              size="large"
              color="inherit"
              onClick={() => {
                const emailUsers = [];
                const personalEmails = [];
                setLocalStorage('showSelectAllinEmail', false);
                setLocalStorage('sendSingleEmail', true);
                setLocalStorage('userManager', true);
                personalEmails.push(row?.personal_email?.toString());
                emailUsers.push(row?.manabadiEmail?.toString());
                dispatch({ type: Constant.MAIL_FILTER, payload: 'User' });
                dispatch({ type: Constant.MAIL_PARENTS, payload: personalEmails });
                dispatch({ type: Constant.RECIPIENTS, payload: emailUsers });
                navigate(NavigateRoutes.ADMIN_EMAIL);
              }}
              aria-label="mail"
            >
              <MailIcon />
            </IconButton>
          </Tooltip>
          {isAdmin && (
            <Tooltip title={t('IMPERSONATE')}>
              <IconButton
                size="large"
                onClick={() => onImpersonateClick(row)}
                disabled={row?.roles.length === 1 && !!row?.roles?.find((obj) => obj.name === userRolesConst.SYSTEM_ADMIN)}
                className={row?.roles.length === 1 && !!row?.roles?.find((obj) => obj.name === userRolesConst.SYSTEM_ADMIN) ? classes.disabled : ''}
              >
                <UserIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t('HELP')}>
            <Menu
              key={row?.id}
              t={t}
              rowInfo={row}
              onUserClick={(rowI) => onUserClick(rowI)}
              onEditUserClick={onEditUserClick}
              recoveryDialogOpen={recoveryDialogOpen}
              onImpersonateClick={(rowI) => onImpersonateClick(rowI)}
              onEmailUser={onEmailUser}
              setEditUserProfile={setEditUserProfile}
              onClickMenu={onClickMenu}
              onEditMenuClick={onEditMenuClick}
              menuViewType="list"
            />
          </Tooltip>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

function DropdownIndicator(props) {
  const { sortType } = props;
  return (
    <div style={{ display: 'grid', gridGap: '1px', paddingLeft: '10px' }}>
      {
        sortType === 'both' && (
          <FontAwesomeIcon icon={faSort} color="rgba(0, 0, 0, 0.54)" />
        )
      }
      {
        sortType === 'asc' && (
          <FontAwesomeIcon icon={faSortUp} color="#C12900" />
        )
      }
      {
        sortType === 'desc' && (
          <FontAwesomeIcon icon={faSortDown} color="#C12900" />
        )
      }
    </div>
  );
}

export default function ListView({
  sortOrder,
  setSortOrder,
  setSortBoth,
  sortBoth,
  onEditUserClick,
  onUserClick,
  recoveryDialogOpen,
  setEditUserProfile,
  onClickMenu,
  onEditMenuClick,
  nextPage,
  pageNum,
  filterOptions,
  setIsFetching,
  isFetching,
  viewInactive,
  viewActiveInactive,
  setViewActiveInactive,
  selectedRows,
  setSelectedRows,
  loggedInUserRole,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [userRoles, setUserRoles] = useState([]);
  const [pagenum, setPagenum] = useState(pageNum);
  const [usersData, setUsersData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [viewSelcteAll, setViewSelcteAll] = useState(false);

  const selectedUserData = useUser();
  const usersRedux = useSelector((state) => state.getUser.totalCount);

  const getUsersData = () => {
    let userData = selectedUserData?.users || [];
    if (!viewInactive) {
      userData = userData?.filter((user) => user?.is_active === true);
    }
    return userData;
  };

  useEffect(() => {
    setUserRoles(selectedUserData.userRoles);
  }, [userRoles, selectedUserData.userRoles]);

  useEffect(() => {
    setUsersData(getUsersData());
  }, [viewInactive, selectedUserData.users]);

  let studentObj = {};
  const rowStudentData = useMemo(() => {
    if (!usersData?.length) return [];
    const rows = [];
    const rows1 = [];
    usersData?.forEach((studentRow, index) => {
      const selectedRoleOptions = [];
      studentRow?.roles?.forEach((selectedVal) => {
        const selectedRole = userRoles.find((ro) => ro.name === selectedVal.name);
        if (selectedRole?.name) selectedRoleOptions.push(selectedRole.name);
      });
      studentObj = {
        ...studentRow,
        id: studentRow?.id,
        key: index,
        profileImage: studentRow?.profile_photo || profilePic,
        userName: `${studentRow?.first_name} ${studentRow?.middle_name} ${studentRow?.last_name}`,
        manabadiEmail: studentRow?.manabadi_email,
        userStatus: studentRow?.is_active ? t('ACTIVE_STATUS') : t('INACTIVE_STATUS'),
        addressText: studentRow?.address?.address == null ? '' : studentRow?.address?.address,
        role: selectedRoleOptions.join(', '),
        gender: studentRow?.gender == null ? '' : studentRow?.gender,
        actions: 'popup',
      };
      rows.push(studentObj);
      if (studentObj.userStatus === 'Active') {
        rows1.push(studentObj);
      }
    });
    if (viewActiveInactive && viewSelcteAll) {
      const newSelecteds = rows1.map((n) => n.id);
      setSelected(newSelecteds);
    }
    if (!viewSelcteAll) {
      setSelected([]);
    }
    setLocalStorage('adminBulkEmailUsers', '');
    return rows;
  }, [usersData]);

  let studentObjDouble = {};
  const rowStudentDataDouble = useMemo(() => {
    if (!usersData?.length) return [];
    const rows = [];
    usersData?.forEach((studentRow, index) => {
      const selectedRoleOptions = [];
      studentRow?.roles?.forEach((selectedVal) => {
        const selectedRole = userRoles.find((ro) => ro.name === selectedVal.name);
        if (selectedRole?.name) selectedRoleOptions.push(selectedRole.name);
      });
      studentObjDouble = {
        ...studentRow,
        id: studentRow?.id,
        key: index,
        profileImage: studentRow?.profile_photo || profilePic,
        userName: `${studentRow?.first_name} ${studentRow?.last_name}`,
        manabadiEmail: studentRow?.manabadi_email,
        userStatus: studentRow?.is_active ? t('ACTIVE_STATUS') : t('INACTIVE_STATUS'),
        addressText: studentRow?.address?.address,
        role: selectedRoleOptions.join(', '),
        gender: studentRow?.gender,
        actions: 'popup',
      };
      rows.push(studentObjDouble);
    });
    setLocalStorage('adminBulkEmailUsers', '');
    return rows;
  }, [usersData]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event) => {
    const emailList = [];
    if (event.target.checked) {
      setViewSelcteAll(true);
      setViewActiveInactive(false);
      const newSelecteds = rowStudentData.map((n) => n.id);
      setSelectedRows(rowStudentData);
      setSelected(newSelecteds);
      const personalEmailList = rowStudentData.map((n) => n.personal_email).filter((x) => x !== null);
      const mbEmailList = rowStudentData.map((user) => user?.manabadi_email).filter((x) => x !== null);
      if (mbEmailList.length > 0) emailList.push(mbEmailList);
      dispatch({ type: Constant.MAIL_PARENTS, payload: personalEmailList });
      setLocalStorage('adminBulkEmailUsers', emailList);
    } else {
      setLocalStorage('adminBulkEmailUsers', '');
      setSelectedRows([]);
      setSelected([]);
      setViewSelcteAll(false);
    }
  };

  const changeSortOrder = (filter, status) => {
    if (sortOrder[status] === 'desc') {
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'both',
      }));
      setSortBoth(true);
    } else if (sortOrder[status] === 'asc') {
      setSortBoth(false);
      if (filter === 'regDate') {
        rowStudentData.sort((a, b) => {
          const aa = a[filter];
          const bb = b[filter];
          return aa < bb ? -1 : (aa > bb ? 1 : 0);
        });
      } else {
        rowStudentData.sort((a, b) => b[filter].localeCompare(a[filter]));
      }
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'desc',
      }));
    } else if (sortOrder[status] === 'both') {
      setSortBoth(false);
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'asc',
      }));
      if (filter === 'regDate') {
        rowStudentData.sort((a, b) => {
          const aa = a[filter];
          const bb = b[filter];
          return aa > bb ? -1 : (aa > bb ? 1 : 0);
        });
      } else {
        rowStudentData.sort((a, b) => a[filter].localeCompare(b[filter]));
      }
    }
  };

  const handleCheck = (rowData) => {
    const selectedIndex = selected.indexOf(rowData.id);
    let newSelected = [];
    let aNewSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowData.id);
      aNewSelected = aNewSelected.concat(selectedRows, rowData);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      aNewSelected = aNewSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      aNewSelected = aNewSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      aNewSelected = aNewSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    const emailList = [];
    const personalEmailList = aNewSelected.map((n) => n.personal_email).filter((x) => x !== null);
    const mbEmailList = aNewSelected.map((user) => user?.manabadi_email).filter((x) => x !== null);
    if (mbEmailList.length > 0) emailList.push(mbEmailList);
    dispatch({ type: Constant.MAIL_PARENTS, payload: personalEmailList });
    setLocalStorage('adminBulkEmailUsers', emailList);

    setSelectedRows(aNewSelected);
    setSelected(newSelected);
  };
  const loadMoreItems = (length) => {
    const searchTextString = localStorage.getItem('searchTextUserManager') || '';
    const filterPayload = { filters: {}, searchText: [searchTextString], loggedInUserRole };
    filterOptions.forEach((filter) => {
      if (!filter?.options?.every((opt) => opt?.checked === false)) {
        const selectedOption = [];
        filter?.options?.forEach((option) => {
          if (option.checked) selectedOption.push(option?.value);
        });
        filterPayload.filters[filter?.filter] = selectedOption;
      }
    });
    filterPayload.timePeriod = 'year';
    if (length < usersRedux) {
      let page = pagenum;
      if (Object.keys(filterPayload.filters).length > 0) {
        page = pagenum + 1;
        setPagenum(page);
      } else {
        page = pagenum + 1;
        setPagenum(page);
      }
      setIsFetching(true);
      dispatch(
        getAllFilteredUsers(
          page,
          50,
          nextPage,
          false,
          usersData.length,
          setIsFetching,
          filterPayload,
        ),
      );
    }
  };
  return (
    <Grid container className={`${classes.userDetails} ${classes.withoutScroll}`}>
      {isFetching ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : rowStudentData?.length > 0
        ? (
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" className={classes.check}>
                    <Checkbox
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < rowStudentData.length}
                      checked={rowStudentData.length > 0 && selected.length === rowStudentData.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.picture}>{t('PICTURES')}</TableCell>
                  <TableCell className={classes.studentName}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('userName', 'studentN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.studentN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('NAME')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.parentName}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('role', 'roleNameN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.roleNameN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('ROLE_NAME')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.centerInfo}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('gender', 'genderN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.genderN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('GENDER')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.classLevel}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('manabadiEmail', 'emailN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.emailN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('EMAIL')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.section}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('addressText', 'addressN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.addressN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('ADDRESS')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.regDate}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('userStatus', 'statusN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.statusN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('STATUS')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.actions}>{t('ACTIONS')}</TableCell>
                </TableRow>
              </TableHead>
              {
                sortBoth === false && (
                  <TableBody>
                    {rowStudentData.map((row, index) => (
                      <Row
                        row={row}
                        index={index}
                        onEditUserClick={onEditUserClick}
                        onUserClick={onUserClick}
                        recoveryDialogOpen={recoveryDialogOpen}
                        setEditUserProfile={setEditUserProfile}
                        onClickMenu={onClickMenu}
                        onEditMenuClick={onEditMenuClick}
                        isSelected={isSelected}
                        handleCheck={handleCheck}
                      />
                    ))}
                    <TableRow>
                      <TableCell className={classes.totalRowCount} colSpan={10}>
                        <span>
                          {t('TOTAL_USERS')}
                          {' '}
                          {rowStudentData?.length}
                          {' '}
                          of
                          {' '}
                          {usersRedux}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.loadMore} colSpan={10}>
                        {isFetching && <p>{t('FETCHING_USERS')}</p>}
                        {!isFetching && selectedUserData.users?.length < usersRedux && (
                          <Button
                            name={t('LOAD_MORE')}
                            btntype={Buttons.PRIMARY}
                            onClick={() => loadMoreItems(rowStudentData?.length)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              }
              {
                sortBoth === true && (
                  <TableBody>
                    {rowStudentDataDouble.map((row, index) => (
                      <Row
                        row={row}
                        index={index}
                        onEditUserClick={onEditUserClick}
                        onUserClick={onUserClick}
                        recoveryDialogOpen={recoveryDialogOpen}
                        setEditUserProfile={setEditUserProfile}
                        onClickMenu={onClickMenu}
                        onEditMenuClick={onEditMenuClick}
                        isSelected={isSelected}
                        handleCheck={handleCheck}
                      />
                    ))}
                    <TableRow>
                      <TableCell className={classes.totalRowCount} colSpan={10}>
                        <span>
                          {t('TOTAL_USERS')}
                          {' '}
                          {rowStudentDataDouble?.length}
                          {' '}
                          of
                          {' '}
                          {usersRedux}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.loadMore} colSpan={10}>
                        {isFetching && <p>{t('FETCHING_USERS')}</p>}
                        {!isFetching && selectedUserData.users?.length < usersRedux && (
                          <Button
                            name={t('LOAD_MORE')}
                            btntype={Buttons.PRIMARY}
                            onClick={() => loadMoreItems(rowStudentDataDouble?.length)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              }
            </Table>
          </TableContainer>
        ) : rowStudentData.length === 0 ? (
          <Grid container className={classes.noData}>
            <Grid item xs={12}>
              <DoNotDisturbIcon />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                {t('NO_DATA')}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Loader message={t('LOADING')} />
          </Grid>
        )}
    </Grid>
  );
}
