import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';

export default function CheckboxesAtom({
  checked, label, handleChange, disabled, onKeyDown,
}) {
  const classes = useStyles(styles)();

  return (
    <FormGroup>
      <FormControlLabel
        className={classes.formControl}
        control={
          <Checkbox className={disabled ? classes.disabled : ''} checked={checked} onChange={handleChange} name="gilad" disabled={disabled} onKeyDown={onKeyDown} size="small" />
        }
        style={{ marginRight: '315px' }}
        label={label}
      />
    </FormGroup>
  );
}
