import React from 'react';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  Box,
  Grid,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { useDispatch } from 'react-redux';
import style from './style';
import useStyles from '../../../custom-hooks/useStyles';
import {
  setLocalStorage,
} from '../../../utils/localStorageMethod';
import { NavigateRoutes } from '../../../constant';
import ButtonAtom from '../../../components/atoms/button';
import ManabadiHeader from '../../common/view-header/manabadi-header/manabadi-header';
import encryptedUserRoles from '../../../constant/encryptedUserRoles';
import Constant from '../../../store/constant';

export default function CoordinatorDashboard() {
  const classes = useStyles(style)();
  const { state } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      {
        JSON.parse(localStorage.getItem('impersonateUser'))
        && (
          <Grid className={classes.headerViewingDiv}>
            <div className={classes.headerViewing}>
              <p>Viewing as</p>
              <p><span>{state?.email ? state?.email : JSON.parse(localStorage.getItem('impersonateUser')).state.email}</span></p>
              <ButtonAtom
                className={classes.exitBtn}
                onClick={() => {
                  setLocalStorage('userRole', encryptedUserRoles.SYSTEM_ADMIN);
                  localStorage.removeItem('tempUserId');
                  setLocalStorage('username', state?.adminUserName ? state?.adminUserName : JSON.parse(localStorage.getItem('impersonateUser')).state.adminUserName);
                  setLocalStorage('profilePhoto', state?.profilePic ? state?.profilePic : JSON.parse(localStorage.getItem('impersonateUser')).state.profilePic);
                  localStorage.removeItem('impersonateToken');
                  localStorage.removeItem('impersonateUser');
                  setLocalStorage('tempUserRole', encryptedUserRoles.SYSTEM_ADMIN);
                  dispatch({
                    type: Constant.IMPERSONATE_USER,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.GET_ASSIGNED_LOCATIONS,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.GET_ASSIGNED_YEARS,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.RESET_STUDENT_COUNT,
                    payload: 0,
                  });
                  dispatch({
                    type: Constant.GET_TEACHERS,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.SET_SELECTED_LOCATION,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.GET_ALL_LOCATION_COURSE,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.GET_STUDENTS_BY_LOCATION,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.GET_CLASSROOMS,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.ADD_CLASSROOMS_SECTIONS,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.GET_ANNOUNCEMENTS,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.GET_STUDENTS_BY_LOCATION_MARKS,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.SET_SECTION_DETAILS,
                    payload: [],
                  });
                  navigate(NavigateRoutes.USER_MANAGER_VIEW, { state: { previousFilters: state?.filterOptions } });
                }}
                name={t('EXIT')}
                icon={<ExitToApp />}
              />
            </div>
          </Grid>
        )
      }
      <Box className={classes.headerMargin}>
        <ManabadiHeader />
      </Box>
    </Stack>
  );
}
