import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import { setSelectedYear, setSelectedClassRooms } from '../../../store/actions/getTeacherView';
import useStyles from '../../../custom-hooks/useStyles';
import { Button, PerformantDropdown } from '../../../components/atoms';
import commonStyle from '../../../utils/commonClasses';

export default function AttendanceFilters({
  formik,
  classes,
  years = [],
  courses = [],
  classWeeks = [],
  noOfAbsentStudents,
  isAttendanceRecording,
  setIsAttendanceRecording,
  studentList,
  setAbsentStudentIds,
  setPresentStudentIds,
}) {
  const { t } = useTranslation();
  const commonClasses = useStyles(commonStyle)();
  const dispatch = useDispatch();
  const yearChange = (e) => {
    formik.setFieldValue('year', e?.target?.value);
    formik.setFieldValue('course', '');
    formik.setFieldValue('classweek', '');
    const payload = {
      id: e.target.value,
      name: e.target.value,
    };
    dispatch(setSelectedYear(payload));
  };

  const courseChange = (e) => {
    formik.setFieldValue('course', e?.target?.value);
    const selectedCourse = courses?.find((c) => c.id === e?.target?.value);
    localStorage.setItem('CourseTeacher', selectedCourse.courseId);
    formik.setFieldValue('classweek', '');
    dispatch(setSelectedClassRooms(e.target));
  };

  const onRecordAttendance = () => {
    const absentList = [];
    const presentList = [];
    studentList.forEach((obj) => {
      obj?.student?.studentHomeworkMarks?.forEach((stu) => {
        if (stu?.studentHomeworkMarksDetail.length > 0) {
          if (!stu.studentHomeworkMarksDetail?.[0]?.isPresent) {
            absentList.push(obj.studentId);
          } else {
            presentList.push(obj.studentId);
          }
        }
      });
    });
    setAbsentStudentIds(absentList);
    setPresentStudentIds(presentList);
    setIsAttendanceRecording(true);
  };
  useEffect(() => {
    if (studentList.length > 0) {
      const absentList = [];
      studentList.forEach((obj) => {
        obj?.student?.studentHomeworkMarks?.forEach((stu) => {
          if (stu?.studentHomeworkMarksDetail.length > 0) {
            if (!stu.studentHomeworkMarksDetail?.[0]?.isPresent) {
              absentList.push(obj.studentId);
            }
          }
        });
      });
      setAbsentStudentIds(absentList);
    }
  }, [studentList]);

  return (
    <FormikProvider value={formik}>

      <Grid container direction="row" className={classes?.dropdowns}>
        <Grid item xs={6} sm={2} md={2} className={classes?.year}>
          <PerformantDropdown
            minWidth="100%"
            label={t('YEAR')}
            labelId={t('YEAR')}
            id="year"
            name="year"
            value={formik.values.year}
            handleChange={(e) => { yearChange(e); }}
            options={years}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} sm={3} md={4} className={classes?.courseDropdown}>
          <PerformantDropdown
            maxWidth="50%"
            label={t('COURSES')}
            labelId={t('COURSES')}
            id="course"
            name="course"
            value={formik?.values?.course}
            handleChange={(e) => { courseChange(e); }}
            options={courses}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} sm={2} md={3} className={classes?.courseDropdown}>
          <PerformantDropdown
            maxWidth="50%"
            label={t('CLASS_WEEK')}
            labelId={t('CLASS_WEEK')}
            id="classWeek"
            name="classWeek"
            value={formik?.values?.classWeek}
            handleChange={formik?.handleChange}
            options={classWeeks}
            variant="standard"
          />
        </Grid>
        <Grid item xs={5} sm={3} md={3} className={classes?.recordButtons}>
          {isAttendanceRecording ? (
            <div>
              <div>
                <span className={classes?.absentStudentsHeading}>
                  {t('NO_OF_STUDENT_ABSENT')}
                </span>
              </div>
              <div><span className={classes?.absentStudents}>{noOfAbsentStudents}</span></div>
            </div>
          ) : studentList.length > 0 && (
            <Button
              id="submit"
              className={commonClasses.activeButton}
              onClick={() => onRecordAttendance()}
              name={t('RECORD_ATTENDANCE')}
            />
          )}
        </Grid>
      </Grid>
    </FormikProvider>
  );
}
