import moment from 'moment';
import { getCourseByAgeLocationService, getCoursesForBacklogRegistratrion } from './auth';

const getCourseByAge = async (dob, location, isAdminUser) => {
  const year = new Date(dob).getFullYear();
  const currentYear = new Date().getFullYear();
  const dateFormat = 'DD-MM-YYYY';
  const toDateFormat = moment(new Date(dob)).format(dateFormat);
  const isValid = moment(toDateFormat, dateFormat, true).isValid();
  if (isValid && year >= 1900 && year <= currentYear) {
    const payload = {
      locationId: location,
      dateOfBirth: moment(dob).format('YYYY-MM-DD HH:mm:ss'),
      isAdminUser,
    };
    const response = await getCourseByAgeLocationService(payload);
    return response.data.map((option) => option.course);
  }
  return [];
};

const getLocationsForBacklogRegistration = async (dob, location) => {
  if (location) {
    const payload = {
      locationId: location,
      dateOfBirth: moment(dob).format('YYYY-MM-DD HH:mm:ss'),
    };
    const response = await getCoursesForBacklogRegistratrion(payload);
    return response.data.map((option) => option.course);
  }
  return [];
};

export {
  getCourseByAge,
  getLocationsForBacklogRegistration,
};
