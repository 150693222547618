import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import {
  Box, Grid, Typography, FormHelperText,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { red } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import style from './style';
import useStyles from '../../../../custom-hooks/useStyles';
import { Buttons } from '../../../../constant';
import ButtonAtom from '../../../../components/atoms/button';
import { DialogAtom } from '../../../../components/atoms';
import {
  fetchAllPstuFaqs, addPstu, updatePstu, updatePstuEdit,
} from '../../../../store/actions/getAdmin';
import TextEditor from './textEditor';
import Loader from '../../../../components/atoms/loader';

export default function SimpleAccordion() {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState('panel1');
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const reduxStore = useSelector((state) => state?.getAdmin);
  const [loading, setLoading] = useState(true);
  const [pstuFaqs, setPstuFaqs] = useState([]);
  const [hasError, setHasError] = useState(false);
  const classes = useStyles(style)();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDelete = (faq) => {
    setSelectedFAQ(faq);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const formik = useFormik({
    initialValues: {
      question: '',
      questionNumber: '',
      answer: '',
    },
  });
  const handleCloseEdit = () => {
    setOpenEdit(false);
    formik.setFieldValue('answer', '');
    formik.setFieldValue('question', '');
    formik.setFieldValue('questionNumber', '');
  };
  const handleSaveEdit = async () => {
    const errors = {};
    setHasError(Object.keys(errors).length > 0);

    if (!formik.values.question.trim()) {
      errors.question = 'Question is required';
    }

    if (!formik.values.answer.trim()) {
      errors.answer = 'Answer is required';
    }

    formik.setErrors(errors);

    if (Object.keys(errors).length === 0) {
      const payload = {
        id: selectedFAQ.id,
        question: formik.values.question,
        answer: formik.values.answer,
      };

      try {
        await dispatch(updatePstuEdit(payload));
        const updatedPstuFaqs = pstuFaqs.map((faq) => (faq.id === selectedFAQ.id ? { ...faq, ...payload } : faq));
        setPstuFaqs(updatedPstuFaqs);
        setSelectedFAQ(null);
        handleCloseEdit();
        formik.setFieldValue('answer', '');
        formik.setFieldValue('question', '');
        formik.setFieldValue('questionNumber', '');
      } catch (error) {
        // Leave comment to avoid "Empty Block Statement" Error
      }
    }
  };

  const handleOpenEdit = (faq) => {
    setSelectedFAQ(faq);
    formik.setValues({
      question: faq.question,
      questionNumber: faq.questionNumber,
      answer: faq.answer,
    });

    setOpenEdit(true);
  };

  useEffect(() => {
    setPstuFaqs(reduxStore?.fetchAllPstuFaqs);
    setLoading(false);
  }, [reduxStore?.fetchAllPstuFaqs]);

  function stripHtmlTags(html) {
    const allowedTags = ['b', 'i', 'u', 'ul', 'ol', 'li', 'strong']; // Add more tags as needed
    const doc = new DOMParser().parseFromString(html, 'text/html');

    const filterNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      }

      if (node.nodeType === Node.ELEMENT_NODE && allowedTags.includes(node.tagName.toLowerCase())) {
        const filteredChildren = Array.from(node.childNodes).map(filterNode);
        const attributes = Array.from(node.attributes).map((attr) => `${attr.name}="${attr.value}"`).join(' ');

        return `<${node.tagName.toLowerCase()}${attributes ? ` ${attributes}` : ''}>${filteredChildren.join('')}</${node.tagName.toLowerCase()}>`;
      }

      return Array.from(node.childNodes).map(filterNode).join('');
    };

    return filterNode(doc.body);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchAllPstuFaqs());
        const updatedFaqs = response?.data
          .map((faq) => ({ ...faq, answer: stripHtmlTags(faq.answer) }))
          .sort((a, b) => a.id - b.id);

        setPstuFaqs(updatedFaqs);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const refreshList = () => {
    setLoading(true);
    dispatch(fetchAllPstuFaqs());
  };

  const handleSave = () => {
    const errors = {};
    formik.setErrors(errors);
    setHasError(Object.keys(errors).length > 0);

    if (!formik.values.question.trim()) {
      errors.question = 'Question is required';
    }

    if (!formik.values.answer.trim()) {
      errors.answer = 'Answer is required';
    }

    formik.setErrors(errors);

    if (Object.keys(errors).length === 0) {
      const payload = {
        question: formik.values.question,
        answer: formik.values.answer,
        isActive: true,
      };

      dispatch(addPstu(payload))
        .then(() => {
          handleClose();
          formik.setFieldValue('answer', '');
          formik.setFieldValue('question', '');
          formik.setFieldValue('questionNumber', '');
          return refreshList();
        });
    }
  };
  const handleConfirmDelete = () => {
    const payload = {
      question: selectedFAQ?.question,
      answer: selectedFAQ?.answer,
      id: selectedFAQ?.id,
      isActive: false,
    };

    dispatch(updatePstu(payload))
      .then(() => {
        handleCloseDelete();
        formik.setFieldValue('answer', '');
        formik.setFieldValue('question', '');
        formik.setFieldValue('questionNumber', '');
        return refreshList();
      });
  };

  useEffect(() => {
    refreshList();
  }, []);

  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item sx={{ alignItems: 'center' }}>
          <Typography className={classes.headerTitle}>
            {t('PSTU_CONFIG')}
          </Typography>
        </Grid>
        <Grid item sx={{ alignItems: 'right' }}>
          <ButtonAtom
            onClick={handleOpen}
            btntype={Buttons.PRIMARY}
            className={classes.addbutton}
            name={t('ADD')}
            icon={<AddIcon />}
          />
        </Grid>
      </Grid>
      {!loading && (
        <div className={classes.pstuAccordion}>
          {pstuFaqs && pstuFaqs.map((data) => (
            <Accordion
              key={data.id}
              expanded={expanded === data.id}
              onChange={handleChange(data.id)}
              className={classes.accordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container spacing={1} className={classes.alignContainer}>

                  <Grid
                    item
                    xs={10.5}
                    className={classes.alignGridAns}
                  >
                    <Typography>
                      <strong>{`Question ${data.questionNumber}: ${data.question}`}</strong>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={1.5}
                    className={classes.alignGrid}
                  >
                    <ButtonAtom
                      btntype={Buttons.ICON}
                      className={classes.editButton}
                      icon={<EditOutlinedIcon />}
                      onClick={() => handleOpenEdit(data)}
                    />
                    <ButtonAtom
                      btntype={Buttons.ICON}
                      className={classes.deleteButton}
                      icon={<DeleteForeverOutlinedIcon sx={{ color: red[500] }} />}
                      onClick={() => handleOpenDelete(data)}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} className={classes.alignContainer}>
                  <Grid
                    item
                    xs={10.5}
                    className={classes.alignGridAns}
                  >
                    <Typography component="div" variant="body1" dangerouslySetInnerHTML={{ __html: data.answer }} />

                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
      {loading ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )
        : null}

      <div>
        <DialogAtom
          isOpen={open}
          dialogHeading={t('ADD_QUESTION')}
          onClose={handleClose}
          dialogActions={false}
          disableEnforceFocus
          content={(
            <FormikProvider value={formik}>
              <Grid container spacing={1} className={classes.alignContainer}>
                <Grid
                  item
                  xs={12}
                  className={classes.alignGri}
                  alignSelf="center"
                >
                  <Typography className={classes.questionHeading}>
                    {`Question ${pstuFaqs ? pstuFaqs.length + 1 : ''}:`}
                  </Typography>
                  <TextField
                    label={`${t('Question')}`}
                    id="question"
                    name="question"
                    value={formik.values.question}
                    onChange={formik.handleChange}
                    error={!!formik.errors.question}
                    helperText={formik.errors.question}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${classes.alignContainerAns} ${hasError ? classes.joditError : ''}`}
                  alignSelf="center"
                  sx={{ borderColor: 'error.main' }}
                >
                  <Typography className={classes.answerHeading}>
                    {t('Answer :')}
                  </Typography>
                  <TextEditor
                    name="answer"
                    id="answer"
                    setFieldValue={(val) => formik.setFieldValue('answer', val)}
                    value={formik.values.answer}
                    error={!!formik.errors.answer}
                    helperText={formik.errors.answer}
                  />
                  {formik.errors.answer && (
                  <FormHelperText error>
                    {formik.errors.answer}
                  </FormHelperText>
                  )}
                </Grid>

                <Grid container>
                  <Grid item xs={12} className={classes.popupButtons}>
                    <ButtonAtom
                      btntype={Buttons.SECONDARY}
                      onClick={handleClose}
                      name={t('CANCEL')}
                    />
                    <ButtonAtom
                      onClick={handleSave}
                      btntype={Buttons.PRIMARY}
                      name={t('ADD')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </FormikProvider>
          )}
          primaryHandle={handleSave}
          secHandle={handleClose}
        />
      </div>
      <div>
        <DialogAtom
          isOpen={openEdit}
          dialogHeading={t('EDIT_QUESTION')}
          onClose={handleCloseEdit}
          dialogActions={false}
          disableEnforceFocus
          content={(
            <FormikProvider value={formik}>
              <Grid container spacing={1} className={classes.alignContainer}>
                <Grid
                  item
                  xs={12}
                  className={classes.alignGid}
                  alignSelf="center"
                >
                  <Typography className={classes.questionHeading}>
                    {`Question ${formik.values.questionNumber}:`}
                  </Typography>
                  <TextField
                    label={`${t('Question')}`}
                    id="question"
                    name="question"
                    value={formik.values.question}
                    onChange={formik.handleChange}
                    error={!!formik.errors.question}
                    helperText={formik.errors.question}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.alignGid}
                  alignSelf="center"
                >
                  <Typography className={classes.answerHeading}>
                    {t('Answer :')}
                  </Typography>
                  <TextEditor
                    name="answer"
                    id="answer"
                    setFieldValue={(val) => formik.setFieldValue('answer', val)}
                    value={formik.values.answer}
                  />
                  {formik.errors.answer && (
                  <FormHelperText error>
                    {formik.errors.answer}
                  </FormHelperText>
                  )}
                </Grid>

                <Grid container>
                  <Grid item xs={12} className={classes.popupButtons}>
                    <ButtonAtom
                      btntype={Buttons.SECONDARY}
                      onClick={handleCloseEdit}
                      name={t('CANCEL')}
                    />
                    <ButtonAtom
                      onClick={handleSaveEdit}
                      btntype={Buttons.PRIMARY}
                      name={t('UPDATE')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </FormikProvider>
          )}
          secHandle={handleCloseEdit}
        />
      </div>
      <div>
        <DialogAtom
          isOpen={openDelete}
          dialogHeading={t('DELETE_QUESTION')}
          onClose={handleCloseDelete}
          dialogActions={false}
          content={(
            <Grid container spacing={1} className={classes.alignContainer}>
              <Grid
                item
                xs={12}
                className={classes.aligGrid}
              >
                <Typography className={classes.questionHeading}>
                  {t('DELETE_CONFIRMATION')}
                </Typography>
              </Grid>

              <Grid container>
                <Grid item xs={12} className={classes.popupButtons}>
                  <ButtonAtom
                    btntype={Buttons.SECONDARY}
                    onClick={handleCloseDelete}
                    name={t('CANCEL')}
                  />
                  <ButtonAtom
                    onClick={handleConfirmDelete}
                    btntype={Buttons.ERROR}
                    name={t('DELETE')}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          secHandle={handleCloseDelete}
        />
      </div>
    </Box>
  );
}
