/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
/* eslint-disable */
import React, { useEffect } from 'react';

import JoditEditor from './editor-relate';

function Form({
  id, setCursorPosition, setCursorLineText, setFieldValue, value, config,
}) {
  useEffect(() => {
  }, [value]);

  const handleWYSIWYGChange = (newTextAreaValue) => {
    setFieldValue(newTextAreaValue);
  };

const handleEditorClick = () => {
  const editor = document.getElementById('message');
  
  if (editor) {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const clickedNode = range.commonAncestorContainer;
      let lineContent = '';
      if (clickedNode.textContent.trim() !== '') {
        lineContent = clickedNode.textContent;
      } else {
        lineContent = clickedNode.textContent.trim();
      }

      const currentPosition = selection.anchorOffset + 1;
      setCursorPosition(currentPosition);
      setCursorLineText(lineContent);
    }
  }
};

  return (
    <div style={{ width: '84%' }}>
      <JoditEditor
        id={id}
        config={config}
        onChange={handleWYSIWYGChange}
        value={value || ''}
        onClick={handleEditorClick}
      />
    </div>
  );
}

export default Form;
