import { colors, fonts } from '../../../../theme';
import listIcon from '../../../../assets/images/listIcon.png';

const editUserstyle = (() => ({
  viewLogs: {
    fontFamily: fonts.fontType.roboto,
    fontWeight: fonts.fontWeight.medium,
    display: 'flex',
    color: `${colors.actionIconsColor} !important`,
    cursor: 'pointer',
    textAlign: 'right',
    alignItems: 'center',
    marginTop: '1vw',
    '& div': {
      marginLeft: '0.2vw',
      margintop: '0.2vw',
    },
  },
  list: {
    listStyleImage: `url(${listIcon})`,
    paddingLeft: '4%',
    marginBottom: '3px',
    lineHeight: '1.5em',
    color: colors.placeHolderColor,
    fontFamily: 'inherit !important',
    '& li': {
      paddingLeft: '0.7em',
    },
    textAlign: 'start',
  },
  switchText: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw !important',
    },
    textAlign: 'center',
  },
  switchHeading: {
    '@media (min-width: 1200px)': {
      fontSize: '1vw !important',
    },
    fontWeight: fonts.fontWeight.bold,
    textAlign: 'center',
  },
  errorText: {
    color: colors.errorText,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    display: 'block',
  },
  alignGrid: {
    '@media (min-width: 1200px)': {
      height: '5vw',
      padding: 0,
      maxWidth: '33.2%',
    },
    '& #addressAutoComplete': {
      width: '100%',
      height: '70px',
      padding: '0.7vw',
      marginBottom: '9px',
      '@media (max-width: 600px)': {
        height: '15vw',
        fontSize: '4.5vw',
        paddingLeft: '15px',
      },
    },
    '& .PhoneInput': {
      borderColor: 'red',
      '@media (max-width: 1199px)': {
        height: '60px !important',
      },
      '@media (min-width: 1200px)': {
        width: '100%',
        height: '52px',
        borderRadius: '8px',
        padding: '0 5px',
        border: '0.1vw solid #104F96',
        '& .makeStyles-errorBorder:focus-within, .makeStyles-errorBorder.PhoneInput-focus': {
          border: 'unset',
        },
        '& input': {
          border: 'unset',
          outline: 'unset',
          boxShadow: 'none',
        },
      },
      '@media (max-width: 2560px), @media (min-width: 2100px)': {
        height: '3em',
        fontSize: '0.9vw',
        '@media (max-width: 600px)': {
          height: '20em !important',
        },
      },
      '& .PhoneInputInput': {
        height: '1.8vw',
        '@media (max-width: 1199px)': {
          border: '0.1vw solid #104F96',
          height: '50px !important',
        },
        '@media (max-width: 600px)': {
          height: '15vw !important',
          fontSize: '4.5vw',
          paddingLeft: '15px',
        },
      },
    },
    '& .PhoneInputCountry': {
      marginRight: 0,
      paddingLeft: 5,
      display: 'none',
    },
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      '& .MuiFormControl-root': {
        margin: 'auto',
        maxWidth: '350px !important',
        width: '98%',
      },
      '& div': {
        margin: 0,
      },
    },
    '& .MuiFormControl-root': {
      margin: '0.5vw 0',
      borderColor: `${colors.primary} !important`,
      '& .MuiFormControl-root': {
        margin: 0,
        width: '100% !important',
      },
    },
    '& .MuiTextField-root': {
      '@media (min-width: 1200px)': {
        width: '100% !important',
        margin: 0,
      },
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      marginTop: 0,
      '@media (min-width: 1200px)': {
        height: '2.7vw',
      },
      borderRadius: '0.4vw',
      width: '100%',
    },
  },
  imageAlign: {
    textAlign: 'center !important',
    '& > span': {
      marginLeft: '0px !important',
    },
  },
  inViewLogButton: {
    '&.MuiButton-textPrimary': {
      minWidth: '40% !important',
    },
  },
  inActiveText: {
    color: colors.redShade,
  },
  activeText: {
    color: colors.greenShade,
  },
}));

export default editUserstyle;
