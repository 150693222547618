/* eslint-disable */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Grid, Typography, List,
  ListSubheader,
  ListItem,
  ListItemText,
  IconButton,
  ListItemButton,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GridSearchIcon } from '@mui/x-data-grid';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextEditor from './textEditor';
import ButtonAtom from '../../../../components/atoms/button';
import { DropdownEmailTemplate } from '../../../../components/atoms';
import DialogAtom from '../../../../components/atoms/dialog';
import TextFieldAtom from '../../../../components/atoms/text-field-with-label';
import { Buttons } from '../../../../constant';
import useStyles from '../../../../custom-hooks/useStyles';
import style from './styles';
import commonStyle from '../../../../utils/commonClasses';
import Loader from '../../../../components/atoms/loader';
import { servicegetWelcomeEmail, servicePostWelcomeEmail } from '../../../../store/actions/getAdmin';
import { getAllCourses } from '../../../../store/actions/getStudent';
import { substitutionTagsAdmin } from './editor-relate';
import { NavigateRoutes } from '../../../../constant';

function SettingTermsAndConditions() {
  const adminData = useSelector((state) => state?.getAdmin);
  const courseData = useSelector((state) => state?.getStudent);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [oCourses, setOCourses] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [cursorLineText, setCursorLineText] = useState('')
  const [config, setConfig] = useState({
    readonly: true,
    toolbar: true,
  });
  const classes = useStyles(style)();
  const registerClasses = useStyles(commonStyle)();
  const [searched, setSearched] = useState('');
  const navigate = useNavigate();
  const welcomeEmail = adminData?.welcomeEmailInfo;
  const aCourses = courseData?.courses;
  const validationSchema = Yup.object({
    logText: Yup.string(t('CHANGE_LOG_REQUIRED'))
      .required(t('CHANGE_LOG_REQUIRED'))
      .typeError(t('CHANGE_LOG_REQUIRED')),
    subject: Yup.string(t('MAIL_SUBJECT_REQUIRED'))
      .required(t('MAIL_SUBJECT_REQUIRED'))
      .typeError(t('MAIL_SUBJECT_REQUIRED')),
  });
  const formik = useFormik({
    initialValues: {
      message: '',
      id: '',
      type: 'registration',
      courseId: '',
      logText: '',
      subject: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const payload = {
        message: values.message,
        id: values.id,
        type: values.type,
        courseId: values.courseId,
        logText: values.logText,
        subject: values.subject,
      }
      dispatch(servicePostWelcomeEmail(payload, setLoading, setIsOpenError));
    },
  });
  const options = [
    { id: 'registration', name: 'New Registration' },
    { id: 'enroll', name: 'Existing Student Registration' },
    { id: 'donation', name: 'Donation' },
  ];
  useEffect(() => {
    dispatch(getAllCourses());
  }, []);
  useEffect(() => {
    formik.setFieldValue('message', welcomeEmail?.body);
    formik.setFieldValue('id', welcomeEmail?.id);
    formik.setFieldValue('subject', welcomeEmail?.subject);
  }, [welcomeEmail]);
  useEffect(() => {
    if (formik.values.courseId === '' || formik.values.courseId === undefined || formik.values.courseId === null) {
      formik.setFieldValue('courseId', oCourses[0]?.id);
    }
  }, [oCourses]);

  useEffect(() => {
    if (formik.values.type === 'registration') {
      const filteredCourses = aCourses.filter((e) => {
        return e.name === 'BalaBadi - II' || e.name === 'Telugu-1' || e.name === 'BalaBadi - I' || e.name === 'Pravesam';
      });
      setOCourses(filteredCourses);
    } else {
      setOCourses(aCourses);
    }

    if (formik.values.type && formik.values.courseId) {
      dispatch(servicegetWelcomeEmail(formik.values.type, formik.values.courseId));
    }
  }, [formik.values.type, formik.values.courseId, aCourses]);

  const handleCancel = () => {
    if (isOpenError) {
      setIsOpenError(false);
    }
    formik.setFieldValue('logText', '');
    formik.setFieldValue('message', welcomeEmail?.body);
    setConfig({
      readonly: true,
      toolbar: true,
    });
  };

  const getError = (key) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={`${classes.errorText} ${registerClasses.errorText}`}>
      {formik.errors[key]}
    </span>
  ) : null);
  const substitutionTagButton = (el, item) => {
    const subTag = ` {{${item.key}}} `;
    let value = formik.values.message.replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&');
    if (value && cursorLineText !== '') {
      const position = value.indexOf(cursorLineText);
      if (position !== -1) {
        const strInital = cursorLineText.substring(0, (cursorPosition - 1));
        const strFinal = cursorLineText.substring((cursorPosition - 1), cursorLineText.length);
        const newLine = strInital + subTag + strFinal;
        var regex = new RegExp(cursorLineText);
        let newValue = value.replace(regex, newLine);
        if (value === newValue) {
          const newStrInitial = value.substring(0, position);
          const newStrFinal = value.substring(((newStrInitial.length + 1) + cursorLineText.substring(1, cursorLineText.length).length), value.length);
          const completedString = newStrInitial + newLine.replace('<', '').replace('>', '') + newStrFinal;
          formik.setFieldValue('message', completedString);
        } else {
          formik.setFieldValue('message', newValue);
        }
      }
    }
  };

  const viewLogs = () => {
    navigate(NavigateRoutes.STUDENTS_LOGS, {
      state: {
        id: formik.values.id,
        lastRoute: NavigateRoutes.WELCOME_EMAIL,
        logVal: true,
      },
    });
  };

  return (
    <FormikProvider value={formik}>
      <Grid container className={classes.container}>
        <Grid container item className={classes.titleRow}>
          <Grid item container sx={{ alignItems: 'center' }}>
            <Typography className={classes.headerTitle}>
              {t('WELCOME_EMAIL')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.dropdownWrapper}>
          <Grid item xs={7} lg={2}>
            <DropdownEmailTemplate
              label={`${t('SELECT_TEMPLATE')}`}
              required
              id="type"
              name="type"
              labelId="type"
              value={formik.values.type}
              options={options}
              shrink
              handleChange={(e) => {
                formik.setFieldValue('type', e.id);
              }}
            />
          </Grid>
          <Grid item xs={7} lg={2} style={{ margin: '0px 10px' }}>
            <DropdownEmailTemplate
              label={`${t('SELECT_COURSE')}`}
              required
              id="courseId"
              name="courseId"
              labelId="courseId"
              value={formik.values.courseId}
              options={oCourses || []}
              shrink
              handleChange={(e) => {
                formik.setFieldValue('courseId', e.id);
              }}
            />
          </Grid>
          {!config.readonly && (
            <Grid item xs={7} lg={4} className={classes.subject}>
              <TextFieldAtom
                id="subject"
                variant="outlined"
                label={t('EMAIL_SUBJECT')}
                sx={{
                  '@media (min-width: 1200px)': {
                    maxWidth: '100%',
                    marginTop: '14px',
                  },
                }}
                placeholder={t('EMAIL_SUBJECT')}
                required
                error={getError('subject')}
                value={formik.values.subject}
                onChange={(val) => formik.setFieldValue('subject', val.target.value)}
              />
              {getError('subject')}
            </Grid>
          )}
          {config.readonly && (
            <Grid container sx={{
              '@media (min-width: 1310px)': {
                width: '65% !important',
                paddingLeft: '0vw !important',
                justifyContent: 'flex-end',
                paddingRight: '3vw !important',
              },
              '@media (max-width: 1309px)': {
                width: '40% !important',
                paddingLeft: '0vw !important',
                justifyContent: 'flex-end',
                paddingRight: '3vw !important',
              },
            }}>
              <Grid item xs={7} lg={2} className={classes.viewLogs2}>
                <>
                  <FindInPageOutlinedIcon style={{ height: 16 }} />
                  <div onClick={() => viewLogs()}>{t('VIEW_LOGS')}</div>
                </>
              </Grid>
              <Grid item xs={12} lg={2} className={classes.addAcademicBtn}>
                <ButtonAtom
                  name={t('EDIT')}
                  btntype={Buttons.PRIMARY}
                  onClick={() =>
                    setConfig({
                      readonly: false,
                      toolbar: true,
                    })
                  }
                  className={classes.editBtn}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.emailEditor}>
          <TextEditor
            name="message"
            id="message"
            setFieldValue={(val) => formik.setFieldValue('message', val)}
            value={formik.values.message}
            {...{ welcomeEmail, config }}
            error={getError('message')}
            cursorPosition={cursorPosition}
            setCursorPosition={setCursorPosition}
            setCursorLineText={setCursorLineText}
          />
        </Grid>
        <Grid sx={{
          marginLeft: '-11.5%',
          '@media (max-width: 1300px)': {
            marginLeft: '-12% !important'
          },
          '@media (max-width: 1240px)': {
            marginLeft: '-13% !important'
          },
          '@media (max-width: 1240px)': {
            marginLeft: '0% !important'
          },
        }}
        >
          <TextField
            id="search"
            variant="outlined"
            sx={{
              '@media (min-width: 1200px)': {
                maxWidth: '100%',
              },
            }}
            placeholder="Search"
            value={searched}
            onChange={(searchVal) => setSearched(searchVal.target.value)}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <GridSearchIcon />
                </IconButton>
              ),
            }}
          />
          <List
            className={classes.substitutionTagList}
            subheader={<li />}
          >
            <div>
              {
                substitutionTagsAdmin.map((el) => {
                  const options = el.options.filter((item) => item.text.toLowerCase().includes(searched.toLowerCase()));
                  if (options.length === 0) return null;
                  return (
                    <li key={el.title} style={{ textAlign: 'start' }}>
                      <ul>
                        <ListSubheader className={classes.substitutionTagListHeader}>{`${el.title}:`}</ListSubheader>
                        {options.map((item) => (
                          <ListItem key={item.key} className={classes.listItem}>
                            <ListItemButton className={classes.listItemButton} onClick={() => { substitutionTagButton(el, item); }}>
                              <ListItemText primary={item.text} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </ul>
                    </li>
                  );
                })
              }
            </div>
          </List>
        </Grid>
        {!config.readonly
          && (
            <Grid container>
              <Grid item xs={7} lg={3} className={classes.logText}>
                <TextFieldAtom
                  id="logText"
                  variant="outlined"
                  label={t('CHANGE_LOG')}
                  sx={{
                    '@media (min-width: 1200px)': {
                      maxWidth: '100%',
                    },
                  }}
                  placeholder="Log Text"
                  required
                  error={getError('logText')}
                  value={formik.values.logText}
                  onChange={(val) => formik.setFieldValue('logText', val.target.value)}
                />
                {getError('logText')}
              </Grid>
              <Grid item xs={7} lg={2} className={classes.viewLogs}>
                <>
                  <FindInPageOutlinedIcon style={{ height: 16 }} />
                  <div onClick={() => viewLogs()}>{t('VIEW_LOGS')}</div>
                </>
              </Grid>
              <Grid
                container
                item
                justifyContent="flex-end"
                className={classes.actionButtons}
                xs={12} lg={7}
              >
                <ButtonAtom
                  name={t('CANCEL')}
                  btntype={Buttons.SECONDARY}
                  onClick={handleCancel}
                />
                <ButtonAtom
                  name={t('UPDATE')}
                  onClick={formik.handleSubmit}
                  btntype={Buttons.PRIMARY}
                />
              </Grid>
            </Grid>
          )}
      </Grid>
      <DialogAtom
        isOpen={isOpenError}
        onClose={() => handleCancel()}
        dialogHeading={t('EMAIL_TITLE')}
        customClass={classes.paymentDialogAtom}
        content={(
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={12}>
              <div className={classes.lastNameError}>
                {t('EMAIL_UPDATED_SUCCESSFULLY')}
              </div>
            </Grid>
            <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>

              <div className={classes.popupBtn}>
                <ButtonAtom
                  name={t('OK')}
                  onClick={() => {
                    handleCancel();
                  }}
                  btntype={Buttons.PRIMARY}
                />
              </div>
            </Grid>
          </Grid>
        )}
        secHandle={() => handleCancel()}
      />
      {loading ? (
        <Grid>
          <Loader message={t('UPDATING')} />
        </Grid>
      )
        : null}
    </FormikProvider>
  );
}

export default SettingTermsAndConditions;
