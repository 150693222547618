import { Box } from '@mui/material';
import React from 'react';
import style from '../style';

import useStyles from '../../../../../custom-hooks/useStyles';

function MapHeader({
  setFilterIndex, setFilterCountry, uniqueCountries, filterIndex,
}) {
  const classes = useStyles(style)();
  return (
    <Box className={classes.mapFilter}>
      <Box className={classes.title}>Select the countries</Box>
      <Box className={classes.filterContainer}>
        {uniqueCountries?.map((items, i) => (
          <p
            onClick={() => {
              setFilterIndex(i);
              setFilterCountry(items);
            }}
            className={`${classes.filterLabel} ${
              filterIndex === i ? classes.activeFilter : null
            }`}
            key={i}
          >
            {items}
          </p>
        ))}
      </Box>
    </Box>
  );
}
export default MapHeader;
