import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Buttons, NavigateRoutes } from '../../../../constant';
import {
  DialogAtom,
  PerformantTextField,
  PerfromantMultiValueDropdown,
} from '../../../../components/atoms';
import { AntSwitch } from '../../../../utils/commonUiComponent';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';
import {
  getRolePermisstion,
  getRoles,
} from '../../../../store/actions/getRole';
import {
  addRoleService,
  updateRoleService,
} from '../../../../store/services/auth';
import logsIcon from '../../../../assets/images/logs-icon.png';
import ButtonAtom from '../../../../components/atoms/button';

export default function AddRoleDialog(props) {
  const {
    open, handleClose, selectedRole, setSuccessMessage,
  } = props;
  const { t } = useTranslation();
  const [roleStatus, setRoleStatus] = useState(selectedRole?.isActive || false);
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const roleStore = useSelector((state) => state.getRole);
  const [roleNameError, setRoleNameError] = useState('');
  const [message, setMessage] = useState('');

  const [stopCalling, setStopCalling] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getRolePermisstion());
  }, []);
  const handleStatusChange = (event) => {
    setRoleStatus(event.target.checked);
  };

  const validationSchema = (open === 'edit')
    ? Yup.object({
      roleName: Yup.string(t('ROLE_NAME_REQUIRED')).required(
        t('ROLE_NAME_REQUIRED'),
      ),
      rolePermissions: Yup.array()
        .min(1, t('ROLE_PERMISSIONS_REQUIRED'))
        .required(t('ROLE_PERMISSIONS_REQUIRED')),

      logMessage: Yup.string()
        .required(t('CHANGE_LOG_REQUIRED')),
    })
    : Yup.object({
      roleName: Yup.string(t('ROLE_NAME_REQUIRED')).required(
        t('ROLE_NAME_REQUIRED'),
      ),
      rolePermissions: Yup.array()
        .min(1, t('ROLE_PERMISSIONS_REQUIRED'))
        .required(t('ROLE_PERMISSIONS_REQUIRED')),
    });

  const refreshList = () => {
    dispatch(getRoles());
    handleClose();
  };

  const onAddRole = (values) => {
    if (stopCalling === true) {
      setStopCalling(false);
      const payload = {
        name: values?.roleName,
        isActive: roleStatus,
        permissions: values.rolePermissions.map((per) => ({ id: per })),
      };
      addRoleService(payload)
        .then(() => {
          setRoleNameError('');
          refreshList();
          toast.success('New role added');
          setStopCalling(true);
          setSuccessMessage(true);
        })
        .catch((err) => {
          if (err?.errors) {
            setRoleNameError(err.errors[0].msg);
          }
          setStopCalling(true);
        });
    }
  };

  const onEditRole = (values) => {
    const payload = {
      name: values?.roleName,
      isActive: roleStatus,
      permissions: values.rolePermissions.map((per) => ({ id: per })),
      logMessage: values?.logMessage,
    };
    updateRoleService(selectedRole?.id, payload)
      .then(() => {
        setRoleNameError('');
        refreshList();
        setMessage('User Updated Successfully');
      })
      .catch((err) => {
        if (!err?.status) {
          setRoleNameError(err.errors[0].msg);
        }
      });
  };

  const viewLogs = () => {
    navigate(NavigateRoutes.ROLE_MANAGER_LOGS, {
      state: {
        role: selectedRole,
        lastRoute: NavigateRoutes.ROLE_MANAGER_VIEW,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      roleName: selectedRole?.name,
      rolePermissions: selectedRole?.selectedPermissions || [],
      logMessage: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (open === 'add') {
        onAddRole(values);
      }
      if (open === 'edit') onEditRole(values);
    },
  });

  const setPreviousFieldTouch = (key) => {
    const allFields = ['roleName', 'rolePermissions'];

    const index = allFields.indexOf(key);
    if (index > -1) {
      const obj = {};
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        const element = allFields[i];
        obj[element] = true;
      }
      formik.setTouched({ ...formik.touched, ...obj }, true);
    }
  };

  // eslint-disable-next-line no-nested-ternary
  const getErrorText = (key, errorText) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={classes.errorText}>
      {formik.errors[key]}
    </span>
  ) : errorText ? (
    <span className={classes.errorText}>{errorText}</span>
  ) : null);

  return (
    <div>
      <DialogAtom
        isOpen={open}
        dialogHeading={t(open === 'add' ? 'ADD_ROLE' : 'EDIT_ROLE')}
        onClose={handleClose}
        dialogActions={false}
        primaryButton={t('SAVE')}
        customClass={classes.addRoleManager}
        secButton={t('CANCEL')}
        content={(
          <FormikProvider value={formik}>
            <Grid container spacing={1}>
              <span className={classes.message}>
                {message}
              </span>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={7.3}
                className={`${classes.alignGrid} ${classes.dialContact}`}
              >
                <PerformantTextField
                  label={`${t('ROLE_NAME')}`}
                  id="roleName"
                  required
                  name="roleName"
                  type="text"
                  disabled={false}
                  value={formik.values.roleName}
                  onBlur={() => setPreviousFieldTouch('roleName')}
                  error={roleNameError || getErrorText('roleName')}
                  onChange={formik.handleChange}
                  labelId="roleName"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                className={classes.alignGrid}
                alignSelf="center"
              >
                <Typography className={classes.switchHeading}>
                  {t('STATUS')}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography className={classes.switchText}>
                    {t('INACTIVE_STATUS')}
                  </Typography>
                  <AntSwitch
                    defaultChecked
                    checked={roleStatus}
                    onChange={handleStatusChange}
                    inputProps={{ 'aria-label': 'ant design' }}
                    className={classes.switchUi}
                  />
                  <Typography className={classes.switchText}>
                    {t('ACTIVE_STATUS')}
                  </Typography>
                </Stack>
                {getErrorText('Status')}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={7.3}
                className={`${classes.alignGrid} ${classes.rolePermissions} ${classes.maxWidthAssign}`}
              >
                <PerfromantMultiValueDropdown
                  minWidth="100%"
                  label={`${t('ROLE_PERMISSIONS')}`}
                  id="rolePermissions"
                  name="rolePermissions"
                  value={formik.values.rolePermissions}
                  onBlur={() => setPreviousFieldTouch('rolePermissions')}
                  error={getErrorText('rolePermissions')}
                  handleChange={formik.handleChange}
                  options={roleStore.permissions.map((per) => ({
                    id: per.id,
                    name: `${per.key}`,
                  })).filter((per) => per.name !== 'null')}
                  required
                  labelId="rolePermissions"
                  showLabel
                />
                {getErrorText('rolePermissions')}
              </Grid>

              {
                      open === 'edit'
                        ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className={`${classes.alignGrid} ${classes.dialContact}`}
                            >
                              <PerformantTextField
                                label={`${t('CHANGE_LOG')}`}
                                id="logMessage"
                                required
                                name="logMessage"
                                type="text"
                                disabled={false}
                                value={formik.values.logMessage}
                                onChange={formik.handleChange}
                                labelId="changeLog"
                                onBlur={formik.handleBlur}
                                allowSpecialCharacter
                              />
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={4}
                              >
                                <span
                                  onClick={() => viewLogs()}
                                  className={classes.logsIconSize}
                                >
                                  <img src={logsIcon} width="20" alt="logsIcon" />
                                  <span>{t('VIEW_LOGS')}</span>
                                </span>
                              </Grid>
                              <Grid item xs={8} className={classes.popupButtons}>
                                <ButtonAtom
                                  btntype={Buttons.SECONDARY}
                                  onClick={handleClose}
                                  name={t('CANCEL')}
                                />
                                <ButtonAtom
                                  onClick={formik.handleSubmit}
                                  btntype={Buttons.PRIMARY}
                                  name={t('SAVE')}
                                />
                              </Grid>
                            </Grid>
                          </>
                        )
                        : (
                          <Grid container xs={12}>
                            <Grid item xs={4} />
                            <Grid item xs={8} className={classes.popupButtons}>
                              <ButtonAtom
                                btntype={Buttons.SECONDARY}
                                onClick={handleClose}
                                name={t('CANCEL')}
                              />
                              <ButtonAtom
                                onClick={formik.handleSubmit}
                                btntype={Buttons.PRIMARY}
                                name={t('SAVE')}
                              />
                            </Grid>
                          </Grid>
                        )
                    }
            </Grid>
          </FormikProvider>
        )}
        primaryHandle={formik.handleSubmit}
        secHandle={handleClose}
      />
    </div>
  );
}
