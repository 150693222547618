import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../../../theme';

export const useStyles = makeStyles((theme) => ({
  regionExportDialogAtom: {
    '& .MuiDialog-paper': {
      minWidth: '60vw !important',
      maxWidth: '60vw !important',
      '& .MuiFormControl-root': {
        margin: '0 !important',
      },
    },
  },
  selectAll: {
    display: 'flex !important',
    justifyContent: 'flex-start',
    '& .MuiFormControlLabel-label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      width: '120px !important',
    },
  },
  checkbox: {
    display: 'flex !important',
    marginBottom: '12px',
    '& .MuiFormControlLabel-label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
    },
    '& div>MuiBox-root:first-child': {
      width: '25%',
    },
  },
  checkboxContent: {
    '& .MuiFormControlLabel-root': {
      whiteSpace: 'nowrap',
    },
    '& css-1vgzwi7-MuiFormControl-root:last child': {
      margin: '0 !important',
    },
  },
  popupButton: {
    textAlign: 'right',
  },

  customCheckBox: {
    fontSize: 20,
    margin: 0,
    background: 'url(../../../assets/images/check-icon.png) 0 0 no-repeat',
    '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
      fontSize: '1vw !important',
      marginTop: '0.3vw !important',
      color: colors.textPrimary,
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
      },
    },
    '& .MuiSvgIcon-root': {
      width: '1.5vw',
      height: '1.5vw',
      [theme.breakpoints.down('lg')]: {
        width: 14,
        height: 14,
      },
      '@media (max-width: 599px)': {
        marginTop: '-6px !important',
      },
    },
    '& .MuiCheckbox-root': {
      '&.Mui-checked': {
        position: 'relative',
        '&::after': {
          position: 'absolute',
          top: '0.6vw',
          content: '""',
          border: `solid 2px ${colors.primary}`,
          zIndex: 99999,
          width: '0.9vw',
          height: '0.9vw',
          borderRadius: 3,
          background: colors.white,
          [theme.breakpoints.down('lg')]: {
            width: 12,
            height: 12,
          },
        },
        '&::before': {
          position: 'absolute',
          top: '0.7vw',
          left: '0.8vw',
          content: '""',
          width: '0.8vw',
          height: '0.3vw',
          color: colors.primary,
          borderBottom: `solid 2px ${colors.primary}`,
          borderLeft: `solid 2px ${colors.primary}`,
          transform: 'rotate(-45deg)',
          zIndex: 111111,
          background: colors.white,
          boxShadow: `3px 4px 2px ${colors.white}`,
          [theme.breakpoints.down('lg')]: {
            color: '#104F96',
            width: 9,
            height: 4,
          },
          '@media (max-width: 599px)': {
            marginLeft: '9.5px !important',
            marginTop: '2px !important',
          },
        },
      },
      '&.Mui-checked.Mui-disabled': {
        position: 'relative',
        '&::after': {
          position: 'absolute',
          top: '0.06vw',
          content: '""',
          border: `solid 2px ${colors.primary}`,
          zIndex: 99999,
          width: '0.9vw',
          height: '0.9vw',
          borderRadius: 3,
          background: colors.backgroundGrey,
          [theme.breakpoints.down('lg')]: {
            top: 8,
            left: 8,
            width: 12,
            height: 12,
          },
        },
        '&::before': {
          position: 'absolute',
          top: '0.15vw',
          left: '0.2vw',
          content: '""',
          width: '0.8vw',
          height: '0.3vw',
          color: colors.primary,
          borderBottom: `solid 2px ${colors.primary}`,
          borderLeft: `solid 2px ${colors.primary}`,
          transform: 'rotate(-45deg)',
          zIndex: 111111,
          background: colors.backgroundGrey,
          boxShadow: `3px 4px 2px ${colors.white}`,
          [theme.breakpoints.down('lg')]: {
            top: 10,
            left: 14,
            color: '#104F96',
            width: 9,
            height: 4,
          },
        },
      },
    },
    '& .MuiTypography-root': {
      marginLeft: 0,
    },
  },
}));

export default {
  useStyles,
};
