import React from 'react';
import { CubeProvider } from '@cubejs-client/react';
import EnrollBar from './enrollmentBar';
import { getCubejsApi } from '../../../../../utils/cube';

function EnrollbarWrapper({
  setEnrollGraphOption,
  locationIds,
  enrollGraphOption,
  selectedFilter,
  setSelectedFilter,
  setFilterOptions,
  filterOptions,
}) {
  return (
    <CubeProvider cubejsApi={getCubejsApi()}>
      <EnrollBar
        {...{
          setEnrollGraphOption,
          locationIds,
          enrollGraphOption,
          selectedFilter,
          setSelectedFilter,
          setFilterOptions,
          filterOptions,
        }}
      />
    </CubeProvider>
  );
}

export default EnrollbarWrapper;
