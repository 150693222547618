import React, { memo } from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import style from './style';

const DropdownWithSearch = memo(
  ({
    handleChange,
    id,
    options,
    placeholder,
    value,
    label,
    changeCss = {},
    disabled = false,
  }) => {
    const classes = style();
    const found = options?.find((element) => element?.id === value);
    return (
      <FormControl
        className={`${classes.formControl} ${
          changeCss ? classes.dropdownUi : ' '
        }`}
      >
        <Autocomplete
          id={id}
          options={options.map((data) => data.name)}
          onChange={handleChange}
          noOptionsText="No text"
          value={found ? found.name : value}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              focused
              {...params}
              label={label}
              placeholder={placeholder}
            />
          )}
        />
      </FormControl>
    );
  },
);

DropdownWithSearch.defaultProps = {
  options: [],
  value: '',
  variant: 'outlined',
  placeholder: '',
  error: false,
  errorText: '',
  disabled: false,
};

export default DropdownWithSearch;
