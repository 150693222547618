import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Box, Grid, TextField, Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import ButtonAtom from '../../../components/atoms/button';
import DatePickerAtom from '../../../components/atoms/datepicker';
import { Buttons } from '../../../constant';
import useStyles from '../../../custom-hooks/useStyles';
import styles, { materialStyles } from '../homework-panel/homewor-panel-schema/styles';

function RenderCategoryField({
  questions,
  setTotalQuestions,
  handleSave,
  courseFieldData,
  courseFieldErrors,
  courseFieldTouched,
  changeAssignmentMarks,
  showSaveBtn,
  schemaCanBeEdited,
  useGetCourseRows,
  restrictKeys,
  refreshSchema,
  quarterDates,
  findValidPrevNextIndices,
  setError,
  setSuccessMsg,
  setErrorSchemaRemoved,
  setSaveClicked,
}) {
  const { t } = useTranslation();
  const { rows, setRow } = useGetCourseRows(questions);
  const [showRows, setShowRows] = useState(false);
  const classes = useStyles(materialStyles)();

  useEffect(() => {
    if (!rows || !rows.length) {
      setShowRows(false);
    } else {
      setShowRows(true);
    }
  }, [rows]);

  const deleteRow = (id) => {
    setTotalQuestions(questions - 1, id);
    setRow((oldRows) => oldRows.filter((rowID) => rowID !== id));
  };

  const onCrossClick = (rowNo) => {
    if (!schemaCanBeEdited) {
      return;
    }
    deleteRow(rowNo);
  };

  const handleChange = (e, rowNo, target) => {
    const { value, name } = target || e.target;
    changeAssignmentMarks(rowNo, name, value);
  };

  const { startDate, endDate } = quarterDates || {
    startDate: new Date(),
    endDate: new Date(),
  };

  const quarterMinLimit = typeof startDate === 'string' ? new Date(startDate.slice(0, -1)) : startDate;
  const quarterMaxLimit = typeof endDate === 'string' ? new Date(endDate.slice(0, -1)) : endDate;

  return (
    <Box marginTop={2}>
      {showRows && (
        <>
          <Box style={styles.courseTableHeadingCont}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography style={styles.courseTableHeading}>
                  {t('CATEGORY')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={styles.courseTableHeading}>
                  {t('MARKS')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={styles.courseTableHeading}>
                  {t('NOTES')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={styles.courseTableHeading}>
                  {t('START_DATE')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={styles.courseTableHeading}>
                  {t('END_DATE')}
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
          <Box marginTop={2}>
            {rows.map((id) => {
              const validPrevIndex = findValidPrevNextIndices(courseFieldData, id - 1);

              const endDateMinLimit = new Date(courseFieldData[id]?.startDate)
                .setDate(new Date(courseFieldData[id]?.startDate).getDate() + 1);
              const startDateMinLimit = validPrevIndex >= 0 ? new Date(courseFieldData[validPrevIndex]?.endDate)
                .setDate(new Date(courseFieldData[validPrevIndex]?.endDate).getDate() + 1) : quarterMinLimit;

              if (courseFieldData[id]?.deletedAt) {
                return null;
              }

              return (
                <Box key={id} marginTop={2} minWidth={650}>
                  <Grid container spacing={3} style={{ flexFlow: 'row' }}>
                    <Grid item xs={2.5} lg={3} md={2} className={`${classes.formControl}`}>
                      <TextField
                        label={t('CATEGORY')}
                        id="category"
                        required
                        name="category"
                        disabled={!schemaCanBeEdited}
                        value={courseFieldData[id]?.category || ''}
                        onChange={(e) => handleChange(e, id)}
                        labelId="category"
                        size="small"
                        inputProps={{ min: '0', style: styles.textFieldLabel }}
                        InputLabelProps={{ style: styles.textFieldLabel }}
                        error={courseFieldErrors && courseFieldErrors[id]?.category && courseFieldTouched && (courseFieldTouched[id]?.week || courseFieldTouched[id]?.category) ? courseFieldErrors[id]?.category : false}
                        helperText={courseFieldErrors && courseFieldErrors[id]?.category && courseFieldTouched && (courseFieldTouched[id]?.week || courseFieldTouched[id]?.category) ? courseFieldErrors[id]?.category : ''}
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.formControl}>
                      <TextField
                        label={t('MARKS')}
                        id="marks"
                        required
                        name="marks"
                        type="number"
                        disabled={!schemaCanBeEdited}
                        value={courseFieldData[id]?.marks || ''}
                        onChange={(e) => handleChange(e, id)}
                        labelId="marks"
                        size="small"
                        onKeyPress={(e) => restrictKeys(e, true)}
                        inputProps={{ min: '0', style: styles.textFieldLabel }}
                        InputLabelProps={{ style: styles.textFieldLabel }}
                        error={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : false}
                        helperText={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : ''}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.formControl}>
                      <TextField
                        label={t('NOTES')}
                        id="notes"
                        required
                        name="notes"
                        type="text"
                        disabled={!schemaCanBeEdited}
                        value={courseFieldData[id]?.notes || ''}
                        onChange={(e) => handleChange(e, id)}
                        labelId="notes"
                        size="small"
                        inputProps={{ style: styles.textFieldLabel }}
                        InputLabelProps={{ style: styles.textFieldLabel }}
                        error={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : false}
                        helperText={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : ''}
                      />
                    </Grid>
                    <Grid item xs={4.5} md={3} lg={2} className={classes.dateControl}>
                      <DatePickerAtom
                        label={`${t('START_DATE')}*`}
                        id="startDate"
                        name="startDate"
                        // eslint-disable-next-line no-nested-ternary
                        value={courseFieldData[id]?.startDate
                          ? moment(courseFieldData[id]?.startDate).utc().format('MM/DD/YYYY') : ''}
                        type="date"
                        error={courseFieldErrors && courseFieldTouched && courseFieldTouched[id]?.startDate ? courseFieldErrors[id]?.startDate : false}
                        onChange={(newDate) => {
                          handleChange(null, id, {
                            name: 'startDate',
                            value: moment(newDate).format('MM/DD/YYYY'),
                          });
                        }}
                        textFieldProps={{
                          sx: {
                            svg: { fill: '#104F96', paddingTop: '0.3vw' },
                          },
                          size: 'small',
                        }}
                        minDate={id && startDateMinLimit ? startDateMinLimit : quarterMinLimit}
                        maxDate={quarterMaxLimit}
                      />
                      {courseFieldErrors && courseFieldErrors[id]?.startDate && courseFieldTouched && courseFieldTouched[id]?.startDate && (
                        <span className={classes.errorText}>{courseFieldErrors[id]?.startDate}</span>
                      )}
                    </Grid>
                    <Grid item xs={4.5} md={3} lg={2} className={classes.dateControl}>
                      <DatePickerAtom
                        label={`${t('END_DATE')}*`}
                        minWidth="100%"
                        id="endDate"
                        name="endDate"
                        // eslint-disable-next-line no-nested-ternary
                        value={courseFieldData[id]?.endDate
                          ? moment(courseFieldData[id]?.endDate).utc().format('MM/DD/YYYY') : ''}
                        type="date"
                        error={courseFieldErrors && courseFieldTouched && courseFieldTouched[id]?.endDate ? courseFieldErrors[id]?.endDate : false}
                        onChange={(newDate) => {
                          handleChange(null, id, {
                            name: 'endDate',
                            value: moment(newDate).format('MM/DD/YYYY'),
                          });
                        }}
                        textFieldProps={{
                          sx: {
                            svg: { fill: '#104F96', paddingTop: '0.3vw' },
                          },
                          size: 'small',
                        }}
                        InputLabelProps={{ style: styles.textFieldLabel }}
                        minDate={id && endDateMinLimit ? endDateMinLimit : quarterMinLimit}
                        maxDate={quarterMaxLimit}
                      />
                      {courseFieldErrors && courseFieldErrors[id]?.endDate && courseFieldTouched && courseFieldTouched[id]?.endDate && (
                        <span className={classes.errorText}>{courseFieldErrors[id]?.endDate}</span>
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      <Box mt={2} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                        <ClearIcon
                          style={!schemaCanBeEdited ? { color: '#bdbbbb' } : {}}
                          onClick={() => onCrossClick(id)}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Box>
        </>
      )}
      {showSaveBtn
        && (
          <Box marginTop={2} alignItems="flex-end" style={styles.saveBtnCont}>
            <ButtonAtom
              btntype={Buttons.SECONDARY}
              onClick={() => {
                setError('');
                setSuccessMsg('');
                setErrorSchemaRemoved('');
                refreshSchema();
              }}
              name={t('CANCEL')}
              type="submit"
            />

            <ButtonAtom
              btntype={Buttons.PRIMARY}
              onClick={() => {
                setSaveClicked(true);
                handleSave();
              }}
              name={t('SAVE')}
              type="submit"
            />
          </Box>
        )}
    </Box>
  );
}

export default RenderCategoryField;
