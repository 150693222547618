/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useTranslation } from 'react-i18next';
import {
  Grid, Box,
  Typography,
  CardActionArea,
  Tooltip,
  TextField,
} from '@mui/material';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import CloseIcon from '@mui/icons-material/Close';
import CheckboxAtom from '../../../components/atoms/checkbox-resources';
import mbPng from '../../../assets/images/mb_png_icon.png';
import mbPdf from '../../../assets/images/mb_pdf_icon.png';
import mbTxt from '../../../assets/images/mb_txt_icon.png';
import mbDoc from '../../../assets/images/mb_doc_icon.png';
import mbVideo from '../../../assets/images/mb_video_icon.png';
import useStyles from '../../../custom-hooks/useStyles';
import styles from '../style';
import defaultIcon from '../../../assets/images/defaultIcon.png';
import { DialogAtom, Loader } from '../../../components/atoms';
import { Buttons } from '../../../constant';
import {
  updateResource,
  getFolderDetails,
  getTeacherResource,
  getStudentResourceCourse,
} from '../../../store/actions/getResources';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import userRoles from '../../../constant/userRoles';
import ButtonAtom from '../../../components/atoms/button';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function ShowFiles(props) {
  const {
    fileType,
    fileUrl,
    classes,
  } = props;
  let aUrlSplitp = '';
  let valueUrlp = '';
  let aUrlSplitP = '';
  let valueUrlP = '';

  switch (fileType) {
    case 'png':
      return <img src={fileUrl} alt={fileUrl} />;
    case 'jpg':
      return <img src={fileUrl} alt={fileUrl} />;
    case 'jpeg':
      return <img src={fileUrl} alt={fileUrl} />;
    case 'ppt':
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`} height={810} width={600} title={fileUrl} /></div>;
    case 'pdf':
      aUrlSplitp = fileUrl?.split('/')?.splice(3);
      valueUrlp = aUrlSplitp?.join('/');
      return (
        <div className={classes.pdfFileS}>
          <iframe className={classes.pdfFileFrame} src={`https://docs.google.com/viewer?url=https://storage.googleapis.com/${valueUrlp}&embedded=true`} frameBorder="0" scrolling="no" seamless="" />
          <div style={{
            width: '50px', height: '50px', position: 'absolute', right: '12px', top: '12px', opacity: 0,
          }}
          >
          &nbsp;
          </div>
        </div>
      );
    case 'Pdf':
      aUrlSplitP = fileUrl?.split('/')?.splice(3);
      valueUrlP = aUrlSplitP?.join('/');
      return <div className={classes.pdfFileS}><iframe className={classes.pdfFileFrame} src={`https://docs.google.com/viewer?url=https://storage.googleapis.com/${valueUrlP}&embedded=true`} /></div>;
    case 'doc':
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}&wdOrigin=BROWSELINK`} height={810} width={600} title={fileUrl} /></div>;
    case 'docx':
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}&wdOrigin=BROWSELINK`} height={810} width={600} title={fileUrl} /></div>;
    case 'mp4':
      return <ReactPlayer url={fileUrl} playing="true" controls="true" playsInline="true" aspectRatio="auto" width="90%" height="90%" />;
    case 'mov':
      return <ReactPlayer url={fileUrl} playing="true" controls="true" playsInline="true" aspectRatio="auto" width="90%" height="90%" />;
    default:
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={fileUrl} height={810} width={600} title={fileUrl} /></div>;
  }
}

// react file viewer
export default function ResourcesData({
  data,
  refreshData,
  typeResources,
  selectedResource,
  folderName,
  setFolderName,
  courseSelectedvalue,
  pageNext,
  pagenum,
  setPagenum,
  setLoading,
  setIsFetching,
  isFetching,
  setActivateChecked,
  allResources,
  setResource,
  isSysAdminOrSuperUser,
}) {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const [open, setOpen] = React.useState(false);
  const [fileType, setFileType] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState(false);
  const [isDownload, setIsDownload] = React.useState(false);
  const [resourceName, setResourceName] = React.useState('');
  const [isSetEditFileOpenDialog, setEditFileOpenDialog] = useState(false);
  const [isEditedFileName, setEditedFileName] = useState('');
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const encryptedTempUserRole = getLocalStorage('tempUserRole');
  const tempuserRole = decryptedUserRoles[encryptedTempUserRole];
  const isValidUser = (userRole === userRoles.SYSTEM_ADMIN || userRole === userRoles.CURRICULUM || userRole === userRoles.SUPER_USER
    || tempuserRole === userRoles.SYSTEM_ADMIN || tempuserRole === userRoles.CURRICULUM || tempuserRole === userRoles.SUPER_USER);
  const [resources, setResources] = useState([]);
  const [error, setError] = useState('');
  const [ischecked, setIsChecked] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const reduxTotalCount = useSelector((state) => state?.getLocationCoordinator.totalCount);

  useEffect(() => {
    setResources(data);
  }, [data]);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const nextPage = (e) => {
    e.stopPropagation();
    changePage(1);
  };

  const previousPage = (e) => {
    e.stopPropagation();
    changePage(-1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const dispatch = useDispatch();
  const handleClose = () => {
    setIsDownload(false);
    setOpen(false);
    setFileUrl(null);
  };
  const getFileType = (file, fileResourceName) => {
    const fileNameSplit = fileResourceName?.split('.')[1];
    if (fileNameSplit === undefined) {
      if (file !== null) {
        switch (file?.split('/')?.[1]) {
          case 'png':
            return file.split('/')[1];
          case 'jpg':
            return file.split('/')[1];
          case 'jpeg':
            return file.split('/')[1];
          case 'pdf':
            return file.split('/')[1];
          case 'Pdf':
            return file.split('/')[1];
          case 'octet-stream':
            return file.split('/')[1];
          case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
            return file.split('/')[1];
          case 'plain':
            return file.split('/')[1];
          case 'msword':
            return file.split('/')[1];
          case 'txt':
            return file.split('/')[1];
          case 'webm':
            return file.split('/')[1];
          case 'webp':
            return file.split('/')[1];
          case 'doc':
            return file.split('/')[1];
          case 'docx':
            return file.split('/')[1];
          case 'ppt':
            return file.split('/')[1];
          case 'mp4':
            return file.split('/')[1];
          case 'mp3':
            return file.split('/')[1];
          case 'Mp3':
            return file.split('/')[1];
          case 'mov':
            return file.split('/')[1];
          default:
            return 'doc';
        }
      } else {
        return 'doc';
      }
    } else if (file !== null) {
      switch (fileNameSplit.split(' ')[0]) {
        case 'png':
          return fileNameSplit.split(' ')[0];
        case 'jpg':
          return fileNameSplit.split(' ')[0];
        case 'jpeg':
          return fileNameSplit.split(' ')[0];
        case 'pdf':
          return fileNameSplit.split(' ')[0];
        case 'Pdf':
          return fileNameSplit.split(' ')[0];
        case 'octet-stream':
          return fileNameSplit.split(' ')[0];
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
          return fileNameSplit.split(' ')[0];
        case 'plain':
          return fileNameSplit.split(' ')[0];
        case 'msword':
          return fileNameSplit.split(' ')[0];
        case 'txt':
          return fileNameSplit.split(' ')[0];
        case 'webm':
          return fileNameSplit.split(' ')[0];
        case 'webp':
          return fileNameSplit.split(' ')[0];
        case 'doc':
          return fileNameSplit.split(' ')[0];
        case 'docx':
          return fileNameSplit.split(' ')[0];
        case 'ppt':
          return fileNameSplit.split(' ')[0];
        case 'mp4':
          return fileNameSplit.split(' ')[0];
        case 'mp3':
          return fileNameSplit.split(' ')[0];
        case 'Mp3':
          return fileNameSplit.split(' ')[0];
        case 'mov':
          return fileNameSplit.split(' ')[0];
        default:
          return file?.split('/')?.[1];
      }
    } else {
      return fileNameSplit.split(' ')[0];
    }
  };

  const openFolder = (obj) => {
    setResource !== undefined ? setResource([]) : null;
    setIsFetching(true);
    setLoading !== undefined ? setLoading(true) : null;
    const payload = {
      courseId: obj.courseId,
      parentKey: obj.selfKey,
    };
    if (obj.resourceKind === 'Student' || obj.resourceKind === 'student') {
      dispatch(getStudentResourceCourse(1, 100, pageNext, payload, setIsFetching, setLoading));
    } else {
      dispatch(getTeacherResource(1, 100, pageNext, payload, setIsFetching, setLoading));
    }
    dispatch(getFolderDetails(obj));
  };
  const handleToggle = (obj) => {
    if (obj.fileType !== 'Folder') {
      const fileSplit = obj.resourceName.split('.');
      const { length } = fileSplit;
      const type = fileSplit[length - 1];
      setFileType(type);
      setFileUrl(obj.fileUrl);
      setIsDownload(obj.canDownload);
      setResourceName(obj?.resourceName);
      setOpen(!open);
    } else {
      const folder = folderName;
      if (!folder.some((resource) => resource.resourceName === obj.resourceName)) {
        folder.push(obj);
        setFolderName(folder);
        openFolder(obj);
        selectedResource(obj);
      }
    }
  };

  const downloadFile = async () => {
    window.open(fileUrl, 'a');
  };

  const icons = {
    png: <img src={mbPng} height="40" alt={t('ICON')} />,
    jpg: <img src={mbPng} height="40" alt={t('ICON')} />,
    jpeg: <img src={mbPng} height="40" alt={t('ICON')} />,
    gif: <img src={mbPng} height="40" alt={t('ICON')} />,
    giff: <img src={mbPng} height="40" alt={t('ICON')} />,
    tif: <img src={mbPng} height="40" alt={t('ICON')} />,
    tiff: <img src={mbPng} height="40" alt={t('ICON')} />,
    webp: <img src={mbPng} height="40" alt={t('ICON')} />,
    pdf: <img src={mbPdf} height="40" alt={t('ICON')} />,
    Pdf: <img src={mbPdf} height="40" alt={t('ICON')} />,
    'octet-stream': <img src={mbDoc} height="40" alt={t('ICON')} />,
    'vnd.openxmlformats-officedocument.wordprocessingml.document': <img src={mbDoc} height="40" alt={t('ICON')} />,
    plain: <img src={mbDoc} height="40" alt={t('ICON')} />,
    msword: <img src={mbDoc} height="40" alt={t('ICON')} />,
    txt: <img src={mbDoc} height="40" alt={t('ICON')} />,
    webm: <img src={mbTxt} height="40" alt={t('ICON')} />,
    doc: <img src={mbDoc} height="40" alt={t('ICON')} />,
    docx: <img src={mbDoc} height="40" alt={t('ICON')} />,
    ppt: <img src={mbDoc} height="40" alt={t('ICON')} />,
    mp4: <img src={mbVideo} height="40" alt={t('ICON')} />,
    mov: <img src={mbVideo} height="40" alt={t('ICON')} />,
    mp3: <img src={mbVideo} height="40" alt={t('ICON')} />,
    Mp3: <img src={mbVideo} height="40" alt={t('ICON')} />,
    Folder: <img src={mbTxt} height="40" alt={t('ICON')} />,
  };

  const [selectedRow, setSelectedRow] = useState([]);

  const openEditFileDialog = () => {
    setError('');
    setEditFileOpenDialog(true);
  };

  const editFile = () => {
    if (isEditedFileName !== '') {
      const paylaod = {
        canDownload: ischecked,
        id: selectedRow?.id,
        courseId: selectedRow?.courseId,
        resourceName: isEditedFileName,
      };
      dispatch(updateResource(paylaod, typeResources));
      refreshData();
      setEditFileOpenDialog(false);
      setTimeout(() => {
        setLoadingBtn(false);
      }, 3000);
      setLoadingBtn(true);
    }
  };

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };
  const loadMoreItems = (length) => {
    if (userRole === userRoles.SYSTEM_ADMIN) {
      setActivateChecked(true);
    }
    setIsFetching(true);
    let page = '';
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    const payload = {
      courseId: courseSelectedvalue,
      parentKey: selectedResource.selfKey,
    };
    if (typeResources === 'student' || typeResources === 'Student') {
      dispatch(getStudentResourceCourse(page, 100, pageNext, payload, setIsFetching, setLoading));
    } else {
      dispatch(getTeacherResource(page, 100, pageNext, payload, setIsFetching, setLoading));
    }
  };
  const renderEditFileContent = () => (
    <Grid>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className={classes.popupTextField}
            required
            id="outlined-required"
            label="File Name"
            value={isEditedFileName}
            onChange={(e) => {
              setError('');
              if (e.target.value === '') {
                setError(t('FILE_NAME_CANNOT_BE_EMPTY'));
              }
              setEditedFileName(e.target.value);
            }}
            defaultValue="BalaBadi-I"
          />
        </div>
        <span className={classes.errorText}>{error}</span>
        <div className={classes.checkboxText}>
          <CheckboxAtom
            label={t('USER_DOWNLOAD')}
            id="acceptCheckbox"
            checked={ischecked}
            handleChange={(e) => handleCheck(e)}
          />
        </div>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => setEditFileOpenDialog(false)}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          className={classes.popupBtn}
          name={t('UPDATE')}
          onClick={() => editFile()}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderFileIcon = (obj) => {
    if (obj.fileType === 'Folder') {
      return <img src={mbTxt} height="40" alt={t('ICON')} />;
    }

    if (obj.fileIcon === '' || obj.fileIcon === null) {
      return icons[String(getFileType(obj?.fileType, obj?.resourceName))];
    }
    return <img src={obj?.fileIcon ? defaultIcon : obj?.fileIcon} height="40" alt={t('ICON')} />;
  };

  return (
    <div>
      {(LoadingBtn === true) ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : (
        <Box>
          <Grid container spacing={2} className={classes.resourceGrid}>
            {resources.map((obj) => (
              <Grid item lg={2} xs={10} sm={6} md={3} className={classes.alignResourceGrid}>
                <Card className={classes.resourcesCard}>
                  <CardActionArea className={classes.resourceGridCardImg} onClick={() => handleToggle(obj)}>
                    <Grid lg={2} xs={10} sm={6} md={3} className={classes.resourceGridCard}>

                      {renderFileIcon(obj)}

                    </Grid>
                  </CardActionArea>
                  <Tooltip title={`${obj.resourceName}`}>
                    <CardContent className={classes.resourceName}>
                      {(isValidUser || isSysAdminOrSuperUser) ? (
                        <>
                          <Typography className={classes.fileNameStyle}>
                            {obj.resourceName}
                          </Typography>
                          <EditOutlinedIcon onClick={() => {
                            setSelectedRow(obj);
                            setEditedFileName(obj?.resourceName);
                            setIsChecked(obj?.canDownload);
                            openEditFileDialog();
                          }}
                          />
                        </>
                      ) : (
                        <Typography className={classes.fileNameStyle}>
                          {obj.resourceName}
                        </Typography>
                      )}
                    </CardContent>
                  </Tooltip>
                </Card>
              </Grid>
            ))}
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <div className={classes.fileViewer}>
                <div style={{ textAlign: 'center' }}>
                  {isDownload
                    && (
                      <div className={classes.fileDownload}>
                        <DownloadSharpIcon onClick={(e) => { e.stopPropagation(); downloadFile(); }} />
                      </div>
                    )}
                  <ShowFiles
                    fileType={fileType}
                    fileUrl={fileUrl}
                    classes={classes}
                    pageNumber={pageNumber}
                    numPages={numPages}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                    previousPage={previousPage}
                    nextPage={nextPage}
                  />
                  <div className={classes.fileClose}>
                    <CloseIcon onClick={handleClose} />
                  </div>
                </div>
              </div>
            </Backdrop>
            <DialogAtom
              isOpen={isSetEditFileOpenDialog}
              dialogHeading={t('EDIT_FILE')}
              customClass={classes.editFileDialogAtom}
              content={renderEditFileContent()}
              secHandle={() => setEditFileOpenDialog(false)}
            />
          </Grid>
          {(reduxTotalCount > 0 && resources?.length > 0
            && fileType !== 'Folder') && (
              <Grid className={classes.loadMoreItems}>
                <Grid xs={12} className={classes.totalRowCount}>
                  <span>
                    {t('TOTAL_RESOURCES')}
                    {' '}
                    {resources?.length}
                    {' '}
                    of
                    {' '}
                    {reduxTotalCount}
                  </span>
                </Grid>
                {reduxTotalCount !== allResources?.length && (
                  <Grid justifyContent="flex-end" className={classes.loadMore}>
                    {isFetching && <p>{t('FETCHING_RESOURCES')}</p>}
                    {(!isFetching && reduxTotalCount !== resources?.length) && (resources?.length !== reduxTotalCount) && (
                      <ButtonAtom
                        btntype={Buttons.PRIMARY}
                        onClick={() => loadMoreItems(resources?.length)}
                        name={t('LOAD_MORE')}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
          )}
        </Box>
      )}
    </div>
  );
}
