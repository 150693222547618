/* eslint-disable */
import moment from 'moment';
import Constant from '../constant';
import {
  getTShirtService,
  getAllCourseService,
  getAcademicGradesService,
  getExtraCurricularActivitiesService,
  getLocationService,
  moveStudentService,
  getStudentLogsService,
  getStudentLogDetailService,
  editStudentByAdminService,
  getStudentAccountDetailService,
  updateStudentInfoService,
  getAcademicInfoById,
  getClassroomById,
  addSiblingService,
  updateSiblingInfoService,
  addFilterViewService,
  getAllFilterViewService,
  getAllFilterService,
  getAllFilterServiceRegion,
  getNextEnrollCourseAdminService,
  getStudentsByRegionService,
  getStudentMarksService,
  getStudentEnrollmentService,
  getAdminPaginatedStudentsService,
  getStudentsByLocationService,
  studentMarksByQuarter,
  updateMarksService,
  exportStudentData,
  getEditExamDetails,
  getUpdateExamCenter,
  getStudentExamDetails,
  getStudentDetailsByYearService,
  getExportedSheets,
  addExportedSheets,
  deleteFilterViewService,
  getCurrentAcademicYearService,
  getCurrentDobCutoffService,
  getRegistrationAcademicYearDatesService,
  getAcademicYearsServiceStatus,
  getTempRegisterInfoService,
  getTermsConditionsSettingsService,
  postPaymentOrderStripe,
  postPaymentOrderSquare,
} from '../services/auth';
import { getLocalStorage } from '../../utils/localStorageMethod';
import userRoles from '../../constant/userRoles';
import decryptedUserRoles from '../../constant/decryptedUserRoles';
import { getCurrentYear } from '../../utils/methods';

export const createPaymentOrderStripe  = (body) => (dispatch) => {
  postPaymentOrderStripe(body)
  .then((response) => {
    window.location.assign(response?.data);
  })
  .catch(() => {
  });
}
export const createPaymentOrderSquare = (body, NavigateRoutes, navigate) => (dispatch) => {
  postPaymentOrderSquare(body)
  .then((response) => {
    if (response?.data?.status === 'COMPLETED') {
      navigate(NavigateRoutes.PAYPAL_SUCCESS);
    } else {
      navigate(NavigateRoutes.PAYPAL_CANCEL);
    }
  })
  .catch(() => {
    navigate(NavigateRoutes.PAYPAL_CANCEL);
  });
}
export const setTermsAndConditionSettingDetailsStu = (body) => (dispatch) => {
  getTermsConditionsSettingsService(body)
    .then((response) => {
      const values = {
        id: response?.data?.id,
        message: response?.data?.data,
      };
      dispatch({
        type: Constant.SET_TERMS_SETTINGS,
        payload: values,
      });
    })
    .catch(() => {
    });
};

export const getRegistrationAcademicYear = () => (dispatch) => {
  getAcademicYearsServiceStatus()
    .then((response) => {
      const result = response.data.filter((obj) => obj.isCurrentYear === true);
      const {
        academicYear,
        isCurrentYear,
      } = result[0];
      const selectedAcademicYear = {
        id: academicYear,
        name: academicYear,
        isCurrentYear,
      };
      dispatch({
        type: Constant.GET_ACADEMIC_YEAR_STUDENT,
        payload: selectedAcademicYear,
      });
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getTShirts = () => (dispatch) => {
  getTShirtService()
    .then((response) => {
      dispatch({
        type: Constant.GET_TSHIRTS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getAllCourses = () => (dispatch) => {
  getAllCourseService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_COURSES,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getAllYears = () => (dispatch) => {
  getAcademicYearsServiceStatus()
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_YEARS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};
export const getCurrentAcademicYear = () => (dispatch) => {
  getCurrentAcademicYearService()
    .then((response) => {
      dispatch({
        type: Constant.GET_CURRENT_ACADEMIC_YEAR,
        payload: response?.data,
      });
      localStorage.setItem('currentAcademicYear', response?.data);
    })
    .catch((error) => {
      console.log('error :', error);
    });
};
export const getCurrentDobCutoff = () => (dispatch) => {
  getCurrentDobCutoffService()
    .then((response) => {
      dispatch({
        type: Constant.GET_CURRENT_DOB_CUTOFF,
        payload: response?.data,
      });
      localStorage.setItem('currentDobCutoff', response?.data);
    })
    .catch((error) => {
      console.log('error :', error);
    });
};
export const getRegistrationAcademicYearDates = () => (dispatch) => {
  getRegistrationAcademicYearDatesService()
    .then((response) => {
      dispatch({
        type: Constant.GET_REGISTRATION_DOB_CUTOFF,
        payload: response?.data[0].dobCutoff,
      });
      localStorage.setItem('registrationDobCutoff', response?.data[0].dobCutoff);
      dispatch({
        type: Constant.GET_REGISTRATION_ACADEMIC_YEAR,
        payload: response?.data[0].academicYear,
      });
      localStorage.setItem('registrationAcademicYear', response?.data[0].academicYear);
      dispatch({
        type: Constant.GET_REGISTRATION_ACADEMIC_YEAR_DATES,
        payload: response?.data[0].academicYear,
      });
      localStorage.setItem('registrationAcademicYearDates', JSON.stringify(response?.data));
      if (localStorage.getItem('getRegistrationAcademicYearDatesData')) {
        localStorage.removeItem('getRegistrationAcademicYearDatesData');
      }
    })
    .catch((error) => {
      console.log('error :', error);
    });
};
export const getTempRegisterInfo = (registrationId) => (dispatch) => {
  getTempRegisterInfoService(registrationId).then((response) => {
    dispatch({
      type: Constant.GET_REGISTRATION_TEMP_INFO,
      payload: response?.data,
    });
  });
};

export const editStudentByAdmin = (studentId, payload, setLoading) => async () => {
  try {
    const res = await editStudentByAdminService(studentId, payload);
    setLoading(false);
    return Promise.resolve(res);
  } catch (err) {
    setLoading(false);
    return Promise.reject(err);
  }
};
export const updateStudentInfo = (payload, refreshList, setLoading) => async () => {
  try {
    const res = await updateStudentInfoService(payload);
    refreshList();
    return Promise.resolve(res);
  } catch (err) {
    setLoading(false);
    return Promise.reject(err);
  }
};
export const getAcademicInfoId = (payload) => async () => {
  try {
    const res = await getAcademicInfoById(payload);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const getClassroomId = (payload) => async () => {
  try {
    const res = await getClassroomById(payload);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const updateSiblingInfo = (payload, siblingId, refreshList, setLoading) => async () => {
  try {
    const res = await updateSiblingInfoService(payload, siblingId);
    refreshList();
    return Promise.resolve(res);
  } catch (err) {
    setLoading(false);
    return Promise.reject(err);
  }
};
export const getStudentLogs = (studentId = false, logVal = false, isAcademicDates = false, isRegistrationDates = false, isAddTeacher = false, isAddSection = false) => (dispatch) => {
  getStudentLogsService(studentId, logVal, isAcademicDates, isRegistrationDates, isAddTeacher, isAddSection).then((response) => {
    dispatch({
      type: Constant.GET_STUDENT_LOGS,
      payload: response?.data,
    });
  });
};

export const getStudentLogDetail = (logId, isEmailLog, isAcademicDates = false, isRegistrationDates = false, isAddTeacher = false, isAddSection = false) => async (dispatch) => {
  try {
    const res = await getStudentLogDetailService(logId, isEmailLog, isAcademicDates, isRegistrationDates, isAddTeacher, isAddSection);
    dispatch({
      type: Constant.GET_STUDENT_LOG_DETAIL,
      payload: res?.data,
    });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAcademicGrades = () => (dispatch) => {
  getAcademicGradesService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ACADEMIC_GRADES,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getExtraCurricularActivities = () => (dispatch) => {
  getExtraCurricularActivitiesService()
    .then((response) => {
      dispatch({
        type: Constant.GET_EXTRA_CURRICULAR_ACTIVITIES,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};
export const getLocations = () => (dispatch) => {
  getLocationService()
    .then((response) => {
      dispatch({
        type: Constant.GET_LOCATIONS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getAllStudents = (setLoading) => (dispatch) => {
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  if (userRole === userRoles.REGION_COORDINATOR) {
    getStudentsByRegionService()
      .then((response) => {
        dispatch({
          type: Constant.GET_ALL_STUDENTS,
          payload: response?.data,
        });
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.message === Constant.NO_PERMISSION) {
          localStorage.setItem('permission', false);
        }
        console.log('error :', error);
        if (setLoading) {
          setLoading(false);
        }
      });
  }
};

export const getAllStudentsByAdmin = (
  pageNumber,
  pageLimit,
  nextPage,
  setLoading,
  students,
  body = {},
  setStudentLoading,
) => (dispatch) => {
  setStudentLoading ? setStudentLoading(true) : null;
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  if (userRole === userRoles.REGION_COORDINATOR) {
    getStudentsByRegionService(pageNumber, pageLimit, body)
      .then((response) => {
        setStudentLoading ? setStudentLoading(false) : null;
        setLoading ? setLoading(false) : null;
        nextPage
          ? nextPage(
            response,
            pageNumber === 1 ? 100 : students + response.data.length,
            pageNumber,
          )
          : null;
        if (pageNumber === 1) {
          dispatch({
            type: Constant.GET_ALL_STUDENTS,
            payload: response?.data,
          });
          if (setLoading) {
            setLoading(false);
          }
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: response?.count,
          });
        } else {
          dispatch({
            type: Constant.GET_PAGINATED_STUDENTS,
            payload: response?.data,
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: response?.count,
          });
        }
      })
      .catch((error) => {
        console.log('error :', error);
        if (setLoading) {
          setStudentLoading ? setStudentLoading(false) : null;
          setLoading(false);
        }
      });
  } else {
    setStudentLoading ? setStudentLoading(true) : null;
    if (pageNumber === 1) {
      dispatch({
        type: Constant.GET_ALL_STUDENTS,
        payload: [],
      });
      dispatch({
        type: Constant.GET_PAGINATED_STUDENTS,
        payload: [],
      });
    }
    getAdminPaginatedStudentsService(pageNumber, pageLimit, body)
      .then((res) => {
        setStudentLoading ? setStudentLoading(false) : null;
        setLoading ? setLoading(false) : null;
        nextPage
          ? nextPage(
            res,
            pageNumber === 1 ? 100 : students + Number(res?.data?.length),
            pageNumber,
          )
          : null;
        if (pageNumber === 1) {
          dispatch({
            type: Constant.GET_ALL_STUDENTS,
            payload: res?.data,
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: res?.count,
          });
        } else if (pageNumber > 1) {
          dispatch({
            type: Constant.GET_PAGINATED_STUDENTS,
            payload: res?.data,
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: res?.count,
          });
        } else {
          dispatch({
            type: Constant.GET_PAGINATED_STUDENTS,
            payload: [],
          });
          dispatch({
            type: Constant.GET_ALL_STUDENTS,
            payload: [],
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: 0,
          });
        }
      })
      .catch((error) => {
        console.log('error :', error);
        if (error.message === Constant.NO_PERMISSION) {
          localStorage.setItem('permission', false);
        }
        if (setLoading) {
          setLoading(false);
        }
      });
  }
};

export const getAllStudentsAfterCancel = (pageNumber, pageLimit, nextPage, setLoading, students, body = {}) => (dispatch) => {
  body?.filters
    && getAdminPaginatedStudentsService(pageNumber, pageLimit, body)
      .then((res) => {
        setLoading ? setLoading(false) : null;
        nextPage
          ? nextPage(
            res,
            pageNumber === 1 ? 100 : students + Number(res?.data?.length),
            pageNumber,
          )
          : null;
        if (pageNumber === 1) {
          dispatch({
            type: Constant.GET_ALL_STUDENTS,
            payload: res?.data,
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: res?.count,
          });
        } else {
          dispatch({
            type: Constant.GET_PAGINATED_STUDENTS,
            payload: res?.data,
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: res?.count,
          });
        }
      })
      .catch((error) => {
        if (error.message === Constant.NO_PERMISSION) {
          localStorage.setItem('permission', false);
        }
        console.log('error :', error);
        if (setLoading) {
          setLoading(false);
        }
      });
};

export const editExamDetails = (body, callback) => async () => {
  try {
    const res = await getEditExamDetails(body);
    await callback();
    return Promise.resolve(res);
  } catch (err) {
    callback();
    return Promise.reject(err);
  }
};

export const moveStudent = (payload, setShowError, setErrorMText, setLoading, callback) => (dispatch) => {
  moveStudentService(payload)
    .then((student) => {
      dispatch({
        type: Constant.MOVE_STUDENT,
        payload: student?.data,
      });
      callback();
    })
    .catch((error) => {
      console.log('error', error);
      setLoading(false);
      setShowError(true);
      setErrorMText(error?.errors?.[0]?.msg);
      // handle error
      dispatch({
        type: Constant.MOVE_STUDENT,
        payload: [],
      });
    });
};

export const getStudentAccountDetails = (loadRefreshData, setStudentState, setSiblingData) => (dispatch) => {
  getStudentAccountDetailService().then((response) => {
    setSiblingData
      ? setSiblingData(
        response?.data?.studentData?.filter((stu) => !stu.primaryStudent),
      )
      : null;
    setStudentState ? setStudentState(response?.data?.data) : null;
    dispatch({
      type: Constant.GET_STUDENT_ACCOUNT_DETAILS,
      payload: response?.data,
    });
    loadRefreshData ? loadRefreshData() : null;
  });
};

export const getStudentDetailsByYear = (year, studentId) => (dispatch) => {
  const payload = {
    studentId,
    academicYear: year,
  };
  getStudentDetailsByYearService(payload).then((response) => {
    dispatch({
      type: Constant.GET_STUDENT_COURSE_DETAILS,
      payload: {
        [payload.studentId]: response.data,
      },
    });
  });
};

export const addSibling = (data) => (dispatch) => {
  addSiblingService(data).then((response) => {
    dispatch({
      type: Constant.ADD_SIBLING_DATA,
      payload: response.data,
    });
  });
};

export const getAllFilter = (data, allFilters = false, setLoading) => (dispatch) => {
  const handleFilterResponse = (response, isAllFilters) => {
    const yearsFilter = response.data[0]?.options;
    yearsFilter?.sort(
      (a, b) => new Date(b?.academic_year?.substr(-4))
        - new Date(a?.academic_year?.substr(-4)),
    );
    response.data[0].options = yearsFilter;
    const regionFilter = response?.data[3]?.options?.filter(
      (item) => item.name !== null,
    );
    response.data[3].options = regionFilter;
    if (isAllFilters) {
      dispatch({
        type: Constant.SET_ALL_FILTERS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: Constant.SET_FILTERS,
        payload: response.data,
      });
    }
    dispatch({
      type: Constant.SET_LOADING,
      payload: false,
    });
    if (setLoading) {
      setLoading(false);
    }
  };

  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];

  if (userRole === userRoles.REGION_COORDINATOR) {
    // using the same handler as for the admin - handleFilterResponse
    getAllFilterServiceRegion(data).then((res) => handleFilterResponse(res, allFilters));
    return;
  }

  getAllFilterService(data).then((res) => handleFilterResponse(res, allFilters));
};

export const getAllFilterView = () => (dispatch) => {
  getAllFilterViewService().then((response) => {
    dispatch({
      type: Constant.SET_FILTER_VIEWS,
      payload: response.data,
    });
  });
};

export const newAddedFilterView = (data) => (dispatch) => {
  dispatch({
    type: Constant.NEW_ADDED_FILTER_VIEW,
    payload: data,
  });
};
export const addFilterView = (data, clearTitleAndError, setTitleError) => (dispatch) => {
  addFilterViewService(data).then(() => {
    dispatch(newAddedFilterView(data));
    dispatch(getAllFilterView());
    clearTitleAndError();
  }).catch((err) => {
    if (err?.errors?.[0]?.msg) {
      setTitleError(err?.errors?.[0]?.msg);
    }
  });
};

export const getNextEnrollCourseAdmin = (payload) => (dispatch) => {
  getNextEnrollCourseAdminService(payload)
    .then((response) => {
      if (payload.isFromEditPage) {
        dispatch({
          type: Constant.SET_NEXT_ENROLL_COURSE_LIST,
          payload: response.data,
        });
      } else {
        dispatch({
          type: Constant.SET_NEXT_ENROLL_COURSE,
          payload: response.data,
        });
        dispatch({
          type: Constant.SET_NEXT_ENROLL_COURSE_LIST,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      // handle error
      dispatch({
        type: Constant.SET_NEXT_ENROLL_COURSE,
        payload: [],
      });
    });
};

export const getStudentMarks = (studentId) => () => {
  getStudentMarksService(studentId)
    .then(() => { })
    .catch((err) => {
      console.log('err', err);
    });
};

export const getStudentEnrollmentHistory = (payload, setAssignLoading) => (dispatch) => {
  dispatch({
    type: Constant.CLEAN_STUDENT_ENROLLMENT_DETAILS,
    payload: {},
  });
  getStudentEnrollmentService(payload)
    .then((response) => {
      dispatch({
        type: Constant.SET_STUDENT_ENROLLMENT_DETAILS,
        payload: {
          [payload.studentId]: response.data,
        },
      });
      if (setAssignLoading) setAssignLoading(false);
    })
    .catch((err) => {
      console.log('err', err);
    });
};

export const getStudentEditExamDetails = (body, callback) => async () => {
  try {
    const res = await getStudentExamDetails(body);
    await callback();
    return Promise.resolve(res);
  } catch (err) {
    callback();
    return Promise.reject(err);
  }
};

export const getMarksDetails = (payload, setLoadingSpinner) => (dispatch) => {
  getStudentsByLocationService(payload)
    .then((response) => {
      const getMarks = response.data.filter(
        (item) => item.id === payload.studentId,
      );
      dispatch({
        type: Constant.GET_STUDENTS_BY_LOCATION,
        payload: getMarks,
      });
      setLoadingSpinner(false);
    })
    .catch((err) => {
      console.log('err', err);
    });
};

export const postStudentMarksByQuarter = (payload, setAssignLoading) => (dispatch) => {
  studentMarksByQuarter(payload)
    .then((response) => {
      dispatch({
        type: Constant.GET_MARKS_BY_QUARTER,
        payload: response.data,
      });
      setAssignLoading(false);
    })
    .catch((err) => {
      console.log('err', err);
    });
};

export const putStudentMarksByQuarter = (payload, setLoadingSpinner, setOpenMarksPopUp, setAssignLoading) => (dispatch) => {
  updateMarksService(payload)
    .then(() => {
      getStudentsByLocationService(payload)
        .then((response) => {
          const getMarks = response.data.filter(
            (item) => item.id === payload.idStudent,
          );
          dispatch({
            type: Constant.GET_STUDENTS_BY_LOCATION,
            payload: getMarks,
          });
          setLoadingSpinner(false);
          setOpenMarksPopUp(false);
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log('err', err);
        });
    })
    .catch((err) => {
      console.log('err', err);
    });
};

export const getMarksDetailsbyStudents = (payload, handleExportToCsv) => async () => {
  const dataStudents = [];
  for (let index = 0; index < payload.length; index++) {
    const response = await getStudentsByLocationService(payload[index]);
    const getMarks = response.data.filter(
      (item) => item.id === payload[index].idStudent,
    );
    dataStudents.push(...getMarks);
  }
  handleExportToCsv(dataStudents);
};

export const getExportedStudentData = (payload, setLoading, setExportSuccessfull, setOpenCSVDialog) => async () => {
  // let dataStudents = [];
    const response = exportStudentData(payload);
    setOpenCSVDialog(false);
    setLoading(false);
    setExportSuccessfull(true);

};
export const updateExamCenter = (payload, callback, setIsEditExamDetailsOpen) => async () => {
  try {
    const res = await getUpdateExamCenter(payload);
    await callback();
    setIsEditExamDetailsOpen(false);
    return Promise.resolve(res);
  } catch (err) {
    callback(false);
    return Promise.reject(err);
  }
};

export const getExportedSheetF = (setLoading) => (dispatch) => {
  getExportedSheets()
    .then((response) => {
      setLoading(false);
      dispatch({
        type: Constant.GET_EXPORTED_SHEETS,
        payload: response.data,
      });
    })
    .catch(() => {
      setLoading(false);
    });
};

export const deleteFilterView = (id) => (dispatch) => {
  deleteFilterViewService(id).then(() => {
    dispatch(getAllFilterView());
    dispatch({
      type: Constant.SET_SELECTED_FILTER,
      payload: [
        {
          label: getCurrentYear().id,
          groupBy: 'Academic Year',
          filterKey: 'academic_year',
        },
        {
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        },
      ],
    });
  });
};

export const postExportedSheetF = (payload, setOpenCSVDialog, setOkOpenDialog, setLoading, setIsCheck, setIsCheckAll, setSelectedRows, setExportSuccessfull) => () => {
  const d = moment(payload.creationDate).format();
  payload.creationDate = moment.utc(d).format('MM-DD-YYYY HH:mm:ss');
  addExportedSheets(payload)
    .then(() => {
      setExportSuccessfull(true);
      setSelectedRows([]);
      setOpenCSVDialog(false);
      setOkOpenDialog(true);
      setLoading(false);
      setIsCheck([]);
      setIsCheckAll([]);
    })
    .catch(() => {
      setLoading(false);
    });
};

export default {
  getTShirts,
  getAllCourses,
  getAcademicGrades,
  getExtraCurricularActivities,
  getLocations,
  getAllStudents,
  editExamDetails,
  moveStudent,
  getStudentAccountDetails,
  updateStudentInfo,
  addSibling,
  editStudentByAdmin,
  addFilterView,
  getAllFilter,
  getNextEnrollCourseAdmin,
  getStudentMarks,
  getStudentEnrollmentHistory,
  getMarksDetails,
  postStudentMarksByQuarter,
  putStudentMarksByQuarter,
  getMarksDetailsbyStudents,
  updateExamCenter,
  getStudentEditExamDetails,
  getStudentDetailsByYear,
  getExportedSheetF,
  postExportedSheetF,
  createPaymentOrderStripe,
  createPaymentOrderSquare,
};
