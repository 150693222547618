import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../../../../theme';
// fonts
export const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '3vw',
    height: '3vw',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      width: '50px',
      height: '50px',
    },
  },
  withoutScroll: {
    fontSize: 0,
    '& .MuiDataGrid-virtualScroller': {
      overflowY: 'hidden !important',
      overflowX: 'auto !important',
      '& .MuiDataGrid-virtualScrollerContent': {
        height: 'auto !important',
        '& .MuiDataGrid-virtualScrollerRenderZone': {
          transform: 'inherit !important',
          position: 'static',
        },
      },
    },
  },
  studentKeys: {
    color: colors.primary,
    textAlign: 'left',
    paddingBottom: '0.3vw',
    '& .MuiFormControlLabel-label': {
      '@media (min-width: 1200px)': {
        fontSize: '1vw',
      },
    },
    marginLeft: '5px !important',
  },
  marksGreen: {
    backgroundColor: colors.lightGreen,
    padding: '0.4vw',
    borderRadius: '0.4vw',
    cursor: 'pointer',
    display: 'block',
    width: '50px !important',
    marginLeft: '20%',
  },
  homeworkColor: {
    backgroundColor: colors.homeworkShade,
    padding: '0.4vw',
    borderRadius: '0.4vw',
    cursor: 'pointer',
    display: 'block',
    width: '50px !important',
    marginLeft: '20%',
  },
  assignmentsColor: {
    backgroundColor: colors.assignmentsShade,
    padding: '0.4vw',
    borderRadius: '0.4vw',
    cursor: 'pointer',
    display: 'block',
    width: '50px !important',
    marginLeft: '20%',
  },
  quizColor: {
    backgroundColor: colors.quizShade,
    padding: '0.4vw',
    borderRadius: '0.4vw',
    cursor: 'pointer',
    display: 'block',
    width: '50px !important',
    marginLeft: '20%',
  },
  curricularColor: {
    backgroundColor: colors.curricularShade,
    padding: '0.4vw',
    borderRadius: '0.4vw',
    cursor: 'pointer',
    display: 'block',
    width: '50px !important',
    marginLeft: '20%',
  },
  marksRed: {
    backgroundColor: colors.errorText,
    padding: '0.4vw',
    borderRadius: '0.4vw',
    cursor: 'pointer',
    display: 'block',
    width: '50px !important',
    marginLeft: '20%',
  },
  marksYellow: {
    backgroundColor: colors.yellow,
    padding: '0.4vw',
    borderRadius: '0.4vw',
    cursor: 'pointer',
    display: 'block',
    width: '50px !important',
    marginLeft: '20%',
  },
  marksGrey: {
    backgroundColor: colors.grey,
    padding: '0.4vw',
    borderRadius: '0.4vw',
    cursor: 'pointer',
    display: 'block',
    width: '50px !important',
    marginLeft: '20%',
  },
  studentDetails: {
    textAlign: 'center',
    paddingTop: '0.2vw',
    overflow: 'auto',
    '& .MuiDataGrid-row': {
      minHeight: '5vw !important',
      maxHeight: '5vw !important',
      alignItems: 'center',
    },
    '& svg': {
      width: '1vw !important',
      height: '1vw !important',
      [theme.breakpoints.down('md')]: {
        height: '15px !important',
        width: '15px !important',
      },
    },
    '& .MuiDataGrid-cell': {
      border: 'none',
    },
    '& .MuiDataGrid-root': {
      minHeight: '5vw !important',
      border: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      border: 'none',
    },
    '& .fa-sort-down': {
      marginBottom: '0',
    },
    '& .fa-sort-up': {
      marginTop: '0',
    },
    '& .MuiDataGrid-columnHeader[data-role="studentName"]': {
      width: '5vw',
    },
  },
  loadMore: {
    textAlign: 'right',
    marginLeft: '10px',
    '& .MuiButton-root': {
      marginRight: '4.5vw',
      minWidth: '8vw',
    },
  },
  totalRowCount: {
    borderBottom: 'none',
    marginBottom: '1vw',
    textAlign: 'right',
    fontSize: '0.9vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    color: colors.black,
    '& span': {
      marginRight: '4.5vw',
    },
  },
  dataGrid: {
    '.MuiDataGrid-root': {
      outline: 'none !important',
      border: 'none !important',
    },
    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
      width: '0 !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid #025EE6 !important',
      borderTop: 'none',
      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
      fontStyle: 'bold',
      fontSize: '1vw',
      lineHeight: '131.19%',
      color: colors.actionIconsColor,
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      width: '75% !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      '@media (min-width: 1200px)': {
        marginTop: '3vw !important',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '1vw',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'left',
    },
    '& .parentHeader, .parentNameCell': { //------------
      width: '13vw !important',
      minWidth: '13vw !important',
      maxWidth: '13vw !important',
      fontSize: '1vw',
      '@media (min-width: 1200px)': {
        minHeight: '3vw !important',
        maxHeight: '3vw !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .picturesHeader, .picturesCell': { //------------
      width: '7vw !important',
      minWidth: '7vw !important',
      maxWidth: '7vw !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '60px !important',
        minWidth: '60px !important',
        maxWidth: '60px !important',
      },
    },
    '& .MuiDataGrid-cellCheckbox, .MuiDataGrid-columnHeaderCheckbox': {
      '@media (min-width: 1200px)': {
        minHeight: '3vw !important',
        maxHeight: '3vw !important',
        minWidth: '3vw !important',
        maxWidth: '3vw !important',
      },
    },
    '& .studentNameHeader, .studentNameCell': { //------------
      '@media (min-width: 1200px)': {
        width: '13vw !important',
        minWidth: '13vw !important',
        maxWidth: '13vw !important',
        fontSize: '1vw',
        minHeight: '3vw !important',
        maxHeight: '3vw !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .centerHeader, .centerCell': { //------------
      width: '20vw !important',
      minWidth: '20vw !important',
      maxWidth: '20vw !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .classHeader, .classCell': {
      width: '9vw !important',
      minWidth: '9vw !important',
      maxWidth: '9vw !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .sectionHeader, .sectionCell': {
      width: '8vw !important',
      minWidth: '8vw !important',
      maxWidth: '8vw !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '60px !important',
        minWidth: '60px !important',
        maxWidth: '60px !important',
      },
    },
    '& .dateHeader, .dateCell': {
      width: '9vw !important',
      minWidth: '9vw !important',
      maxWidth: '9vw !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .actionHeader, .actionCell': {
      width: '12vw !important',
      minWidth: '12vw !important',
      maxWidth: '12vw !important',
      fontSize: '1vw',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
      outlineOffset: 0,
    },
    '& .actionCell': {
      paddingLeft: '2vw',
    },
    '& .MuiTablePagination-root': {
      '& .MuiTablePagination-selectLabel, .MuiInputBase-root': {
        display: 'none',
      },
      '& svg': {
        [theme.breakpoints.down('md')]: {
          width: '20px !important',
          height: '20px !important',
        },
      },
      '& .MuiTablePagination-displayedRows': {
        fontSize: '1vw !important',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px !important',
        },
      },
    },
  },
  noData: {
    marginTop: '3vw',
  },
  check: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    minWidth: '3vw !important',
    maxWidth: '3vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  studentName: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '13vw !important',
    minWidth: '13vw !important',
    maxWidth: '13vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
  },
  studentNameLabel: {
    color: '#015EEA !important',
    '& .MuiTableSortLabel-icon': {
      width: '40px !important',
    },
    '& div': {
      paddingLeft: '2px !important',
    },
  },
  picture: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '7vw !important',
    minWidth: '7vw !important',
    maxWidth: '7vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '60px !important',
      minWidth: '60px !important',
      maxWidth: '60px !important',
    },
  },
  parentName: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '13vw !important',
    minWidth: '13vw !important',
    maxWidth: '13vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
  },
  centerInfo: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '20vw !important',
    minWidth: '20vw !important',
    maxWidth: '20vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  classLevel: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '9vw !important',
    minWidth: '12vw !important',
    maxWidth: '9vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  section: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '8vw !important',
    minWidth: '8vw !important',
    maxWidth: '8vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '60px !important',
      minWidth: '60px !important',
      maxWidth: '60px !important',
    },
  },
  regDate: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '9vw !important',
    minWidth: '11vw !important',
    maxWidth: '9vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  actions: {
    borderBottom: '1px solid #025EE6 !important',
    borderTop: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '1vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '12vw !important',
    minWidth: '12vw !important',
    maxWidth: '12vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  tableCellCheck: {
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    minWidth: '3vw !important',
    maxWidth: '3vw !important',
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {

      minHeight: 'min-content !important',
      maxHeight: 'min-content !important',
      minWidth: 'min-content !important',
      maxWidth: 'min-content !important',
    },
  },
  tableCellPictures: {
    borderBottom: 'none',
    width: '7vw !important',
    minWidth: '7vw !important',
    maxWidth: '7vw !important',
    fontSize: '1vw',
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '60px !important',
      minWidth: '60px !important',
      maxWidth: '60px !important',
    },
  },
  tableCellStudent: {
    borderBottom: 'none',
    width: '13vw !important',
    minWidth: '13vw !important',
    maxWidth: '13vw !important',
    fontSize: '1vw',
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  tableCellParent: {
    borderBottom: 'none',
    textOverflow: 'ellipsis',
    width: '13vw !important',
    minWidth: '13vw !important',
    maxWidth: '13vw !important',
    fontSize: '1vw',
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  tableCellCenter: {
    borderBottom: 'none',
    width: '20vw !important',
    minWidth: '20vw !important',
    maxWidth: '20vw !important',
    fontSize: '1vw',
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  tableCellClass: {
    borderBottom: 'none',
    width: '9vw !important',
    minWidth: '9vw !important',
    maxWidth: '9vw !important',
    fontSize: '1vw',
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  tableCellSection: {
    borderBottom: 'none',
    width: '8vw !important',
    minWidth: '8vw !important',
    maxWidth: '8vw !important',
    fontSize: '1vw',
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '60px !important',
      minWidth: '60px !important',
      maxWidth: '60px !important',
    },
  },
  tableCellDate: {
    borderBottom: 'none',
    width: '9vw !important',
    minWidth: '9vw !important',
    maxWidth: '9vw !important',
    fontSize: '1vw',
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  mailButton: {
    paddingLeft: 0,
  },
  tableCellAction: {
    borderBottom: 'none',
    width: '12vw !important',
    minWidth: '12vw !important',
    maxWidth: '12vw !important',
    fontSize: '1vw',
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      width: '120px !important',
      minWidth: '120px !important',
      maxWidth: '120px !important',
    },
  },
  tableMarksCourse: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '.9vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '8vw !important',
    minWidth: '8vw !important',
    maxWidth: '8vw !important',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableMarksQ: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '.9vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableMarks: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '.9vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '8vw !important',
    minWidth: '8vw !important',
    maxWidth: '8vw !important',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableMarksSemester: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '.9vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '5vw !important',
    minWidth: '5vw !important',
    maxWidth: '5vw !important',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableMarksHeaderMarks: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '.9vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '8vw !important',
    minWidth: '8vw !important',
    maxWidth: '8vw !important',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableMarksHeaderHw: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontStyle: 'bold',
    fontSize: '.9vw',
    lineHeight: '131.19%',
    color: colors.actionIconsColor,
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '8vw !important',
    minWidth: '8vw !important',
    maxWidth: '8vw !important',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  tablesMarksQR: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontSize: '.9vw',
    lineHeight: '200%',
    color: 'white',
    textAlign: 'center',
  },
  tableMarksCourseR: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontSize: '.9vw',
    lineHeight: '200%',
    textAlign: 'center',
    minHeight: '3vw !important',
    maxHeight: '3vw !important',
    width: '8vw !important',
    minWidth: '8vw !important',
    maxWidth: '8vw !important',
  },
  tablesMarksQ: {
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontSize: '.9vw',
    lineHeight: '200%',
    color: 'black',
    textAlign: 'center',
  },
  expanAction: {
    textAlign: 'right',
  },
  expanActionSemester: {
    textAlign: 'right',
    width: '2vw !important',
    minWidth: '2vw !important',
    maxWidth: '2vw !important',
  },
  spaceFirst: {
    width: '2vw !important',
    minWidth: '2vw !important',
    maxWidth: '2vw !important',
  },
  addMakupMarksPopup: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid',
      borderRadius: 'unset',
      borderColor: 'unset',
      border: 'transparent',
    },
    '& .popupBtn': {
      '& .makeStyles-dialog .MuiButton-textPrimary': {
        minWidth: '9vw !important',
      },
    },
  },
  marksUpdatePopup: {
    '&  .MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper': {
      top: '55%',
      left: '45%',
    },
  },
  popupBtnDisable: {
    opacity: '.6',
    '& button': {
      cursor: 'not-allowed',
    },
  },
}));

export default {
  useStyles,
};
