/* eslint-disable no-unused-expressions */
import Constant from '../constant/index';

import {
  getAllExamCenterManagerService, editExamCenterService, createExamCenterService,
} from '../services/auth';

export const getExamCenterManager = (setLoading, pageNumber, pageLimit, nextPage, searchText) => (dispatch) => {
  getAllExamCenterManagerService(pageNumber, pageLimit, searchText)
    .then((response) => {
      if (response.success) {
        nextPage
          ? nextPage(
            response?.data,
            pageNumber === 1 ? 100 : response?.data?.count,
            pageNumber,
          )
          : null;
        if (pageNumber === 1) {
          dispatch({
            type: Constant.GET_EXAM_CENTER_MANAGER,
            payload: response?.data?.rows,
          });
          dispatch({
            type: Constant.TOTAL_EXAM_CENTER_COUNT,
            payload: response?.data?.count,
          });
        } else if (pageNumber > 1) {
          dispatch({
            type: Constant.GET_PAGINATED_EXAM_CENTER,
            payload: response?.data?.rows,
          });
          dispatch({
            type: Constant.TOTAL_EXAM_CENTER_COUNT,
            payload: response?.data?.count,
          });
        } else {
          dispatch({
            type: Constant.GET_EXAM_CENTER_MANAGER,
            payload: [],
          });
          dispatch({
            type: Constant.GET_PAGINATED_EXAM_CENTER,
            payload: [],
          });
          dispatch({
            type: Constant.TOTAL_EXAM_CENTER_COUNT,
            payload: 0,
          });
        }
        setLoading(false);
      }
    })
    .catch(() => {
      setLoading(false);
    });
};

export const createExamCenter = (payload, refreshList, nameExistError, setShowAddExamCenterDialog, setLoading, setSuccessMessage) => {
  createExamCenterService(payload)
    .then(() => {
      refreshList();
      setShowAddExamCenterDialog(false);
      setLoading(false);
      setSuccessMessage(true);
    }).catch((err) => {
      nameExistError(err.errors[0].msg);
      setLoading(false);
    });
};

export const editExamCenter = (payload, refreshList, nameExistError, setShowEditExamCenterDialog, setLoading) => {
  editExamCenterService(payload)
    .then(() => {
      refreshList();
      setShowEditExamCenterDialog(false);
      setLoading(false);
    }).catch((err) => {
      nameExistError(err.errors[0].msg);
      setLoading(false);
    });
};

export default {
  getExamCenterManager,
};
