/* eslint-disable array-callback-return */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Box, Grid, TextField, Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import useStyles from '../../../custom-hooks/useStyles';
import { materialStyles } from './styles';

function RenderCategoryField({
  questions,
  setTotalQuestions,
  handleSave,
  courseFieldData,
  courseFieldErrors,
  courseFieldTouched,
  changeAcademicPanelMarks,
  showSaveBtn,
  schemaCanBeEdited,
  useGetCourseRows,
  styles,
  restrictKeys,
  refreshSchema,
  setSuccessMsg,
  setError,
  setSaveClicked,
}) {
  const { t } = useTranslation();
  const classes = useStyles(materialStyles)();
  const { rows } = useGetCourseRows(questions);
  const [showCategoryError, setShowCategoryError] = useState(false);
  const [categoryRows, setCategoryRows] = useState([]);
  const [showRows, setShowRows] = useState(false);

  useEffect(() => {
    const categorySet = new Set();
    const categorySetRows = [];
    let hasDuplicate = false;
    courseFieldData.map((data) => {
      const category = data?.category || '';
      if (categorySet.has(category)) {
        if (category !== '') {
          hasDuplicate = true;
          categorySetRows.push(category);
        }
      } else {
        categorySet.add(category);
      }
    });
    if (hasDuplicate) {
      setShowCategoryError(true);
      setCategoryRows(categorySetRows);
    } else {
      setShowCategoryError(false);
    }
  }, [rows, courseFieldData]);

  useEffect(() => {
    if (!rows || !rows.length) {
      setShowRows(false);
    } else {
      setShowRows(true);
    }
  }, [rows]);
  const deleteRow = (id) => {
    setTotalQuestions(questions - 1, id);
  };

  const onCrossClick = (rowNo) => {
    if (!schemaCanBeEdited) {
      return;
    }
    deleteRow(rowNo);
  };

  const handleChange = (e, rowNo, target) => {
    const { value, name } = target || e.target;
    changeAcademicPanelMarks(rowNo, name, value);
  };

  return (
    <Box marginTop={2} minWidth="auto" style={{ overflowX: 'auto' }}>
      {showRows && (
        <>
          <Box style={styles.courseTableHeadingCont} minWidth="600px">
            <Grid container spacing={3} style={{ flexFlow: 'row' }}>
              <Grid item xs={3} md={3} lg={3}>
                <Typography style={styles.courseTableHeading}>
                  {t('QUESTIONS')}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography style={styles.courseTableHeading}>
                  {t('MARKS')}
                </Typography>
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <Typography style={styles.courseTableHeading}>
                  {t('CATEGORY')}
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
          <Box marginTop={2}>
            {rows.map((id) => {
              if (courseFieldData[id]?.deletedAt) {
                return null;
              }

              return (
                <Box key={id} marginTop={2} minWidth={650}>
                  <Grid container spacing={3} style={{ flexFlow: 'row' }}>
                    {!courseFieldData[id]?.deletedAt && (
                      <>
                        <Grid item xs={3} md={3} lg={3} className={classes.formControl}>
                          <TextField
                            label={t('CATEGORY')}
                            id="category"
                            type="number"
                            required
                            name="category"
                            disabled={!schemaCanBeEdited}
                            value={courseFieldData[id]?.category || ''}
                            onChange={(e) => handleChange(e, id)}
                            labelId="category"
                            size="small"
                            onKeyPress={restrictKeys}
                            inputProps={{ min: '0', style: styles.textFieldLabel }}
                            InputLabelProps={{ style: styles.textFieldLabel }}
                            error={courseFieldErrors && courseFieldErrors[id]?.category && courseFieldTouched && courseFieldTouched[id]?.category ? courseFieldErrors[id]?.category : false}
                            helperText={courseFieldErrors && courseFieldErrors[id]?.category && courseFieldTouched && courseFieldTouched[id]?.category ? courseFieldErrors[id]?.category : ''}
                          />
                        </Grid>
                        <Grid item xs={3} md={3} lg={3} className={classes.formControl}>
                          <TextField
                            label={t('MARKS')}
                            id="marks"
                            required
                            name="marks"
                            type="number"
                            disabled={!schemaCanBeEdited}
                            value={courseFieldData[id]?.marks || ''}
                            onChange={(e) => handleChange(e, id)}
                            labelId="marks"
                            size="small"
                            onKeyPress={restrictKeys}
                            inputProps={{ min: '0', style: styles.textFieldLabel }}
                            InputLabelProps={{ style: styles.textFieldLabel }}
                            error={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : false}
                            helperText={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : ''}
                          />
                        </Grid>
                        <Grid item xs={5} md={5} lg={5} className={classes.formControl}>
                          <TextField
                            label={t('NOTES')}
                            id="notes"
                            required
                            name="notes"
                            type="text"
                            disabled={!schemaCanBeEdited}
                            value={courseFieldData[id]?.notes || ''}
                            onChange={(e) => handleChange(e, id)}
                            labelId="notes"
                            size="small"
                            inputProps={{ style: styles.textFieldLabel }}
                            InputLabelProps={{ style: styles.textFieldLabel }}
                            error={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : false}
                            helperText={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : ''}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Box mt={2} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                            <ClearIcon onClick={() => onCrossClick(id)} />
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {showCategoryError && (
                    categoryRows.map((cat, index) => {
                      if (cat === courseFieldData[id]?.category) {
                        const hasDisplayedError = categoryRows.slice(0, index).some((c) => c === cat);
                        if (hasDisplayedError) {
                          return null;
                        }
                        return (
                          <Box style={styles.errorCont}>
                            <Typography style={styles.errorText} variant="text">
                              {t('CATEGORY_NUMBER_ERROR')}
                            </Typography>
                          </Box>
                        );
                      }
                      return null;
                    })
                  )}
                </Box>
              );
            })}
          </Box>
        </>
      )}

      {showSaveBtn && !showCategoryError
        && (
          <Box marginTop={2} alignItems="flex-end" style={styles.saveBtnCont}>
            <ButtonAtom
              btntype={Buttons.SECONDARY}
              onClick={() => {
                setSuccessMsg('');
                setError('');
                refreshSchema();
              }}
              name={t('CANCEL')}
              type="submit"
            />

            <ButtonAtom
              btntype={Buttons.PRIMARY}
              onClick={() => {
                setSaveClicked(true);
                handleSave();
              }}
              name={t('SAVE')}
              type="submit"
            />
          </Box>
        )}
    </Box>
  );
}

export default RenderCategoryField;
