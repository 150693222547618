/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import clsx from 'clsx';
import useStyles from '../../../custom-hooks/useStyles';
import style from './style';

function Phone({
  onChange, value, label, id, error, onBlur, defaultCountry, onCountryChange, customClass = '', disabled, inputRef, inputProps,
}) {
  const classes = useStyles(style)();
  return (
    <PhoneInput
      containerClass={classes.borderClass}
      label={label}
      ref={inputRef}
      usenationalformatfordefaultcountryvalue
      international
      countryCallingCodeEditable={false}
      maxLength={15}
      placeholder={label}
      value={value}
      countries={['US', 'CA']}
      onChange={onChange}
      onCountryChange={onCountryChange}
      onBlur={onBlur}
      numberInputProps={inputProps}
      className={clsx(
        customClass === '' || typeof customClass === 'undefined' ? classes.phoneInput : '',
        error ? classes.errorBorder : '',
        classes[customClass],
      )}
      data-testid={id}
      defaultCountry={defaultCountry || 'US'}
      addInternationalOption
      disabled={disabled}
    />
  );
}

export default Phone;
