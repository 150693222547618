/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Grid, Stack, TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { FormikProvider } from 'formik';
import moment from 'moment';
import TimePickerAtom from '../../../../components/atoms/timePicker';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import logsIcon from '../../../../assets/images/logs-icon.png';
import { AntSwitch } from '../../user-manager/helperComponent';
import style from './style';
import {
  PerformantTextField,
  PerformantDropdown,
  PerfromantMultiValueDropdown,
  AddressAutoComplete,
  Loader,
} from '../../../../components/atoms';
import { getWeekDays } from '../helper';
import MultiAutoComplete from '../add-coordinator';
import { getPlaceInformationWithCity } from '../../../../utils/mapMethod';

function AddEditLocation({
  formik, setShowLocationDialog, showLocationDialog, loading,
  getErrorText, state, locationState, activeRegions, setLocationAddressInfo, setShippingAddressInfo,
  locationActive, setLocationActive, examCenterActive, setExamCenterActive, locationExistsError, isEdit,
  selectedOptions, setSelectedOptions, locationActiveError, examCenterActiveError, primaryContactNumber,
  setPrimaryContactNumber, coordinatorError, setCoordinatorError, startEndTimeError, setStartEndTimeError,
  viewLogs, setStartTime, setEndTime, setLocationExistsError,
}) {
  useEffect(() => {
    const regionFromActiveRegions = activeRegions?.find((i) => i?.id === formik?.values?.region);
    formik.setFieldValue('region', regionFromActiveRegions?.id || '');
  }, [activeRegions]);
  const { t } = useTranslation();
  const classes = style();
  const activeCourses = state.courses;
  const activeExamCenters = locationState.examCenters;
  const [isLocAddFromGoogle, setLocAddFromGoogle] = useState(null);
  const [isShipAddFromGoogle, setShipAddFromGoogle] = useState(null);

  useEffect(() => {
    setStartTime(formik.values.startTime);
  }, [formik.values.startTime]);

  useEffect(() => {
    formik.setFieldValue('contactNumber', primaryContactNumber);
  }, [primaryContactNumber]);

  const locationAddressBlur = (e) => {
    formik.handleBlur(e);
    formik.setTouched({ ...formik.touched, locationAddress: true }, true);
  };
  const shippingAddressBlur = (e) => {
    formik.handleBlur(e);
    formik.setTouched({ ...formik.touched, shippingAddress: true }, true);
  };

  const startTimeBlur = (e) => {
    formik.handleBlur(e);
    formik.setTouched({ ...formik.touched, startTime: true }, true);
  };

  const endTimeBlur = (e) => {
    formik.handleBlur(e);
    formik.setTouched({ ...formik.touched, endTime: true }, true);
  };

  return (
    <Box className={classes.gridContainer}>
      <FormikProvider value={formik}>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12} lg={6} md={6}>
            <PerformantTextField
              label={t('LOCATION_NAME')}
              id="locationName"
              required
              name="locationName"
              type="text"
              disabled={false}
              value={formik.values.locationName}
              onBlur={formik.handleBlur}
              error={getErrorText('locationName')}
              onChange={(e) => {
                formik.handleChange(e);
                setLocationExistsError('');
              }}
              labelId="locationName"
              allowSpecialCharacter
            />
            <span className={classes.errorText}>{locationExistsError}</span>
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <Typography className={classes.switchHeading}>{t('STATUS')}</Typography>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <Typography className={`${classes.switchText} ${!locationActive ? classes.inActiveText : null}`}>{t('INACTIVE_STATUS')}</Typography>
              <AntSwitch
                checked={locationActive}
                onChange={() => {
                  setLocationActive(!locationActive);
                }}
                inputProps={{ 'aria-label': 'ant design' }}
              />
              <Typography className={`${classes.switchText} ${locationActive ? classes.activeText : null}`}>{t('ACTIVE_STATUS')}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <span className={classes.errorText}>{locationActiveError}</span>
            </Stack>

          </Grid>
          <Grid item xs={12} lg={6} md={6} className={classes.addressCSS}>
            <AddressAutoComplete
              shrink
              showLabel
              label={`${t('LOCATION_ADDRESS')}`}
              id="locationAddress"
              required
              name="locationAddress"
              value={formik.values.locationAddress}
              error={getErrorText('locationAddress')}
              onPlaceSelected={async (e) => {
                const res = await getPlaceInformationWithCity(e.formatted_address);
                formik.setFieldValue(
                  'locationAddress',
                  e.formatted_address,
                  true,
                );
                setLocationAddressInfo({ ...res, add: e.formatted_address });
                setLocAddFromGoogle(true);
              }}
              onChange={(e) => {
                formik.setFieldValue('locationAddress', e.target.value);
                setLocAddFromGoogle(false);
              }}
              onBlur={locationAddressBlur}
              customClass="addressStyle1"
            />
            {
              // eslint-disable-next-line no-nested-ternary
              (getErrorText('locationAddress') !== null)
                ? <span className={classes.errorText}>{getErrorText('locationAddress')}</span>
                : (isLocAddFromGoogle === false)
                  ? <span className={classes.errorText}>Address must be selected from suggestions</span>
                  : null
            }

          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <PerformantTextField
              label={t('APISUITE')}
              id="locationAddressAptSuite"
              name="locationAddressAptSuite"
              type="text"
              disabled={false}
              value={formik.values.locationAddressAptSuite}
              error={getErrorText('locationAddressAptSuite')}
              onChange={formik.handleChange}
              labelId="locationAddressAptSuite"
              allowSpecialCharacter
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} className={classes.addressCSS}>
            <AddressAutoComplete
              shrink
              showLabel
              label={`${t('SHIPPING_ADDRESS')}`}
              id="shippingAddress"
              required
              name="shippingAddress"
              value={formik.values.shippingAddress}
              error={getErrorText('shippingAddress')}
              onPlaceSelected={async (e) => {
                const res = await getPlaceInformationWithCity(e.formatted_address);
                formik.setFieldValue(
                  'shippingAddress',
                  e.formatted_address,
                  true,
                );
                setShippingAddressInfo({ ...res, add: e.formatted_address });
                setShipAddFromGoogle(true);
              }}
              onChange={(e) => {
                formik.setFieldValue('shippingAddress', e.target.value);
                setShipAddFromGoogle(false);
              }}
              onBlur={shippingAddressBlur}
              customClass="addressStyle1"
            />

            {
              // eslint-disable-next-line no-nested-ternary
              (getErrorText('shippingAddress') !== null)
                ? <span className={classes.errorText}>{getErrorText('shippingAddress')}</span>
                : (isShipAddFromGoogle === false)
                  ? <span className={classes.errorText}>{t('LOCATION_ADDRESS_FROM_SUGESTIONS')}</span>
                  : null
            }
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <PerformantTextField
              label={t('APISUITE')}
              id="shippingAddressAptSuite"
              name="shippingAddressAptSuite"
              type="text"
              disabled={false}
              value={formik.values.shippingAddressAptSuite}
              error={getErrorText('shippingAddressAptSuite')}
              onChange={formik.handleChange}
              labelId="shippingAddressAptSuite"
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} className={classes.dropDownSelect}>
            <PerformantDropdown
              label={(examCenterActive) ? t('EXAM_CENTER*') : t('EXAM_CENTER')}
              id="examCenter"
              maxWidth="50%"
              customSelectClass={classes.select}
              shrink
              name="examCenter"
              type="text"
              disabled={false}
              value={formik.values.examCenter}
              onBlur={formik.handleBlur}
              error={getErrorText('examCenter')}
              onChange={formik.handleChange}
              labelId="examCenter"
              options={activeExamCenters}
            />
            <span className={classes.errorText}>{getErrorText('examCenter')}</span>
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <Typography className={classes.switchHeading}>{t('EXAM_CENTER')}</Typography>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <Typography className={`${classes.switchText} ${!examCenterActive ? classes.inActiveText : null}`}>{t('DEACTIVATE')}</Typography>
              <AntSwitch
                checked={examCenterActive}
                onChange={() => {
                  setExamCenterActive(!examCenterActive);
                }}
                inputProps={{ 'aria-label': 'ant design' }}
              />
              <Typography className={`${classes.switchText} ${examCenterActive ? classes.activeText : null}`}>{t('ACTIVATE')}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <span className={classes.errorText}>{examCenterActiveError}</span>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={12} md={12} className={classes.dropDownSelect}>
            <PerformantDropdown
              label={t('REGION')}
              id="region"
              minWidth="50%"
              customSelectClass={classes.select}
              name="region"
              shrink
              type="text"
              required
              disabled={false}
              value={formik.values.region}
              onBlur={formik.handleBlur}
              error={getErrorText('region')}
              handleChange={formik.handleChange}
              labelId="region"
              options={activeRegions}
            />
            <span className={classes.errorText}>{getErrorText('region')}</span>
          </Grid>
          <Grid item xs={12} className={classes.dropDownSelect}>
            <div className={classes.coordinatorHeading}>
              Find Coordinators
              <em>*</em>
            </div>
            <MultiAutoComplete
              {... {
                setSelectedOptions,
                selectedOptions,
                t,
                isEdit,
                locationStore: { locationState },
              }}
              coordinatorError={coordinatorError}
              setCoordinatorError={setCoordinatorError}
              name="coOrdinator"
              id="coOrdinator"
              data={formik.values.coOrdinator}
              setPrimaryContactNumber={setPrimaryContactNumber}
            />
            <span className={classes.errorText}>{getErrorText('', coordinatorError)}</span>
          </Grid>

          <Grid item xs={12} lg={6} md={6} className={classes.phoneInput}>
            <TextField
              label={`${t('CONTACT_NUMBER')}*`}
              id="contactNumber"
              name="contactNumber"
              type="text"
              disabled
              value={primaryContactNumber}
              onBlur={() => { }}
              error={() => { }}
              onChange={() => {
                formik.setFieldValue('contactNumber', primaryContactNumber);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6} md={6} className={classes.dropDownSelect}>
            <PerformantDropdown
              label={t('DAY_OF_THE_CLASS')}
              id="classTiming"
              minWidth="50%"
              customSelectClass={classes.select}
              name="classTiming"
              shrink
              type="text"
              required
              disabled={false}
              value={formik.values.classTiming}
              onBlur={formik.handleBlur}
              error={getErrorText('classTiming')}
              handleChange={formik.handleChange}
              labelId="classTiming"
              options={getWeekDays()}
            />
            <span className={classes.errorText}>{getErrorText('classTiming')}</span>
          </Grid>
          <Grid item xs={12} lg={6} md={6} className={classes.timePicker}>
            <TimePickerAtom
              wrapperClassName={classes.datePicker}
              label={`${t('START_TIME')}*`}
              minWidth="100%"
              id="startTime"
              name="startTime"
              value={formik.values.startTime}
              inputFormat="HH:mm"
              mask="__:__"
              type="date"
              error={getErrorText('startTime')}
              onBlur={startTimeBlur}
              onChange={(e) => {
                setStartEndTimeError('');
                formik.setFieldValue('startTime', e);
                setStartTime('');
                setStartTime(e);

                if (moment(e).isValid() && formik.values.endTime !== '') {
                  const isValidEndTime = moment(formik.values.endTime).isAfter(moment(e));
                  if (!isValidEndTime) {
                    setStartEndTimeError(t('ENDTIME_GREATER_THAN_STARTTIME'));
                  }
                }
              }}
            />
            <span className={classes.errorText}>{getErrorText('startTime')}</span>
          </Grid>
          <Grid item xs={12} lg={6} md={6} className={classes.timePicker}>
            <TimePickerAtom
              wrapperClassName={classes.datePicker}
              label={`${t('END_TIME')}*`}
              minWidth="100%"
              id="endTime"
              name="endTime"
              value={formik.values.endTime}
              views={['hours', 'minutes']}
              inputFormat="HH:mm"
              mask="__:__"
              type="date"
              error={getErrorText('endTime')}
              onBlur={endTimeBlur}
              onChange={(e) => {
                setStartEndTimeError('');
                formik.setFieldValue('endTime', e);
                setEndTime(e);
                if (moment(e).isValid()) {
                  const isValidEndTime = moment(e).isAfter(moment(formik.values.startTime));

                  if (!isValidEndTime) {
                    setStartEndTimeError(t('ENDTIME_GREATER_THAN_STARTTIME'));
                  }
                } else {
                  setStartEndTimeError(t('INVALID_END_TIME'));
                }
              }}
            />
            <span className={classes.errorText}>{getErrorText('endTime')}</span>
            <span className={classes.errorText}>{startEndTimeError}</span>
          </Grid>
          <Grid item xs={12} className={classes.activeCourseDropdown}>
            <PerfromantMultiValueDropdown
              label={t('ACTIVE_COURSES')}
              id="activeCourses"
              minWidth="50%"
              customSelectClass={classes.select}
              name="activeCourses"
              shrink
              type="text"
              required
              disabled={false}
              value={formik.values.activeCourses}
              onBlur={formik.handleBlur}
              error={getErrorText('activeCourses')}
              onChange={formik.handleChange}
              labelId="activeCourses"
              options={activeCourses}
              showLabel
            />
            <span className={classes.errorText}>{getErrorText('activeCourses')}</span>
          </Grid>
          {isEdit && (
            <>
              <Grid item xs={12}>
                <PerformantTextField
                  label={t('CHANGE_LOG')}
                  id="changeLog"
                  required
                  name="changeLog"
                  type="text"
                  disabled={false}
                  value={formik.values.changeLog}
                  onBlur={formik.handleBlur}
                  error={getErrorText('changeLog')}
                  onChange={formik.handleChange}
                  labelId={t('CHANGE_LOG')}
                  allowSpecialCharacter
                />
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <div
                  className={classes.logsIconSize}
                  onClick={() => viewLogs()}
                >
                  <img src={logsIcon} width="20" alt="logsIcon" />
                  <span>{t('VIEW_LOGS')}</span>
                </div>
              </Grid>
            </>
          )}

          <Grid container justifyContent="flex-end" item xs={12} lg={6} md={6}>
            <div>
              <ButtonAtom
                xs={3}
                name={t('CANCEL')}
                onClick={() => {
                  if (primaryContactNumber) {
                    setPrimaryContactNumber('');
                    setSelectedOptions([]);
                  }
                  setCoordinatorError(t(''));
                  setShowLocationDialog(!showLocationDialog);
                }}
                btntype={Buttons.SECONDARY}
                className={classes.inViewLogButton}
              />
            </div>
            <div>
              <ButtonAtom
                xs={3}
                name={t('SAVE')}
                onClick={() => formik.handleSubmit()}
                btntype={Buttons.PRIMARY}
                className={classes.inViewLogButton}
              />
            </div>
          </Grid>
        </Grid>
      </FormikProvider>
      {loading ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : null}
    </Box>

  );
}
export default AddEditLocation;
