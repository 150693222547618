import { ApiRoutes } from '../../constant';
import request from '../../utils/request';

// Homework Panel Services
// eslint-disable-next-line import/prefer-default-export
export const getQuizSchemaService = (body) => request.get(`${ApiRoutes.SAVE_QUIZ_SCHEMA}?courseId=${body.courseId}&academicYear=${body.academicYear}&quarter=${body.quarter}&quiz=${body.quiz}`);

export const saveQuizSchemaService = (body, update = false) => {
  const requestMethod = update ? request.put : request.post;
  return requestMethod(ApiRoutes.SAVE_QUIZ_SCHEMA, body);
};

export const updateQuizMarksService = (body) => {
  const requestMethod = request.put;
  return requestMethod(ApiRoutes.UPDATE_QUIZ_MARKS, body);
};
