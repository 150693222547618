const encryptedUserRoles = {
  SYSTEM_ADMIN: '#YWKA$Y&xW0&a6MjB52lx#bh6&T',
  SUPER_USER: 'pvEvt8r!VPQa75%S%abcd1239!2',
  CURRICULUM: 'pvEvt8r!VPQa75%S%YSW7x3u!Sc',
  REGION_COORDINATOR: 'mXdE6C%1%2E&Uou%ghb9I@40w1N',
  LOCATION_COORDINATOR: 'AqB5RXpW7N1o1y9xyWop!o%^N@r',
  STUDENT: '0C#3*ID5n4OZo2h!2i%oHWo5mb^',
  TEACHER: 'ttw8wEoqCpt*y81R&h1Ab7yxaGm',
};
export default encryptedUserRoles;
