import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {
  Surface,
  Cell,
  Symbols,
} from 'recharts';
import DonutChart from '../../../common/donut-chart';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (label) => {
  const {
    cx, cy, midAngle, innerRadius, outerRadius, payload,
  } = label;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fontWeight="bold" fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${payload.value}`}
    </text>
  );
};

const renderCusomizedLegend = ({ payload }, classes) => {
  payload.sort((a, b) => a.payload.level - b.payload.level);

  return (
    <div className={classes.customLegend}>
      {payload.map((entry, index) => {
        const { value, color } = entry;
        const style = {
        };
        return (
          <span
            key={index}
            className={classes.legendItem}
            style={style}
          >
            <Surface
              width={12}
              height={12}
              style={{ float: 'left', marginTop: '3px', marginRight: '3px' }}
              viewBox={{
                x: 0,
                y: 0,
                width: 12,
                height: 12,
              }}
            >
              <Symbols cx={5} cy={5} type="circle" size={70} fill={color} />
              <Symbols
                cx={5}
                cy={5}
                type="circle"
                size={25}
                fill={color}
              />
            </Surface>
            <span>{`  ${value}`}</span>
          </span>
        );
      })}
    </div>
  );
};

let defaultColors = [];

const renderCells = (chartData, classes) => {
  return chartData.map((cd, index) => {
    const color = defaultColors[index];

    return <Cell className={classes[`${cd.name.toLowerCase()}`]} key={`cell-${index}`} fill={color} />;
  });
};

const assignColor = (status) => {
  switch (status) {
    case 'Pass':
      defaultColors.push('#097E17');
      return;
    case 'Failed':
      defaultColors.push('#F04B00');
      return;
    case 'Pursuing':
      defaultColors.push('#BEE118');
      break;
    default:
  }
};

export default function StudentStatus({
  currentYear, year, teacherPanel,
}) {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  let data = {
    Pass: 0,
    Failed: 0,
    Pursuing: 0,
  };
  if (teacherPanel?.studentsByYearAndClassId.length) {
    data = teacherPanel?.studentsByYearAndClassId.reduce((obj, std) => {
      let { annualScore } = std;
      annualScore = parseFloat(annualScore);
      if (currentYear === year) {
      // eslint-disable-next-line
        for (const prop in std.marks) {
          if (typeof std.marks[prop].maximumMarks === 'undefined') {
            obj.Pursuing += 1;
            return obj;
          }
        }
      }
      if (annualScore <= 0 && (currentYear === year)) {
        obj.Pursuing += 1;
      } else if ((annualScore >= 0) && (annualScore < 65)) {
        obj.Failed += 1;
      } else {
        obj.Pass += 1;
      }

      return obj;
    }, {
      Pass: 0,
      Failed: 0,
      Pursuing: 0,
    });
  }

  if (currentYear !== year) {
    delete data.Pursuing;
  }

  defaultColors = [];

  data = Object.entries(data).map(([k, v]) => {
    if (v === 0) {
      return null;
    }
    assignColor(k);

    return { name: k, value: v };
  }).filter((value) => !!value);
  return (
    <Grid container item direction="column">
      <div className={`${classes.title} `}>
        {t('STUDENT_STATUS')}
      </div>
      <div className={classes.horizontalLine} />
      { data.length ? (
        <div className={classes.chartWrapper}>
          <DonutChart
            chartsData={data}
            renderCells={renderCells}
            outerRadius="100%"
            renderCusomizedLegend={renderCusomizedLegend}
            renderCustomizedLabel={renderCustomizedLabel}
          />
        </div>
      ) : (
        <Grid container item className={classes.noData} direction="column">
          <DoNotDisturbIcon />
          <Grid container item direction="column" justifyContent="center">
            <Typography variant="subtitle2" color="text.secondary">
              {t('NO_DATA')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
