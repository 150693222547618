import { colors } from '../../theme';

const dashboardStyle = (() => ({
  headerMargin: {
    marginTop: '0 !important',
  },
  headerViewingDiv: {
    position: 'relative',
    '& div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgb(0, 0, 0, .5)',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      height: '5.6vw',
      '& p': {
        color: colors.white,
        marginLeft: '0.5vw',
        minWidth: '1vw',
        maxWidth: '21vw',
        wordBreak: 'break-all',
        '& span': {
          color: colors.white,
          textDecoration: 'unset',
        },
      },
      '& button': {
        backgroundColor: colors.primary,
        color: '#fff',
        fontSize: '14px',
        margin: '0 10px',
        height: '2.7vw',
        padding: '0.8vw',
        minWidth: '8.5vw',
        fontFamily: 'inherit',
        borderRadius: '5px !important',
        '& svg': {
          padding: '0 5px',
          height: '19px',
        },
        '&:hover': {
          backgroundColor: colors.primary,
        },
      },
    },
  },
}));
export default dashboardStyle;
