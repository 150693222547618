import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input';

function Location(locations) {
  const options = locations?.map((location) => {
    const coOrd = (location?.location_coordinators || []).find((coordinator) => coordinator.isPrimary === true);
    return {
      name: location.name,
      locationCoordinator: coOrd?.user?.first_name ? `${coOrd?.user?.first_name} ${coOrd?.user?.last_name}` : '',
      city: location?.locationAddress?.city,
      address: location?.locationAddress?.address,
      locationAddressAptSuite: location?.locationAddress?.aptSuite,
      shippingAddress: location?.shippingAddress?.address,
      shippingAddressAptSuite: location?.shippingAddress?.aptSuite,
      isActive: location?.isActive,
      examCenter: location?.exam_center?.id,
      isActiveExamCenter: location?.examCenterStatus,
      classTiming: location?.classTiming,
      startTime: location?.startTime,
      endTime: location?.endTime,
      region: location?.region?.id,
      coordinator: location?.location_coordinators,
      contactNumber: coOrd?.user?.contact_number,
      activeCourses: location?.location_courses,
      id: location?.id,
      addressId: location?.locationAddress?.id,
      shippingAddressId: location?.shippingAddress?.id,
    };
  });
  return options;
}

function getEmptyLocation() {
  return {
    locationName: '',
    status: '',
    locationAddress: '',
    locationAddressAptSuite: '',
    shippingAddress: '',
    shippingAddressAptSuite: '',
    examCenter: '',
    region: '',
    coOrdinator: [],
    contactNumber: '',
    activeCourses: '',
    changeLog: '',
    day: '',
    startTime: '',
    endTime: '',
    isActive: false,
    isActiveExamCenter: false,
  };
}

function GetAllLocations(locations) {
  if (locations) {
    return Location(locations);
  }
}

function GetOnlyActiveLocations(locations) {
  if (locations) {
    let options = locations.filter((obj) => obj.isActive === true);
    options = Location(options);
    return options;
  }
}

function getWeekDays() {
  return [
    { id: 'Sunday', name: 'Sunday' },
    { id: 'Monday', name: 'Monday' },
    { id: 'Tuesday', name: 'Tuesday' },
    { id: 'Wednesday', name: 'Wednesday' },
    { id: 'Thursday', name: 'Thursday' },
    { id: 'Friday', name: 'Friday' },
    { id: 'Saturday', name: 'Saturday' },
  ];
}

function EditDataObject(
  formik,
  selectedRow,
  setLocationActive,
  setExamCenterActive,
  setPrimaryContactNumber,
  setLocationAddressId,
  setShippingAddressId,
  setActualActiveCourses,
) {
  formik.setTouched({}, false);
  formik.values.coOrdinator = [];
  formik.values.activeCourses = [];
  formik.values.contactNumber = '';
  formik.values.startTime = '';
  formik.values.endTime = '';
  formik.values.changeLog = '';
  if (selectedRow !== undefined) {
    const row = selectedRow?.[0];
    formik.values.locationName = row?.name;
    formik.values.locationAddress = row?.address;
    formik.values.locationAddressAptSuite = row?.locationAddressAptSuite;
    formik.values.shippingAddress = row?.shippingAddress;
    formik.values.shippingAddressAptSuite = row?.shippingAddressAptSuite;
    setLocationActive(row?.isActive);
    setExamCenterActive(row?.isActiveExamCenter);
    formik.values.classTiming = row?.classTiming;
    if (row?.startTime) {
      formik.values.startTime = moment(row?.startTime, 'HH:mm:ss');
    }
    if (row?.endTime) {
      formik.values.endTime = moment(row?.endTime, 'HH:mm:ss');
    }
    formik.values.examCenter = row?.examCenter;
    formik.values.region = row?.region;
    row?.coordinator?.forEach((obj) => {
      formik.values.coOrdinator.push({
        ...obj?.user,
        firstName: obj?.user?.first_name,
        lastName: obj?.user?.last_name,
        manabadiEmail: obj?.user?.manabadi_email,
        isPrimary: obj.isPrimary,
      });
    });
    row?.activeCourses?.forEach((obj) => {
      formik.values.activeCourses.push(obj?.courseId);
    });

    // eslint-disable-next-line no-unsafe-optional-chaining
    setPrimaryContactNumber('');
    if (row?.contactNumber && isValidPhoneNumber(row?.contactNumber?.toString())) {
      setPrimaryContactNumber(row?.contactNumber);
    }
    setLocationAddressId(row?.addressId);
    setShippingAddressId(row?.shippingAddressId);
    setActualActiveCourses(formik.values.activeCourses);
  }
}

function getActivecourses(courses, actualCourses) {
  const normalizedObj1 = Object.fromEntries(Object.entries(courses).sort(([k1], [k2]) => k1.localeCompare(k2)));
  const normalizedObj2 = Object.fromEntries(Object.entries(actualCourses).sort(([k1], [k2]) => k1.localeCompare(k2)));
  if (JSON.stringify(normalizedObj1) === JSON.stringify(normalizedObj2)) {
    return actualCourses;
  }
  return courses;
}

function getActiveCoordinators(coordinators) {
  return coordinators?.reverse();
}

function createPayload(
  values,
  selectedOptions,
  locationActive,
  examCenterActive,
  locationAddressInfo,
  shippingAddressInfo,
  actualActiveCourses,
) {
  const getCoordinators = () => selectedOptions?.map((cor) => ({
    userId: cor?.id,
    isPrimary: cor?.isPrimary,
  }));

  const payload = {
    location: {
      name: values?.locationName,
      isActive: !!locationActive,
      defaultCenter: false,
      examCenterStatus: !!examCenterActive,
      startTime: moment(values?.startTime).format('HH:mm:ss'),
      endTime: moment(values?.endTime).format('HH:mm:ss'),
      classTiming: values?.classTiming,
      examCenterId: values?.examCenter !== '' ? values?.examCenter : null,
      regionId: values?.region,
    },
    locationAddress: {
      address: values?.locationAddress,
      latitude: locationAddressInfo?.latitude,
      longitude: locationAddressInfo?.longitude,
      city: locationAddressInfo?.city,
      country: locationAddressInfo?.country,
      zipCode: locationAddressInfo?.zipCode,
      state: locationAddressInfo?.state,
    },
    shippingAddress: {
      address: values?.shippingAddress,
      latitude: shippingAddressInfo?.latitude,
      longitude: shippingAddressInfo?.longitude,
      city: shippingAddressInfo?.city,
      country: shippingAddressInfo?.country,
      zipCode: shippingAddressInfo?.zipCode,
      state: shippingAddressInfo?.state,
    },
    courseIds: getActivecourses(values?.activeCourses, actualActiveCourses),
    coordinators: getActiveCoordinators(getCoordinators()),
  };
  if (values?.locationAddressAptSuite !== '' && values?.locationAddressAptSuite !== null) {
    payload.locationAddress.aptSuite = values?.locationAddressAptSuite;
  }
  if (values?.shippingAddressAptSuite !== '' && values?.shippingAddressAptSuite !== null) {
    payload.shippingAddress.aptSuite = values?.shippingAddressAptSuite;
  }
  return payload;
}

export {
  GetOnlyActiveLocations,
  GetAllLocations,
  Location,
  getWeekDays,
  getEmptyLocation,
  EditDataObject,
  createPayload,
};
