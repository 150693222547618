import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TextEditor from './textEditor';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import useStyles from '../../../../custom-hooks/useStyles';
import style from './styles';
import commonStyle from '../../../../utils/commonClasses';

import useAdmin from '../../../../custom-hooks/useAdmin';

import { UpdateTermsConditionsSettingsService } from '../../../../store/services/auth';
import Loader from '../../../../components/atoms/loader';
import { setTermsAndConditionSettingDetails } from '../../../../store/actions/getAdmin';

function SettingTermsAndConditions() {
  const adminData = useAdmin();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({
    readonly: true,
    toolbar: true,
  });

  const { termsSettingDetails } = adminData;
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
  });
  const formik = useFormik({
    initialValues: {
      message: '',
      id: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const payload = {
        id: values.id,
        data: values.message,
      };
      UpdateTermsConditionsSettingsService(payload)
        .then(() => {
          dispatch(setTermsAndConditionSettingDetails());
          setLoading(false);
          setConfig({
            readonly: true,
            toolbar: true,
          });
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue('message', termsSettingDetails?.message);
    formik.setFieldValue('id', termsSettingDetails?.id);
  }, [termsSettingDetails]);
  useEffect(() => {

  }, [formik.values.message]);

  const handleCancel = () => {
    formik.setFieldValue('message', termsSettingDetails?.message);
    dispatch(setTermsAndConditionSettingDetails());
    setConfig({
      readonly: true,
      toolbar: true,
    });
  };
  const classes = useStyles(style)();
  const registerClasses = useStyles(commonStyle)();

  const getError = (key) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={`${classes.errorText} ${registerClasses.errorText}`}>
      {formik.errors[key]}
    </span>
  ) : null);
  return (
    <FormikProvider value={formik}>
      <Grid container className={classes.container}>
        <Grid className={classes.topBar}>
          <Grid className={classes.heading}>
            <span className={classes.heading}>
              {t('TERMS_AND_CONDITIONS_SETTINGS')}
            </span>
          </Grid>
          {config.readonly
          && (
          <ButtonAtom
            name={t('EDIT')}
            btntype={Buttons.PRIMARY}
            onClick={() => setConfig({
              readonly: false,
              toolbar: true,
            })}
            className={classes.editBtn}
          />
          )}
        </Grid>
        <Grid className={classes.emailEditor}>
          <TextEditor
            name="message"
            id="message"
            setFieldValue={(val) => formik.setFieldValue('message', val)}
            value={formik.values.message}
            {...{ termsSettingDetails, config }}
            error={getError('message')}
          />

        </Grid>
        {!config.readonly
        && (
        <Grid
          container
          item
          justifyContent="flex-end"
          className={classes.actionButtons}
        >
          <ButtonAtom
            name={t('CANCEL')}
            btntype={Buttons.SECONDARY}
            onClick={handleCancel}
          />
          <ButtonAtom
            name={t('UPDATE')}
            onClick={formik.handleSubmit}
            btntype={Buttons.PRIMARY}
          />
        </Grid>
        )}
      </Grid>
      {loading ? (
        <Grid>
          <Loader message={t('UPDATING')} />
        </Grid>
      )
        : null}
    </FormikProvider>
  );
}

export default SettingTermsAndConditions;
