import React, {
  useState, useRef, useEffect,
} from 'react';
import {
  Grid, Paper, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from '../../components/atoms/loader';
import EmailIconText from '../../assets/images/email.png';
import useStyles from '../../custom-hooks/useStyles';
import styles from './style';
import ButtonAtom from '../../components/atoms/button';
import { Buttons } from '../../constant';
import TextFieldAtom from '../../components/atoms/textfield';
import PhoneInputForgotPassword from '../../components/atoms/phoneinputforgotpassword';
import commonStyles from '../../utils/commonClasses';
import logo from '../../assets/images/logo.png';
import forgotPasword from '../../assets/images/Boy.png';
import girlBg from '../../assets/images/girlBg.png';
import DialogAtom from '../../components/atoms/dialog';
import { postForgotPassword, checkStudensByEmail, checkStudensByPhone } from '../../store/actions/auth';
import emailIcon from '../../assets/images/email-icon.png';
import callIcon from '../../assets/images/call-icon.png';

function DialogContent({ data, header, classes }) {
  return (
    <Grid item xs={10} className={classes.content}>
      <h1 className={classes.dialogHeader}>{header}</h1>
      {data}
    </Grid>
  );
}
function DialogFooter({
  classes, handleDialog, t,
}) {
  const commonClasses1 = useStyles(commonStyles)();

  return (
    <Grid container className={classes.content} justifyContent="flex-end">
      <ButtonAtom
        name={t('OK')}
        onClick={handleDialog}
        btntype={Buttons.PRIMARY}
        className={commonClasses1.activeButton}
      />
    </Grid>
  );
}
export default function ForgotPassword() {
  const [showLoader, setShowLoader] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isStudentDialogOpen, setStudentDialogOpen] = useState(false);
  const [showMessage] = useState('');
  const [message, setMessage] = useState(null);
  const [dialogTitle, showDialogTitle] = useState(null);
  const [dialogHeader, showDialogHeader] = useState('');
  const [apiStatus, setApiStatus] = useState(false);
  const [students, setStudents] = useState([]);
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles(styles)();
  const commonClasses = useStyles(commonStyles)();
  const navigate = useNavigate();

  const emailValidationSchema = Yup.object({
    email: Yup.string(t('PROVIDE_VALID_EMAIL'))
      .email(t('PROVIDE_VALID_EMAIL'))
      .required(t('PROVIDE_VALID_EMAIL')),
  });

  const phoneValidationSchema = Yup.object({
    contact: Yup.string(t('CONTACTNO_REQUIRED'))
      .required(t('CONTACTNO_REQUIRED'))
      .test('isValidPhoneNumber', t('INVALID_PHONE'), (val) => (val ? isValidPhoneNumber(val) : false)),
  });

  function getMaskedEmail(msg) {
    const email = msg.split(':')[1];
    const output = email.replace(/(..)(.{1,3})(?=.*@)/g, (_, a, b) => a + '*'.repeat(b.length));
    return output;
  }
  async function saveData(values, setSubmitting) {
    dispatch(postForgotPassword(values)).then((res) => {
      const data = res?.sentEmailResponse;
      setApiStatus(data.status);
      if (!data.status) {
        setShowLoader(false);
        setDialogOpen(true);
        setMessage(data?.message);
        showDialogTitle(t('PASSWORD_RECOVERY_FAILED'));
      } else {
        if (setSubmitting) setSubmitting(true);
        setShowLoader(false);
        setDialogOpen(true);
        showDialogTitle(t('PASSWORD_RECOVERY'));
        showDialogHeader(t('RECOVER_PASSWORD_EMAIL'));
        setMessage(t('PASSWORD_RESET_LINK', { email: getMaskedEmail(data.message) }));
      }
    });
  }

  async function checkEmail(values) {
    setShowLoader(true);
    const { email } = values;
    const emailDomail = email.split('@')[1];
    if (emailDomail === 'manabadi.siliconandhra.org' || emailDomail === 'test.mb.siliconandhra.org') {
      saveData({ email });
    } else {
      dispatch(checkStudensByEmail(email)).then((res) => {
        const data = res?.students;
        if (data?.length >= 2) {
          setShowLoader(false);
          setStudents(data);
          setValue(data[0].studentInfo.manabadiEmail);
          setStudentDialogOpen(true);
          showDialogTitle(t('STUDENTS'));
        } else if (data?.length === 1) {
          saveData({ email: data[0].studentInfo.manabadiEmail });
        } else {
          setShowLoader(false);
          setDialogOpen(true);
          setMessage(t('NO_STUDENTS'));
          showDialogTitle(t('PASSWORD_RECOVERY_FAILED'));
        }
      });
    }
  }

  async function checkPhone(values) {
    setShowLoader(true);
    const { contact } = values;
    const phone = contact.substring(2);
    dispatch(checkStudensByPhone(phone)).then((res) => {
      const data = res?.students;
      if (data?.length >= 2) {
        setShowLoader(false);
        setStudents(data);
        setValue(data[0].studentInfo.manabadiEmail);
        setStudentDialogOpen(true);
        showDialogTitle(t('STUDENTS'));
      } else if (data?.length === 1) {
        saveData({ email: data[0].studentInfo.manabadiEmail });
      } else {
        setShowLoader(false);
        setDialogOpen(true);
        setMessage(t('NO_STUDENTS'));
        showDialogTitle(t('PASSWORD_RECOVERY_FAILED'));
      }
    });
  }

  const emailFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      setShowLoader(true);
      checkEmail(values);
    },
  });

  // Lógica para el formulario de teléfono
  const phoneFormik = useFormik({
    initialValues: {
      contact: '',
    },
    validationSchema: phoneValidationSchema,
    onSubmit: (values) => {
      setShowLoader(true);
      checkPhone(values);
    },
  });
  const [tabValue, setTabValue] = React.useState('email');

  const handleTabChange = (event, newValue) => {
    if (newValue === 'email') {
      phoneFormik.resetForm();
    } else {
      emailFormik.resetForm();
    }
    setTabValue(newValue);
  };

  const handleCancel = () => {
    phoneFormik.resetForm();
    emailFormik.resetForm();
    setDialogOpen(true);
    navigate('/');
  };

  const emailContent = () => {
    return (
      <form onSubmit={emailFormik.handleSubmit}>
        <Grid item xs={12} className={classes.EnterStudentText}>
          {t('PLEASE_ENTER_ID')}
        </Grid>
        <Grid item xs={12} lg={12} className={classes.emailNew}>
          <TextFieldAtom
            id="email"
            name="email"
            type="email"
            value={emailFormik?.values?.email}
            className={classes.emailnputNew}
            onBlur={emailFormik.handleBlur}
            onChange={emailFormik.handleChange}
            placeholder="Enter here"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.textBox}>
                  <img src={EmailIconText} alt="" className={`${classes.textBoxIcon} ${classes.imgSize}`} />
                  <b>Email &nbsp;- </b>
                </InputAdornment>
              ),
            }}
          />
          {emailFormik.touched.email && emailFormik.errors.email ? (
            <span className={classes.errorText}>{emailFormik.errors.email}</span>
          ) : null}
        </Grid>
        <Grid container justifyContent="center">
          <ButtonAtom
            name={t('CANCEL')}
            className={classes.secButtonNew}
            onClick={handleCancel}
          />
          <ButtonAtom
            name={t('RECOVER')}
            className={classes.activeButtonNew}
            onClick={emailFormik.handleSubmit}
          />
        </Grid>
      </form>
    );
  };

  const phoneInput = (
    label,
    id,
    handleChange,
    onCountryChange,
    defaultCountry,
    phoneValue,
    error,
    handleBlur,
    setPhoneValue = () => { },
    required = true,
  ) => {
    const inputRef = useRef();

    useEffect(() => {
      if (!phoneValue && inputRef?.current?.value) {
        setPhoneValue?.(id, inputRef?.current?.value);
      }
    }, [inputRef?.current?.value]);

    return (
      <PhoneInputForgotPassword
        customClass="customClass1"
        label={label}
        inputRef={inputRef}
        inputProps={{
          style: {
            WebkitBoxShadow: '0 0 0 1000px white inset',
          },
        }}
        id={id}
        onBlur={handleBlur}
        required={required}
        name={id}
        value={phoneValue}
        shrink
        showLabel
        onCountryChange={onCountryChange}
        defaultCountry={defaultCountry}
        error={error && Object.keys(error).length}
        onChange={(e) => {
          handleChange(e);
        }}
      />
    );
  };

  const handleParentOneCellphone = (phone) => {
    phoneFormik.setFieldValue('contact', phone || '');
  };

  const [parentOneDefaultCountry, setParentOneDefaultCountry] = useState('US');

  const handleParentOneDefaultCountry = (countryValue) => {
    setParentOneDefaultCountry(countryValue);
  };

  const getErrorText = (key, errorText) => {
    if (phoneFormik.touched[key] && phoneFormik.errors[key]) {
      return (
        <span data-testid={key} className={classes.errorText}>
          {phoneFormik.errors[key]}
        </span>
      );
    } if (errorText) {
      return (
        <span className={classes.errorText}>
          {errorText}
        </span>
      );
    }
    return null;
  };

  const phoneContent = () => {
    return (
      <form onSubmit={phoneFormik.handleSubmit}>
        <Grid item xs={12} className={classes.EnterStudentText}>
          {t('PLEASE_ENTER_PHONE')}
        </Grid>
        <Grid item xs={12} lg={12} className={classes.emailNew}>
          {phoneInput(
            `${t('CONTACT_NO')} *`,
            'contact',
            handleParentOneCellphone,
            handleParentOneDefaultCountry,
            parentOneDefaultCountry,
            phoneFormik?.values?.contact,
            getErrorText('contact')
            || (phoneFormik?.touched?.contact
              && phoneFormik.errors.contact
              && !isValidPhoneNumber(phoneFormik?.values?.contact)
              && getErrorText(null, t('INVALID_PHONE'))),
            phoneFormik.handleBlur,
          )}
          {phoneFormik?.touched?.contact && phoneFormik.errors.contact ? (
            <span style={{ marginLeft: '-1vw' }} className={classes.errorText}>
              {phoneFormik.errors.contact}
            </span>
          ) : null}
        </Grid>
        <Grid container justifyContent="center">
          <ButtonAtom
            name={t('CANCEL')}
            className={classes.secButtonNew}
            onClick={handleCancel}
          />
          <ButtonAtom
            name={t('RECOVER')}
            className={classes.activeButtonNew}
            onClick={phoneFormik.handleSubmit}
          />
        </Grid>
      </form>
    );
  };
  const renderForgotPassword = () => (
    <Grid container className={classes.passwordGrid}>
      <Grid
        item
        xs={12}
        lg={12}
        className={classes.keyImage}
      />
      <Grid container justifyContent="flex-end" className={classes.cardRightOuter}>
        <Grid item xs={12} className={classes.girlBG}>
          <img
            src={girlBg}
            width={642}
            alt="ManaBadi SiliconAndhra Logo"
            className={classes.girlBgImg}
          />
        </Grid>
        <Grid item xs={12} className={classes.cardRight}>
          <Grid container className={classes.topContainer}>
            <Grid item xs={12} lg={12} className={classes.forgotYourPassword}>
              <h1>
                {t('FORGOT_YOUR_PASSWORD')}
              </h1>
            </Grid>
          </Grid>
          <Grid container className={classes.cardRightAll}>
            <Box className={classes.tabContainer} sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Email" value="email" />
                    <Tab label="Phone Number" value="phone" />
                  </TabList>
                </Box>
                <TabPanel className={classes.tabPanel} value="email">{emailContent()}</TabPanel>
                <TabPanel className={classes.tabPanel} value="phone">{phoneContent()}</TabPanel>
              </TabContext>
              <Grid container className={classes.additionalHelp}>
                <Grid lg={2} />
                <Grid item xs={12} lg={4}>
                  <Box display="flex" className={classes.helpText}>
                    {t('IF_ADDITIONAL_HELP')}
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box display="flex" direction="row" alignItems="center" className={classes.contactUsMargin}>
                    <img alt="" src={callIcon} className={classes.imgSize} />
                    <Typography className={classes.contactUsDes}>
                      <a className={commonClasses.contactUsLinks} href="tel:+18446262334">1 (844) 626-BADI (2234)</a>
                    </Typography>
                  </Box>

                  <Box display="flex" direction="row" alignItems="center" className={classes.contactUsMargin}>
                    <img alt="" src={emailIcon} className={classes.imgSize} />
                    <Typography className={classes.contactUsDes}>
                      <a className={commonClasses.contactUsLinks} href="mailto:support@manabadi.siliconandhra.org">support@manabadi.siliconandhra.org</a>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const studentDialog = () => {
    return (
      <Grid>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">Select the student that you want update the password</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            {students.map((test) => (
              <FormControlLabel value={`${test.studentInfo.manabadiEmail}`} control={<Radio />} label={`${test.studentInfo.firstName} ${test.studentInfo.lastName}`} />
            ))}
          </RadioGroup>
        </FormControl>
        <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
          <ButtonAtom
            className={classes.popupBtn}
            name={t('CANCEL')}
            onClick={() => {
              phoneFormik.setValues({
                contact: '',
              });
              emailFormik.setValues({
                email: '',
              });
              setStudentDialogOpen(false);
            }}
            btntype={Buttons.SECONDARY}
          />
          <ButtonAtom
            className={classes.popupBtn}
            name={t('OK')}
            onClick={() => {
              setShowLoader(true);
              checkEmail({ email: value });
            }}
            btntype={Buttons.PRIMARY}
          />
        </Grid>
      </Grid>
    );
  };
  const handleDialog = () => {
    setDialogOpen(false);
    if (apiStatus) {
      navigate('/');
    }
  };
  return (
    <Paper className={classes.root}>
      <Grid item alignItems="center" className={classes.imgGrid}>
        <img
          src={logo}
          width={441}
          alt="ManaBadi SiliconAndhra Logo"
          className={classes.headerLogo}
          onClick={() => navigate('/')}
        />
      </Grid>
      <Grid container className={classes.headerContainer}>
        <Grid item xs={2} className={classes.imageCard}>
          <img
            component="img"
            width="100%"
            src={forgotPasword}
            alt="forgot password"
            className={classes.passwordImg}
          />
        </Grid>

        <Grid item xs={10} className={classes.passwordCard}>
          {renderForgotPassword()}
        </Grid>

      </Grid>

      {showLoader && (
        <Grid>
          <Loader
            message={showMessage}
            dialogTitle={showDialogTitle}
            dialogHeader={showDialogHeader}
          />
        </Grid>
      )}
      <DialogAtom
        customClass={classes.dialogAtom}
        isOpen={isDialogOpen}
        dialogHeading={dialogTitle}
        secHandle={() => setDialogOpen(false)}
        content={<DialogContent data={message} header={dialogHeader} classes={classes} t={t} />}
        footer={(
          <DialogFooter
            classes={classes}
            handleDialog={handleDialog}
            navigate={navigate}
            t={t}
          />
        )}
      />
      <DialogAtom
        customClass={classes.StudentDialogAtom}
        isOpen={isStudentDialogOpen}
        dialogHeading={dialogTitle}
        secHandle={() => setStudentDialogOpen(false)}
        content={studentDialog()}
      />
    </Paper>
  );
}
