import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardMedia from '@mui/material/CardMedia';
import {
  Divider, Grid, Typography, Box, Link,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import ListIcon from '@mui/icons-material/List';
import GridViewIcon from '@mui/icons-material/GridView';
import { useTranslation } from 'react-i18next';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import moment from 'moment';

import useStyles from '../../../custom-hooks/useStyles';
import { Loader } from '../../../components/atoms';
import style from './style';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import DialogAtom from '../../../components/atoms/dialog';

import MapPinIcon from '../../../assets/svg/mapPin';
import GmailIcon from '../../../assets/images/gmailIcon.png';
import { newDOB, getDistanceBetweenTwoPoint } from '../../../utils/methods';
import { setLocalStorage } from '../../../utils/localStorageMethod';
import { getStudentResourceCourse } from '../../../store/actions/getResources';
import Dropdown from '../../../components/atoms/dropdown';
import GridView from '../resources/grid-view';
import ListView from '../resources/list-view';
import { getStudentEnrollmentHistory } from '../../../store/actions/getStudent';
import { GetActivatedFiles } from '../../admin-user/resources/helper';
import useDataTableParams from '../../../custom-hooks/useDataTableParams';

function displayValue(key, value, classes) {
  return (
    <Grid container spacing="2" className={classes.container}>
      <Grid item xs={5} className={classes.label}>
        {key}
      </Grid>
      <Grid item xs={6} className={classes.value}>
        :
        &nbsp;
        &nbsp;
        {value}
      </Grid>
    </Grid>
  );
}
function displayButton(name, onClick, classes) {
  return (
    <ButtonAtom
      btntype={Buttons.PRIMARY}
      className={classes.actionBtn}
      name={name}
      onClick={onClick}
    />
  );
}

function LocationDialogContent({
  classes, studentDetials, addressData,
}) {
  const { t } = useTranslation('translation');
  const getLocationDistance = () => {
    const distance = getDistanceBetweenTwoPoint(
      { lat: addressData?.latitude, lng: addressData?.longitude },
      {
        lat: studentDetials?.enrolled_courses?.[0]?.location?.locationAddress?.latitude,
        lng: studentDetials?.enrolled_courses?.[0]?.location?.locationAddress?.longitude,
      },
    );
    const miles = distance ? distance / 1609.34 : 0;
    return miles.toFixed(2);
  };
  const getFormatTime = (time) => moment(time, 'hh:mm a').format('hh:mm a');

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={11.5}>
          <Grid container className={classes.locationInfo} direction="row" spacing={2}>
            <Grid item xs={9} className={classes.locationInfoKeys}>
              <MapPinIcon />
              <b>{studentDetials?.enrolled_courses?.[0]?.location?.name}</b>
            </Grid>
            <Grid item xs={3} style={{ color: '#979797' }} textAlign="right">
              <b>
                {getLocationDistance()}
                {' '}
                miles
              </b>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.locationInfoDetail}>
            <div>{studentDetials?.enrolled_courses?.[0]?.location?.locationAddress?.address}</div>
            <Grid item className={classes.locationInfoKeys}>
              <AccessTimeIcon />
              <b>Class Timing:  </b>
              &nbsp;
              {studentDetials?.enrolled_courses?.[0]?.location?.classTiming}
              {' '}
              {getFormatTime(studentDetials?.enrolled_courses?.[0]?.location?.startTime)}
              {' '}
              -
              {' '}
              {getFormatTime(studentDetials?.enrolled_courses?.[0]?.location?.endTime)}
            </Grid>
          </Grid>
        </Grid>

        <div className={classes.footerBottom} />
        <Grid item xs={12} lg={6}>
          <b>
            {t('TEACHER')}
            :
          </b>
          <br />
          <div>
            {studentDetials?.enrolled_courses?.[0]?.google_class?.class_teachers?.[0]?.user?.firstName}
            {' '}
            {studentDetials?.enrolled_courses?.[0]?.google_class?.class_teachers?.[0]?.user?.lastName}
            {' '}
            <br />
            {studentDetials?.enrolled_courses?.[0]?.google_class?.class_teachers?.[0]?.user?.contactNumber}
            <br />
            {studentDetials?.enrolled_courses?.[0]?.google_class?.class_teachers?.[0]?.user?.manabadiEmail}
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <b>
            {t('COORDINATOR')}
            :
          </b>
          <br />
          <div>
            {studentDetials?.enrolled_courses?.[0]?.location?.location_coordinators[0].user?.firstName}
            {' '}
            {studentDetials?.enrolled_courses?.[0]?.location?.location_coordinators[0].user?.middleName}
            {' '}
            {studentDetials?.enrolled_courses?.[0]?.location?.location_coordinators[0].user?.lastName}
            <br />
            {studentDetials?.enrolled_courses?.[0]?.location?.location_coordinators[0].user?.contactNum}
            <br />
            {studentDetials?.enrolled_courses?.[0]?.location?.location_coordinators[0].user?.manabadiEm}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
const getGmailLink = (email, isPassive) => `https://accounts.google.com/ServiceLogin?service=mail&passive=${isPassive}&Email=${email}&continue=https://mail.google.com/mail/u/${email}`;
const getGoogleClassroom = (email, isPassive) => `https://accounts.google.com/ServiceLogin?service=mail&passive=${isPassive}&Email=${email}&continue=https://classroom.google.com`;
function GmailDialogContent({
  classes, setIsGmailDialogOpen, studentDetials,
}) {
  const { t } = useTranslation('translation');
  const myGmail = getGmailLink(studentDetials?.studentInfo?.manabadiEmail, studentDetials?.primaryStudent);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.gmailInfo} direction="row">
          <img src={GmailIcon} alt="" style={{ cursor: 'pointer', textAlign: 'center' }} />
          <h3>{t('LOGIN_TO_GMAIL')}</h3>
          <Grid container>
            <Grid item xs={6}>
              <ButtonAtom
                btntype={Buttons.SECONDARY}
                className={classes.gmailBtn}
                name={t('CANCEL')}
                onClick={() => setIsGmailDialogOpen(false)}
              />
            </Grid>
            <Grid item xs={6}>
              <Link href={myGmail} target="_blank">
                <ButtonAtom
                  btntype={Buttons.PRIMARY}
                  className={classes.gmailBtn}
                  name={t('CONTINUE')}
                  onClick={() => setIsGmailDialogOpen(false)}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
function GoogleClassroomDialogContent({
  classes, isGoogleClassroomDialogOpen, studentDetials,
}) {
  const { t } = useTranslation('translation');
  const googleClassRoom = getGoogleClassroom(studentDetials?.studentInfo?.manabadiEmail, studentDetials?.primaryStudent);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.googleClassroomInfo} direction="row">
          <h3>{t('GOOGLE_CLASSROOM')}</h3>
          <Grid container>
            <Grid item xs={6}>
              <ButtonAtom
                btntype={Buttons.SECONDARY}
                className={classes.gmailBtn}
                name={t('CANCEL')}
                onClick={() => isGoogleClassroomDialogOpen(false)}
              />
            </Grid>
            <Grid item xs={6}>
              <Link href={googleClassRoom} target="_blank">
                <ButtonAtom
                  btntype={Buttons.PRIMARY}
                  className={classes.gmailBtn}
                  name={t('CONTINUE')}
                  onClick={() => isGoogleClassroomDialogOpen(false)}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const openGoogleClassroom = (studentDetials) => {
  const myClassRoomLink = getGoogleClassroom(studentDetials?.studentInfo?.manabadiEmail, studentDetials?.primaryStudent);
  window.open(myClassRoomLink, '_blank');
};

export default function StudentCard({
  studentDetials, addressData,
  isActive,
  loading,
  setLoading,
  selectedYear,
  allStudentDetails,
}) {
  const {
    nextPage,
  } = useDataTableParams();
  const { t } = useTranslation();
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
  const [isGmailDialogOpen, setIsGmailDialogOpen] = useState(false);
  const [isResourcesDialogOpen, setIsResourcesDialogOpen] = useState(false);
  const [isGoogleClassroomDialogOpen, setIsGoogleClassroomDialogOpen] = useState(false);
  const classes = useStyles(style)();
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getAdmin);
  const reduxStudentStore = useSelector((state) => state?.getStudent);
  const assignedCourses = reduxStudentStore?.courses;
  const allStudentCourseDetails = reduxStudentStore?.studentCourseDetails;
  const reduxStudentLocationStore = useSelector((state) => state?.getLocationCoordinator);
  const getStudentResourceInFolderResponse = reduxStore?.studentResourcesByFolder;
  const [isSelectedvalue, setSelectedValue] = useState('');
  const [selectedResource, setSelectedResource] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [studentResource, setStudentResource] = useState([]);
  const [resources, setResources] = useState([]);
  const [view, setView] = useState('gridView');
  const [isFetching, setIsFetching] = useState(false);
  const [pagenum, setPagenum] = useState(1);
  const [folderName, setFolderName] = useState([]);
  // eslint-disable-next-line no-nested-ternary
  const cardDetails = Object.keys(allStudentCourseDetails).length ? allStudentCourseDetails[studentDetials.id] ? allStudentCourseDetails[studentDetials.id] : {} : {};

  useEffect(() => {
    if (studentDetials?.enrolled_courses?.courseId) {
      setSelectedValue(studentDetials?.enrolled_courses?.courseId);
    }
  }, [studentDetials?.enrolled_courses?.courseId]);

  useEffect(() => {
    setStudentResource(reduxStudentLocationStore?.studentResourceWRT);
  }, [reduxStudentLocationStore?.studentResourceWRT]);

  useEffect(() => {
    setStudentResource(getStudentResourceInFolderResponse.data);
  }, [getStudentResourceInFolderResponse]);

  useEffect(() => {
    setResources(new GetActivatedFiles(studentResource));
  }, [studentResource]);

  useEffect(() => {
    const payload = {
      studentId: cardDetails?.id || studentDetials?.id,
      courseId: cardDetails?.enrolled_courses?.[0]?.courseId || studentDetials?.enrolled_courses?.courseId,
      academicYear: cardDetails?.enrolled_courses?.[0]?.academicYear || studentDetials?.enrolled_courses?.academicYear,
    };
    if (cardDetails?.id) {
      setSelectedValue(cardDetails?.enrolled_courses?.[0].courseId);
      allStudentDetails.forEach((obj) => {
        if (obj.id === cardDetails?.id) {
          const id = cardDetails?.id;
          obj[id] = cardDetails;
        }
      });
    }

    if (typeof selectedYear === 'string' && selectedYear && cardDetails?.id !== undefined) {
      dispatch(getStudentEnrollmentHistory(payload));
    }
  }, [cardDetails]);

  const getBackToMainResource = () => {
    setIsFetching(true);
    setLoading(true);
    setFolderName([]);
    if (isSelectedvalue !== '' && isSelectedvalue !== undefined) {
      const payload = {
        courseId: isSelectedvalue,
      };
      setSelectedResource([]);
      dispatch(getStudentResourceCourse(1, 100, nextPage, payload, setIsFetching, setLoading));
    }
  };

  const renderResourcesDialogContent = () => (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} className={classes.resourePopup}>
        <Grid item xs={12} className={classes.driveInfo} direction="row">
          <Grid container>
            <Grid item xs={2} className={classes.courseDropdown}>
              <Dropdown
                minWidth="100%"
                id="studentCourse"
                className={classes.helpSite}
                variant="standard"
                options={assignedCourses}
                value={isSelectedvalue}
                customClass={classes.dropdown}
                changeCss
                customFormControlCss={{ width: '100%' }}
                labelId="studentCourse"
                handleChange={(e) => {
                  setSelectedResource([]);
                  setFolderName([]);
                  setSelectedValue(e.target.value);
                }}
                icon={<HomeIcon onClick={getBackToMainResource} />}
                disabled="true"
              />
            </Grid>
            <Grid item xs={6} textAlign="left">
              {folderName.map((name) => {
                return (
                  <div style={{
                    display: 'flex',
                    fontSize: '0.9vw',
                    margin: '0.5vw',
                    float: 'left',
                  }}
                  >
                    <div className={classes.slash}>/</div>
                    <FolderIcon className={classes.folderIcon} />
                    <div className={classes.folderName}>{name?.resourceName}</div>
                  </div>
                );
              })}
            </Grid>
            <Grid item xs={4} textAlign="right">
              {view === 'gridView'
                ? (
                  <div style={{ margin: '0 0.8vw 1.2vw', cursor: 'pointer', display: 'inline-block' }} className={classes.gridIcon} onClick={() => setView('listView')}><ListIcon /></div>
                )
                : (
                  <div style={{ margin: '0 0.8vw 1.2vw', cursor: 'pointer', display: 'inline-block' }} className={classes.gridIcon} onClick={() => setView('gridView')}><GridViewIcon /></div>
                )}
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '1rem' }} />
          <Grid container className={classes.resourceContainer} sx={{ padding: '0 !important' }}>
            {view === 'listView'
              ? (
                <Grid container className={classes.resourcesListView}>
                  {setLocalStorage('myResourceView', 'listView')}
                  <ListView
                    tableDataResources={resources}
                    selectedResource={setSelectedResource}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    setLoading={setLoading}
                    loading={loading}
                    pageNext={nextPage}
                    pagenum={pagenum}
                    setPagenum={setPagenum}
                    courseSelectedvalue={isSelectedvalue}
                    folderName={folderName}
                    setFolderName={setFolderName}
                  />
                </Grid>
              )
              : (
                <Grid container className={classes.resourcesGridView}>
                  {setLocalStorage('myResourceView', 'gridView')}
                  <GridView
                    tableDataResources={resources}
                    selectedResource={setSelectedResource}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    setLoading={setLoading}
                    loading={loading}
                    pageNext={nextPage}
                    pagenum={pagenum}
                    setPagenum={setPagenum}
                    courseSelectedvalue={isSelectedvalue}
                    folderName={folderName}
                    setFolderName={setFolderName}
                  />
                </Grid>
              )}
          </Grid>
          {(loading && resources.length === 0) ? (
            <Grid>
              <Loader message={t('LOADING')} />
            </Grid>
          ) : (!loading && resources.length === 0) && (
            <Grid container className={classes.noData}>
              <Grid item xs={12}>
                <DoNotDisturbIcon />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('NO_Files_DATA')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );

  const handleResourcesDialog = () => {
    setIsFetching(true);
    setLoading(true);
    if (isSelectedvalue !== '' && isSelectedvalue !== undefined) {
      const payload = {
        courseId: isSelectedvalue,
        parentKey: selectedResource.selfKey,
      };
      setSelectedResource([]);
      dispatch(getStudentResourceCourse(1, 100, nextPage, payload, setIsFetching, setLoading));
    }
    setIsResourcesDialogOpen(true);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container className={classes.imgContainer} spacing={2}>
          <Grid item xs={12} sm={4}>
            <CardMedia
              className={classes.profileImg}
              component="img"
              src={`${studentDetials?.studentInfo?.profilePhoto}?random=${Date.now()}`}
              alt="profile-img"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography gutterBottom variant="h5" component="div" className={classes.studentName}>
              {`${studentDetials?.studentInfo?.firstName} ${studentDetials?.studentInfo?.middleName || ''} ${studentDetials?.studentInfo?.lastName}`}
            </Typography>
            {displayValue(t('DOB'), `${newDOB(studentDetials?.dateOfBirth)}`, classes)}
            {displayValue(t('GENDER'), `${studentDetials?.studentInfo?.gender ? studentDetials?.studentInfo?.gender : ''}`, classes)}
            {displayValue(t('TSHIRT'), `${studentDetials?.enrolled_courses?.[0]?.tShirtSize ? studentDetials?.enrolled_courses?.[0]?.tShirtSize : ''}`, classes)}
            {cardDetails && (
              displayValue(
                t('COURSE_SECTION'),
                `${studentDetials?.enrolled_courses?.[0]?.course?.name}
                 (${studentDetials?.enrolled_courses?.[0]?.google_class?.section || 'N/A'})`,

                classes,
              )
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.manabadiInfo}>
          <div>
            <b>{t('MANABADI_ID')}</b>
            <br />
            <span className={classes.label}>
              {studentDetials?.studentInfo?.manabadiEmail}
            </span>
          </div>
          <br />
          <div>
            <b>{t('EXTRACURRICULARS')}</b>
            {': '}
            <span className={classes.label}>
              {studentDetials?.enrolled_courses?.[0]?.extraCurricularActivities?.join(', ')}
            </span>
          </div>
        </Grid>

        <Divider />
        <Grid container spacing={1} className={classes.btn}>

          <Grid item xs={3} sm={2} md={4} sx={{ textAlignLast: 'center' }}>
            {displayButton(t('GMAIL'), setIsGmailDialogOpen, classes)}
          </Grid>

          <Grid item xs={6} sm={3} md={4} sx={{ textAlignLast: 'center' }}>
            {displayButton(t('LOCATION_INFO'), setIsLocationDialogOpen, classes)}
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={4}
            sx={{ textAlignLast: 'center' }}
          >
            <ButtonAtom
              btntype={Buttons.PRIMARY}
              className={classes.googleClassroomBtn}
              onClick={() => {
                handleResourcesDialog();
              }}
              disabled={!isActive}
              name={t('RESOURCES')}
            />
          </Grid>

          <Grid
            item
            xs={11.85}
            sm={4}
            md={11.85}
            sx={{ textAlign: 'center' }}
          >
            <ButtonAtom
              btntype={Buttons.PRIMARY}
              className={classes.googleClassroomBtn}
              onClick={() => openGoogleClassroom(studentDetials)}
              name={t('GOOGLE_CLASSROOM')}
            />
          </Grid>
        </Grid>
      </Grid>
      <DialogAtom
        isOpen={isLocationDialogOpen}
        dialogHeading={t('LOCATION_INFO')}
        customClass={classes.locationDialogAtom}
        content={(
          <LocationDialogContent
            classes={classes}
            studentDetials={studentDetials}
            addressData={addressData}
          />
        )}
        secHandle={() => setIsLocationDialogOpen(false)}
      />
      <DialogAtom
        isOpen={isResourcesDialogOpen}
        dialogHeading={t('RESOURCES')}
        customClass={classes.resourcesDialogAtom}
        content={renderResourcesDialogContent()}
        secHandle={() => {
          setIsResourcesDialogOpen(false);
          setStudentResource([]);
          setResources([]);
          setFolderName([]);
          setSelectedResource([]);
        }}
      />
      <DialogAtom
        isOpen={isGmailDialogOpen}
        dialogHeading={t('GMAIL')}
        customClass={classes.gmailDialogAtom}
        content={(
          <GmailDialogContent
            classes={classes}
            setIsGmailDialogOpen={setIsGmailDialogOpen}
            studentDetials={studentDetials}
          />
        )}
        secHandle={() => setIsGmailDialogOpen(false)}
      />
      <DialogAtom
        isOpen={isGoogleClassroomDialogOpen}
        dialogHeading={t('GOOGLE_CLASSROOM')}
        customClass={classes.googleClassroomDialogAtom}
        content={(
          <GoogleClassroomDialogContent
            classes={classes}
            isGoogleClassroomDialogOpen={setIsGoogleClassroomDialogOpen}
            studentDetials={studentDetials}
          />
        )}
        secHandle={() => setIsGoogleClassroomDialogOpen(false)}
      />
    </Grid>

  );
}
