import Constant from '../constant';
import {
  studentsByYearAndClassIdService,
  classesTimingsService,
  classesTimingsStudentService,
  classesByAcademicYear,
  getTeachetPanelStudentsService,
  markStudentAttendanceService,
  makeUpMarksService,
  studentAttendDetailService,
  getStudentsByLocationMarksService,
  updateMarksService,
  createMarksService,
  saveImportExportSheetTime,
} from '../services/auth';

export const setSelectedYear = (payload, loadFalse) => (dispatch) => {
  dispatch({
    type: Constant.SET_SELECTED_YEAR,
    payload,
  });
  if (loadFalse) loadFalse();
};

export const setSelectedClassRooms = (payload, loadFalse) => (dispatch) => {
  dispatch({
    type: Constant.SET_SELECTED_CLASSROOMS,
    payload,
  });
  if (loadFalse) loadFalse(false);
};

export const studentsByYearAndClassId = (body, setLoading) => (dispatch) => {
  studentsByYearAndClassIdService(body)
    .then((response) => {
      dispatch({
        type: Constant.STUDENTS_BY_YEAR_AND_CLASSID,
        payload: response.data,
      });
      if (setLoading) setLoading(false);
    })
    .catch(() => {
      if (setLoading) setLoading(false);
    });
};

export const classesByYear = (body, setLoading) => (dispatch) => {
  classesByAcademicYear(body)
    .then((response) => {
      if (response?.data.length === 0) {
        dispatch({
          type: Constant.CLASSES_BY_YEAR,
          payload: response?.data,
        });
      } else {
        const classesOfAcademicYear = response?.data.map((i) => ({
          id: i?.id,
          googleClassId: i?.googleClassId,
          userId: i?.userId,
          classRoomId: i?.google_class?.classRoomId,
          courseId: i?.google_class?.courseId,
          locationId: i?.google_class?.locationId,
          locationActive: i?.google_class?.location?.isActive,
          locationName: i?.google_class?.location?.name,
          courseName: i?.google_class?.course?.name,
          name: i?.google_class?.course?.name,
          sectionName: i?.google_class?.section,
        }));
        const classes = classesOfAcademicYear?.filter((obj) => obj.locationActive === true);
        dispatch({
          type: Constant.CLASSES_BY_YEAR,
          payload: classes,
        });
      }
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch(() => {
    });
};

export const getclassesTimings = (body, setLoading) => (dispatch) => {
  classesTimingsService(body)
    .then((response) => {
      dispatch({
        type: Constant.CLASSES_TIMINGS,
        payload: response.data,
      });
      if (setLoading) setLoading(false);
    })
    .catch(() => {
    });
};

export const getclassesTimingsStudents = (body) => (dispatch) => {
  classesTimingsStudentService(body)
    .then((response) => {
      dispatch({
        type: Constant.CLASSES_TIMINGS_STUDENTS,
        payload: response.data,
      });
    })
    .catch(() => {
    });
};

export const getTeachetPanelStudents = (body, setLoading) => (dispatch) => {
  getTeachetPanelStudentsService(body)
    .then((response) => {
      dispatch({
        type: Constant.GET_TEACHER_PANEL_STUDENTS,
        payload: response?.data,
      });
      if (setLoading) setLoading(false);
    })
    .catch(() => {
      dispatch({
        type: Constant.GET_TEACHER_PANEL_STUDENTS,
        payload: [],
      });
    });
};

export const markStudentAttendance = (body, refreshList, payload) => () => {
  markStudentAttendanceService(body)
    .then((res) => {
      if (res?.success) {
        refreshList(payload);
      }
    })
    .catch(() => {
    });
};
export const makeUpMarks = (body) => (dispatch) => {
  makeUpMarksService(body)
    .then((response) => {
      dispatch({
        type: Constant.MAKE_UP_MARKS,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};
export const studentAttendDetails = (body) => (dispatch) => {
  studentAttendDetailService(body)
    .then((response) => {
      dispatch({
        type: Constant.STUDENT_ATTENDANCE_DETAILS,
        payload: response?.data,
      });
    })
    .catch(() => {
      dispatch({
        type: Constant.STUDENT_ATTENDANCE_DETAILS,
        payload: [],
      });
    });
};

export const studentsByLocationMarks = (body, setLoading, setOpenDialogErrorGetData, setListRefreshed) => (dispatch) => {
  getStudentsByLocationMarksService(body)
    .then((response) => {
      setTimeout(() => {
        if (setLoading) {
          setLoading(false);
          if (setListRefreshed) {
            setListRefreshed(true);
          }
        }
      }, 2000);
      dispatch({
        type: Constant.GET_STUDENTS_BY_LOCATION_MARKS,
        payload: response?.data,
      });
    })
    .catch(() => {
      dispatch({
        type: Constant.GET_STUDENTS_BY_LOCATION_MARKS,
        payload: [],
      });
      if (setLoading) {
        setLoading(false);
      }
      setOpenDialogErrorGetData(true);
    });
};

export const classesByYearTeacher = (body, setLoading) => (dispatch) => {
  classesByAcademicYear(body)
    .then((response) => {
      const classesOfAcademicYear = response?.data.map((i) => ({
        id: i?.id,
        googleClassId: i?.googleClassId,
        userId: i?.userId,
        classRoomId: i?.google_class?.classRoomId,
        courseId: i?.google_class?.courseId,
        locationId: i?.google_class?.locationId,
        locationName: i?.google_class?.location?.name,
        courseName: i?.google_class?.course?.name,
        name: i?.google_class?.course?.name,
        sectionName: i?.google_class?.section,
        locationActive: i?.google_class?.location?.isActive,
      }));
      const classes = classesOfAcademicYear?.filter((obj) => obj.locationActive === true);
      dispatch({
        type: Constant.CLASSES_BY_YEAR,
        payload: classes,
      });
      setLoading(false);
    })
    .catch(() => {
    });
};
export const updateMarksStudents = (payload, sendSheet, setOpenDialogErrorImport, setLoading, setDialogErrorImportMessage, t) => async () => {
  try {
    for (let index = 0; index < payload.length; index++) {
      await updateMarksService(payload[index]);
    }
    setLoading(true);
    sendSheet();
  } catch (error) {
    if (error.permissions) {
      setLoading(false);
      setOpenDialogErrorImport(true);
      setDialogErrorImportMessage(`${error.message} needed permissions ${error.permissions}`);
    } else {
      setLoading(false);
      setOpenDialogErrorImport(true);
      setDialogErrorImportMessage(`${t('SHEET_IMPORT_ERROR')}`);
    }
  }
};
export const saveImportExportSheetTimes = (payload, setOpenDialogErrorImport, setDialogErrorImportMessage) => async () => {
  try {
    await saveImportExportSheetTime(payload);
  } catch (error) {
    if (error.permissions) {
      setOpenDialogErrorImport(true);
      setDialogErrorImportMessage(`${error.message} needed permissions ${error.permissions}`);
    }
  }
};
export const createStudentMarks = (payloadAdd, UpdateMarksBeforeMarksAdded, payloadEdit, setOpenDialogErrorImport, setLoading, setDialogErrorImportMessage, t) => async () => {
  try {
    for (let index = 0; index < payloadAdd.length; index++) {
      await createMarksService(payloadAdd[index]);
    }
    setLoading(true);
    UpdateMarksBeforeMarksAdded(payloadEdit);
  } catch (error) {
    if (error.permissions) {
      setLoading(false);
      setOpenDialogErrorImport(true);
      setDialogErrorImportMessage(`${error.message} needed permissions ${error.permissions}`);
    } else {
      setLoading(false);
      setOpenDialogErrorImport(true);
      setDialogErrorImportMessage(`${t('SHEET_IMPORT_ERROR')}`);
    }
  }
};
export default {
  studentsByYearAndClassId,
  getclassesTimings,
  getclassesTimingsStudents,
  classesByYear,
  getTeachetPanelStudents,
  markStudentAttendance,
  makeUpMarks,
  studentAttendDetails,
  studentsByLocationMarks,
  classesByYearTeacher,
  updateMarksStudents,
  createStudentMarks,
  saveImportExportSheetTimes,
};
