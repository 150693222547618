import { colors, fonts } from '../../../theme';

const swapcoursestyle = (theme) => ({
  select: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    bottom: '20',
  },
  changeLogs: {
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.692vw',
        maxWidth: '53.5vw',
      },
      borderRadius: '0.4vw',
      width: '100%',
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          padding: '0.7vw !important',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
    },
  },
  academicYear: {
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.692vw',
        maxWidth: '26vw',
      },
      borderRadius: '0.4vw',
      width: '100%',
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          padding: '0.7vw !important',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
    },
  },
  dropDownSelect: {
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.692vw',
        maxWidth: '26vw',
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.15vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      borderRadius: '0.4vw',
      width: '100%',
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          padding: '0.7vw !important',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
    },
    '& .MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.7vw)',
      },
      color: colors.placeHolderColor,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(1vw, -0.7vw)',
        '@media (max-width: 899px)': {
          transform: 'translate(11px, -8px) !important',
          fontSize: '13px !important',
        },
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
        },
        color: colors.primary,
      },
    },
    '& .MuiAutocomplete-root': {
      color: colors.primary,
      '& .MuiInputLabel-root': {
        '@media (min-width: 1200px)': {
          transform: 'translate(0.7vw, 0.5vw)',
        },
        color: colors.placeHolderColor,
        '&.MuiInputLabel-shrink': {
          transform: 'translate(1vw, -0.5vw)',
          '@media (min-width: 1200px)': {
            fontSize: '0.7vw',
          },
          color: colors.primary,
        },
      },
    },
  },
  errorText: {
    color: colors.errorText,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
      marginTop: '0.3vw',
      marginLeft: '0.2vw',
    },
    display: 'block',
  },
  button: {
    justifyContent: 'flex-end',
    margin: '10px 4px 3px 1px',
    width: '98%',
  },
  innerContainer: {
    margin: '0.3vw 0.6vw',
    fontFamily: fonts.fontType.roboto,
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      width: '97%',
      margin: '0',
      '& .MuiFormControl-root': {
        margin: '10px 0 0 0',
      },
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.primary} !important`,
    },
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.692vw',
      },
      borderRadius: '0.4vw',
      width: '100%',
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          padding: '2px 31px 8px 6px !important',
        },
        backgroundColor: 'inherit',
      },
    },
    '& .MuiFormControl-root .MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        lineHeight: '0.9vw !important',
        fontSize: '0.9vw',
        paddingLeft: '0.4vw',
        letterSpacing: 1,
      },
      color: `${colors.primary} !important`,
      transform: 'translate(0.5vw, 0.9vw)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0.4vw, -0.5vw)',
        '@media (max-width: 900px)': {
          transform: 'translate(12px, -0.5vw)',
          lineHeight: '0',
          letterSpacing: '0',
        },
        fontSize: 'calc(11px + 6 * ((100vw - 320px) / 1199))',
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
          paddingBottom: '0px',
        },
        '@media (max-width: 1200px)': {
          lineHeight: '0.8 !important',
        },
      },
    },
  },
  form: {
    marginTop: '0 !important',
  },
  locationSwap: {
    '@media (min-width: 1200px)': {
      width: '50vw',
      minWidth: '24vw',
      maxWidth: '50vw',
    },
  },
  tenantUserWidth: {
    paddingLeft: '0 !important',
    paddingRight: 15,
  },
});

export default swapcoursestyle;
