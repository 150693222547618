/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import Constant from '../constant/index';
import {
  createCourseService,
  editCourseService,
  getCoursesForManagerServicePagination,
} from '../services/auth';

export const getAllCoursesforManager = (pageNumber, pageLimit, nextPage) => (dispatch) => {
  getCoursesForManagerServicePagination(pageNumber, pageLimit)
    .then((response) => {
      nextPage
        ? nextPage(
          response?.data,
          pageNumber === 1 ? 20 : response?.data?.rows.length,
          pageNumber,
        )
        : null;
      if (pageNumber === 1) {
        dispatch({
          type: Constant.GET_COURSES_MANAGER,
          payload: response?.data?.rows,
        });
        dispatch({
          type: Constant.TOTAL_COURSES_MANAGER,
          payload: response?.data?.count,
        });
      } else if (pageNumber > 1) {
        dispatch({
          type: Constant.GET_COURSES_MANAGER_PAGINATION,
          payload: response?.data?.rows,
        });
        dispatch({
          type: Constant.TOTAL_COURSES_MANAGER,
          payload: response?.data?.count,
        });
      } else {
        dispatch({
          type: Constant.GET_COURSES_MANAGER,
          payload: [],
        });
        dispatch({
          type: Constant.GET_COURSES_MANAGER_PAGINATION,
          payload: [],
        });
        dispatch({
          type: Constant.TOTAL_COURSES_MANAGER,
          payload: 0,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createCourse = (payload, refreshAfterCreated, setEmailExistError, setShowAddCourseDialog, setSuccesMessage, setBaseCourse, setStatus) => {
  createCourseService(payload)
    .then((response) => {
      console.log('response :', response);
      refreshAfterCreated();
      setShowAddCourseDialog(false);
      setSuccesMessage(true);
      setStatus(false);
      setBaseCourse(false);
    })
    .catch((err) => {
      setEmailExistError(err.errors[0].msg);
    });
};

export const editCourse = (payload, refreshAfterCreated, setEmailExistError, setShowEditCourseDialog, setBaseCourse, setStatus) => {
  editCourseService(payload)
    .then((response) => {
      console.log('response :', response);
      refreshAfterCreated();
      setShowEditCourseDialog(false);
      setBaseCourse(false);
      setStatus(false);
    })
    .catch((err) => {
      setEmailExistError(err.errors[0].msg);
    });
};

export default {
  getAllCoursesforManager,
};
