/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Typography,
  Grid,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { DataGrid } from '@mui/x-data-grid';
import ButtonAtom from '../../../components/atoms/button';
import commonStyles from '../../../utils/commonClasses';
import { Buttons, NavigateRoutes } from '../../../constant';
import { DialogAtom, Loader } from '../../../components/atoms';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import { ViewIcon } from '../../../assets/svg';
import { logDate } from '../../../utils/methods';
import { getRoleChangeLogsList, getRoleChangeLogsDetails } from '../../../store/actions/getAdmin';

function DialogContent({ t, classes, data }) {
  const setValue = (value) => {
    if (value === 'true') { return 'Active'; } if (value === 'false') { return 'Inactive'; }
    return value;
  };

  const setItemValue = (value) => {
    if (value === 'active') { return 'isActive'; }
    return value;
  };

  const arr = [];
  data?.[0]?.roleChangeLogDetails?.forEach((item, i) => {
    arr.push({
      id: i,
      item: setItemValue(item?.fieldName),
      from: setValue(item?.oldValue),
      to: setValue(item?.newValue),
    });
  });

  const columns = [
    {
      field: 'item',
      flex: 1,
      type: 'string',
      headerName: t('ITEM'),
      sortable: false,
      headerClassName: 'itemHeader',
      cellClassName: 'itemCell',
    },
    {
      field: 'from',
      flex: 1,
      type: 'string',
      headerName: t('FROM'),
      sortable: false,
      headerClassName: 'fromHeader',
      cellClassName: 'fromCell',
    },
    {
      field: 'to',
      flex: 1,
      type: 'string',
      headerName: t('TO'),
      sortable: false,
      headerClassName: 'toHeader',
      cellClassName: 'toCell',
    },
  ];

  return (
    <Grid>
      <Grid container justifyContent="space-between" className={classes.dialogOverviewWrapper}>
        <Box item className={classes.item} xs={3} lg={3}>
          <Typography className={classes.dialogOverview}>
            {t('LOG_MESSAGE')}
          </Typography>
          <br />
          {data?.[0]?.logMessage}
        </Box>
        <div className={classes.vl} />
        <Box item className={classes.item} xs={3} lg={3}>
          <Typography className={classes.dialogOverview}>
            {t('CHANGED_BY')}
          </Typography>
          <br />
          {`${data?.[0]?.user?.firstName} ${data?.[0]?.user?.lastName}`}
        </Box>
        <div className={classes.vl} />
        <Box item className={classes.item} xs={3} lg={3}>
          <Typography className={classes.dialogOverview}>
            {t('DATE')}
          </Typography>
          <br />
          {logDate(data?.[0]?.createdAt)}
        </Box>
      </Grid>
      <Box mt={4} mb={2} className={classes.changesMadeSection}>
        <Typography className={classes.dialogOverview}>
          {t('CHANGES_MADE')}
        </Typography>
      </Box>
      <Box className={classes.dialogTableWrapper} height="15vw">
        <DataGrid
          rows={arr || []}
          columns={columns}
          className={classes.dataGrid}
          disableColumnFilter
          disableColumnSelector
          disableSelectionOnClick
          hideFooterPagination
          disableColumnMenu
          hideFooter
          sx={{
            '.MuiDataGrid-root': {
              outline: 'none !important',
              border: 'none !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 'none !important',
              borderTop: 'none',
              fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
              fontStyle: 'normal',
              fontWeight: 'bold !important',
              fontSize: '18px',
              lineHeight: '131.19%',
            },
            '& .MuiDataGrid-iconSeparator': {
              visibility: 'hidden',
            },
            '& .MuiDataGrid-cell ': {
              border: '0px',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
          }}
        />
      </Box>
    </Grid>
  );
}

function DialogFooter({
  classes, handleDialog, t,
}) {
  const commonClasses1 = useStyles(commonStyles)();

  return (
    <Grid container className={classes.content} justifyContent="flex-end">
      <ButtonAtom
        name={t('CLOSE')}
        onClick={handleDialog}
        btntype={Buttons.PRIMARY}
        className={`${commonClasses1.activeButton} ${classes.dialogButton}`}
      />
    </Grid>
  );
}

export default function RoleManagerLogs() {
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getAdmin);
  const roleLogs = reduxStore?.changeLogsList;
  const roleLogDetails = reduxStore?.changeLogsDetails;
  const [rowData, setRowData] = useState([]);

  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const { state: routeState } = useLocation();
  const location = useLocation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getRoleChangeLogsList());
  }, []);

  useEffect(() => {
    setRowData([]);
    roleLogs.map((roleLog) => {
      return (
        roleLog.roleId === location.state.role.id
        && setRowData((old) => [...old, {
          id: roleLog?.id,
          logMessage: roleLog?.logMessage,
          addedBy: `${roleLog?.user?.first_name} ${roleLog?.user?.last_name}`,
          createdAt: logDate(roleLog?.createdAt),
        }])
      );
    });
  }, [roleLogs]);

  const renderDialog = (event, value) => {
    event.preventDefault();
    dispatch(getRoleChangeLogsDetails(value.id));
    setDialogOpen(true);
  };

  const columns = [
    {
      field: 'logMessage',
      flex: 1,
      type: 'string',
      headerName: t('LOG_MESSAGE'),
      sortable: false,
    },
    {
      field: 'addedBy',
      flex: 1,
      type: 'string',
      headerName: t('ADDED_BY'),
      sortable: false,
    },
    {
      field: 'createdAt',
      flex: 1,
      type: 'string',
      headerName: t('ADDED_ON'),
      sortable: false,
    },
    {
      field: 'view',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      headerClassName: 'viewLogHeader',
      cellClassName: 'viewLogCell',
      renderHeader: () => (
        <>
          <ViewIcon fontSize="small" />
          {i18next.t('VIEW')}
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#" onClick={(event) => renderDialog(event, params)} aria-hidden="false" style={{ color: '#025EE6' }}>
          {i18next.t('VIEW_LOG')}
        </a>
      ),
    },
  ];

  function onBackButtonClick() {
    navigate(routeState?.lastRoute ? routeState.lastRoute : NavigateRoutes.ROLE_MANAGER_VIEW);
  }

  return (
    <>
      <Typography className={classes.mainHeaderTitle}>
        {t('VIEW_ROLEMANAGER_LOGS')}
      </Typography>
      <div className={classes.tableWrapper}>
        <DataGrid
          rows={rowData || []}
          columns={columns}
          className={classes.root}
          autoHeight
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableSelectionOnClick
          hideFooterPagination
          hideFooter
          sx={{
            '.MuiDataGrid-root': {
              outline: 'none !important',
              border: 'none !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '0.1vw solid #025EE6 !important',
              borderTop: 'none',
              fontFamily: 'Roboto,sans-serif',
              fontStyle: 'normal',
              fontWeight: '500',
              '@media (min-width: 1200px)': {
                fontSize: '1.2vw',
              },
              lineHeight: '2vw',
              color: '#025EE6',
            },
            '& .MuiDataGrid-cell ': {
              '@media (max-width: 499px)': {
                overflow: 'visible !important',
              },
              border: '0vw',
            },
            '& .MuiDataGrid-iconSeparator': {
              visibility: 'hidden',
            },
          }}
        />
        <Grid container className={classes.backButton} justifyContent="flex-end">
          <ButtonAtom
            name={t('BACK')}
            /* eslint-disable-next-line react/jsx-no-bind */
            onClick={onBackButtonClick}
            btntype={Buttons.PRIMARY}
          />
        </Grid>
        <DialogAtom
          isOpen={isDialogOpen}
          customClass={classes.viewLogDialogAtom}
          dialogHeading={t('VIEW_LOGS')}
          secHandle={() => setDialogOpen(false)}
          content={<DialogContent t={t} classes={classes} data={roleLogDetails} />}
          footer={(
            <DialogFooter
              classes={classes}
              handleDialog={() => setDialogOpen(false)}
              t={t}
            />
          )}
        />
      </div>
    </>
  );
}
