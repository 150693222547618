/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Grid, Typography, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Constant from '../../../../store/constant/index';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './styles';
import editStyles from './editStyles';
import Dropdown from '../../../../components/atoms/dropdown';
import { getAssignmentByCourseAndYear, editAssignmentFromStatus, deleteAssignmentFromStatus, deleteSelectedAssignmentsFromStatus } from '../../../../store/actions/getAdmin';
import { setSelectedYear, setSelectedLocation } from '../../../../store/actions/getLocationCoordinator';
import { getCurrentYear } from '../../../../utils/methods';
import AssignmentStatusTable from './assignment-status-table';
import AssignmentStatusDialog from './assignment-status-dialog';
import MapPin from '../../../../assets/images/map-pin.png';
import AddAssignment from '../new-assignment/addAssignment';
import DeleteAssignment from '../new-assignment/deleteAssignment';
import { getClassDetails } from '../../../teacher-user/attendance/helper';
import { DialogAtom } from '../../../../components/atoms';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';

const getCourseOptions = (courses) => courses.map((course) => ({
  id: course.id,
  name: course.name,
}));

function AssignmentStatus({
  selectedCourse,
  setSelectedCourse,
  courses,
  setLoading,
}) {
  const classes = useStyles(styles)();
  const editClasses = useStyles(editStyles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getAdmin);
  const reduxClass = useSelector((state) => state?.getTeacherView);
  const locationCoorRedux = useSelector((state) => state?.getLocationCoordinator);
  const events = reduxClass?.classesTimings;
  const assignmentTopics = reduxStore?.topics;
  let studentResourceWRT = locationCoorRedux?.studentResourceWRT;
  const studentResourceWRTFolder = locationCoorRedux?.studentResourceWRTFolder;
  const courseOptions = courses.length ? getCourseOptions(courses) : [];
  const locationCoordinatorData = useSelector((state) => state?.getLocationCoordinator);
  const selectedYearRedux = locationCoordinatorData?.selectedYear;
  const assignedYears = locationCoordinatorData?.assignedYears;
  const selectedLocationRedux = locationCoordinatorData?.selectedLocations;
  const assignedLocations = locationCoordinatorData?.assignedLocations;
  const [years, setYearsData] = useState([]);
  const [year, setYear] = useState('');
  const [rows, setRows] = useState('');
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [assignmentIdToDelete, setAssignmentIdToDelete] = useState();
  const [classroomIdToDelete, setClassroomIdToDelete] = useState();
  const [selectedAssignment, setSelectedAssignment] = useState();
  const [selectedAssignmentToEdit, setSelectedAssignmentToEdit] = useState();
  const [selectedLocation, setLocation] = useState('');
  const [locations, setLocationData] = useState([]);
  const [eventsClassData, setEventsClassData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [loadingResources, setLoadingResources] = useState(false);
  const [totalCountNum, setTotalCountNum] = useState(0);
  const [classroomIds, setClassroomIds] = useState();
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [showDeletedMessage, setShowDeletedMessage] = useState(false);
  const [oneChecked, setOneChecked] = useState(false);

  useEffect(() => {
    if (!events || !Array.isArray(events)) {
      return;
    }
    setEventsClassData(getClassDetails(events));
  }, [events]);
  const topicsData = assignmentTopics.filter((d) => d.topic_name !== '' && d.topic_name !== null);
  const assignmentTopicsWithId = topicsData.map((topic) => ({
    id: topic.topic_name,
    name: topic.topic_name,
  }));
  useEffect(() => {
    if (studentResourceWRT && Array.isArray(studentResourceWRT) && studentResourceWRT.length) {
      setTotalCountNum(studentResourceWRT.length);
      studentResourceWRT = studentResourceWRT.filter((e) => e.deactivateDate === null);
      setResourceData(studentResourceWRT);
    } else {
      setTotalCountNum(0);
      setResourceData([]);
    }
  }, [studentResourceWRT]);
  const selectedCourseName = useMemo(() => {
    if (!selectedCourse) {
      return '';
    }

    return courseOptions.find((course) => course.id === selectedCourse)?.name;
  }, [selectedCourse]);

  useEffect(() => {
    setLocationData(assignedLocations);
    if (assignedLocations?.length === 0) {
      setLocDetails([]);
    }
    if (selectedLocationRedux?.value) {
      setLocation(selectedLocationRedux?.value);
    } else {
      setLocation(assignedLocations?.[0]?.id);
    }
    setYearsData(assignedYears);

    const currentYear = getCurrentYear()?.id;
    const selectedYear = assignedYears?.filter((opt) => opt?.id === currentYear.toString());
    if (selectedYearRedux?.id) {
      setYear(selectedYearRedux?.id);
    } else {
      setYear(selectedYear?.[0]?.id);
    }
  }, [assignedYears, assignedLocations]);

  useEffect(() => {
    if (!courseOptions || !courseOptions.length) {
      return;
    }
    setSelectedCourse(selectedCourse || courseOptions[0]?.id || '', false);
  }, [courseOptions, year]);

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    dispatch(setSelectedLocation(e.target));
  };

  useEffect(() => {
    setLoading(true);
    if (selectedCourse && year && selectedLocation) {
      const payload = {
        courseId: selectedCourse,
        year: year,
        locationId: selectedLocation,
      };
    dispatch(getAssignmentByCourseAndYear(payload, setLoading, setRows));
    }
  }, [selectedCourse, year, selectedLocation]);

  const refreshAssignmentsList = () => {
    setLoading(true);
    if (selectedCourse && year && selectedLocation) {
      const payload = {
        courseId: selectedCourse,
        year: year,
        locationId: selectedLocation,
      };
    dispatch(getAssignmentByCourseAndYear(payload, setLoading, setRows));
    }
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
    const payload = {
      id: e.target.value,
      name: e.target.value,
    };
    dispatch(setSelectedYear(payload));
  };

  const viewDeleteAssignment = async (assignmentID, classroomID) => {
    setShowDeleteDialog(true);
    setClassroomIdToDelete(classroomID);
    setAssignmentIdToDelete(assignmentID);
  };

  const cancelViewDeleteAssignment = () => {
    setClassroomIdToDelete(null);
    setAssignmentIdToDelete(null);
    setShowDeleteDialog(false);
    setLoading(false);
  };

  const handleDeleteAssignment = async () => {
    setLoading(true);
    await dispatch(deleteAssignmentFromStatus(assignmentIdToDelete, classroomIdToDelete, setLoading, refreshAssignmentsList));
  };

  const handleDeleteSelectedAssignments = async () => {
    setLoading(true);
    const modifiedAssignmentsArray = selectedAssignments.map((assignment) => ({
      assignmentId: assignment.id,
      courseId: assignment.courseId,
    }));

    const payload = {
      assignmentsArray: modifiedAssignmentsArray,
    };
    await dispatch(deleteSelectedAssignmentsFromStatus(payload, setLoading, refreshAssignmentsList, setShowDeletedMessage, setSelectedAssignments));
  };

  const viewStatus = (row) => {
    if (row && row?.id) {
      setShowStatusDialog(true);
      setSelectedAssignment(row);
    }
  };

  const viewEdit = () => {
    setShowStatusDialog(false);
    setShowEditDialog(true);
  };
  const CancelViewEdit = () => {
    setShowEditDialog(false);
  };
  const SaveEditAssignment = async (payload) => {
    dispatch(editAssignmentFromStatus(payload, setLoading, classroomIds));
  }

  return (
    <Box className={classes.gridContainter}>
      <Grid container item className={classes.titleRow}>
        <Grid item xs={12} md={12} lg={6}>
            <Typography className={classes.headerTitle}>
              {t('ASSIGNMENT_STATUS')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className={classes.functionalGridItem}>
            <div className={classes.year}>
              <Dropdown
                minWidth="100%"
                label={t('YEAR')}
                labelId={t('YEAR')}
                id="year"
                name="year"
                value={year}
                handleChange={(e) => handleYearChange(e)}
                options={years}
                customClass={classes.year}
                variant="standard"
              />
            </div>
            <div className={classes.year}>
              <Dropdown
                minWidth="100%"
                id="courses"
                name="courses"
                options={courseOptions}
                label={`${t('COURSES')}`}
                labelId={`${t('COURSES')}`}
                customClass={classes.year}
                variant="standard"
                value={selectedCourse}
                handleChange={(e) => {
                  const { value } = e.target;
                  setSelectedCourse(value);
                  dispatch({
                    type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                    payload: null,
                  });
                }}
              />
            </div>
            <div xs={8} sm={5} md={4} className={classes.locationDropdown}>
              <Dropdown
                minWidth="100%"
                label={t('LOCATION')}
                id="location"
                name="location"
                value={selectedLocation}
                handleChange={(e) => handleLocationChange(e)}
                options={locations}
                customClass={classes.locationDropdown}
                variant="standard"
                icon={<img src={MapPin} alt="" className={classes.mapPinImg} />}
              />
            </div>
            {(selectedAssignments.length > 0 && oneChecked) && (
              <div>
                <ButtonAtom
                  className={classes.buttonDiv}
                  name={t('DELETE_ASSIGNMENTS')}
                  onClick={() => {
                    handleDeleteSelectedAssignments();
                  }}
                  btntype={Buttons.PRIMARY}
                />
              </div>
            )}
          </Grid>
      </Grid>
      <span className={classes.successText}>
        { showDeletedMessage ? t('DELETED_ASSIGNMENTS_MESSAGE') : ''}
      </span>
      <Grid container className={classes.announcementsContainer}>
        <Grid container className={classes.announcementsList}>
          <Grid className={classes.tableView}>
            <AssignmentStatusTable
              rows={rows}
              onStatusView={(row) => {
                viewStatus(row);
              }}
              onDeleteAssignment={(assignmentID, classroomID) => {
                viewDeleteAssignment(assignmentID, classroomID);
              }}
              selectedAssignments={selectedAssignments}
              setSelectedAssignments={setSelectedAssignments}
              setOneChecked={setOneChecked}
            />
          </Grid>
        </Grid>
      </Grid>
      <AssignmentStatusDialog
        isOpen={showStatusDialog}
        onDialogClose={() => setShowStatusDialog(false)}
        selectedAssignment={selectedAssignment}
        viewEditAssignment={viewEdit}
        setSelectedAssignmentToEdit={setSelectedAssignmentToEdit}
        setClassroomIds={setClassroomIds}
      />
      {showEditDialog ? (
      <DialogAtom
        isOpen={showEditDialog}
        dialogHeading={t('EDIT_ASSIGNMENT')}
        customClass={editClasses.DialogAtomAddAssignment}
        content={(
          <AddAssignment
            classes={editClasses}
            eventsClassData={eventsClassData}
            assignmentTopicsWithId={assignmentTopicsWithId}
            resourceData={resourceData}
            setIsFetching={setIsFetching}
            selectedCourseName={selectedCourseName}
            selectedCourse={selectedCourse}
            studentResourceWRT={studentResourceWRT}
            studentResourceWRTFolder={studentResourceWRTFolder}
            isFetching={isFetching}
            handlePopUpCancel={CancelViewEdit}
            handlePopUpSave={SaveEditAssignment}
            selectedAssignment={selectedAssignmentToEdit}
            loadingResources={loadingResources}
            setLoadingResources={setLoadingResources}
            setAddAssignmentOpen={setShowEditDialog}
            setLoading={setLoading}
            editFromStatus={true}
            refreshList={refreshAssignmentsList}
          />
        )}
        secHandle={CancelViewEdit}
      />
      ) : showDeleteDialog ? (
        <DialogAtom
          isOpen={showDeleteDialog}
          dialogHeading={t('DELETE_ASSIGNMENT')}
          customClass={editClasses.DialogAtomAddAssignment}
          content={(
          <DeleteAssignment
            assignmentId={assignmentIdToDelete}
            classes={classes}
            setDeleteAssignmentOpen={cancelViewDeleteAssignment}
            deleteAssignmentDialog={handleDeleteAssignment}
            classroomId={classroomIdToDelete}
            deleteFromStatus={true}
          />
          )}
          secHandle={cancelViewDeleteAssignment}
        />
        ) : ( null)}
    </Box>
  );
}

export default AssignmentStatus;
