/* eslint-disable */
import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import {
  Grid, Box,
} from '@mui/material';
import { pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/fontawesome-free-solid';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  DialogAtom, Datepicker, Button, TextField,
} from '../../../../components/atoms';
import CheckboxAtom from '../../../../components/atoms/checkbox';
import OptionsMenu from '../../../resources/list-view/menuOptions';
import mbTxt from '../../../../assets/images/mb_txt_icon.png';
import mbPdf from '../../../../assets/images/mb_pdf_icon.png';
import mbPng from '../../../../assets/images/mb_png_icon.png';
import mbDoc from '../../../../assets/images/mb_doc_icon.png';
import mbVideo from '../../../../assets/images/mb_video_icon.png';
import defaultIcon from '../../../../assets/images/defaultIcon.png';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';
import userRoles from '../../../../constant/userRoles';
import { getLocalStorage } from '../../../../utils/localStorageMethod';
import { Buttons } from '../../../../constant';
import {
  deactivateResource,
  getFolderDetails,
  getTeacherResource,
  getStudentResourceCourse,
  updateResource,
} from '../../../../store/actions/getResources';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ButtonAtom from '../../../../components/atoms/button';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function DropdownIndicator(props) {
  const { sortType } = props;
  return (
    <div style={{ display: 'grid', gridGap: '1px', paddingLeft: '10px' }}>
      {
        sortType === 'both' && (
          <FontAwesomeIcon icon={faSort} color="rgba(0, 0, 0, 0.54)" />
        )
      }
      {
        sortType === 'asc' && (
          <FontAwesomeIcon icon={faSortUp} color="#C12900" />
        )
      }
      {
        sortType === 'desc' && (
          <FontAwesomeIcon icon={faSortDown} color="#C12900" />
        )
      }
    </div>
  );
}

function ShowFiles(props) {
  const {
    fileType,
    fileUrl,
    classes,
  } = props;
  let aUrlSplitp = '';
  let valueUrlp = '';
  let aUrlSplitP = '';
  let valueUrlP = '';

  switch (fileType) {
    case 'png':
      return <img src={fileUrl} alt={fileUrl} />;
    case 'jpg':
      return <img src={fileUrl} alt={fileUrl} />;
    case 'jpeg':
      return <img src={fileUrl} alt={fileUrl} />;
    case 'ppt':
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`} height={810} width={600} title={fileUrl} /></div>;
    case 'pdf':
      aUrlSplitp = fileUrl?.split('/')?.splice(3);
      valueUrlp = aUrlSplitp?.join('/');
      return (
        <div className={classes.pdfFileS}>
          <iframe className={classes.pdfFileFrame} src={`https://docs.google.com/viewer?url=https://storage.googleapis.com/${valueUrlp}&embedded=true`} frameBorder="0" scrolling="no" seamless="" />
          <div style={{
            width: '50px', height: '50px', position: 'absolute', right: '12px', top: '12px', opacity: 0,
          }}
          >
          &nbsp;
          </div>
        </div>
      );
    case 'Pdf':
      aUrlSplitP = fileUrl?.split('/')?.splice(3);
      valueUrlP = aUrlSplitP?.join('/');
      return <div className={classes.pdfFileS}><iframe className={classes.pdfFileFrame} src={`https://docs.google.com/viewer?url=https://storage.googleapis.com/${valueUrlP}&embedded=true`} /></div>;
    case 'doc':
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}&wdOrigin=BROWSELINK`} height={810} width={600} title={fileUrl} /></div>;
    case 'docx':
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}&wdOrigin=BROWSELINK`} height={810} width={600} title={fileUrl} /></div>;
    case 'mp4':
      return <ReactPlayer url={fileUrl} playing="true" controls="true" playsInline="true" aspectRatio="auto" width="90%" height="90%" />;
    case 'mov':
      return <ReactPlayer url={fileUrl} playing="true" controls="true" playsInline="true" aspectRatio="auto" width="90%" height="90%" />;
    default:
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={fileUrl} height={810} width={600} title={fileUrl} /></div>;
  }
}

export default function ListView({
  tableData,
  totalRows,
  typeResources,
  selectedResource,
  folderName,
  setFolderName,
  courseSelectedvalue,
  pageNext,
  pagenum,
  setPagenum,
  setLoading,
  setIsFetching,
  isFetching,
  setActivateChecked,
  setResource,
  loading,
  sortOrder,
  setSortOrder,
  isSysAdminOrSuperUser,
}) {
  const classes = useStyles(styles)();
  const [deactivateDateValue, setDeactivateDateValue] = useState(new Date());
  const [isSetEditFileOpenDialog, setEditFileOpenDialog] = useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [isDownload, setIsDownload] = React.useState(false);
  const [resourceName, setResourceName] = React.useState('');
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const [addfileerror, setAddFileError] = useState('');
  const [dateError, setDateError] = useState('');
  const [isSetDeactivateFileOpenDialog, setDeactivateFileOpenDialog] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isEditedFileName, setEditedFileName] = useState('');
  const [ischecked, setIsChecked] = useState(false);
  const icons = {
    png: <img src={mbPng} height="20" alt={t('ICON')} />,
    jpg: <img src={mbPng} height="20" alt={t('ICON')} />,
    jpeg: <img src={mbPng} height="20" alt={t('ICON')} />,
    gif: <img src={mbPng} height="20" alt={t('ICON')} />,
    giff: <img src={mbPng} height="20" alt={t('ICON')} />,
    tif: <img src={mbPng} height="20" alt={t('ICON')} />,
    tiff: <img src={mbPng} height="20" alt={t('ICON')} />,
    webp: <img src={mbPng} height="20" alt={t('ICON')} />,
    pdf: <img src={mbPdf} height="20" alt={t('ICON')} />,
    Pdf: <img src={mbPdf} height="20" alt={t('ICON')} />,
    'octet-stream': <img src={mbDoc} height="20" alt={t('ICON')} />,
    'vnd.openxmlformats-officedocument.wordprocessingml.document': <img src={mbDoc} height="20" alt={t('ICON')} />,
    plain: <img src={mbDoc} height="20" alt={t('ICON')} />,
    msword: <img src={mbDoc} height="20" alt={t('ICON')} />,
    txt: <img src={mbDoc} height="20" alt={t('ICON')} />,
    webm: <img src={mbDoc} height="20" alt={t('ICON')} />,
    doc: <img src={mbDoc} height="20" alt={t('ICON')} />,
    docx: <img src={mbDoc} height="20" alt={t('ICON')} />,
    ppt: <img src={mbDoc} height="20" alt={t('ICON')} />,
    mp4: <img src={mbVideo} height="20" alt={t('ICON')} />,
    mov: <img src={mbVideo} height="20" alt={t('ICON')} />,
    mp3: <img src={mbVideo} height="20" alt={t('ICON')} />,
    Mp3: <img src={mbVideo} height="20" alt={t('ICON')} />,
    Folder: <img src={mbTxt} height="20" alt={t('ICON')} />,
  };
  const reduxTotalCount = useSelector((state) => state?.getLocationCoordinator.totalCount);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const nextPage = (e) => {
    e.stopPropagation();
    changePage(1);
  };

  const previousPage = (e) => {
    e.stopPropagation();
    changePage(-1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const dispatch = useDispatch();

  const [fileType, setFileType] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState(false);

  const handleClose = () => {
    setIsDownload(false);
    setOpen(false);
    setFileUrl(null);
  };
  const getFileType = (file, fileResourceName) => {
    const fileNameSplit = fileResourceName?.split('.')[1];
    if (fileNameSplit === undefined) {
      if (file !== null) {
        switch (file?.split('/')?.[1]) {
          case 'png':
            return file.split('/')[1];
          case 'jpg':
            return file.split('/')[1];
          case 'jpeg':
            return file.split('/')[1];
          case 'pdf':
            return file.split('/')[1];
          case 'Pdf':
            return file.split('/')[1];
          case 'octet-stream':
            return file.split('/')[1];
          case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
            return file.split('/')[1];
          case 'plain':
            return file.split('/')[1];
          case 'msword':
            return file.split('/')[1];
          case 'txt':
            return file.split('/')[1];
          case 'webm':
            return file.split('/')[1];
          case 'webp':
            return file.split('/')[1];
          case 'doc':
            return file.split('/')[1];
          case 'docx':
            return file.split('/')[1];
          case 'ppt':
            return file.split('/')[1];
          case 'mp4':
            return file.split('/')[1];
          case 'mp3':
            return file.split('/')[1];
          case 'Mp3':
            return file.split('/')[1];
          case 'mov':
            return file.split('/')[1];
          default:
            return 'doc';
        }
      } else {
        return 'doc';
      }
    } else if (file !== null) {
      switch (fileNameSplit.split(' ')[0]) {
        case 'png':
          return fileNameSplit.split(' ')[0];
        case 'jpg':
          return fileNameSplit.split(' ')[0];
        case 'jpeg':
          return fileNameSplit.split(' ')[0];
        case 'pdf':
          return fileNameSplit.split(' ')[0];
        case 'Pdf':
          return fileNameSplit.split(' ')[0];
        case 'octet-stream':
          return fileNameSplit.split(' ')[0];
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
          return fileNameSplit.split(' ')[0];
        case 'plain':
          return fileNameSplit.split(' ')[0];
        case 'msword':
          return fileNameSplit.split(' ')[0];
        case 'txt':
          return fileNameSplit.split(' ')[0];
        case 'webm':
          return fileNameSplit.split(' ')[0];
        case 'webp':
          return fileNameSplit.split(' ')[0];
        case 'doc':
          return fileNameSplit.split(' ')[0];
        case 'docx':
          return fileNameSplit.split(' ')[0];
        case 'ppt':
          return fileNameSplit.split(' ')[0];
        case 'mp4':
          return fileNameSplit.split(' ')[0];
        case 'mp3':
          return fileNameSplit.split(' ')[0];
        case 'Mp3':
          return fileNameSplit.split(' ')[0];
        case 'mov':
          return fileNameSplit.split(' ')[0];
        default:
          return file?.split('/')?.[1];
      }
    } else {
      return fileNameSplit.split(' ')[0];
    }
  };

  const openFolder = (obj) => {
    setResource !== undefined ? setResource([]) : null;
    setLoading !== undefined ? setLoading(true) : null;
    setIsFetching(true);
    const payload = {
      courseId: obj.courseId,
      parentKey: obj.selfKey,
    };
    dispatch(getFolderDetails(obj));
    if (obj.resourceKind === 'Student' || obj.resourceKind === 'student') {
      dispatch(getStudentResourceCourse(1, 100, pageNext, payload, setIsFetching, setLoading));
    } else {
      dispatch(getTeacherResource(1, 100, pageNext, payload, setIsFetching, setLoading !== undefined ? setLoading : null));
    }
  };

  const handleToggle = (obj) => {
    if (obj.fileType !== 'Folder') {
      const fileSplit = obj.resourceName.split('.');
      const { length } = fileSplit;
      const type = fileSplit[length - 1];
      setFileType(type);
      setFileType(type);
      setFileUrl(obj.fileUrl);
      setIsDownload(obj.canDownload);
      setResourceName(obj?.resourceName);
      setOpen(!open);
    } else {
      const folder = folderName;
      folder.push(obj);
      setFolderName(folder);
      openFolder(obj);
      selectedResource(obj);
    }
  };

  const downloadFile = async () => {
    window.open(fileUrl, 'a');
  };

  const openDeactivateFileDialog = () => {
    setDateError('');
    setDeactivateFileOpenDialog(true);
  };

  const openEditFileDialog = () => {
    setAddFileError('');
    setEditedFileName(selectedRow?.resourceName);
    setIsChecked(selectedRow?.canDownload);
    setEditFileOpenDialog(true);
  };

  const isValidDate = (value) => {
    const val = moment.utc(value).format('YYYY-MM-DD Z');

    const mNow = new Date();
    const now = moment.utc(mNow).format('YYYY-MM-DD Z');

    if (val === 'Invalid date') {
      return false;
    } else if (val < now) {
      return false;
    } else {
      return true;
    }
  };

  const deactivateFile = () => {
    if (isValidDate(deactivateDateValue)) {
      const paylaod = {
        id: selectedRow?.id,
        deactivateDate: deactivateDateValue,
      };
      dispatch(deactivateResource(paylaod));
      setDeactivateFileOpenDialog(false);
    }
  };

  const editFile = () => {
    if (isEditedFileName !== '') {
      const paylaod = {
        canDownload: ischecked,
        id: selectedRow?.id,
        courseId: selectedRow?.courseId,
        resourceName: isEditedFileName,
      };
      dispatch(updateResource(paylaod, typeResources));
      setEditFileOpenDialog(false);
    }
  };
  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };
  const [status, setStatus] = useState();
  const renderDeactivateFileContent = () => (
    <Grid>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <Datepicker
            className={classes.popupTextField}
            required
            value={deactivateDateValue}
            minDate={new Date()}
            onChange={(newValue) => {
              setDateError('');
              if (!isValidDate(newValue)) {
                setDateError(t('INVALID_DEACTIVATE_DATE'));
              }
              setDeactivateDateValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            id="outlined-required"
            label="Deactivate Date"
          />
        </div>
        <span className={classes.errorText}>{dateError}</span>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <Button
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => {
            setDeactivateDateValue(new Date());
            setDeactivateFileOpenDialog(false);
          }}
          btntype={Buttons.SECONDARY}
        />
        <Button
          className={classes.popupBtn}
          name={t('DEACTIVATE')}
          btntype={Buttons.PRIMARY}
          onClick={() => deactivateFile()}
        />
      </Grid>
    </Grid>
  );

  const renderEditFileContent = () => (
    <Grid>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className={classes.popupTextField}
            required
            id="outlined-required"
            label="File Name"
            value={isEditedFileName}
            onChange={(e) => {
              setAddFileError('');
              if (e.target.value === '') {
                setAddFileError(t('FILE_NAME_CANNOT_BE_EMPTY'));
              }
              setEditedFileName(e.target.value);
            }}
            defaultValue="BalaBadi-I"
          />
        </div>
        <span className={classes.errorText}>{addfileerror}</span>
        <div className={classes.checkbox}>
          <CheckboxAtom
            label={t('USER_DOWNLOAD')}
            id="acceptCheckbox"
            checked={ischecked}
            handleChange={(e) => handleCheck(e)}
          />
        </div>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <Button
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => setEditFileOpenDialog(false)}
          btntype={Buttons.SECONDARY}
        />
        <Button
          className={classes.popupBtn}
          name={t('UPDATE')}
          btntype={Buttons.PRIMARY}
          onClick={() => editFile()}
        />
      </Grid>
    </Grid>
  );

  const renderFileIcon = (params) => {
    if (params?.fileType === 'Folder') {
      return <img src={mbTxt} height="20" alt={t('ICON')} />;
    }

    if (params?.fileIcon === '' || params?.fileIcon === null) {
      return icons[String(getFileType(params?.fileType, params?.resourceName))];
    }
    return <img src={params?.fileIcon ? defaultIcon : params?.fileIcon} height="20" alt={t('ICON')} className={classes.fileIcon} />;
  };

  const loadMoreItems = (length) => {
    if (userRole === userRoles.SYSTEM_ADMIN) {
      setActivateChecked(true);
    }
    setIsFetching(true);
    let page = '';
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    const payload = {
      courseId: courseSelectedvalue,
      parentKey: selectedResource.selfKey,
    };
    if (typeResources === 'student' || typeResources === 'Student') {
      dispatch(getStudentResourceCourse(page, 100, pageNext, payload, setIsFetching));
    } else {
      dispatch(getTeacherResource(page, 100, pageNext, payload, setIsFetching));
    }
  };
  const getNameString = (name) => {
    /* eslint-disable no-else-return */
    /* eslint-disable prefer-template */
    if (name.length > 30) {
      return name.substring(0, 30) + '...';
    } else {
      return name;
    }
  };

  const changeSortOrder = (filter, status) => {
    if (sortOrder[status] === 'desc') {
      tableData.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'both',
      }));
    } else if (sortOrder[status] === 'asc') {
      if (filter === 'createdBy') {
        tableData.sort((a, b) => b.createdUser.firstName.localeCompare(a.createdUser.firstName));
      } else if (filter === 'updatedBy') {
        tableData.sort((a, b) => b.updatedUser?.firstName?.localeCompare(a.updatedUser?.firstName));
      } else {
        tableData.sort((a, b) => b[filter]?.localeCompare(a[filter]));
      }
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'desc',
      }));
    } else if (sortOrder[status] === 'both') {
      if (filter === 'createdBy') {
        tableData.sort((a, b) => a.createdUser?.firstName?.localeCompare(b.createdUser?.firstName));
      } else if (filter === 'updatedBy') {
        tableData.sort((a, b) => a.updatedUser?.firstName?.localeCompare(b.updatedUser?.firstName));
      } else {
        tableData.sort((a, b) => a[filter]?.localeCompare(b[filter]));
      }
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'asc',
      }));
    }
  };
  return (
    <Box>
      {!loading && (
        <Grid container className={classes.studentDetails}>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerTable}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('resourceName', 'resourceN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder?.resourceN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('FILE_NAME')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.headerTable}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('createdAt', 'createdOn')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder?.createdOn} />}
                      className={classes.studentNameLabel}
                    >
                      {t('CREATED_ON')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.headerTable}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('createdBy', 'createdBy')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder?.createdBy} />}
                      className={classes.studentNameLabel}
                    >
                      {t('CREATED_BY')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.headerTable}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('updatedAt', 'updatedOn')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder?.updatedOn} />}
                      className={classes.studentNameLabel}
                    >
                      {t('MODIFIED_ON')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.headerTable}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('updatedBy', 'updatedBy')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder?.updatedBy} />}
                      className={classes.studentNameLabel}
                    >
                      {t('MODIFIED_BY')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.headerTable}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('fileSize', 'sizeN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder?.sizeN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('FILE_SIZE')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.headerTable}>{t('STATUS')}</TableCell>
                  {
                (isSysAdminOrSuperUser)
                && (
                  <TableCell className={classes.headerTable}>{t('ACTIONS')}</TableCell>
                )
              }
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((params) => (
                  <TableRow
                    key={params?.resourceName}
                  >
                    <TableCell style={{ padding: '4px', cursor: 'pointer' }}>

                      <Grid display="flex" onClick={() => handleToggle(params)}>
                        {renderFileIcon(params)}
                        <Tooltip title={`${params.resourceName}`} placement="bottom-start">
                          <Typography className={classes.resourceNameCell}>
                            {getNameString(params.resourceName)}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <Grid display="flex">
                        <Typography className={classes.studentResourcesTableCell}>{moment(params?.createdAt).format('MMM DD, YYYY HH:mm:ss a')}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <Grid display="flex">
                        <Typography className={classes.studentResourcesTableCell}>{`${params?.createdUser?.firstName} ${params?.createdUser?.lastName}`}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <Grid display="flex">
                        <Typography className={classes.studentResourcesTableCell}>{moment(params?.updatedAt).format('MMM DD, YYYY HH:mm:ss a')}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <Grid display="flex">
                        {(params?.updatedUser !== null) ? (
                          <Typography className={classes.studentResourcesTableCell}>{`${params?.updatedUser?.firstName} ${params?.updatedUser?.lastName}`}</Typography>

                        ) : (
                          <Typography className={classes.studentResourcesTableCell}>N/A</Typography>
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <Grid display="flex">
                        <Typography className={classes.studentResourcesTableCell}>
                          {
                            params?.fileSize !== '' && params?.fileSize !== null ? params?.fileSize : 'N/A'
                          }
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <Grid display="flex">
                        <Typography className={classes.studentResourcesTableCell}>
                          {
                            params?.deactivateDate !== null ? 'Inactive' : 'Active'
                          }
                        </Typography>
                      </Grid>
                    </TableCell>
                    {
                  (isSysAdminOrSuperUser)
                  && (
                    <TableCell style={{ padding: '4px' }}>
                      <OptionsMenu
                        openDeactivateFileDialog={openDeactivateFileDialog}
                        openEditFileDialog={openEditFileDialog}
                        setSelectedRow={setSelectedRow}
                        row={params}
                        setStatus={setStatus}
                        status={status}
                      />
                    </TableCell>
                  )
                }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}

          >
            <div className={classes.fileViewer}>
              <div style={{ textAlign: 'center' }}>
                {isDownload
                  && (
                    <div className={classes.fileDownload}>
                      <DownloadSharpIcon onClick={(e) => { e.stopPropagation(); downloadFile(); }} />
                    </div>
                  )}
                <ShowFiles
                  fileType={fileType}
                  fileUrl={fileUrl}
                  classes={classes}
                  pageNumber={pageNumber}
                  numPages={numPages}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  previousPage={previousPage}
                  nextPage={nextPage}

                />
                <div className={classes.fileClose}>
                  <CloseIcon onClick={handleClose} />
                </div>
              </div>
            </div>
          </Backdrop>
          <DialogAtom
            isOpen={isSetEditFileOpenDialog}
            dialogHeading={t('EDIT_FILE')}
            customClass={classes.editFileDialogAtom}
            content={renderEditFileContent()}
            secHandle={() => setEditFileOpenDialog(false)}
          />
          <DialogAtom
            isOpen={isSetDeactivateFileOpenDialog}
            dialogHeading={t('DEACTIVATE_FILE')}
            customClass={classes.deactivateFileDialogAtom}
            content={renderDeactivateFileContent()}
            secHandle={() => setDeactivateFileOpenDialog(false)}
          />
        </Grid>
      )}
      {
        reduxTotalCount > 0 && (
          <Grid className={classes.loadMoreItems}>
            <Grid xs={12} className={classes.totalRowCount}>
              <span>
                {t('TOTAL_RESOURCES')}
                {' '}
                {totalRows}
                {' '}
                of
                {' '}
                {reduxTotalCount}
              </span>
            </Grid>
            <Grid justifyContent="flex-end" className={classes.loadMore}>
              {isFetching && <p>{t('FETCHING_RESOURCES')}</p>}
              {(!isFetching && reduxTotalCount !== totalRows) && (totalRows !== reduxTotalCount) && (
                <ButtonAtom
                  btntype={Buttons.PRIMARY}
                  onClick={() => loadMoreItems(totalRows)}
                  name={t('LOAD_MORE')}
                />
              )}
            </Grid>
          </Grid>
        )
      }
    </Box>
  );
}
