/* eslint-disable no-else-return */
import moment from 'moment';
import { formatPhoneNumber } from 'react-phone-number-input';

const geolib = require('geolib');

const getFormatTime = (time) => moment(time, 'hh:mm a').format('hh:mm a');
const getLocationsObject = async (locations, currentLocation, distanceMatrix, batchNumber) => {
  // calculate distance between two points by lat and lng
  const calculateDistance = async (origin, destinations) => {
    const batchSize = 25;
    const distances = [];
    for (let i = 0; i < (batchNumber * batchSize); i += batchSize) {
      const batchDestinations = destinations.slice(i, i + batchSize);
      const request = {
        origins: [origin],
        destinations: batchDestinations,
        travelMode: 'DRIVING',
        avoidHighways: false,
        avoidTolls: false,
      };

      try {
        const response = await distanceMatrix.getDistanceMatrix(request);
        const batchDistances = response.rows[0].elements.map((element, index) => {
          if (element.status === 'OK') {
            return (element.distance.value / 1609.344).toFixed(3);
          } else {
            const distanceInMeters = geolib.getDistance(
              { latitude: origin.lat, longitude: origin.lng },
              { latitude: batchDestinations[index].lat, longitude: batchDestinations[index].lng },
              0.01,
            );
            const distanceInMiles = distanceInMeters / 1609.344;
            return distanceInMiles.toFixed(3);
          }
        });

        distances.push(...batchDistances);
      } catch (error) {
        const batchDistances = batchDestinations.map((destination) => {
          const distanceInMeters = geolib.getDistance(
            { latitude: origin.lat, longitude: origin.lng },
            { latitude: destination.lat, longitude: destination.lng },
            0.01,
          );
          const distanceInMiles = distanceInMeters / 1609.344;
          return distanceInMiles.toFixed(3);
        });
        distances.push(...batchDistances);
      }
    }

    return distances;
  };

  const destinationCoords = locations.map((location) => ({
    lat: location.locationAddress.latitude,
    lng: location.locationAddress.longitude,
  }));

  const distancesFromCurrent = await calculateDistance(
    { lat: currentLocation.lat, lng: currentLocation.lng },
    destinationCoords,
  );
  const result = locations.map((location, index) => {
    const coOrd = (location?.location_coordinators || []).find((coordinator) => coordinator.isPrimary === true);
    const activeCoursesObj = (location?.location_courses || []).filter((activecourses) => activecourses.isActive === true);
    const exportObj = {
      id: location.id,
      name: location.name,
      city: location?.locationAddress?.city,
      address: location?.locationAddress?.address,
      classTime: `${location?.classTiming} ${getFormatTime(location?.startTime)}-${getFormatTime(location?.endTime)}`,
      locationCoordinator: `${coOrd?.user?.first_name} ${coOrd?.user?.last_name}`,
      contactNo: formatPhoneNumber(coOrd?.user?.contact_number),
      mailID: coOrd?.user?.manabadi_email,
      lat: location?.locationAddress?.latitude,
      lng: location?.locationAddress?.longitude,
      activeCourses: activeCoursesObj?.course?.name,
      distanceFromPrevious: null,
    };
    const sortedCourses = activeCoursesObj.sort((courseA, courseB) => {
      if (courseA.course?.level < courseB.course?.level) {
        return -1;
      } else if (courseA.course?.level > courseB.course?.level) {
        return 1;
      } else {
        return 0;
      }
    });
    const activeCourses = sortedCourses.map((obj) => obj.course?.name);
    exportObj.activeCourses = activeCourses.join(', ');
    exportObj.distanceFromLoc = distancesFromCurrent[index];
    return exportObj;
  });
  const sortedCenters = result?.sort((a, b) => a.distanceFromLoc - b.distanceFromLoc);
  return sortedCenters;
};

const getFilteredList = (location, examCenters) => {
  const calculateDistance = (origin, destination) => {
    const distanceInMeters = geolib.getDistance(
      { latitude: origin.lat, longitude: origin.lng },
      { latitude: destination.locationAddress.latitude, longitude: destination.locationAddress.longitude },
      0.01,
    );

    const distanceInMiles = distanceInMeters / 1609.344; // Convertir a millas
    return distanceInMiles.toFixed(3);
  };

  const distances = examCenters.map((center) => {
    const distance = calculateDistance(location, center);
    const numericDistance = parseFloat(distance);
    return { ...center, distance: numericDistance };
  });

  // Ordenar por distancia
  const sortedCenters = distances.sort((a, b) => a.distance - b.distance);
  return sortedCenters;
};

export {
  getLocationsObject,
  getFilteredList,
};
