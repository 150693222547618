/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Typography,
  CardActionArea,
} from '@mui/material';
import ReactModal from 'react-modal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Card from '@mui/material/Card';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import ButtonAtom from '../../../../components/atoms/button';

import { DialogAtom, Button, TextField } from '../../../../components/atoms';
import { Buttons } from '../../../../constant';

import useStyles from '../../../../custom-hooks/useStyles';
import styles from '../../../resources/style';

import { updateHelpVideo, getHelpVideosPagination } from '../../../../store/actions/getResources';

export default function ResourcesData({
  tableData, refreshData, pageNext,
  pagenum,
  setPagenum,
  totalRows,
  isSysAdminOrSuperUser,
  isDeactivateChecked,
  totalActiveResources,
}) {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [openVideo, setOpenVideo] = useState('');
  const reduxTotalCount = useSelector((state) => state?.getLocationCoordinator?.totalHVideosCount);
  const counter = (isDeactivateChecked === true) ? reduxTotalCount : totalActiveResources;

  const handleToggle = (row) => {
    setOpenVideo(row.url);
    setOpen(!open);
  };
  const [selectedRow, setSelectedRow] = useState([]);
  const [isEditedFileName, setEditedFileName] = useState('');
  const [isSetEditFileOpenDialog, setEditFileOpenDialog] = useState(false);
  const openEditFileDialog = (obj) => {
    setSelectedRow(obj);
    setEditedFileName(obj.name);
    setEditFileOpenDialog(true);
  };

  const editFile = () => {
    const callback = () => {
      refreshData();
    };
    const paylaod = {
      id: selectedRow?.id,
      name: isEditedFileName,
    };
    dispatch(updateHelpVideo(paylaod, callback));
    setEditFileOpenDialog(false);
  };

  const loadMoreItems = (length) => {
    let page = '';
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    const payload = {
      userRole: 'LOCATION_COORDINATOR',
    };
    dispatch(getHelpVideosPagination(payload, page, 100, pageNext));
  };

  const renderEditFileContent = () => (
    <Grid>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className={classes.popupTextField}
            required
            id="outlined-required"
            label="File Name"
            value={isEditedFileName}
            onChange={(e) => setEditedFileName(e.target.value)}
            defaultValue="BalaBadi-I"
          />
        </div>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <Button
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => setEditFileOpenDialog(false)}
          btntype={Buttons.SECONDARY}
        />
        <Button
          className={classes.popupBtn}
          name={t('UPDATE')}
          btntype={Buttons.PRIMARY}
          onClick={() => editFile()}
        />
      </Grid>
    </Grid>
  );

  return (

    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} className={classes.resourceGrid}>
        {tableData?.length > 0 ? tableData.map((obj) => (
          <Grid item lg={2} xs={6} md={3} className={classes.alignResourceGrid}>
            <Card className={classes.resourcesCard} key={obj.id}>
              <CardActionArea className={classes.resourceGridCardImg} onClick={() => handleToggle(obj)}>
                <Grid lg={2} xs={6} md={3} className={classes.resourceGridCard}>
                  <OndemandVideoOutlinedIcon sx={{ color: '#1034ad' }} />
                </Grid>
              </CardActionArea>
              <CardContent className={classes.resourceName}>
                {(isSysAdminOrSuperUser) ? (
                  <>
                    <Typography className={classes.fileNameStyle}>
                      {obj.name}
                    </Typography>
                    <EditOutlinedIcon onClick={() => openEditFileDialog(obj)} />
                  </>
                ) : (
                  <Typography className={classes.fileNameStyle}>
                    {obj.name}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        )) : null}
        <ReactModal
          isOpen={open}
          onRequestClose={handleToggle}
          className={classes.reactModal}
          overlayClassName={classes.reactOverlay}
        >
          <video
            className="video-js"
            src={openVideo}
            autoPlay
            controls
            style={{
              height: 'auto', width: '100%',
            }}
          >
            <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
          </video>
        </ReactModal>
        <DialogAtom
          isOpen={isSetEditFileOpenDialog}
          dialogHeading={t('EDIT_FILE')}
          customClass={classes.editFileDialogAtom}
          content={renderEditFileContent()}
          secHandle={() => setEditFileOpenDialog(false)}
        />
      </Grid>
      {(reduxTotalCount > 0 && totalRows > 0)
        && (
          <Grid className={classes.loadMoreItems}>
            <Grid xs={12} className={classes.totalRowCount}>
              <span>
                {t('TOTAL_RESOURCES')}
                {' '}
                {totalRows}
                {' '}
                of
                {' '}
                {reduxTotalCount}
              </span>
            </Grid>
            {(counter !== totalRows)
              && (
                <Grid justifyContent="flex-end" className={classes.loadMore}>
                  <ButtonAtom
                    btntype={Buttons.PRIMARY}
                    onClick={() => loadMoreItems(totalRows)}
                    name={t('LOAD_MORE')}
                  />
                </Grid>

              )}
          </Grid>

        )}
    </Box>
  );
}
