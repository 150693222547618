import makeStyles from '@mui/styles/makeStyles';
import { colors, fonts } from '../../../theme';

const style = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& span': {
      '&:nth-child(2)': {
        position: 'relative',
        zIndex: 1,
        '& .MuiInputBase-root': {
          background: 'rgb(255 255 255 / 0%)',
        },
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.placeHolderColor} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        transform: 'translate(0.7vw, 0.9vw)',
      },
      letterSpacing: 1,
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.primary} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        transform: 'translate(0.7vw, -0.5vw)',
      },
      letterSpacing: 1,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.black} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        padding: '0.5vw !important',
        minHeight: '0.5em !important',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
    },
    '& .MuiOutlinedInput-root': {
      background: 'white',
      '@media (min-width: 1200px)': {
        height: '2.9vw !important',
        lineHeight: '1vw',
        borderRadius: '-0.6vw',
      },
      outline: 'none',
      width: '100%',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.primary}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.15vw solid ${colors.primary}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.15vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.errorText}`,
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          width: '1vw',
          height: '1vw',
          right: '0.5vw',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
      },
    },
    '& input': {
      padding: '0.7vw 1vw 0.7vw 0.7vw',
      boxSizing: 'border-box',
      fontSize: '0.9vw',
      fontFamily: `${fonts.fontType.roboto} !important`,
      color: '#696969 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      '&::placeholder': {
        textTransform: 'uppercase',
        color: `${colors.placeHolderColor} !important`,
        opacity: 1,
        letterSpacing: 2,
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        fontFamily: fonts.fontType.roboto,
        fontWeight: '300',
      },
    },
  },
  menuItem: {
    borderBottom: '0.1vw solid #eee !important',
    padding: '0.5vw !important',
    '@media (max-width: 800px)': {
      padding: '3vw !important',
    },
    maxWidth: 500,
    minWidth: '100%',
    whiteSpace: 'break-spaces',
    justifyContent: 'start !important',
    display: 'block !important',
    fontFamily: fonts.fontType.roboto,
    fontSize: '0.9vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      fontFamily: `${fonts.fontType.roboto} !important`,
    },
    '&:last-child': {
      borderBottom: 'none !important',
    },
  },
  defaultMenu: {
    fontSize: '0.9vw',
    padding: '0.7vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      fontFamily: `${fonts.fontType.roboto} !important`,
    },
  },
  errorText: {
    color: colors.errorText,
    fontSize: '0.9vw',
    bottom: -20,
    position: 'absolute',
    fontFamily: fonts.fontType.roboto,
    display: 'block',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      fontFamily: `${fonts.fontType.roboto} !important`,
    },
  },
  dropDownSelect: {
    width: '50%',
    '& .MuiInputLabel-root:not(Mui-focused)': {
    },
    '& img': {
      position: 'absolute',
      top: 9,
      left: 0,
      '& + .MuiInput-root': {
        '& .MuiSelect-select': {
          paddingLeft: 25,
        },
      },
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      '& .MuiSelect-select': {
        '@media (min-width: 1200px)': {
          padding: '7px 25px 10px 0',
        },
      },
    },
  },
  withLeftIcon: {
    '& .MuiInputLabel-root:not(Mui-focused)': {
      transform: 'translate(25px, 6px) scale(1)',
    },
  },
}));

export default style;
