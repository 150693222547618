import { colors, fonts } from '../../../theme';

const detailstyle = (theme) => ({

  HeaderWrapper: {
    boxShadow: 'none',
    left: '0',
    top: '0',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dropdownWrap: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    '& .MuiFormControl-root': {
    },
    '& .MuiInputBase-root.MuiInput-root:before': {
      borderBottom: `0.15vw solid ${colors.primary}`,
    },
    '& .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '0',
    },
  },

  googleClasses: {
    marginLeft: '5vw',
    '& .MuiInputBase-root.MuiInput-root': {
      '@media (max-width: 1200px)': {
        minWidth: '35vw !important',
        maxWidth: '250px !important',
      },
    },
    '& .MuiSelect-select.MuiSelect-select': {
      '@media (min-width: 1200px)': {
        width: '30vw',
      },
      '@media (max-width: 1200px)': {
        width: '95%',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  assignyear: {
    maxWidth: '250px',
    '& .MuiFormControl-root': {
      minWidth: '8vw',
      '& .MuiInputBase-root.MuiInput-root': {
        width: '100%',
      },
    },
  },
  gridContainer: {
    padding: '2vw 2vw 2vw 3vw !important',
    minHeight: '65vh',
  },
  titleRow: {
    justifyContent: 'space-between',

  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    fontSize: '1.5vw',
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  dropdowns: {
    '@media (min-width: 1200px)': {
      maxHeight: '4vw',
    },
  },
  year: {
    textAlign: 'start',
    minWidth: '8vw',
    '& .MuiFormControl-root': {
      '& .MuiInputLabel-root': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '1vw',
        },
        letterSpacing: 1,
      },
      '& .MuiInput-root': {
        '@media (max-width: 1200px)': {
          display: 'block',
        },
      },
      '& .MuiInput-root:before': {
        borderBottom: `0.1vw solid ${colors.actionIconsColor}`,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        fontSize: '1vw',
        padding: '0.7vw !important',
        width: '100%',
        '@media (min-width: 1200px)': {
          width: '6vw !important',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-root': {
        background: 'white',
        height: '2.7vw !important',
        lineHeight: '1vw',
        borderRadius: '0.4vw',
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          width: '1vw',
          height: '1vw',
        },
      },
      '& input': {
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        boxSizing: 'border-box',
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
        },
        fontFamily: fonts.fontType.roboto,
        color: '#696969 !important',
        '&::placeholder': {
          textTransform: 'uppercase',
          color: `${colors.placeHolderColor} !important`,
          opacity: 1,
          letterSpacing: 2,
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontFamily: fonts.fontType.roboto,
          fontWeight: 400,
        },
      },
    },

  },
  locationDropdown: {
    textAlign: 'start',
    '@media (min-width: 1200px)': {
      minWidth: '40vw',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiInputBase-root.MuiInput-root': {
      '@media (min-width: 1200px)': {
        minWidth: '35vw !important',
        maxWidth: '35vw !important',
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiInputLabel-root': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '1vw',
          marginLeft: '1.3vw',
        },
        letterSpacing: 1,
      },
      '& .MuiInput-root': {
        '@media (max-width: 1200px)': {
          display: 'block',
          width: '85%',
          paddingRight: '15px',
          paddingLeft: '8px',
        },
      },
      '& .MuiInput-root:before': {
        borderBottom: `0.1vw solid ${colors.actionIconsColor}`,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        padding: '0.7vw !important',
        '@media (min-width: 1200px)': {
          fontSize: '1vw',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-root': {
        background: 'white',
        '@media (min-width: 1200px)': {
          height: '2.7vw !important',
          lineHeight: '1vw',
          borderRadius: '0.4vw',
        },
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          width: '1vw',
          height: '1vw',
        },
      },
      '& input': {
        '@media (min-width: 1200px)': {
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontSize: '0.9vw',
        },
        boxSizing: 'border-box',
        fontFamily: fonts.fontType.roboto,
        color: '#696969 !important',
        '&::placeholder': {
          textTransform: 'uppercase',
          color: `${colors.placeHolderColor} !important`,
          opacity: 1,
          letterSpacing: 2,
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontFamily: fonts.fontType.roboto,
          fontWeight: 400,
        },
      },
    },

  },
  mapPinImg: {
    '@media (min-width: 1200px)': {
      width: '1vw',
      height: '1vw',
    },
    transform: 'translate(0.9vw, 0.1vw)',
    marginRight: '0.2vw',
  },
  gridBorder: {
    border: '1px solid #015EEA',
    borderRadius: '0.2vw',
    marginRight: '10px',
    marginTop: '2vw',
    '@media (max-width: 600px)': {
      marginTop: '15px',
    },
  },
  noData: {
    textAlign: 'center',
    marginTop: '7vw',
  },
});

export default detailstyle;
