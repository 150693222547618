/* eslint-disable no-unused-expressions */
import Constant from '../constant';
import {
  getActiveRegionService,
  getAllRegionService,
  getCountriesService,
  getGeoRegionService,
  getRegionCordinatorsService,
} from '../services/auth';

export const getRegions = (setLoading, pageNumber, pageLimit, nextPage, searchText) => (dispatch) => {
  getAllRegionService(pageNumber, pageLimit, searchText)
    .then((response) => {
      nextPage
        ? nextPage(
          response?.data,
          pageNumber === 1 ? 100 : response?.data?.count,
          pageNumber,
        )
        : null;
      if (pageNumber === 1) {
        dispatch({
          type: Constant.SET_ALL_REGION,
          payload: response?.data?.rows,
        });
        dispatch({
          type: Constant.TOTAL_REGION_COUNT,
          payload: response?.data?.count,
        });
      } else if (pageNumber > 1) {
        dispatch({
          type: Constant.GET_PAGINATED_REGIONS,
          payload: response?.data?.rows,
        });
        dispatch({
          type: Constant.TOTAL_REGION_COUNT,
          payload: response?.data?.count,
        });
      } else {
        dispatch({
          type: Constant.SET_ALL_REGION,
          payload: [],
        });
        dispatch({
          type: Constant.GET_PAGINATED_REGIONS,
          payload: [],
        });
        dispatch({
          type: Constant.TOTAL_REGION_COUNT,
          payload: 0,
        });
      }
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};

export const getActiveRegions = () => (dispatch) => {
  getActiveRegionService()
    .then((response) => {
      dispatch({
        type: Constant.SET_ACTIVE_REGIONS,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};
export const getCountries = () => (dispatch) => {
  getCountriesService()
    .then((response) => {
      dispatch({
        type: Constant.SET_COUNTRIES,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export const getRegionCordinators = () => (dispatch) => {
  getRegionCordinatorsService()
    .then((response) => {
      dispatch({
        type: Constant.SET_REGION_COORDINATOR,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export const getGeoRegion = (payload) => (dispatch) => {
  getGeoRegionService(payload)
    .then((response) => {
      dispatch({
        type: Constant.SET_GEO_REGION,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

export default {
  getRegions,
  getActiveRegions,
  getRegionCordinators,
  getGeoRegion,
};
