import { colors, fonts } from '../../../theme';

const adminDashboardStyle = (theme) => ({
  mainContainer: {
    padding: 30,
    backgroundColor: colors.white,
  },
  titleRow: {
    justifyContent: 'space-between',
    color: colors.actionIconsColor,
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    fontSize: '1.5vw',
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  rowsTitle: {
    fontWeight: 600,
    color: colors.blue,
  },
  rowsTitleSheetName: {
    fontWeight: 600,
    color: colors.blue,
    '@media (max-width: 600px)': {
      width: 320,
    },
  },
});

export default adminDashboardStyle;
