/* eslint-disable */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import styles from './style';
import useStyles from '../../../../custom-hooks/useStyles';
import { Buttons } from '../../../../constant';
import ButtonAtom from '../../../../components/atoms/button';
import { getExamCenterManager } from '../../../../store/actions/getExamCenterManager';

function createData(
  name,
  address,
  city,
  isActive,
  addressId,
  id,
  aptSuite,
) {
  return {
    name,
    address,
    city,
    isActive: isActive ? 'Active' : 'Inactive',
    addressId,
    id,
    aptSuite,
  };
}

function ExpandableTableRow({
  children,
  expandComponent,
  classes,
  rowData,
  setSelectedRow,
  setShowEditExamCenterDialog,
  setNameExistErrorMessage,
  ...otherProps
}) {

  return (
    <TableRow {...otherProps}>
      {children}
      <TableCell padding="checkbox" align="right" className={classes.actionCell}>
        <Box display="flex">
          <EditIcon onClick={() => {
            const row = [];
            row.push(rowData);
            setSelectedRow(row);
            setShowEditExamCenterDialog(true);
            setNameExistErrorMessage('');
          }}
          />
        </Box>

      </TableCell>
    </TableRow>
  );
}

export default function ExamCenterManagerTable({
  tableData, setSelectedRow, setShowEditExamCenterDialog,
  setNameExistErrorMessage,
  pageNext,
  pagenum,
  setPagenum,
  totalRows,
  setLoading,
  totalActiveResources,
  isDeactivateChecked,
  totalInactiveResources,
}) {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const reduxTotalCount = useSelector((state) => state?.getExamCenterManager?.totalExamCenters);
  const dispatch = useDispatch();
  const counter = (isDeactivateChecked === true) ? reduxTotalCount : totalActiveResources;
  const rows = [];
  tableData?.map((item) => rows.push(createData(
    item?.name,
    item?.address?.address,
    item?.address?.city,
    item?.isActive,
    item?.addressId,
    item?.id,
    item?.address?.aptSuite,
  )));

  const loadMoreItems = (length) => {
    let page = '';
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    dispatch(getExamCenterManager(setLoading, page, 100, pageNext));
  };

  return (
    <Grid>
      <TableContainer>
        <Table sx={12} aria-label="simple table">
          <TableHead className={classes.roleManagerTable}>
            <TableRow>
              <TableCell>{t('EXAM_LOCATION_NAME')}</TableCell>
              <TableCell align="left">{t('ADDRESS')}</TableCell>
              <TableCell align="center">{t('CITY')}</TableCell>
              <TableCell align="right">{t('STATUS')}</TableCell>
              <TableCell align="right">{t('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.roleManagerTable}>
            {
              rows.length > 0
                ? (
                  <>
                    {rows.map((row) => (
                      <ExpandableTableRow
                        key={row.id}
                        classes={classes}
                        rowData={row}
                        setSelectedRow={setSelectedRow}
                        setShowEditExamCenterDialog={setShowEditExamCenterDialog}
                        setNameExistErrorMessage={setNameExistErrorMessage}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {row.address}
                        </TableCell>
                        <TableCell align="center">
                          {row.city}
                        </TableCell>
                        <TableCell align="right">
                          {row.isActive}
                        </TableCell>
                      </ExpandableTableRow>
                    ))}
                  </>
                )
                : (
                  <TableRow>
                  </TableRow>
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
      {(reduxTotalCount > 0 && totalRows > 0)
        && (
          <Grid container justifyContent="flex-end">

            <Grid className={classes.loadMoreItems}>
              <Grid xs={12} className={classes.totalRowCount}>
                <span>
                  {t('TOTAL_EXAM_CENTERS')}
                  {' '}
                  {totalRows}
                  {' '}
                  of
                  {' '}
                  {reduxTotalCount}
                </span>
              </Grid>
              {(reduxTotalCount !== (totalActiveResources + totalInactiveResources))
                && (totalRows !== reduxTotalCount ? (
                  <Grid justifyContent="flex-end" className={classes.loadMore}>
                    <ButtonAtom
                      btntype={Buttons.PRIMARY}
                      onClick={() => loadMoreItems(totalRows)}
                      name={t('LOAD_MORE')}
                    />
                  </Grid>
                ) : (
                  <Grid justifyContent="flex-end" className={classes.loadMore}>
                  </Grid>
                )
                )}
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}
