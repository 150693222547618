import {
  Box, Grid, TextField, Typography,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import { useStudent, useStyles } from '../../../custom-hooks';
import styles from './styles';
import { DropdownCurricular, Loader } from '../../../components/atoms';
import { getAllCourses } from '../../../store/actions/getStudent';
import { getAssignedLocations } from '../../../store/actions/getLocationCoordinator';
import useLocationCoordinator from '../../../custom-hooks/useLocationCoordinator';
import { getCurrentYear, isValidSemesterYear } from '../../../utils/methods';
import { getQuarterDetails } from '../../../store/actions/academic-panel';
import RenderCategoryField from './category-fields';
import useAssignmentsSchema from '../../../custom-hooks/useAssignmentsSchema';
import { createAssignmentsSchema, getAssignmentsSchema, updateAssignmentsSchema } from '../../../store/actions/getAssignmentsSchema';

function AssignmentsSchema() {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { courses } = useStudent();
  const { assignedYears } = useLocationCoordinator();
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMarks, setErrorMarks] = useState('');
  const [validSemesterYear, setValidSemesterYear] = useState(true);
  const [updateSchema, setUpdateSchema] = useState(false);
  const [assignmentMarksCopy, setAssignmentMarksCopy] = useState('');
  const [academicYearCopy, setAcademicYearCopy] = useState('');
  const [errorSchemaRemoved, setErrorSchemaRemoved] = useState('');
  const [saveClicked, setSaveClicked] = useState(false);

  const { schema, quarterDetails } = useAssignmentsSchema();
  const [quarterCopy, setQuarterCopy] = useState('');

  const semesterOptions = [
    { id: 'S1', key: 'SEMESTER1' },
    { id: 'S2', key: 'SEMESTER2' },
  ];

  useEffect(() => {
    setLoading(true);
    const loadFalse = () => setLoading(false);
    dispatch(getAllCourses());
    dispatch(getAssignedLocations(loadFalse));
    setErrorSchemaRemoved('');
    setSuccessMsg('');
  }, []);

  const restrictKeys = (ev, allowDecimal = false) => {
    const restrictedSymbols = ['+', '-', 'e', '*'];

    if (!allowDecimal) {
      restrictedSymbols.push('.');
    }

    if (restrictedSymbols.includes(ev.key)) {
      ev.preventDefault();
    }
  };

  const useGetCourseRows = (ques) => {
    const [rows, setRow] = useState([]);

    useEffect(() => {
      setRow([]);
      [...Array(ques)].map((_, i) => setRow((oldRows) => [...oldRows, i]));
    }, [ques]);

    return { rows, setRow };
  };

  const isValidDate = (value) => {
    const val = moment(value).format('yyyy-MM-DD');
    const now = moment().format('yyyy-MM-DD');
    if (moment(val).isBefore(moment(now))) {
      return false;
    }
    return true;
  };

  const getQuarterStartEndDate = () => {
    if (quarterDetails) {
      const { academicYearQuarterDates } = quarterDetails;
      return academicYearQuarterDates.find((quarterItem) => quarterItem.quarter === quarterCopy);
    }

    return null;
  };

  const handleSave = (values) => {
    setLoading(true);

    const createCurricularSchema = values?.assignmentSchemaMarks?.map((ele) => ({
      category: ele.category,
      marks: ele.marks,
      notes: ele.notes,
      startDate: ele.startDate,
      endDate: ele.endDate,
    }));

    const updateCurricularSchema = values?.assignmentSchemaMarks?.map((ele) => ({
      category: ele.category || 'category',
      marks: ele.marks || 0,
      notes: ele.notes || 'notes',
      startDate: ele.startDate,
      endDate: ele.endDate,
      isActive: true,
      assignmentSchemaId: updateSchema,
    }));

    const payload = {
      courseId: values.courses,
      academicYear: assignedYears.find((year) => year.id === values.academicYear)?.name,
      maximumMarks: values.maxMarks,
      schemaLabel: values.schemaLabel,
      passingCriteria: 0,
      weightage: values.weightage,
      totalQuestions: values.totalQuestions,
      assignmentMarks: updateSchema ? updateCurricularSchema : createCurricularSchema,
      quarter: values.semester,
      isActive: !!updateSchema,
      ...(updateSchema ? { assignmentSchemaId: updateSchema } : {}),
    };

    if (!updateSchema) {
      // eslint-disable-next-line no-use-before-define
      dispatch(createAssignmentsSchema(payload, refreshSchema, setLoading, setSuccessMsg));
    } else {
      // eslint-disable-next-line no-use-before-define
      dispatch(updateAssignmentsSchema(payload, refreshSchema, setLoading, setError, setSuccessMsg));
    }
  };

  const findValidPrevNextIndices = (data, index, prev = true) => {
    if (data[index]?.deletedAt) {
      return findValidPrevNextIndices(data, prev ? index - 1 : index + 1, prev);
    }
    return index;
  };

  const isValidDateRange = (start, end, date = null) => {
    const startDate = moment(start).utc(false).format('yyyy-MM-DD');
    const endDate = moment(end).utc(false).format('yyyy-MM-DD');
    const now = date ? moment(date).format('yyyy-MM-DD') : moment().format('yyyy-MM-DD');

    return moment(now).isBetween(startDate, endDate, undefined, '[]');
  };

  const checkValidDateByQuarter = (date) => {
    let editable = false;
    if (!quarterDetails) {
      return quarterDetails;
    }
    const { academicYearQuarterDates } = quarterDetails;

    academicYearQuarterDates.forEach((quarterItem) => {
      if (quarterItem.quarter === quarterCopy) {
        editable = isValidDateRange(quarterItem.startDate, quarterItem.endDate, date);
      }
    });

    return editable;
  };

  const validationSchema = Yup.object({
    schemaLabel: Yup.string(t('SCHEMA_LABEL_IS_REQUIRED'))
      .required(t('SCHEMA_LABEL_IS_REQUIRED')),
    weightage: Yup.number(t('WEIGHTAGE_IS_REQUIRED'))
      .required(t('WEIGHTAGE_IS_REQUIRED'))
      .max(100, t('INVALID_WEIGHTAGE')),
    totalQuestions: Yup.number(t('TOTAL_WEEKS_REQUIRED'))
      .required(t('TOTAL_WEEKS_REQUIRED')),
    maxMarks: Yup.number(t('MAX_MARKS_ARE_REQUIRED'))
      .required(t('MAX_MARKS_ARE_REQUIRED')),
    assignmentSchemaMarks: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().test('category required test', t('CATEGORY_LABEL_IS_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (assignmentMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        marks: Yup.number().test('marks required test', t('MARKS_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (assignmentMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        notes: Yup.string().test('notes required test', t('NOTES_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (assignmentMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        startDate: Yup.date().nullable().test('startDate required test', t('START_DATE_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (assignmentMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }).typeError(t('INVALID_DATE'))
          .test(
            'startDate in academicYear range',
            t('INVALID_DATE'),
            (value, context) => {
              if (academicYearCopy) {
                const [startYear, endYear] = academicYearCopy.split('-');
                const startDateObj = new Date(startYear, 0, 1);
                const endDateObj = new Date(endYear, 11, 31);

                const { path } = context;

                const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

                if (assignmentMarksCopy[currIndex]?.deletedAt) {
                  return true;
                }

                const prevIndex = findValidPrevNextIndices(assignmentMarksCopy, currIndex - 1);

                if (assignmentMarksCopy && Array.isArray(assignmentMarksCopy) && assignmentMarksCopy.length && assignmentMarksCopy[prevIndex]) {
                  return (moment(value).isSameOrAfter(startDateObj) && moment(value).isSameOrBefore(endDateObj)
                    && moment(new Date(assignmentMarksCopy[currIndex].startDate)).isAfter(assignmentMarksCopy[prevIndex].endDate));
                }

                return (moment(value).isSameOrAfter(startDateObj) && moment(value).isSameOrBefore(endDateObj));
              }

              return true;
            },
          )
          .test(
            'startDate in quarter range',
            validSemesterYear ? t('START_DATE_OUT_SEMESTER_RANGE') : t('START_DATE_OUT_QUARTER_RANGE'),
            (value, context) => {
              const { path } = context;

              const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

              if (assignmentMarksCopy[currIndex]?.deletedAt) {
                return true;
              }

              return checkValidDateByQuarter(value);
            },
          ),
        endDate: Yup.date().nullable().test('startDate required test', t('END_DATE_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (assignmentMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }).typeError(t('INVALID_DATE'))
          .when(
            'startDate',
            (startDate, schem) => {
              if (!startDate) {
                return schem;
              }

              const date = new Date(startDate.getTime());

              date.setDate(date.getDate() + 1);
              date.setHours(0, 0, 0);

              return (date
                ? schem.min(date, t('END_DATE_LESS_THAN_START_DATE'))
                : schem);
            },
          )
          .test(
            'endDate in academicYear range',
            t('INVALID_DATE'),
            (value, context) => {
              if (academicYearCopy) {
                const [startYear, endYear] = academicYearCopy.split('-');
                const startDateObj = new Date(startYear, 0, 1);
                const endDateObj = new Date(endYear, 11, 31);

                const { path } = context;

                const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

                if (assignmentMarksCopy[currIndex]?.deletedAt) {
                  return true;
                }
                const nextIndex = findValidPrevNextIndices(assignmentMarksCopy, currIndex + 1, false);

                if (
                  assignmentMarksCopy
                  && Array.isArray(assignmentMarksCopy)
                  && assignmentMarksCopy.length
                  && assignmentMarksCopy[nextIndex]
                  && assignmentMarksCopy[nextIndex].startDate
                ) {
                  return (moment(value).isBetween(startDateObj, endDateObj)
                    && moment(
                      typeof assignmentMarksCopy[nextIndex].startDate === 'string'
                        ? new Date(assignmentMarksCopy[nextIndex].startDate)
                        : assignmentMarksCopy[nextIndex].startDate,
                    ).isAfter(assignmentMarksCopy[currIndex].endDate));
                }

                return moment(value).isBetween(startDateObj, endDateObj);
              }

              return true;
            },
          )
          .test(
            'endDate in quarter range',
            validSemesterYear ? t('END_DATE_OUT_SEMESTER_RANGE') : t('END_DATE_OUT_QUARTER_RANGE'),
            (value, context) => {
              const { path } = context;

              const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

              if (assignmentMarksCopy[currIndex]?.deletedAt) {
                return true;
              }

              return checkValidDateByQuarter(value);
            },
          ),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      courses: '',
      academicYear: '',
      schemaLabel: '',
      weightage: '',
      totalQuestions: '',
      maxMarks: '',
      assignmentSchemaMarks: [],
      semester: 'S1',
    },
    validationSchema,
    onSubmit: handleSave,
  });

  const refreshSchema = () => {
    if (formik.values.courses && formik.values.academicYear && formik.values.semester) {
      setLoading(true);
      dispatch(getAssignmentsSchema(formik.values.courses, formik.values.academicYear, formik.values.semester, setLoading));
      formik.setTouched({}, false);
    }
  };

  const currentAcademicYear = getCurrentYear()?.id;
  useEffect(() => {
    formik.setFieldValue('academicYear', currentAcademicYear);
  }, [assignedYears]);

  useEffect(() => {
    formik.setFieldValue('courses', courses?.[0]?.id);
  }, [courses]);

  useEffect(() => {
    setSaveClicked(false);
    setSuccessMsg('');
    setErrorSchemaRemoved('');
    if (formik.values.courses && formik.values.academicYear) {
      setAcademicYearCopy(formik.values.academicYear);
      setQuarterCopy(formik?.values?.semester);
      refreshSchema();
    }
  }, [formik.values.courses, formik.values.academicYear, formik.values.semester]);

  const prefillSchema = (data) => {
    if (!data.length) {
      formik.setFieldValue('schemaLabel', '');
      formik.setFieldValue('passingCriteria', '');
      formik.setFieldValue('weightage', '');
      formik.setFieldValue('totalQuestions', '');
      formik.setFieldValue('maxMarks', '');
      formik.setFieldValue('assignmentSchemaMarks', []);
      setAssignmentMarksCopy([]);
      setUpdateSchema(false);
      setLoading(false);

      return;
    }

    const prefillData = data[0];

    formik.setFieldValue('schemaLabel', prefillData?.schemaLabel || '');
    formik.setFieldValue('passingCriteria', prefillData?.passingCriteria >= 0 ? prefillData?.passingCriteria : 0);
    formik.setFieldValue('weightage', prefillData?.weightage >= 0 ? prefillData?.weightage : 0);
    formik.setFieldValue('totalQuestions', prefillData?.totalQuestions || 0);
    formik.setFieldValue('maxMarks', prefillData?.maximumMarks || 0);
    formik.setFieldValue('assignmentSchemaMarks', prefillData?.assignment_marks_schemas || []);
    setTimeout(() => {
      formik.setFieldTouched('assignmentSchemaMarks', prefillData?.assignment_marks_schemas?.map(() => ({
        category: false,
        marks: false,
        notes: false,
        startDate: false,
        endDate: false,
      })));
    }, 1000);

    if (prefillData?.assignment_marks_schemas?.length > 0) {
      setErrorSchemaRemoved('');
    } else {
      setErrorSchemaRemoved(validSemesterYear ? t('SEMESTER_SCHEMA_REMOVED') : t('QUARTER_SCHEMA_REMOVED'));
      setSuccessMsg('');
    }
    if (prefillData?.academic_panel_marks?.length > 0) {
      setErrorSchemaRemoved('');
    }

    setAssignmentMarksCopy(prefillData?.assignment_marks_schemas || []);
    setUpdateSchema(prefillData.id);

    setLoading(false);
  };

  const checkIfSchemeCanBeEdited = () => {
    let editable = false;
    if (quarterDetails) {
      const { academicYearQuarterDates } = quarterDetails;

      academicYearQuarterDates.forEach((quarterItem) => {
        if (quarterItem.quarter === formik.values.quarter || quarterItem.quarter === formik.values.semester) {
          editable = isValidDate(quarterItem.endDate);
        }
      });
    }
    if (!editable) {
      setErrorSchemaRemoved(validSemesterYear ? t('SEMESTER_SCHEMA_REMOVED') : t('QUARTER_SCHEMA_REMOVED'));
    }
    return editable;
  };

  const schemaCanBeEdited = useMemo(checkIfSchemeCanBeEdited, [quarterDetails, formik.values.semester, formik.values.quarter]);

  useEffect(() => {
    if (schema === null) {
      return;
    }

    prefillSchema(schema);
  }, [schema]);

  useEffect(() => {
    if (formik.values.semester === '') {
      setError('');
    } else if (!validSemesterYear) {
      setError(t('ASSIGNMENTS_YEAR_ERROR'));
      setErrorSchemaRemoved('');
      setSuccessMsg('');
    } else if (!schemaCanBeEdited && schemaCanBeEdited !== null) {
      setError(t('ASSIGNMENTS_SCHEMA_ERROR'));
      setErrorSchemaRemoved('');
      setSuccessMsg('');
    } else {
      setError('');
    }
  }, [schemaCanBeEdited, formik.values.semester, formik.values.courses, validSemesterYear]);

  useEffect(() => {
    if (formik.values.academicYear) {
      setValidSemesterYear(isValidSemesterYear(formik.values.academicYear));
      formik.setTouched({}, false);
      const selectedYear = (assignedYears?.length > 0) ? assignedYears.find((year) => year.id === formik.values.academicYear)?.name : formik.values.academicYear;
      dispatch(getQuarterDetails(selectedYear));
    }
  }, [formik.values.academicYear]);

  const dependenciesForMarksValidation = formik.values.assignmentSchemaMarks.map(
    (ele) => ele.marks,
  );

  const calculateCourseTotal = () => {
    if (!formik.values.assignmentSchemaMarks.length) {
      return 0;
    }

    const total = formik.values.assignmentSchemaMarks.reduce((oldVal, currentEle) => (
      oldVal + (currentEle?.marks ? parseInt(currentEle.marks, 10) : 0)
    ), 0);

    return total;
  };

  useEffect(() => {
    /* eslint-disable no-else-return */
    const totalMarks = calculateCourseTotal();
    if ((formik.values.totalQuestions === 0) && (formik.values.maxMarks >= 1)) {
      setErrorMarks(t('TOTAL_WEEKS_ERROR'));
      setErrorSchemaRemoved('');
      setSuccessMsg('');
      return;
    }

    if (totalMarks === 0 || totalMarks === null || formik.values.maxMarks === '') {
      setErrorMarks('');
      return;
    }

    if (totalMarks !== formik.values.maxMarks) {
      setErrorMarks(t('MAX_MARKS_ERROR'));
      setErrorSchemaRemoved('');
      setSuccessMsg('');
    } else {
      setErrorMarks('');
    }
  }, [dependenciesForMarksValidation, formik.values.maxMarks]);

  const deleteAcademiMarksEntry = (index) => {
    setAssignmentMarksCopy((oldMarks) => {
      const clonedMarks = [...oldMarks];
      clonedMarks.splice(index, 1);
      formik.setFieldValue('assignmentSchemaMarks', clonedMarks, false);
      return clonedMarks;
    });
  };

  const setTotalQuestions = (questions, index) => {
    formik.setFieldValue('totalQuestions', questions);
    deleteAcademiMarksEntry(index);
  };

  const deletedRows = useMemo(() => {
    if (formik.values.assignmentSchemaMarks.length) {
      let count = 0;
      formik.values.assignmentSchemaMarks.forEach((ele) => {
        if (ele?.deletedAt) {
          count += 1;
        }
      });

      return count;
    }

    return null;
  }, [formik.values.assignmentSchemaMarks]);

  useEffect(() => {
    const marksLength = formik.values.assignmentSchemaMarks.length - deletedRows;

    if (!formik.values.totalQuestions && formik.values.totalQuestions !== 0) {
      return;
    }

    if (formik.values.totalQuestions < marksLength) {
      const countDiff = marksLength - formik.values.totalQuestions;

      for (let i = 1; i <= countDiff; i++) {
        deleteAcademiMarksEntry(marksLength - i);
      }
    }

    if (formik.values.totalQuestions > formik.values.assignmentSchemaMarks.length && formik.values.totalQuestions < 21) {
      const countDiff = formik.values.totalQuestions - formik.values.assignmentSchemaMarks.length;

      const newEntries = [];

      for (let i = 1; i <= countDiff; i++) {
        newEntries.push({
          week: '',
          marks: '',
          notes: '',
          startDate: '',
          endDate: '',
        });
      }

      const allEntries = [...formik.values.assignmentSchemaMarks, ...newEntries];

      formik.setFieldValue('assignmentSchemaMarks', allEntries);
      setAssignmentMarksCopy(allEntries);
    }
  }, [formik.values.totalQuestions]);

  const changeAssignmentMarks = (row, key, value) => {
    const clonedMarks = [...formik.values.assignmentSchemaMarks];

    if (clonedMarks.length <= row) {
      clonedMarks[row] = {
        [key]: value,
      };
    }
    clonedMarks[row][key] = value;

    formik.setFieldValue('assignmentSchemaMarks', clonedMarks);
    setAssignmentMarksCopy(clonedMarks);
  };

  const courseFieldTotal = useMemo(
    () => calculateCourseTotal() || 0,
    [formik.values.assignmentSchemaMarks, formik.values.totalQuestions],
  );

  useEffect(() => {
    const { totalQuestions } = formik.values;
    if (totalQuestions > 21) {
      formik.values.totalQuestions = 20;
    }
  }, [formik.values.totalQuestions]);

  return (
    <Box p={2}>
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
      <Box padding={3}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              {t('ASSIGNMENTS_SCHEMA')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box class={classes.contentCont}>
        <Box class={classes.content}>
          <Box m={3}>
            <FormikProvider value={formik}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} className={classes.formControlGridDropdown}>
                  <DropdownCurricular
                    label={`${t('COURSE')} *`}
                    id="courses"
                    name="courses"
                    labelId="courses"
                    disabled={false}
                    value={formik.values.courses}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    options={courses}
                    shrink
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className={classes.formControlGridDropdown}>
                  <DropdownCurricular
                    label={`${t('ACADEMICYEAR')} *`}
                    id="academicYear"
                    name="academicYear"
                    labelId="academicYear"
                    disabled={false}
                    value={formik.values.academicYear}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    options={assignedYears}
                    shrink
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className={classes.formControlGridDropdown}>
                  <DropdownCurricular
                    label={`${t('SEMESTER')} *`}
                    id="semester"
                    name="semester"
                    labelId="semester"
                    value={formik.values.semester}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    options={semesterOptions.map((sem) => ({
                      id: sem.id,
                      name: sem.key,
                    }))}
                    shrink
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
                  <TextField
                    label={t('LABEL')}
                    id="schemaLabel"
                    labelId="schemaLabel"
                    name="schemaLabel"
                    required
                    type="text"
                    disabled={!schemaCanBeEdited}
                    value={formik.values.schemaLabel}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    size="small"
                    inputProps={{ style: styles.textFieldLabel }}
                    InputLabelProps={{ style: styles.textFieldLabel }}
                    error={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
                    helperText={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
                  <TextField
                    label={t('WEIGHTAGE')}
                    required
                    id="weightage"
                    name="weightage"
                    labelId="weightage"
                    type="number"
                    disabled={!schemaCanBeEdited}
                    value={formik.values.weightage}
                    onKeyPress={(e) => restrictKeys(e, true)}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    size="small"
                    inputProps={{ min: '0', style: styles.textFieldLabel }}
                    InputLabelProps={{ style: styles.textFieldLabel }}
                    error={formik.touched.weightage ? formik.errors.weightage : undefined}
                    helperText={formik.touched.weightage ? formik.errors.weightage : undefined}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
                  <TextField
                    label={t('TOTAL_ASSIGNMENTS')}
                    required
                    id="totalQuestions"
                    name="totalQuestions"
                    labelId="totalQuestions"
                    type="number"
                    disabled={!schemaCanBeEdited}
                    value={formik.values.totalQuestions < 21 ? formik.values.totalQuestions : 20}
                    onKeyPress={restrictKeys}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    size="small"
                    inputProps={{ min: '0', style: styles.textFieldLabel, max: '20' }}
                    InputLabelProps={{ style: styles.textFieldLabel }}
                    error={formik.touched.totalQuestions ? formik.errors.totalQuestions : undefined}
                    helperText={formik.touched.totalQuestions ? formik.errors.totalQuestions : undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
                  <TextField
                    label={t('MAXIMUM_MARKS')}
                    id="maxMarks"
                    labelId="maxMarks"
                    name="maxMarks"
                    required
                    type="number"
                    disabled={!schemaCanBeEdited}
                    value={formik.values.maxMarks}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyPress={restrictKeys}
                    size="small"
                    inputProps={{ min: '0', style: styles.textFieldLabel }}
                    InputLabelProps={{ style: styles.textFieldLabel }}
                    error={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
                    helperText={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
                  />
                </Grid>

              </Grid>

              {error && (
                <Box style={styles.errorCont}>
                  <Typography style={styles.errorText} variant="text">
                    {error}
                  </Typography>
                </Box>
              )}

              {errorMarks && (
                <Box style={styles.errorCont}>
                  <Typography style={styles.errorText} variant="text">
                    {errorMarks}
                  </Typography>
                </Box>
              )}

              {successMsg && (
                <Box style={styles.successCont}>
                  <Typography style={styles.successText} variant="text">
                    {successMsg}
                  </Typography>
                </Box>
              )}

              {errorSchemaRemoved && (
                <Box style={styles.errorCont}>
                  <Typography style={styles.errorText} variant="text">
                    {saveClicked === true ? errorSchemaRemoved : ''}
                  </Typography>
                </Box>
              )}

              <RenderCategoryField
                questions={formik.values.totalQuestions || 0}
                {...{ setTotalQuestions, changeAssignmentMarks }}
                handleSave={formik.handleSubmit}
                courseFieldData={formik.values.assignmentSchemaMarks}
                courseFieldErrors={formik.errors.assignmentSchemaMarks}
                courseFieldTouched={formik.touched.assignmentSchemaMarks}
                showSaveBtn={courseFieldTotal === formik.values.maxMarks && schemaCanBeEdited}
                schemaCanBeEdited={schemaCanBeEdited}
                useGetCourseRows={useGetCourseRows}
                restrictKeys={restrictKeys}
                refreshSchema={refreshSchema}
                quarterDates={getQuarterStartEndDate()}
                findValidPrevNextIndices={findValidPrevNextIndices}
                setError={setError}
                setSuccessMsg={setSuccessMsg}
                setErrorSchemaRemoved={setErrorSchemaRemoved}
                setSaveClicked={setSaveClicked}
              />
            </FormikProvider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AssignmentsSchema;
