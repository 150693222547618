export const exportExamCenterColumns = [
  {
    id: 'name',
    label: 'Exam Location Name',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'city',
    label: 'City',
  },
  {
    id: 'isActive',
    label: 'Status',
  },
];

export default {
  exportExamCenterColumns,
};
