import Constant from '../constant';

const initialState = {
  coursesData: [],
  TotalCoursesManager: 0,
  courses: [],
  coursesDouble: [],
};

const getCourseManager = (state = initialState, action = {}) => {
  switch (action.type) {
    case Constant.GET_COURSES_MANAGER:
      return {
        ...state,
        coursesData: action.payload,
      };
    case Constant.TOTAL_COURSES_MANAGER:
      return {
        ...state,
        TotalCoursesManager: action.payload,
      };
    case Constant.GET_COURSES_MANAGER_PAGINATION:
      return {
        ...state,
        coursesData: state.coursesData?.concat(action.payload),
      };
    default:
      return state;
  }
};

export default getCourseManager;
