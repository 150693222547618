import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import AttendanceFilters from './attendance-filter';
import AttendanceCard from './attendance-card/attendance-card';
import { Button } from '../../../components/atoms';
import commonStyle from '../../../utils/commonClasses';
import {
  getAssignedLocations,
} from '../../../store/actions/getLocationCoordinator';
import {
  getclassesTimings, getTeachetPanelStudents,
  classesByYear, markStudentAttendance,
} from '../../../store/actions/getTeacherView';
import { getClassDetails } from './helper';
import { getCurrentYear } from '../../../utils/methods';

export default function TeacherAttendanceView() {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commonClasses = useStyles(commonStyle)();
  const reduxStore = useSelector((state) => state?.getLocationCoordinator);
  const assignedYears = reduxStore?.assignedYears;
  const reduxClass = useSelector((state) => state?.getTeacherView);
  const assignedClass = reduxClass?.classesTimings;
  const assignedClassrooms = reduxClass?.classesByYear;
  const assignedTeacherPanelStudents = reduxClass?.assignedTeachetPanelStudents;
  const [absentStudentIds, setAbsentStudentIds] = useState([]);
  const [presentStudentIds, setPresentStudentIds] = useState([]);
  const [isAttendanceRecording, setIsAttendanceRecording] = useState(false);
  const [years, setYearsData] = useState([]);
  const [courses, setCoursesData] = useState([]);
  const [classWeek, setclassWeekData] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [homeworkPanelId, setHomeworkPanelId] = useState('');
  const [homeworkPanelMarksId, setHomeworkPanelMarksId] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState('');
  const [initialAbsentStudentIds, setInitialAbsentStudentIds] = useState([]);
  const [initialPresentStudentIds, setInitialPresentStudentIds] = useState([]);
  const validationSchema = Yup.object({
  });

  const reloadData = () => {
    setLoading(true);
    setStudentList([]);
  };

  const formik = useFormik({
    initialValues: {
      year: years[0]?.id,
      course: courses[0]?.id,
      classWeek: classWeek[0]?.id,
    },
    validationSchema,
    onSubmit: () => {
    },
  });

  useEffect(() => {
    dispatch(getAssignedLocations());
  }, []);
  useEffect(() => {
    setYearsData(assignedYears);
    const currentYear = getCurrentYear()?.id;
    const selectedYear = assignedYears.filter((opt) => opt?.id === currentYear.toString());
    if (reduxClass?.selectedYear?.name) {
      formik.setFieldValue('year', reduxClass?.selectedYear?.name);
    } else {
      formik.setFieldValue('year', selectedYear[0]?.id);
    }
  }, [assignedYears]);

  useEffect(() => {
    if (!formik.values.year) {
      return;
    }
    reloadData();
    const payload = {
      academicYear: formik.values.year,
    };
    dispatch(classesByYear(payload, setLoading));
  }, [formik.values.year]);

  useEffect(() => {
    const classRooms = assignedClassrooms.map((obj) => ({
      id: obj?.googleClassId,
      name: `${obj?.name}(${obj?.sectionName}) - ${obj?.locationName}`,
      courseId: obj?.courseId,
      locationId: obj?.locationId,
    }));
    setCoursesData(classRooms);
    if (reduxClass?.selectedClassroom?.value) {
      formik.setFieldValue('course', reduxClass?.selectedClassroom?.value);
    } else {
      formik.setFieldValue('course', classRooms[0]?.id);
    }
  }, [assignedClassrooms]);

  const refreshList = (payload) => {
    if (homeworkPanelMarksId?.length) {
      payload.homeworkPanelMarksId = homeworkPanelMarksId;
    }
    reloadData();
    dispatch(getTeachetPanelStudents(payload, setLoading));
    setIsAttendanceRecording(false);
    setAbsentStudentIds([]);
  };
  useEffect(() => {
    if (formik.values.course && formik.values.year) {
      setHomeworkPanelId('');
      setHomeworkPanelMarksId('');
      const courseItem = courses.find((item) => item.id === formik.values.course);
      if (courseItem?.courseId && courseItem?.locationId) {
        const payload = {
          locationId: courseItem?.locationId,
          courseId: courseItem?.courseId,
          academicYear: formik.values.year,
          courseCompleted: false,
          isActive: true,
          googleClassId: courseItem?.id,
        };
        refreshList(payload);
      } else {
        reloadData();
      }
      if (courseItem?.courseId && formik.values.year) {
        const payload = {
          courseId: courseItem?.courseId,
          academicYear: formik.values.year,
        };
        dispatch(getclassesTimings(payload, setLoading));
      } else {
        setLoading(false);
      }
    }
  }, [formik.values.course, formik.values.year]);

  useEffect(() => {
    if (!assignedClass.length) {
      setclassWeekData([]);
    } else {
      setclassWeekData(getClassDetails(assignedClass));
    }
  }, [assignedClass, reduxClass?.classesTimings]);

  useEffect(() => {
    formik.setFieldValue('classWeek', classWeek[0]?.id);
  }, [classWeek]);

  useEffect(() => {
    if (!formik.values.classWeek) {
      return;
    }
    const classWeekDetails = classWeek.filter((obj) => obj.id === formik.values.classWeek);
    if (classWeekDetails?.[0]?.homeworkPanelId !== undefined) {
      setHomeworkPanelId(classWeekDetails?.[0]?.homeworkPanelId);
      setHomeworkPanelMarksId(classWeekDetails?.[0]?.id);
    } else {
      setclassWeekData([]);
    }
  }, [formik.values.classWeek]);

  useEffect(() => {
    const courseItem = courses.find((item) => item.id === formik.values.course);
    if (courseItem?.courseId && courseItem?.locationId && courseItem?.id !== courseId) {
      setCourseId(courseItem?.id);
      const payload = {
        locationId: courseItem?.locationId,
        courseId: courseItem?.courseId,
        academicYear: formik.values.year,
        courseCompleted: false,
        isActive: true,
        googleClassId: courseItem?.id,
      };
      refreshList(payload);
    }
    if (courseItem?.courseId && courseItem?.locationId && selectedWeek !== formik.values.classWeek) {
      setSelectedWeek(formik.values.course);
      const payload = {
        locationId: courseItem?.locationId,
        courseId: courseItem?.courseId,
        academicYear: formik.values.year,
        courseCompleted: false,
        isActive: true,
        googleClassId: courseItem?.id,
      };
      refreshList(payload);
    }
  }, [homeworkPanelId, homeworkPanelMarksId]);

  useEffect(() => {
    setStudentList(assignedTeacherPanelStudents?.filter((item) => item?.student));
    setInitialAbsentStudentIds(absentStudentIds);
    setInitialPresentStudentIds(presentStudentIds);
  }, [assignedTeacherPanelStudents]);

  const softRefresh = () => {
    setAbsentStudentIds(initialAbsentStudentIds);
    setPresentStudentIds(initialPresentStudentIds);
  };

  const onCancel = () => {
    setIsAttendanceRecording(false);
    softRefresh();
  };

  const submitAttendance = () => {
    const payload = [];
    setIsAttendanceRecording(false);
    reloadData();
    const quarter = classWeek.find((item) => item.id === formik.values.classWeek);
    const quarterName = quarter.name.split(' | ')[2];
    const courseItem = courses.find((item) => item.id === formik.values.course);
    absentStudentIds?.forEach((studentId) => {
      payload.push({
        homeworkPanelId,
        homeworkPanelMarksId,
        studentId,
        isPresent: false,
        courseId: courseItem?.courseId,
        academicYear: formik.values.year,
        quarter: quarterName,
      });
    });
    /* eslint-disable no-else-return */
    const aPresentStudentIds = presentStudentIds.filter((item) => {
      if (absentStudentIds.length !== 0) {
        for (let i = 0; i < absentStudentIds.length; i++) {
          if (item !== absentStudentIds[i]) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    });
    aPresentStudentIds?.forEach((studentId) => {
      payload.push({
        homeworkPanelId,
        homeworkPanelMarksId,
        studentId,
        isPresent: true,
        courseId: courseItem?.courseId,
        academicYear: formik.values.year,
        quarter: quarterName,
      });
    });

    const refreshListPayload = {
      locationId: courseItem?.locationId,
      courseId: courseItem?.courseId,
      academicYear: formik.values.year,
      courseCompleted: false,
      isActive: true,
      googleClassId: courseItem?.id,
    };

    dispatch(markStudentAttendance(payload, refreshList, refreshListPayload));
  };

  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('ATTENDANCE')}
          </Typography>
        </Grid>
      </Grid>
      <AttendanceFilters
        formik={formik}
        classes={classes}
        courses={courses}
        years={years}
        classWeeks={classWeek}
        noOfAbsentStudents={absentStudentIds.length}
        isAttendanceRecording={isAttendanceRecording}
        setIsAttendanceRecording={setIsAttendanceRecording}
        studentList={studentList}
        setAbsentStudentIds={(ids) => {
          setAbsentStudentIds(ids);
          setInitialAbsentStudentIds(ids);
        }}
        setPresentStudentIds={(ids) => {
          setPresentStudentIds(ids);
          setInitialPresentStudentIds(ids);
        }}
      />
      <Grid container className={classes.card} item lg={12}>
        {
          studentList.map((student) => (
            <AttendanceCard
              isStudentAbsent={absentStudentIds.includes(student.studentId)}
              studentDetail={student}
              onCardClick={(studentDetail) => {
                if (!isAttendanceRecording) return;
                const localAbsentStudentIds = [...absentStudentIds];
                const localPresentStudentIds = [...presentStudentIds];
                if (localAbsentStudentIds.includes(studentDetail.studentId)) {
                  const index = localAbsentStudentIds.indexOf(studentDetail.studentId);
                  if (index > -1) {
                    localAbsentStudentIds.splice(index, 1);
                    localPresentStudentIds.push(studentDetail.studentId);
                  }
                } else {
                  const index = localPresentStudentIds.indexOf(studentDetail.studentId);
                  if (index > -1) {
                    localPresentStudentIds.splice(index, 1);
                  }
                  localAbsentStudentIds.push(studentDetail.studentId);
                }
                setPresentStudentIds([...localPresentStudentIds]);
                setAbsentStudentIds([...localAbsentStudentIds]);
              }}
            />
          ))
        }
        {((studentList.length === 0 || assignedClassrooms.length === 0) && !loading) && (
          <Grid container className={classes.noData}>
            <Grid item xs={12}>
              <DoNotDisturbIcon />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                {assignedClassrooms.length === 0 ? (
                  t('NO_CLASSES_AVAILABLE')
                ) : (
                  t('NO_DATA')
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        {(studentList.length === 0 && loading) && (
          <Grid container className={classes.noData}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </Grid>
      {isAttendanceRecording ? (
        <Grid container>
          <Grid item xs={7} />
          <Grid item xs={5}>
            <div className={classes.buttons}>
              <Button
                id="cancel"
                className={commonClasses.secButton}
                onClick={onCancel}
                name={t('CANCEL')}
              />
              <Button
                id="submit"
                className={commonClasses.activeButton}
                onClick={() => submitAttendance()}
                name={t('SUBMIT')}
              />
            </div>
          </Grid>
        </Grid>
      ) : <div />}
    </Box>
  );
}
