/* eslint-disable no-nested-ternary */
/* eslint-disable */
import React, {
  useCallback, useContext,
  useEffect, useMemo, useState,
} from 'react';
import {
  Grid, Typography, TextField, Popover,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  useNavigate,
} from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/fontawesome-free-solid';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import moment from 'moment';
import { ThemeProvider } from '@mui/material/styles';
import Menu from '../helpers/menu';
import { Button } from '../../../../components/atoms';
import { Buttons, NavigateRoutes } from '../../../../constant';
import MailIcon from '../../../../assets/svg/mailIcon';
import UserIcon from '../../../../assets/svg/userIcon';
import CommonProfileImage from '../../../../components/molecules/common/CommonProfileImage';
import { impersonateUser } from '../../../../store/actions/getAdmin';
import Constant from '../../../../store/constant';
import {
  getMarksDetails,
  getStudentEnrollmentHistory,
} from '../../../../store/actions/getStudent';
import Loader from '../../../../components/atoms/loader';
import useStudent from '../../../../custom-hooks/useStudent';
import { useStyles } from './style';
import { useRegionStyles } from './region-style';
import { getLocalStorage, setLocalStorage } from '../../../../utils/localStorageMethod';
import userRoles from '../../../../constant/userRoles';
import StudentContext from '../../../../contexts/StudentProvider';
import useLocationCoordinator from '../../../../custom-hooks/useLocationCoordinator';
import ButtonAtom from '../../../../components/atoms/button';
import DialogAtom from '../../../../components/atoms/dialog';
import { makeUpMarks } from '../../../../store/actions/getTeacherView';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';
import BonusMarks from '../../../location-coordinator/bonus-marks';
import { toolTipTheme } from '../../../../utils/commonUiComponent';
import {
  calculateAnnualScore,
  getSemester3Title,
  isNullOrUndefined,
  isValidSemesterYear,
} from '../../../../utils/methods';

function Row(props) {
  const {
    row,
    index,
    selectedFilter,
    handleImpersonate,
    onClickCancelEnrollMenu,
    onClickEditExamDetails,
    paymentDialogOpen,
    editExamDetailsOpen,
    onClickMenu,
    setSwapCourseOrLocation,
    recoveryDialogOpen,
    onEditMenuClick,
    onUpdateParentClick,
    setEditStudentProfile,
    showStudentLogs,
    loadingSpinner,
    setLoadingSpinner,
    isSelected,
    handleCheck,
    setOpenMarksPopUp,
    setDataAssignScore,
    setAssignLoading,
    sortBoth,
    setRepeatStatusOpen,
    studentsCount,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const [open, setOpen] = React.useState(false);
  const coordinatorInfo = useLocationCoordinator();
  const studentsByLocation = coordinatorInfo?.students;
  localStorage.removeItem('sendSingleEmail');
  localStorage.removeItem('userManager', true);

  let isItemSelected = false;
  if (sortBoth) {
    isItemSelected = false;
  } else {
    isItemSelected = isSelected(row.id);
  }
  const labelId = `enhanced-table-checkbox-${index}`;
  const [updateMakupMarks, setUpdateMakupMarks] = useState(false);
  const [setFullName, isSetFullName] = useState();
  const [setSetCourseName, isSetCourseName] = useState();
  const [setQuarterName, isSetQuarterName] = useState();
  const [setRowData, isSetRowData] = useState();
  const [setQuarterValue, isSetQuarterValue] = useState();
  const [showMakeupMarks, setShowMakeupMarks] = useState();
  const [passingCriteria, setPassingCriteria] = useState();
  const [updateTypeData, setUpdateType] = useState();
  const [number, setNumber] = useState();
  const [maxNumber, setMaxNumber] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBonusMarks, setIsBonusMarks] = useState(false);
  const [isValidSemester, setValidSemester] = useState(false);
  const [acadYear, setAcadYear] = useState();

  // only used to refresh state when updating marks
  // eslint-disable-next-line no-unused-vars
  const [refreshState, setRefreshState] = useState(false);

  const open1 = Boolean(anchorEl);
  const id = open1 ? 'simple-popover' : undefined;

  useEffect(() => {
    if (studentsByLocation.length !== 0) {
      if (row.id === studentsByLocation[0].id) {
        row.studentsMarks = studentsByLocation;
        setRefreshState((state) => !state);
      }
    }
  }, [studentsByLocation, studentsCount]);

  useEffect(() => {
    if (loadingSpinner === false && row.studentsMarks.length === 0) {
      setOpen(false);
    }
  }, [studentsCount]);

  const MarksDetailsOpen = (rowInfo) => {
    setOpen(true);
    setLoadingSpinner(true);
    const selectedAcademicYear = selectedFilter.find((filterItem) => filterItem?.filterKey === 'academic_year');
    setAcadYear(selectedAcademicYear?.label);
    setValidSemester(isValidSemesterYear(selectedAcademicYear.label));
    dispatch(getMarksDetails({
      academicYear: rowInfo.enrolled_courses.academicYear || selectedAcademicYear?.label ? selectedAcademicYear?.label : '',
      locationId: rowInfo.enrolled_courses.location.id,
      courseId: rowInfo.enrolled_courses.course.id,
      studentId: rowInfo.id,
    }, setLoadingSpinner));
  };
  const onClickRepeatStatus = () => {
    setRepeatStatusOpen(true);
  };
  const openAssign = (fullName, courseName, quarterName, rowData, quarterValue, updateType, quizName) => {
    setAssignLoading(true);

    if (updateType === 'bonus') {
      setIsBonusMarks(true);
      isSetRowData(rowData);
      setAnchorEl(null);
      return;
    }

    setOpenMarksPopUp(true);
    setAnchorEl(null);
    const aGetCourseName = studentsByLocation.map((item) => item.enrolled_courses[0].courseName);
    const aCourseName = aGetCourseName.filter((item) => item !== undefined);
    const selectedAcademicYear = selectedFilter.find((filterItem) => filterItem?.filterKey === 'academic_year');
    setAcadYear(selectedAcademicYear?.label);
    const validSemesterYear = isValidSemesterYear(selectedAcademicYear?.label);
    const semesterOptions = (courseName === 'BalaBadi - I' || courseName === 'BalaBadi - II'
      || courseName === 'Telugu-3' || courseName === 'Telugu-1') ? [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }]
      : (courseName === 'Prakasam' || courseName === 'Prabhasam' || courseName === 'Telugu-2' || courseName === 'Telugu-4') ? [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }, { id: 'S3', name: 'PSTU' }]
        : [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }, { id: 'S3', name: 'SUPPLEMENTARY' }];
    const aQuarter = validSemesterYear ? (updateType === 'marks' ? semesterOptions : [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }]) : [{ id: 'Q1', name: 'Q1' }, { id: 'Q2', name: 'Q2' }, { id: 'Q3', name: 'Q3' }];
    setDataAssignScore({
      fullName,
      courseName,
      quarterName,
      rowData,
      quarterValue,
      aCourseName,
      aQuarter,
      setAssignLoading,
      updateType,
      quizName,
      validSemesterYear,
    });
    if (updateType === 'homework') {
      const payload = {
        studentId: rowData.id,
        courseId: rowData.enrolled_courses.course.id,
        academicYear: rowData.enrolled_courses.academicYear || selectedAcademicYear?.label ? selectedAcademicYear?.label : '',
        quarter: quarterValue,
      };
      dispatch(getStudentEnrollmentHistory(payload, setAssignLoading));
    } else {
      const payload = {
        studentId: rowData.id,
        courseId: rowData.enrolled_courses.course.id,
        academicYear: rowData.enrolled_courses.academicYear || selectedAcademicYear?.label ? selectedAcademicYear?.label : '',
        quarter: quarterValue,
      };
      dispatch(getStudentEnrollmentHistory(payload, setAssignLoading));
    }
  };

  const setOpenDialog = (event, dataFullName, dataCourseName, dataQuarterName, dataRowData, dataQuarterValue, dataUpdateType) => {
    if (dataCourseName === 'BalaBadi - I' || dataCourseName === 'BalaBadi - II') {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
    isSetFullName(dataFullName);
    isSetCourseName(dataCourseName);
    isSetQuarterName(dataQuarterName);
    isSetRowData(dataRowData);
    isSetQuarterValue(dataQuarterValue);
    setUpdateType(dataUpdateType);
    if (dataUpdateType === 'homework') {
      setShowMakeupMarks(dataRowData?.studentsMarks[0]?.homeworkMarks[dataQuarterValue]?.obtainedMarks);
    } else {
      setShowMakeupMarks(dataRowData?.studentsMarks[0]?.marks[dataQuarterValue]?.obtainedMarks);
      setPassingCriteria(dataRowData?.studentsMarks[0]?.marks[dataQuarterValue]?.passingCriteria);
    }
  };

  const handleUpdateMakupMarksClose = () => {
    setUpdateMakupMarks(false);
  };

  const bonusMarksClose = () => {
    setIsBonusMarks(false);
  };

  const handleUpdateMakupMarksOpen = () => {
    const maxAddNumber = passingCriteria - showMakeupMarks;
    setMaxNumber(parseFloat(maxAddNumber).toFixed(1));
    setUpdateMakupMarks(true);
    setAnchorEl(null);
    setNumber('');
  };

  const validateNumber = (event) => {
    const value = parseFloat(event.target.value) || event.target.value;
    const setValue = value <= maxNumber ? event.target.value : number;
    const num = setValue.split('.');
    if (num.length > 2) return;
    if (/^\d+$/.test(num[0])) {
      if (num[1]) {
        if (/^\d+$/.test(num[1])) {
          if (num[1].length > 2) {
            setNumber(Number(setValue).toFixed(2));
          } else {
            setNumber(setValue);
          }
        }
      } else {
        setNumber(setValue);
      }
    } else if (setValue === '') {
      setNumber(setValue);
    }
  };

  const handleUpdateMakupMarks = (updateMakeUpData) => {
    if (number) {
      const payload = {
        courseId: updateMakeUpData?.enrolled_courses?.course?.id,
        studentId: updateMakeUpData?.id,
        academicPanelId: updateMakeUpData?.studentsMarks[0]?.marks[setQuarterValue]?.academicPanelId,
        academicYear: updateMakeUpData?.enrolled_courses?.academicYear,
        quarter: setQuarterValue,
        makeUpMarks: Number(number),
      };
      dispatch(makeUpMarks(payload));
      setUpdateMakupMarks(false);
      setTimeout(() => {
        MarksDetailsOpen(updateMakeUpData);
      }, 2000);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderUpdateMakupMarksContent = () => (
    <Grid>
      <Typography>
        {t('MAKEUP_MARKS')}
        {' '}
        (Max:
        {' '}
        {maxNumber}
        ):
      </Typography>
      <div className={classes.addMakupMarksPopup}>
        <TextField
          id="outlined-number"
          onChange={validateNumber}
          value={number}
          error={number < 0}
          helperText={number < 0 ? 'Value should be greater than 0' : ''}
          InputProps={
            {
              inputProps: {
                min: 0,
                max: maxNumber,
                pattern: '[0-9]*',
                step: '1',
                inputMode: 'numeric',
              },
            }
          }
          onKeyDown={(event) => {
            if ((event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e')) {
              event.preventDefault();
            }
          }}
          required
        />
      </div>
      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'end', padding: '40px 0 15px' }}>
          <div className={classes.popupBtn}>
            <ButtonAtom
              className={classes.popupBtn}
              name={t('CANCEL')}
              btntype={Buttons.SECONDARY}
              onClick={() => {
                setUpdateMakupMarks(false);
              }}
            />
          </div>
          <div className={number < 0 ? classes.popupBtnDisable : ''}>
            <ButtonAtom
              name={t('SAVE')}
              btntype={Buttons.PRIMARY}
              onClick={() => handleUpdateMakupMarks(setRowData)}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );

  const isValidAnnualScore = (student) => {
    if (isValidSemester) {
      return ((isNullOrUndefined(student?.marks?.S1?.isAttended) && isNullOrUndefined(student?.marks?.S2?.isAttended)) || isNullOrUndefined(student?.marks?.S3?.isAttended));
    }
    return (student?.marks?.Q1?.isAttended || student?.marks?.Q2?.isAttended || student?.marks?.Q3?.isAttended);
  };

  return (
    <>
      <TableRow
        onClick={() => handleCheck(row)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox" className={classes.tableCellCheck}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCell>
        <TableCell
          className={classes.tableCellPictures}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          <CommonProfileImage key={row.id} src={row.pictures} />
        </TableCell>
        <TableCell className={classes.tableCellStudent}>
          {row.studentName}
        </TableCell>
        <ThemeProvider theme={toolTipTheme}>
          <Tooltip title={`${row.parentName}`}>
            <TableCell className={classes.tableCellParent}>
              {row.parentName}
            </TableCell>
          </Tooltip>
        </ThemeProvider>
        <TableCell className={classes.tableCellCenter}>
          {row.locationInfo}
        </TableCell>
        <TableCell className={classes.tableCellClass}>
          {row.classLevel}
        </TableCell>
        <TableCell className={classes.tableCellSection}>
          {row.section}
        </TableCell>
        <TableCell className={classes.tableCellDate}>
          {moment(row.enrolled_courses?.createdAt).utc().format('MMM DD, YYYY')}
        </TableCell>
        <TableCell className={classes.tableCellAction}>
          <Grid item xs={12} display="flex" alignItems="center">
            {(userRole === userRoles.SYSTEM_ADMIN || userRole === userRoles.REGION_COORDINATOR
              || userRole === userRoles.SUPER_USER) && (
                <Tooltip title={t('SEND_EMAIL')}>
                  <IconButton
                    size="large"
                    className={classes.mailButton}
                    color="inherit"
                    onClick={(event) => {
                      event.stopPropagation();
                      const emailStudents = [];
                      const emailParents = [];
                      emailParents.push(row.parent1Info?.personalEmail?.toString());
                      emailParents.push(row.parent2Info?.personalEmail?.toString());
                      emailStudents.push(row.studentInfo?.manabadiEmail?.toString());
                      const filtersList = [];
                      selectedFilter?.forEach((obj) => {
                        filtersList.push(obj);
                      });
                      setLocalStorage('showSelectAllinEmail', false);
                      setLocalStorage('moduleReturn', 'student');
                      localStorage.setItem('sendSingleEmail', true);
                      dispatch({ type: Constant.RECIPIENTS, payload: emailStudents });
                      dispatch({ type: Constant.STUDENT_FILTERS, payload: [] });
                      dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
                      dispatch({ type: Constant.MAIL_PARENTS, payload: emailParents });
                      navigate(NavigateRoutes.ADMIN_EMAIL);
                    }}
                  >
                    <MailIcon />
                  </IconButton>
                </Tooltip>
            )}
            {(userRole === userRoles.SYSTEM_ADMIN || userRole === userRoles.SUPER_USER) ? (
              <Tooltip title={t('IMPERSONATE')}>
                <IconButton
                  size="large"
                  color="inherit"
                  onClick={(event) => {
                    handleImpersonate(row);
                    event.stopPropagation();
                  }}
                >
                  <UserIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Grid xs={2} />
            )}
            <Tooltip title={t('HELP')}>
              <Menu
                key={row?.id}
                t={t}
                rowInfo={row}
                {...{
                  onClickCancelEnrollMenu,
                  onClickEditExamDetails,
                  MarksDetailsOpen,
                  paymentDialogOpen,
                  editExamDetailsOpen,
                  onClickMenu,
                  setSwapCourseOrLocation,
                  recoveryDialogOpen,
                  onEditMenuClick,
                  onUpdateParentClick,
                  setEditStudentProfile,
                  showStudentLogs,
                  onClickRepeatStatus,
                }}
              />
            </Tooltip>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {!isValidSemester && (
                <Table size="small" aria-label="purchases" sx={{ minWidth: 500 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableMarksCourse} />
                      <TableCell className={classes.tableMarksCourse} />
                      <TableCell className={classes.tableMarksCourse} />
                      <TableCell className={classes.tableMarksHeaderMarks}>{t('MARKS')}</TableCell>
                      <TableCell className={classes.tableMarksCourse} />
                      <TableCell className={classes.tableMarksCourse} />
                      <TableCell className={classes.tableMarksHeaderHw} colSpan={9}>{t('HOMEWORK')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.expanAction}>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(!open)}
                        >
                          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell className={classes.tableMarksCourse}>{t('COURSE')}</TableCell>
                      <TableCell className={classes.tableMarksQ}>{t('Q1')}</TableCell>
                      <TableCell className={classes.tableMarksQ}>{t('Q2')}</TableCell>
                      <TableCell className={classes.tableMarksQ}>{t('Q3')}</TableCell>
                      <TableCell className={classes.tableMarksQ}>{t('Q1')}</TableCell>
                      <TableCell className={classes.tableMarksQ}>{t('Q2')}</TableCell>
                      <TableCell className={classes.tableMarksQ}>{t('Q3')}</TableCell>
                      <TableCell className={classes.tableMarks}>{t('BONUS_MARKS')}</TableCell>
                      <TableCell className={classes.tableMarks}>{t('GRADE')}</TableCell>
                      <TableCell className={classes.tableMarks}>{t('GPA')}</TableCell>
                      <TableCell className={classes.tableMarks}>{t('ANNUAL_SCORE')}</TableCell>
                    </TableRow>
                  </TableHead>
                  {loadingSpinner === true && row.studentsMarks.length === 0 && (
                    <Box sx={{ display: 'flex', position: 'absolute', paddingLeft: '45%' }} key={row.id}>
                      <CircularProgress />
                    </Box>
                  )}
                  {
                    row.studentsMarks.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell className={classes.tableMarksCourseR} />
                        <TableCell className={classes.tableMarksCourseR}>{item.enrolled_courses[0].course?.name}</TableCell>
                        <TableCell className={classes.tablesMarksQR}>
                          <Box
                            component="span"
                            className={(isNullOrUndefined(item.marks.Q1.passingCriteria) && !item.marks.Q1?.isAttended) ? classes.marksRed
                              : item.marks.Q1.obtainedMarks >= item.marks.Q1.passingCriteria ? classes.marksGreen
                                : item.marks.Q1.obtainedMarks < (item.marks.Q1.passingCriteria - 10) ? classes.marksRed
                                  : item.marks.Q1.obtainedMarks >= (item.marks.Q1.passingCriteria - 10) ? classes.marksYellow
                                    : classes.marksGrey}
                            onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.marks.Q1.obtainedMarks, row, 'Q1', 'marks')}
                          >
                            {isNullOrUndefined(item.marks.Q1.passingCriteria) && (item.marks.Q1.passingCriteria >= 0)
                              ? Number.isInteger(item.marks.Q1.obtainedMarks) ? item.marks.Q1.obtainedMarks : Number(item.marks.Q1.obtainedMarks).toFixed(2) : '-'}
                            {isNullOrUndefined(item.marks.Q1.passingCriteria) && !item.marks.Q1?.isAttended ? '(A)' : ''}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tablesMarksQR}>
                          <Box
                            component="span"
                            className={(isNullOrUndefined(item.marks.Q2.passingCriteria) && !item.marks.Q2?.isAttended) ? classes.marksRed
                              : item.marks.Q2.obtainedMarks >= item.marks.Q2.passingCriteria ? classes.marksGreen
                                : item.marks.Q2.obtainedMarks < (item.marks.Q2.passingCriteria - 10) ? classes.marksRed
                                  : item.marks.Q2.obtainedMarks >= (item.marks.Q2.passingCriteria - 10) ? classes.marksYellow
                                    : classes.marksGrey}
                            onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.marks.Q2.obtainedMarks, row, 'Q2', 'marks')}
                          >
                            {isNullOrUndefined(item.marks.Q2.passingCriteria) && item.marks.Q2.passingCriteria >= 0
                              ? Number.isInteger(item.marks.Q2.obtainedMarks) ? item.marks.Q2.obtainedMarks : Number(item.marks.Q2.obtainedMarks).toFixed(2) : '-'}
                            {isNullOrUndefined(item.marks.Q2.passingCriteria) && !item.marks.Q2?.isAttended ? '(A)' : ''}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tablesMarksQR}>
                          <Box
                            component="span"
                            className={(isNullOrUndefined(item.marks.Q3.passingCriteria) && !item.marks.Q3?.isAttended) ? classes.marksRed
                              : item.marks.Q3.obtainedMarks >= item.marks.Q3.passingCriteria ? classes.marksGreen
                                : item.marks.Q3.obtainedMarks < (item.marks.Q3.passingCriteria - 10) ? classes.marksRed
                                  : item.marks.Q3.obtainedMarks >= (item.marks.Q3.passingCriteria - 10) ? classes.marksYellow
                                    : classes.marksGrey}
                            onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.marks.Q3.obtainedMarks, row, 'Q3', 'marks')}
                          >
                            {isNullOrUndefined(item.marks.Q3.passingCriteria) && item.marks.Q3.passingCriteria >= 0
                              ? Number.isInteger(item.marks.Q3.obtainedMarks) ? item.marks.Q3.obtainedMarks : Number(item.marks.Q3.obtainedMarks).toFixed(2) : '-'}
                            {isNullOrUndefined(item.marks.Q3.passingCriteria) && !item.marks.Q3?.isAttended ? '(A)' : ''}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tablesMarksQR}>
                          <Box
                            component="span"
                            className={isNullOrUndefined(item.homeworkMarks.Q1.weightage) && item.homeworkMarks.Q1.weightage >= 0
                              ? classes.homeworkColor : classes.marksGrey}
                            onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.homeworkMarks.Q1.obtainedMarks, row, 'Q1', 'homework')}
                          >
                            {isNullOrUndefined(item.homeworkMarks.Q1.weightage) && item.homeworkMarks.Q1.weightage >= 0
                              ? Number.isInteger(item.homeworkMarks.Q1.obtainedMarks) ? item.homeworkMarks.Q1.obtainedMarks : Number(item.homeworkMarks.Q1.obtainedMarks).toFixed(2) : '-'}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tablesMarksQR}>
                          <Box
                            component="span"
                            className={isNullOrUndefined(item.homeworkMarks.Q2.weightage) && item.homeworkMarks.Q2.weightage >= 0
                              ? classes.homeworkColor : classes.marksGrey}
                            onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.homeworkMarks.Q2.obtainedMarks, row, 'Q2', 'homework')}
                          >
                            {isNullOrUndefined(item.homeworkMarks.Q2.weightage) && item.homeworkMarks.Q2.weightage >= 0
                              ? Number.isInteger(item.homeworkMarks.Q2.obtainedMarks) ? item.homeworkMarks.Q2.obtainedMarks : Number(item.homeworkMarks.Q2.obtainedMarks).toFixed(2) : '-'}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tablesMarksQR}>
                          <Box
                            component="span"
                            className={isNullOrUndefined(item.homeworkMarks.Q3.weightage) && item.homeworkMarks.Q3.weightage >= 0
                              ? classes.homeworkColor : classes.marksGrey}
                            onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.homeworkMarks.Q3.obtainedMarks, row, 'Q3', 'homework')}
                          >
                            {isNullOrUndefined(item.homeworkMarks.Q3.weightage) && item.homeworkMarks.Q3.weightage >= 0
                              ? Number.isInteger(item.homeworkMarks.Q3.obtainedMarks) ? item.homeworkMarks.Q3.obtainedMarks : Number(item.homeworkMarks.Q3.obtainedMarks).toFixed(2) : '-'}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tablesMarksQR}>
                          <Box
                            component="span"
                            className={classes.marksGreen}
                            onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.bonus, row, null, 'bonus')}
                          >
                            {item.bonus || 0}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tablesMarksQ}>{isValidAnnualScore(item) ? item.grade : 'N/A'}</TableCell>
                        <TableCell className={classes.tablesMarksQ}>{isValidAnnualScore(item) ? item.gpa : 'N/A'}</TableCell>
                        <TableCell className={classes.tablesMarksQ}>{isValidAnnualScore(item) ? calculateAnnualScore(item.enrolled_courses[0].course?.name, item?.marks?.Q3?.maximumMarks, item?.annualScore, item?.marks?.Q3?.obtainedMarks) : 'N/A'}</TableCell>
                      </TableRow>
                    ))
                  }
                </Table>
              )}
              {isValidSemester && (
                <Table size="small" aria-label="purchases" sx={{ minWidth: 500 }}>
                  {loadingSpinner === true && row.studentsMarks.length === 0 && (
                    <Box sx={{ display: 'flex', position: 'absolute', paddingLeft: '45%' }} key={row.id}>
                      <CircularProgress />
                    </Box>
                  )}
                  {
                    loadingSpinner === false && row.studentsMarks.length === 0 && (
                      <Box sx={{ display: 'flex', position: 'absolute', paddingLeft: '45%' }} key={row.id}>
                        No Data Available
                      </Box>
                    )
                  }
                  {
                    row.studentsMarks.map((item) => (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableMarksHeaderMarks} colSpan={9}>{t('S1')}</TableCell>
                            <TableCell className={classes.tableMarksHeaderHw} colSpan={9}>{t('S2')}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.expanActionSemester}>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                              >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>
                            <TableCell className={classes.tableMarksCourse}>{t('COURSE')}</TableCell>
                            <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '31px' }}>{t('EXAM')}</TableCell>
                            <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '31px' }}>{t('HW')}</TableCell>
                            <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '31px' }}>{t('QUIZ1')}</TableCell>
                            {acadYear === "2023-2024" && (
                              <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '31px' }}>{t('QUIZ2')}</TableCell>
                            )}
                            <TableCell className={classes.tableMarksQ}>{t('ASS..MENT')}</TableCell>
                            <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '0px', borderRight: '0.1vw solid #ccc' }}>{t('CO-CURR')}</TableCell>
                            <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '31px' }}>{t('EXAM')}</TableCell>
                            <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '31px' }}>{t('HW')}</TableCell>
                            <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '31px' }}>
                              {acadYear === "2023-2024" ? t('QUIZ3') : t('QUIZ2')}
                            </TableCell>
                            {acadYear === "2023-2024" && (
                              <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '31px' }}>{t('QUIZ4')}</TableCell>
                            )}
                            <TableCell className={classes.tableMarksQ}>{t('ASS..MENT')}</TableCell>
                            <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '0px' }}>{t('CO-CURR')}</TableCell>
                            <TableCell className={classes.tableMarksQ} style={{ paddingLeft: '10px' }}>{getSemester3Title(item.enrolled_courses[0].course?.name, t)}</TableCell>
                            <TableCell className={classes.tableMarksSemester} style={{ paddingLeft: '5px' }}>{t('GRADE')}</TableCell>
                            <TableCell className={classes.tableMarksSemester} style={{ paddingLeft: '5px' }}>{t('GPA')}</TableCell>
                            <TableCell className={classes.tableMarksSemester} style={{ paddingLeft: '5px' }}>{t('ANNUAL_SCORE')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={item.id}>
                            <TableCell className={classes.spaceFirst} />
                            <TableCell className={classes.tableMarksCourseR}>{item.enrolled_courses[0].course?.name}</TableCell>
                            <TableCell className={classes.tablesMarksQR}>
                              <Box
                                component="span"
                                className={(isNullOrUndefined(item.marks.S1.passingCriteria) && !item.marks.S1?.isAttended) ? classes.marksRed
                                    : item.marks.S1?.obtainedMarks >= item.marks.S1.passingCriteria ? classes.marksGreen
                                      : item.marks.S1?.obtainedMarks < (item.marks.S1.passingCriteria - 10) ? classes.marksRed
                                        : item.marks.S1?.obtainedMarks >= (item.marks.S1.passingCriteria - 10) ? classes.marksYellow
                                          : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.marks.S1?.obtainedMarks, row, 'S1', 'marks')}
                              >
                                {isNullOrUndefined(item.marks.S1.passingCriteria) && (item.marks.S1.passingCriteria >= 0)
                                  ? Number.isInteger(item.marks.S1?.obtainedMarks) ? item.marks.S1?.obtainedMarks : Number(item.marks.S1?.obtainedMarks).toFixed(2) : '-'}
                                {isNullOrUndefined(item.marks.S1.passingCriteria) && !item.marks.S1?.isAttended ? '(A)' : ''}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tablesMarksQR}>
                              <Box
                                component="span"
                                className={isNullOrUndefined(item.homeworkMarks.S1?.weightage) && item.homeworkMarks.S1?.weightage >= 0
                                  ? classes.homeworkColor : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.homeworkMarks.S1?.obtainedMarks, row, 'S1', 'homework')}
                              >
                                {isNullOrUndefined(item.homeworkMarks.S1?.weightage) && item.homeworkMarks.S1?.weightage >= 0
                                  ? Number.isInteger(item.homeworkMarks.S1?.obtainedMarks) ? item.homeworkMarks.S1?.obtainedMarks : Number(item.homeworkMarks.S1?.obtainedMarks).toFixed(2) : '-'}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tablesMarksQR}>
                              <Box
                                component="span"
                                className={((isNullOrUndefined(item.quizMarks['S1Quiz 1'].weightage) && item.quizMarks['S1Quiz 1'].weightage >= 0))
                                  ? classes.quizColor : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.quizMarks['S1Quiz 1'].obtainedMarks, row, 'S1', 'quiz1-marks')}
                              >
                                {((isNullOrUndefined(item.quizMarks['S1Quiz 1'].weightage) && item.quizMarks['S1Quiz 1'].weightage >= 0)) ? Number(item.quizMarks['S1Quiz 1'].obtainedMarks) : '-'}
                              </Box>
                            </TableCell>
                            {acadYear === "2023-2024" && (
                              <TableCell className={classes.tablesMarksQR}>
                                <Box
                                  component="span"
                                  className={((isNullOrUndefined(item.quizMarks['S1Quiz 2'].weightage) && item.quizMarks['S1Quiz 2'].weightage >= 0))
                                    ? classes.quizColor : classes.marksGrey}
                                  onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.quizMarks['S1Quiz 2'].obtainedMarks, row, 'S1', 'quiz2-marks')}
                                >
                                  {((isNullOrUndefined(item.quizMarks['S1Quiz 2'].weightage) && item.quizMarks['S1Quiz 2'].weightage >= 0)) ? item.quizMarks['S1Quiz 2'].obtainedMarks : '-'}
                                </Box>
                              </TableCell>
                            )}
                            <TableCell className={classes.tablesMarksQR}>
                              <Box
                                component="span"
                                className={isNullOrUndefined(item.assignmentMarks.S1?.weightage) && item.assignmentMarks.S1?.weightage >= 0
                                  ? classes.assignmentsColor : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.assignmentMarks.S1?.obtainedMarks, row, 'S1', 'assignments')}
                              >
                                {isNullOrUndefined(item.assignmentMarks.S1?.weightage) && item.assignmentMarks.S1?.weightage >= 0
                                  ? Number.isInteger(item.assignmentMarks.S1?.obtainedMarks) ? item.assignmentMarks.S1?.obtainedMarks : Number(item.assignmentMarks.S1?.obtainedMarks).toFixed(2) : '-'}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tablesMarksQR} style={{ paddingLeft: '0px', borderRight: '0.1vw solid #ccc' }}>
                              <Box
                                component="span"
                                className={isNullOrUndefined(item.attendenceActivitiesMarks.S1?.weightage) && item.attendenceActivitiesMarks.S1?.weightage >= 0
                                  ? classes.curricularColor : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.attendenceActivitiesMarks.S1?.obtainedMarks, row, 'S1', 'curricular-marks')}
                              >
                                {isNullOrUndefined(item.attendenceActivitiesMarks.S1?.weightage) && item.attendenceActivitiesMarks.S1?.weightage >= 0
                                  ? Number.isInteger(item.attendenceActivitiesMarks.S1?.obtainedMarks) ? item.attendenceActivitiesMarks.S1?.obtainedMarks : Number(item.attendenceActivitiesMarks.S1?.obtainedMarks).toFixed(2) : '-'}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tablesMarksQR}>
                              <Box
                                component="span"
                                className={(isNullOrUndefined(item.marks.S2.passingCriteria) && !item.marks.S2?.isAttended) ? classes.marksRed
                                    : item.marks.S2?.obtainedMarks >= item.marks.S2.passingCriteria ? classes.marksGreen
                                      : item.marks.S2?.obtainedMarks < (item.marks.S2.passingCriteria - 10) ? classes.marksRed
                                        : item.marks.S2?.obtainedMarks >= (item.marks.S2.passingCriteria - 10) ? classes.marksYellow
                                          : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.marks.S2?.obtainedMarks, row, 'S2', 'marks')}
                              >
                                {isNullOrUndefined(item.marks.S2.passingCriteria) && item.marks.S2.passingCriteria >= 0
                                  ? Number.isInteger(item.marks.S2?.obtainedMarks) ? item.marks.S2?.obtainedMarks : Number(item.marks.S2?.obtainedMarks).toFixed(2) : '-'}
                                {isNullOrUndefined(item.marks.S2.passingCriteria) && !item.marks.S2?.isAttended ? '(A)' : ''}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tablesMarksQR}>
                              <Box
                                component="span"
                                className={isNullOrUndefined(item.homeworkMarks.S2?.weightage) && item.homeworkMarks.S2?.weightage >= 0
                                  ? classes.homeworkColor : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.homeworkMarks.S2?.obtainedMarks, row, 'S2', 'homework')}
                              >
                                {isNullOrUndefined(item.homeworkMarks.S2?.weightage) && item.homeworkMarks.S2?.weightage >= 0
                                  ? Number.isInteger(item.homeworkMarks.S2?.obtainedMarks) ? item.homeworkMarks.S2?.obtainedMarks : Number(item.homeworkMarks.S2?.obtainedMarks).toFixed(2) : '-'}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tablesMarksQR}>
                              <Box
                                component="span"
                                className={((isNullOrUndefined(item.quizMarks['S2Quiz 1'].weightage) && item.quizMarks['S2Quiz 1'].weightage >= 0))
                                  ? classes.quizColor : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.quizMarks['S2Quiz 1'].obtainedMarks, row, 'S2', 'quiz1-marks')}
                              >
                                {((isNullOrUndefined(item.quizMarks['S2Quiz 1'].weightage) && item.quizMarks['S2Quiz 1'].weightage >= 0)) ? item.quizMarks['S2Quiz 1'].obtainedMarks : ' - '}
                              </Box>
                            </TableCell>
                            {acadYear === "2023-2024" && (
                              <TableCell className={classes.tablesMarksQR}>
                                <Box
                                  component="span"
                                  className={((isNullOrUndefined(item.quizMarks['S2Quiz 2'].weightage) && item.quizMarks['S2Quiz 2'].weightage >= 0))
                                    ? classes.quizColor : classes.marksGrey}
                                  onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.quizMarks['S2Quiz 2'].obtainedMarks, row, 'S2', 'quiz2-marks')}
                                >
                                  {((isNullOrUndefined(item.quizMarks['S2Quiz 2'].weightage) && item.quizMarks['S2Quiz 2'].weightage >= 0)) ? item.quizMarks['S2Quiz 2'].obtainedMarks : ' - '}
                                </Box>
                              </TableCell>
                            )}
                            <TableCell className={classes.tablesMarksQR}>
                              <Box
                                component="span"
                                className={isNullOrUndefined(item.assignmentMarks.S2?.weightage) && item.assignmentMarks.S2?.weightage >= 0
                                  ? classes.assignmentsColor : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.assignmentMarks.S2?.obtainedMarks, row, 'S2', 'assignments')}
                              >
                                {isNullOrUndefined(item.assignmentMarks.S2?.weightage) && item.assignmentMarks.S2?.weightage >= 0
                                  ? Number.isInteger(item.assignmentMarks.S2?.obtainedMarks) ? item.assignmentMarks.S2?.obtainedMarks : Number(item.assignmentMarks.S2?.obtainedMarks).toFixed(2) : '-'}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tablesMarksQR} style={{ paddingLeft: '0px', borderRight: '0.1vw solid #ccc' }}>
                              <Box
                                component="span"
                                className={isNullOrUndefined(item.attendenceActivitiesMarks.S2?.weightage) && item.attendenceActivitiesMarks.S2?.weightage >= 0
                                  ? classes.curricularColor : classes.marksGrey}
                                onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.attendenceActivitiesMarks.S2?.obtainedMarks, row, 'S2', 'curricular-marks')}
                              >
                                {isNullOrUndefined(item.attendenceActivitiesMarks.S2?.weightage) && item.attendenceActivitiesMarks.S2?.weightage >= 0
                                  ? Number.isInteger(item.attendenceActivitiesMarks.S2?.obtainedMarks) ? item.attendenceActivitiesMarks.S2?.obtainedMarks : Number(item.attendenceActivitiesMarks.S2?.obtainedMarks).toFixed(2) : '-'}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tablesMarksQR} style={{ paddingLeft: '0px' }}>
                              {
                                (item?.enrolled_courses?.[0]?.course?.name !== 'BalaBadi - I' && item?.enrolled_courses?.[0]?.course?.name !== 'BalaBadi - II'
                                  && item?.enrolled_courses?.[0]?.course?.name !== 'Telugu-1' && item?.enrolled_courses?.[0]?.course?.name !== 'Telugu-3')
                                  ? (item?.enrolled_courses?.[0]?.course?.name === 'Prakasam' || item?.enrolled_courses?.[0]?.course?.name === 'Prabhasam'
                                  || item?.enrolled_courses?.[0]?.course?.name === 'Telugu-2' || item?.enrolled_courses?.[0]?.course?.name === 'Telugu-4') ? (
                                    <Box
                                      component="span"
                                      className={(isNullOrUndefined(item?.marks?.S3?.passingCriteria) && !item.marks.S3?.isAttended) ? classes.marksRed
                                        : item.marks.S3?.obtainedMarks >= item.marks.S3?.passingCriteria ? classes.marksGreen
                                          // eslint-disable-next-line no-unsafe-optional-chaining
                                          : item.marks.S3?.obtainedMarks < (item.marks.S3?.passingCriteria - 10) ? classes.marksRed
                                            // eslint-disable-next-line no-unsafe-optional-chaining
                                            : item.marks.S3?.obtainedMarks >= (item.marks.S3?.passingCriteria - 10) ? classes.marksYellow
                                              : classes.marksGrey}
                                      onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.marks.S3?.obtainedMarks, row, 'S3', 'marks')}
                                    >
                                      {isNullOrUndefined(item.marks.S3?.passingCriteria) && (item.marks.S3?.passingCriteria >= 0)
                                        ? Number.isInteger(item.marks.S3?.obtainedMarks) ? item.marks.S3?.obtainedMarks : Number(item.marks.S3?.obtainedMarks).toFixed(2) : '-'}
                                      {isNullOrUndefined(item.marks.S3?.passingCriteria) && !item.marks.S3?.isAttended ? '(A)' : ''}
                                    </Box>
                                    ) : (item?.enrolled_courses?.[0]?.result === 'FAIL' || (item.marks.S3?.obtainedMarks >= 0 && item.marks.S3?.isAttended !== null)) ? (
                                      <Box
                                        component="span"
                                        className={(isNullOrUndefined(item?.marks?.S3?.passingCriteria) && !item.marks.S3?.isAttended) ? classes.marksRed
                                          : item.marks.S3?.obtainedMarks >= item.marks.S3?.passingCriteria ? classes.marksGreen
                                            // eslint-disable-next-line no-unsafe-optional-chaining
                                            : item.marks.S3?.obtainedMarks < (item.marks.S3?.passingCriteria - 10) ? classes.marksRed
                                              // eslint-disable-next-line no-unsafe-optional-chaining
                                              : item.marks.S3?.obtainedMarks >= (item.marks.S3?.passingCriteria - 10) ? classes.marksYellow
                                                : classes.marksGrey}
                                        onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.marks.S3?.obtainedMarks, row, 'S3', 'marks')}
                                      >
                                        {isNullOrUndefined(item.marks.S3?.passingCriteria) && (item.marks.S3?.passingCriteria >= 0)
                                          ? Number.isInteger(item.marks.S3?.obtainedMarks) ? item.marks.S3?.obtainedMarks : Number(item.marks.S3?.obtainedMarks).toFixed(2) : '-'}
                                        {isNullOrUndefined(item.marks.S3?.passingCriteria) && !item.marks.S3?.isAttended ? '(A)' : ''}
                                      </Box>
                                    ) : ((item.marks.S1?.isAttended === false && item.marks.S2?.isAttended == false)) ? (
                                      <Box
                                        component="span"
                                        className={(isNullOrUndefined(item?.marks?.S3?.passingCriteria) && !item.marks.S3?.isAttended) ? classes.marksRed
                                          : item.marks.S3?.obtainedMarks >= item.marks.S3?.passingCriteria ? classes.marksGreen
                                            // eslint-disable-next-line no-unsafe-optional-chaining
                                            : item.marks.S3?.obtainedMarks < (item.marks.S3?.passingCriteria - 10) ? classes.marksRed
                                              // eslint-disable-next-line no-unsafe-optional-chaining
                                              : item.marks.S3?.obtainedMarks >= (item.marks.S3?.passingCriteria - 10) ? classes.marksYellow
                                                : classes.marksGrey}
                                        onClick={(e) => setOpenDialog(e, row.studentName, item.enrolled_courses[0].course?.name, item.marks.S3?.obtainedMarks, row, 'S3', 'marks')}
                                      >
                                        {isNullOrUndefined(item.marks.S3?.passingCriteria) && (item.marks.S3?.passingCriteria >= 0)
                                          ? Number.isInteger(item.marks.S3?.obtainedMarks) ? item.marks.S3?.obtainedMarks : Number(item.marks.S3?.obtainedMarks).toFixed(2) : '-'}
                                        {isNullOrUndefined(item.marks.S3?.passingCriteria) && !item.marks.S3?.isAttended ? '(A)' : ''}
                                      </Box>
                                    ) : (
                                      <span className={classes.tableMarksSemester} style={{ paddingLeft: '0.4vw' }}>
                                        N/A
                                      </span>
                                    ) : (
                                      <span className={classes.tableMarksSemester} style={{ paddingLeft: '0.4vw' }}>
                                        N/A
                                      </span>
                                  )
                              }
                            </TableCell>
                            <TableCell className={classes.tableMarksSemester} style={{ paddingLeft: '0.4vw' }}>{isValidAnnualScore(item) ? item.grade : 'N/A'}</TableCell>
                            <TableCell className={classes.tableMarksSemester} style={{ paddingLeft: '0.4vw' }}>{isValidAnnualScore(item) ? item.gpa : 'N/A'}</TableCell>
                            <TableCell className={classes.tableMarksSemester} style={{ paddingLeft: '0.4vw' }}>{isValidAnnualScore(item) ? (isNullOrUndefined(item?.annualScore) && item?.annualScore >= 0 ? `${item.annualScore}%` : 'N/A') : 'N/A'}</TableCell>
                          </TableRow>
                        </TableBody>
                      </>
                    ))
                  }
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DialogAtom
        isOpen={isBonusMarks}
        secHandle={() => bonusMarksClose()}
        dialogHeading={t('ASSIGN_BONUS_MARKS')}
        content={(
          <BonusMarks
            updateData={{
              value: setRowData?.studentsMarks[0]?.bonus,
            }}
            studentData={{
              courseId: row.studentsMarks[0]?.enrolled_courses[0]?.course?.id,
              studentId: row.studentsMarks[0]?.id,
              academicYear: row.studentsMarks[0]?.enrolled_courses[0].academicYear,
              locationId: row.studentsMarks[0]?.enrolled_courses[0]?.location?.id,
            }}
            handleClose={() => bonusMarksClose()}
            afterSave={() => {
              dispatch(getMarksDetails({
                academicYear: row.studentsMarks[0]?.enrolled_courses[0].academicYear,
                locationId: row.studentsMarks[0]?.enrolled_courses[0]?.location?.id,
                courseId: row.studentsMarks[0]?.enrolled_courses[0]?.course?.id,
                studentId: row.studentsMarks[0]?.id,
              }, bonusMarksClose));
            }}
          />
        )}
      />
      <DialogAtom
        isOpen={updateMakupMarks}
        secHandle={() => handleUpdateMakupMarksClose(false)}
        dialogHeading={t('ADD_MAKUP_MARKS')}
        content={renderUpdateMakupMarksContent()}
      />
      <Popover
        id={id}
        open={open1}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ left: '2.5%' }}
      >
        {updateTypeData === 'marks' && (showMakeupMarks >= (passingCriteria - 10) && showMakeupMarks < passingCriteria && (!isValidSemester)) && (
          <div onClick={() => handleUpdateMakupMarksOpen()}>
            <Typography sx={{
              p: 2,
              cursor: 'pointer',
              fontSize: '0.9vw',
              borderBottom: '0.1vw solid #d0d0d0',
            }}
            >
              {t('UPDATE_MAKEUP_MARKS')}
            </Typography>
          </div>
        )}
        {(updateTypeData === 'quiz1-marks')
          ? (
            <div onClick={() => openAssign(
              setFullName,
              setSetCourseName,
              setQuarterName,
              setRowData,
              setQuarterValue,
              updateTypeData,
              'Quiz 1',
            )}
            >
              <Typography sx={{ p: 2, cursor: 'pointer', fontSize: '0.9vw' }}>
                {t('UPDATE_MARKS')}
              </Typography>
            </div>
          ) : (updateTypeData === 'quiz2-marks')
            ? (
              <div onClick={() => openAssign(
                setFullName,
                setSetCourseName,
                setQuarterName,
                setRowData,
                setQuarterValue,
                updateTypeData,
                'Quiz 2',
              )}
              >
                <Typography sx={{ p: 2, cursor: 'pointer', fontSize: '0.9vw' }}>
                  {t('UPDATE_MARKS')}
                </Typography>
              </div>
            ) : (
              <div onClick={() => openAssign(
                setFullName,
                setSetCourseName,
                setQuarterName,
                setRowData,
                setQuarterValue,
                updateTypeData,
                'Quiz 1',
              )}
              >
                <Typography sx={{ p: 2, cursor: 'pointer', fontSize: '0.9vw' }}>
                  {t('UPDATE_MARKS')}
                </Typography>
              </div>
            )}
      </Popover>
    </>
  );
}

function DropdownIndicator(props) {
  const { sortType } = props;
  return (
    <div style={{ display: 'grid', gridGap: '1px', paddingLeft: '10px' }}>
      {
        sortType === 'both' && (
          <FontAwesomeIcon icon={faSort} color="rgba(0, 0, 0, 0.54)" />
        )
      }
      {
        sortType === 'asc' && (
          <FontAwesomeIcon icon={faSortUp} color="#C12900" />
        )
      }
      {
        sortType === 'desc' && (
          <FontAwesomeIcon icon={faSortDown} color="#C12900" />
        )
      }
    </div>
  );
}

export default function ListView({
  selectedFilter,
  filterOptions,
  onClickCancelEnrollMenu,
  onClickEditExamDetails,
  paymentDialogOpen,
  editExamDetailsOpen,
  onClickMenu,
  setSwapCourseOrLocation,
  recoveryDialogOpen,
  onEditMenuClick,
  onUpdateParentClick,
  setEditStudentProfile,
  showStudentLogs,
  loadingSpinner,
  setLoadingSpinner,
  isFetching,
  hasMore,
  loadMoreItems,
  setOpenMarksPopUp,
  setDataAssignScore,
  setAssignLoading,
  setSortOrder,
  sortOrder,
  sortBoth,
  setSortBoth,
  setSelected,
  studentLoading,
  selected,
  setRepeatStatusOpen,
  setSelectAllCheck,
}) {
  let classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const studentsData = useStudent()?.students || [];
  const studentsDataDouble = useStudent()?.studentsDouble || [];
  const studentCount = useStudent()?.totalCount || 0;
  const [selectedRows, setSelectedRows] = React.useState([]);
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const { onCheckboxChange } = useContext(StudentContext);
  const isAdmin = userRole === userRoles.SYSTEM_ADMIN;

  if (userRole === userRoles.REGION_COORDINATOR) {
    classes = useRegionStyles();
  }

  useEffect(() => {
    const emailList = [];
    const recipientList = [];
    emailList.push(selectedRows.map((student) => student?.parent1Info?.personalEmail));
    recipientList.push(selectedRows.map((student) => student?.studentInfo?.manabadiEmail));
    emailList.push(selectedRows.map((student) => student?.parent2Info?.personalEmail));
    setLocalStorage('adminBulkEmailStudents', emailList);
    setLocalStorage('adminBulkEmailStudents', emailList);
    setLocalStorage('adminBulkRecipientList', recipientList);
    onCheckboxChange(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRows([]);
    setSelected([]);
  }, []);

  let studentObj = {};
  const rowStudentData = useMemo(() => {
    if (!studentsData?.length) return [];
    const rows = [];

    studentsData?.forEach((student, index) => {
      studentObj = {
        ...student,
        id: student?.id,
        key: index,
        pictures: student?.studentInfo?.profilePhoto,
        studentName: `${student?.studentInfo?.firstName} ${student?.studentInfo?.lastName}`,
        parentName: `${student?.parent1Info?.firstName} ${student?.parent1Info?.lastName}`,
        locationInfo: student?.enrolled_courses?.location?.name,
        centerInfo: student?.enrolled_courses?.location?.locationAddress?.address ? student?.enrolled_courses?.location?.locationAddress?.address : student?.enrolled_courses?.location?.name,
        classLevel: student?.enrolled_courses?.course ? student?.enrolled_courses?.course?.name : student?.enrolled_courses[0]?.course?.name,
        section: (student?.enrolled_courses?.google_class?.section ? student?.enrolled_courses?.google_class?.section : student?.enrolled_courses[0]?.google_class?.section) || '',
        regDate: new Date(student?.enrolled_courses?.createdAt),
        actions: 'popup',
        studentsMarks: [],
      };
      rows.push(studentObj);
    });

    return rows;
  }, [studentsData]);

  let studentObjDouble = {};
  const rowStudentDataDouble = useMemo(() => {
    if (!studentsData?.length) return [];
    const rows = [];
    studentsDataDouble?.forEach((student, index) => {
      studentObjDouble = {
        ...student,
        id: student?.id,
        key: index,
        pictures: student?.studentInfo?.profilePhoto,
        studentName: `${student?.studentInfo?.firstName} ${student?.studentInfo?.lastName}`,
        parentName: `${student?.parent1Info?.firstName} ${student?.parent1Info?.lastName}`,
        locationInfo: student?.enrolled_courses?.location?.name,
        centerInfo: student?.enrolled_courses?.location?.name ? student?.enrolled_courses?.location?.name : student?.enrolled_courses[0]?.location?.name,
        classLevel: student?.enrolled_courses?.course ? student?.enrolled_courses?.course?.name : student?.enrolled_courses[0]?.course?.name,
        section: (student?.enrolled_courses?.google_class?.section ? student?.enrolled_courses?.google_class?.section : student?.enrolled_courses[0]?.google_class?.section) || '',
        regDate: new Date(student?.enrolled_courses?.createdAt),
        actions: 'popup',
        studentsMarks: [],
      };
      rows.push(studentObjDouble);
    });
    return rows;
  }, [studentsDataDouble]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleCheck = (rowData) => {
    const selectedIndex = selected.indexOf(rowData.id);
    let newSelected = [];
    let aNewSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowData.id);
      aNewSelected = aNewSelected.concat(selectedRows, rowData);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      aNewSelected = aNewSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      aNewSelected = aNewSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      aNewSelected = aNewSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelectedRows(aNewSelected);
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event) => {
    setSelectAllCheck(event.target.checked);
    if (event.target.checked) {
      const newSelecteds = rowStudentData.map((n) => n.id);
      setSelectedRows(rowStudentData);
      setSelected(newSelecteds);
      setTimeout(() => {
        setSortBoth(false);
      }, 100);
      return;
    }
    setSelectedRows([]);
    setSelected([]);
  };
  useEffect(() => {
    const value = _.uniq(Object.values(sortOrder));
    if (value.length === 1 && value[0] === 'both') {
      rowStudentData.sort((a, b) => {
        const aa = a.regDate;
        const bb = b.regDate;
        return aa > bb ? -1 : (aa > bb ? 1 : 0);
      });
    }
  }, [sortOrder]);

  const changeSortOrder = (filter, status) => {
    if (sortOrder[status] === 'desc') {
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'both',
      }));
      setSortBoth(true);
    } else if (sortOrder[status] === 'asc') {
      setSortBoth(false);
      if (filter === 'regDate') {
        rowStudentData.sort((a, b) => {
          const aa = a[filter];
          const bb = b[filter];
          return aa < bb ? -1 : (aa > bb ? 1 : 0);
        });
      } else {
        rowStudentData.sort((a, b) => b[filter].localeCompare(a[filter]));
      }
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'desc',
      }));
    } else if (sortOrder[status] === 'both') {
      setSortBoth(false);
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'asc',
      }));
      if (filter === 'regDate') {
        rowStudentData.sort((a, b) => {
          const aa = a[filter];
          const bb = b[filter];
          return aa > bb ? -1 : (aa > bb ? 1 : 0);
        });
      } else {
        rowStudentData.sort((a, b) => a[filter].localeCompare(b[filter]));
      }
    }
  };
  const impersonateStudentUser = useCallback(
    async (row) => {
      dispatch(impersonateUser({
        email: row?.studentInfo?.manabadiEmail,
      }));
    },
    [],
  );
  const handleImpersonate = async (row) => {
    await impersonateStudentUser(row).finally(() => {
      localStorage.setItem('impersonateUser', JSON.stringify({ state: { showView: true, email: row?.studentInfo?.manabadiEmail } }));
      localStorage.setItem('isAdminUser', isAdmin);
      dispatch({
        type: Constant.SET_VIEW,
        payload: 'listView',
      });
      navigate(NavigateRoutes.STUDENT_DASHBOARD, { state: { showView: true, email: row?.studentInfo?.manabadiEmail, filterOptions } });
    });
  };

  if (studentLoading) {
    return <Loader message={t('LOADING')} />;
  }
  return (
    <Grid container className={`${classes.studentDetails} ${classes.withoutScroll}`}>
      {studentsData.length > 0
        ? (
          <FormControlLabel
            value="end"
            className={classes.studentKeys}
            control={(
              <Checkbox
                color="primary"
                indeterminate={selected.length > 0 && selected.length < rowStudentData.length}
                checked={rowStudentData.length > 0 && selected.length === rowStudentData.length}
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            )}
            label={t('SELECT_ALL')}
            labelPlacement="end"
          />
        ) : null}
      {rowStudentData?.length > 0
        ? (
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" className={classes.check} />
                  <TableCell className={classes.picture}>{t('PICTURES')}</TableCell>
                  <TableCell className={classes.studentName}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('studentName', 'studentN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.studentN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('STUDENT_NAME')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.parentName}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('parentName', 'parentN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.parentN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('PARENT_NAME')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.centerInfo}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('locationInfo', 'centerI')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.centerI} />}
                      className={classes.studentNameLabel}
                    >
                      {t('CENTER_INFO')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.classLevel}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('classLevel', 'classL')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.classL} />}
                      className={classes.studentNameLabel}
                    >
                      {t('CLASS_LEVEL')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.section}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('section', 'sectionN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.sectionN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('SECTION')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.regDate}>
                    <TableSortLabel
                      active
                      onClick={() => changeSortOrder('regDate', 'regDateN')}
                      IconComponent={() => <DropdownIndicator sortType={sortOrder.regDateN} />}
                      className={classes.studentNameLabel}
                    >
                      {t('REG_DATE')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.actions}>{t('ACTIONS')}</TableCell>
                </TableRow>
              </TableHead>
              {
                sortBoth === false && (
                  <TableBody>
                    {rowStudentData.map((row, index) => (
                      <Row
                        row={row}
                        index={index}
                        selectedFilter={selectedFilter}
                        handleImpersonate={handleImpersonate}
                        onClickCancelEnrollMenu={onClickCancelEnrollMenu}
                        onClickEditExamDetails={onClickEditExamDetails}
                        paymentDialogOpen={paymentDialogOpen}
                        editExamDetailsOpen={editExamDetailsOpen}
                        onClickMenu={onClickMenu}
                        setSwapCourseOrLocation={setSwapCourseOrLocation}
                        recoveryDialogOpen={recoveryDialogOpen}
                        onEditMenuClick={onEditMenuClick}
                        onUpdateParentClick={onUpdateParentClick}
                        setEditStudentProfile={setEditStudentProfile}
                        showStudentLogs={showStudentLogs}
                        loadingSpinner={loadingSpinner}
                        setLoadingSpinner={setLoadingSpinner}
                        isSelected={isSelected}
                        handleCheck={handleCheck}
                        setOpenMarksPopUp={setOpenMarksPopUp}
                        setDataAssignScore={setDataAssignScore}
                        setAssignLoading={setAssignLoading}
                        sortBoth={sortBoth}
                        setRepeatStatusOpen={setRepeatStatusOpen}
                        studentsCount={rowStudentData?.length}
                      />
                    ))}
                    <TableRow>
                      <TableCell className={classes.totalRowCount} colSpan={10}>
                        <span>
                          {t('TOTAL_STUDENTS')}
                          {' '}
                          {rowStudentData?.length}
                          {' '}
                          of
                          {' '}
                          {studentCount}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.loadMore} colSpan={10}>
                        {isFetching && <p>{t('FETCHING_STUDENTS')}</p>}
                        {!isFetching && hasMore && (
                          <Button
                            name={t('LOAD_MORE')}
                            btntype={Buttons.PRIMARY}
                            onClick={() => loadMoreItems(rowStudentData?.length)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              }
              {
                sortBoth === true && (
                  <TableBody>
                    {rowStudentDataDouble.map((row, index) => (
                      <Row
                        row={row}
                        index={index}
                        selectedFilter={selectedFilter}
                        handleImpersonate={handleImpersonate}
                        onClickCancelEnrollMenu={onClickCancelEnrollMenu}
                        onClickEditExamDetails={onClickEditExamDetails}
                        paymentDialogOpen={paymentDialogOpen}
                        editExamDetailsOpen={editExamDetailsOpen}
                        onClickMenu={onClickMenu}
                        setSwapCourseOrLocation={setSwapCourseOrLocation}
                        recoveryDialogOpen={recoveryDialogOpen}
                        onEditMenuClick={onEditMenuClick}
                        onUpdateParentClick={onUpdateParentClick}
                        setEditStudentProfile={setEditStudentProfile}
                        showStudentLogs={showStudentLogs}
                        loadingSpinner={loadingSpinner}
                        setLoadingSpinner={setLoadingSpinner}
                        isSelected={isSelected}
                        handleCheck={handleCheck}
                        setOpenMarksPopUp={setOpenMarksPopUp}
                        setDataAssignScore={setDataAssignScore}
                        setAssignLoading={setAssignLoading}
                        sortBoth={sortBoth}
                        setRepeatStatusOpen={setRepeatStatusOpen}
                        studentsCount={rowStudentData?.length}
                      />
                    ))}
                    <TableRow>
                      <TableCell className={classes.totalRowCount} colSpan={10}>
                        <span>
                          {t('TOTAL_STUDENTS')}
                          {' '}
                          {rowStudentData?.length}
                          {' '}
                          of
                          {' '}
                          {studentCount}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.loadMore} colSpan={10}>
                        {isFetching && <p>{t('FETCHING_STUDENTS')}</p>}
                        {!isFetching && hasMore && (
                          <Button
                            name={t('LOAD_MORE')}
                            btntype={Buttons.PRIMARY}
                            onClick={() => loadMoreItems(rowStudentData?.length)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              }
            </Table>
          </TableContainer>
        ) : rowStudentData.length === 0 ? (
          <Grid container className={classes.noData}>
            <Grid item xs={12}>
              <DoNotDisturbIcon />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                {t('NO_DATA')}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Loader message={t('LOADING')} />
          </Grid>
        )}
    </Grid>
  );
}
