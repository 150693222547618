import { ApiRoutes } from '../../constant';
import request from '../../utils/request';

// Academic Panel Services

export const getAcademicSchemaService = (body) => request.get(`${ApiRoutes.GET_ACADEMIC_SCHEMA}?courseId=${body.courseId}&academicYear=${body.academicYear}&quarter=${body.quarter}`);

export const saveAcademicSchemaService = (body, update = false) => {
  const requestMethod = update ? request.put : request.post;

  return requestMethod(ApiRoutes.GET_ACADEMIC_SCHEMA, body);
};

export const getAcademicYearQuarterDetails = (year) => request.post(ApiRoutes.GET_QUARTER_DETAILS, { year });

export const saveAcademicAssignmentService = (body, courseId) => request.post(`${ApiRoutes.ADD_ASSIGNMENT_PER_COURSE}/${courseId}`, body);
