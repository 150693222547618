const decryptedUserRoles = {
  '#YWKA$Y&xW0&a6MjB52lx#bh6&T': 'System Admin',
  'pvEvt8r!VPQa75%S%abcd1239!2': 'Super User',
  'pvEvt8r!VPQa75%S%YSW7x3u!Sc': 'Curriculum',
  'mXdE6C%1%2E&Uou%ghb9I@40w1N': 'Region Coordinator',
  'AqB5RXpW7N1o1y9xyWop!o%^N@r': 'Location Coordinator',
  '0C#3*ID5n4OZo2h!2i%oHWo5mb^': 'Student',
  'ttw8wEoqCpt*y81R&h1Ab7yxaGm': 'Teacher',
};
export default decryptedUserRoles;
