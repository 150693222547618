import React, { useState } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Box,
  ListItemText,
  IconButton, Grid,
  Menu, MenuItem, Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '../../components/atoms';
import LogoutIcon from '../../assets/svg/logoutIcon';
import useStyles from '../../custom-hooks/useStyles';
import logo from '../../assets/images/logo.png';
import style from './style';
import { Buttons } from '../../constant';
import { logout, getLocalStorage } from '../../utils/localStorageMethod';
import decryptedUserRoles from '../../constant/decryptedUserRoles';
import Header from '../common/view-header/navigation-header/navigation-header';
import CommonProfileImage from '../../components/molecules/common/CommonProfileImage';

export default function ManabadiHeader() {
  const classes = useStyles(style)();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const [logoutAnchorEl, setLogoutAnchorEl] = useState(null);
  const logoutOpen = Boolean(logoutAnchorEl);
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const tempUserRoleLocalStorage = getLocalStorage('tempUserRole');
  const tempUserRole = decryptedUserRoles[tempUserRoleLocalStorage];

  const handleLogoutClick = (event) => {
    const impersonateToken = getLocalStorage('impersonateToken');
    if (!impersonateToken) {
      setLogoutAnchorEl(event.currentTarget);
    }
  };
  const handleLogoutClose = () => {
    setLogoutAnchorEl(null);
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Grid className={classes.divWrapper}>
        <Grid className={classes.HeaderWrapper}>
          <Box>
            <img
              src={logo}
              width={380}
              alt="ManaBadi SiliconAndhra Logo"
              className={classes.headerLogo}
              onClick={() => navigate('/')}
            />
          </Box>
          <Stack direction="row" spacing={1} className={classes.iconsHeader} justifyContent="space-between !important">
            {localStorage.getItem('token')
              ? (
                <Box>
                  <Menu
                    anchorEl={logoutAnchorEl}
                    id="account-menu"
                    open={logoutOpen}
                    className={classes.logOut}
                    onClose={handleLogoutClose}
                    onClick={handleLogoutClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.32))',
                        '& .MuiMenuItem-root': {
                          fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
                          '@media (min-width: 1200px)': {
                            fontSize: '0.9vw',
                          },
                          display: 'list-item',
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem onClick={logout} className={classes.logOut}>
                      <LogoutIcon />
                      {t('LOGOUT')}
                    </MenuItem>
                  </Menu>
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${classes.keyboardDownIcon}`}
                    onClick={handleLogoutClick}
                    aria-controls={logoutOpen ? 'help-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={logoutOpen ? 'true' : undefined}
                  >
                    <CommonProfileImage src={localStorage.getItem('profilePhoto')} />
                    <ListItemText primary={localStorage.getItem('username')} secondary={userRole || tempUserRole} className={classes.userText} />
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Box>
              )
              : (
                <Box />
              )}
          </Stack>
        </Grid>
        <Box className={classes.header}>
          <Header />
        </Box>
        <Box className={classes.bgBox} mt={2}>
          <Grid
            item
            xs={12}
            md={7}
            lg={7}
            className={classes.cardLeft}
          />
          <Typography className={classes.noPageText}>
            {t('404_LOGGEDIN')}
          </Typography>
          <Box>
            <Grid item xs={12} className={classes.popupButton}>
              {localStorage.getItem('token')
                ? (
                  <Box />
                ) : (
                  <Button
                    className={classes.popupBtn}
                    name={t('HOME')}
                    btntype={Buttons.PRIMARY}
                    onClick={() => navigate('/')}
                  />
                )}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Stack>
  );
}
