/* eslint-disable import/prefer-default-export */
import Constant from '../constant';

import {
  getQuizSchemaService, saveQuizSchemaService, updateQuizMarksService,
} from '../services/quizSchema';

const getQuizSchema = (requestData, setLoading) => (dispatch) => {
  getQuizSchemaService(requestData)
    .then((response) => {
      dispatch({
        type: Constant.GET_QUIZ_SCHEMA,
        payload: response?.data,
      });
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};

const saveQuizSchema = async (payload, update, loadFalse, setError, setSuccessMsg, validSemesterYear, setErrorSchemaRemoved, quizSchemaIdAux, setQuizSchemaIdAux) => {
  const errMsg = 'Failed to update Quiz schema - marks are already assigned';
  const errMatch2 = 'Quiz marks already assigned';

  try {
    let success;
    let data;
    const errMatch = 'Failed to update Quiz panel';
    try {
      ({ success, data } = await saveQuizSchemaService(payload, update));
      setQuizSchemaIdAux(data.id);
    } catch (e) {
      if (!success) {
        // Si success es false, intentar nuevamente con update establecido en true
        if (quizSchemaIdAux !== '') {
          payload.quizSchemaId = quizSchemaIdAux;
          ({ success, data } = await saveQuizSchemaService(payload, true));
        }
      } else {
        setError(errMatch);
      }
    }

    if (success && typeof data === 'string' && setError) {
      setError(errMsg);
    } else if (success && !data.error && setSuccessMsg) {
      if (data.quiz_schema_marks.length === 0) {
        setErrorSchemaRemoved(validSemesterYear ? 'Schema for this Semester was removed by Admin' : 'Schema for this Quarter was removed by Admin');
        setSuccessMsg('');
      } else {
        setErrorSchemaRemoved('');
        setSuccessMsg('Quiz Schema Created/Updated Successfully');
      }
    }

    if (success && data?.error?.includes(errMatch2) && setError) {
      setError(errMsg);
    }

    loadFalse();
  } catch (error) {
    setError('Some error occured, cannot update schema');
    loadFalse();
  }
};

const updateQuizMarks = (requestObj, refreshList) => () => {
  const {
    studentId, courseName, quarterName,
    academicYear, studentQuizDetailMarks, isPresent, isAttended, locationId, quiz,
  } = requestObj;
  const payload = {
    studentId,
    academicYear,
    courseId: courseName,
    quarter: quarterName,
    studentQuizDetailMarks,
    isPresent,
    isAttended,
    locationId,
    quiz,
  };
  updateQuizMarksService(payload)
    .then(() => {
      refreshList();
    })
    .catch(() => {

    });
};

export {
  getQuizSchema,
  saveQuizSchema,
  updateQuizMarks,
};
