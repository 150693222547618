import { Grid } from '@mui/material';
import React, { useState } from 'react';
import {
  PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import useStyles from '../../../../custom-hooks/useStyles';
import { colors } from '../../../../theme';
import style from './style';
import { CustomTooltip } from './commonComponent';

const COLORS = [colors.mustardShade, colors.purple];

function ReturningBreakdown({ label, data }) {
  if (!Array.isArray(data)) {
    return null;
  }
  const [posData, setposData] = useState({});
  // eslint-disable-next-line no-param-reassign
  data = data.reduce((obj, item) => {
    const { name } = item;
    if (Object.entries(obj).length > 0 && obj[name]) {
      if (!obj[name]?.isNew || !item || !item?.isNew) {
        return obj;
      }
      obj[name] = {
        ...obj[name],
        isNew: obj[name].isNew + item.isNew,
        isReturning: obj[name].isReturning + item.isReturning,
        value: obj[name].value + item.value,
      };
    } else {
      // eslint-disable-next-line no-param-reassign
      obj[item.name] = item;
    }
    return obj;
  }, {});

  // eslint-disable-next-line no-param-reassign
  data = Object.values(data);

  const { t } = useTranslation();

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul>
        {payload.map((entry, index) => (
          <li key={`item-${index}`}>
            <div
              className="dots"
              style={entry?.value === 'false' ? { background: COLORS[0] } : { background: COLORS[1] }}
            />
            {entry?.value === 'false'
              ? t('NEW_STUDENTS')
              : t('RETURN_STUDENTS')}
          </li>
        ))}
      </ul>
    );
  };
  const classes = useStyles(style)();
  const totalValue = data.reduce((sum, item) => {
    // eslint-disable-next-line no-param-reassign
    sum += parseInt(item.value, 10);
    return sum;
  }, 0);
  // eslint-disable-next-line no-param-reassign
  data = data.map((item) => {
    // eslint-disable-next-line no-param-reassign
    item.totalValue = totalValue;
    return item;
  });
  return (
    <Grid container className={classes.enrollWeekGraph}>
      <div className={classes.heading}>{label}</div>
      <ResponsiveContainer className={classes.donutChart}>
        <PieChart
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Pie
            data={data}
            color="#000000"
            dataKey="value"
            nameKey="name"
            fill="#8884d8"
            minAngle={3}
            innerRadius={40}
            onMouseMove={(point) => {
              setposData(point);
            }}
          >
            {data?.length > 0
            && data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry?.name === 'false' ? COLORS[0] : COLORS[1]}
              />
            ))}
          </Pie>
          <Tooltip
            content={(props) => CustomTooltip({
              ...props,
              coordinate: {
                x: ((posData?.tooltipPosition?.x || 0) - 25), y: (posData?.tooltipPosition?.y || 0) - 20,
              },
            })}
            position={{ y: (posData?.tooltipPosition?.y || 0) }}
          />
          <Legend content={renderLegend} iconType="circle" />
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  );
}

export default ReturningBreakdown;
