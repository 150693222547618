/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box } from '@mui/material';
import { useFormik, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from '../../../register/style';
import useStyles from '../../../../custom-hooks/useStyles';
import Loader from '../../../../components/atoms/loader';
import { getLocalStorage } from '../../../../utils/localStorageMethod';
import userRoles from '../../../../constant/userRoles';
import { PerformantTextField } from '../../../../components/atoms';
import DropdownWithSearch from '../../../../components/atoms/dropdown-with-search';
import { updateExamCenter } from '../../../../store/actions/getStudent';
import { getActiveExamCenters } from '../../../../store/services/auth';
import style from './style';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';

export default function EditExamDetails(props) {
  const {
    editExamInfo,
    refreshList,
    setIsEditExam,
    setCustomForm,
    setIsEditExamDetailsOpen,
    isEditButtonDisable,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(style)();
  const dispatch = useDispatch();
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const [examCenter, setExamCenter] = useState([]);
  const [loading, setLoading] = useState(false);

  const textField = (
    label,
    id,
    type,
    handleChange,
    onBlur,
    value,
    error,
    required = true,
    disable = false,
  ) => (
    <PerformantTextField
      label={label}
      id={id}
      required={required}
      name={id}
      type={type}
      value={value}
      onBlur={onBlur}
      error={error}
      onChange={handleChange}
      disabled={disable}
    />
  );

  const validationArray = [t('STUDENT_NAME_REQUIRED'), t('PARENT_NAME_REQUIRED'),
    t('ACADEMIC_YEAR_REQUIRED'), t('COURSE_REQUIRED'), t('EXAM_LOCATION_REQUIRED'), t('CHANGE_LOG_REQUIRED')];

  const commonSchema = Yup.object({
    studentName: Yup.string()
      .required(validationArray[0])
      .typeError(validationArray[0]),
    parentName: Yup.string()
      .required(validationArray[1])
      .typeError(validationArray[1]),
    academicYear: Yup.string()
      .required(validationArray[2])
      .typeError(validationArray[2]),
    selectCourse: Yup.string()
      .required(validationArray[3])
      .typeError(validationArray[3]),
    exam_center: Yup.string()
      .required(validationArray[4])
      .typeError(validationArray[4]),
    changeLogs: Yup.string()
      .required(validationArray[5])
      .typeError(validationArray[5]),
  });

  const validationSchema = commonSchema;
  const formik = useFormik({
    initialValues: {
      studentName: editExamInfo?.studentName,
      parentName: editExamInfo?.parentName,
      academicYear: editExamInfo?.academicYear,
      selectCourse: editExamInfo?.selectCourse,
      hallTicketNumber: editExamInfo?.hallTicketNumber,
      exam_center: editExamInfo?.exam_center,
      changeLogs: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (isEditButtonDisable) {
        return;
      }
      const callback = () => {
        refreshList();
        setIsEditExam(false);
        setLoading(false);
      };
      setLoading(true);
      const payloadObject = {
        studentId: editExamInfo?.studentId,
        logMessage: values.changeLogs,
        enrolledCourseId: editExamInfo?.enrolledCourseId,
        hallTicketNumber: values?.hallTicketNumber,
        examCenterId: examCenter?.find((i) => i?.name === values?.exam_center)?.id || '',
      };
      dispatch(updateExamCenter(payloadObject, callback, setIsEditExamDetailsOpen));
    },
  });

  React.useEffect(() => {
    if (userRole !== userRoles.REGION_COORDINATOR) {
      const payload = {
        exam_center: formik?.values?.exam_center,
      };
      getActiveExamCenters(payload)
        .then((response) => {
          const optionsArray = [];
          response.data.forEach((option) => {
            optionsArray.push(option);
          });
          setExamCenter(optionsArray);
        })
        .catch(() => { });
      setCustomForm(formik);
    }
  }, [formik?.values?.exam_center]);

  useEffect(() => {
    const values = {
      studentName: editExamInfo?.studentName,
      parentName: editExamInfo?.parentName,
      academicYear: editExamInfo?.academicYear,
      selectCourse: editExamInfo?.selectCourse,
      hallTicketNumber: editExamInfo?.hallTicketNumber,
      exam_center: editExamInfo?.exam_center,
      changeLogs: '',
    };
    formik.setValues(values);
  }, [editExamInfo]);
  const getErrorText = (key, errorText) => {
    if (formik.touched[key] && formik.errors[key]) {
      return (
        <span data-testid={key} className={classes.errorText}>
          {formik.errors[key]}
        </span>
      );
    } if (errorText) {
      return (
        <span className={classes.errorText}>
          {errorText}
        </span>
      );
    }
    return null;
  };

  React.useEffect(() => {
    setCustomForm(formik);
  }, [formik?.values]);

  const setPreviousFieldTouch = (key) => {
    const allFields = [
      'studentName',
      'parentName',
      'academicYear',
      'selectCourse',
      'hallTicketNumber',
      'exam_center',
      'changeLogs',
    ];
    const index = allFields.indexOf(key);
    if (index > -1) {
      const obj = {};
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        const element = allFields[i];
        obj[element] = true;
      }
      formik.setTouched({ ...formik.touched, ...obj }, true);
    }
  };
  const registerClasses = useStyles(styles)();

  return (
    <Box>
      <Grid container>
        <FormikProvider value={formik}>
          <form
            style={{ width: '100%' }}
            className={`${registerClasses.form} ${classes.form}`}
          >
            <Grid container marginTop={1} spacing={2} className={classes.errorMsg}>
              <Grid xs={12} className={classes.previousYear}>
                {isEditButtonDisable
                  ? t('PREVIOUS_YEAR_EDIT_EXAM') : null}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} sm={4}>
                {textField(
                  t('STUDENT_NAME'),
                  'studentName',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('studentName'),
                  formik.values.studentName,
                  getErrorText('studentName'),
                  true,
                  true,
                )}
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                {textField(
                  t('PARENT_NAME'),
                  'parentName',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('parentName'),
                  formik.values.parentName,
                  getErrorText('parentName'),
                  true,
                  true,
                )}
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                {textField(
                  t('ACADEMIC_YEAR'),
                  'academicYear',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('academicYear'),
                  formik.values.academicYear,
                  getErrorText('academicYear'),
                  true,
                  true,
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.alignGrid}>
              <Grid item xs={8} md={8} sm={8}>
                {textField(
                  t('SELECT_COURSE'),
                  'selectCourse',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('selectCourse'),
                  formik.values.selectCourse,
                  getErrorText('selectCourse'),
                  true,
                  true,
                )}
              </Grid>
              <Grid item xs={4} md={4} sm={4} className={classes.textFieldSize}>
                <PerformantTextField
                  label={`${t('HALL_TICKET_NUMBER')}`}
                  id="hallTicketNumber"
                  name="hallTicketNumber"
                  type="text"
                  value={formik.values.hallTicketNumber}
                  error={getErrorText('hallTicketNumber')}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} flexDirection="row" className={`${classes.alignGrid} ${classes.innerContainer}`}>
              <Grid item xs={12} md={12} sm={12} className={`${formik.touched.exam_center && formik.errors.exam_center && 'Mui-error'} ${classes.handleErrorClasses} ${classes.autoFieldDisabled}`}>
                <DropdownWithSearch
                  minWidth="100%"
                  label={`${t('FROM_EXAM_LOCATION')}`}
                  id="exam_center"
                  name="exam_center"
                  value={formik.values.exam_center}
                  handleChange={(_event, newValue) => {
                    formik.setFieldValue('exam_center', newValue);
                  }}
                  options={examCenter}
                  onBlur={() => setPreviousFieldTouch('exam_center')}
                  error={getErrorText('exam_center')}
                  required
                  disabled={isEditButtonDisable || !!formik.values.hallTicketNumber || userRole === userRoles.REGION_COORDINATOR || userRole === userRoles.LOCATION_COORDINATOR}
                />
                {getErrorText('exam_center')}
              </Grid>
            </Grid>
            <Grid container spacing={2} flexDirection="row" className={`${classes.alignGrid} ${classes.innerContainer}`}>
              <Grid item xs={12} md={12} sm={12}>
                <PerformantTextField
                  placeholder={`${t('CHANGE_LOG')}*`}
                  label={`${t('CHANGE_LOG')}`}
                  id="changeLogs"
                  required
                  name="changeLogs"
                  type="text"
                  value={formik.values.changeLogs}
                  disabled={isEditButtonDisable}
                  onBlur={() => setPreviousFieldTouch('changeLogs')}
                  error={getErrorText('changeLogs')}
                  onChange={formik.handleChange}
                  labelId="changeLogs"
                  allowSpecialCharacter
                />
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </Grid>
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
    </Box>
  );
}
