import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import styles from './style';
import useStyles from '../../../../../custom-hooks/useStyles';
import CourseRow from '../course-row';

export default function ListOfCourses(props) {
  const { t } = useTranslation();

  const classes = useStyles(styles)();
  const { status, data, updateCourseDetails } = props;

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container spacing={1}>
        <TableContainer>
          <Table sx={12} aria-label="simple table">
            <TableHead className={classes.regionManagerTable}>
              <TableRow>
                <TableCell align="left">{`${t('COURSE_NAME')}`}</TableCell>
                <TableCell align={isMobile ? 'left' : 'center'}>{`${t('LEVEL')}`}</TableCell>
                <TableCell align={isMobile ? 'left' : 'center'}>{`${t('STATUS')}`}</TableCell>
                <TableCell align={isMobile ? 'left' : 'center'}>
                  {' '}
                  {`${t('FEE_STRUCTURE')}`}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.regionManagerTable}>
              {data.map((row, index) => (
                <CourseRow
                  regionStatus={status}
                  key={row?.id}
                  courseInfo={row}
                  updateCourse={(details) => {
                    updateCourseDetails(index, {
                      info: row,
                      details,
                    });
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
