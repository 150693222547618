import { colors, fonts } from '../../../theme';

const StudentClassStyle = ((theme) => ({
  divWrapper: {
    width: '100% !important',
  },
  HeaderWrapper: {
    boxShadow: 'none',
    left: '0',
    top: '0',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    '@media (min-width: 1200px)': {
      fontSize: '1.5vw',
    },
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  titleRow: {
    justifyContent: 'space-between',
    padding: '0 1vw',
    '& [direction=row]': {
      boxSizing: 'border-box',
      flexFlow: 'row wrap',
      '@media (min-width: 1200px)': {
        marginTop: '-32px',
      },
      width: 'calc(100% + 16px)',
      marginLeft: '5px',
      display: 'flex',
      alignItems: 'center',
      '& span': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
        },
        marginTop: '3vw',
        marginLeft: '10px',
        color: '#d32f2f',
      },
    },
  },
  gridPadding: {
    padding: '24px',
  },
  divAlignment: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  location: {
    display: 'contents',
    '& img': {
      width: '1vw',
      height: '1vw',
      transform: 'translate(0.9vw, 0.1vw)',
      marginRight: '0.2vw',
    },
    '& .MuiSelect-select': {
      width: '30vw',
    },
  },
  dropdownWrap: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    '& .MuiFormControl-root': {
    },
    '& .MuiInputBase-root.MuiInput-root:before': {
      borderBottom: `0.15vw solid ${colors.primary}`,
    },
    '& .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '0',
    },
  },
  googleClasses: {
    marginLeft: '5vw',
    '& .MuiInputBase-root.MuiInput-root': {
      '@media (max-width: 1200px)': {
        minWidth: '35vw !important',
        maxWidth: '250px !important',
      },
    },
    '& .MuiSelect-select.MuiSelect-select': {
      '@media (min-width: 1200px)': {
        width: '30vw',
      },
      '@media (max-width: 1200px)': {
        width: '95%',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  assignyear: {
    maxWidth: '250px',
    '& .MuiFormControl-root': {
      minWidth: '8vw',
      '& .MuiInputBase-root.MuiInput-root': {
        width: '100%',
      },
    },
  },
  emailIcon: {
  },
  emailIconDisable: {
    opacity: '.6',
    cursor: 'not-allowed',
  },
  rightIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0.5vw',
    marginLeft: 'auto',
    '& svg': {
      width: '1.5vw',
      height: '1.5vw',
      color: colors.primary,
    },
    '&:hover': {
      backgroundColor: 'white !important',
      minHeight: '3px !important',
    },
  },
  gridActions: {
    textAlign: 'right',
    cursor: 'pointer',
    marginRight: '2vw',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1200px)': {
      maxWidth: 'fit-content',
    },
  },
  icon: {
    marginBottom: '25px',
    width: '1.5vw !important',
    height: '1.5vw !important',
    color: colors.primary,
  },
  iconn: {
    marginBottom: '25px',
    width: '1.1vw !important',
    height: '1.1vw !important',
    color: colors.primary,
  },
  columnSettingDialog: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        minWidth: '55vw !important',
        maxWidth: '65vw !important',
      },
      overflowX: 'hidden !important',
    },
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
    },
    '& .MuiDialogContent-root .MuiGrid-root': {
      '@media (min-width: 1200px)': {
        maxWidth: '65vw !important',
      },
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
    '& .MuiBox-root': {
      padding: '0vw !important',
    },
    '& #datePickerDiv': {
      '@media (min-width: 1200px)': {
        width: '18vw !important',
      },
    },
    '& #addressAutoComplete': {
      '@media (min-width: 1200px)': {
        width: '46.2vw',
      },
    },
  },
  dataGridMyClass: {
    '& .MuiDataGrid-columnHeadersInner': {
      paddingLeft: '100px !important',
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      paddingLeft: '100px !important',
    },
    border: 'unset',
    '& .MuiDataGrid-main': {
      '& div:nth-child(2)': {
        height: 'auto !important',
        '@media (max-width: 767px)': {
        },
      },
    },
    '.MuiDataGrid-root': {
      outline: 'none !important',
      border: 'none !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      overflowX: 'auto !important',
      minHeight: '10vw !important',
      '@media (max-width: 1500px)': {
        overflowY: 'auto !important',
        overflowX: 'auto !important',
      },

      '& .MuiDataGrid-virtualScrollerContent': {
        '& .MuiDataGrid-virtualScrollerRenderZone': {
        },
      },
    },
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
    },
    '& .MuiDataGrid-row': {
      alignItems: 'center',
    },
    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
      width: '0 !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '4px solid #025EE6 !important',
      borderTop: 'none',
      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
      fontStyle: 'bold',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      lineHeight: '63px !important',
      color: colors.actionIconsColor,
      minHeight: '3vw !important',
      zIndex: '111 !important',
      backgroundColor: '#fff  !important',
      marginTop: '8px !important',
      '@media (max-width: 1500px)': {
        minHeight: '4vw !important',
        maxHeight: '6vw !important',
      },
      '@media (max-width: 449px)': {
        minHeight: '70px !important',
        maxHeight: '70px !important',
        lineHeight: '18px !important',
      },
      '@media (min-width: 450px) and (max-width: 900px)': {
        minHeight: '70px !important',
        maxHeight: '70px !important',
        lineHeight: '18px !important',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      textAlign: 'left',
      '@media (max-width: 1500px)': {
        fontSize: '1vw',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-pinnedColumnHeaders': {
      '@media (max-width: 1200px)': {
        height: 'auto !important',
      },
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'left',
      border: 'none !important',
    },
    '& .parentHeader, .parentNameCell': {
      width: '12vw !important',
      minWidth: '10vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '10vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
        minHeight: '3vw !important',
      },
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '120px !important',
        minWidth: '120px !important',
      },
    },
    '& .marksTableHeader, .marksTableCell': {
      width: '10vw !important',
      minWidth: '10vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '10vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
      '& .MuiFormControl-root': {
        width: '45%',
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 10px',
      },
      '& div:first-child': {
        display: 'flex',
        alignItems: 'center',
        '& span': {
          padding: '0 10px',
        },
      },
    },
    '& .MuiDataGrid-cellCheckbox, .MuiDataGrid-columnHeaderCheckbox': {
      minHeight: '3vw !important',
      '@media (min-width: 1200px)': {
        maxHeight: '3vw !important',
        maxWidth: '5vw !important',
      },
      minWidth: '5vw !important',
    },
    '& .studentNameHeader, .studentNameCell': {
      width: '11vw !important',
      minWidth: '11vw !important',
      maxWidth: '11vw !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        minHeight: '3vw !important',
        maxHeight: '3vw !important',
      },
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '125px !important',
        minWidth: '125px !important',
      },
    },
    '& .studentEmailHeader, .studentEmailCell': {
      width: '12vw !important',
      minWidth: '13vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '13vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '183px !important',
        minWidth: '183px !important',
      },
    },
    '& .marksHeader, .marksCell': {
      width: '8vw !important',
      minWidth: '8vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '8vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      justifyContent: 'space-around',
      alignItems: 'center',
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '120px !important',
        minWidth: '120px !important',
      },
      '@media (min-width: 450px) and (max-width: 900px)': {
        minHeight: '70px !important',
        maxHeight: '70px !important',
        height: '70px !important',
        lineHeight: '18px !important',
      },
      '& .MuiDataGrid-columnHeaderDraggableContainer': {
        '& span': {
          top: '-25px',
          left: '13px',
          cursor: 'pointer',
          zIndex: '1',
          position: 'absolute',
          '@media (max-width: 1500px)': {
            top: '-33px',
            left: '40px !important',
          },
          '@media (max-width: 1200px)': {
            top: '-32px',
          },
          '@media (max-width: 1199px)': {
            top: '-22px',
          },
          '@media (max-width: 767px)': {
            top: '12px',
          },
          '@media (max-width: 450px)': {
            top: '0px',
          },
        },
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
        '@media (min-width: 1200px)': {
          minHeight: 65,
        },
      },
    },
    '& .annualScoreHeader, .annualScoreCell': {
      width: '8vw !important',
      minWidth: '8vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '8vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      justifyContent: 'space-around',
      alignItems: 'center',
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '120px !important',
        minWidth: '120px !important',
      },
      '@media (min-width: 450px) and (max-width: 900px)': {
        minHeight: '70px !important',
        maxHeight: '70px !important',
        height: '70px !important',
        lineHeight: '18px !important',
      },
      '& .MuiDataGrid-columnHeaderDraggableContainer': {
        '& span': {
          top: '-25px',
          left: '13px',
          cursor: 'pointer',
          zIndex: '1',
          position: 'absolute',
          '@media (max-width: 1500px)': {
            top: '-33px',
            left: '40px !important',
          },
          '@media (max-width: 1200px)': {
            top: '-32px',
          },
          '@media (max-width: 1199px)': {
            top: '-22px',
          },
          '@media (max-width: 767px)': {
            top: '12px',
          },
          '@media (max-width: 450px)': {
            top: '0px',
          },
        },
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
        '@media (min-width: 1200px)': {
          minHeight: 65,
        },
      },
      '& .MuiIconButton-root': {
        marginLeft: '-50px',
        position: 'absolute',
        marginTop: '-15px',
      },
    },
    '& .borderCell': {
      width: '8vw !important',
      minWidth: '8vw !important',
      borderRight: '0.1vw solid #ccc !important',
      '@media (min-width: 1200px)': {
        maxWidth: '8vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
        minHeight: '4vw !important',
      },
      minHeight: '3vw !important',
      justifyContent: 'space-around',
      alignItems: 'center',
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '120px !important',
        minWidth: '120px !important',
      },
      '@media (min-width: 450px) and (max-width: 900px)': {
        minHeight: '70px !important',
        maxHeight: '70px !important',
        height: '70px !important',
        lineHeight: '18px !important',
      },
      '& .MuiDataGrid-columnHeaderDraggableContainer': {
        '& span': {
          top: '-25px',
          left: '13px',
          cursor: 'pointer',
          zIndex: '1',
          position: 'absolute',
          '@media (max-width: 1500px)': {
            top: '-33px',
            left: '40px !important',
          },
          '@media (max-width: 1200px)': {
            top: '-32px',
          },
          '@media (max-width: 1199px)': {
            top: '-22px',
          },
          '@media (max-width: 767px)': {
            top: '12px',
          },
          '@media (max-width: 450px)': {
            top: '0px',
          },
        },
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
        '@media (min-width: 1200px)': {
          minHeight: 65,
        },
      },
    },
    '& .homeworkHeader, .homeworkCell': {
      width: '8vw !important',
      minWidth: '8vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '8vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      justifyContent: 'space-around',
      alignItems: 'center',
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '120px !important',
        minWidth: '120px !important',
      },
      '@media (min-width: 450px) and (max-width: 900px)': {
        minHeight: '70px !important',
        maxHeight: '70px !important',
        height: '70px !important',
        lineHeight: '18px !important',
      },
      '& .MuiDataGrid-columnHeaderDraggableContainer': {
        '& span': {
          top: '-25px',
          left: '0px !important',
          cursor: 'pointer',
          zIndex: '1',
          position: 'absolute',
          '@media (max-width: 1500px)': {
            top: '-33px',
            left: '24px !important',
          },
          '@media (max-width: 1200px)': {
            top: '-32px',
            left: '29px !important',
          },
          '@media (max-width: 1199px)': {
            top: '-22px',
          },
          '@media (max-width: 767px)': {
            top: '12px',
          },
          '@media (max-width: 450px)': {
            top: '0px',
          },
        },
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
        '@media (min-width: 1200px)': {
          minHeight: 65,
        },

      },
    },
    '& .bonusHeader, .bonusCell': {
      width: '6vw !important',
      justifyContent: 'center !important',
      minWidth: '8vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '8vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '120px !important',
        minWidth: '120px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
      '& .MuiDataGrid-cellContent': {
        color: '#fff',
        backgroundColor: '#9cc962',
        borderRadius: '0.3vw',
        textAlign: 'center',
        padding: '0.5vw',
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          width: '1.5vw',
          height: '1.5vw',
        },
        '@media (max-width: 1199px)': {
          padding: '3px 5px',
          fontSize: '12px',
        },
      },
    },
    '& .annualHeader, .annualCell': {
      width: '9vw !important',
      minWidth: '9vw !important',
      justifyContent: 'center !important',
      '@media (min-width: 1200px)': {
        maxWidth: '9vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '130px !important',
        minWidth: '130px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .gradeHeader, .gradeCell': {
      width: '4vw !important',
      minWidth: '5vw !important',
      justifyContent: 'center !important',
      '@media (min-width: 1200px)': {
        maxWidth: '5vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
        paddingLeft: '17px',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '120px !important',
        minWidth: '120px !important',
        paddingLeft: '17px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .gpaHeader, .gpaCell': {
      width: '5vw !important',
      minWidth: '5vw !important',
      justifyContent: 'center !important',
      '@media (min-width: 1200px)': {
        maxWidth: '5vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '120px !important',
        fontSize: '12px',
        maxWidth: '120px !important',
        minWidth: '120px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .actionHeader, .actionCell': {
      width: '5vw !important',
      minWidth: '4vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '5vw !important',
        fontSize: '0.9vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '300px !important',
        fontSize: '12px',
        maxWidth: '300px !important',
        minWidth: '300px !important',
      },
      '@media (max-width: 1300px)': {
        width: '500px !important',
        fontSize: '12px',
        maxWidth: '500px !important',
        minWidth: '500px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        width: '120px !important',
        minWidth: '120px !important',
        maxWidth: '120px !important',
      },
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
      outlineOffset: 0,
    },
    '& .MuiTablePagination-root': {
      '& svg': {
        [theme.breakpoints.down('md')]: {
          width: '20px !important',
          height: '20px !important',
        },
      },
      '& .MuiTablePagination-displayedRows': {
        fontSize: '1vw !important',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px !important',
        },
      },
    },
  },
  emailIconColor: {
    borderRadius: '0.4vw !important',
    position: 'relative',
    padding: '0.5vw',
    '& svg': {
      width: '1.5vw',
      height: '1.5vw',
      color: colors.blue,
      [theme.breakpoints.down('md')]: {
        width: 16,
        height: 16,
      },
    },
    '&:hover': {
      backgroundColor: 'white !important',
      minHeight: '3px !important',
    },
  },
  marksBlock: {
    display: 'flex !important',
  },
  marksRed: {
    color: '#fff',
    backgroundColor: colors.errorText,
    width: '3vw',
    height: '2vw',
    padding: '0.6vw 0.2vw 0vw 0.2vw',
    fontSize: '0.9vw',
    textAlign: 'center',
    borderRadius: '0.3vw',
    '@media (max-width: 1199px)': {
      fontSize: '12px',
      width: '39px',
      height: '22px',
    },
  },
  marksGreen: {
    color: '#fff',
    backgroundColor: colors.lightGreen,
    width: '3vw',
    height: '2vw',
    padding: '0.6vw 0.2vw 0vw 0.2vw',
    fontSize: '0.9vw',
    textAlign: 'center',
    borderRadius: '0.3vw',
    '@media (max-width: 1199px)': {
      fontSize: '12px',
      width: '39px',
      height: '22px',
    },
  },
  homeworkColor: {
    color: '#fff',
    backgroundColor: colors.homeworkShade,
    width: '3vw',
    height: '2vw',
    padding: '0.6vw 0.2vw 0vw 0.2vw',
    fontSize: '0.9vw',
    textAlign: 'center',
    borderRadius: '0.3vw',
    '@media (max-width: 1199px)': {
      fontSize: '12px',
      width: '39px',
      height: '22px',
    },
  },
  assignmentsColor: {
    color: '#fff',
    backgroundColor: colors.assignmentsShade,
    width: '3vw',
    height: '2vw',
    padding: '0.6vw 0.2vw 0vw 0.2vw',
    fontSize: '0.9vw',
    textAlign: 'center',
    borderRadius: '0.3vw',
    '@media (max-width: 1199px)': {
      fontSize: '12px',
      width: '39px',
      height: '22px',
    },
  },
  quizColor: {
    color: '#fff',
    backgroundColor: colors.quizShade,
    width: '3vw',
    height: '2vw',
    padding: '0.6vw 0.2vw 0vw 0.2vw',
    fontSize: '0.9vw',
    textAlign: 'center',
    borderRadius: '0.3vw',
    '@media (max-width: 1199px)': {
      fontSize: '12px',
      width: '39px',
      height: '22px',
    },
  },
  curricularColor: {
    color: '#fff',
    backgroundColor: colors.curricularShade,
    width: '3vw',
    height: '2vw',
    padding: '0.6vw 0.2vw 0vw 0.2vw',
    fontSize: '0.9vw',
    textAlign: 'center',
    borderRadius: '0.3vw',
    '@media (max-width: 1199px)': {
      fontSize: '12px',
      width: '39px',
      height: '22px',
    },
  },
  marksYellow: {
    color: '#fff',
    backgroundColor: colors.yellow,
    width: '3vw',
    height: '2vw',
    padding: '0.6vw 0.2vw 0vw 0.2vw',
    fontSize: '0.9vw',
    textAlign: 'center',
    borderRadius: '0.3vw',
    '@media (max-width: 1199px)': {
      fontSize: '12px',
      width: '39px',
      height: '22px',
    },
  },
  marksGrey: {
    color: '#fff',
    backgroundColor: colors.grey,
    width: '3vw',
    height: '2vw',
    padding: '0.6vw 0.2vw 0vw 0.2vw',
    fontSize: '0.9vw',
    textAlign: 'center',
    borderRadius: '0.3vw',
    '@media (max-width: 1199px)': {
      fontSize: '12px',
      width: '39px',
      height: '22px',
    },
  },
  button: {
    justifyContent: 'flex-end',
    margin: '10px 4px 3px 1px',
    width: '98%',
  },
  setGreen: {
    color: colors.lightGreen,
  },
  innerContainer: {
    margin: '0.3vw 0.6vw',
    fontFamily: fonts.fontType.roboto,
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      width: '97%',
      margin: '0 0 0 10px',
      '& .MuiFormControl-root': {
        margin: '10px 0 0 0',
      },
    },
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.692vw',
        borderRadius: '0.4vw',
        width: '100%',
      },
      '& .MuiSelect-select': {
        minHeight: '0vw !important',
      },
    },
    '& .MuiFormControl-root .MuiInputLabel-root': {
      lineHeight: '0.9vw !important',
      fontSize: '0.9vw',
      color: `${colors.primary} !important`,
    },
  },
  form: {
    marginTop: '0 !important',
  },
  changeGridLayout: {
    minWidth: '800px',
    '& .MuiFormControl-root': {
      width: '100%',
      '& .MuiInputLabel-root': {
        fontSize: '0.9vw',
        lineHeight: '1vw !important',
        transform: 'translate(0.7vw, 0.9vw)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(0.7vw, -0.5vw)',
          backgroundColor: `${colors.white} !important`,
          backgroundImage: 'none !important',
          '@media (max-width: 1200px)': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          fontSize: '0.7vw',
          color: '#104F96',

        },
      },
    },
    '& .MuiGrid-root.MuiGrid-container': {
      maxWidth: 'inherit !important',
      paddingLeft: '1vw',
    },
    '& .MuiGrid-root.MuiGrid-grid-md-3, & .MuiGrid-root.MuiGrid-grid-xs-3': {
      maxWidth: '25% !important',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        height: '1.2vw',
        fontSize: '0.9vw',
        padding: '0.7vw',
        transform: 'translate(0vw, -0.1vw)',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& input.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
        backgroundColor: '#fff',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  switchSection: {
    textAlign: 'center',
    '& .MuiTypography-h5': {
      fontSize: '0.9vw',
    },
  },
  switchUi: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    '& p': {
      margin: '0 8px !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px !important',
      },
    },
    '& span': {
      marginTop: '0 !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px !important',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked+.MuiSwitch-track': {
        background: colors.lightGreen,
      },
    },
    '& .MuiSwitch-thumb': {
      width: 10,
      height: 10,
    },
  },
  marksDropDown: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      width: '100px !important',
      marginRight: '10px !important',
    },
  },
  addStyleHead: {
    '& p': {
      color: 'hsl(212deg 81% 33%)',
      fontSize: '0.9vw',
      textAlign: 'left',
      fontWeight: 'bold',
    },
  },
  alignHorCenter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    '& p': {
      marginBottom: 0,
      fontSize: '0.9vw',
    },
  },
  secButtonNew: {
    marginRight: '20px',
    marginTop: '10px',
  },
  marksTotal: {
    display: 'flex',
    '& span': {
      width: '11.5vw !important',
      fontSize: '16px',
      maxWidth: '13vw !important',
      minWidth: '13vw !important',
      marginRight: '1.8vw',
      paddingTop: '1vw',
      paddingLeft: '2vw',
      '@media (max-width: 1450px)': {
        minWidth: '13.2vw !important',
      },
      '@media (max-width: 1300px)': {
        maxWidth: '13.5vw !important',
        minWidth: '13.5vw !important',
      },
      '@media (max-width: 1250px)': {
        maxWidth: '15.5vw !important',
        minWidth: '14vw !important',
      },
    },
    '& .MuiInputBase-formControl': {
      width: '47% !important',
      '& input': {
        padding: '0.7vw',
        '@media (max-width: 1300px)': {
          padding: '0.4vw',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      '& legend': {
        maxWidth: '48px',
      },
    },
  },
  wrappingCell: {
    '@media (min-width: 1200px)': {
      width: '16vw',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  marksUpdatePopup: {
    '&  .MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper': {
      top: '55%',
      left: '45%',
    },
  },
  addMakupMarksPopup: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid',
      borderRadius: 'unset',
      borderColor: 'unset',
      border: 'transparent',
    },
    '& .popupBtn': {
      '& .makeStyles-dialog .MuiButton-textPrimary': {
        minWidth: '9vw !important',
      },
    },
  },
  popOverMark: {
    '& .MuiTypography-root': {
      cursor: 'pointer',
    },
  },
  popupBtnDisable: {
    opacity: '.6',
    '& button': {
      cursor: 'not-allowed',
    },
  },
  noData: {
    textAlign: 'center',
    marginTop: '7vw',
    width: '100%',
  },
}));

export default StudentClassStyle;
