/* eslint-disable */
/* eslint-disable import/prefer-default-export */
import {
  getPlaceInformationService
} from '../store/services/mapMethods';

const { google } = window;

const getPlaceInformation = async (address) => {
  const response = await getPlaceInformationService({"address": address});
  return response.data.results[0].geometry.location;
};
const getParentPlaceInformation = async (address) => {
  const oInformation = await getPlaceInformationService({"address": address});
  const response = { country: '', lat: '', lng:'' }
  if (oInformation.data.results[0] !== undefined) {
    const { lat, lng } = oInformation.data.results[0]?.geometry?.location;
    let country;
    for (let i = 0; i < oInformation.data.results[0]?.address_components?.length; i++) {
      for (let j = 0; j < oInformation.data.results[0]?.address_components[i]?.types?.length; j++) {
        if (oInformation.data.results[0]?.address_components[i]?.types[j] === 'country') {
          country = oInformation.data.results[0]?.address_components[i]?.long_name;
        }
      }
    }
    response.country = country;
    response.lat= lat;
    response.lng=lng;
  }
  return response;
};
const getPlaceInformationWithCity = async (address) => {
  const response = await getPlaceInformationService({"address": address});
  let city;
  let state;
  let country;
  for (let i = 0; i < response.data.results[0].address_components.length; i++) {
    for (let j = 0; j < response.data.results[0].address_components[i].types.length; j++) {
      switch (response.data.results[0].address_components[i].types[j]) {
        case 'locality':
          city = response.data.results[0].address_components[i].long_name;
          break;
        case 'administrative_area_level_1':
          state = response.data.results[0].address_components[i].long_name;
          break;
        case 'country':
          country = response.data.results[0].address_components[i].long_name;
          break;
        default:
      }
    }
  }
  const { lat, lng } = response.data.results[0].geometry.location;

  // checking for postal code
  const latlng = new google.maps.LatLng(lat, lng);
  const geocoder = new google.maps.Geocoder();

  const zipCodePromiss = () => (new Promise((res) => {
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      let zipcodeNo;
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] === 'postal_code') {
              zipcodeNo = res(results[0].address_components[j].short_name);
            }
          }
        }
      }
      return zipcodeNo;
    });
  }));

  const zipCode = await zipCodePromiss();

  const data = {
    address: response.data.results[0].formatted_address,
    city,
    state,
    country,
    zipCode,
    longitude: lng,
    latitude: lat,
  };
  return data;
};
export {
  getPlaceInformation,
  getParentPlaceInformation,
  getPlaceInformationWithCity,
};
