import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
} from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { AntSwitch } from '../../../location-coordinator/update-marks/style';
import style from './style';
import {
  getMarksDetails,
  getStudentEnrollmentHistory,
} from '../../../../store/actions/getStudent';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import {
  updateStudentHomeworkMarks,
  updateStudentMarks,
} from '../../../../store/actions/getLocationCoordinator';
import { checkFloatOrIntGreaterThanZero } from '../../../../utils/methods';
import { updateCurricularActivitiesMarks } from '../../../../store/actions/curricular-activities';
import { updateAssignmentsMarks } from '../../../../store/actions/getAssignmentsSchema';
import { updateQuizMarks } from '../../../../store/actions/quizSchema';
import { Dropdown } from '../../../../components/atoms';

function AssignScore({
  openMarksPopUp,
  setOpenMarksPopUp,
  dataAssignScore,
  assignLoading,
  setLoadingSpinner,
  setDataAssignScore,
}) {
  const marksValidation = {};
  const marksInitial = {};
  const maxMarksValues = {};
  const classes = style();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPresent, setIsPresent] = useState(true);
  const [bChangeQuarter, setBchangeQuarter] = useState(false);
  const [quizName, setQuizName] = useState('');
  const [isMarksTotal, setMarksTotal] = useState(0);
  const [isHomeworkTotal, setHomeworkTotal] = useState(0);
  const [sCourseName, setScourseName] = useState('');
  const [sQuarterName, setSquarterName] = useState('');
  const [isValueChanged, setIsValuechanged] = useState(false);
  const allEnrollDetails = useSelector((state) => state.getStudent.enrollmentDetails);
  const enrollStudentDetails = Object.keys(allEnrollDetails).length ? allEnrollDetails[dataAssignScore?.rowData?.id] : {};
  const [weightage, setWeightage] = useState(0);
  const [enrollDetails, setEnrollDetails] = useState([]);
  const [quizOptions, setQuizOptions] = useState([{ id: 'Quiz 1', name: 'Quiz 1' }, { id: 'Quiz 2', name: 'Quiz 2' }]);
  const [selectedQuiz, setSelectedQuiz] = useState('Quiz 1');

  React.useEffect(() => {
    setQuizName(dataAssignScore.quizName);
  }, [dataAssignScore?.quizName]);

  React.useEffect(() => {
    if (sQuarterName === 'S1' && dataAssignScore?.updateType === 'quiz1-marks') {
      setSelectedQuiz('Quiz 1');
    } else if (sQuarterName === 'S2' && dataAssignScore?.updateType === 'quiz1-marks') {
      setSelectedQuiz('Quiz 3');
    } else if (sQuarterName === 'S1' && dataAssignScore?.updateType === 'quiz2-marks') {
      setSelectedQuiz('Quiz 2');
    } else if (sQuarterName === 'S2' && dataAssignScore?.updateType === 'quiz2-marks') {
      setSelectedQuiz('Quiz 4');
    }
  }, [sQuarterName, dataAssignScore?.updateType]);

  React.useEffect(() => {
    if (sQuarterName === 'S1') {
      setQuizOptions([{ id: 'Quiz 1', name: 'Quiz 1' }, { id: 'Quiz 2', name: 'Quiz 2' }]);
    } else if (sQuarterName === 'S2') {
      setQuizOptions([{ id: 'Quiz 3', name: 'Quiz 3' }, { id: 'Quiz 4', name: 'Quiz 4' }]);
    }
  }, [sQuarterName]);

  const refreshList = () => {
    setLoadingSpinner(true);
    setOpenMarksPopUp(false);
    dataAssignScore.setAssignLoading(false);
    dispatch(getMarksDetails({
      academicYear: dataAssignScore.rowData.enrolled_courses.academicYear,
      locationId: dataAssignScore.rowData.enrolled_courses.location.id,
      courseId: dataAssignScore?.rowData?.enrolled_courses?.course.id,
      studentId: dataAssignScore.rowData.id,
    }, setLoadingSpinner));
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
      courseName: '',
      quarterName: dataAssignScore?.quarterValue,
      ...marksInitial,
    },
    onSubmit: (values) => {
      const aMarks = [];
      const aStudentDetailMarks = [];
      const aHomeworkMarks = [];
      const aStudentDetailHomeworkMarks = [];
      const payload = values;
      formik.resetForm();
      setBchangeQuarter(false);
      if (dataAssignScore?.updateType === 'marks') {
        Object.keys(values).forEach((key) => {
          if (key.substr(-20, 10) === 'marksField') {
            aMarks.push(values[key]);
          }
        });
        if (!isPresent) {
          enrollDetails?.forEach((item) => {
            aStudentDetailMarks.push({
              academicPanelMarksId: item.panelMarksId,
              obtainedMarks: 0,
            });
          });
        } else {
          enrollDetails?.forEach((item, i) => {
            aStudentDetailMarks.push({
              academicPanelMarksId: item.panelMarksId,
              obtainedMarks: parseFloat(aMarks[i], 10) || 0,
            });
          });
        }
        payload.studentDetailMarks = aStudentDetailMarks;
        payload.academicYear = dataAssignScore.rowData.enrolled_courses.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.academic_panel?.maximumMarks;
        payload.isPresent = isPresent;
        payload.locationId = dataAssignScore.rowData.enrolled_courses.location.id;
        payload.isAttended = isPresent;
        payload.quarterName = sQuarterName !== '' ? sQuarterName : dataAssignScore?.quarterValue;
        payload.studentId = dataAssignScore.rowData.id;
        payload.courseName = dataAssignScore?.rowData?.enrolled_courses?.course.id;
        setLoadingSpinner(true);
        dataAssignScore.setAssignLoading(true);

        updateStudentMarks(payload, refreshList);
      } else if (dataAssignScore?.updateType === 'homework') {
        Object.keys(values).forEach((key) => {
          if (key.substr(-20, 10) === 'marksField') {
            aHomeworkMarks.push(values[key]);
          }
        });

        enrollDetails?.forEach((item, i) => {
          aStudentDetailHomeworkMarks.push({
            homeworkPanelMarksId: item?.panelMarksId,
            obtainedMarks: parseFloat(aHomeworkMarks[i], 10) || 0,
          });
        });

        payload.studentHomeworkMarksDetail = aStudentDetailHomeworkMarks;
        payload.academicYear = dataAssignScore?.rowData?.enrolled_courses?.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.homeworkPanel?.maximumMarks;
        payload.isPresent = true;
        payload.locationId = dataAssignScore.rowData.enrolled_courses.location.id;
        payload.isAttended = true;
        payload.quarterName = sQuarterName !== '' ? sQuarterName : dataAssignScore?.quarterValue;
        payload.studentId = dataAssignScore.rowData.id;
        payload.courseName = dataAssignScore?.rowData?.enrolled_courses?.course.id;
        setLoadingSpinner(true);
        dataAssignScore.setAssignLoading(true);
        dispatch(updateStudentHomeworkMarks(payload, refreshList));
      } else if (dataAssignScore?.updateType === 'curricular-marks') {
        Object.keys(values).forEach((key) => {
          if (key.substr(-20, 10) === 'marksField') {
            aMarks.push(values[key]);
          }
        });
        if (!isPresent) {
          enrollDetails?.forEach((item) => {
            aStudentDetailMarks.push({
              id: item.panelMarksId,
              attendenceActivitiesSchemaId: item?.schemaId,
              obtainedMarks: 0,
            });
          });
        } else {
          enrollDetails?.forEach((item, i) => {
            aStudentDetailMarks.push({
              id: item.panelMarksId,
              attendenceActivitiesSchemaId: item?.schemaId,
              obtainedMarks: parseFloat(aMarks[i], 10) || 0,
            });
          });
        }
        payload.studentAttendenceActivitiesDetailMarks = aStudentDetailMarks;
        payload.academicYear = dataAssignScore.rowData.enrolled_courses.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.academic_panel?.maximumMarks;
        payload.isPresent = isPresent;
        payload.locationId = dataAssignScore.rowData.enrolled_courses.location.id;
        payload.isAttended = isPresent;
        payload.quarterName = sQuarterName !== '' ? sQuarterName : dataAssignScore?.quarterValue;
        payload.studentId = dataAssignScore.rowData.id;
        payload.courseName = dataAssignScore?.rowData?.enrolled_courses?.course.id;
        setLoadingSpinner(true);
        dataAssignScore.setAssignLoading(true);
        dispatch(updateCurricularActivitiesMarks(payload, refreshList));
      } else if (dataAssignScore?.updateType === 'assignments') {
        Object.keys(values).forEach((key) => {
          if (key.substr(-20, 10) === 'marksField') {
            aMarks.push(values[key]);
          }
        });
        if (!isPresent) {
          enrollDetails?.forEach((item) => {
            aStudentDetailMarks.push({
              id: item.panelMarksId,
              assignmentSchemaId: item?.schemaId,
              obtainedMarks: 0,
            });
          });
        } else {
          enrollDetails?.forEach((item, i) => {
            aStudentDetailMarks.push({
              id: item.panelMarksId,
              assignmentSchemaId: item?.schemaId,
              obtainedMarks: parseFloat(aMarks[i], 10) || 0,
            });
          });
        }
        payload.studentAssignmentDetailMarks = aStudentDetailMarks;
        payload.academicYear = dataAssignScore.rowData.enrolled_courses.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.academic_panel?.maximumMarks;
        payload.isPresent = isPresent;
        payload.locationId = dataAssignScore.rowData.enrolled_courses.location.id;
        payload.isAttended = isPresent;
        payload.quarterName = sQuarterName !== '' ? sQuarterName : dataAssignScore?.quarterValue;
        payload.studentId = dataAssignScore.rowData.id;
        payload.courseName = dataAssignScore?.rowData?.enrolled_courses?.course.id;
        setLoadingSpinner(true);
        dataAssignScore.setAssignLoading(true);
        dispatch(updateAssignmentsMarks(payload, refreshList));
      } else if (dataAssignScore?.updateType === 'quiz1-marks') {
        Object.keys(values).forEach((key) => {
          if (key.substr(-20, 10) === 'marksField') {
            aMarks.push(values[key]);
          }
        });

        enrollDetails?.forEach((item, i) => {
          aStudentDetailMarks.push({
            id: item.panelMarksId,
            quizSchemaId: item?.schemaId,
            obtainedMarks: parseFloat(aMarks[i], 10) || 0,
          });
        });

        payload.studentQuizDetailMarks = aStudentDetailMarks;
        payload.academicYear = dataAssignScore.rowData.enrolled_courses.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.academic_panel?.maximumMarks;
        payload.isPresent = isPresent;
        payload.locationId = dataAssignScore.rowData.enrolled_courses.location.id;
        payload.isAttended = isPresent;
        payload.quarterName = sQuarterName !== '' ? sQuarterName : dataAssignScore?.quarterValue;
        payload.studentId = dataAssignScore.rowData.id;
        payload.courseName = dataAssignScore?.rowData?.enrolled_courses?.course.id;
        payload.quiz = quizName;
        setLoadingSpinner(true);
        dataAssignScore.setAssignLoading(true);
        dispatch(updateQuizMarks(payload, refreshList));
      } else if (dataAssignScore?.updateType === 'quiz2-marks') {
        Object.keys(values).forEach((key) => {
          if (key.substr(-20, 10) === 'marksField') {
            aMarks.push(values[key]);
          }
        });

        enrollDetails?.forEach((item, i) => {
          aStudentDetailMarks.push({
            id: item.panelMarksId,
            quizSchemaId: item?.schemaId,
            obtainedMarks: parseFloat(aMarks[i], 10) || 0,
          });
        });

        payload.studentQuizDetailMarks = aStudentDetailMarks;
        payload.academicYear = dataAssignScore.rowData.enrolled_courses.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.academic_panel?.maximumMarks;
        payload.isPresent = isPresent;
        payload.locationId = dataAssignScore.rowData.enrolled_courses.location.id;
        payload.isAttended = isPresent;
        payload.quarterName = sQuarterName !== '' ? sQuarterName : dataAssignScore?.quarterValue;
        payload.studentId = dataAssignScore.rowData.id;
        payload.courseName = dataAssignScore?.rowData?.enrolled_courses?.course.id;
        payload.quiz = quizName;
        setLoadingSpinner(true);
        dataAssignScore.setAssignLoading(true);
        dispatch(updateQuizMarks(payload, refreshList));
      }
    },
  });

  const fetchMarks = () => {
    let details = [];
    if (dataAssignScore?.updateType === 'marks') {
      details = enrollStudentDetails?.marks?.[0] ? enrollStudentDetails?.marks?.[0] : enrollStudentDetails;
    } else if (dataAssignScore?.updateType === 'homework') {
      details = enrollStudentDetails?.homeworkMarks?.[0] ? enrollStudentDetails?.homeworkMarks?.[0] : enrollStudentDetails;
    } else if (dataAssignScore?.updateType === 'curricular-marks') {
      details = enrollStudentDetails?.attendenceActivitiesMarks?.[0] ? enrollStudentDetails?.attendenceActivitiesMarks?.[0] : enrollStudentDetails;
    } else if (dataAssignScore?.updateType === 'assignments') {
      details = enrollStudentDetails?.assignmentMarks?.[0] ? enrollStudentDetails?.assignmentMarks?.[0] : enrollStudentDetails;
    } else if (dataAssignScore?.updateType === 'quiz1-marks') {
      details = enrollStudentDetails?.quizPanelQ1Marks?.[0] ? enrollStudentDetails?.quizPanelQ1Marks?.[0] : enrollStudentDetails;
    } else if (dataAssignScore?.updateType === 'quiz2-marks') {
      details = enrollStudentDetails?.quizPanelQ2Marks?.[0] ? enrollStudentDetails?.quizPanelQ2Marks?.[0] : enrollStudentDetails;
    }
    return details;
  };

  React.useEffect(() => {
    setIsValuechanged(false);
    setMarksTotal(0);
    setHomeworkTotal(0);
    setEnrollDetails([]);
    // eslint-disable-next-line no-nested-ternary
    const details = fetchMarks();
    let marks = [];
    if (dataAssignScore?.updateType === 'marks') {
      const setAscending = details?.academicPanel?.academic_panel_marks?.sort((a, b) => a.category - b.category);
      const studentDetailMarks = details?.studentDetailMarks?.sort((a, b) => a.academicPanelMarks.category - b.academicPanelMarks.category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        obtainedMarks: (checkFloatOrIntGreaterThanZero(studentDetailMarks?.[index]?.obtainedMarks) ? studentDetailMarks?.[index]?.obtainedMarks : ''),
        notes: o1?.notes,
        category: o1?.category,
      }));
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
    } else if (dataAssignScore?.updateType === 'homework') {
      const studentHomeworkMarksDetail = details?.studentHomeworkMarksDetail?.map((e) => ({
        week: e?.homeworkPanelMarks?.week,
        obtainedMarks: e?.obtainedMarks,
        id: e?.homeworkPanelMarks?.id,
      }));
      const studentHomeworkMarks = studentHomeworkMarksDetail?.sort((a, b) => {
        const getWeekNumber = (week) => {
          if (!week) return 0;
          const parts = week.split(' ');
          return parts.length > 1 ? parseInt(parts[1], 10) : parseInt(parts[0], 10);
        };
        const weekA = getWeekNumber(a.week);
        const weekB = getWeekNumber(b.week);
        return weekA - weekB;
      });
      marks = enrollStudentDetails?.homeworkPanel?.homework_panel_marks?.map((o1) => {
        const index = studentHomeworkMarks?.findIndex((o2) => o2?.id === o1?.id);
        const sameWeek = index !== -1;
        const obtainedMarks = (checkFloatOrIntGreaterThanZero(studentHomeworkMarks?.[index]?.obtainedMarks) && sameWeek) ? studentHomeworkMarks?.[index]?.obtainedMarks : '';
        return {
          panelMarksId: o1?.id,
          maxMarks: o1?.marks,
          obtainedMarks,
          notes: o1?.notes,
          category: o1?.week,
        };
      });
      setWeightage(details?.homeworkPanel?.weightage);
    } else if (dataAssignScore?.updateType === 'curricular-marks') {
      const curricularObj = details?.attendenceActivitesPanelList ? details?.attendenceActivitesPanelList?.attendence_activities_schema_marks : details?.attendenceActivitiesSchema?.attendenceActivitiesSchemaMarks;
      const setAscending = curricularObj?.sort((a, b) => a.category - b.category);
      const studentDetailMarks = details?.attendenceActivitiesSchema?.studentAttendenceActivitiesMarksDetail?.sort((a, b) => a.studentAttendenceActivitiesMarksDetail[0].category - b.studentAttendenceActivitiesMarksDetail[0].category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        schemaId: o1?.attendenceActivitiesSchemaId,
        obtainedMarks: checkFloatOrIntGreaterThanZero(studentDetailMarks?.[index]?.studentAttendenceActivitiesMarksDetail?.[0].obtainedMarks) ? studentDetailMarks?.[index]?.studentAttendenceActivitiesMarksDetail?.[0].obtainedMarks : '',
        notes: o1?.notes,
        category: o1?.category,
      }));
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
    } else if (dataAssignScore?.updateType === 'assignments') {
      const assignmentObj = details?.assignmentPanelList ? details?.assignmentPanelList : details?.assignmentSchema;
      const setAscending = assignmentObj?.assignment_marks_schemas?.sort((a, b) => a.category - b.category);
      const studentDetailMarks = details?.studentAssignmentDetailMarks?.sort((a, b) => a.assignmentMarksSchema.category - b.assignmentMarksSchema.category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        schemaId: o1?.assignmentSchemaId || details?.assignmentSchemaId,
        obtainedMarks: checkFloatOrIntGreaterThanZero(studentDetailMarks?.[index]?.obtainedMarks) ? studentDetailMarks?.[index]?.obtainedMarks : '',
        notes: o1?.notes,
        category: o1?.category,
      }));
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
    } else if (dataAssignScore?.updateType === 'quiz1-marks') {
      const quizObj = details?.quizPanelQ1List ? details?.quizPanelQ1List : details?.quizSchema;
      const setAscending = quizObj?.quiz_schema_marks?.sort((a, b) => a.category - b.category);
      const quizDetailMarks = details?.studentQuizDetailMarks?.sort((a, b) => a.quizSchemaMarks.category - b.quizSchemaMarks.category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        schemaId: o1?.quizSchemaId || quizDetailMarks?.[index]?.studentQuizMarksId,
        obtainedMarks: checkFloatOrIntGreaterThanZero(quizDetailMarks?.[index]?.obtainedMarks) ? quizDetailMarks?.[index]?.obtainedMarks : '',
        notes: o1?.notes,
        category: o1?.category,
      }));
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
    } else if (dataAssignScore?.updateType === 'quiz2-marks') {
      const quizObj = details?.quizPanelQ2List ? details?.quizPanelQ2List : details?.quizSchema;
      const setAscending = quizObj?.quiz_schema_marks?.sort((a, b) => a.category - b.category);
      const quizDetailMarks = details?.studentQuizDetailMarks?.sort((a, b) => a.quizSchemaMarks.category - b.quizSchemaMarks.category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        schemaId: o1?.quizSchemaId || quizDetailMarks?.[index]?.studentQuizMarksId,
        obtainedMarks: checkFloatOrIntGreaterThanZero(quizDetailMarks?.[index]?.obtainedMarks) ? quizDetailMarks?.[index]?.obtainedMarks : '',
        notes: o1?.notes,
        category: o1?.category,
      }));
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
    }
    setEnrollDetails(marks);
  }, [enrollStudentDetails, quizName]);

  React.useEffect(() => {
    setMarksTotal(0);
    setHomeworkTotal(0);
    if (enrollDetails && enrollDetails.length > 0) {
      if (dataAssignScore?.updateType === 'marks') {
        let marks = 0;
        enrollDetails?.forEach((elem, i) => {
          marksValidation[`marksField-${i}`] = Yup.number()
            .required('Required')
            .typeError('Required');
          let obtainedMarks = 0;
          if (elem?.obtainedMarks !== '' && elem?.obtainedMarks <= elem.maxMarks) {
            obtainedMarks = elem?.obtainedMarks;
          } else if (elem?.obtainedMarks !== '' && elem?.obtainedMarks > elem.maxMarks) {
            obtainedMarks += elem.maxMarks;
          }
          maxMarksValues[`marksField-${i}`] = elem?.maxMarks;
          formik.setFieldValue(`marksField-${i}`, obtainedMarks);
          marks += obtainedMarks;
        });
        setMarksTotal(parseFloat(marks, 10));
        if (bChangeQuarter === false) {
          setSquarterName(dataAssignScore.quarterValue);
        }
        setScourseName(sCourseName === '' || sCourseName === undefined ? dataAssignScore.courseName : sCourseName);
      } else if (dataAssignScore?.updateType === 'homework') {
        let homeworkmarks = 0;
        enrollDetails?.forEach((elem, i) => {
          marksValidation[`marksField-${i}`] = Yup.number()
            .required('Required')
            .typeError('Required');
          let obtainedHWMarks = 0;
          if (elem?.obtainedMarks !== '' && elem?.obtainedMarks <= elem.maxMarks) {
            obtainedHWMarks = elem?.obtainedMarks;
          } else if (elem?.obtainedMarks !== '' && elem?.obtainedMarks > elem.maxMarks) {
            obtainedHWMarks += elem.maxMarks;
          }
          maxMarksValues[`marksField-${i}`] = elem?.maxMarks;
          formik.setFieldValue(`marksField-${i}`, obtainedHWMarks);
          homeworkmarks += obtainedHWMarks;
        });
        setHomeworkTotal(parseFloat(homeworkmarks, 10));
        if (bChangeQuarter === false) {
          setSquarterName(dataAssignScore.quarterValue);
        }
        setScourseName(sCourseName === '' || sCourseName === undefined ? dataAssignScore.courseName : sCourseName);
      } else if (dataAssignScore?.updateType === 'curricular-marks') {
        let curricularMarks = 0;
        enrollDetails?.forEach((elem, i) => {
          marksValidation[`marksField-${i}`] = Yup.number()
            .required('Required')
            .typeError('Required');
          let obtainedMarks = 0;
          if (elem?.obtainedMarks !== '' && elem?.obtainedMarks <= elem.maxMarks) {
            obtainedMarks = elem?.obtainedMarks;
          } else if (elem?.obtainedMarks !== '' && elem?.obtainedMarks > elem.maxMarks) {
            obtainedMarks += elem.maxMarks;
          }
          maxMarksValues[`marksField-${i}`] = elem?.maxMarks;
          formik.setFieldValue(`marksField-${i}`, obtainedMarks);
          curricularMarks += obtainedMarks;
        });
        setMarksTotal(parseFloat(curricularMarks, 10));
        if (bChangeQuarter === false) {
          setSquarterName(dataAssignScore.quarterValue);
        }
        setScourseName(sCourseName === '' || sCourseName === undefined ? dataAssignScore.courseName : sCourseName);
      } else if (dataAssignScore?.updateType === 'assignments') {
        let marks = 0;
        enrollDetails?.forEach((elem, i) => {
          marksValidation[`marksField-${i}`] = Yup.number()
            .required('Required')
            .typeError('Required');
          let obtainedMarks = 0;
          if (elem?.obtainedMarks !== '' && elem?.obtainedMarks <= elem.maxMarks) {
            obtainedMarks = elem?.obtainedMarks;
          } else if (elem?.obtainedMarks !== '' && elem?.obtainedMarks > elem.maxMarks) {
            obtainedMarks += elem.maxMarks;
          }
          maxMarksValues[`marksField-${i}`] = elem?.maxMarks;
          formik.setFieldValue(`marksField-${i}`, obtainedMarks);
          marks += obtainedMarks;
        });
        setMarksTotal(parseFloat(marks, 10));
        if (bChangeQuarter === false) {
          setSquarterName(dataAssignScore.quarterValue);
        }
        setScourseName(sCourseName === '' || sCourseName === undefined ? dataAssignScore.courseName : sCourseName);
      } else if (dataAssignScore?.updateType === 'quiz1-marks') {
        let marks = 0;
        enrollDetails?.forEach((elem, i) => {
          marksValidation[`marksField-${i}`] = Yup.number()
            .required('Required')
            .typeError('Required');
          let obtainedMarks = 0;
          if (elem?.obtainedMarks !== '' && elem?.obtainedMarks <= elem.maxMarks) {
            obtainedMarks = elem?.obtainedMarks;
          } else if (elem?.obtainedMarks !== '' && elem?.obtainedMarks > elem.maxMarks) {
            obtainedMarks += elem.maxMarks;
          }
          maxMarksValues[`marksField-${i}`] = elem?.maxMarks;
          formik.setFieldValue(`marksField-${i}`, obtainedMarks);
          marks += obtainedMarks;
        });
        setMarksTotal(parseFloat(marks, 10));
        if (bChangeQuarter === false) {
          setSquarterName(dataAssignScore.quarterValue);
        }
        setScourseName(sCourseName === '' || sCourseName === undefined ? dataAssignScore.courseName : sCourseName);
      } else if (dataAssignScore?.updateType === 'quiz2-marks') {
        let marks = 0;
        enrollDetails?.forEach((elem, i) => {
          marksValidation[`marksField-${i}`] = Yup.number()
            .required('Required')
            .typeError('Required');
          let obtainedMarks = 0;
          if (elem?.obtainedMarks !== '' && elem?.obtainedMarks <= elem.maxMarks) {
            obtainedMarks = elem?.obtainedMarks;
          } else if (elem?.obtainedMarks !== '' && elem?.obtainedMarks > elem.maxMarks) {
            obtainedMarks += elem.maxMarks;
          }
          maxMarksValues[`marksField-${i}`] = elem?.maxMarks;
          formik.setFieldValue(`marksField-${i}`, obtainedMarks);
          marks += obtainedMarks;
        });
        setMarksTotal(parseFloat(marks, 10));
        if (bChangeQuarter === false) {
          setSquarterName(dataAssignScore.quarterValue);
        }
        setScourseName(sCourseName === '' || sCourseName === undefined ? dataAssignScore.courseName : sCourseName);
      }
    }
  }, [enrollDetails]);

  const handlePresent = () => {
    setIsPresent(!isPresent);
    setIsValuechanged(true);
  };

  const customMarkChange = (e) => {
    let marks = 0;
    enrollDetails?.forEach((elem, i) => {
      const key = `marksField-${i}`;
      if (key === e.target.id) {
        if (e.target.value <= elem.maxMarks) {
          const regx = /^\d+(\.\d{0,2})?$/;
          if (e.target.value === '' || regx.test(e.target.value)) {
            if (e.target.value !== '') {
              const num = e.target.value ? Number(parseFloat(e.target.value, 10).toFixed(2)) : 0;
              if (num === 0 && e.nativeEvent.data === '0') {
                formik.setFieldValue(`marksField-${i}`, num.toString());
              } else {
                formik.setFieldValue(`marksField-${i}`, num);
              }
              marks += num;
            } else {
              formik.setFieldValue(`marksField-${i}`, '');
            }
          } else {
            const num = e.target.value ? Number(parseFloat(e.target.value, 10).toFixed(2)) : 0;
            if (num === 0 && e.nativeEvent.data === '0') {
              formik.setFieldValue(`marksField-${i}`, num.toString());
            } else {
              formik.setFieldValue(`marksField-${i}`, num);
            }
            marks += num;
          }
        } else {
          formik.setFieldValue(`marksField-${i}`, 0);
        }
      } else if (key.includes('marksField')) {
        if (formik.values[key] && Number(formik.values[key]) <= elem.maxMarks) {
          marks += Number(formik.values[key]);
        } else if (Number(formik.values[key]) > elem.maxMarks) {
          marks += elem.maxMarks;
          formik.setFieldValue(`marksField-${i}`, elem.maxMarks);
        } else {
          marks += 0;
        }
      } else {
        marks += 0;
      }
    });
    setIsValuechanged(true);
    setMarksTotal(marks);
  };

  const customHomeworkChange = (e) => {
    let homeworkMarks = 0;
    enrollDetails?.forEach((elem, i) => {
      const key = `marksField-${i}`;
      if (key === e.target.id) {
        if (e.target.value <= elem.maxMarks) {
          const regx = /^\d+(\.\d{0,2})?$/;
          if (e.target.value === '' || regx.test(e.target.value)) {
            if (e.target.value !== '') {
              const num = e.target.value ? Number(parseFloat(e.target.value, 10).toFixed(2)) : 0;
              if (num === 0 && e.nativeEvent.data === '0') {
                formik.setFieldValue(`marksField-${i}`, num.toString());
              } else {
                formik.setFieldValue(`marksField-${i}`, num);
              }
              homeworkMarks += num;
            } else {
              formik.setFieldValue(`marksField-${i}`, '');
            }
          } else {
            const num = e.target.value ? Number(parseFloat(e.target.value, 10).toFixed(2)) : 0;
            if (num === 0 && e.nativeEvent.data === '0') {
              formik.setFieldValue(`marksField-${i}`, num.toString());
            } else {
              formik.setFieldValue(`marksField-${i}`, num);
            }
            homeworkMarks += num;
          }
        } else {
          formik.setFieldValue(`marksField-${i}`, 0);
        }
      } else if (key.includes('marksField')) {
        if (formik.values[key] && Number(formik.values[key]) <= elem.maxMarks) {
          homeworkMarks += Number(formik.values[key]);
        } else if (Number(formik.values[key]) > elem.maxMarks) {
          homeworkMarks += elem.maxMarks;
          formik.setFieldValue(`marksField-${i}`, elem.maxMarks);
        } else {
          homeworkMarks += 0;
        }
      } else {
        homeworkMarks += 0;
      }
    });
    setIsValuechanged(true);
    setHomeworkTotal(homeworkMarks);
  };

  const changeQuarter = (e) => {
    setBchangeQuarter(true);
    setSquarterName(e.target.value);
    formik.resetForm();
  };

  React.useEffect(() => {
    if (dataAssignScore && dataAssignScore.rowData && sQuarterName !== '') {
      dataAssignScore.setAssignLoading(true);
      dispatch(getStudentEnrollmentHistory({
        studentId: dataAssignScore.rowData.id,
        courseId: dataAssignScore.rowData.enrolled_courses.course.id,
        academicYear: dataAssignScore.rowData.enrolled_courses.academicYear,
        quarter: sQuarterName,
      }, dataAssignScore.setAssignLoading));
    }
  }, [sQuarterName, dataAssignScore]);
  const calculateValue = (e, i) => {
    const parsedValue = formik.values[`marksField-${i}`] ? Number(parseFloat(formik.values[`marksField-${i}`], 10).toFixed(2)) : 0;
    if (parsedValue >= 0 && parsedValue <= e?.maxMarks) {
      return parsedValue;
    }
    if (parsedValue > e?.maxMarks) {
      return e?.maxMarks;
    }
    return 0;
  };

  return (
    <Dialog
      open={openMarksPopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={clsx(classes.dialog, classes.swapCourseDialog)}
    >
      {
        assignLoading && (
          <Box sx={{
            display: 'flex', position: 'absolute', paddingLeft: '45%', paddingTop: '15%',
          }}
          >
            <CircularProgress />
          </Box>
        )
      }
      <DialogTitle id="alert-dialog-title">
        {t('ASSIGN_SCORE')}
        <CloseIcon
          onClick={() => {
            setOpenMarksPopUp(false);
            setScourseName('');
            setSquarterName('');
            setEnrollDetails([]);
            setBchangeQuarter(false);
            setQuizName('Quiz 1');
          }}
          className={classes.closeImg}
        />
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <form
            name="tenantUserForm"
            noValidate
            autoComplete="off"
            className={`${classes.changeGridLayout}`}
          >
            {
              !assignLoading && (
                <Grid container spacing={2}>
                  <Grid className={classes.performTextField1} item sm={6} md={3}>
                    <TextField
                      label={t('FULL_NAME')}
                      id="fullName"
                      name="fullName"
                      autoComplete="fullName"
                      value={dataAssignScore.fullName}
                      disabled="true"
                    />
                  </Grid>
                  <Grid className={classes.performTextField1} item sm={6} md={3}>
                    <TextField
                      label={t('COURSE')}
                      id="courseName"
                      name="courseName"
                      autoComplete="courseName"
                      value={dataAssignScore.courseName ? dataAssignScore.courseName : sCourseName}
                      disabled="true"
                    />
                  </Grid>
                  <Grid className={`${classes.performTextField1} ${classes.dropdownField}`} item sm={6} md={3}>
                    <Dropdown
                      shrink
                      minWidth="100%"
                      label={dataAssignScore?.validSemesterYear ? `${t('SEMESTER')}` : `${t('QUARTER')}`}
                      labelId={dataAssignScore?.validSemesterYear ? `${t('SEMESTER')}` : `${t('QUARTER')}`}
                      id="quarterName"
                      name="quarterName"
                      className="quarterName"
                      value={bChangeQuarter ? sQuarterName : dataAssignScore.quarterValue}
                      handleChange={(e) => {
                        changeQuarter(e);
                      }}
                      options={dataAssignScore.aQuarter}
                    />
                  </Grid>
                  {dataAssignScore?.updateType === 'homework' && (
                    <Grid item xs={12} sm={6} md={3} className={classes.performTextField1}>
                      <TextField
                        label={t('WEIGHTAGE')}
                        id="weightage"
                        name="weightage"
                        autoComplete="weightage"
                        value={weightage || 0}
                        disabled="true"
                      />
                    </Grid>
                  )}
                  {(dataAssignScore?.updateType === 'quiz1-marks' && dataAssignScore?.rowData?.enrolled_courses.academicYear === '2023-2024') && (
                    <Grid item xs={12} sm={6} md={3} className={`${classes.performTextField1} ${classes.dropdownField}`}>
                      <Dropdown
                        shrink
                        minWidth="100%"
                        label={selectedQuiz}
                        labelId={selectedQuiz}
                        id="QUIZ"
                        name="quizName"
                        className="quizName"
                        value={selectedQuiz}
                        handleChange={(e) => {
                          setSelectedQuiz(e.target.value);
                          const oldValues = dataAssignScore;
                          setDataAssignScore({
                            fullName: oldValues?.fullName,
                            courseName: oldValues?.courseName,
                            quarterName: oldValues?.quarterName,
                            rowData: oldValues?.rowData,
                            quarterValue: oldValues?.quarterValue,
                            aCourseName: oldValues?.aCourseName,
                            aQuarter: oldValues?.aQuarter,
                            setAssignLoading: oldValues?.setAssignLoading,
                            updateType: e.target.value === 'Quiz 1' || e.target.value === 'Quiz 3' ? 'quiz1-marks' : 'quiz2-marks',
                            quizName: e.target.value === 'Quiz 1' || e.target.value === 'Quiz 3' ? 'Quiz 1' : 'Quiz 2',
                            validSemesterYear: oldValues?.validSemesterYear,
                          });
                        }}
                        options={quizOptions}
                      />
                    </Grid>
                  )}
                  {(dataAssignScore?.updateType === 'quiz2-marks' && dataAssignScore?.rowData?.enrolled_courses.academicYear === '2023-2024') && (
                    <Grid item xs={12} sm={6} md={3} className={`${classes.performTextField1} ${classes.dropdownField}`}>
                      <Dropdown
                        shrink
                        minWidth="100%"
                        label={selectedQuiz}
                        labelId={selectedQuiz}
                        id="QUIZ"
                        name="quizName"
                        className="quizName"
                        value={selectedQuiz}
                        handleChange={(e) => {
                          setSelectedQuiz(e.target.value);
                          const oldValues = dataAssignScore;
                          setDataAssignScore({
                            fullName: oldValues?.fullName,
                            courseName: oldValues?.courseName,
                            quarterName: oldValues?.quarterName,
                            rowData: oldValues?.rowData,
                            quarterValue: oldValues?.quarterValue,
                            aCourseName: oldValues?.aCourseName,
                            aQuarter: oldValues?.aQuarter,
                            setAssignLoading: oldValues?.setAssignLoading,
                            updateType: e.target.value === 'Quiz 1' || e.target.value === 'Quiz 3' ? 'quiz1-marks' : 'quiz2-marks',
                            quizName: e.target.value === 'Quiz 1' || e.target.value === 'Quiz 3' ? 'Quiz 1' : 'Quiz 2',
                            validSemesterYear: oldValues?.validSemesterYear,
                          });
                        }}
                        options={quizOptions}
                      />
                    </Grid>
                  )}
                  {dataAssignScore?.updateType === 'marks' && (
                    <Grid item xs={12} sm={6} md={3} className={classes.switchSection}>
                      <Typography variant="h5" gutterBottom component="div">
                        {t('ATTENDANCE')}
                      </Typography>
                      <Stack className={classes.switchUi} spacing={1}>
                        <Typography className={!isPresent ? classes.setRed : ''}>{t('ABSENT')}</Typography>
                        <AntSwitch checked={isPresent} onChange={() => handlePresent()} inputProps={{ 'aria-label': 'ant design' }} />
                        <Typography className={isPresent ? classes.setGreen : ''}>{t('PRESENT')}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  <Grid container>
                    {dataAssignScore?.updateType === 'homework' ? (
                      <Grid container spacing={2} mt={3} mb={2} className={classes.addStyleHead}>
                        <Grid item xs={3}>
                          <Typography variant="body1" gutterBottom>
                            {t('WEEKS')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1" gutterBottom>
                            {t('MARKS')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1" gutterBottom>
                            {t('NOTES')}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={2} mt={3} mb={2} className={classes.addStyleHead}>
                        <Grid item xs={3}>
                          <Typography variant="body1" gutterBottom>
                            {t('CATEGORY')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1" gutterBottom>
                            {t('MARKS')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1" gutterBottom>
                            {t('NOTES')}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {dataAssignScore?.updateType === 'homework' ? (enrollDetails?.map((e, i) => (
                      <Grid container spacing={2} className={classes.alignHorCenter}>
                        <Grid item xs={3}>
                          <Typography variant="body1" gutterBottom className={classes.categoryLabel}>
                            {e?.category}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.marksDropDown}>
                          <TextField
                            label={t('MARKS')}
                            id={`marksField-${i}`}
                            type="number"
                            onChange={customHomeworkChange}
                            value={calculateValue(e, i)}
                            InputProps={{ inputProps: { min: 0, max: e?.maxMarks } }}
                            onKeyDown={(event) => {
                              if ((event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e')) {
                                event.preventDefault();
                              }
                            }}
                          />
                          /
                          {e?.maxMarks < 10 ? `0${e?.maxMarks}` : `${e?.maxMarks}`}
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1" gutterBottom>
                            {e?.notes}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))
                    ) : (isPresent && (
                      enrollDetails?.map((e, i) => (
                        <Grid container spacing={2} className={classes.alignHorCenter}>
                          <Grid item xs={3}>
                            <Typography variant="body1" gutterBottom className={classes.categoryLabel}>
                              {e?.category}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.marksDropDown}>
                            <TextField
                              label={t('MARKS')}
                              id={`marksField-${i}`}
                              type="number"
                              onChange={customMarkChange}
                              value={calculateValue(e, i)}
                              InputProps={{ inputProps: { min: 0, max: e?.maxMarks } }}
                              onKeyDown={(event) => {
                                if ((event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e')) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            /
                            {e?.maxMarks < 10 ? `0${e?.maxMarks}` : `${e?.maxMarks}`}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" gutterBottom>
                              {e?.notes}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))
                    ))}
                  </Grid>
                  {dataAssignScore?.updateType === 'homework' ? (
                    <Grid container>
                      <Grid container spacing={2} className={classes.grayLine}>
                        <Grid item xs={3}>Total Marks</Grid>
                        <Grid item xs={3} className={classes.marksDropDown}>
                          <TextField
                            id="outlined-number"
                            label=" Marks"
                            value={(Number.isInteger(isHomeworkTotal) ? isHomeworkTotal : isHomeworkTotal.toFixed(2))}
                            className={classes.totalMarksInput}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (isPresent && (
                    <Grid container>
                      <Grid container spacing={2} className={classes.grayLine}>
                        <Grid item xs={3}>Total Marks</Grid>
                        <Grid item xs={3} className={classes.marksDropDown}>
                          <TextField
                            id="outlined-number"
                            label=" Marks"
                            value={Number.isInteger(isMarksTotal) ? isMarksTotal : isMarksTotal.toFixed(2)}
                            className={classes.totalMarksInput}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid container justifyContent="flex-end">
                    <ButtonAtom
                      btntype={Buttons.SECONDARY}
                      name={t('CANCEL')}
                      className={classes.secButtonNew}
                      onClick={() => {
                        formik.resetForm();
                        setOpenMarksPopUp(false);
                        setBchangeQuarter(false);
                        setQuizName('Quiz 1');
                      }}
                    />
                    <ButtonAtom
                      btntype={Buttons.PRIMARY}
                      name={t('UPDATE')}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      disabled={!isValueChanged}
                      className={[classes.activeButtonNew, classes.secButtonNew]}
                    />
                  </Grid>
                </Grid>
              )
            }
          </form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}

export default AssignScore;
