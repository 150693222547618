// import { colors } from '../../../theme';

const checkboxstyle = () => ({
  formControl: {
    '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
      color: '#979797 !important',
      fontSize: 12,
      width: 150,
      cursor: 'pointer',
    },
  },
  disabled: {
    opacity: 0.2,
  },
});

export default checkboxstyle;
