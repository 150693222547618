import React from 'react';
import {
  Box, Grid,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExitToApp from '@mui/icons-material/ExitToApp';
import ManabadiHeader from '../common/view-header/manabadi-header/manabadi-header';
import useStyles from '../../custom-hooks/useStyles';
import useAdmin from '../../custom-hooks/useAdmin';
import style from './style';
import ButtonAtom from '../../components/atoms/button';
import { setLocalStorage } from '../../utils/localStorageMethod';
import { NavigateRoutes } from '../../constant';
import encryptedUserRoles from '../../constant/encryptedUserRoles';

export default function AdminDashboard() {
  const classes = useStyles(style)();
  const { state } = useAdmin();

  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      {
        JSON.parse(localStorage.getItem('impersonateUser'))
        && (
          <Grid className={classes.headerViewingDiv}>
            <div className={classes.headerViewing}>
              <p>Viewing as</p>
              <p><span>{state?.email ? state?.email : JSON.parse(localStorage.getItem('impersonateUser')).state.email}</span></p>
              <ButtonAtom
                className={classes.exitBtn}
                onClick={() => {
                  setLocalStorage('userRole', encryptedUserRoles.SYSTEM_ADMIN);
                  setLocalStorage('username', state?.adminUserName ? state?.adminUserName : JSON.parse(localStorage.getItem('impersonateUser')).state.adminUserName);
                  setLocalStorage('profilePhoto', state?.profilePic ? state?.profilePic : JSON.parse(localStorage.getItem('impersonateUser')).state.profilePic);
                  localStorage.removeItem('impersonateToken');
                  localStorage.removeItem('impersonateUser');
                  localStorage.removeItem('tempUserId');
                  localStorage.removeItem('tempUserRole');
                  setLocalStorage('tempUserRole', encryptedUserRoles.SYSTEM_ADMIN);
                  navigate(NavigateRoutes.USER_MANAGER_VIEW, { state: { previousFilters: state?.filterOptions, reload: true } });
                }}
                name={t('EXIT')}
                icon={<ExitToApp />}
              />
            </div>
          </Grid>
        )
      }
      <Box className={classes.headerMargin}>
        <ManabadiHeader />
      </Box>
    </Stack>
  );
}
