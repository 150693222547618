/* eslint-disable no-restricted-syntax */
import Constant from '../constant';
import {
  CreateLocationService,
  getAllExamCentersService,
  getAllLocationCoordinatorsService,
  getAllLocationsService, updateLocationService, updateSyncCsv,
  getAllPaginatedLocationsService,
  saveImportExportSheetTime,
  moveStudentService,
} from '../services/auth';
import { getSectionDetails } from './getLocationCoordinator';

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}
function haversine(lat1, lon1, lat2, lon2) {
  const R = 6371;
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceSort = R * c;
  return distanceSort;
}

export const getAllPaginatedLocations = (pageNum, pageLimit, setTotalCount, searchText, refreshList, clearLocations, setAssignedLocations, setAddLocLoading, setLoading) => (dispatch) => {
  const payload = {};
  if (searchText && searchText !== '') {
    payload.search = [searchText];
  }
  getAllPaginatedLocationsService(pageNum, pageLimit, payload)
    .then((response) => {
      if (clearLocations) {
        setAssignedLocations([]);
      }
      setTotalCount(response?.count);
      dispatch({
        type: Constant.GET_ALL_LOCATIONS,
        payload: response?.data,
      });
      setTimeout(() => {
        if (setAddLocLoading) setAddLocLoading(false);
        if (setLoading) setLoading(false);
      }, 2000);
    })
    .catch((err) => {
      if (err.readyState === 4 && err.status === 0) {
        setTimeout(() => {
          if (setAddLocLoading) setAddLocLoading(false);
          if (setLoading) setLoading(false);
          refreshList();
        }, 2000);
      }
    });
};

export const getAllLocations = () => (dispatch) => {
  getAllLocationsService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_LOCATIONS,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};
const aproximatedLocations = async (payload, locations) => {
  const distances = [];
  for (const row of locations) {
    const locationLat = row.locationAddress.latitude;
    const locationLon = row.locationAddress.longitude;
    const distanceSort = haversine(payload.lat, payload.lng, locationLat, locationLon);
    distances.push({ location: row, distanceSort });
  }
  distances.sort((a, b) => a.distanceSort - b.distanceSort);
  const sortedLocations = distances.map((item) => item.location);
  return sortedLocations;
};
export const getAllLocationsSearchLocations = async (payload) => (dispatch) => {
  getAllLocationsService().then(async (response) => {
    const data = await aproximatedLocations(payload, response?.data);
    dispatch({
      type: Constant.GET_ALL_LOCATIONS,
      payload: data,
    });
  }).catch(() => {
  });
};

export const updateLocation = (payload, refreshList, setLocationError, setAssignedLocations) => () => {
  updateLocationService(payload)
    .then(() => {
      setAssignedLocations([]);
      refreshList();
    })
    .catch((err) => {
      setLocationError(err.errors?.[0]?.msg);
    });
};

export const createLocation = (payload, refreshList, setLocationError, setLoading, setSuccessMessage) => () => {
  CreateLocationService(payload)
    .then(() => {
      refreshList();
      setSuccessMessage(true);
    })
    .catch((err) => {
      setLocationError(err.errors?.[0]?.msg);
      if (setLoading) setLoading(false);
    });
};

export const getAllExamCenters = () => (dispatch) => {
  getAllExamCentersService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_EXAM_CENTERS,
        payload: response?.body,
      });
    })
    .catch(() => {
    });
};

export const getAllLocationCoordinators = () => (dispatch) => {
  getAllLocationCoordinatorsService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_LOCATION_COORDINATORS,
        payload: response?.body,
      });
    })
    .catch(() => {
    });
};

export const moveStudent = (file, setLoading, refreshList, locationId, year, type, setFailed, courseId = null) => async (dispatch) => {
  try {
    for (let i = 0; i < file.length; i++) {
      try {
        await moveStudentService(file[i]);
      } catch (error) {
        setFailed(error?.errors?.[0]?.msg, error?.errors?.[0]?.value);
      }
      const payload = {
        syncType: 'section',
        type: type || 'import',
        syncTime: new Date(),
        locationId,
        academicYear: year,
      };
      if (courseId) {
        payload.courseId = courseId;
      }
      await saveImportExportSheetTime(payload);
    }
    dispatch(getSectionDetails({
      locationId,
      year,
    }));
    refreshList();
  } catch (error) {
    setFailed(error?.errors?.[0]?.msg, error?.errors?.[0]?.value);
    setLoading(false);
  }
};

export const updateSections = (file, setLoading, refreshList, locationId, year, type, setFailed) => (dispatch) => {
  setLoading(true);
  updateSyncCsv(file)
    .then((response) => {
      dispatch(getSectionDetails({
        locationId,
        year,
      }));
      dispatch({
        type: Constant.UPDATE_CSV_FILE,
        payload: response?.data,
      });
      const payload = {
        syncType: 'section',
        type: type || 'import',
        syncTime: new Date(),
        locationId,
        academicYear: year,
      };
      saveImportExportSheetTime(payload).then(() => {
        refreshList();
      }).catch(() => {
      });
    })
    .catch((error) => {
      setFailed(error?.errors?.[0]?.msg);
      setLoading(false);
    });
};

export default {
  getAllLocations,
  getAllExamCenters,
  getAllLocationCoordinators,
  updateLocation,
  createLocation,
  getAllPaginatedLocations,
};
