import { colors, fonts } from '../../../theme';

const paymentInfoStyle = (() => ({
  boxDiv: {
    borderRadius: 15,
    backgroundColor: colors.greyShade,
    padding: '0px 12px 0px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 15,
    marginBottom: 15,
    width: '100% !important',
    maxHeight: '350px !important',
  },
  root: {
    background: colors.white,
    width: '100%',
    boxShadow: 'none !important',
    padding: '20px 20px 40px 20px !important',
  },
  content: {
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      marginLeft: '2%',
      fontFamily: fonts.fontType.roboto,
      fontSize: '0.9vw',
      lineHeight: 1.5,
    },
  },
  totalPayment: {
    color: colors.black,
    padding: '1%',
    fontWeight: fonts.fontWeight.bold,
    background: colors.secondary,
    width: '100%',
    fontSize: '1.2vw',
    borderRadius: 5,
    marginBottom: '15px',
    '@media (max-width: 1199px)': {
      fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
    },
  },
  payButton: {
    marginTop: 20,
  },
  heading: {
    color: colors.footerBottomColor,
    padding: '5px 5px 5px 0px',
    fontWeight: 500,
    width: '100%',
    fontSize: '1.5vw',
    marginTop: '20px',
    borderRadius: 5,
    '@media (max-width: 1199px)': {
      fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
    },
  },
  summaryGrid: {
    display: 'inherit',
    border: '0.2vw solid #104F96',
    borderTop: 'none',
    fontSize: '1vw',
    fontWeight: 'bold',
    margin: '0vw 0 1vw 0vw',
    '@media (max-width: 1199px)': {
      fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    },
    '@media (min-width: 1200px)': {
      height: '4vw',
    },
  },
  mainContainer: {
    paddingTop: 20,
    '@media (max-width: 1200px)': {
      justifyContent: 'center',
    },
    '@media (max-width: 600px)': {
      justifyContent: 'end',
    },
    '& .MuiDataGrid-root': {
      border: '0.2vw solid #104F96',
      borderBottom: '0.1vw solid #ccc',
      borderBottomLeftRadius: 'revert',
      borderBottomRightRadius: 'revert',
    },
    '& .MuiDataGrid-cell': {
      '@media (min-width: 1200px)': {
        minHeight: '3vw !important',
        maxHeight: '4vw !important',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '1vw',
      fontWeight: 'bold',
      '@media (max-width: 1199px)': {
        fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: colors.secondary,
    },
    '& .MuiDataGrid-cell--textLeft': {
      justifyContent: 'center',
    },
    '& .studentNameHeader, .studentNameCell': {
      '@media (min-width: 1200px)': {
        width: '12vw !important',
        minWidth: '12vw !important',
        maxWidth: '12vw !important',
        fontSize: '1vw',
        justifyContent: 'center !important',
      },
    },
    '& .studentNameCell, .classCell, .courseFeeCell, .registrationFeeCell, .pstuFeeCell, .totalFeeCell': {
      justifyContent: 'center',
      paddingLeft: '2vw',
    },
    '& .classHeader, .classCell': {
      '@media (min-width: 1200px)': {
        width: '11vw !important',
        minWidth: '11vw !important',
        maxWidth: '11vw !important',
        fontSize: '1vw',
      },
    },
    '& .courseFeeHeader, .courseFeeCell': {
      '@media (min-width: 1200px)': {
        width: '10vw !important',
        minWidth: '10vw !important',
        maxWidth: '10vw !important',
        fontSize: '1vw',
      },
    },
    '& .discountCell': {
      color: colors.green,
    },
    '@media (min-width: 1200px)': {
      '& .registrationFeeHeader, .registrationFeeCell': {
        width: '12vw !important',
        minWidth: '12vw !important',
        maxWidth: '12vw !important',
        fontSize: '1vw',
      },
      '& .pstuFeeHeader, .pstuFeeCell': {
        width: '10vw !important',
        minWidth: '10vw !important',
        maxWidth: '10vw !important',
        fontSize: '1vw',
      },
      '& .discountHeader, .discountCell': {
        width: '10vw !important',
        minWidth: '10vw !important',
        maxWidth: '10vw !important',
        fontSize: '1vw',
      },
      '& .discountCell': {
        justifyContent: 'center',
        color: colors.green,
        paddingLeft: '2vw',
      },
      '& .totalFeeHeader': {
        width: '12.5vw !important',
        minWidth: '12.5vw !important',
        maxWidth: '12.5vw !important',
        fontSize: '1vw',
      },
      '& .totalFeeCell': {
        width: '12vw !important',
        minWidth: '12vw !important',
        maxWidth: '12vw !important',
        fontSize: '1vw',
      },
    },
  },
  dialogButtons: {
    paddingRight: 'inherit',
    '@media (min-width: 1200px)': {
      marginLeft: '20vw !important',
    },
    '@media (max-width: 1199px)': {
      marginLeft: '30vw !important',
    },
    '@media (max-width: 1000px)': {
      marginLeft: '28vw !important',
    },
    '@media (max-width: 825px)': {
      marginLeft: '26vw !important',
    },
    '@media (max-width: 700px)': {
      marginLeft: '24vw !important',
    },
    '@media (max-width: 620px)': {
      marginLeft: '22vw !important',
    },
    '@media (max-width: 550px)': {
      marginLeft: '20vw !important',
    },
    '@media (max-width: 420px)': {
      marginLeft: '14vw !important',
    },
  },
  error: {
    width: '20vw !important',
    marginBottom: '5px !important',
    marginLeft: '20% !important',
  },
  errorText: {
    fontSize: '12px',
    marginTop: '-30px !important',
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      marginLeft: '-60px !important',
      marginBottom: '5px !important',
      fontSize: '12px',
      width: 'max-content',
    },
    '@media (max-width: 600px)': {
      fontSize: '12px',
      marginLeft: '-50px !important',
    },
    display: 'block',
  },
  termsText: {
    color: `${colors.primary} !important`,
    fontWeight: fonts.fontWeight.bold,
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
  },
  acceptCheckbox: {
    marginLeft: '1vw',
    '& .MuiFormControlLabel-root': {
      width: 'max-content',
    },
  },
  borderBottom: {
    width: '100%',
    height: 5,
    display: 'block',
    background: colors.secondary,
  },
  footerBottom: {
    background: colors.footerBottomColor,
    height: 3,
    display: 'block',
    width: '100%',
    marginTop: '10px !important',
  },
  footerBottom2: {
    background: colors.footerBottomColor,
    height: 3,
    display: 'block',
    width: '100%',
    marginTop: '10px !important',
    marginBottom: '-10px !important',
  },
  list: {
    padding: 0,
    margin: 0,
    overflowY: 'auto',
    height: '200px !important',
  },
  titleTerms: {
    fontFamily: 'helvetica, sans-serif',
    color: colors.primary,
    fontSize: '15pt',
    marginBottom: 0,
  },
  listItem: {
    marginBottom: 3,
  },
  headerContainer: {
    maxWidth: 'inherit',
  },
  textAlign: {
    textAlign: 'right',
    paddingRight: '1vw',
  },
  paddingLeft: {
    '@media (min-width: 1200px)': {
      paddingRight: '2vw !important',
    },
    textAlign: 'right',
    '@media (max-width: 1199px)': {
      paddingRight: '8px',
      paddingBottom: '16px',
    },
  },
  contributionAmount: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.1vw solid #104F96',
    },
    '& .MuiOutlinedInput-root': {
      '@media (max-width: 600px)': {
        height: '56px !important',
      },
      '& input': {
        fontWeight: '600',
        paddingLeft: '2vw !important',
        textAlign: 'right',
        borderRadius: '0.2vw',
      },
      '& .MuiInputLabel-root': {
        fontsize: '0.9vw !important',
      },
    },
    '& .MuiFormControl-root': {
      '@media (max-width: 1199px)': {
        marginTop: '0px',
      },
      marginTop: 'auto !important',
      marginBottom: '16px !important',
    },
    '& .MuiFormControl-root.MuiTextField-root': {
      margin: 0,
    },
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    textAlign: 'left',
    color: colors.primary,
    padding: '0px !important',
    '@media (max-width: 1199px)': {
      fontSize: 'calc(18px + 6 * ((100vw - 320px) / 1199))',
    },
    '@media (min-width: 1200px)': {
      fontSize: '2vw',
    },
  },
  contributionCause: {
    paddingLeft: '2vw !important',
    '& .MuiFormControl-root': {
      '@media (min-width: 1200px)': {
        width: '28vw',
        borderRadius: '1vw',
      },
      '& .MuiInputLabel-root.MuiInputLabel-shrink': {
        fontSize: '12px !important',
        lineHeight: 1.5,
        transform: 'translate(0.9vw, -0.5vw) !important',
        color: `${colors.primary} !important`,
      },
      '& .MuiInputLabel-root': {
        fontSize: '16px !important',
      },
      '@media (max-width: 600px)': {
        '& .MuiInputLabel-root': {
          fontSize: '14px !important',
        },
        '& .MuiInputLabel-root.MuiInputLabel-shrink': {
          fontSize: '12px !important',
          transform: 'translate(3vw, -2vw) !important',
        },
      },
      '@media (min-width: 1199px)': {
        '& .MuiInputLabel-root.MuiInputLabel-shrink': {
          transform: 'translate(1vw, -0.3vw) !important',
        },
      },
    },
    lineHeight: 1.5,
  },
  commonButtonFooter: {
    minWidth: '2vw !important',
    height: '2.5vw !important',
    padding: '1vw 1vw 1.2vw 1.2vw !important',
    '@media (max-width: 1199px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
      borderRadius: '12px !important',
    },
    '@media (max-width: 499px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
    },
    '& svg': {
      '@media (max-width: 1199px)': {
        width: '16px !important',
        height: '16px !important',
      },
    },
  },
  commonButtonNew: {
    minWidth: '2vw !important',
    height: '3vw !important',
    padding: '1vw 1vw 1.2vw 1.2vw !important',
    '@media (max-width: 1199px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
      borderRadius: '12px !important',
    },
    '@media (max-width: 499px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
    },
    '& svg': {
      '@media (max-width: 1199px)': {
        width: '16px !important',
        height: '16px !important',
      },
    },
  },
  activeButton: {
    color: '#f3f8fe !important',
    '@media (min-width: 1200px)': {
      height: '2.9vw',
      fontSize: '1vw',
      padding: '0.8vw',
      minWidth: '15vw',
      borderRadius: '0.4vw !important',
    },
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    boxShadow: '0 4px 4px -2px hsl(0deg 0% 65% / 25%)',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    marginLeft: '1.2vw !important',
    marginRight: '1vw',
    textTransform: 'none !important',
    background: `${colors.newBGColor} !important`,
    '@media (max-width: 900px)': {
      height: 'auto !important',
    },
  },
}));

export default paymentInfoStyle;
