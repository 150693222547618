import { colors, fonts } from '../../../theme';

const donationstyle = (() => ({
  rowWrapper: {
    flexWrap: 'nowrap',
    marginBottom: '1.3vw',
    '@media (max-width: 600px)': {
      flexWrap: 'wrap',
    },
  },
  marginBottomRow: {
    marginBottom: '0.8vw',
  },
  rowWrapperAmount: {
    flexWrap: 'nowrap',
    marginTop: '-0.8vw',
    marginBottom: '1.3vw',
    '@media (max-width: 600px)': {
      flexWrap: 'wrap',
    },
  },
  dropdownWrapper: {
    '& .MuiFormControl-root': {
      width: '24vw !important',
    },
    justifyContent: 'flex-end',
    '& .MuiInputBase-root': {
      width: '-webkit-fill-available',
      '@media (max-width: 600px)': {
        width: '200px !important',
      },
    },
  },
  errorText: {
    color: colors.errorText,
    fontSize: 'calc(9px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
      paddingBottom: '5%',
    },
    display: 'block',
    textAlign: 'right',
  },
  notesErrorText: {
    color: colors.errorText,
    fontSize: 'calc(9px + 6 * ((100vw - 320px) / 1199))',
    width: '100%',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    display: 'block',
    textAlign: 'left',
    paddingBottom: '5%',
    marginTop: '0vw',
  },
  donationNotesLength: {
    '@media (min-width: 1200px)': {
      fontSize: '0.8vw',
    },
    color: colors.textTertiary,
  },
  dropdown: {
    minWidth: '20vw !important',
    marginTop: '0 !important',
    maxWidth: '20vw',
    '@media (min-width: 1200px)': {
      fontSize: '1vw',
    },
  },
  labelWrapper: {
    marginTop: '1vw',
    width: '100%',
    maxWidth: '180px',
    '@media (min-width: 1200px)': {
      flexBasis: '10vw !important',
    },
    '@media (max-width: 600px)': {
      maxWidth: 'none',
      minWidth: 'max-content',
    },
  },
  textStyle: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    minWidth: '12vw',
    fontFamily: fonts.fontType.roboto,
    fontWeight: fonts.fontWeight.medium,
    color: colors.black,
  },
  studentName: {
    paddingLeft: '1vw',
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    minWidth: '12vw',
    fontFamily: fonts.fontType.roboto,
    fontWeight: fonts.fontWeight.medium,
    color: colors.black,
  },
  donationAmount: {
    minWidth: '20vw',
    '& .MuiFormControl-root': {
      width: '24vw !important',
      '@media (max-width: 600px)': {
        width: '200px !important',
        '& .MuiInputBase-input': {
          textAlign: 'right !important',
        },
      },
    },
  },
  donationAmountGrid: {
    marginTop: '-1.5vw',
  },
  buttonWrapper: {
    marginTop: '1vw',
    justifyContent: 'flex-end',
  },
  notesWrapper: {
    marginTop: '1.5vw',
    color: colors.black,

  },
  textField: {
    minWidth: '20vw',
    color: colors.black,

  },
  selectClass: {
    maxWidth: '23vw',
  },
}));

export default donationstyle;
