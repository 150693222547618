/* eslint-disable import/prefer-default-export */

const getFilterKey = (filterKey) => {
  let key = filterKey;
  if (filterKey === 'academic_year') {
    key = 'AcademicYear';
  } else if (filterKey === 'course_name') {
    key = 'Course';
  } else if (filterKey === 'section') {
    key = 'Section';
  } else if (filterKey === 'region_name') {
    key = 'Region';
  } else if (filterKey === 'Role') {
    key = 'Role';
  }
  return key;
};

function DataList(data) {
  const academicFilter = { AcademicYear: [] };
  const locationFilter = { Location: [] };
  const courseFilter = { Course: [] };
  const completionStatusFilter = { CompletionStatus: [] };
  const studentFilter = { Student: [] };
  const regionFilter = { Region: [] };
  const statusFilter = { status: [] };
  const countryFilter = { Country: [] };
  const returningFilter = { Returning: [] };
  const examCenterFilter = { ExamCenter: [] };
  const centerFilter = { Center: [] };
  const hasPicFilter = { HasPic: [] };
  const roleFilter = { Role: [] };
  const userFilter = { User: [] };
  const createdDateFilter = { CreatedDate: [] };
  const registeredWeekFilter = { RegisteredWeek: [] };
  const lessMarksQ1Filter = { 'Marks_Q1<=': [] };
  const greaterMarksQ1Filter = { 'Marks_Q1>=': [] };
  const lessMarksQ2Filter = { 'Marks_Q2<=': [] };
  const greaterMarksQ2Filter = { 'Marks_Q2>=': [] };
  const lessMarksQ3Filter = { 'Marks_Q3<=': [] };
  const greaterMarksQ3Filter = { 'Marks_Q3>=': [] };
  const lessMarksS2Filter = { 'Marks_S2<=': [] };
  const greaterMarksS2Filter = { 'Marks_S2>=': [] };
  const lessMarksS3Filter = { 'SUPPL/PSTU<=': [] };
  const greaterMarksS3Filter = { 'SUPPL/PSTU>=': [] };
  const lessMarksS1Filter = { 'Marks_S1<=': [] };
  const greaterMarksS1Filter = { 'Marks_S1>=': [] };
  const sectionFilter = { Section: [] };
  const stateFilter = { State: [] };

  let studentCount = 0;
  let filtersCount = 0;

  data?.forEach((obj) => {
    if (obj.filterKey === 'recipients') {
      studentCount += 1;
      studentFilter.Student.push(obj.label);
    } else if (obj.filterKey === 'locationFilter') {
      filtersCount += 1;
      locationFilter.Location.push(obj.label);
    } else if (obj.filterKey === 'yearFilter') {
      filtersCount += 1;
      academicFilter.AcademicYear.push(obj.label);
    } else {
      const key = getFilterKey(obj.filterKey);
      if (key === 'Student') {
        studentCount += 1;
        studentFilter[key].push(obj.label);
      } else if (key === 'AcademicYear') {
        filtersCount += 1;
        academicFilter[key].push(obj.label);
      } else if (key === 'Role - User groups') {
        filtersCount += 1;
        roleFilter.Role.push(obj.label);
      } else if (key === 'Course') {
        filtersCount += 1;
        courseFilter[key].push(obj.label);
      } else if (key === 'Region') {
        filtersCount += 1;
        regionFilter[key].push(obj.label);
      } else if (key === 'CompletionStatus') {
        filtersCount += 1;
        completionStatusFilter[key].push(obj.label);
      } else if (key.toLowerCase() === 'status') {
        filtersCount += 1;
        statusFilter[key.toLowerCase()].push(obj.label);
      } else if (key === 'Country') {
        filtersCount += 1;
        countryFilter[key].push(obj.label);
      } else if (key === 'Returning') {
        filtersCount += 1;
        returningFilter[key].push(obj.label);
      } else if (key === 'Center') {
        filtersCount += 1;
        centerFilter[key].push(obj.label);
      } else if (key === 'HasPic') {
        filtersCount += 1;
        hasPicFilter[key].push(obj.label);
      } else if (key === 'ExamCenter') {
        filtersCount += 1;
        examCenterFilter[key].push(obj.label);
      } else if (key === 'User') {
        filtersCount += 1;
        userFilter[key].push(obj.label);
      } else if (key === 'CreatedDate') {
        filtersCount += 1;
        createdDateFilter[key].push(obj.label);
      } else if (key === 'RegisteredWeek') {
        filtersCount += 1;
        registeredWeekFilter[key].push(obj.label);
      } else if (key === 'Marks_Q1<=') {
        filtersCount += 1;
        lessMarksQ1Filter[key].push(obj.label);
      } else if (key === 'Marks_Q1>=') {
        filtersCount += 1;
        greaterMarksQ1Filter[key].push(obj.label);
      } else if (key === 'Section') {
        filtersCount += 1;
        sectionFilter[key].push(obj.label);
      } else if (key === 'Location') {
        filtersCount += 1;
        locationFilter[key].push(obj.label);
      } else if (key === 'center_name') {
        filtersCount += 1;
        locationFilter.Location.push(obj.label);
      } else if (key === 'status') {
        filtersCount += 1;
        statusFilter[key].push(obj.label);
      } else if (key === 'State') {
        filtersCount += 1;
        stateFilter[key].push(obj.label);
      } else if (key === 'Marks_Q2<=') {
        filtersCount += 1;
        lessMarksQ2Filter[key].push(obj.label);
      } else if (key === 'Marks_Q2>=') {
        filtersCount += 1;
        greaterMarksQ2Filter[key].push(obj.label);
      } else if (key === 'Marks_Q3<=') {
        filtersCount += 1;
        lessMarksQ3Filter[key].push(obj.label);
      } else if (key === 'Marks_Q3>=') {
        filtersCount += 1;
        greaterMarksQ3Filter[key].push(obj.label);
      } else if (key === 'Marks_S2<=') {
        filtersCount += 1;
        lessMarksS2Filter[key].push(obj.label);
      } else if (key === 'Marks_S2>=') {
        filtersCount += 1;
        greaterMarksS2Filter[key].push(obj.label);
      } else if (key === 'Marks_S1<=') {
        filtersCount += 1;
        lessMarksS1Filter[key].push(obj.label);
      } else if (key === 'Marks_S1>=') {
        filtersCount += 1;
        greaterMarksS1Filter[key].push(obj.label);
      } else if (key === 'SUPPL/PSTU<=') {
        filtersCount += 1;
        lessMarksS3Filter[key].push(obj.label);
      } else if (key === 'SUPPL/PSTU>=') {
        filtersCount += 1;
        greaterMarksS3Filter[key].push(obj.label);
      }
    }
  });
  const finalFilters = [];
  if (studentFilter.Student.length > 0) {
    finalFilters.push(studentFilter);
  }
  if (academicFilter.AcademicYear.length > 0) {
    finalFilters.push(academicFilter);
  }
  if (courseFilter.Course.length > 0) {
    finalFilters.push(courseFilter);
  }
  if (regionFilter.Region.length > 0) {
    finalFilters.push(regionFilter);
  }
  if (completionStatusFilter.CompletionStatus.length > 0) {
    finalFilters.push(completionStatusFilter);
  }
  if (statusFilter.status.length > 0) {
    finalFilters.push(statusFilter);
  }
  if (countryFilter.Country.length > 0) {
    finalFilters.push(countryFilter);
  }
  if (returningFilter.Returning.length > 0) {
    finalFilters.push(returningFilter);
  }
  if (centerFilter.Center.length > 0) {
    finalFilters.push(centerFilter);
  }
  if (locationFilter.Location.length > 0) {
    finalFilters.push(locationFilter);
  }
  if (examCenterFilter.ExamCenter.length > 0) {
    finalFilters.push(examCenterFilter);
  }
  if (userFilter.User.length > 0) {
    finalFilters.push(userFilter);
  }
  if (createdDateFilter.CreatedDate.length > 0) {
    finalFilters.push(createdDateFilter);
  }
  if (registeredWeekFilter.RegisteredWeek.length > 0) {
    finalFilters.push(registeredWeekFilter);
  }
  if (greaterMarksQ1Filter['Marks_Q1>='].length > 0) {
    finalFilters.push(greaterMarksQ1Filter);
  }
  if (lessMarksQ1Filter['Marks_Q1<='].length > 0) {
    finalFilters.push(lessMarksQ1Filter);
  }
  if (greaterMarksQ2Filter['Marks_Q2>='].length > 0) {
    finalFilters.push(greaterMarksQ2Filter);
  }
  if (lessMarksQ2Filter['Marks_Q2<='].length > 0) {
    finalFilters.push(lessMarksQ2Filter);
  }
  if (greaterMarksQ3Filter['Marks_Q3>='].length > 0) {
    finalFilters.push(greaterMarksQ3Filter);
  }
  if (lessMarksQ3Filter['Marks_Q3<='].length > 0) {
    finalFilters.push(lessMarksQ3Filter);
  }
  if (greaterMarksS2Filter['Marks_S2>='].length > 0) {
    finalFilters.push(greaterMarksS2Filter);
  }
  if (lessMarksS2Filter['Marks_S2<='].length > 0) {
    finalFilters.push(lessMarksS2Filter);
  }
  if (greaterMarksS1Filter['Marks_S1>='].length > 0) {
    finalFilters.push(greaterMarksS1Filter);
  }
  if (lessMarksS1Filter['Marks_S1<='].length > 0) {
    finalFilters.push(lessMarksS1Filter);
  }
  if (greaterMarksS3Filter['SUPPL/PSTU>='].length > 0) {
    finalFilters.push(greaterMarksS3Filter);
  }
  if (lessMarksS3Filter['SUPPL/PSTU<='].length > 0) {
    finalFilters.push(lessMarksS3Filter);
  }
  if (sectionFilter.Section.length > 0) {
    finalFilters.push(sectionFilter);
  }
  if (hasPicFilter.HasPic.length > 0) {
    finalFilters.push(hasPicFilter);
  }
  if (stateFilter.State.length > 0) {
    finalFilters.push(stateFilter);
  }
  if (roleFilter.Role.length > 0) {
    finalFilters.push(roleFilter);
  }
  return { finalFilters, studentCount, filtersCount };
}

export { DataList };
