import { colors, fonts } from '../../../theme';

const style = (theme) => ({
  gridContainer: {
    padding: '2vw 2vw 2vw 3vw !important',
    minHeight: '65vh',
  },
  dropdowns: {
    '@media (min-width: 1200px)': {
      maxHeight: '4vw',
    },
    '& .locationDropdown': {
      width: '100%',
    },
    '& .MuiInputLabel-root': {
      lineHeight: 'normal',
      top: '-8px',
    },
  },
  studentBox: {
    maxWidth: 'unset !important',
    '& .MuiGrid-item': {
      maxWidth: 'unset !important',
      textAlign: 'right !important',
    },
  },
  selectAll: {
    marginLeft: '15%',
    display: 'flex !important',
    justifyContent: 'flex-start',
    '& .MuiFormControlLabel-label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      width: '120px !important',
    },
  },
  checkbox: {
    display: 'flex !important',
    marginBottom: '12px',
    '& .MuiFormControlLabel-label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
    },
    '& div>MuiBox-root:first-child': {
      width: '25%',
    },
  },
  mapPinImg: {
    '@media (min-width: 1200px)': {
      width: '1vw',
      height: '1vw',
      transform: 'translate(0.9vw, 0.1vw)',
    },
    marginRight: '0.2vw',
  },
  rowStudent: {
    padding: '5px 100px !important',
    backgroundColor: '#F5F6F7 !important',
    fontWeight: '700 !important',
  },
  rowMark: {
    padding: '5px 47px !important',
    backgroundColor: '#F5F6F7 !important',
    fontWeight: '700 !important',
  },
  rowTotal: {
    padding: '5px 60px !important',
    backgroundColor: '#F5F6F7 !important',
    fontWeight: '700 !important',
  },
  rowAbsent: {
    padding: '5px 60px !important',
    backgroundColor: '#F5F6F7 !important',
    fontWeight: '700 !important',
  },
  bodyMarksNames: {
    padding: '8px !important',
    backgroundColor: 'lightblue !important',
    fontWeight: '700 !important',
  },
  bodyMarksMarks: {
    padding: '8px 0px 0px 0px !important',
    backgroundColor: '#FFFFFF !important',
  },
  responsive: {
    '@media (max-width: 600px)': {
      display: 'grid',
    },
  },
  checkboxHead: {
    display: 'flex',
    margin: '5px 0 0px 0px !important',
    color: '#104F96',
    minWidth: '15%',
    position: 'relative',
    '& p': {
      margin: '0',
      '@media (max-width: 1200px)': {
        paddingRight: '15px',
      },
      '& span': {
        '@media (min-width: 1200px)': {
          position: 'absolute',
          right: '30px',
        },
      },
    },
  },
  checkboxContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '75%',
    '& .MuiFormControlLabel-root': {
      minWidth: '230px',
    },
    '& css-1vgzwi7-MuiFormControl-root:last child': {
      width: '0% !important',
      margin: '0 !important',
    },
  },
  dialogButtons: {
    justifyContent: 'flex-end',
    padding: '0 10px',
    '& .MuiButton-root': {
      '@media (min-width: 1200px)': {
        minWidth: '10vw !important',
        height: '2.7vw !important',
        fontSize: '0.8vw',
      },
    },
  },
  alertprimary: {
    height: '3vw',
    display: 'flex',
    color: colors.green,
  },
  popupButton: {
    display: 'flex',
    justifyContent: 'end',
    '& .MuiButtonBase-root.MuiButton-root': {
      '@media (min-width: 1200px)': {
        height: '3vw !important',
        minWidth: '9vw !important',
      },
      marginRight: '15px',
      borderRadius: '4px',
    },
  },
  yearMarksSyncLocation: {
    '& .MuiInputBase-root.MuiInput-root': {
      width: '100%',
      '@media (max-width: 600px)': {
        width: '100%',

      },
      '@media (max-width: 1199px) and (min-width: 601px)': {
        width: '80%',
        paddingLeft: '10px',

      },
    },
    '@media (max-width: 1200px)': {
      marginBottom: '5px',
    },
    '& .MuiFormControl-root .MuiSelect-select.MuiSelect-select': {
      '@media (max-width: 600px)': {
        paddingRight: 0,
      },
    },
    textAlign: 'start',
    minWidth: '1vw',
    '& .MuiInputBase-root': {
      width: '50%',
    },
    '& .MuiFormControl-root': {
      width: '100% !important',
      '& .MuiInputLabel-root': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        fontSize: '0.9vw',
        letterSpacing: 1,
        [theme.breakpoints.down('md')]: {
          fontSize: 16,
        },
      },
      '& .MuiInput-root:before': {
        borderBottom: `0.15vw solid ${colors.primary}`,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          padding: '0.7vw !important',
          width: '9vw !important',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-root': {
        background: 'white',
        height: '2.7vw !important',
        lineHeight: '1vw',
        borderRadius: '0.4vw',
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
        },
        right: '0vw',
      },
      '& input': {
        padding: '0.7vw 0.7vw 0.7vw 0.7vw',
        boxSizing: 'border-box',
        fontSize: '0.9vw',
        fontFamily: fonts.fontType.roboto,
        [theme.breakpoints.down('md')]: {
          fontSize: '0.9vw',
        },
        '@media (max-width: 499px)': {
          fontSize: '0.9vw',
        },
        textAlign: 'center',
        color: 'black !important',
        '&::placeholder': {
          textTransform: 'uppercase',
          color: `${colors.placeHolderColor} !important`,
          opacity: 1,
          letterSpacing: 2,
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontFamily: fonts.fontType.roboto,
          fontWeight: 400,
        },
      },
    },
  },
  location: {
    textAlign: 'start',
    '@media (min-width: 1200px)': {
      minWidth: '30vw',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .MuiInputBase-root.MuiInput-root': {
      minWidth: '30vw !important',
      maxWidth: '30vw !important',
      '@media (max-width: 600px)': {
        minWidth: '150px !important',
        maxWidth: '150px !important',
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.primary} !important`,
      fontSize: '1.2vw',
      letterSpacing: 1,
    },
    '& .MuiInput-root:before': {
      borderBottom: `0.15vw solid ${colors.primary}`,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      textAlign: 'left',
      padding: '0.7vw !important',
      '@media (max-width: 600px)': {
        padding: '6px 24px 7px 0px !important',
      },
      fontFamily: fonts.fontType.roboto,
      transform: 'translate(8px, 0px)',
      backgroundColor: 'inherit',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    '& .MuiOutlinedInput-root': {
      background: 'white',
      height: '2.7vw !important',
      lineHeight: '1vw',
      borderRadius: '0.4vw',
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
    },
    '& input': {
      padding: '0.7vw 1vw 0.7vw 0.7vw',
      boxSizing: 'border-box',
      fontSize: '0.9vw',
      fontFamily: fonts.fontType.roboto,
      color: '#696969 !important',
      '&::placeholder': {
        textTransform: 'uppercase',
        color: `${colors.placeHolderColor} !important`,
        opacity: 1,
        letterSpacing: 2,
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        fontFamily: fonts.fontType.roboto,
        fontWeight: 400,
      },
    },

  },
  titleRow: {
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    fontSize: '1.5vw',
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  agoText: {
    justifyContent: 'center',
    fontSize: 12,
    color: colors.primary,
    display: 'flex',
    marginLeft: '1.3vw',
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'left',
    },
    '& b': {
      marginLeft: 5,
    },
  },
  filterCSV: {
    '& ul': {
      padding: 0,
      marginTop: 10,
    },
    '& li': {
      listStyle: 'none',
      display: 'inline-block',
      padding: '5px 16px',
      color: colors.primary,
      fontSize: 16,
      fontWeight: '500',
      '@media (min-width: 768px)': {
        borderRight: `solid 1px ${colors.borderColor}`,
      },
      '& span': {
        color: colors.black,
        paddingBottom: 10,
      },
    },
  },
  dialogContent: {
    '& .MuiGrid-root': {
      '@media (max-width: 600px)': {
        height: '13vw !important',
      },
      '@media (max-width: 425px)': {
        height: '15vw !important',
      },
    },
  },
  direction: {
    display: 'flex',
    flexDirection: 'column',
  },
  dataGrid: {
    border: 'none',
    borderBottom: '1px solid',
    borderRight: '1px solid',
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '&>.MuiDataGrid-main': {
      '&>.MuiDataGrid-columnHeaders': {
        borderTop: '1px solid',
        minHeight: '56px !important',
      },
      '& div div div div >.MuiDataGrid-cell': {
        borderTop: '1px solid',
        borderLeft: '1px solid',
      },
      '& .MuiDataGrid-columnHeader': {
        borderLeft: '1px solid',
        backgroundColor: '#F5F6F7',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      paddingLeft: '45%',
    },
  },
  year: {
    paddingRight: '2rem',
    width: '100%',
    '@media (max-width: 600px)': {
      minWidth: '100%',
    },
    '@media (max-width: 1200px)': {
      marginBottom: '5px',
    },
    textAlign: 'start',
    minWidth: '12vw',
    '& .MuiFormControl-root': {
      '& .MuiInputLabel-root': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        fontSize: '0.9vw',
        letterSpacing: 1,
        [theme.breakpoints.down('md')]: {
          fontSize: 16,
        },
      },
      '& .MuiInput-root:before': {
        borderBottom: `0.0vw solid ${colors.primary}`,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          padding: '0.7vw !important',
          width: '8vw !important',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-root': {
        background: 'white',
        height: '2.7vw !important',
        lineHeight: '1vw',
        borderRadius: '0.4vw',
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
      },
      '& .MuiSvgIcon-root': {
        right: '0.5vw',
      },
      '& input': {
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        boxSizing: 'border-box',
        fontSize: '0.9vw',
        fontFamily: fonts.fontType.roboto,
        [theme.breakpoints.down('md')]: {
          fontSize: '2.5vw',
          height: '100%',
        },
        textAlign: 'center',
        color: 'black !important',
        '&::placeholder': {
          textTransform: 'uppercase',
          color: `${colors.placeHolderColor} !important`,
          opacity: 1,
          letterSpacing: 2,
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontFamily: fonts.fontType.roboto,
          fontWeight: 400,
        },
      },
    },
  },
  checkAbsent: {
    textAlign: 'center',
    fontWeight: '700',
    border: 'none',
    fontSize: '0.9vw',
    color: 'black !important',
    fontFamily: fonts.fontType.roboto,
    cursor: 'auto',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.1vw',
    },
    '&:focus': {
      outline: 'none !important',
      border: 'none !important',
    },
  },
  manageBottomScroll: {
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& > .MuiDataGrid-main': {
      '& > .MuiDataGrid-columnHeaders': {
        borderTop: '1px solid',
        minHeight: '56px !important',
      },
      '& div div div div > .MuiDataGrid-cell': {
        borderTop: '1px solid',
        borderLeft: '1px solid',
      },
      '& .MuiDataGrid-columnHeader': {
        borderLeft: '1px solid',
        backgroundColor: '#F5F6F7',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      paddingLeft: '45%',
    },
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.2) !important',
    },
  },
});

export default style;
