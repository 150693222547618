import { colors, fonts } from '../../../../theme';

const detailstyle = (theme) => ({
  buttonDiv: {
    marginTop: '20px',
    display: 'start',
    justifyContent: 'space-between',
    float: 'right',
    '@media (max-width:900px)': {
      '& .MuiButtonBase-root.MuiButton-root': {
        maxHeight: '55px !important',
        fontSize: 'smaller !important',
      },
      width: '120px',
    },
  },
  successText: {
    display: 'flex',
    float: 'right',
    fontSize: '1.0vw',
    marginLeft: '15px',
    color: colors.green,
  },
  year: {
    marginTop: '5px',
    '@media (max-width: 1200px)': {
      paddingRight: 10,
    },
    textAlign: 'start',
    minWidth: '8vw',
    '& .MuiFormControl-root': {
      '& .MuiInputLabel-root': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '1vw',
        },
        letterSpacing: 1,
      },
      '& .MuiInput-root': {
        '@media (max-width: 1200px)': {
          display: 'block',
        },
      },
      '& .MuiInput-root:before': {
        borderBottom: `0.1vw solid ${colors.actionIconsColor}`,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        fontSize: '1vw',
        padding: '0.7vw !important',
        width: '100%',
        '@media (min-width: 1200px)': {
          width: '6vw !important',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-root': {
        background: 'white',
        height: '2.7vw !important',
        lineHeight: '1vw',
        borderRadius: '0.4vw',
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          width: '1vw',
          height: '1vw',
        },
      },
      '& input': {
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        boxSizing: 'border-box',
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
        },
        fontFamily: fonts.fontType.roboto,
        color: '#696969 !important',
        '&::placeholder': {
          textTransform: 'uppercase',
          color: `${colors.placeHolderColor} !important`,
          opacity: 1,
          letterSpacing: 2,
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontFamily: fonts.fontType.roboto,
          fontWeight: 400,
        },
      },
    },
  },
  locationDropdown: {
    marginTop: '5px',
    textAlign: 'start',
    '@media (min-width: 1200px)': {
      minWidth: '40vw',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiInputBase-root.MuiInput-root': {
      '@media (min-width: 1200px)': {
        minWidth: '35vw !important',
        maxWidth: '35vw !important',
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiInputLabel-root': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '1vw',
          marginLeft: '1.3vw',
        },
        letterSpacing: 1,
      },
      '& .MuiInput-root': {
        '@media (max-width: 1200px)': {
          display: 'block',
          width: '85%',
          paddingRight: '15px',
          paddingLeft: '8px',
        },
      },
      '& .MuiInput-root:before': {
        borderBottom: `0.1vw solid ${colors.actionIconsColor}`,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        padding: '0.7vw 0.7vw 0.7vw 1.1vw !important',
        '@media (min-width: 1200px)': {
          fontSize: '1vw',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-root': {
        background: 'white',
        '@media (min-width: 1200px)': {
          height: '2.7vw !important',
          lineHeight: '1vw',
          borderRadius: '0.4vw',
        },
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          width: '1vw',
          height: '1vw',
        },
      },
      '& input': {
        '@media (min-width: 1200px)': {
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontSize: '0.9vw',
        },
        boxSizing: 'border-box',
        fontFamily: fonts.fontType.roboto,
        color: '#696969 !important',
        '&::placeholder': {
          textTransform: 'uppercase',
          color: `${colors.placeHolderColor} !important`,
          opacity: 1,
          letterSpacing: 2,
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontFamily: fonts.fontType.roboto,
          fontWeight: 400,
        },
      },
    },
  },
  dropdowns: {
    '@media (min-width: 1200px)': {
      maxHeight: '4vw',
    },
  },
  mapPinImg: {
    '@media (min-width: 1200px)': {
      width: '1vw',
      height: '1vw',
    },
    transform: 'translate(0.9vw, 0.1vw)',
  },
  location: {
    '@media (min-width: 1200px)': {
      minWidth: '40vw',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiFormControl-root': {
      '@media (max-width: 1200px)': {
        maxWidth: '85%',
      },
    },
    '& .MuiInputBase-root.MuiInput-root': {
      '@media (max-width: 1200px)': {
        maxWidth: '85%',
      },
      '@media (min-width: 1200px)': {
        minWidth: '40vw !important',
        maxWidth: '40vw !important',
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.primary} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '1.2vw',
        transform: 'translate(35px, 10px)',
        letterSpacing: 1,
      },
    },
    '& .MuiInput-root:before': {
      borderBottom: `0.1vw solid ${colors.actionIconsColor}`,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      textAlign: 'left',
      '@media (min-width: 1200px)': {
        width: '40vw',
        padding: '0.7vw',
        fontSize: '0.9vw',
      },
      fontFamily: fonts.fontType.roboto,
      transform: 'translate(8px, 0px)',
      backgroundColor: 'inherit',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    '& .MuiOutlinedInput-root': {
      background: 'white',
      '@media (min-width: 1200px)': {
        height: '2.7vw !important',
        lineHeight: '1vw',
      },
      borderRadius: '0.4vw',
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
    },
    '& .MuiSvgIcon-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        width: '1vw',
        height: '1vw',
      },
      right: '0.5vw',
    },
    '& input': {
      padding: '0.7vw 1vw 0.7vw 0.7vw',
      boxSizing: 'border-box',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        minWidth: 900,
      },
      fontFamily: fonts.fontType.roboto,
      color: '#696969 !important',
      '&::placeholder': {
        textTransform: 'uppercase',
        color: `${colors.placeHolderColor} !important`,
        opacity: 1,
        letterSpacing: 2,
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        fontFamily: fonts.fontType.roboto,
        fontWeight: 400,
      },
    },

  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  gridContainer: {
    padding: '2vw 2vw 2vw 3vw !important',
    minHeight: '65vh',
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    '@media (max-width: 600px)': {
      fontSize: '16px',
    },
    '@media (min-width: 1200px)': {
      fontSize: '1.0vw',
      marginLeft: '15px',
      marginTop: '19px',
    },
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  titleButton: {
    marginRight: '20px',
  },
  announcementsContainer: {
    color: colors.black,
    width: 'auto',
    fontFamily: fonts.fontType.roboto,
    marginTop: '2vw',
  },
  announcementsList: {
    textAlign: 'center',
    paddingBottom: 50,
  },
  tableView: {
    width: '100%',
    '& .MuiTable-root': {
      '@media (max-width: 1199px)': {
        width: '90% !important',
      },
    },
  },
  functionalGridItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropdownDiv: {
    width: '10vw',
    padding: '1vw',
    '@media (max-width:900px)': {
      width: '120px',
    },
  },
});

export default detailstyle;
