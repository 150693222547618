import moment from 'moment';

/* eslint-disable import/prefer-default-export */
const sortByDate = (a, b) => {
  if (a.quarter < b.quarter) {
    return -1;
  }
  if (a.quarter > b.quarter) {
    return 1;
  }
  return 0;
};
function getClassDetails(data) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const sort = data?.sort((a, b) => a?.homework_panel_marks?.week?.split(' ')[1] - b?.homework_panel_marks?.week?.split(' ')[1]);
  const setAscending = sort.sort(sortByDate);
  const classTimings = setAscending.filter((i) => i?.homework_panel_marks?.id).map((i) => {
    const startDay = moment(i?.homework_panel_marks?.startDate).format('Do');
    const startMonth = moment(i?.homework_panel_marks?.startDate).format('MMM');
    const endDay = moment(i?.homework_panel_marks?.endDate).format('Do');
    const endMonth = moment(i?.homework_panel_marks?.endDate).format('MMM');
    let name = '';
    if (startMonth === endMonth) {
      name = `${i?.homework_panel_marks?.week} | ${startDay} - ${endDay} ${startMonth} | ${i?.quarter}`;
    } else {
      name = `${i?.homework_panel_marks?.week} | ${startDay} ${startMonth} - ${endDay} ${endMonth} | ${i?.quarter}`;
    }
    return {
      id: i?.homework_panel_marks?.id,
      name,
      homeworkPanelId: i?.id,
    };
  });
  return classTimings;
}

export { getClassDetails };
