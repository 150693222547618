const userRoles = {
  SYSTEM_ADMIN: 'System Admin',
  SUPER_USER: 'Super User',
  USER_MANAGER: 'User Manager',
  REGION_COORDINATOR: 'Region Coordinator',
  LOCATION_COORDINATOR: 'Location Coordinator',
  STUDENT: 'Student',
  TEACHER: 'Teacher',
  CURRICULUM: 'Curriculum',
};
export default userRoles;
