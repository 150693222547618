import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import useStyles from '../../../../custom-hooks/useStyles';
import style from './style';

function CustomTooltip({ payload, label, active }) {
  const { t } = useTranslation('translation');
  if (active && payload !== null && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${t('VALUE')} : ${payload[0].value}`}</p>
        <p className="label">{`${t('DATE')} : ${label}`}</p>
      </div>
    );
  }

  return null;
}

function EnrollmentGrowthAcrossYear(props) {
  // eslint-disable-next-line prefer-const
  let { data, label, xAxisName } = props;
  const classes = useStyles(style)();

  // eslint-disable-next-line no-const-assign
  data = data.reduce((sumObj, obj) => {
    if (sumObj[obj.year]) {
      sumObj[obj.year].value += obj.value;
    } else {
      sumObj[obj.year] = obj;
    }
    return sumObj;
  }, {});

  // eslint-disable-next-line no-const-assign
  data = Object.values(data);
  data = Object.values(data).reverse();
  return (
    <>
      <div className={classes.heading}>{label}</div>
      <p className="verticle">{xAxisName}</p>
      <ResponsiveContainer
        width="100%"
        height={400}
      >
        <LineChart
          width={730}
          height={250}
          data={data}
          margin={{
            top: 5,
            right: 100,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" fontSize="8px" fontWeight={500} />
          <YAxis fontSize="8px" fontWeight={500} />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            height={40}
            dataKey="value"
            stroke="#2979ff"
            activeDot={{ r: 6 }}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

export default EnrollmentGrowthAcrossYear;
