/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import commonStyles from '../../../utils/commonClasses';
import ButtonAtom from '../../../components/atoms/button';
import DialogAtom from '../../../components/atoms/dialog';
import { Buttons, NavigateRoutes } from '../../../constant';
import { ViewIcon } from '../../../assets/svg';
import {
  getStudentLogs,
  getStudentLogDetail,
  getAllYears,
  getAcademicInfoId,
  getClassroomId,
} from '../../../store/actions/getStudent';
import { logDate, formatDate } from '../../../utils/methods';

function DialogContent({
  data,
  classes,
  t,
  isEmailTemplate = false,
  isAcademicDates = false,
  isRegistrationDates = false,
  isAddTeacher = false,
  isAddSection = false,
}) {
  const returnJSXFragment = (tag) => tag;

  const setValue = (value) => {
    if (value === 'true') { return 'Active'; } if (value === 'false') { return 'Inactive'; }
    return value;
  };
  const arr = [];
  if (isEmailTemplate) {
    data[0]?.email_template_change_log_details?.forEach((log, i) => {
      let oldVal = log.old_value;
      let newVal = log.new_value;
      if (log.field_name === 'dateOfBirth') {
        oldVal = formatDate(log.old_value, 'MMM DD, YYYY');
        newVal = formatDate(log.new_value, 'MMM DD, YYYY');
      }
      arr.push({
        id: i,
        item: log.field_name,
        from: setValue(oldVal),
        to: setValue(newVal),
      });
    });
  } else if (isAcademicDates) {
    data[0]?.academic_dates_change_log_details?.forEach((log, i) => {
      let oldVal = log.old_value;
      let newVal = log.new_value;
      if (log.field_name === 'dateOfBirth') {
        oldVal = formatDate(log.old_value, 'MMM DD, YYYY');
        newVal = formatDate(log.new_value, 'MMM DD, YYYY');
      }
      arr.push({
        id: i,
        item: log.field_name,
        from: setValue(oldVal),
        to: setValue(newVal),
      });
    });
  } else if (isRegistrationDates) {
    data[0]?.registration_dates_change_log_details?.forEach((log, i) => {
      let oldVal = log.old_value;
      let newVal = log.new_value;
      if (log.field_name === 'Registration Start Date' || log.field_name === 'Registration End Date' || log.field_name === 'Late Fee Start Date') {
        oldVal = formatDate(log.old_value, 'MMM DD, YYYY');
        newVal = formatDate(log.new_value, 'MMM DD, YYYY');
      }
      arr.push({
        id: i,
        item: log.field_name,
        from: setValue(oldVal),
        to: setValue(newVal),
      });
    });
  } else if (isAddTeacher) {
    data[0]?.add_teacher_change_log_details?.forEach((log, i) => {
      let oldVal = log.old_value;
      let newVal = log.new_value;
      arr.push({
        id: i,
        item: log.field_name,
        from: setValue(oldVal),
        to: setValue(newVal),
      });
    });
  } else if (isAddSection) {
    data[0]?.add_section_change_log_details?.forEach((log, i) => {
      let oldVal = log.old_value;
      let newVal = log.new_value;
      arr.push({
        id: i,
        item: log.field_name,
        from: setValue(oldVal),
        to: setValue(newVal),
      });
    });
  } else {
    data[0]?.log_details?.forEach((log, i) => {
      let oldVal = log.old_value;
      let newVal = log.new_value;
      if (log.field_name === 'dateOfBirth') {
        oldVal = formatDate(log.old_value, 'MMM DD, YYYY');
        newVal = formatDate(log.new_value, 'MMM DD, YYYY');
      }
      arr.push({
        id: i,
        item: log.field_name,
        from: setValue(oldVal),
        to: setValue(newVal),
      });
    });
  }
  const columns = [
    {
      field: 'item',
      flex: 1,
      type: 'string',
      headerName: t('ITEM'),
      sortable: false,
      headerClassName: 'itemHeader',
      cellClassName: 'itemCell',
    },
    {
      field: 'from',
      flex: 1,
      type: 'string',
      headerName: t('FROM'),
      sortable: false,
      headerClassName: 'fromHeader',
      cellClassName: 'fromCell',
      renderCell: (rowCell) => (
        rowCell.row.item === 'message' ?
          < div
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: returnJSXFragment(rowCell.row.from) }}
          /> : rowCell.row.from
      ),
    },
    {
      field: 'to',
      flex: 1,
      type: 'string',
      headerName: t('TO'),
      sortable: false,
      headerClassName: 'toHeader',
      cellClassName: 'toCell',
      renderCell: (rowCell) => (
          rowCell.row.item === 'message' ?
            < div
            // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: returnJSXFragment(rowCell.row.to) }}
            /> : rowCell.row.to
      ),
    },
  ];
  return (
    <Grid>
      <Grid container justifyContent="space-between" className={classes.dialogOverviewWrapper}>
        <Box item className={classes.item} xs={3} lg={3}>
          <Typography className={classes.dialogOverview}>
            {t('LOG_MESSAGE')}
          </Typography>
          <br />
          {data.row.log_message}
        </Box>
        <div className={classes.vl} />
        <Box item className={classes.item} xs={3} lg={3}>
          <Typography className={classes.dialogOverview}>
            {t('CHANGED_BY')}
          </Typography>
          <br />
          {data.row.added_by}
        </Box>
        <div className={classes.vl} />
        <Box item className={classes.item} xs={3} lg={3}>
          <Typography className={classes.dialogOverview}>
            {t('DATE')}
          </Typography>
          <br />
          {logDate(data.row.added_on)}
        </Box>
      </Grid>
      <Box mt={4} mb={2} className={classes.changesMadeSection}>
        <Typography className={classes.dialogOverview}>
          {t('CHANGES_MADE')}
        </Typography>
      </Box>

      <Box className={classes.dialogTableWrapper} height="15vw">
        <DataGrid
          rows={arr}
          columns={columns}
          className={classes.dataGrid}
          disableColumnFilter
          disableColumnSelector
          disableSelectionOnClick
          hideFooterPagination
          disableColumnMenu
          hideFooter
          sx={{
            '.MuiDataGrid-root': {
              outline: 'none !important',
              border: 'none !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 'none !important',
              borderTop: 'none',
              fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
              fontStyle: 'normal',
              fontWeight: 'bold !important',
              fontSize: '18px',
              lineHeight: '131.19%',
            },
            '& .MuiDataGrid-iconSeparator': {
              visibility: 'hidden',
            },
            '& .MuiDataGrid-cell ': {
              border: '0px',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
          }}
        />
      </Box>
    </Grid>
  );
}
function DialogFooter({
  classes, handleDialog, t,
}) {
  const commonClasses1 = useStyles(commonStyles)();

  return (
    <Grid container className={classes.content} justifyContent="flex-end">
      <ButtonAtom
        name={t('CLOSE')}
        onClick={handleDialog}
        btntype={Buttons.PRIMARY}
        className={`${commonClasses1.activeButton} ${classes.dialogButton}`}
      />
    </Grid>
  );
}
export default function StudentsLogs() {
  const { state: routeState } = useLocation();
  const dispatch = useDispatch();
  const { studentLogs, allAcademicYears } = useSelector((st) => st?.getStudent);
  const [rowData, setRowData] = useState([]);
  const [academicYears, setAcademicYearsa] = useState([]);
  const [title, setTitle] = useState('');
  const [columnsTitle, setColumnsTitle] = useState([]);
  const [isEmailTemplate, setIsEmailTemplate] = useState(false);
  const [isAcademicDates, setIsAcademicDates] = useState(false);
  const [isRegistrationDates, setIsRegistrationDates] = useState(false);
  const [isAddTeacher, setIsAddTeacher] = useState(false);
  const [isAddSection, setIsAddSection] = useState(false);
  let logs = {};
  const rows = [];
  useEffect(() => {
    dispatch(getAllYears());
  }, []);
  const setYear = (value) => {
    if (routeState.isAcademicDates && academicYears.length > 0) {
      return academicYears.filter((a) => a.id === value)[0]?.academicYear;
    }
    return value;
  };
  const setYearAndCourse = async (value, isRegistrationDates = false) => {
    const payload = { id: value };
    try {
      if (isRegistrationDates) {
        const academicInfo = await getAcademicInfoId(payload)();
        return {
            year: academicInfo?.data[0]?.academicYear || '',
            course: academicInfo?.data[0]?.course?.name || '',
        };
      } else {
        const googleClassInfo = await getClassroomId(payload)();
        return {
            year: googleClassInfo?.data?.academic_year || '',
            course: googleClassInfo?.data?.course?.name || '',
            section: googleClassInfo?.data?.section || '',
            location: googleClassInfo?.data?.location?.name || '',
        };
      }
    } catch (error) {
        console.error("Error occurred while fetching Log info:", error);
        return {
            year: '',
            course: '',
        };
    }
  };

  useEffect(() => {
    const updatedRows = studentLogs?.map(async (logData) => {
      let logYear = null;
      let logCourse = null;
      let section = null;
      let location = null;
      let numSections = null;
      if (routeState.isAcademicDates) {
        logYear = setYear(logData?.academic_dates_id);
      } else if (routeState.isRegistrationDates) {
        const logYearAndCourse = await setYearAndCourse(logData?.academic_info_id, true);
        logYear = logYearAndCourse.year;
        logCourse = logYearAndCourse.course;
      } else if (routeState.isAddTeacher) {
        const googleClassInfo = await setYearAndCourse(logData?.google_class_id, false);
        logYear = googleClassInfo.year;
        logCourse = googleClassInfo.course;
        section = googleClassInfo.section;
        location = googleClassInfo.location;
      } else if (routeState.isAddSection) {
        logYear = logData.academicYear;
        logCourse = logData.course.name;
        location = logData.location.name;
        numSections = logData.numSections;
      } else {
        logYear = logData?.year;
        logCourse = logData?.course || '';
      }
      const logs = {
        ...logData,
        added_by: `${logData?.user?.first_name}  ${logData?.user?.last_name}`,
        user: `${logData?.user?.first_name}  ${logData?.user?.last_name}`,
        added_on: logDate(logData?.added_on),
        course: logCourse,
        id: logData?.id,
        log_message: logData?.log_message,
        year: logYear,
        section,
        location,
        numSections,
      };
      return logs;
    });
    Promise.all(updatedRows).then((updatedRowsData) => {
      setRowData(updatedRowsData);
    });
  }, [studentLogs]);

  useEffect(() => {
    const years = [];
    allAcademicYears?.forEach((year) => {
      if (year.id) {
        years.push({
          academicYear: year.academicYear,
          id: year.id,
        });
      }
    });
    setAcademicYearsa(years);
  }, [allAcademicYears]);

  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const navigate = useNavigate();

  const renderDialog = (event, value) => {
    event.preventDefault();
    dispatch(getStudentLogDetail(value.id, routeState.logVal, routeState.isAcademicDates, routeState.isRegistrationDates, routeState.isAddTeacher, routeState.isAddSection)).then((res) => {
      setMessage({ ...value, ...res.data });
      setDialogOpen(true);
    });
  };

  function onBackButtonClick() {
    navigate(routeState?.lastRoute ? routeState.lastRoute : NavigateRoutes.STUDENTS_VIEW);
  }

  useEffect(() => {
    dispatch(getStudentLogs(routeState?.id || ' ', routeState.logVal, routeState.isAcademicDates, routeState.isRegistrationDates, routeState.isAddTeacher, routeState.isAddSection));
  }, []);

  const columns = [
    {
      field: 'log_message',
      flex: 1,
      type: 'string',
      headerName: i18next.t('LOG_MESSAGE'),
      sortable: false,
    },
    {
      field: 'year',
      flex: 1,
      type: 'string',
      headerName: i18next.t('YEAR'),

      sortable: false,
    },
    {
      field: 'course',
      flex: 1,
      type: 'string',
      headerName: i18next.t('COURSE'),
      sortable: false,
    },
    {
      field: 'added_by',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_BY'),
      sortable: false,
    },
    {
      field: 'added_on',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_ON'),
      sortable: false,
      cellClassName: 'addedOnCell',
    },
    {
      field: 'view',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      headerClassName: 'viewLogHeader',
      cellClassName: 'viewLogCell',
      renderHeader: () => (
        <>
          <ViewIcon fontSize="small" />
          {i18next.t('VIEW')}
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="" onClick={(event) => renderDialog(event, params)} aria-hidden="false" style={{ color: '#025EE6' }}>
          {i18next.t('VIEW_LOG')}
        </a>
      ),
    },
  ];

  const academicDatesColumns = [
    {
      field: 'log_message',
      flex: 1,
      type: 'string',
      headerName: i18next.t('LOG_MESSAGE'),
      sortable: false,
    },
    {
      field: 'year',
      flex: 1,
      type: 'string',
      headerName: i18next.t('YEAR'),

      sortable: false,
    },
    {
      field: 'added_by',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_BY'),
      sortable: false,
    },
    {
      field: 'added_on',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_ON'),
      sortable: false,
      cellClassName: 'addedOnCell',
    },
    {
      field: 'view',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      headerClassName: 'viewLogHeader',
      cellClassName: 'viewLogCell',
      renderHeader: () => (
        <>
          <ViewIcon fontSize="small" />
          {i18next.t('VIEW')}
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="" onClick={(event) => renderDialog(event, params)} aria-hidden="false" style={{ color: '#025EE6' }}>
          {i18next.t('VIEW_LOG')}
        </a>
      ),
    },
  ];

  const userManagerColumns = [
    {
      field: 'log_message',
      flex: 1,
      type: 'string',
      headerName: i18next.t('LOG_MESSAGE'),
      sortable: false,
    },
    {
      field: 'added_by',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_BY'),
      sortable: false,
    },
    {
      field: 'added_on',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_ON'),
      sortable: false,
    },
    {
      field: 'view',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      headerClassName: 'viewLogHeader',
      cellClassName: 'viewLogCell',
      renderHeader: () => (
        <>
          <ViewIcon fontSize="small" />
          {i18next.t('VIEW')}
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="" onClick={(event) => renderDialog(event, params)} aria-hidden="false" style={{ color: '#025EE6' }}>
          {i18next.t('VIEW_LOG')}
        </a>
      ),
    },
  ];

  const registrationDatesColumns = [
    {
      field: 'log_message',
      flex: 1,
      type: 'string',
      headerName: i18next.t('LOG_MESSAGE'),
      sortable: false,
    },
    {
      field: 'year',
      flex: 1,
      type: 'string',
      headerName: i18next.t('YEAR'),
      sortable: false,
    },
    {
      field: 'course',
      flex: 1,
      type: 'string',
      headerName: i18next.t('COURSE'),
      sortable: false,
    },
    {
      field: 'added_by',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_BY'),
      sortable: false,
    },
    {
      field: 'added_on',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_ON'),
      sortable: false,
      cellClassName: 'addedOnCell',
    },
    {
      field: 'view',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      headerClassName: 'viewLogHeader',
      cellClassName: 'viewLogCell',
      renderHeader: () => (
        <>
          <ViewIcon fontSize="small" />
          {i18next.t('VIEW')}
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="" onClick={(event) => renderDialog(event, params)} aria-hidden="false" style={{ color: '#025EE6' }}>
          {i18next.t('VIEW_LOG')}
        </a>
      ),
    },
  ];

  const addTeacherColumns = [
    {
      field: 'log_message',
      flex: 1,
      type: 'string',
      headerName: i18next.t('LOG_MESSAGE'),
      sortable: false,
    },
    {
      field: 'year',
      flex: 0.5,
      type: 'string',
      headerName: i18next.t('YEAR'),
      sortable: false,
    },
    {
      field: 'location',
      flex: 1,
      type: 'string',
      headerName: i18next.t('LOCATION'),
      sortable: false,
    },
    {
      field: 'course',
      flex: 0.5,
      type: 'string',
      headerName: i18next.t('COURSE'),
      sortable: false,
    },
    {
      field: 'section',
      flex: 0.5,
      type: 'string',
      headerName: i18next.t('SECTION'),
      sortable: false,
    },
    {
      field: 'added_by',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_BY'),
      sortable: false,
    },
    {
      field: 'added_on',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_ON'),
      sortable: false,
      cellClassName: 'addedOnCell',
    },
    {
      field: 'view',
      flex: 1.3,
      align: 'right',
      headerAlign: 'right',
      headerClassName: 'viewLogHeader',
      cellClassName: 'viewLogCell',
      renderHeader: () => (
        <>
          <ViewIcon fontSize="small" />
          {i18next.t('VIEW')}
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="" onClick={(event) => renderDialog(event, params)} aria-hidden="false" style={{ color: '#025EE6' }}>
          {i18next.t('VIEW_LOG')}
        </a>
      ),
    },
  ];

  const addSectionColumns = [
    {
      field: 'log_message',
      flex: 1,
      type: 'string',
      headerName: i18next.t('LOG_MESSAGE'),
      sortable: false,
    },
    {
      field: 'year',
      flex: 0.5,
      type: 'string',
      headerName: i18next.t('YEAR'),
      sortable: false,
    },
    {
      field: 'location',
      flex: 1,
      type: 'string',
      headerName: i18next.t('LOCATION'),
      sortable: false,
    },
    {
      field: 'course',
      flex: 0.5,
      type: 'string',
      headerName: i18next.t('COURSE'),
      sortable: false,
    },
    {
      field: 'numSections',
      flex: 1,
      type: 'string',
      headerName: i18next.t('NEW_ADDED_SECTIONS'),
      sortable: false,
    },
    {
      field: 'added_by',
      flex: 1,
      type: 'string',
      headerName: i18next.t('ADDED_BY'),
      sortable: false,
    },
    {
      field: 'added_on',
      flex: 0.75,
      type: 'string',
      headerName: i18next.t('ADDED_ON'),
      sortable: false,
      cellClassName: 'addedOnCell',
    },
    {
      field: 'view',
      flex: 1.3,
      align: 'right',
      headerAlign: 'right',
      headerClassName: 'viewLogHeader',
      cellClassName: 'viewLogCell',
      renderHeader: () => (
        <>
          <ViewIcon fontSize="small" />
          {i18next.t('VIEW')}
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="" onClick={(event) => renderDialog(event, params)} aria-hidden="false" style={{ color: '#025EE6' }}>
          {i18next.t('VIEW_LOG')}
        </a>
      ),
    },
  ];

  useEffect(() => {
    if (routeState?.lastRoute === NavigateRoutes?.USER_MANAGER_VIEW) {
       setColumnsTitle(userManagerColumns)
       setTitle(t('VIEW_USER_LOGS'))
    } else if (routeState?.lastRoute === NavigateRoutes?.WELCOME_EMAIL) {
      setColumnsTitle(userManagerColumns)
      setTitle(t('VIEW_TEMPLATE_LOGS'))
      setIsEmailTemplate(true);
    } else if (routeState?.lastRoute === NavigateRoutes?.ACADEMIC_DATES) {
      setColumnsTitle(academicDatesColumns)
      setTitle(t('VIEW_ACADEMIC_DATES_LOGS'))
      setIsAcademicDates(true);
    } else if (routeState?.lastRoute === NavigateRoutes?.REGISTRATION_DATES) {
      setColumnsTitle(registrationDatesColumns)
      setTitle(t('VIEW_REGISTRATION_DATES_LOGS'))
      setIsRegistrationDates(true);
    } else if (routeState?.lastRoute === NavigateRoutes?.LC_CLASSES && routeState?.isAddTeacher) {
      setColumnsTitle(addTeacherColumns)
      setTitle(t('VIEW_ADD_TEACHER_LOGS'))
      setIsAddTeacher(true);
    } else if (routeState?.lastRoute === NavigateRoutes?.LC_CLASSES && routeState?.isAddSection) {
      setColumnsTitle(addSectionColumns)
      setTitle(t('VIEW_ADD_SECTION_LOGS'))
      setIsAddSection(true);
    } else {
      setColumnsTitle(columns)
      setTitle(t('VIEW_STUDENT_LOGS'))
    }
  }, []);

  return (
    <>
      <Typography className={classes.mainHeaderTitle}>
        { title }
      </Typography>
      <div className={classes.tableWrapper}>
        <DataGrid
          rows={rowData || []}
          columns={columnsTitle}
          className={classes.root}
          autoHeight
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableSelectionOnClick
          hideFooterPagination
          hideFooter
          sx={{
            '.MuiDataGrid-root': {
              outline: 'none !important',
              border: 'none !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '0.1vw solid #025EE6 !important',
              borderTop: 'none',
              fontFamily: 'Roboto,sans-serif',
              fontStyle: 'normal',
              fontWeight: '500',
              '@media (min-width: 1200px)': {
                fontSize: '1.2vw',
              },
              lineHeight: '2vw',
              color: '#025EE6',
            },
            '& .MuiDataGrid-cell ': {
              '@media (max-width: 499px)': {
                overflow: 'visible !important',
              },
              border: '0vw',
            },
            '& .MuiDataGrid-iconSeparator': {
              visibility: 'hidden',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle:focus': {
              outline: 'none',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none !important',
            },
          }}
        />
        <Grid container className={classes.backButton} justifyContent="flex-end">
          <ButtonAtom
            name={t('BACK')}
            /* eslint-disable-next-line react/jsx-no-bind */
            onClick={onBackButtonClick}
            btntype={Buttons.PRIMARY}
          />
        </Grid>
        <DialogAtom
          isOpen={isDialogOpen}
          customClass={classes.viewLogDialogAtom}
          dialogHeading={t('VIEW_LOGS')}
          secHandle={() => setDialogOpen(false)}
          content={<DialogContent data={message} classes={classes} t={t} isEmailTemplate={isEmailTemplate} isAcademicDates={isAcademicDates} isRegistrationDates={isRegistrationDates} isAddTeacher={isAddTeacher} isAddSection={isAddSection}/>}
          footer={(
            <DialogFooter
              classes={classes}
              handleDialog={() => setDialogOpen(false)}
              t={t}
            />
          )}
        />
      </div>
    </>
  );
}
