import Constants from '../constant';

const initialState = {
  schema: null,
  quarterDetails: null,

};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Constants.GET_ACADEMIC_SCHEMA:

      return {
        ...state,
        schema: action.payload,
      };

    case Constants.GET_QUARTER_DETAILS:
      return {
        ...state,
        quarterDetails: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
