import constant from '../constant';
import {
  createAssignmentSchemaService,
  getAssignmentSchemaService,
  updateAssignmentMarksService,
  updateAssignmentSchemaService,
} from '../services/auth';

const getAssignmentsSchema = (courseId, academicYear, quarter, setLoading) => (dispatch) => {
  getAssignmentSchemaService(courseId, academicYear, quarter)
    .then((response) => {
      dispatch({
        type: constant.GET_ASSIGNMENTS_SCHEMA,
        payload: response?.data,
      });
      setLoading(false);
    })
    .catch(() => {
    });
};

const updateAssignmentsSchema = (requestObj, refreshSchema, setLoading, setError, setSuccessMsg) => (dispatch) => {
  updateAssignmentSchemaService(requestObj)
    .then((response) => {
      if (typeof response?.data === 'string') {
        setError(response.data);
      } else {
        dispatch({
          type: constant.GET_ASSIGNMENTS_SCHEMA,
          payload: response?.data,
        });
        setSuccessMsg('Assignments Schema Created/Updated Successfully');
      }
      refreshSchema();
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
};

const createAssignmentsSchema = (requestObj, refreshSchema, setLoading, setSuccessMsg) => (dispatch) => {
  createAssignmentSchemaService(requestObj)
    .then((response) => {
      dispatch({
        type: constant.GET_ASSIGNMENTS_SCHEMA,
        payload: response?.data,
      });
      refreshSchema();
      setLoading(false);
      setSuccessMsg('Assignments Schema Created/Updated Successfully');
    })
    .catch(() => {
      setLoading(false);
    });
};

const updateAssignmentsMarks = (requestObj, refreshList) => () => {
  const {
    studentId, courseName, quarterName,
    academicYear, studentAssignmentDetailMarks, isPresent, isAttended, locationId,
  } = requestObj;
  const payload = {
    studentId,
    academicYear,
    courseId: courseName,
    quarter: quarterName,
    studentAssignmentDetailMarks,
    isPresent,
    isAttended,
    locationId,
  };
  updateAssignmentMarksService(payload)
    .then(() => {
      refreshList();
    })
    .catch(() => {

    });
};

export {
  getAssignmentsSchema,
  updateAssignmentsSchema,
  createAssignmentsSchema,
  updateAssignmentsMarks,
};
