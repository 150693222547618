import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Box, Card, Grid } from '@mui/material';
import moment from 'moment';
import useStyles from '../../custom-hooks/useStyles';
import {
  getAllCourses,
  getAcademicGrades,
  getLocations,
  getCurrentAcademicYear,
  getCurrentDobCutoff,
  getRegistrationAcademicYearDates,
  getTempRegisterInfo,
} from '../../store/actions/getStudent';
import { getEmailAvailabilityService } from '../../store/services/auth';
import { checkDateValid } from '../../utils/methods';
import { setTermsAndConditionSettingDetails } from '../../store/actions/getAdmin';
import { postFeeStructureSpotReg, getDonations } from '../../store/actions/getPayment';
import logo from '../../assets/images/logo.png';
import style from './style';
import RegisterHeader from './header';
import HeaderWithIcons from './header-with-icons';
import UserInfo from './user-info';
import PaymentInfo from './payment-info';
import DialogAtom from '../../components/atoms/dialog';
import ButtonAtom from '../../components/atoms/button';
import { Buttons } from '../../constant';
import { getParentPlaceInformation } from '../../utils/mapMethod';
import usePayment from '../../custom-hooks/usePayment';
import { getLocalStorage } from '../../utils/localStorageMethod';

function RegistrationForm() {
  const { t } = useTranslation();
  const classes = useStyles(style)();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenError, setIsOpenError] = useState(false);
  const [contributionAmount, setContributionAmount] = useState('0.00');
  const [contributionCause, setContributionCause] = useState(0);
  const [contributionCauseAmount, setContributionCauseAmount] = useState(true);
  const [parentOneEmailAlreadyExist, setParentOneEmailAlreadyExist] = useState(false);
  const [parentTwoEmailAlreadyExist, setParentTwoEmailAlreadyExist] = useState(false);
  const [parentTwoInfo, setParentTwoInfo] = useState({
    parentTwoTitle: '',
    parentTwoEmail: '',
    parentTwoFirstName: '',
    parentTwoLastName: '',
    parentTwoContact: '',
  });
  const [studentTwoInfo, setStudentTwoInfo] = useState({
    studentTwoFirstName: '',
    studentTwoLastName: '',
    studentTwoDOB: '',
    studentTwoAcaGrade: '',
    studentTwoLocation: '',
    studentTwoClassTimings: '',
    studentTwoCourse: '',
    studentTwoAcaYear: '',
  });
  const [homeAddressInfo, setHomeAddressInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const studentInfoFromStore = useSelector((state) => state?.getStudent);
  const gradeOptions = studentInfoFromStore?.academicGrades;
  const [mapOptions, setMapOptions] = useState(studentInfoFromStore?.locations);
  const [classLevelOptionSTwo, setClassLevelOptionSTwo] = useState([]);
  const [classLevelOptionSOne, setClassLevelOptionSOne] = useState([]);
  const [selectedYearStudentOne, setSelectedYearStudentOne] = useState();
  const [selectedYearStudentTwo, setSelectedYearStudentTwo] = useState();
  const [missingHomeAddress, setMissingHomeAddress] = useState('');
  const [registrationId, setRegistrationId] = useState(null);
  const [registrationTempInfo, setRegistrationTempInfo] = useState(null);
  const [isSiblingLastNameMismatch, setIsSiblingLastNameMismatch] = useState(false);
  const [isSiblingFirstNameMatch, setIsSiblingFirstNameMatch] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  React.useEffect(() => {
    dispatch(getDonations());
    dispatch(getAllCourses());
    dispatch(getAcademicGrades());
    dispatch(getLocations());
    dispatch(setTermsAndConditionSettingDetails());
    dispatch(getCurrentAcademicYear());
    dispatch(getCurrentDobCutoff());
    dispatch(getRegistrationAcademicYearDates());
    const registrationAcademicYear = getLocalStorage('registrationAcademicYear') || getLocalStorage('currentAcademicYear');
    setSelectedYearStudentOne(registrationAcademicYear);
    setSelectedYearStudentTwo(registrationAcademicYear);
    const currentUrl = window.location.href;
    const newUrl = new URL(currentUrl);
    const id = new URLSearchParams(newUrl.search).get('id');
    setRegistrationId(id);
  }, []);

  React.useEffect(() => {
    if (registrationId !== null && registrationId !== undefined) {
      dispatch(getTempRegisterInfo(registrationId));
    }
  }, [registrationId]);

  React.useEffect(() => {
    const registrationTempInfos = studentInfoFromStore?.registrationTempInfo;
    if (registrationTempInfos !== null && registrationTempInfos !== undefined) {
      setRegistrationTempInfo(registrationTempInfos);
    }
  }, [studentInfoFromStore?.registrationTempInfo]);

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    `${t('USER_INFO')}`,
    `${t('PAYMENT_INFO')}`,
  ];
  const handleStepper = (index, callback) => {
    if (callback) { callback(); }
    setActiveStep(index);
  };

  const getDestinationLatLng = (nearestValue) => {
    if (!nearestValue) {
      return { lat: '', lng: '' };
    }
    return mapOptions.find((opt) => opt.id === nearestValue);
  };

  const getCourseNameSone = (courseId) => {
    if (!courseId) {
      return { name: '' };
    }
    return classLevelOptionSOne.find((opt) => opt.id === courseId);
  };
  const getCourseNameStwo = (courseId) => {
    if (!courseId) {
      return { name: '' };
    }
    return classLevelOptionSTwo.find((opt) => opt.id === courseId);
  };
  const getAcaGradeSone = (gradeId) => {
    if (!gradeId) {
      return { name: '' };
    }
    return gradeOptions.find((opt) => opt.id === gradeId);
  };
  const getAcaGradeStwo = (gradeId) => {
    if (!gradeId) {
      return { name: '' };
    }
    return gradeOptions.find((opt) => opt.id === gradeId);
  };

  const isParentTwoFilled = () => {
    const isAnyKeyHasValue = Object.keys(parentTwoInfo).some(
      (k) => parentTwoInfo[k],
    );

    return isAnyKeyHasValue;
  };

  const isStudentTwoFilled = () => {
    const isAnyKeyHasValue = Object.keys(studentTwoInfo).some(
      (k) => studentTwoInfo[k],
    );

    return isAnyKeyHasValue;
  };

  const parentValidations = {
    title: Yup.string(t('TITLE_REQUIRED'))
      .min(2, t('TITLE_REQUIRED'))
      .required(t('TITLE_REQUIRED')),
    email: Yup.string(t('EMAIL_REQUIRED'))
      .email(t('EMAIL_INVALID'))
      .required(t('EMAIL_REQUIRED')),
    firstName: Yup.string(t('FIRSTNAME_REQUIRED'))
      .matches(/^[aA-zZ\s]+$/, t('NOT_SPECIAL_CHAR'))
      .min(2, t('FIRSTNAME_MIN'))
      .required(t('FIRSTNAME_REQUIRED')),
    lastName: Yup.string(t('LASTNAME_REQUIRED'))
      .matches(/^[aA-zZ\s]+$/, t('NOT_SPECIAL_CHAR'))
      .min(2, t('LASTNAME_MIN'))
      .required(t('LASTNAME_REQUIRED')),
    contact: Yup.string(t('CONTACTNO_REQUIRED'))
      .required(t('CONTACTNO_REQUIRED'))
      .test('isValidPhoneNumber', t('INVALID_PHONE'), (value) => (value ? isValidPhoneNumber(value) : false)),
  };
  const parentOneValidations = Yup.object({
    parentOneTitle: parentValidations?.title,
    parentOneEmail: parentValidations?.email,
    parentOneFirstName: parentValidations?.firstName,
    parentOneLastName: parentValidations?.lastName,
    parentOneContact: parentValidations?.contact,
    homeAddress: Yup.string(t('HOMEADDRESS_REQUIRED'))
      .required(t('HOMEADDRESS_REQUIRED'))
      .test('isValidAddress', t('SELECT_ADDRESS_REQUIRED_FROM_SUGGESTION'), (value) => (value ? value === homeAddressInfo?.add : false)),
  });

  const parentTwoValidations = Yup.object({
    parentTwoTitle: parentValidations?.title,
    parentTwoEmail: parentValidations?.email,
    parentTwoFirstName: parentValidations?.firstName,
    parentTwoLastName: parentValidations?.lastName,
    parentTwoContact: parentValidations?.contact,
  });

  const studentOneValidations = Yup.object({
    studentOneFirstName: parentValidations?.firstName,
    studentOneLastName: parentValidations?.lastName,
    studentOneDOB: Yup.string(t('DATEOFBIRTH_REQUIRED'))
      .required(t('DATEOFBIRTH_REQUIRED'))
      .test('isValidAddress', t('INVALID_DATE'), (value) => (value ? checkDateValid(value) : false)).nullable(),
    studentOneAcaGrade: Yup.string(t('GRADE_REQUIRED'))
      .required(t('GRADE_REQUIRED')),
    studentOneLocation: Yup.string(t('SORTEDNEAREST_REQUIRED'))
      .required(t('SORTEDNEAREST_REQUIRED')),
    studentOneCourse: Yup.string(t('CLASSLEVEL_REQUIRED'))
      .required(t('CLASSLEVEL_REQUIRED')),
  });

  const studentTwoValidations = Yup.object({
    studentTwoFirstName: parentValidations?.firstName,
    studentTwoLastName: parentValidations?.lastName,
    studentTwoDOB: Yup.string(t('DATEOFBIRTH_REQUIRED'))
      .required(t('DATEOFBIRTH_REQUIRED'))
      .test('isValidAddress', t('INVALID_DATE'), (value) => (value ? checkDateValid(value) : false)).nullable(),
    studentTwoAcaGrade: Yup.string(t('GRADE_REQUIRED'))
      .required(t('GRADE_REQUIRED')),
    studentTwoLocation: Yup.string(t('SORTEDNEAREST_REQUIRED'))
      .required(t('SORTEDNEAREST_REQUIRED')),
    studentTwoCourse: Yup.string(t('CLASSLEVEL_REQUIRED'))
      .required(t('CLASSLEVEL_REQUIRED')),
  });

  const concatenatedParentStudentSchema = parentOneValidations.concat(studentOneValidations);

  function capitalizeFirstLetter(string) {
    let concatenedString = string;
    if (string) {
      concatenedString = string.split(' ') // Dividir el nombre en palabras
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalizar la primera letra de cada palabra
        .join(' '); // Unir las palabras de nuevo en un solo string
    }
    return concatenedString;
  }
  // eslint-disable-next-line no-nested-ternary
  const validationSchema = isParentTwoFilled()
    ? isStudentTwoFilled()
      ? concatenatedParentStudentSchema.concat(studentTwoValidations).concat(parentTwoValidations)
      : concatenatedParentStudentSchema.concat(parentTwoValidations)
    : isStudentTwoFilled()
      ? concatenatedParentStudentSchema.concat(studentTwoValidations)
      : concatenatedParentStudentSchema;
  const formik = useFormik({
    initialValues: {
      parentOneTitle: '',
      parentOneFirstName: '',
      parentOneLastName: '',
      parentOneEmail: '',
      homeAddress: '',
      aptSuite: '',
      parentOneContact: '',
      parentTwoTitle: '',
      parentTwoFirstName: '',
      parentTwoLastName: '',
      parentTwoEmail: '',
      parentTwoContact: '',
      studentOneFirstName: '',
      studentOneLastName: '',
      studentOneDOB: new Date(),
      studentOneAcaGrade: '',
      studentOneLocation: '',
      studentOneClassTimings: '',
      studentOneCourse: '',
      studentOneAcaYear: '',
      studentTwoFirstName: '',
      studentTwoLastName: '',
      studentTwoDOB: new Date(),
      studentTwoAcaGrade: '',
      studentTwoLocation: '',
      studentTwoClassTimings: '',
      studentTwoCourse: '',
      studentTwoAcaYear: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const primaryStudentLastName = capitalizeFirstLetter(values?.studentOneLastName);
      const siblingLastName = capitalizeFirstLetter(values?.studentTwoLastName);
      setLoading(true);
      const locationLatLng = getDestinationLatLng(values.studentOneLocation);
      const courseNameStudentOne = getCourseNameSone(values.studentOneCourse);
      const studentOneAcaGrade = getAcaGradeSone(values.studentOneAcaGrade);
      const payload = {
        parent1: {
          user: {
            firstName: capitalizeFirstLetter(values.parentOneFirstName),
            lastName: capitalizeFirstLetter(values.parentOneLastName),
            middleName: '',
            contactNumber: values.parentOneContact,
            personalEmail: values.parentOneEmail,
          },
          userDetails: {
            title: values.parentOneTitle,
          },
        },
        addresses: {
          address: values.homeAddress,
          aptSuite: values.aptSuite,
          latitude: locationLatLng.locationAddress.latitude,
          longitude: locationLatLng.locationAddress.longitude,
        },

        students: [
          {
            user: {
              firstName: capitalizeFirstLetter(values.studentOneFirstName),
              lastName: primaryStudentLastName,
            },
            studentDetail: {
              dateOfBirth: moment(values.studentOneDOB).format('YYYY-MM-DD'),
            },
            enrollmentCourse: {
              academicYear: selectedYearStudentOne,
              academicGrade: studentOneAcaGrade.name,
              locationId: values.studentOneLocation,
              courseId: values.studentOneCourse,
              courseName: courseNameStudentOne.name,
            },
          },
        ],
        isNewRegistration: true,
      };
      if (values.parentTwoTitle !== '') {
        payload.parent2 = {
          user: {
            firstName: capitalizeFirstLetter(values.parentTwoFirstName),
            lastName: capitalizeFirstLetter(values.parentTwoLastName),
            middleName: '',
            contactNumber: values.parentTwoContact,
            personalEmail: values.parentTwoEmail,
          },
          userDetails: {
            title: values.parentTwoTitle,
          },
        };
      }
      if (values.studentTwoFirstName) {
        const courseNameStudentTwo = getCourseNameStwo(values.studentTwoCourse);
        const studentTwoAcaGrade = getAcaGradeStwo(values.studentTwoAcaGrade);

        payload.students.push({
          user: {
            firstName: capitalizeFirstLetter(values.studentTwoFirstName),
            lastName: siblingLastName,
          },
          studentDetail: {
            dateOfBirth: moment(values.studentTwoDOB).format('YYYY-MM-DD'),
          },
          enrollmentCourse: {
            academicYear: selectedYearStudentTwo,
            academicGrade: studentTwoAcaGrade.name,
            locationId: values.studentTwoLocation,
            courseId: values.studentTwoCourse,
            courseName: courseNameStudentTwo.name,
          },
        });
      }

      dispatch(postFeeStructureSpotReg(payload, setLoading));
      handleStepper(1);
    },
  });

  function validateAllFields() {
    const fieldsToValidate = [
      'parentOneTitle',
      'parentOneEmail',
      'parentOneFirstName',
      'parentOneLastName',
      'parentOneContact',
      'homeAddress',
      'studentOneFirstName',
      'studentOneLastName',
      'studentOneDOB',
      'studentOneAcaGrade',
      'studentOneLocation',
      'studentOneCourse',
    ];

    const newTouched = { ...formik.touched };

    fieldsToValidate.forEach((field) => {
      newTouched[field] = true;
      formik.validateField(field);
    });

    if (isParentTwoFilled()) {
      const parentTwoFieldsToValidate = [
        'parentTwoTitle',
        'parentTwoEmail',
        'parentTwoFirstName',
        'parentTwoLastName',
        'parentTwoContact',
      ];
      parentTwoFieldsToValidate.forEach((field) => {
        newTouched[field] = true;
        formik.validateField(field);
      });
    }

    if (isStudentTwoFilled()) {
      const studentTwoFieldsToValidate = [
        'studentTwoFirstName',
        'studentTwoLastName',
        'studentTwoDOB',
        'studentTwoAcaGrade',
        'studentTwoLocation',
        'studentTwoCourse',
      ];
      studentTwoFieldsToValidate.forEach((field) => {
        newTouched[field] = true;
        formik.validateField(field);
      });
    }

    formik.setTouched(newTouched, true);
  }

  const getGeneralErrorText = () => {
    if (Object.keys(formik.errors).length > 0 || isSiblingLastNameMismatch || isSiblingFirstNameMatch || parentOneEmailAlreadyExist || parentTwoEmailAlreadyExist) {
      const studentTwoFields = [
        'studentTwoFirstName',
        'studentTwoLastName',
        'studentTwoAcaGrade',
        'studentTwoCourse',
        'studentTwoAcaYear',
      ];

      let isFilled = false;

      for (let i = 0; i < studentTwoFields.length; i++) {
        const field = studentTwoFields[i];
        if (formik.errors[field] && formik.errors[field] !== '') {
          isFilled = true;
          break;
        }
      }

      if (isFilled) {
        if (isStudentTwoFilled()) {
          setIsOpenError(true);
        } else {
          formik.setFieldError('studentTwoFirstName', undefined);
          formik.setFieldError('studentTwoLastName', undefined);
          formik.setFieldError('studentTwoCourse', undefined);
          formik.setFieldError('studentTwoAcaYear', undefined);
          formik.handleSubmit();
        }
      } else {
        setIsOpenError(true);
      }
    } else {
      formik.handleSubmit();
    }
  };

  const paymentData = usePayment();
  React.useEffect(() => {
    if (registrationTempInfo && Object.keys(registrationTempInfo).length > 0 && paymentData?.donations.length > 0) {
      const contributionCauses = paymentData?.donations.filter((contribution) => contribution.name === registrationTempInfo?.donation?.type);
      const ContributionAmount = Number.isInteger(registrationTempInfo?.donation?.amount) ? `${registrationTempInfo?.donation?.amount}.00` : registrationTempInfo?.donation?.amount;
      setContributionAmount(ContributionAmount);
      setContributionCause(contributionCauses[0]?.id);
    }
  }, [registrationTempInfo, paymentData?.donations]);

  const setPreviousFieldTouch = (key, isFormFor) => {
    let allFields = [
      'parentOneTitle',
      'parentOneFirstName',
      'parentOneLastName',
      'parentOneEmail',
      'homeAddress',
      'aptSuite',
      'parentOneContact',
      'studentOneFirstName',
      'studentOneLastName',
      'studentOneDOB',
      'studentOneAcaGrade',
      'studentOneLocation',
      'studentOneClassTimings',
      'studentOneCourse',
      'studentOneAcaYear',
    ];
    if (isFormFor === 'parentTwo' || (isFormFor === 'parentOne' && isParentTwoFilled())) {
      allFields = allFields.concat([
        'parentTwoTitle',
        'parentTwoFirstName',
        'parentTwoLastName',
        'parentTwoEmail',
        'parentTwoContact',
      ]);
    }
    if (isFormFor === 'studentTwo' || (isFormFor === 'studentOne' && isStudentTwoFilled())) {
      allFields = allFields.concat([
        'studentTwoFirstName',
        'studentTwoLastName',
        'studentTwoDOB',
        'studentTwoAcaGrade',
        'studentTwoLocation',
        'studentTwoClassTimings',
        'studentTwoCourse',
        'studentTwoAcaYear',
      ]);
    }
    const index = allFields.indexOf(key);
    if (index > -1) {
      const obj = {};
      for (let i = 0; i <= index; i += 1) {
        const element = allFields[i];
        obj[element] = true;
      }
      formik.setTouched({ ...formik.touched, ...obj }, true);
    }
  };

  const handlePopstate = () => {
    window.history.pushState({}, document.title, window.location.pathname);
  };

  React.useEffect(async () => {
    if (registrationTempInfo && Object.keys(registrationTempInfo).length > 0) {
      const studentOneAcaGrade = gradeOptions.filter((grade) => grade.name === registrationTempInfo.students[0].enrollmentCourse?.academicGrade);
      const res = await getParentPlaceInformation(registrationTempInfo.addresses?.address);
      setHomeAddressInfo({ ...res, add: registrationTempInfo.addresses?.address });
      formik.setFieldValue('parentOneTitle', registrationTempInfo.parent1.userDetails?.title);
      formik.setFieldValue('parentOneFirstName', registrationTempInfo.parent1.user?.firstName);
      formik.setFieldValue('parentOneLastName', registrationTempInfo.parent1.user?.lastName);
      formik.setFieldValue('parentOneEmail', registrationTempInfo.parent1.user?.personalEmail);
      formik.setFieldValue('parentOneContact', registrationTempInfo.parent1.user?.contactNumber);
      formik.setFieldValue('homeAddress', registrationTempInfo.addresses?.address);
      formik.setFieldValue('aptSuite', registrationTempInfo.addresses?.aptSuite);
      formik.setFieldValue('studentOneFirstName', registrationTempInfo.students[0].user?.firstName);
      formik.setFieldValue('studentOneLastName', registrationTempInfo.students[0].user?.lastName);
      formik.setFieldValue('studentOneDOB', registrationTempInfo.students[0].studentDetail?.dateOfBirth);
      formik.setFieldValue('studentOneAcaGrade', studentOneAcaGrade[0].id);
      formik.setFieldValue('studentOneLocation', registrationTempInfo.students[0].enrollmentCourse?.locationId);
      formik.setFieldValue('studentOneClassTimings', registrationTempInfo.students[0].enrollmentCourse?.classTiming);
      formik.setFieldValue('studentOneCourse', registrationTempInfo.students[0].enrollmentCourse?.courseId);
      formik.setFieldValue('studentOneAcaYear', registrationTempInfo.students[0].enrollmentCourse?.academicYear);
      setSelectedYearStudentOne(registrationTempInfo.students[0].enrollmentCourse?.academicYear);
      getEmailAvailabilityService(registrationTempInfo.parent1.user?.personalEmail)
        .then((parentOneResponse) => {
          if (parentOneResponse?.email_found) {
            setParentOneEmailAlreadyExist(t('EMAIL_ALREADY_EXIST'));
          } else {
            setParentOneEmailAlreadyExist(false);
          }
          setPreviousFieldTouch('parentOneEmail', 'parentOne');
        });
      if (registrationTempInfo?.parent2) {
        getEmailAvailabilityService(registrationTempInfo?.parent2.user?.personalEmail)
          .then((parentTwoResponse) => {
            if (parentTwoResponse?.email_found) {
              setParentTwoEmailAlreadyExist(t('EMAIL_ALREADY_EXIST'));
            } else {
              setParentTwoEmailAlreadyExist(false);
            }
            setPreviousFieldTouch('parentTwoEmail', 'parentTwo');
          });
        formik.setFieldValue('parentTwoTitle', registrationTempInfo?.parent2.userDetails?.title);
        formik.setFieldValue('parentTwoFirstName', registrationTempInfo?.parent2.user?.firstName);
        formik.setFieldValue('parentTwoLastName', registrationTempInfo?.parent2.user?.lastName);
        formik.setFieldValue('parentTwoEmail', registrationTempInfo?.parent2.user?.personalEmail);
        formik.setFieldValue('parentTwoContact', registrationTempInfo?.parent2.user?.contactNumber);
        setParentTwoInfo({
          parentTwoTitle: registrationTempInfo?.parent2.userDetails?.title,
          parentTwoEmail: registrationTempInfo?.parent2.user?.personalEmail,
          parentTwoFirstName: registrationTempInfo?.parent2.user?.firstName,
          parentTwoLastName: registrationTempInfo?.parent2.user?.lastName,
          parentTwoContact: registrationTempInfo?.parent2.user?.contactNumber,
        });
        isParentTwoFilled();
      }
      if (registrationTempInfo?.students[1]) {
        const studentTwoAcaGrade = gradeOptions.filter((grade) => grade.name === registrationTempInfo?.students[1].enrollmentCourse?.academicGrade);
        formik.setFieldValue('studentTwoFirstName', registrationTempInfo?.students[1].user?.firstName);
        formik.setFieldValue('studentTwoLastName', registrationTempInfo?.students[1].user?.lastName);
        formik.setFieldValue('studentTwoDOB', registrationTempInfo?.students[1].studentDetail?.dateOfBirth);
        formik.setFieldValue('studentTwoAcaGrade', studentTwoAcaGrade[0].id);
        formik.setFieldValue('studentTwoLocation', registrationTempInfo?.students[1].enrollmentCourse?.locationId);
        formik.setFieldValue('studentTwoClassTimings', registrationTempInfo?.students[1].enrollmentCourse?.classTiming);
        formik.setFieldValue('studentTwoCourse', registrationTempInfo?.students[1].enrollmentCourse?.courseId);
        formik.setFieldValue('studentTwoAcaYear', registrationTempInfo?.students[1].enrollmentCourse?.academicYear);
        setSelectedYearStudentTwo(registrationTempInfo?.students[1].enrollmentCourse?.academicYear);
        setStudentTwoInfo({
          studentTwoFirstName: registrationTempInfo?.students[1].user?.firstName,
          studentTwoLastName: registrationTempInfo?.students[1].user?.lastName,
          studentTwoDOB: registrationTempInfo?.students[1].studentDetail?.dateOfBirth,
          studentTwoAcaGrade: studentTwoAcaGrade[0].id,
          studentTwoLocation: registrationTempInfo?.students[1].enrollmentCourse?.locationId,
          studentTwoClassTimings: registrationTempInfo?.students[1].enrollmentCourse?.classTiming,
          studentTwoCourse: registrationTempInfo?.students[1].enrollmentCourse?.courseId,
          studentTwoAcaYear: registrationTempInfo?.students[1].enrollmentCourse?.academicYear,
        });
        isStudentTwoFilled();
      }
      // Clean url after prefilling information
      window.history.pushState({}, document.title, window.location.pathname);
      window.addEventListener('popstate', handlePopstate);
      window.removeEventListener('popstate', handlePopstate);
    }
  }, [registrationTempInfo]);

  const setStep = () => {
    if (activeStep === 0) {
      return (
        <div>
          <HeaderWithIcons />
          <UserInfo
            {...{
              handleStepper,
              steps,
              activeStep,
              formik,
              setPreviousFieldTouch,
              parentTwoInfo,
              setParentTwoInfo,
              studentTwoInfo,
              setStudentTwoInfo,
              isStudentTwoFilled,
              isParentTwoFilled,
              homeAddressInfo,
              setHomeAddressInfo,
              loading,
              setLoading,
              studentInfoFromStore,
              gradeOptions,
              mapOptions,
              setMapOptions,
              classLevelOptionSTwo,
              setClassLevelOptionSTwo,
              classLevelOptionSOne,
              setClassLevelOptionSOne,
              selectedYearStudentOne,
              setSelectedYearStudentOne,
              selectedYearStudentTwo,
              setSelectedYearStudentTwo,
              missingHomeAddress,
              setMissingHomeAddress,
              getGeneralErrorText,
              registrationTempInfo,
              parentOneEmailAlreadyExist,
              setParentOneEmailAlreadyExist,
              parentTwoEmailAlreadyExist,
              setParentTwoEmailAlreadyExist,
              isSiblingLastNameMismatch,
              setIsSiblingLastNameMismatch,
              isSiblingFirstNameMatch,
              setIsSiblingFirstNameMatch,
              checkEmail,
              setCheckEmail,
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <HeaderWithIcons />
        <PaymentInfo
          {...{
            steps,
            activeStep,
            handleStepper,
            contributionAmount,
            setContributionAmount,
            contributionCause,
            setContributionCause,
            contributionCauseAmount,
            setContributionCauseAmount,
            paymentData,
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Card className={classes.divWrapper}>
        <Box className={classes.logo}>
          <img
            src={logo}
            width={380}
            alt="ManaBadi SiliconAndhra Logo"
            className={classes.headerLogo}
            onClick={() => navigate('/')}
          />
        </Box>
        <Box className={classes.header} mt={2}>
          <RegisterHeader
            activeStep={activeStep}
            steps={steps}
            handleStepper={handleStepper}
          />
        </Box>
        {setStep()}
        <DialogAtom
          isOpen={isOpenError}
          onClose={() => setIsOpenError(false)}
          dialogHeading={t('INVALID_NOINPUT')}
          customClass={classes.paymentDialogAtom}
          content={(
            <Grid container spacing={0} justifyContent="space-between">
              <Grid item xs={12}>
                <div className={classes.lastNameError}>
                  {t('ERROR_VALIDATION')}
                </div>
              </Grid>
              <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>

                <div className={classes.popupBtn}>
                  <ButtonAtom
                    name={t('OK')}
                    onClick={() => {
                      validateAllFields();
                      setIsOpenError(false);
                    }}
                    btntype={Buttons.PRIMARY}
                  />
                </div>
              </Grid>
            </Grid>
          )}
          secHandle={() => setIsOpenError(false)}
        />
      </Card>
    </div>
  );
}

export default RegistrationForm;
