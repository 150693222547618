/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import ClearIcon from '@mui/icons-material/Clear';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import GridView from './gridView';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';
import EnrollbarWrapper from './charts/enrollment-bar/enrollmentBarWrapper';
import { filterYearOptions } from './charts/constants';
import { getCurrentYear } from '../../../utils/methods';
import useSelectedGraphOptions from '../../../custom-hooks/useSelectedGraphOption';
import {
  setEnrollGraphOption,
  setGraphOption,
} from '../../../store/actions/getAdmin';
import useAdmin from '../../../custom-hooks/useAdmin';
import useDataTableParams from '../../../custom-hooks/useDataTableParams';
import useDispatchHook from '../../../custom-hooks/useDispatch';
import Header from './header/header';
import DashboardFilters from '../filters';
import {
  Main, AppBar, DrawerHeader, DrawerWidth,
} from './helper';
import {
  getAllCourses,
  getLocations,
  getTShirts,
  getAcademicGrades,
  getExtraCurricularActivities,
  addFilterView,
  getAllFilter,
  getAllFilterView,
  newAddedFilterView,
} from '../../../store/actions/getStudent';
import {
  getHearAboutUs,
  getVolunteers,
} from '../../../store/actions/getParent';
import constant from '../../../store/constant';
import { getCubejsApi } from '../../../utils/cube';
import userRoles from '../../../constant/userRoles';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorageMethod';

export default function AdminDashboard() {
  const { graphOption, handleGraphOption } = useSelectedGraphOptions();
  const adminData = useAdmin();
  const enrollGraphOption = adminData?.enrollGraphOption;

  const selectedAcademicYear = getCurrentYear();
  const {
    // eslint-disable-next-line no-unused-vars
    nextPage,
    setIsFetching,
    handlePageNumberChange,
  } = useDataTableParams();

  const [loading, setLoading] = useState(false);
  const [isSelectAllCheck, setSelectAllCheck] = useState(false);

  React.useEffect(() => {
    setLoading(true);
  }, []);

  const tempUserRoleLocalStorage = getLocalStorage('tempUserRole');
  const tempUserRole = decryptedUserRoles[tempUserRoleLocalStorage];

  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sortBoth, setSortBoth] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState([]);
  const { state } = useLocation();
  const [filterView, setFilterView] = useState('');
  const [flag, setFlag] = useState(false);
  const [loading1, setLoading1] = useState(true);

  const studentFilters = useSelector(
    (reduxState) => reduxState.getStudent.studentFilters,
  );

  const studentFilterViews = useSelector(
    (reduxState) => reduxState.getStudent.studentFilterViews,
  );

  let filterOptions = useSelector(
    (reduxState) => reduxState.getStudent.filterOptions,
  );

  if (tempUserRole === 'Region Coordinator') {
    filterOptions = filterOptions.filter((filter) => filter.label !== 'Geo Region');
  }

  let selectedFilter = useSelector(
    (reduxState) => reduxState.getStudent.selectedFilter,
  );

  useEffect(() => {
    const currentYear = getLocalStorage('academicYear');
    const studentSearchText = getLocalStorage('studentSearchText');
    const studentSearchTextArray = selectedFilter?.filter((obj) => (obj.filterKey === studentSearchText));
    selectedFilter = selectedFilter?.filter((obj) => (obj.filterKey !== studentSearchText));
    const year = selectedFilter.find((item) => !item.label);
    const checkYear = selectedFilter.find((item) => item.label !== currentYear && item.filterKey === 'academic_year');
    if (year || checkYear || studentSearchTextArray) {
      const filter = [
        {
          label: getCurrentYear().id,
          groupBy: 'Academic Year',
          filterKey: 'academic_year',
        },
        {
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        },
      ];
      dispatch({
        type: constant.SET_SELECTED_FILTER,
        payload: filter,
      });
    }
  }, []);

  const filterChanged = useSelector(
    (reduxState) => reduxState.getStudent.filterChanged,
  );

  const expandedLabels = useSelector(
    (reduxState) => reduxState.getStudent.expandedLabels,
  );
  useDispatchHook([
    getAllFilterView,
    getVolunteers,
    getAllCourses,
    getHearAboutUs,
    getLocations,
    getTShirts,
    getAcademicGrades,
    getExtraCurricularActivities,
  ]);

  useEffect(() => {
    let data = {};
    if (selectedFilter) {
      data = {
        filters: selectedFilter.reduce((obj, item) => {
          if (obj[item.filterKey]) {
            obj[item.filterKey].push(item.label);
          } else {
            obj[item.filterKey] = [item.label];
          }
          return obj;
        }, {}),
      };
      if ((data.filters?.created_date?.length > 0) && (data.filters?.reg_week)) {
        delete data.filters?.reg_week;
      }
      data.filters.status = [data.filters.status[0] === 'true'];
    } else {
      data = {
        filters: {
          academic_year: [selectedAcademicYear.id],
          status: [true],
        },
      };
    }
    dispatch(getAllFilter(undefined, true));
    setFlag(true);
  }, []);

  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  if (!userRole) {
    return null;
  }
  const [locationIds, setLocationIds] = useState([]);
  useEffect(() => {
    dispatch({
      type: constant.SET_USER_ROLE,
      payload: userRole,
    });
    if ((userRole === userRoles.REGION_COORDINATOR)
      || (tempUserRole === userRoles.REGION_COORDINATOR)) {
      const userId = getLocalStorage('userId');
      const tempUserId = getLocalStorage('tempUserId');
      const query = {
        dimensions: ['RegionCoordinator.regionId', 'Location.id'],
        order: { 'RegionCoordinator.createdAt': 'asc' },
        filters: [
          {
            member: 'RegionCoordinator.deletedAt',
            operator: 'notSet',
          },
          {
            member: 'RegionCoordinator.userId',
            operator: 'equals',
            values: tempUserId ? [tempUserId] : [userId],
          },
        ],
      };
      getCubejsApi()
        .load(query)
        .then((resultSet) => {
          const result = resultSet.rawData();
          if (result && result.length > 0) {
            const data = result.map((item) => item['Location.id']);
            setLocationIds(data);
          }
        });
    }
  }, [userRole, tempUserRole]);

  useEffect(() => {
    const data = {
      filters: selectedFilter.reduce((obj, item) => {
        if (item.filterKey === 'academic_year') {
          localStorage.setItem('academicYear', item.label);
        }
        if (obj[item.filterKey]) {
          obj[item.filterKey].push(item.label);
        } else {
          obj[item.filterKey] = [item.label];
        }
        return obj;
      }, {}),
    };
    if ((data.filters?.created_date?.length > 0) && (data.filters?.reg_week)) {
      delete data.filters?.reg_week;
    }
    data.filters.status = [data.filters.status[0] === 'true'];
    dispatch({
      type: constant.SET_LOADING,
      payload: true,
    });
    if (flag) {
      dispatch(getAllFilter(data, false, setLoading1));
    }
  }, [selectedFilter, flag]);

  const option = graphOption;
  useEffect(() => {
    if (option) {
      dispatch(setGraphOption(option));
    }
  }, [graphOption, adminData?.enrollGraphOption]);

  const setEnrollBarGraphOption = (op) => {
    dispatch(setEnrollGraphOption(op));
  };
  const [open, setOpen] = useState(false);
  const handleDrawer = () => {
    dispatch({
      type: constant.SET_FILTER_STATUS,
      payload: !open,
    });
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    dispatch({
      type: constant.SET_FILTER_STATUS,
      payload: false,
    });
    setOpen(false);
  };

  const onSetFilterOptions = (fil, refetchFilteredStudent) => {
    dispatch({
      type: constant.SET_FILTER_OPTIONS,
      payload: fil,
    });
    const selectedFil = [];
    fil?.forEach((fi) => {
      fi?.options?.forEach((fiOpt) => {
        if (fiOpt.checked) {
          selectedFil.push({
            label: fiOpt?.label?.toString(),
            groupBy: fi.label.toString(),
            filterKey: fi.filter,
          });
        }
      });
    });
    const acedemicYears = selectedFil.filter(
      (item) => item.filterKey === 'academic_year',
    );
    const academicYear = acedemicYears[acedemicYears.length - 1];
    const newOptions = [...selectedFil];
    const academicYearIndex = selectedFil?.findIndex(
      (i) => i?.filterKey === 'academic_year',
    );
    if (adminData?.graphOption === 'acrossyear' && (academicYear?.acrossYear === false)) {
      academicYear.acrossYear = true;
    } else if (academicYear) {
      academicYear.acrossYear = false;
    }
    const statusData = selectedFil.filter(
      (item) => item.filterKey === 'status',
    );
    const statusIndex = selectedFil.findIndex(
      (item) => item.filterKey === 'status',
    );
    const status = statusData.length ? statusData[statusData.length - 1] : null;
    if (academicYearIndex !== -1) {
      newOptions[academicYearIndex] = academicYear;
    }
    if (status && statusIndex !== -1) {
      newOptions[statusIndex] = status;
    }
  };

  useEffect(() => {
    if (filterView !== '') {
      return;
    }
    if (selectedFilter.length <= 0) {
      dispatch({
        type: constant.SET_SELECTED_FILTER,
        payload: [
          {
            label: selectedAcademicYear.id,
            groupBy: 'Academic Year',
            filterKey: 'academic_year',
          },
          {
            label: 'true',
            groupBy: 'Status',
            filterKey: 'status',
          },
        ],
      });
    }
  }, [filterView]);
  const [inputCleared, setInputStatus] = useState(false);

  useEffect(() => {
    if (filterOptions.length === 0) {
      return;
    }
    const filterOption = filterOptions?.find(
      (i) => i.key === 'academic_year',
    )?.options;
    const options = filterOption?.map((obj) => {
      return { ...obj, checked: true };
    });
    const newOptions = filterOptions?.filter((i) => i?.key === 'academic_year');
    const opdata = newOptions?.map((i) => {
      return {
        ...i,
        options,
      };
    });

    const isActiveOption = filterOptions?.find(
      (i) => i.key === 'isActive',
    )?.options;

    const isActiveOptions = isActiveOption?.map((obj) => {
      if (obj.label === true) {
        return { ...obj, checked: true };
      }
      return { ...obj, checked: false };
    });
    const newisActiveOptions = filterOptions?.filter(
      (i) => i?.key === 'isActive',
    );
    const statusData = newisActiveOptions?.map((i) => {
      return {
        ...i,
        isActiveOptions,
      };
    });

    let uncheckedOptionregWeek = filterOptions?.find(
      (i) => i.key === 'regWeek',
    )?.options;

    uncheckedOptionregWeek = uncheckedOptionregWeek.map((opt) => {
      if (adminData.graphOption === 'today') {
        opt.disabled = true;
      } else {
        opt.disabled = false;
      }
      return opt;
    });

    let newRegWeek = filterOptions?.find((i) => i.key === 'regWeek');
    newRegWeek = {
      ...newRegWeek,
      options: uncheckedOptionregWeek,
    };

    let newCreatedDate = filterOptions?.find((i) => i.key === 'createdAt');
    newCreatedDate = {
      ...newCreatedDate,
      options: uncheckedOptionregWeek,
    };

    let uncheckedOptionCreatedAt = filterOptions?.find(
      (i) => i.key === 'createdAt',
    )?.options;

    uncheckedOptionCreatedAt = uncheckedOptionCreatedAt.map((opt) => {
      opt.checked = false;
      if (adminData.graphOption === 'today') {
        opt.disabled = true;
      } else {
        opt.disabled = false;
      }
      return opt;
    });

    let newCreateDate = filterOptions?.find((i) => i.key === 'createdAt');
    newCreateDate = {
      ...newCreateDate,
      options: uncheckedOptionCreatedAt,
    };

    const restData = filterOptions?.filter(
      (i) => !['academic_year', 'isActive'].includes(i.key),
    );
    restData.unshift(statusData[0]);
    restData.unshift(opdata[0]);
    onSetFilterOptions(restData, true);
  }, [adminData.graphOption]);

  const isInSelectedFilter = (label, filterKey) => {
    const isPresent = selectedFilter.find((item) => (item.label?.toString() === label?.toString())
      && (item.filterKey?.toString() === filterKey?.toString()));
    return isPresent;
  };
  const initialFilterPayload = (isRefresh = false) => {
    if (!isRefresh) {
      const regWeek = selectedFilter.filter((item) => item.filterKey === 'reg_week');
      const createdDate = selectedFilter.filter((item) => item.filterKey === 'created_date');
      const fil = state?.previousFilters
        || studentFilters?.map((filterOpt) => {
          const options = filterOpt?.options?.map((op) => {
            let checked = !!(
              (filterOpt.filter === 'academic_year'
                && op[filterOpt.key] === selectedAcademicYear.id)
              || (filterOpt.filter === 'enrolled_status'
                && op[filterOpt.key] === true)
              || (filterOpt.filter === 'course_status'
                && op[filterOpt.key] === true)
            );
            if (filterOpt.filter === 'academic_year') {
              checked = true;
            } else {
              let keyValue = op[filterOpt.key]?.toString();
              if (!keyValue) {
                keyValue = op?.name?.toString();
              }
              checked = isInSelectedFilter(op[filterOpt.key], filterOpt.filter) ? true : checked;
            }
            return {
              label: (op[filterOpt.key] === true || op[filterOpt.key] === false || op[filterOpt.key]) ? op[filterOpt.key] : op.name,
              count: op.count,
              checked,
              value: (op[filterOpt.key] === true || op[filterOpt.key] === false || op[filterOpt.key]) ? op[filterOpt.key] : op.name,
              filterKey: filterOpt.filter,
            };
          });
          return {
            ...filterOpt,
            expanded: expandedLabels.includes(filterOpt.label),
            options,
            value: filterOpt.label,
          };
        });

      const newIndex = fil?.findIndex((o) => o.key === 'isActive');
      const optionsData = fil?.find((o) => o.key === 'isActive')?.options;

      if (optionsData?.length) {
        const data = optionsData?.map((i) => {
          if (i.label === true) {
            return { ...i, checked: true };
          }
          return i;
        });
        fil[newIndex].options = data;
      }
      setInputStatus(false);

      dispatch({
        type: constant.SET_FILTER_OPTIONS,
        payload: fil,
      });
      if (fil?.length > 0) {
        onSetFilterOptions(fil, true);
      }
    }
    if (isRefresh) {
      if (filterOptions?.length > 0) {
        onSetFilterOptions(filterOptions, true);
      }
    }
  };

  React.useEffect(() => {
    initialFilterPayload();
  }, [studentFilters]);

  const onAddView = (title, filters, clearTitleAndError, setTitleError) => {
    const queryArray = [];
    filters.forEach((fil) => {
      fil.options.forEach((filOp) => {
        if (filOp.checked) {
          queryArray.push({
            label: filOp.label.toString(),
            groupBy: fil.label.toString(),
            filterKey: fil.filter,
          });
        }
      });
    });
    dispatch(
      addFilterView(
        {
          queryData: JSON.stringify(queryArray),
          name: title,
        },
        clearTitleAndError,
        setTitleError,
      ),
    );
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div>
      <Grid
        style={{
          minHeight:
            option === 'none'
              ? window.innerHeight - 100
              : window.innerHeight + 100,
        }}
        className={classes.mainContainer}
      >
        <Grid item xs={12} lg={12} className={classes.filterSection}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} />
            <Drawer
              sx={{
                width: DrawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: DrawerWidth,
                  boxSizing: 'border-box',
                  '@media (max-width:600px)': {
                    width: '200px !important',
                  },
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <DrawerHeader>
                <div>
                  <TuneOutlinedIcon className={classes.filterIcon} />
                  <span
                    style={{
                      verticalAlign: 'top',
                      marginLeft: '0.8vw',
                    }}
                  >
                    {t('SEARCH_BY_FILTERS')}
                  </span>
                  <ClearIcon
                    onClick={handleDrawerClose}
                    style={{
                      marginLeft: '2vw',
                    }}
                    className={classes.clearIcon}
                  />
                </div>
              </DrawerHeader>
              <DashboardFilters
                filterOptions={filterOptions}
                setFilterOptions={onSetFilterOptions}
                setFilterView={setFilterView}
                onAddView={onAddView}
                setSortBoth={setSortBoth}
                sortBoth={sortBoth}
                setSelected={setSelected}
                setLoading={setLoading}
                selected={selected}
                setSelectAllCheck={setSelectAllCheck}
              />
            </Drawer>
            <Main open={open} className={classes.mainSection}>
              <Header
                setSelectAllCheck={setSelectAllCheck}
                newAddedFilterView={newAddedFilterView}
                {...{
                  handleDrawer,
                  setInputStatus,
                  selectedFilter,
                  filterOptions,
                  studentFilterViews,
                  filterView,
                  setFilterView,
                  setSearchText,
                  searchText,
                  setSortBoth,
                  sortBoth,
                  setSelected,
                  graphOption,
                  handleGraphOption,
                  filterYearOptions,
                  open,
                  isSelectAllCheck,
                }}
              />
              <Box>
                <EnrollbarWrapper
                  {...{
                    enrollGraphOption,
                    selectedFilter,
                    locationIds,
                  }}
                  setFilterOptions={onSetFilterOptions}
                  filterOptions={filterOptions}
                  setEnrollGraphOption={setEnrollBarGraphOption}
                />
              </Box>
              <Box mt={4}>
                <GridView
                  selectedOption={graphOption}
                  locationIds={locationIds}
                  selectedFilter={selectedFilter}
                  loading1={loading1}
                />
              </Box>
            </Main>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
