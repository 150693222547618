import Constant from '../constant';

const initialState = {
  users: [],
  userRoles: [],
  userFilters: [],
  userEmails: [],
  totalCount: 0,
  selectedFilter: [],
  allUserFilters: [],
  expandedLabels: [],
  allExpanded: false,
};

const auth = (state = initialState, action = {}) => {
  switch (action.type) {
    case Constant.GET_ALL_USER:
      return {
        ...state,
        users: action.payload,
      };
    case Constant.SET_USERS_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      };
    case Constant.GET_ALL_EMAIL_USERS:
      return {
        ...state,
        userEmails: action.payload,
      };
    case Constant.TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case Constant.GET_PAGINATED_USERS:
      return {
        ...state,
        users: state.users?.concat(action.payload),
      };
    case Constant.GET_ALL_USER_FILTERS:
      return {
        ...state,
        allUserFilters: action.payload,
      };
    case Constant.GET_USER_FILTERS:
      return {
        ...state,
        userFilters: action.payload,
      };
    case Constant.GET_ALL_USER_ROLES:
      return {
        ...state,
        userRoles: action.payload,
      };
    case Constant.SET_USER_EXPANDED_LABELS:
      return {
        ...state,
        expandedLabels: action.payload,
      };
    case Constant.SET_ALL_USER_EXPAND:
      return {
        ...state,
        allExpanded: action.payload,
      };
    default:
      return state;
  }
};

export default auth;
