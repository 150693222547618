import React from 'react';
import Chart from 'react-google-charts';
import { Grid, Typography } from '@mui/material';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { useTranslation } from 'react-i18next';
import ReactDependentScript from '../../../../utils/ReactDependentScript';
import Loader from '../../../../components/atoms/loader';
import useStyles from '../../../../custom-hooks/useStyles';
import style from '../style';

function GoogleCalendarChart({ data }) {
  const { t } = useTranslation();
  if (!data) {
    return null;
  }
  const maxCount = Math.max(...data.map((o) => o.count));
  const newData = data?.map((i) => {
    let { date } = i;
    if (!date) {
      return null;
    }
    date = date.split('-');
    const monthArrangement = Number(date[1]) - 1;
    return [new Date(date[0], monthArrangement.toString(), date[2]), i?.count];
  }).filter((value) => !!value);
  const classes = useStyles(style)();

  const formattedData = [[{ type: 'date', id: 'Date' },
    { type: 'number', id: 'count' }]].concat(newData);
  const uniqueYears = [
    ...new Map(data.map((item) => [item.year, item])).values(),
  ];

  const getHeight = () => {
    if (uniqueYears?.length === 4) {
      return 800;
    }
    if (uniqueYears?.length === 3) {
      return 630;
    }
    return 400;
  };

  let options = {
    calendar: {
      cellSize: 20,
    },
  };
  if (maxCount === 0) {
    const colorAxis = {
      minValue: 0, maxValue: 1,
    };
    options = {
      ...options,
      colorAxis,
    };
  } else {
    const colorAxis = { minValue: 0, maxValue: 10, colors: ['white', 'rgb(30, 25, 182)'] };
    options = {
      ...options,
      colorAxis,
    };
  }
  return (
    <div>
      {formattedData && formattedData?.length
        ? (
          <ReactDependentScript
            loadingComponent={<div>loading...</div>}
            scripts={['https://maps.googleapis.com/maps/api/js?key=AIzaSyAU48ne6Tr40XKCK767OibE5dQLs9nuz0U&libraries=places']}
          >
            <Chart
              width={1200}
              height={getHeight()}
              chartType="Calendar"
              loader={<Loader message="" />}
              data={formattedData}
              options={options}
            />
          </ReactDependentScript>
        ) : (
          <Grid className={classes.noData}>
            <DoNotDisturbIcon />
            <Grid>
              <Typography variant="subtitle2" color="text.secondary">
                {t('NO_DATA')}
              </Typography>
            </Grid>
          </Grid>
        )}

    </div>

  );
}

export default GoogleCalendarChart;
