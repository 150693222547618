/* eslint-disable */
import React, { useState, memo, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import MainViewHeader from '../main-view-header/mainViewHeader';
import MainView from '../main-view/mainView';
import { colors } from '../../../../theme';
import { StudentProvider } from '../../../../contexts/StudentProvider';
import { setLocalStorage } from '../../../../utils/localStorageMethod';
import constant from '../../../../store/constant';

const useStyles = makeStyles(() => ({
  errorText: {
    color: colors.errorText,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
  },
}));
const initalSortOder = {
  studentN: 'both',
  parentN: 'both',
  centerI: 'both',
  classL: 'both',
  sectionN: 'both',
  regDateN: 'both',
};
function MainViewWithHeader(props) {
  const classes = useStyles();
  const view = useSelector((state) => state.getStudent.view);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [isSelectedCheckbox, setIsSelectedCheckbox] = useState([]);
  const [sortOrder, setSortOrder] = useState(initalSortOder);
  const onCheckBoxSelection = (e) => {
    if (view !== 'cardView') {
      const idStudents = e.map((item) => item.userId);
      setIsSelectedCheckbox(idStudents);
    } else {
      setIsSelectedCheckbox(e);
    }
  };
  const [sortModel, setSortModel] = useState([
    {
      field: 'regDate',
      sort: 'desc',
    },
  ]);

  useEffect(() => {
  }, [isSelectedCheckbox]);
  const handleView = (event, myView) => {
    if (myView !== null) {
      dispatch({
        type: constant.SET_VIEW,
        payload: myView,
      });
    }
  };
  const {
    setSelectedFilter,
    setFilterOptions,
    filterByYearDropDown,
    filterOptions,
    onUpdateParentClick,
    paymentDialogOpen,
    editExamDetailsOpen,
    onClickMenu,
    onClickCancelEnrollMenu,
    onClickEditExamDetails,
    onEditMenuClick,
    setEditStudentProfile,
    setSwapCourseOrLocation,
    setSelectedRecord,
    setIsRecoveryDialogOpen,
    setCancelEnroll,
    setIsEditExam,
    isFetching,
    hasMore,
    pageNumber,
    pageLimit,
    nextPage,
    setIsFetching,
    localFilterPayload,
    selectedFilter,
    setOpenMarksPopUp,
    setDataAssignScore,
    setAssignLoading,
    setLoadingSpinner,
    loadingSpinner,
    setSortBoth,
    sortBoth,
    setSelected,
    selected,
    studentLoading,
    isSelectAllCheck,
    setSelectAllCheck,
    selectedStudentIds,
    setSelectedStudentIds,
    refSelectedStudentIds,
    filterView,
    filterYear,
    setFilterView,
    setFilterYear,
    newAddedFilterView,
    setRepeatStatusOpen,
    setUnselectedStudents,
    unselectedStudents,
    refUnSelectedStudentIds,
  } = props;

  const studentFilterViews = useSelector(
    (state) => state.getStudent.studentFilterViews,
  );
  const getFilterViewOptions = () => {
    let opt = [
      {
        name: ' ',
        id: 'year',
      },
    ];
    studentFilterViews.forEach((filView) => {
      opt.push({
        name: filView.name,
        id: filView.name,
        filterId: filView.id,
      });
    });
    opt = opt.length === 1 ? [] : opt;
    return opt;
  };
  const onSortModelChange = (model) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };

  const refreshSort = () => {
    setSortOrder({
      studentN: 'both',
      parentN: 'both',
      centerI: 'both',
      classL: 'both',
      sectionN: 'both',
      regDateN: 'both',
    });
    setLocalStorage('adminBulkRecipientList', []);
    setSortBoth(true);
    refSelectedStudentIds.current = [];
    refUnSelectedStudentIds.current = [];
    setSelected([]);
    setSelectedStudentIds([]);
    setIsSelectedCheckbox([]);
    setSelectAllCheck(false);
    setUnselectedStudents([]);
    setTimeout(() => {
      setSortBoth(false);
    }, 100);
  };

  useEffect(() => {
    refSelectedStudentIds.current = selectedStudentIds;
    refUnSelectedStudentIds.current = [];
  }, [isSelectAllCheck]);

  useEffect(() => {
    setSelectedStudentIds([]);
    setIsSelectedCheckbox([]);
    setSelectAllCheck(false);
    setUnselectedStudents([]);
  }, [filterOptions, view]);

  return (
    <div>
      <StudentProvider value={{ onCheckboxChange: onCheckBoxSelection }}>
        <MainViewHeader {...{
          refreshSort,
          handleView,
          getFilterViewOptions,
          studentFilterViews,
          view,
          setSelectedFilter,
          setFilterOptions,
          filterByYearDropDown,
          filterOptions,
          setError,
          selectedFilter,
          isSelectedCheckbox,
          filterView,
          filterYear,
          setFilterView,
          setFilterYear,
          newAddedFilterView,
          localFilterPayload,
          isSelectAllCheck,
          refUnSelectedStudentIds,
        }}
        />
        <Grid>
          <span className={classes.errorText}>{error}</span>
        </Grid>
        <Grid>
          <MainView {...{
            isSelectAllCheck,
            setSelectAllCheck,
            studentLoading,
            view,
            onUpdateParentClick,
            paymentDialogOpen,
            editExamDetailsOpen,
            onClickMenu,
            onClickCancelEnrollMenu,
            onClickEditExamDetails,
            onEditMenuClick,
            setCancelEnroll,
            setIsEditExam,
            sortModel,
            onSortModelChange,
            setEditStudentProfile,
            setSwapCourseOrLocation,
            setSelectedRecord,
            setIsRecoveryDialogOpen,
            isFetching,
            hasMore,
            pageNumber,
            pageLimit,
            nextPage,
            setIsFetching,
            localFilterPayload,
            setError,
            selectedFilter,
            setOpenMarksPopUp,
            setDataAssignScore,
            setAssignLoading,
            setLoadingSpinner,
            loadingSpinner,
            // to pass filters back
            filterOptions,
            setSortOrder,
            sortOrder,
            sortBoth,
            setSortBoth,
            setSelected,
            selected,
            selectedStudentIds,
            setSelectedStudentIds,
            refSelectedStudentIds,
            setRepeatStatusOpen,
            setUnselectedStudents,
            unselectedStudents,
            refUnSelectedStudentIds
          }}
          />
        </Grid>
      </StudentProvider>
    </div>
  );
}
export default memo(MainViewWithHeader);
