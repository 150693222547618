/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import DownLeftRightIcon from '../../../../assets/svg/downLeftRight';
import { GroupedAutoComplete } from '../../../../components/atoms';
import { useStyles } from './style';
import { getCurrentYear } from '../../../../utils/methods';
import {
  setGraphOption,
} from '../../../../store/actions/getAdmin';
import constant from '../../../../store/constant';

function Header(props) {
  const {
    handleDrawer,
    selectedFilter,
    setSelectedFilter,
    filterOptions,
    setSearchText,
    searchText,
    setSortBoth,
    setSelected,
    open,
    setSelectAllCheck,
    setInputStatus,
    setFilterChanged,
    handleGraphOption,
    setFilterYear,
    setFilterView,
    filterByYearDropDown,
    inputRef,
    setUnselectedStudents,
    unselectedStudents,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const allFilters = useSelector(
    (reduxState) => reduxState.getStudent.allFilters,
  );
  const selectedAcademicYear = getCurrentYear();
  const clearValues = () => {
    dispatch({
      type: constant.SET_SELECTED_FILTER,
      payload: [
        {
          label: selectedAcademicYear.id,
          groupBy: 'Academic Year',
          filterKey: 'academic_year',
        },
        {
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        },
      ],
    });
    if (setFilterView) setFilterView('');
    if (filterByYearDropDown) filterByYearDropDown('');
    if (setFilterYear) setFilterYear('');
    dispatch(setGraphOption('year'));
    if (handleGraphOption) handleGraphOption({ target: { value: 'year' } });
    setSearchText('');
    localStorage.setItem('studentSearchText', '');
  };
  const onGroupAutoCompleteSelection = (e, val) => {
    setSelectAllCheck(false);
    setUnselectedStudents([]);
    setSortBoth(true);
    setSelected([]);
    setTimeout(() => {
      setSortBoth(false);
    }, 1000);
    const isExist = val?.filter((obj) => (obj.filterKey === 'academic_year'));
    const searchFilter = selectedFilter?.filter((obj) => !val.includes(obj));
    if (searchFilter?.length > 0) {
      const isSearchText = searchFilter?.filter((obj) => (obj.filterKey === searchText));
      if (isSearchText?.length > 0) {
        setSearchText('');
        localStorage.setItem('studentSearchText', '');
      }
    }
    if (isExist.length > 0) {
      // todo:(1) We could isolate this logic and make the if statement to calculate the year only and mix it with the year calculation logic in the else statement
      const acedemicYears = val.filter(
        (item) => item.filterKey === 'academic_year',
      );
      const academicYear = acedemicYears[acedemicYears.length - 1];
      const newOptions = val?.filter(
        (i) => i?.filterKey !== 'academic_year' && i?.filterKey !== 'status',
      );
      // (1) We could isolate this logic and make the if statement to calculate the year only and mix it with the year calculation logic in the else statement
      const statusData = val.filter((item) => item.filterKey === 'status');
      const status = statusData.length ? statusData[statusData.length - 1] : null;
      // We want to protect status and year (set them at the begining so they are the last ones to be deleted on a backspace keystroke, thus allowing other filters to be deleted/unchecked)
      if (status) {
        newOptions.unshift(status);
      } else {
        newOptions.push({
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        });
      }
      newOptions.unshift(academicYear);
      dispatch({
        type: constant.SET_SELECTED_FILTER,
        payload: newOptions,
      });
    }
  };
  const studentFilters = useSelector(
    (state) => state.getStudent.studentFilters,
  );
  const getGroupOptions = () => {
    const studentFil = [...allFilters];
    const options = [];
    const year = parseInt(localStorage.getItem('academicYear').split('-')[0], 10);
    studentFil?.forEach((stu) => {
      stu?.options?.forEach((stuOpt) => {
        const key = stu?.key;
        if (key !== 'isAttended' && key !== 'isAbsent') {
          options.push({
            label: typeof stuOpt[key] === 'string' ? stuOpt[key] : stuOpt[key]?.toString(),
            groupBy: stu.label?.toString(),
            filterKey: stu.filter,
          });
        } else {
          const isAfter2023 = year >= 2023;
          const isQuarter = ['Quarter 1', 'Quarter 2', 'Quarter 3'].includes(stuOpt[key]);

          if ((isAfter2023 && !isQuarter) || (!isAfter2023 && isQuarter)) {
            options.push({
              label: typeof stuOpt[key] === 'string' ? stuOpt[key] : stuOpt[key]?.toString(),
              groupBy: stu.label?.toString(),
              filterKey: stu.filter,
            });
          }
        }
      });
    });
    return options;
  };

  const handleInputChangeDebounced = debounce((newInputValue, reason) => {
    if (reason === 'clear') {
      clearValues();
      return;
    }
    if (newInputValue.length > 2) {
      const trimmedInputValue = newInputValue.replace(/^[\W_]+|[\W_]+$/g, '');
      const searchTextWithoutExtraSpaces = trimmedInputValue.replace(/\s{2,}/g, ' ');
      setSearchText(searchTextWithoutExtraSpaces);
      localStorage.setItem('studentSearchText', searchTextWithoutExtraSpaces);
    } else {
      const newOptions = selectedFilter?.filter((obj) => (obj.filterKey !== searchText));
      if (newOptions.length !== selectedFilter.length) {
        dispatch({
          type: constant.SET_SELECTED_FILTER,
          payload: newOptions,
        });
      }
      setSearchText('');
      localStorage.setItem('studentSearchText', '');
    }
  }, 1000);

  const clearSearchText = () => {
    setSearchText('');
    localStorage.setItem('studentSearchText', '');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', clearSearchText);
    return () => {
      window.removeEventListener('beforeunload', clearSearchText);
    };
  }, []);

  return (
    <div>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('STUDENTS')}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.filterButton}>
        <Grid container>
          <Grid item xs={12} md={2} lg={1.5}>
            <Button variant="filter" onClick={handleDrawer} className={classes.filterBtn}>
              {!open ? <DownLeftRightIcon />

                : (
                  <div className="DownLeftIcon">
                    <ZoomOutMapOutlinedIcon />
                  </div>
                )}
              <TuneOutlinedIcon className={classes.filterIcon} />
              filter
            </Button>
          </Grid>
          <Grid item xs={12} md={10} lg={10.5} className={classes.ChipSection}>
            <GroupedAutoComplete
              isStudentFilter
              options={getGroupOptions()}
              onSelection={onGroupAutoCompleteSelection}
              value={selectedFilter}
              label={t('SEARCH')}
              placeholder={(localStorage.getItem('studentSearchText') !== '' && localStorage.getItem('studentSearchText') !== null) ? `Last Text Searched: ${localStorage.getItem('studentSearchText')} ` : t('SEARCH')}
              onInputChange={(e, newInputValue, reason) => {
                handleInputChangeDebounced(newInputValue, reason);
              }}
              enterNotAllowed
              allowUserInput
              inputRef={inputRef}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid />
    </div>
  );
}

export default Header;
