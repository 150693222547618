import React from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
} from 'recharts';

export default function Chart({
  chartData, renderBars, renderLegend, renderTooltip, xAxisDataKey, tick, yAxisLabel,
}) {
  return (
    <ResponsiveContainer>
      <BarChart
        data={chartData}
      >
        <CartesianGrid vertical={false} />
        <XAxis axisLine={{ stroke: '#2965CD' }} dataKey={xAxisDataKey} tick={tick} />
        <YAxis
          axisLine={{ stroke: '#2965CD' }}
          tickLine={{ fill: '#2965CD', stroke: '#2965CD', strokeWidth: 2 }}
          allowDecimals={false}

        >
          <Label
            value={yAxisLabel}
            fontWeight="bold"
            offset={15}
            position="insideLeft"
            angle={-90}
            dy={30}
          />
        </YAxis>
        {renderTooltip && renderTooltip()}
        {renderLegend && renderLegend()}
        {renderBars()}
      </BarChart>
    </ResponsiveContainer>
  );
}
