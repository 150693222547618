/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import 'react-phone-number-input/style.css';
import FormControl from '@mui/material/FormControl';
import PhoneInput from 'react-phone-number-input';
import clsx from 'clsx';
import { InputLabel } from '@mui/material';
import useStyles from '../../../custom-hooks/useStyles';
import style from './style';

function Phone({
  onChange, value, label, id, error, onBlur, className, defaultCountry, required, onCountryChange, showLabel, shrink, customClass = '', disabled, inputRef, inputProps,
}) {
  const classes = useStyles(style)();
  return (
    <div>
      <FormControl
        className={!error ? `${classes[customClass]} ${className || classes.formControl}` : `${className || classes.formControl}`}
        sx={{
          m: {
            lg: 1,
          },
          width: 200,
        }}
        style={{ marginBottom: '-3px', marginTop: '-1px' }}
      >
        {(showLabel)
          && <InputLabel shrink={value && shrink} id={id}>{label + (required ? '' : '')}</InputLabel>}
        <PhoneInput
          containerClass={classes.borderClass}
          label={label}
          ref={inputRef}
          usenationalformatfordefaultcountryvalue
          international
          countryCallingCodeEditable={false}
          maxLength={15}
          placeholder={label}
          value={value}
          countries={['US', 'CA']}
          onChange={onChange}
          onCountryChange={onCountryChange}
          onBlur={onBlur}
          numberInputProps={inputProps}
          className={clsx(
            customClass === '' || typeof customClass === 'undefined' ? classes.phoneInput : '',
            error ? `${classes.errorBorder} ${classes[customClass]}` : '',
          )}
          data-testid={id}
          defaultCountry={defaultCountry || 'US'}
          addInternationalOption
          disabled={disabled}
        />
      </FormControl>
    </div>
  );
}

export default Phone;
