/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Grid, Box,
  Typography,
  CardActionArea,
  Tooltip,
} from '@mui/material';
import Card from '@mui/material/Card';
import ReactPlayer from 'react-player/lazy';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import CardContent from '@mui/material/CardContent';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import mbPng from '../../../../assets/images/mb_png_icon.png';
import defaultIcon from '../../../../assets/images/defaultIcon.png';
import mbPdf from '../../../../assets/images/mb_pdf_icon.png';
import mbTxt from '../../../../assets/images/mb_txt_icon.png';
import mbDoc from '../../../../assets/images/mb_doc_icon.png';
import mbVideo from '../../../../assets/images/mb_video_icon.png';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from '../style';
import { Buttons } from '../../../../constant';
import {
  getFolderDetails,
  getStudentResourceCourse,
} from '../../../../store/actions/getResources';
import ButtonAtom from '../../../../components/atoms/button';

function ShowFiles(props) {
  const {
    fileType,
    fileUrl,
    classes,
  } = props;
  let aUrlSplitp = '';
  let valueUrlp = '';
  let aUrlSplitP = '';
  let valueUrlP = '';

  switch (fileType) {
    case 'png':
      return <img src={fileUrl} alt={fileUrl} />;
    case 'jpg':
      return <img src={fileUrl} alt={fileUrl} />;
    case 'jpeg':
      return <img src={fileUrl} alt={fileUrl} />;
    case 'ppt':
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`} height={810} width={600} title={fileUrl} /></div>;
    case 'pdf':
      aUrlSplitp = fileUrl?.split('/')?.splice(3);
      valueUrlp = aUrlSplitp?.join('/');
      return (
        <div className={classes.pdfFileS}>
          <iframe title={fileUrl} className={classes.pdfFileFrame} src={fileUrl + "#toolbar=0"} frameBorder="0" scrolling="no" seamless="" />
        </div>
      );
    case 'Pdf':
      aUrlSplitP = fileUrl?.split('/')?.splice(3);
      valueUrlP = aUrlSplitP?.join('/');
      return <div className={classes.pdfFileS}><iframe title={fileUrl} className={classes.pdfFileFrame} src={fileUrl + "#toolbar=0"} /></div>;
    case 'doc':
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}&wdOrigin=BROWSELINK`} height={810} width={600} title={fileUrl} /></div>;
    case 'docx':
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}&wdOrigin=BROWSELINK`} height={810} width={600} title={fileUrl} /></div>;
    case 'mp4':
      return <ReactPlayer url={fileUrl} playing="true" controls="true" playsInline="true" aspectRatio="auto" width="90%" height="90%" />;
    case 'mov':
      return <ReactPlayer url={fileUrl} playing="true" controls="true" playsInline="true" aspectRatio="auto" width="90%" height="90%" />;
    default:
      return <div className={classes.fileEmbed}><iframe className={classes.embedStyle} src={fileUrl} height={810} width={600} title={fileUrl} /></div>;
  }
}
export default function ResourcesData({
  tableDataResources,
  selectedResource,
  courseSelectedvalue,
  pageNext,
  pagenum,
  setPagenum,
  setIsFetching,
  isFetching,
  setLoading,
  folderName,
  setFolderName,
}) {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const [resources, setResources] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [fileType, setFileType] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState(false);
  const [isDownload, setIsDownload] = React.useState(false);
  const [resourceName, setResourceName] = React.useState('');
  const reduxTotalCount = useSelector((state) => state?.getLocationCoordinator.totalCount);

  useEffect(() => {
    setResources(tableDataResources);
  }, [tableDataResources]);

  const dispatch = useDispatch();
  const handleClose = () => {
    setIsDownload(false);
    setOpen(false);
    setFileUrl(null);
  };
  const getFileType = (file, fileResourceName) => {
    const fileNameSplit = fileResourceName?.split('.')[1];
    if (fileNameSplit === undefined) {
      switch (file?.split('/')?.[1]) {
        case 'png':
          return file.split('/')[1];
        case 'jpg':
          return file.split('/')[1];
        case 'jpeg':
          return file.split('/')[1];
        case 'pdf':
          return file.split('/')[1];
        case 'Pdf':
          return file.split('/')[1];
        case 'octet-stream':
          return file.split('/')[1];
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
          return file.split('/')[1];
        case 'plain':
          return file.split('/')[1];
        case 'msword':
          return file.split('/')[1];
        case 'txt':
          return file.split('/')[1];
        case 'webm':
          return file.split('/')[1];
        case 'webp':
          return file.split('/')[1];
        case 'doc':
          return file.split('/')[1];
        case 'docx':
          return file.split('/')[1];
        case 'ppt':
          return file.split('/')[1];
        case 'mp4':
          return file.split('/')[1];
        case 'mp3':
          return file.split('/')[1];
        case 'Mp3':
          return file.split('/')[1];
        case 'mov':
          return file.split('/')[1];
        default:
          return 'doc';
      }
    } else {
      switch (fileNameSplit.split(' ')[0]) {
        case 'png':
          return fileNameSplit.split(' ')[0];
        case 'jpg':
          return fileNameSplit.split(' ')[0];
        case 'jpeg':
          return fileNameSplit.split(' ')[0];
        case 'pdf':
          return fileNameSplit.split(' ')[0];
        case 'Pdf':
          return fileNameSplit.split(' ')[0];
        case 'octet-stream':
          return fileNameSplit.split(' ')[0];
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
          return fileNameSplit.split(' ')[0];
        case 'plain':
          return fileNameSplit.split(' ')[0];
        case 'msword':
          return fileNameSplit.split(' ')[0];
        case 'txt':
          return fileNameSplit.split(' ')[0];
        case 'webm':
          return fileNameSplit.split(' ')[0];
        case 'webp':
          return fileNameSplit.split(' ')[0];
        case 'doc':
          return fileNameSplit.split(' ')[0];
        case 'docx':
          return fileNameSplit.split(' ')[0];
        case 'ppt':
          return fileNameSplit.split(' ')[0];
        case 'mp4':
          return fileNameSplit.split(' ')[0];
        case 'mp3':
          return fileNameSplit.split(' ')[0];
        case 'Mp3':
          return fileNameSplit.split(' ')[0];
        case 'mov':
          return fileNameSplit.split(' ')[0];
        default:
          return file?.split('/')?.[1];
      }
    }
  };

  const icons = {
    png: <img src={mbPng} height="40" alt={t('ICON')} />,
    jpg: <img src={mbPng} height="40" alt={t('ICON')} />,
    jpeg: <img src={mbPng} height="40" alt={t('ICON')} />,
    gif: <img src={mbPng} height="40" alt={t('ICON')} />,
    giff: <img src={mbPng} height="40" alt={t('ICON')} />,
    tif: <img src={mbPng} height="40" alt={t('ICON')} />,
    tiff: <img src={mbPng} height="40" alt={t('ICON')} />,
    webp: <img src={mbPng} height="40" alt={t('ICON')} />,
    pdf: <img src={mbPdf} height="40" alt={t('ICON')} />,
    Pdf: <img src={mbPdf} height="40" alt={t('ICON')} />,
    'octet-stream': <img src={mbDoc} height="40" alt={t('ICON')} />,
    'vnd.openxmlformats-officedocument.wordprocessingml.document': <img src={mbDoc} height="40" alt={t('ICON')} />,
    plain: <img src={mbDoc} height="40" alt={t('ICON')} />,
    msword: <img src={mbDoc} height="40" alt={t('ICON')} />,
    txt: <img src={mbDoc} height="40" alt={t('ICON')} />,
    webm: <img src={mbTxt} height="40" alt={t('ICON')} />,
    doc: <img src={mbDoc} height="40" alt={t('ICON')} />,
    docx: <img src={mbDoc} height="40" alt={t('ICON')} />,
    ppt: <img src={mbDoc} height="40" alt={t('ICON')} />,
    mp4: <img src={mbVideo} height="40" alt={t('ICON')} />,
    mov: <img src={mbVideo} height="20" alt={t('ICON')} />,
    mp3: <img src={mbVideo} height="40" alt={t('ICON')} />,
    Mp3: <img src={mbVideo} height="40" alt={t('ICON')} />,
    Folder: <img src={mbTxt} height="40" alt={t('ICON')} />,
  };

  const renderFileIcon = (obj) => {
    if (obj.fileType === 'Folder') {
      return <img src={mbTxt} height="40" alt={t('ICON')} />;
    }

    if (obj.fileIcon === '' || obj.fileIcon === null) {
      return icons[String(getFileType(obj?.fileType, obj?.resourceName))];
    }
    return <img src={obj?.fileIcon ? defaultIcon : obj?.fileIcon} height="40" alt={t('ICON')} />;
  };

  const openFolder = (obj) => {
    setIsFetching(true);
    const payload = {
      courseId: obj.courseId,
      parentKey: obj.selfKey,
    };
    if (obj.resourceKind === 'Student' || obj.resourceKind === 'student') {
      dispatch(getStudentResourceCourse(1, 100, pageNext, payload, setIsFetching, setLoading));
    }
    dispatch(getFolderDetails(obj));
  };
  const handleToggle = (obj) => {
    if (obj.fileType !== 'Folder') {
      const fileSplit = obj.resourceName.split('.');
      const { length } = fileSplit;
      const type = fileSplit[length - 1];
      setFileType(type);
      setFileUrl(obj.fileUrl);
      setIsDownload(obj.canDownload);
      setResourceName(obj?.resourceName);
      setOpen(!open);
    } else {
      openFolder(obj);
      const folder = folderName;
      folder.push(obj);
      setFolderName(folder);
      selectedResource(obj);
    }
  };
  const downloadFile = async () => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const loadMoreItems = (length) => {
    setIsFetching(true);
    setLoading(true);
    let page = '';
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    const payload = {
      courseId: courseSelectedvalue,
      parentKey: selectedResource.selfKey,
    };
    dispatch(getStudentResourceCourse(page, 100, pageNext, payload, setIsFetching, setLoading));
  };

  return (
    <Box>

      <Grid container spacing={2} className={classes.resourceGrid}>
        {resources.map((obj) => (
          <Grid item lg={2} xs={12} md={3} className={classes.alignResourceGrid}>
            <Card className={classes.resourcesCard}>
              <CardActionArea className={classes.resourceGridCardImg} onClick={() => handleToggle(obj)}>
                <Grid lg={2} xs={12} md={3} className={classes.resourceGridCard}>
                  {renderFileIcon(obj)}
                </Grid>
              </CardActionArea>
              <Tooltip title={`${obj.resourceName}`}>
                <CardContent classes={classes.name}>
                  <Typography className={classes.fileNameStyle}>
                    {obj.resourceName}
                  </Typography>
                </CardContent>
              </Tooltip>
            </Card>
          </Grid>
        ))}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <div className={classes.fileViewer}>
            <div>
              {isDownload
                && (
                  <div className={classes.fileDownload}>
                    <DownloadSharpIcon onClick={(e) => { e.stopPropagation(); downloadFile(); }} />
                  </div>
                )}
              <ShowFiles fileType={fileType} fileUrl={fileUrl} classes={classes} />
              <div className={classes.fileClose}>
                <CloseIcon onClick={handleClose} />
              </div>
            </div>
          </div>
        </Backdrop>
      </Grid>
      {(reduxTotalCount > 0 && resources?.length > 0
        && fileType !== 'Folder') && (
          <Grid className={classes.loadMoreItems}>
            <Grid xs={12} className={classes.totalRowCount}>
              <span>
                {t('TOTAL_RESOURCES')}
                {' '}
                {resources?.length}
                {' '}
                of
                {' '}
                {reduxTotalCount}
              </span>
            </Grid>
            <Grid justifyContent="flex-end" className={classes.loadMore}>
              {isFetching && <p>{t('FETCHING_RESOURCES')}</p>}
              {(!isFetching && resources?.length >= 20) && (resources?.length !== reduxTotalCount) && (
                <ButtonAtom
                  btntype={Buttons.PRIMARY}
                  onClick={() => loadMoreItems(resources?.length)}
                  name={t('LOAD_MORE')}
                />
              )}
            </Grid>
          </Grid>
      )}
    </Box>
  );
}
