const cardStyle = () => ({
  card: {
    backgroundColor: '#c7e5ff',
    width: '100% !important',
  },
  cardContent: {
    textAlign: 'center !important',
    paddingBottom: '0px !important',
  },
  customBanner: {
    width: '95% !important',
  },
  '@media (max-width: 1450px)': {
    customBanner: {
      width: '95% !important',
    },
  },
  '@media (max-width: 600px)': {
    customBanner: {
      width: '100% !important',
      padding: '5px 0px !important',
    },
  },
});

export default cardStyle();
