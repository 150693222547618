import { colors, fonts } from '../../../theme';

const cardStyle = () => ({
  reset: {
    color: `${colors.primary} !important`,
    cursor: 'pointer',
    marginRight: '10px',
  },
  card: {
    width: '100% !important',
    position: 'relative',
  },
  form: {
    marginTop: '0 !important',
  },
  alignTextGrid: {
    width: '98%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '29%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignTextGrid2: {
    '@media (max-width: 600px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        minWidth: '96vw',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        margin: '0 !important',
      },
    },
    '@media (min-width: 500px) and (max-width: 1199px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        minWidth: '84vw',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        margin: '0 !important',
      },
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '28%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        margin: '0 !important',
      },
      '& .MuiTextField-root': {
        width: '111% !important',
      },
    },
  },
  borderBottom: {
    background: colors.secondary,
    height: '2px',
    '@media (max-width: 1024px)': {
      height: '5px',
    },
    display: 'block',
    width: '97.5%',
    marginLeft: '1vw',
  },
  heading: {
    color: colors.primary,
    padding: '0 0 0.5vw 0',
    fontWeight: 600,
    fontSize: '1.5vw',
    marginTop: '15px',
    borderRadius: '0.4vw',
    '@media (max-width: 1199px)': {
      fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
    },
    '@media (max-width: 899px)': {
      marginTop: '20px',
    },
    '@media (max-width: 599px)': {
      marginTop: '10px',
    },
  },
  innerContainerWithoutTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: fonts.fontType.roboto,
    margin: '0px',
    paddingTop: '0px !important',
    '& .MuiInputBase-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '& label.Mui-focused': {
      color: `${colors.primary} !important`,
      outline: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '@media (min-width: 1200px)': {
      margin: '0px 0px 0px 9%',
      '& .MuiInputBase-input': {
        '@media (min-width: 1200px)': {
          padding: '0.7vw 0vw 0.9vw 0vw',
        },
      },
      '& .MuiOutlinedInput-root': {
        '@media (min-width: 1200px)': {
          width: '100%',
          fontSize: '0.9vw',
        },
      },
    },
  },
  innerContainerTitle: {
    color: 'linear-gradient(180deg, rgba(1,94,234,1) 0%, rgba(16,79,150,1) 100%)',
    padding: '0px !important',
    '@media (min-width: 600px)': {
      marginTop: '-8px',
    },
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: fonts.fontType.roboto,
    margin: '0',
    paddingTop: '0px !important',
    '& .MuiInputBase-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '& label.Mui-focused': {
      color: `${colors.primary} !important`,
      outline: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '@media (min-width: 1200px)': {
      '& .MuiInputBase-input': {
        '@media (min-width: 1200px)': {
          padding: '0.7vw 0vw 0.9vw 1vw',
        },
      },
      '& .MuiOutlinedInput-root': {
        '@media (min-width: 1200px)': {
          width: '100%',
          fontSize: '0.9vw',
        },
      },
    },
  },
  innerContainer1: {
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: fonts.fontType.roboto,
    margin: '0',
    paddingTop: '0px !important',
    '& .MuiInputBase-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '& label.Mui-focused': {
      color: `${colors.primary} !important`,
      outline: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '@media (min-width: 1200px)': {
      '& .MuiInputBase-input': {
        '@media (min-width: 1200px)': {
          padding: '0.7vw 0vw 0.9vw 1vw',
        },
      },
      '& .MuiOutlinedInput-root': {
        '@media (min-width: 1200px)': {
          width: '100%',
          fontSize: '0.9vw',
        },
      },
    },
  },
  innerContainer2: {
    width: '76.5vw',
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: fonts.fontType.roboto,
    margin: '0',
    paddingTop: '0px !important',
    '& .MuiInputBase-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '& label.Mui-focused': {
      color: `${colors.primary} !important`,
      outline: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '@media (min-width: 1200px)': {
      '& .MuiInputBase-input': {
        '@media (min-width: 1200px)': {
          padding: '0.7vw 0vw 0.9vw 1vw',
        },
      },
      '& .MuiOutlinedInput-root': {
        '@media (min-width: 1200px)': {
          width: '100%',
          fontSize: '0.9vw',
        },
      },
    },
  },
  alignGridTitle: {
    width: '98%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '10%',
        flexBasis: '9%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        marginLeft: '0px !important',
        width: '100%',
        marginRight: '0.8vw !important',
        marginBottom: '5px !important',
        outline: 'none',
        '& .MuiFormControl-root': {
          margin: '0 !important',
          width: '100%',
        },
        '& .MuiInputLabel-root': {
          color: colors.primary,
        },
      },
    },
  },
  paperStyle: {
    '@media (max-width: 600px)': {
      margin: '10px auto 5px 0px !important',
    },
  },
  alignGridTitle2: {
    '@media (max-width: 600px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        minWidth: '96vw',
      },
    },
    '@media (min-width: 500px) and (max-width: 1199px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        minWidth: '84vw',
      },
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '10%',
        flexBasis: '9%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        marginLeft: '0px !important',
        width: '100%',
        marginRight: '0.8vw !important',
        marginBottom: '5px !important',
        outline: 'none',
        '& .MuiFormControl-root': {
          margin: '0 !important',
          width: '100%',
        },
      },
    },
  },
  errorText: {
    color: colors.errorText,
    margin: '3px 14px 0px 0px !important',
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    '@media (max-width: 1200px)': {
      fontSize: '0.7rem',
      marginTop: 10,
    },
    display: 'block',
  },
  errorTextGrid1: {
    color: colors.errorText,
    margin: '8px 14px 0px 0px !important',
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    '@media (max-width: 1200px)': {
      fontSize: '0.7rem',
      marginTop: 10,
    },
    display: 'block',
  },
  errorTextGrid2: {
    color: colors.errorText,
    margin: '0px 14px 0px 0px !important',
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    '@media (max-width: 1200px)': {
      fontSize: '0.7rem',
      marginTop: 10,
    },
    display: 'block',
  },
  alignGrid: {
    padding: 0,
    '@media (min-width: 1200px)': {
      maxWidth: '33.2%',
      height: '5vw',
    },
    '& .MuiOutlinedInput-root': {
      '&:focus': {
        border: '0.15vw solid #104F96',
        outline: 'none',
      },
    },
    '& #extraCurricularActivities': {
      border: `0.1vw solid ${colors.outline}`,
      color: `${colors.placeHolderColor} !important`,
      padding: '0.4vw',
      fontSize: '0.9vw',
      transform: 'translate(0.3vw, 0.4vw) scale(1)',
      '@media (max-width: 1199px)': {
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
        left: '15px',
      },
      fontFamily: fonts.fontType.roboto,
      letterSpacing: '0.1vw',
    },
    '& .MuiOutlinedInput-root .MuiSelect-select': {
      transform: 'translate(0vw, 0.1vw)',
    },
    '& .MuiOutlinedInput-root .MuiSelect-select.MuiSelect-multiple': {
      transform: 'translate(0vw, 0vw)',
    },
    '& #addressAutoComplete': {
      '@media (min-width: 1200px)': {
        width: '89.98%',
      },
    },
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      '& .MuiFormControl-root': {
        margin: 'auto',
      },
      '& div': {
        margin: 0,
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiFormControl-root': {
        margin: 0,
        width: '100% !important',
      },
    },
    '& .MuiTextField-root': {
      '@media (min-width: 1200px)': {
        width: '100% !important',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.1vw solid ${colors.primary} `,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.2vw solid ${colors.primary} !important`,
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '@media (min-width: 1200px)': {
        marginTop: 0,
        borderRadius: '0.4vw',
        width: '100%',
      },
    },
  },
  alignTextGridPhone: {
    width: '99%',
    '& .MuiInputLabel-root': {
      marginTop: '2px !important',
      fontSize: '0.9vw !important',
      '@media (max-width: 599px)': {
        marginTop: '-2px !important',
      },
      '@media (min-width: 1199px)': {
        marginTop: '-1.5px !important',
        marginLeft: '10px !important',
      },
      '@media (max-width: 1199px)': {
        fontSize: '16px !important',
        marginLeft: '16px !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      fontSize: '12px !important',
      marginTop: '0px !important',
      color: `${colors.primary} !important`,
      background: 'white',
      paddingLeft: '2px',
      marginLeft: '0px !important',
    },
    '@media (max-width: 800px)': {
      '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
        fontSize: '16px !important',
        marginTop: '0px !important',
        color: `${colors.primary} !important`,
        background: 'white',
        paddingLeft: '2px',
        marginLeft: '0px !important',
      },
      '& .MuiInputLabel-root': {
        marginLeft: '20px !important',
      },
      '& .MuiFormControl-root': {
        width: '98% !important',
        '& input': {
          marginTop: '0px !important',
        },
      },
      '&.MuiGrid-root.MuiGrid-item': {
        width: '100% !important',
        flexBasis: '100% !important',
        maxWidth: 'initial',
      },
    },
    '@media (min-width: 601px) and (max-width: 1199px)': {
      '& .MuiInputLabel-root': {
        marginLeft: '20px !important',
      },
      '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
        fontSize: '16px !important',
      },
      '& .MuiFormControl-root': {
        width: '98% !important',
      },
      '&.MuiGrid-root.MuiGrid-item': {
        width: '100% !important',
        flexBasis: '100% !important',
        maxWidth: 'initial',
      },
    },
    '@media (max-width: 1699px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '33%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '99%',
        '& input': {
          paddingLeft: '6px',
          marginTop: '2px',
          maxHeight: '50px !important',
        },
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '99%',
          padding: '0 0vw',
        },
      },
    },
    '@media (min-width: 1700px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '33%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '99%',
        '& input': {
          paddingLeft: '6px',
          marginTop: '5px',
          maxHeight: '62px !important',
        },
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '99%',
          padding: '0 0vw',
        },
      },
    },
  },
  resetBtn: {
    textAlign: 'end',
    padding: '0px',
    marginLeft: '-22px',
    display: 'ruby',
    marginTop: '-16px',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      marginLeft: '-60px !important',
      display: 'flex !important',
    },
  },
  noResetBtn: {
    textAlign: 'end',
    padding: '0px',
    marginLeft: '-22px',
    display: 'ruby',
    marginTop: '-16px',
    alignItems: 'center',
  },
  alignTextGridPhone2: {
    '@media (min-width: 601px) and (max-width: 1199px)': {
      '& .MuiInputLabel-root': {
        marginLeft: '20px !important',
      },
      '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
        fontSize: '16px !important',
      },
      '& .MuiFormControl-root': {
        width: '98% !important',
      },
      '&.MuiGrid-root.MuiGrid-item': {
        width: '100% !important',
        flexBasis: '100% !important',
        maxWidth: 'initial',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      fontSize: '12px',
      marginTop: '0px !important',
      color: `${colors.primary} !important`,
      background: 'white',
      paddingLeft: '2px',
      marginLeft: '0px !important',
    },
    width: '96%',
    '@media (max-width: 800px)': {
      '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
        fontSize: '16px !important',
        marginTop: '0px !important',
        color: `${colors.primary} !important`,
        background: 'white',
        paddingLeft: '2px',
        marginLeft: '0px !important',
      },
      '& .MuiInputLabel-root': {
        marginLeft: '20px !important',
      },
      '& .MuiFormControl-root': {
        width: '126% !important',
      },
    },
    '@media (max-width: 500px)': {
      '& .MuiFormControl-root': {
        width: '124% !important',
      },
    },
    '@media (min-width: 501px) and (max-width: 1199px)': {
      '& .MuiFormControl-root': {
        width: '107% !important',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw',
      '@media (max-width: 599px)': {
        marginTop: '-2px !important',
      },
      '@media (min-width: 1199px)': {
        marginTop: '-1.5px !important',
      },
      '@media (max-width: 1199px)': {
        fontSize: '16px !important',
        marginLeft: '16px !important',
      },
    },
    '@media (min-width: 1199px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '33%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '92.5% !important',
        '& input': {
          paddingLeft: '6px',
          marginTop: '2px',
          maxHeight: '50px !important',
        },
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '99% !important',
          padding: '0 0vw',
        },
      },
    },
    '@media (min-width: 1700px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '33%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '94%',
        '& input': {
          paddingLeft: '6px',
          marginTop: '5px',
          maxHeight: '62px !important',
        },
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '94%',
          padding: '0 0vw',
        },
      },
    },
  },
  textArea: {
    width: '100%',
    margin: '0px',
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw !important',
      marginTop: '-4px !important',
      '@media (max-width: 600px)': {
        fontSize: '16px !important',
        marginTop: '8px !important',
      },
      '@media (min-width: 601px) and (max-width: 1199px)': {
        marginTop: '8px !important',
        fontSize: '16px !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      fontSize: '0.9vw !important',
      marginTop: '2px !important',
      color: `${colors.primary} !important`,
      background: 'white',
      paddingLeft: '2px',
      '@media (max-width: 600px)': {
        fontSize: '16px !important',
        marginTop: '0px !important',
      },
      '@media (min-width: 601px) and (max-width: 1199px)': {
        marginTop: '0px !important',
        fontSize: '16px !important',
      },
    },
    '& .MuiFormControl-root': {
      margin: '2px !important',
    },
  },
  button: {
    justifyContent: 'flex-end',
    paddingBottom: '10px',
    width: '98%',
    '@media (max-width: 899px)': {
      marginBottom: '5vw',
      marginRight: '1vw',
      width: '100%',
    },
  },
  activeButton: {
    color: '#f3f8fe !important',
    '@media (min-width: 1200px)': {
      height: '2.9vw',
      fontSize: '1vw',
      padding: '0.8vw',
      minWidth: '15vw',
      borderRadius: '0.4vw !important',
    },
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    boxShadow: '0 4px 4px -2px hsl(0deg 0% 65% / 25%)',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    marginLeft: '1.2vw !important',
    marginRight: '1vw',
    textTransform: 'none !important',
    background: `${colors.newBGColor} !important`,
    '@media (max-width: 900px)': {
      height: 'auto !important',
    },
  },
  commonButtonNew: {
    minWidth: '2vw !important',
    height: '3vw !important',
    padding: '1.2vw 1vw 1.2vw 1.2vw !important',
    '@media (max-width: 1199px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
      borderRadius: '12px !important',
    },
    '@media (max-width: 499px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
    },
    '& svg': {
      width: '18px !important',
      height: '18px !important',
      '@media (max-width: 1199px)': {
        width: '16px !important',
        height: '16px !important',
      },
    },
  },
  expandIcon: {
    minWidth: '1vw !important',
    height: '1vw !important',
    padding: '18px 18px 18px 18px !important',
    marginTop: '3px !important',
    '@media (max-width: 1700px)': {
      padding: '1vw 1vw 1.2vw 1.2vw !important',
    },
    '@media (max-width: 1199px)': {
      height: 'auto !important',
      padding: '4px 7px !important',
      minWidth: 'auto !important',
      borderRadius: '12px !important',
    },
    '@media (max-width: 499px)': {
      padding: '5px 8px !important',
      minWidth: 'auto !important',
    },
    '& svg': {
      width: '25px !important',
      height: '25px !important',
      margin: '5px 0px 0px -3px',
      '@media (max-width: 1199px)': {
        width: '16px !important',
        height: '16px !important',
      },
    },
  },
  outerContainer: {
    marginBottom: '2vw',
  },
  footerBottom: {
    background: colors.footerBottomColor,
    height: '0.1vw',
    display: 'block',
    width: '100%',
    marginTop: '2vw',
  },
  alignGridAcaYearStudent: {
    width: '98%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '24%',
        maxWidth: 'initial',
      },
      '& .MuiOutlinedInput-root': {
        height: '2.9vw !important',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridAcaYearStudent2: {
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '10px !important',
        color: colors.primary,
        marginLeft: '0px !important',
      },
    },
    '@media (max-width: 600px)': {
      width: '94.5%',
    },
    width: '97%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '24%',
        maxWidth: 'initial',
      },
      '& .MuiOutlinedInput-root': {
        height: '2.9vw !important',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  formControl: {
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '10px !important',
        color: colors.primary,
        marginLeft: '0px !important',
      },
    },
  },
  alignGridAcaGradeStudent: {
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '10px !important',
        color: colors.primary,
        marginLeft: '0px !important',
      },
    },
    width: '98%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '24%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridAcaGradeStudent2: {
    width: '97%',
    '@media (max-width: 600px)': {
      width: '94.5% !important',
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '24%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: '2px !important',
        '& .MuiFormControl-root': {
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridStudentLocation: {
    paddingTop: '20px',
    maxHeight: '100px',
    overflowY: 'auto',
    width: '98%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '25%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridStudentData: {
    maxHeight: '100px',
    overflowY: 'auto',
    width: '98%',
    '@media (max-width: 800px)': {
      padding: '20px 6px 0px 24px !important',
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '25%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '96%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridStudentLocation2: {
    maxHeight: '100px',
    overflowY: 'auto',
    width: '98%',
    '@media (max-width: 600px)': {
      width: '94.5%',
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '25%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridFirstNamesTUDENT: {
    width: '98%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '25%',
        maxWidth: 'initial',
      },
      '& .MuiInputLabel-root.Mui-disabled': {
        marginTop: '-4px !important',
      },
      '& .MuiOutlinedInput-root': {
        height: '2.9vw !important',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0px 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridFirstNamesTUDENTCL2: {
    width: '98%',
    '@media (max-width: 600px)': {
      width: '94.5%',
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '25%',
        maxWidth: 'initial',
      },
      '& .MuiInputLabel-root.Mui-disabled': {
        marginTop: '-4px !important',
      },
      '& .MuiOutlinedInput-root': {
        height: '2.9vw !important',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0px 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridFirstNamesTUDENT2: {
    width: '97%',
    '@media (max-width: 600px)': {
      '& .MuiFormControl-root': {
        margin: '0px !important',
        width: '98.5%',
      },
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '25%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: '1px !important',
        '& .MuiFormControl-root': {
          width: '100%',
          padding: '0 0vw',
          marginLeft: '-8px !important',
        },
      },
    },
  },
  alignGridFirstNamesTUDENTDate2: {
    width: '97%',
    '@media (max-width: 600px)': {
      width: '94%',
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '25%',
        maxWidth: 'initial',
      },
      '& .MuiOutlinedInput-root.MuiInputBase-root': {
        height: '2.9vw !important',
      },
      '& .MuiOutlinedInput-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          '&.MuiInputBase-root': {
            height: '2.9vw !important',
            lineHeight: '1',
          },
        },
      },
      '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: '1px !important',
        '& .MuiFormControl-root': {
          width: '100%',
          padding: '0 0vw',
          marginLeft: '-8px !important',
        },
      },
    },
  },
  alignGridFirstName: {
    width: '98%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '30%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridLastName: {
    width: '98%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '30%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        marginLeft: '0vw !important',
        width: '100%',
        marginRight: '0px !important',
        '& .MuiFormControl-root': {
          margin: '0 !important',
          width: '100%',
        },
      },
    },
  },
  alignGridFirstName2: {
    '@media (max-width: 600px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        minWidth: '96vw',
      },
    },
    '@media (min-width: 500px) and (max-width: 1199px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        minWidth: '84vw',
      },
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '31%',
        flexBasis: '31.3%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridLastName2: {
    '@media (max-width: 600px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        minWidth: '96vw',
      },
    },
    '@media (min-width: 500px) and (max-width: 1199px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        minWidth: '84vw',
      },
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '31%',
        flexBasis: '31%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        marginLeft: '0vw !important',
        width: '100%',
        marginRight: '0px !important',
        '& .MuiFormControl-root': {
          margin: '0 !important',
          width: '100%',
        },
      },
    },
  },
  alignGridAppSuite: {
    width: '98%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '33%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridAdress: {
    marginTop: '0vw !important',
    width: '98%',
    '@media (max-width: 1199px)': {
      height: '80px !important',
      '& .MuiFormControl-root': {
        height: '60px !important',
      },
    },
    '@media (min-width: 600px) and (max-width: 1199px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '98% !important',
        innerHeight: '50px !important',
        flexBasis: '98% !important',
        maxWidth: 'initial',
      },
    },
    '@media (min-width: 1200px) and (max-width: 1399px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '33%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        maxHeight: '45px !important',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
    '@media (min-width: 1400px) and (max-width: 1749px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '33%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        maxHeight: '55px !important',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
    '@media (min-width: 1750px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '33%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        maxHeight: '65px !important',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
  alignGridEmail: {
    width: '98%',
    '@media (max-width: 600px)': {
      '& .MuiFormControl-root': {
        margin: '0 !important',
      },
    },
    '@media (min-width: 500px) and (max-width: 1199px)': {
      '& .MuiFormControl-root': {
        margin: '0 !important',
      },
    },
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '30%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        margin: '0 !important',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },
});

export default cardStyle();
