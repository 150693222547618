/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/no-find-dom-node */
import React, { useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import HighMaps from 'highcharts/highmaps';
import usCanada from '@highcharts/map-collection/custom/usa-and-canada.topo.json';

function CustomHighMap(props) {
  const { filterCountry, coordinatesData, color } = props;
  const ref = useRef(null);
  useEffect(() => {
    const options = {
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        map: {
          states: {
            hover: {
              color: '#EEDD66',
            },
          },
        },
      },
      colorAxis: {
        min: 0,
        minColor: '#E6E7E8',
        maxColor: color,
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },

      subtitle: {
        text: '',
        floating: true,
        align: 'right',
        y: 50,
        style: {
          fontSize: '16px',
        },
      },
      series: [
        {
          mapData: usCanada,
          data: coordinatesData,
          name: '',
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
        },
      ],
      tooltip: {
        formatter() {
          const country = this.point['hc-key'].startsWith('ca') ? 'Canada' : 'USA';
          return `<span style="color: ${this.point.color}">\u25CF</span>${country} <br>${this.point.name}: ${this.point.value}`;
        },
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom',
        },
      },
    };
    const chart = new HighMaps.Map(findDOMNode(ref.current), options);
    return () => chart.destroy();
  }, [coordinatesData, filterCountry]);

  return <div ref={ref} className="in-highchart" />;
}

export default CustomHighMap;
