import { colors } from '../../../../theme';

const deafaultStyle = (theme) => ({
  locationManagerTable: {
    '&.MuiTableHead-root': {
      borderBottom: `0.15vw solid ${colors.actionIconsColor}`,
    },
    '& .MuiTableCell-head': {
      fontSize: '0.9vw',
      padding: '0.5vw',
      color: colors.actionIconsColor,
      fontWeight: 600,
      [theme.breakpoints.down('lg')]: {
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
        whiteSpace: 'nowrap',
      },
    },
    '& .MuiTableCell-body': {
      fontSize: '0.9vw',
      padding: '1vw 0.5vw',
      [theme.breakpoints.down('lg')]: {
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
      },
      '& .MuiSvgIcon-root': {
        width: '1.5vw',
        height: '1.5vw',
        cursor: 'pointer',
        [theme.breakpoints.down('lg')]: {
          width: 12,
          height: 12,
        },
      },
    },
  },
  noData: {
    textAlign: 'center',
    minHeight: '12vw',
    width: '100%',
    paddingTop: '5vw',
  },
  loadMore: {
    textAlign: 'right',
    '& .MuiButton-root': {
      marginRight: '1vw',
      minWidth: '8vw',
    },
  },
  loadMoreItems: {
    paddingBottom: '2vw',
  },
  totalRowCount: {
    marginRight: '1.3vw',
    marginBottom: '1vw',
    textAlign: 'right',
    fontSize: '0.9vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    color: colors.black,
  },
  fetch: {
    fontSize: '0.9vw',
  },
});

export default deafaultStyle;
