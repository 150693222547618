import { useState } from 'react';

export default function useDataTableParams() {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  // setting tha initial pageNumber
  // we need to know if therpageNumbere is more data
  const nextPage = (res, students, prevPageNumber) => {
    setPageNumber(prevPageNumber + 1);
    setHasMore(students < res.count);
    setIsFetching(false);
  };
  // Change number of raw per pages
  function handleChangeRowPerPage(rowsPerPage) {
    setPageLimit(rowsPerPage);
    setPageNumber(1);
  }
  // Change pages on select next or previous
  function handlePageNumberChange(page) {
    setPageNumber(page);
  }
  /*
   * FilterData search the data and get the list data
   */
  return {
    pageNumber,
    pageLimit,
    isFetching,
    hasMore,
    nextPage,
    setHasMore,
    setIsFetching,
    handlePageNumberChange,
    setPageNumber,
    handleChangeRowPerPage,
  };
}
