import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import DownLeftRightIcon from '../../../../assets/svg/downLeftRight';
import { GroupedAutoComplete } from '../../../../components/atoms';
import { useStyles } from './style';
import constant from '../../../../store/constant';

function Header(props) {
  const {
    handleDrawer,
    setFilterChanged,
    setFilterOptions,
    filterOptions,
    setSearchText,
    setSearchTextUM,
    searchText,
    open,
    inputRef,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedFilter = useSelector(
    (reduxState) => reduxState.getUser.selectedFilter,
  );

  const allUserFilters = useSelector(
    (reduxState) => reduxState.getUser.allUserFilters,
  );

  React.useEffect(() => {
    if (filterOptions.length !== 0) {
      setFilterOptions(filterOptions, true);
    }
  }, [searchText]);

  const onGroupAutoCompleteSelection = (e, val) => {
    const searchFilter = selectedFilter?.filter((obj) => !val.includes(obj));
    if (searchFilter?.length > 0) {
      const isSearchText = searchFilter?.filter((obj) => (obj.filterKey === searchText));
      if (isSearchText?.length > 0) {
        setSearchText('');
        setSearchTextUM('');
        localStorage.setItem('searchTextUserManager', '');
      }
    }
    dispatch({
      type: constant.SET_USERS_SELECTED_FILTER,
      payload: val,
    });
    const updatedFilOptions = [...filterOptions].map((fiOpt) => {
      const opts = fiOpt.options;
      const options = opts.map((op) => {
        const findIn = _.findIndex(val, {
          label: op.label,
          groupBy: fiOpt.label,
        });
        if (findIn !== -1) {
          return { ...op, checked: true };
        }
        return { ...op, checked: false };
      });
      return { ...fiOpt, options };
    });
    setFilterOptions(updatedFilOptions);
    if (setFilterChanged) { setFilterChanged(true); }
  };
  const clearValues = () => {
    onGroupAutoCompleteSelection({}, []);
    dispatch({
      type: constant.SET_USERS_SELECTED_FILTER,
      payload: [],
    });
    setSearchText('');
    setSearchTextUM('');
    localStorage.setItem('searchTextUserManager', '');
  };
  const getGroupOptions = () => {
    const studentFil = [...allUserFilters];
    const options = [];
    studentFil.forEach((stu) => {
      stu?.options?.forEach((stuOpt) => {
        options.push({
          label: stuOpt[stu.key],
          groupBy: stu.label,
          filterKey: stu.filter,
        });
      });
    });
    return options;
  };

  const handleInputChangeDebounced = debounce((newInputValue, reason) => {
    if (reason === 'clear') {
      clearValues();
    }
    if (newInputValue.length > 2) {
      const trimmedInputValue = newInputValue.replace(/^[\W_]+|[\W_]+$/g, '');
      const searchTextWithoutExtraSpaces = trimmedInputValue.replace(/\s{2,}/g, ' ');
      setSearchText(searchTextWithoutExtraSpaces);
      setSearchTextUM(searchTextWithoutExtraSpaces);
      localStorage.setItem('searchTextUserManager', searchTextWithoutExtraSpaces);
    } else {
      const newOptions = selectedFilter?.filter((obj) => (obj.filterKey !== searchText));
      if (newOptions.length !== selectedFilter.length) {
        dispatch({
          type: constant.SET_USERS_SELECTED_FILTER,
          payload: newOptions,
        });
      }
      setSearchText('');
      setSearchTextUM('');
      localStorage.setItem('searchTextUserManager', '');
    }
  }, 700);

  const clearSearchText = () => {
    setSearchText('');
    localStorage.setItem('searchTextUserManager', '');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', clearSearchText);
    return () => {
      window.removeEventListener('beforeunload', clearSearchText);
    };
  }, []);

  return (
    <div>
      <Grid container className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('USERMANAGER')}
          </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.titleRow}>
          <Grid item xs={12} md={3} lg={1.5} className={classes.filterButton}>
            <Button variant="filter" onClick={handleDrawer} className={classes.filterBtn}>
              {!open ? <DownLeftRightIcon />

                : (
                  <div className="DownLeftIcon">
                    <ZoomOutMapOutlinedIcon />
                  </div>
                )}
              <TuneOutlinedIcon className={classes.filterIcon} />
              filter
            </Button>
          </Grid>
          <Grid item xs={12} md={7} lg={10.5} className={classes.ChipSection}>
            <GroupedAutoComplete
              isStudentFilter
              options={getGroupOptions()}
              onSelection={onGroupAutoCompleteSelection}
              value={selectedFilter}
              label={t('SEARCH')}
              placeholder={(localStorage.getItem('searchTextUserManager') !== '' && localStorage.getItem('searchTextUserManager') !== null) ? `Last Text Searched: ${localStorage.getItem('searchTextUserManager')} ` : t('SEARCH')}
              onInputChange={(e, newInputValue, reason) => {
                handleInputChangeDebounced(newInputValue, reason);
              }}
              allowUserInput
              enterNotAllowed
              inputRef={inputRef}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Header;
