/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DataGridProTable from '../../../components/atoms/data-grid-pro-students';
import useStyles from '../../../custom-hooks/useStyles';
import styles from '../my-class/style';
import { getSemesterColumnsData, getQuarterColumnsData } from './columns';
import { isValidSemesterYear } from '../../../utils/methods';
import { Loader } from '../../../components/atoms';
import { getGrades } from '../../../store/actions/getGrades';

export default function ListView({
  studentsData,
  checkboxChanged,
  loading,
  setError,
  setOpenDialog,
  isselectedYear,
  visibleFields,
}) {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem('sendSingleEmail', false);
  const gradesData = useSelector((state) => state.getGrade);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    dispatch(getGrades());
  }, []);

  const columns = isValidSemesterYear(isselectedYear) ? getSemesterColumnsData(t, classes, setOpenDialog, dispatch, navigate, gradesData, studentsData, visibleFields, isselectedYear) : getQuarterColumnsData(t, classes, setOpenDialog, dispatch, navigate, visibleFields);

  return (
    <Grid container className={classes.studentDetails}>
      {studentsData?.length > 0
        && (
          <DataGridProTable
            data={studentsData}
            columns={columns}
            autoHeight
            hideFooter={false}
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableColumnResize
            disableSelectionOnClick
            disableColumnReorder
            checkboxSelection
            ColumnUnsorted
            ColumnSortedAscending
            ColumnSortedDescending
            onSelectionModelChange={(ids) => {
              setError('');
              const selectedIDs = new Set(ids);
              const selectedData = studentsData.filter((row) => selectedIDs.has(row.id));
              checkboxChanged(selectedData);
            }}
            loading={loading}
            pageSize={pageSize}
            pagination={true}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        )}

      {(!loading && studentsData?.length === 0) && (
        <Grid className={classes.noData}>
          <DoNotDisturbIcon />
          <Grid>
            <Typography variant="subtitle2" color="text.secondary">
              {t('NO_DATA')}
            </Typography>
          </Grid>
        </Grid>
      )}

      {(loading && studentsData?.length === 0) && (
        <Grid className={classes.noData}>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
    </Grid>
  );
}
