/* eslint-disable */
import {TextFieldProps, TextField} from "@mui/material";
import {useField} from "formik";
import React, {memo, useEffect, useState} from "react";
import {usePropagateRef} from "./usePropagateRef";
import style from './style';
import { removeSpecialChar } from '../../../utils/methods';

/**
 * This is kind of hacky solution, but it mostly works. Your mileage may vary
 */

const PerformantTextField = memo(
    (props) => { 
        const classes = style();
        const { onKeyDown, customClass, textAlign, inputStyle} =props;
        const [field, meta] = useField(props.name);
        const error = (!!meta.error && meta.touched) || props.error;
        /**
         * For performance reasons (possible due to CSS in JS issues), heavy views
         * affect re-renders (Formik changes state in every re-render), bringing keyboard
         * input to its knees. To control this, we create a setState that handles the field's inner
         * (otherwise you wouldn't be able to type) and then propagate the change to Formik onBlur and
         * onFocus.
         */
        
        /**
         * Using this useEffect guarantees us that pre-filled forms
         * such as passwords work.
         */
        
        
        // Will set depending on the performance props
       
            const inputProps = {
                ...(props.type === "number" ? {min: props?.min, max: props?.max} : {}),
                ...(textAlign ? {style: {textAlign}} : {}),
                ...(inputStyle ? {style: inputStyle} : {})
            }

        return (
            <>
                <TextField className={`${classes[customClass]} ${classes.performTextField}`}
                    id={props.name}
                    value={props.value}
                    label={props.label}
                    inputRef={props.inputRef}
                    onChange={props.onChange}
                    InputProps={props.InputProps}
                    type={props.type}
                    required={props.required}
                    variant={props.variant}
                    placeholder={props.placeholder}
                    {...(onKeyDown && { onKeyDown })}
                    title={props.title}
                    onBlur={props.onBlur}
                    inputProps={inputProps}
                    error={error}
                    helperText={(meta.touched && meta.error) || props.error}
                />
            </>
        );
    }
);

export default PerformantTextField;
