import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import ArrowDropdown from '@mui/icons-material/KeyboardArrowDown';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './style';
import { toolTipTheme } from '../../../utils/commonUiComponent';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '20vw',
    },
  },
};

export default function MultipleSelectCheckmarks({
  handleChange, label, id, options = [], value, onBlur, error, required, onFocus, name,
  customClass, tooltip,
}) {
  const { t } = useTranslation();
  const classes = style();
  const [tooltipValues, setTooltipValues] = React.useState(false);
  return (
    <div>
      <ThemeProvider theme={toolTipTheme}>
        <Tooltip title={tooltipValues && tooltip ? tooltipValues : ''}>
          <FormControl
            className={customClass ? `${classes[customClass]}` : `${classes.formControl}`}
          >
            <InputLabel id={id}>
              {(t(label) + (required ? ' *' : ''))}
            </InputLabel>
            <Select
              label={label}
              labelId={id}
              name={name}
              id="demo-multiple-checkbox"
              multiple
              error={!!error}
              value={value || []}
              IconComponent={ArrowDropdown}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value?.length === 0) {
                  setTooltipValues(false);
                }
              }}
              onBlur={onBlur}
              onClose={onBlur}
              onFocus={onFocus}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                const values = [];
                selected.forEach((item) => {
                  const selectedItem = options.find((opt) => opt.id === item);
                  if (selectedItem) {
                    values.push(t(selectedItem.name));
                  }
                });
                setTooltipValues(values.join(', '));
                return values.join(', ');
              }}
              MenuProps={MenuProps}
            >
              {options.map((opt) => (
                <MenuItem key={opt.name} value={opt.id}>
                  <Checkbox checked={value && value.indexOf(opt.id) > -1} />
                  <ListItemText primary={t(opt.name)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>
      </ThemeProvider>
    </div>
  );
}
