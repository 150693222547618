import { fonts, colors } from '../../../theme';

const datePickerStyle = () => ({
  wrapper: {
    width: '100%',
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      color: `${colors.primary} !important`,
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '0.7vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.primary} !important`,
        marginLeft: '2px !important',
      },
      '@media (max-width: 600px)': {
        marginTop: '4px !important',
      },
    },
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        '&.MuiInputBase-root': {
          height: '2.7vw',
          lineHeight: '1',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&:hover fieldset': {
          border: `0.2vw solid ${colors.primary} !important`,
          outline: 'none',
        },
      },
      '@media (max-width: 1199px)': {
        borderRadius: 3,
      },
      fontFamily: fonts.fontType.roboto,
      borderRadius: '0.4vw',
      outline: 'none',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.backgroundGrey,
        color: `${colors.primary} !important`,
        WebkitTextFillColor: `${colors.primary} !important`,
      },
      '&.MuiOutlinedInput-root input.Mui-disabled': {
        WebkitTextFillColor: `${colors.primary} !important`,

      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          width: '2vw',
          height: '2vw',
        },
      },
    },
    '& .MuiFormControl-root.MuiTextField-root': {
      '@media (min-width: 1200px)': {
        width: '100% !important',
      },
    },
    '& .MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
        transform: 'translate(1.5vw, -0.6vw) scale(1)',
        marginLeft: '-0.8vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: colors.primary,
      },
      fontFamily: fonts.fontType.roboto,
      '& .Mui-error': {
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: 'inline-table',
    },
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            '@media (min-width: 1200px)': {
              fontFamily: `${fonts.fontType.roboto} !important`,
              fontSize: '0.9vw !important',
              color: `${colors.placeHolderColor}!important`,
              letterSpacing: 1,
            },
            '@media (max-width: 1199px)': {
              fontSize: '3vw !important',
              fontFamily: `${fonts.fontType.roboto} !important`,
              color: `${colors.placeHolderColor}!important`,
              letterSpacing: 1,
            },
          },
        },
      },
    },
  },

});

export default datePickerStyle;
