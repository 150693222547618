import { useState } from 'react';

export default function useDataTableParams() {
  const [graphOption, setGraphOption] = useState('year');

  const handleGraphOption = (event) => {
    setGraphOption(event?.target?.value || '');
  };
  return {
    graphOption,
    handleGraphOption,
  };
}
