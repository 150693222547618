import * as React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function BasicMenu({
  openDeactivateFileDialog,
  openEditFileDialog,
  setSelectedRow,
  row,
  setStatus,
  status,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setStatus(row.deactivateDate);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={t('OPTIONS')} placement="top">
        <IconButton
          aria-label="menu"
          onClick={handleClick}
        >
          <MoreHorizIcon style={{ color: '#015EEA' }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          style={{ borderBottom: '0.1vw solid #d0d0d0', padding: '0.6vw' }}
          onClick={() => {
            setAnchorEl(null);
            openEditFileDialog();
          }}
        >
          {t('EDIT')}
        </MenuItem>
        {status === null
          && (
            <MenuItem
              style={{ padding: '0.6vw' }}
              onClick={() => {
                setAnchorEl(null);
                openDeactivateFileDialog();
              }}
            >
              {t('DEACTIVATE')}
            </MenuItem>
          )}
      </Menu>
    </div>
  );
}
