import { colors, fonts } from '../../../theme';

const enrollStudentStyle = ((theme) => ({
  contributionAmount: {
    '& .MuiFormControl-root': {
      marginRight: '0.5vw',
      '@media (max-width: 1200px)': {
        width: '14.5vw !important',
        paddingRight: 0,
      },
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      width: '100% !important',
      marginRight: '0',
    },
    '& .MuiFormControl-root .MuiOutlinedInput-root': {
      marginTop: '0.8vw !important',
      marginRight: '0.8vw !important',
    },
    '& .MuiFormControl-root .MuiInputLabel-root': {
      marginTop: '0.8vw !important',
      fontSize: '1vw !important',
    },
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
  },
  contributionCause: {
    paddingLeft: '2vw !important',
    '& .MuiFormControl-root': {
      '@media (min-width: 1200px)': {
        width: '28vw',
        borderRadius: '1vw',
      },
      '& .MuiInputLabel-root.MuiInputLabel-shrink': {
        transform: 'translate(0.9vw, -0.5vw) !important',
        color: colors.primary,

      },
    },
    '& .MuiOutlinedInput-root': {
      '@media (max-width: 1200px)': {
        lineHeight: 'normal',
      },
    },
  },
  feeRow: {
    '& .MuiTableCell-root': {
      '@media (max-width: 1199px)': {
        padding: '0px !important',
      },
    },
    '& td, & th': {
      '@media (min-width: 1200px)': {
        padding: '0px !important',
      },
      '&:last-child': {
        padding: '0px !important',
      },
    },
    '& tr': {
      padding: '0px !important',
      '& td, & th': {
        '@media (min-width: 1199px)': {
          padding: '0px !important',

        },
        '&:last-child': {
          padding: '0px !important',
        },
      },
    },
  },
  tableView: {
    width: '100%',
    maxWidth: 'inherit !important',
    border: `solid 1px ${colors.primary}`,
    borderRadius: '0.5vw',
    marginTop: '-0.5vw',
    marginBottom: '0vw',
    overflow: 'hidden',
    '& .MuiTableCell-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        width: '8vw',
      },
    },
    '& tr': {
      '& td, & th': {
        '@media (min-width: 1200px)': {
          fontSize: '1.1vw',
          lineHieght: '1.5vw',
        },
        '&:last-child': {
          borderRight: 0,
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        fontWeight: '600',
        paddingLeft: '2vw !important',
        textAlign: 'right',
        borderRadius: '0.2vw',
      },
      '& .MuiInputLabel-root': {
        fontsize: '0.9vw !important',
      },
    },
    '& .MuiSelect-select.MuiSelect-select': {
      textAlign: 'left !important',
    },
  },
  tableheadBg: {
    background: '#e4f5fd',
    borderRadius: '10px 10px 0 0',
    '& th': {
      fontWeight: '600',
    },
  },
  paymentStudent: {
    display: 'flex',
    height: '4vw',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
  paymentDialogAtom: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        maxWidth: '70vw !important',
      },
    },
    '& #contributionCause': {
      paddingRight: '1.5vw !important',
    },
  },
  paymentDialogAtom1: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        maxWidth: '70vw !important',
      },
    },
    '& #contributionCause': {
      paddingRight: '1.5vw !important',
    },
    '& .MuiGrid-root': {
      '@media (min-width: 1200px)': {
        maxWidth: 'none !important',
      },
    },
  },
  confirmCheck: {
    paddingLeft: '16px',
    '@media (min-width: 1200px)': {
      padding: '0 0.5vw',
      '& label': {
        margin: '0 0 0 8px',
      },
    },
    '& fieldset': {
      margin: '0 !important',
    },
  },
  lastNameError: {
    color: colors.red,
  },
  accordin: {
    fontFamily: fonts.fontType.roboto,
    '& .MuiAccordionSummary-root': {
      background: colors.accordionBarColor,
      marginTop: '1vw',
      borderRadius: '0.5vw',
    },
    '& .MuiGrid-root .MuiAccordionSummary-content': {
      margin: '0 !important',
      minHeight: '1vw !important',
      fontSize: '1vw !important',
    },
    '& .MuiIconButton-root': {
      padding: '0.7vw',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      minHeight: '1vw',
      margin: '1vw 0 !important',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '3.5vw',
    },
    '& .MuiAccordion-root': {
      boxShadow: 'none',
      border: 'none !important',
    },
    '& .MuiAccordion-root:before': {
      backgroundColor: colors.white,
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px 1vw 1vw',
    },
    '& svg': {
      [theme.breakpoints.down('md')]: {
      },
    },
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    '@media (min-width: 1200px)': {
      fontSize: '1.2vw',
    },
    textAlign: 'left',
    color: colors.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  removeTitle: {
    float: 'right',
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    paddingTop: '0.2vw',
  },
  siblingTitle: {
    width: '90%',
    float: 'left',
  },
  headerContainer: {
    padding: 0,
    display: 'block',
    width: '100%',
  },
  editParentCheckbox: {
    '@media (min-width: 1200px)': {
      paddingLeft: '0.5vw',
    },
    '& .MuiFormControl-root': {
      margin: '0',
      padding: '16px',
    },
  },
  editStudentBlock: {
    padding: '2vw 0 1vw 0',
    width: '101%',
    '& form': {
      paddingBottom: '1vw',
    },
    '& input#academicSchool': {
      '@media (min-width: 1200px)': {
        width: '95%',
      },
    },
  },
  primaryStudentConscentError: {
    marginbottom: -2,
    display: 'block',
  },
  errorText: {
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.8vw',
      marginLeft: '1vw',
      padding: '0.5vw 0.5vw',
      marginTop: '0.1vw',
    },
    fontFamily: fonts.fontType.roboto,
    display: 'block',
    marginTop: '0',
    textAlign: 'right',
  },
  errorTextPayment: {
    width: '20vw !important',
    marginBottom: '5px !important',
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    marginLeft: 10,
    display: 'block',
  },
  error: {
    width: '20vw !important',
    marginBottom: '5px !important',
    marginLeft: '20% !important',
  },
  footerBottom: {
    background: colors.footerBottomColor,
    height: 3,
    display: 'block',
    width: '100%',
    marginTop: '10px !important',
  },
  footerBottom2: {
    background: colors.footerBottomColor,
    height: 3,
    display: 'block',
    width: '100%',
    marginTop: '10px !important',
  },
  boxDiv: {
    borderRadius: 15,
    backgroundColor: colors.greyShade,
    padding: '0px 12px 0px 15px',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginTop: 15,
    marginBottom: 15,
    // marginLeft: '4.5vw',
    width: '100%',
    maxHeight: '350px !important',
  },
  titleTerms: {
    fontFamily: 'helvetica, sans-serif',
    color: colors.primary,
    fontSize: '15pt',
    marginBottom: 0,
    width: '100%',
  },
  list: {
    padding: 0,
    margin: 0,
    overflowY: 'auto',
    height: '200px !important',
    width: '100%',
  },
  acceptCheckbox: {
    marginTop: '1vw',
    marginBottom: '-2vw',
    marginLeft: '1vw',
    '& .MuiFormControlLabel-root': {
      width: 'max-content',
    },
    width: '100%',
  },
  gridContainer: {
    background: colors.white,
    width: '100%',
    boxShadow: 'none !important',
    padding: '0px !important',
  },
  moreRightSpace: {
    '@media (min-width: 1200px)': {
      marginLeft: 37,
      marginTop: -15,
    },
  },
  errorCheckBox: {
    marginTop: -50,
  },
  totalPaymentRow: {
    backgroundColor: colors.backgroundColor,
  },
  discount: {
    color: colors.green,
  },
}));

export default enrollStudentStyle;

export const customCss = {
  minWidth: '2vw',
  height: '3vw',
  padding: '1vw 1vw 1.2vw 1.2vw',
  borderRadius: '0.4vw !important',
  '& .MuiButtonRoot': {
    minWidth: '2vw !important',
  },
};
