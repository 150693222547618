/* eslint-disable radix */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Grid, TextField, Typography,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import * as Yup from 'yup';
import { DropdownHomeWork, Loader } from '../../../components/atoms';
import styles, { materialStyles } from './styles';
import useStudent from '../../../custom-hooks/useStudent';
import { getAllCourses } from '../../../store/actions/getStudent';
import { getAssignedLocations } from '../../../store/actions/getLocationCoordinator';
import useLocationCoordinator from '../../../custom-hooks/useLocationCoordinator';
import useStyles from '../../../custom-hooks/useStyles';
import { saveQuizSchema, getQuizSchema } from '../../../store/actions/quizSchema';
import { getCurrentYear, isValidSemesterYear } from '../../../utils/methods';
import { getQuarterDetails } from '../../../store/actions/academic-panel';
import RenderCategoryField from './category-fields';
import useQuizSchema from '../../../custom-hooks/useQuizSchema';

const SEMESTER_OPTIONS = [
  { id: 'S1', key: 'SEMESTER 1' },
  { id: 'S2', key: 'SEMESTER 2' },
];
const QUIZZES_OPTIONS_S1 = [
  { id: 'Quiz 1', key: 'Quiz 1' },
  { id: 'Quiz 2', key: 'Quiz 2' },
];
const QUIZZES_OPTIONS_S2 = [
  { id: 'Quiz 1', key: 'Quiz 3' },
  { id: 'Quiz 2', key: 'Quiz 4' },
];

const todayLimitStart = new Date();
const todayLimitEnd = new Date();
todayLimitStart.setHours(0, 0, 0);
todayLimitEnd.setHours(23, 59, 0);

const getCourseOptions = (courses) => courses.map((course) => ({
  id: course.id,
  name: course.name,
}));

const isValidDate = (value, date = null) => {
  const val = moment(value).format('yyyy-MM-DD');
  const now = date ? moment(date).format('yyyy-MM-DD') : moment().format('yyyy-MM-DD');
  if (moment(val).isBefore(moment(now))) {
    return false;
  }
  return true;
};

const restrictKeys = (ev, allowDecimal = false) => {
  const restrictedSymbols = ['+', '-', 'e', '*'];

  if (!allowDecimal) {
    restrictedSymbols.push('.');
  }

  if (restrictedSymbols.includes(ev.key)) {
    ev.preventDefault();
  }
};

const useGetCourseRows = (ques) => {
  const [rows, setRow] = useState([]);

  useEffect(() => {
    setRow([]);
    [...Array(ques)].map((_, i) => setRow((oldRows) => [...oldRows, i]));
  }, [ques]);

  return { rows, setRow };
};

function RenderFieldsGrid({
  courseOptions,
  academicYearOptions,
  setLoading,
  selectedCourse,
  setSelectedCourse,
}) {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [errorSchemaRemoved, setErrorSchemaRemoved] = useState('');
  const [errorMarks, setErrorMarks] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [updateSchema, setUpdateSchema] = useState(false);
  const [homeworkMarksCopy, sethomeworkMarksCopy] = useState('');
  const [validSemesterYear, setValidSemesterYear] = useState(true);
  const { schema, quarterDetails } = useQuizSchema();
  const currentAcademicYear = getCurrentYear()?.id;
  const [saveClicked, setSaveClicked] = useState(false);
  const [quizSchemaIdAux, setQuizSchemaIdAux] = useState('');
  const [quizOptions, setQuizOptions] = useState([]);

  const handleSave = (values) => {
    setLoading(true);

    const createQuizSchema = values?.quizSchemaMarks?.map((ele) => ({
      category: ele.category,
      marks: ele.marks,
      notes: ele.notes,
    }));
    const updateQuizSchema = values?.quizSchemaMarks?.map((ele) => ({
      category: ele.category || 'category',
      marks: ele.marks || 0,
      notes: ele.notes || 'notes',
      isActive: true,
      quizSchemaId: updateSchema,
    }));

    const payload = {
      courseId: values.courses,
      academicYear: academicYearOptions.find((year) => year.id === values.academicYear)?.name,
      maximumMarks: values.maxMarks,
      quarter: validSemesterYear ? values.semester : values.quarter,
      schemaLabel: values.schemaLabel,
      weightage: values.weightage,
      passingCriteria: 0,
      quiz: values.quiz,
      totalQuestions: values.totalQuestions,
      quizSchemaMarks: updateSchema ? updateQuizSchema : createQuizSchema,
      ...(updateSchema ? { quizSchemaId: updateSchema } : {}),
    };

    const loadFalse = () => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    setError('');
    setErrorMarks('');
    setErrorSchemaRemoved('');

    saveQuizSchema(payload, updateSchema, loadFalse, setError, setSuccessMsg, validSemesterYear, setErrorSchemaRemoved, quizSchemaIdAux, setQuizSchemaIdAux);
  };

  const validationSchema = Yup.object({
    schemaLabel: Yup.string(t('SCHEMA_LABEL_IS_REQUIRED'))
      .required(t('SCHEMA_LABEL_IS_REQUIRED')),
    weightage: Yup.number(t('WEIGHTAGE_IS_REQUIRED'))
      .required(t('WEIGHTAGE_IS_REQUIRED'))
      .max(100, t('INVALID_WEIGHTAGE')),
    totalQuestions: Yup.number(t('TOTAL_QUESTIONS_REQUIRED'))
      .required(t('TOTAL_QUESTIONS_REQUIRED'))
      .max(100, t('QUESTIONS_LESS_THAN_100')),
    maxMarks: Yup.number(t('MAX_MARKS_ARE_REQUIRED'))
      .required(t('MAX_MARKS_ARE_REQUIRED')),
    quizSchemaMarks: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().test('category required test', t('CATEGORY_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (homeworkMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        marks: Yup.number().test('marks required test', t('MARKS_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (homeworkMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        notes: Yup.string().test('notes required test', t('NOTES_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (homeworkMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      courses: '',
      academicYear: '',
      quarter: 'Q1',
      semester: 'S1',
      quiz: 'Quiz 1',
      schemaLabel: '',
      passingCriteria: '',
      weightage: '',
      totalQuestions: '',
      maxMarks: '',
      quizSchemaMarks: [],
    },
    validationSchema,
    onSubmit: handleSave,
  });

  useEffect(() => {
    if (homeworkMarksCopy?.length !== formik?.touched?.quizSchemaMarks?.length) {
      formik?.touched?.quizSchemaMarks?.pop();
    }
  }, [homeworkMarksCopy]);

  useEffect(() => {
    if (courseOptions && courseOptions.length) {
      formik.setTouched({}, false);
      formik.setFieldValue('courses', selectedCourse || courseOptions[0].id);
    }
  }, [courseOptions, selectedCourse]);

  useEffect(() => {
    setValidSemesterYear(isValidSemesterYear(formik.values.academicYear));
  }, [formik.values.academicYear]);

  useEffect(() => {
    if (academicYearOptions && academicYearOptions.length) {
      formik.setTouched({}, false);

      formik.setFieldValue('academicYear', currentAcademicYear);

      if (validSemesterYear) {
        formik.setFieldValue('semester', 'S1');
      } else {
        formik.setFieldValue('semester', 'S1');
      }
    }
  }, [academicYearOptions]);
  // ===================================

  const changeAcademicPanelMarks = (row, key, value) => {
    const clonedMarks = [...formik.values.quizSchemaMarks];

    if (key === 'category' || key === 'notes') {
      clonedMarks[row][key] = value;
    } else if (key === 'marks') {
      clonedMarks[row][key] = value;
    }
    formik.setFieldValue('quizSchemaMarks', clonedMarks);

    sethomeworkMarksCopy(clonedMarks);
  };

  // get/update/save schema ======================================================
  const dispatch = useDispatch();

  const refreshSchema = () => {
    if (!formik.values.courses || !formik.values.academicYear || !formik.values.semester || !formik.values.quiz) {
      return;
    }
    setLoading(true);
    const payload = {
      courseId: formik.values.courses,
      academicYear: academicYearOptions.find((year) => year.id === formik.values.academicYear)?.name,
      quarter: formik.values.semester,
      quiz: formik.values.quiz,
    };
    dispatch(getQuizSchema(payload, setLoading));

    formik.setTouched({}, false);
  };

  useEffect(() => {
    if (formik.values.semester === 'S2') {
      setQuizOptions(QUIZZES_OPTIONS_S2);
      formik.setFieldValue('quiz', 'Quiz 1');
    } else {
      setQuizOptions(QUIZZES_OPTIONS_S1);
      formik.setFieldValue('quiz', 'Quiz 1');
    }
  }, [formik.values.semester]);

  useEffect(() => {
    setSaveClicked(false);
    refreshSchema();
    setSuccessMsg('');
  }, [formik.values.courses, formik.values.semester, formik.values.quiz, formik.values.academicYear]);

  useEffect(() => {
    if (!formik.values.academicYear) {
      return;
    }

    formik.setTouched({}, false);

    dispatch(getQuarterDetails(academicYearOptions.find((year) => year.id === formik.values.academicYear)?.name));
  }, [formik.values.academicYear]);

  const prefillSchema = (data) => {
    if (!data?.length) {
      formik.setFieldValue('schemaLabel', '');
      formik.setFieldValue('passingCriteria', '');
      formik.setFieldValue('weightage', '');
      formik.setFieldValue('totalQuestions', '');
      formik.setFieldValue('maxMarks', '');
      formik.setFieldValue('quizSchemaMarks', []);
      sethomeworkMarksCopy([]);
      setUpdateSchema(false);
      setLoading(false);
      return;
    }

    const prefillData = data[0];

    formik.setFieldValue('schemaLabel', prefillData?.schemaLabel || '');
    formik.setFieldValue('passingCriteria', prefillData?.passingCriteria >= 0 ? prefillData?.passingCriteria : 0);
    formik.setFieldValue('weightage', (prefillData?.weightage >= 0 ? prefillData?.weightage : 0));
    formik.setFieldValue('totalQuestions', Array.isArray(prefillData?.quiz_schema_marks) && prefillData?.quiz_schema_marks.length
      ? prefillData?.quiz_schema_marks.length
      : 0);
    formik.setFieldValue('maxMarks', prefillData?.maximumMarks || 0);
    formik.setFieldValue('quizSchemaMarks', prefillData?.quiz_schema_marks || []);

    setTimeout(() => {
      formik.setFieldTouched('quizSchemaMarks', prefillData?.quiz_schema_marks.map(() => ({
        category: false,
        marks: false,
        notes: false,
      })));
    }, 1000);

    if (prefillData?.quiz_schema_marks?.length > 0) {
      setErrorSchemaRemoved('');
    } else {
      setErrorSchemaRemoved(validSemesterYear ? t('SEMESTER_SCHEMA_REMOVED') : t('QUARTER_SCHEMA_REMOVED'));
      setSuccessMsg('');
    }

    sethomeworkMarksCopy(prefillData?.quiz_schema_marks || []);
    setUpdateSchema(prefillData.id);
    setLoading(false);
  };

  useEffect(() => {
    if (schema === null) {
      return;
    }
    prefillSchema(schema);
  }, [schema]);

  // =============================================================================
  const deleteAcademiMarksEntry = (index) => {
    sethomeworkMarksCopy((oldMarks) => {
      const clonedMarks = [...oldMarks];
      clonedMarks.splice(index, 1);
      formik.setFieldValue('quizSchemaMarks', clonedMarks, false);
      return clonedMarks;
    });
  };

  const deletedRows = useMemo(() => {
    if (formik.values.quizSchemaMarks.length) {
      let count = 0;
      formik.values.quizSchemaMarks.forEach((ele) => {
        if (ele?.deletedAt) {
          count += 1;
        }
      });

      return count;
    }

    return null;
  }, [formik.values.quizSchemaMarks]);

  useEffect(() => {
    const marksLength = formik.values.quizSchemaMarks.length - deletedRows;

    if (!formik.values.totalQuestions && formik.values.totalQuestions !== 0) {
      return;
    }

    if (formik.values.totalQuestions < marksLength) {
      const countDiff = marksLength - formik.values.totalQuestions;

      for (let i = 1; i <= countDiff; i++) {
        deleteAcademiMarksEntry(marksLength - i);
      }
    }

    if (formik.values.totalQuestions > formik.values.quizSchemaMarks.length && formik.values.totalQuestions < 21) {
      const countDiff = formik.values.totalQuestions - formik.values.quizSchemaMarks.length;

      const newEntries = [];

      for (let i = 1; i <= countDiff; i++) {
        newEntries.push({
          category: '',
          marks: '',
          notes: '',
        });
      }

      const allEntries = [...formik.values.quizSchemaMarks, ...newEntries];

      formik.setFieldValue('quizSchemaMarks', allEntries);
      sethomeworkMarksCopy(allEntries);
    }
  }, [formik.values.totalQuestions]);

  const setTotalQuestions = (questions, index) => {
    formik.setFieldValue('totalQuestions', questions);
    deleteAcademiMarksEntry(index);
  };

  const calculateCourseTotal = () => {
    if (!formik.values.quizSchemaMarks.length) {
      return 0;
    }

    return formik.values.quizSchemaMarks.reduce((oldVal, currentEle) => (
      oldVal + (currentEle?.marks && !currentEle?.deletedAt ? parseFloat(currentEle.marks) : 0)
    ), 0);
  };

  const courseFieldTotal = useMemo(
    () => calculateCourseTotal() || 0,
    [formik.values.quizSchemaMarks, formik.values.totalQuestions],
  );

  const checkIfSchemeCanBeEdited = () => {
    let editable = false;
    if (quarterDetails) {
      const { academicYearQuarterDates } = quarterDetails;

      academicYearQuarterDates.forEach((quarterItem) => {
        if (quarterItem.quarter === formik.values.semester) {
          editable = isValidDate(quarterItem.endDate);
        }
      });
    }

    return editable;
  };

  const schemaCanBeEdited = useMemo(checkIfSchemeCanBeEdited, [quarterDetails, formik.values.semester, formik.values.quarter]);

  useEffect(() => {
    if (formik.values.semester === '') {
      setError('');
      setSuccessMsg('');
    } else if (!validSemesterYear) {
      setError(t('QUIZ_YEAR_ERROR'));
      setSuccessMsg('');
    } else if (!schemaCanBeEdited && schemaCanBeEdited !== null) {
      setError(t('QUIZ_SCHEMA_ERROR'));
      setSuccessMsg('');
    } else {
      setError('');
    }
  }, [schemaCanBeEdited, formik.values.semester, selectedCourse, validSemesterYear]);

  const dependenciesForMarksValidation = formik.values.quizSchemaMarks.map(
    (ele) => ele.marks,
  );

  useEffect(() => {
    /* eslint-disable no-else-return */
    const totalMarks = calculateCourseTotal();
    if ((formik.values.totalQuestions === 0 || formik.values.totalQuestions > 100) && (formik.values.maxMarks >= 1)) {
      setErrorMarks(t('TOTAL_QUESTIONS_ERROR'));
      setError('');
      setErrorSchemaRemoved('');
      setSuccessMsg('');
      return;
    }

    if (totalMarks === 0 || totalMarks === null || formik.values.maxMarks === '') {
      setErrorMarks('');
      return;
    }

    if (totalMarks !== formik.values.maxMarks) {
      setErrorMarks(t('MAX_MARKS_ERROR'));
      setError('');
      setErrorSchemaRemoved('');
      setSuccessMsg('');
    } else {
      setErrorMarks('');
    }
  }, [dependenciesForMarksValidation, formik.values.maxMarks]);
  const classes = useStyles(materialStyles)();

  useEffect(() => {
    const { totalQuestions } = formik.values;
    if (totalQuestions > 21) {
      formik.values.totalQuestions = 20;
    }
  }, [formik.values.totalQuestions]);

  return (
    <Box style={{ width: '100%', padding: '10px' }}>
      <FormikProvider value={formik}>
        <Grid container spacing={3}>
          <Grid item xs={6} lg={3} md={4} className={classes.formControl}>
            <DropdownHomeWork
              label={`${t('COURSE')}`}
              required
              id="courses"
              name="courses"
              labelId="courses"
              disabled={false}
              value={formik.values.courses}
              handleChange={(e) => {
                formik.handleChange(e);
                setSelectedCourse(e.target.value);
              }}
              onBlur={formik.handleBlur}
              options={courseOptions}
              customStyles={styles.dropDownCont}
              floatingLabel
              shrink
            />
          </Grid>
          <Grid item xs={6} lg={3} md={4} className={classes.formControl}>
            <DropdownHomeWork
              label={`${t('ACADEMICYEAR')}`}
              required
              id="academicYear"
              name="academicYear"
              labelId="academicYear"
              disabled={false}
              value={formik.values.academicYear}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={academicYearOptions}
              customStyles={styles.dropDownCont}
              floatingLabel
              shrink
            />
          </Grid>
          <Grid item xs={6} lg={3} md={4} className={classes.formControl}>
            <DropdownHomeWork
              label={`${t('SEMESTER')}`}
              required
              id="semester"
              name="semester"
              labelId="semester"
              disabled={false}
              value={formik.values.semester}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={SEMESTER_OPTIONS.map((sem) => ({
                id: sem.id,
                name: sem.key,
              }))}
              customStyles={styles.dropDownCont}
              shrink
            />
          </Grid>
          <Grid item xs={6} lg={3} md={4} className={classes.formControl}>
            {formik.values.academicYear.split('-')[0] <= '2023' ? (
              <DropdownHomeWork
                label={`${t('QUIZZES')}`}
                required
                id="quiz"
                name="quiz"
                labelId="quiz"
                disabled={false}
                value={formik.values.quiz}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={quizOptions.map((quiz) => ({
                  id: quiz.id,
                  name: quiz.key,
                }))}
                customStyles={styles.dropDownCont}
                shrink
              />
            ) : (
              <TextField
                label={`${t('QUIZZES')}`}
                required
                id="quiz"
                name="quiz"
                disabled
                value={formik.values.semester === 'S1' ? 'Quiz 1' : 'Quiz 2'}
                size="small"
                inputProps={{ min: '0', max: '100', style: styles.textFieldLabel }}
                InputLabelProps={{ style: styles.textFieldLabel }}
              />
            )}
          </Grid>
          <Grid item xs={6} lg={3} md={4} className={classes.formControl}>
            <TextField
              label={t('LABEL')}
              id="schemaLabel"
              labelId="schemaLabel"
              name="schemaLabel"
              required
              type="text"
              disabled={!schemaCanBeEdited}
              value={formik.values.schemaLabel}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              inputProps={{ style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
              helperText={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
            />
          </Grid>
          {/* row 2 */}
          <Grid item xs={6} lg={3} md={4} className={classes.formControl}>
            <TextField
              label={t('WEIGHTAGE')}
              id="weightage"
              name="weightage"
              labelId="weightage"
              required
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.weightage}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              onKeyPress={(e) => restrictKeys(e, true)}
              inputProps={{ min: '0', max: '100', style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.weightage ? formik.errors.weightage : undefined}
              helperText={formik.touched.weightage ? formik.errors.weightage : undefined}
            />
          </Grid>
          <Grid item xs={6} lg={3} md={4} className={classes.formControl}>
            <TextField
              label={t('TOTAL_QUESTIONS')}
              required
              id="totalQuestions"
              name="totalQuestions"
              labelId="totalQuestions"
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.totalQuestions < 21 ? formik.values.totalQuestions : 20}
              onKeyPress={restrictKeys}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              inputProps={{ min: '0', style: styles.textFieldLabel, max: '20' }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.totalQuestions ? formik.errors.totalQuestions : undefined}
              helperText={formik.touched.totalQuestions ? formik.errors.totalQuestions : undefined}
            />
          </Grid>
          <Grid item xs={6} lg={3} md={4} className={classes.formControl}>
            <TextField
              label={t('MAXIMUM_MARKS')}
              id="maxMarks"
              labelId="maxMarks"
              name="maxMarks"
              required
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.maxMarks}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              onKeyPress={restrictKeys}
              size="small"
              inputProps={{ min: '0', style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
              helperText={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
            />
          </Grid>
        </Grid>
        {error && (
          <Box style={styles.errorCont}>
            <Typography style={styles.errorText} variant="text">
              {error}
            </Typography>
          </Box>
        )}
        {errorMarks && (
          <Box style={styles.errorCont}>
            <Typography style={styles.errorText} variant="text">
              {errorMarks}
            </Typography>
          </Box>
        )}
        {successMsg && (
          <Box style={styles.successCont}>
            <Typography style={styles.successText} variant="text">
              {successMsg}
            </Typography>
          </Box>
        )}

        {errorSchemaRemoved && (
          <Box style={styles.errorCont}>
            <Typography style={styles.errorText} variant="text">
              {saveClicked === true ? errorSchemaRemoved : ''}
            </Typography>
          </Box>
        )}
        <RenderCategoryField
          questions={formik.values.totalQuestions || 0}
          {...{ setTotalQuestions, changeAcademicPanelMarks }}
          handleSave={formik.handleSubmit}
          refreshSchema={refreshSchema}
          courseFieldData={formik.values.quizSchemaMarks}
          courseFieldErrors={formik.errors.quizSchemaMarks}
          courseFieldTouched={formik.touched.quizSchemaMarks}
          showSaveBtn={courseFieldTotal === formik.values.maxMarks && schemaCanBeEdited}
          schemaCanBeEdited={schemaCanBeEdited}
          useGetCourseRows={useGetCourseRows}
          styles={styles}
          restrictKeys={restrictKeys}
          setSuccessMsg={setSuccessMsg}
          setError={setError}
          setSaveClicked={setSaveClicked}
        />
      </FormikProvider>
    </Box>
  );
}

function QuizSchema() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedCourse, setSelectedCourse] = useState('');
  const [loading, setLoading] = useState(true);
  const classes = useStyles(materialStyles)();

  useEffect(() => {
    setLoading(true);
    const loadFalse = () => setLoading(false);
    dispatch(getAllCourses());
    dispatch(getAssignedLocations(loadFalse));
  }, []);

  const { courses } = useStudent();
  const { assignedYears } = useLocationCoordinator();

  return (
    <>
      <Box padding={3}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              {t('QUIZ_SCHEMA')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.contentCont}>
        <RenderFieldsGrid
          courseOptions={getCourseOptions(courses)}
          academicYearOptions={assignedYears}
          {...{ setLoading, selectedCourse, setSelectedCourse }}
        />
        {loading && (
          <Grid>
            <Loader message={t('LOADING')} />
          </Grid>
        )}
      </Box>
    </>
  );
}

export default QuizSchema;
