import { colors, fonts } from '../../../theme';

const style = (() => ({
  phoneInput: {
    background: 'white',
    borderRadius: '4px',
    width: '100%',
    border: '0.1vw solid',
    '@media (max-width: 1199px)': {
      marginLeft: 0,

    },
    '@media (max-width: 899px) ': {

    },
    '@media (min-width: 1200px)': {
      background: 'white',
      borderRadius: '0.4vw',
      marginBottom: '0.2vw',
      width: '45vw',
      marginTop: '0.25vw !important',
    },
    '&:focus-visible': {
      outline: 'none !important',
    },
    '&:focus': {
      border: '0.15vw solid yellow',
      outline: 'none',
    },
    '&.PhoneInput--focus, &:focus-within': {
      border: '0.2vw solid yellow',
      outline: 'none',
    },
    '& .PhoneInputInput': {
      borderRadius: '3px',
      padding: '0.8vw',
      width: '100%',
      color: `${colors.black} !important`,
      border: 'none',
      '@media (max-width: 1199px)': {
        padding: '20px 14px',
        fontSize: '1rem',
      },
      '@media (min-width: 1200px)': {
        padding: '0 0 0 0.8vw',
        borderRadius: '0.4vw',
        height: '2.5vw',
        marginTop: '0.25vw !important',
      },
      '&:hover, &:focus': {

        outline: 'none',
        borderColor: 'blue',
      },
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw !important',
        },
        color: `${colors.placeHolderColor}!important`,
        letterSpacing: 1,
      },
    },
    '& .PhoneInputCountry': {
      marginRight: 0,
      paddingLeft: 5,
      display: 'none',
    },
    '& .PhoneInputCountrySelect': {

    },
    '& .PhoneInputCountryIcon': {
      border: 4,
      marginLeft: 10,
    },
    '& input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
      },
      color: '#696969 !important',
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '14px !important',
        color: `${colors.placeHolderColor} !important`,
        letterSpacing: 1,
      },
    },
    '& input:disabled': {
      backgroundColor: colors.disabledField,
    },
  },
  inputStyle: {
    borderColor: colors.primary,
    outline: 'none',
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none !important',
    },
  },
  errorBorder: {
    border: `0.1vw solid ${colors.errorText} !important`,
    '&:focus-within, &.PhoneInput--focus': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
  },
  borderClass: {
    '&.react-tel-input .form-control:focus': {
      outline: 'none',
    },
  },
  customClass1: {
    border: `0.1vw solid ${colors.primary}`,
    background: 'white',
    borderRadius: '4px',
    '@media (max-width: 1199px)': {
      marginLeft: 0,

    },
    '@media (max-width: 899px) ': {

    },
    '@media (min-width: 1200px)': {
      background: 'white',
      borderRadius: '0.3vw',
    },
    '&:focus-visible': {
      outline: 'none !important',
    },
    '&:focus': {
      border: '0.15vw solid yellow',
      outline: 'none',
    },
    '&.PhoneInput--focus, &:focus-within': {
      border: '0.2vw solid yellow',
      outline: 'none',
      '@media (min-width: 1200px)': {
        border: '0.15vw solid yellow',
        outline: 'none',
      },
    },
    '& .PhoneInputInput': {
      '&.react-tel-input .form-control:focus': {
        border: '0.2vw solid yellow',
        outline: 'none',
        boxShadow: '0px 0px 0px 1px yellow !important',
      },
      borderRadius: '0.6vw !important',
      width: '100%',
      color: `${colors.black} !important`,
      border: 'none',
      '@media (max-width: 1199px)': {
        padding: '16.5px 14px',
        fontSize: '1rem',
        height: '1.8vw !important',
      },
      '@media (min-width: 1200px)': {
        padding: '0 0 0 0.8vw',
        height: '2.5vw !important',
      },
      '@media (max-width: 899px)': {
        padding: '16.5px 14px',
        fontSize: '1rem',
        height: '2.5vw !important',
      },
      '&:focus-visible': {
        border: '0.2vw solid yellow',
        outline: 'none',
      },
      '&:hover, &:focus': {
        border: '0.2vw solid yellow',
        outline: 'none',
      },
      '&.Mui-focused fieldset': {
        border: '0.2vw solid yellow',
        outline: 'none',
      },
      '&:hover fieldset': {
        border: '0.2vw solid yellow',
        outline: 'none',
      },
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw !important',
        },
        color: `${colors.placeHolderColor}!important`,
        letterSpacing: 1,
      },
    },
    '& .PhoneInputCountry': {
      marginRight: 0,
      paddingLeft: 5,
      display: 'none',
    },
    '& .PhoneInputCountrySelect': {

    },
    '& .PhoneInputCountryIcon': {
      border: 4,
      marginLeft: 10,
    },
    '& input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
      },
      color: 'yellow !important',
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '14px !important',
        color: `${colors.placeHolderColor} !important`,
        letterSpacing: 1,
      },
    },
    '& input:disabled': {
      backgroundColor: colors.disabledField,
    },
  },
}));

export default style;
