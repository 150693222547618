/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  GoogleMap, Marker, DirectionsRenderer, InfoWindow,
} from '@react-google-maps/api';
import manabadiLogoIcon from '../../assets/icons/manabadi-logo-icon.ico';

function MapContainer({
  originLatLng, destinationLatLng, isSetMap, data, setDistance, setDistanceMatrix,
}) {
  const [currentPosition] = useState(originLatLng || {});
  const [directions, setDirections] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const destination = destinationLatLng;
  const origin = originLatLng;

  const mapStyles = () => {
    return {
      height: '100vh',
      width: '100%',
    };
  };
  // function that is calling the directions service
  const changeDirection = (origin, destination) => {
    if (!directionsService) {
      return;
    }
    directionsService.route(
      {
        origin: { lat: Number(origin.lat), lng: Number(origin.lng) },
        destination: { lat: Number(destination.lat), lng: Number(destination.lng) },
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        optimizeWaypoints: true,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
        // changing the state of directions to the result of direction service
          setDirections(result);
          setDistance(result.routes[0].legs[0].distance.text);
        } else {
          setDirections(null);
          setDistance(null);
        }
      },
    );
  };

  useEffect(() => {
    directionsService && changeDirection(originLatLng, destinationLatLng);
  }, [originLatLng.lat, destinationLatLng.lat, originLatLng.lng, destinationLatLng.lng]);

  const onMapLoad = () => {
    const ds = new google.maps.DirectionsService();
    const service = new google.maps.DistanceMatrixService();
    setDirectionsService(ds);
    setDistanceMatrix(service);
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    // load default origin and destination
    changeDirection(origin, destination);
  }, [directionsService]);

  return (
    <GoogleMap
      id="example-map"
      mapContainerStyle={mapStyles()}
      zoom={13}
      onLoad={(map) => onMapLoad(map)}
      center={currentPosition}
    >
      {isSetMap && data.map((lMap) => {
        return (
          <Marker
            key={lMap?.id}
            icon={manabadiLogoIcon}
            position={{ lat: lMap?.lat, lng: lMap?.lng }}
            onClick={() => handleActiveMarker(lMap?.id)}
          >
            {activeMarker === lMap?.id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>
                  {lMap?.name}
                  <br />
                  {lMap?.address}
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        );
      })}
      {directions !== null && (
      <DirectionsRenderer directions={directions} />
      )}
    </GoogleMap>
  );
}

export default MapContainer;
