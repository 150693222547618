import makeStyles from '@mui/styles/makeStyles';
import {
  colors,
  fonts,
} from '../../../../theme';

const style = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        maxHeight: '90vh',
        maxWidth: '65vw',
        minWidth: '40vw',
        borderRadius: '20px',
      },
      '@media (max-width: 1199px)': {
        borderRadius: 8,
        width: '100%',
        maxWidth: '75%',
        transform: 'translateX(-8%)',
      },
      margin: 0,
      boxShadow: 'none',
      boxSizing: 'border-box',
      paddingBottom: 20,
      '& .MuiFormControl-root': {
        marginTop: '0',
        width: '100%',
        '@media (min-width: 1200px)': {
          borderRadius: 8,
          width: '80%',
        },
      },
      '& .MuiOutlinedInput-input': {
        width: '100%',
        borderRadius: '0.5vw',
      },
    },
    '& .MuiDialogTitle-root': {
      color: colors.primary,
      padding: '.75vw 1vw .75vw 2vw',
      marginBottom: 8,
      fontFamily: fonts.fontType.roboto,
      backgroundColor: colors.secondary,
      '@media (min-width: 1200px)': {
        fontSize: '1vw',
      },
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    '& .MuiDialogContent-root': {
      '@media (min-width: 1200px)': {
        padding: '1vw 2vw 10px',
        fontSize: '0.9vw',
        overflowX: 'hidden !important',
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: 40,
      },
      '@media (max-width: 499px)': {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      '& .MuiGrid-root': {
        '@media (min-width: 1200px)': {
          maxWidth: '60vw',
        },
      },
    },
    '& .MuiButton-textPrimary': {
      color: `${colors.white} !important`,
      '@media (min-width: 1200px)': {
        minWidth: '15vw !important',
        height: '2.7vw',
        borderRadius: '0.4vw !important',
        fontSize: '1vw',
      },
      background: `${colors.newBGColor} !important`,
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.7vw',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: colors.scrollBarColor,
        borderRadius: '0.4vw',
      },
    },
  },
  closeImg: {
    cursor: 'pointer',
    float: 'right',
    color: colors.black,
    width: '1.5vw !important',
    height: '1.5vw !important',
    padding: '0 !important',
    [theme.breakpoints.down('md')]: {
      width: '18px !important',
      height: '18px !important',
    },
    '& .MuiSvgIcon-root': {
      width: '1vw',
      height: '1vw',
    },
  },
  swapCourseDialog: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        minWidth: '55vw !important',
        maxWidth: '65vw !important',
      },
      overflowX: 'hidden !important',
    },
    '& .MuiDialogContent-root .MuiGrid-root': {
      '@media (min-width: 1200px)': {
        width: '55vw',
      },
    },
    '& #acedemicYear': {
      '@media (min-width: 1200px)': {
        width: '22vw',
      },
    },
    '& #changeLogs': {
      '@media (min-width: 1200px)': {
        width: '47.2vw',
      },
    },
  },
  performTextField: {
    '& .Mui-error': {
      color: colors.errorText,
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
      },
      marginLeft: 0,
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '0.9vw',
        color: colors.placeHolderColor,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '0.9vw',
        color: colors.primary,
      },
      color: colors.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiInputLabel-root': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw !important',
        },
        color: `${colors.placeHolderColor} !important`,
      },
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${colors.placeHolderColor} !important`,
      },
      '& input': {
        '@media (min-width: 1200px)': {
          fontSize: '1.1vw',
          color: colors.black,
        },
        '&.Mui-disabled': {
          '@media (min-width: 1200px)': {
            fontSize: '1.1vw',
            padding: '0.5vw',
          },
          backgroundColor: colors.backgroundGrey,
          color: colors.primary,
          WebkitTextFillColor: colors.primary,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
      },
      '& .MuiFormHelperText-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
        },
        marginLeft: 0,
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
      },
    },
  },
  adminAssignScore: {
    '& .MuiOutlinedInput-root': {
      '& input.Mui-disabled': {
        color: '#104F96',
        backgroundColor: '#fff',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
        padding: '16.5px 14px',
      },
    },
  },
  switchSection: {
    textAlign: 'center',
    '& .MuiTypography-h5': {
      fontSize: '1vw',
    },
  },
  switchUi: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    '& p': {
      margin: '0 8px !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px !important',
      },
    },
    '& span': {
      marginTop: '0 !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px !important',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked+.MuiSwitch-track': {
        background: colors.lightGreen,
      },
    },
    '& .MuiSwitch-thumb': {
      width: 10,
      height: 10,
    },
  },
  setGreen: {
    color: colors.lightGreen,
  },
  setRed: {
    color: colors.errorText,
  },
  dropdownField: {
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, -0.96vw) !important',
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '0.9vw',
        backgroundColor: colors.white,
        color: colors.placeHolderColor,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, -0.5vw)',
        fontSize: '0.7vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: colors.primary,
      },
    },
  },
  performTextField1: {
    '& .Mui-error': {
      color: colors.errorText,
      fontFamily: `${fonts.fontType.roboto} !important`,
      letterSpacing: 'inherit',
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
      },
      marginLeft: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      '@media (min-width: 1200px)': {
        color: 'inherit !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.9vw)',
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '0.9vw',
        backgroundColor: colors.white,
        color: colors.placeHolderColor,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, -0.5vw)',
        fontSize: '0.7vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: colors.primary,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.7vw)',
        fontSize: '0.9vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '0.7vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: colors.primary,
      },
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiInputLabel-root': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw !important',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
        color: `${colors.placeHolderColor} !important`,
      },
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${colors.placeHolderColor} !important`,
      },
      '& input': {
        color: colors.black,
        '@media (min-width: 1200px)': {
          paddingLeft: '1vw',
          paddingRight: '1vw',
          fontSize: '0.9vw',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
        '&.Mui-disabled': {
          '@media (min-width: 1200px)': {
            padding: '0.65vw',
          },
          backgroundColor: colors.backgroundGrey,
          color: colors.primary,
          WebkitTextFillColor: colors.primary,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
        borderRadius: '0.4vw',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& .MuiFormHelperText-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
        marginLeft: 0,
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
    },
    '& fieldset': {
      '& legend span': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  textStyle: {
    paddingRight: '10px',
    paddingLeft: '0 !important',
    '& .MuiOutlinedInput-input': {
      width: '100%',
      height: '0vw !important',
      fontSize: '0.9vw',
      transform: 'translate(0vw, -0.1vw)',
      padding: '0.7vw',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.1vw solid ${colors.primary}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.2vw solid ${colors.primary}`,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `0.1vw solid ${colors.errorText}`,
    },
    '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.2vw solid ${colors.errorText}`,
    },
  },
  addStyleHead: {
    borderBottom: 'none',
    '& p': {
      color: 'hsl(212deg 81% 33%)',
      fontSize: '0.9vw',
      textAlign: 'left',
      fontWeight: 'bold',
    },
  },
  alignHorCenter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    '& p': {
      marginBottom: 0,
      fontSize: '0.9vw',
    },
  },
  marksDropDown: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9vw !important',
    '& .MuiFormControl-root': {
      width: '100px !important',
      marginRight: '10px !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.1vw solid ${colors.primary}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.2vw solid ${colors.primary}`,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `0.1vw solid ${colors.errorText}`,
    },
    '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.2vw solid ${colors.errorText}`,
    },
  },
  marksTotal: {
    display: 'flex',
    '& span': {
      width: '11.5vw !important',
      fontSize: '18px',
      maxWidth: '13vw !important',
      minWidth: '13vw !important',
      marginRight: '2.5vw',
      paddingTop: '1vw',
      paddingLeft: '2vw',
      '@media (max-width: 1450px)': {
        minWidth: '13.2vw !important',
      },
      '@media (max-width: 1300px)': {
        maxWidth: '13.5vw !important',
        minWidth: '13.5vw !important',
      },
      '@media (max-width: 1250px)': {
        maxWidth: '15.5vw !important',
        minWidth: '14vw !important',
      },
    },
    '& .MuiInputBase-formControl': {
      width: '47% !important',
      '& input': {
        padding: '0.7vw',
        '@media (max-width: 1300px)': {
          padding: '0.4vw',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      '& legend': {
        maxWidth: '48px',
      },
    },
  },
  secButtonNew: {
    marginRight: '20px',
    marginTop: '10px',
  },
  tableAssgin: {
    height: '200px !important',
  },
  changeGridLayout: {
    minWidth: '800px',
    minHeight: '100px',
    '& .MuiFormControl-root': {
      width: '100% !important',
      marginLeft: '0',
      '& .MuiInputLabel-root': {
        fontSize: '0.9vw',
        lineHeight: '1vw !important',
        transform: 'translate(0.7vw, 0.9vw)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(1vw, -0.5vw)',
          '@media (max-width: 1200px)': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          fontSize: '0.7vw',
          color: '#104F96',
        },
      },
    },
    '& .MuiGrid-root.MuiGrid-container': {
      maxWidth: 'inherit !important',
      paddingLeft: '1vw',
    },
    '& .MuiGrid-root.MuiGrid-grid-md-3, & .MuiGrid-root.MuiGrid-grid-xs-3': {
      maxWidth: '25% !important',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        height: '1.2vw',
        fontSize: '0.9vw',
        transform: 'translate(0vw, -0.1vw)',
        padding: '0.7vw',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& input.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
        backgroundColor: '#fff',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  grayLine: {
    border: '1px solid #cecece',
    borderLeft: 'unset',
    borderRight: 'unset',
    paddingBottom: '0.5vw',
    marginTop: '0',
  },
  totalMarksInput: {
    '&.MuiFormControl-root.MuiTextField-root': {
      width: '83% !important',
    },
  },
}));

export default style;
