/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */

import Constant from '../constant';
import {
  getAllUserService,
  getUserRolesService,
  updateUserInfoService,
  addAddUserService,
  getAllUserServicePaginated,
  getAllFilterUserManagerService,
  getAllEmailUsersService,
  getAssignedUserRolesServices,
} from '../services/auth';

export const getUsers = (setLoading) => (dispatch) => {
  getAllUserService()
    .then((response) => {
      setLoading(false);
      dispatch({
        type: Constant.GET_ALL_USER,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getAllEmailUsers = () => (dispatch) => {
  getAllEmailUsersService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_EMAIL_USERS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getAssignedUserRoles = () => async () => {
  try {
    const res = await getAssignedUserRolesServices();
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addUser = (payload, refreshList, setLoading) => async () => {
  try {
    const res = await addAddUserService(payload);
    refreshList();
    return Promise.resolve(res);
  } catch (err) {
    setLoading(false);
    return Promise.reject(err);
  }
};

export const updateUserInfo = (userId, payload, refreshList, setLoading) => async () => {
  try {
    const res = await updateUserInfoService(userId, payload);
    refreshList();
    return Promise.resolve(res);
  } catch (err) {
    setLoading(false);
    return Promise.reject(err);
  }
};

export const getUserRoles = () => (dispatch) => {
  getUserRolesService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_USER_ROLES,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getAllFilteredUsers = (pageNumber, pageLimit, nextPage, setLoading, students, setIsFetching, body = {}) => (dispatch) => {
  getAllUserServicePaginated(pageNumber, pageLimit, body)
    .then((response) => {
      setIsFetching(false);
      setLoading ? setLoading(false) : null;
      nextPage ? nextPage(response, pageNumber === 1 ? 100 : students + Number(response?.data?.length), pageNumber) : null;
      if (pageNumber === 1) {
        dispatch({
          type: Constant.GET_ALL_USER,
          payload: response?.data,
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: response?.count,
        });
      } else if (pageNumber > 1) {
        dispatch({
          type: Constant.GET_PAGINATED_USERS,
          payload: response?.data,
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: response?.count,
        });
      } else {
        dispatch({
          type: Constant.GET_ALL_USER,
          payload: [],
        });
        dispatch({
          type: Constant.GET_PAGINATED_USERS,
          payload: [],
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: 0,
        });
      }
    })
    .catch((error) => {
      console.log('error :', error);
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const getAllFilteredUserManager = (setLoadingFilters, pageNumber, pageLimit, nextPage, setLoading, students, setIsFetching, body = {}) => (dispatch) => {
  getAllUserServicePaginated(pageNumber, pageLimit, body)
    .then((response) => {
      setLoadingFilters(false);
      setIsFetching(false);
      setLoading ? setLoading(false) : null;
      nextPage ? nextPage(response, pageNumber === 1 ? 100 : students + Number(response?.data?.length)) : null;
      if (pageNumber === 1) {
        dispatch({
          type: Constant.GET_ALL_USER,
          payload: response?.data,
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: response?.count,
        });
      } else if (pageNumber > 1) {
        dispatch({
          type: Constant.GET_PAGINATED_USERS,
          payload: response?.data,
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: response?.count,
        });
      } else {
        dispatch({
          type: Constant.GET_ALL_USER,
          payload: [],
        });
        dispatch({
          type: Constant.GET_PAGINATED_USERS,
          payload: [],
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: 0,
        });
      }
      dispatch({
        type: Constant.SET_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      console.log('error :', error);
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const getAllFilterForUserManager = (data) => (dispatch) => {
  getAllFilterUserManagerService(data)
    .then((response) => {
      if (!data) {
        dispatch({
          type: Constant.GET_ALL_USER_FILTERS,
          payload: response.data,
        });
        return;
      }
      dispatch({
        type: Constant.GET_USER_FILTERS,
        payload: response.data,
      });
    });
};
export default {
  getUsers,
  getUserRoles,
  updateUserInfo,
  addUser,
};
