/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Loader from '../../../components/atoms/loader';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import DialogAtom from '../../../components/atoms/dialog';
import ClassroomList from '../classroom-list';
import AddTeacher from '../add-teacher';
import {
  getAllTeachers,
  getAssignedLocations,
  getClassrooms,
} from '../../../store/actions/getLocationCoordinator';
import { Buttons } from '../../../constant';
import { getAllCourses } from '../../../store/actions/getStudent';
import ButtonAtom from '../../../components/atoms/button';
import { colors } from '../../../theme';
import DialogAtomSheeet from './dialogSheet';

const useStylesSheet = makeStyles(() => ({
  studentBox: {
    maxWidth: 'unset !important',
    '& .MuiGrid-item': {
      maxWidth: 'unset !important',
      textAlign: 'right !important',
    },
  },
  alertprimary: {
    height: '3vw',
    display: 'flex',
    color: colors.green,
  },
}));

export default function Classes() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [classroomListPayload, setClassroomListPayload] = useState(false);

  const classes = useStyles(styles)();

  const { t } = useTranslation();
  const [addTeacherInfo, setAddTeacherInfo] = useState(0);
  const [isAddTeacher, setAddTeacher] = useState(false);
  const [showTokenAdvice, setShowTokenAdvice] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const classesSheet = useStylesSheet();

  useEffect(() => {
    const loadRefreshData = () => {
      setLoading(false);
    };
    setLoading(true);
    dispatch(getAssignedLocations(loadRefreshData));
    dispatch(getAllTeachers());
    dispatch(getAllCourses());
    setLoading(false);
  }, []);
  const refreshList = (payload) => {
    setLoading(true);
    const loadRefreshData = () => {
      setLoading(false);
    };
    dispatch(getClassrooms(payload, loadRefreshData));
  };

  const onAddTeacherClick = (courseData) => {
    const {
      class_teachers,
      course, section, googleClassId,
      classRoomId,
    } = courseData;
    const teachers = class_teachers;
    const formattedTeachers = teachers?.map((i) => ({
      checked: i?.is_primary || false,
      value: i?.user?.first_name ? `${i?.user?.first_name} ${i?.user?.last_name} ` : '',
      email: i?.user?.manabadi_email || '',
      key: i?.user?.id || '',
      id: i?.user?.id || '',
    }));
    const classObj = {
      teachers: formattedTeachers,
      course: course?.name,
      googleClassId,
      classRoomId,
      section,
    };
    setAddTeacherInfo(classObj);
  };

  const missingToken = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('TOKEN_EXPIRED')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}>
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => {
            setShowTokenAdvice(false);
            setLoading(false);
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderOkContent = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('STUDENT_CSV_EXPORTED_SUCCESSFULLY')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}>
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => {
            setOpenSheet(false);
            setLoading(false);
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('CLASSES')}
          </Typography>
        </Grid>
      </Grid>
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
      <Grid container className={classes.classRoomContainer}>
        <Grid container className={classes.classRoomList}>
          <ClassroomList
            setAddTeacher={() => setAddTeacher(true)}
            onAddTeacherClick={onAddTeacherClick}
            refreshList={refreshList}
            setClassroomListPayload={setClassroomListPayload}
            setLoading={setLoading}
            loading={loading}
            setShowTokenAdvice={setShowTokenAdvice}
            setOpenSheet={setOpenSheet}
          />
        </Grid>
      </Grid>

      <DialogAtom
        customClass={classes.addTeacherDialog}
        isOpen={isAddTeacher}
        dialogHeading={t('ADD_TEACHER')}
        content={(
          <AddTeacher
            classes={classes}
            addTeacherInfo={addTeacherInfo}
            setAddTeacherInfo={setAddTeacherInfo}
            setDialogOpen={setAddTeacher}
            classroomListPayload={classroomListPayload}
          />
        )}
        secHandle={() => setAddTeacher(false)}
      />
      <DialogAtomSheeet
        isOpen={showTokenAdvice}
        dialogHeading={t('TOKEN_EXPIRED_TITLE')}
        content={missingToken()}
        secHandle={() => setShowTokenAdvice(false)}
      />
      <DialogAtomSheeet
        isOpen={openSheet}
        dialogHeading={t('STUDENT_EXPORT')}
        content={renderOkContent()}
        secHandle={() => { setOpenSheet(false); setLoading(false); }}
      />
    </Box>
  );
}
