/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
} from 'react-router-dom';

import Loader from '../../../components/atoms/loader';
import useStyles from '../../../custom-hooks/useStyles';
import { getCurrentYear } from '../../../utils/methods';

import styles from './style';
import {
  getAssignedLocations,
} from '../../../store/actions/getLocationCoordinator';
import {
  getAnnouncementById,
  getAnnouncementListByUserID
} from '../../../store/actions/getEmail';

import AnnouncementsTable from './announcement-table';
import AnnouncementDialog from './announcement-dialog/announcementDialog';
import { NavigateRoutes } from '../../../constant';
import Constant from '../../../store/constant';
import { Button } from '../../../components/atoms';
import { setLocalStorage } from '../../../utils/localStorageMethod';
import commonStyle from '../../../utils/commonClasses';
import useDataTableParams from '../../../custom-hooks/useDataTableParams';

export default function RCAnnouncements() {
  const {
    nextPage,
  } = useDataTableParams();
  const commonClasses = useStyles(commonStyle)();
  const [loading, setLoading] = useState(true);
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const locationCoordinatorData = useSelector((state) => state?.getLocationCoordinator);
  const selectedYearRedux = locationCoordinatorData?.selectedYear;
  const assignedLocations = locationCoordinatorData?.assignedLocations;
  const assignedYears = locationCoordinatorData?.assignedYears;
  const announcements = locationCoordinatorData?.announcements;
  const selectedLocationRedux = locationCoordinatorData?.selectedLocations;
  const [selectedLocationName, setSelectedLocationName] = useState('');
  const navigate = useNavigate();
  const [showAnnouncementDialog, setShowAnnouncementDialog] = useState(false);
  const { t } = useTranslation();
  const validationSchema = Yup.object({
  });
  const [totalRows, setTotalRows] = useState(0);
  const [pagenum, setPagenum] = useState(1);
  const [manabadiEmail, setManabadiEmail] = useState();

  localStorage.removeItem('mailFilter');
  useEffect(() => {
    const loadFalse = () => setLoading(false);
    dispatch(getAssignedLocations(loadFalse));
  }, []);

  const formik = useFormik({
    initialValues: {
      year: '',
      location: '',
    },
    validationSchema,
    onSubmit: () => {
    },
  });

  const viewAnnouncement = (row) => {
    if (row && row?.id) {
      dispatch(getAnnouncementById(row?.id));
    }
    setShowAnnouncementDialog(true);
  };

  useEffect(() => {
    setTotalRows(announcements?.length);
  }, [announcements]);

  useEffect(() => {
    setLoading(true);
    if (selectedLocationRedux?.value) {
      formik.setFieldValue('location', selectedLocationRedux?.value);
    } else {
      formik.setFieldValue('location', assignedLocations[0]?.id);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setSelectedLocationName(assignedLocations[0]?.shortName);
  }, [assignedLocations, selectedLocationRedux]);

  useEffect(() => {
    const currentYear = getCurrentYear()?.id;
    const selectedYear = assignedYears?.filter((opt) => opt?.id === currentYear.toString());
    if (selectedYear) {
      if (selectedYearRedux?.id) {
        formik.setFieldValue('year', selectedYearRedux?.id);
      } else {
        formik.setFieldValue('year', selectedYear[0]?.id);
      }
    }
  }, [assignedYears, selectedYearRedux]);

  useEffect(() => {
    if (formik && formik.values && formik.values.location && formik.values.year) {
      const selectedLocation = assignedLocations.filter((loc) => loc.id === formik?.values?.location);
      setSelectedLocationName(selectedLocation[0]?.shortName);

      const payload = {
        manabadiEmail: ''
      };
      if (JSON.parse(localStorage.getItem('impersonateUser'))){
        payload.manabadiEmail = JSON.parse(localStorage.getItem('impersonateUser')).state.email;
        setManabadiEmail(payload.manabadiEmail);
      } else {
        payload.manabadiEmail = localStorage.getItem('userEmailId');
        setManabadiEmail(payload.manabadiEmail);
      }
      setPagenum(1);
      dispatch(getAnnouncementListByUserID(payload, 1, 100, nextPage));
    }
  }, [formik?.values?.year, formik?.values?.location]);

  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('ANNOUNCEMENTS')}
          </Typography>
        </Grid>
        <Button
          id="submit"
          className={commonClasses.activeButton}
          onClick={() => {
            dispatch({ type: Constant.RECIPIENTS, payload: [] });
            dispatch({ type: Constant.SET_MAIL_SUBJECT, payload: '' });
            dispatch({ type: Constant.SET_MAIL_BODY, payload: '' });
            dispatch({ type: Constant.MAIL_FILTER, payload: 'Role - User groups' });
            setLocalStorage('showLocationFilterRecipients', false);
            setLocalStorage('showLocationAnnouncementsRecipients', true);
            setLocalStorage('showSelectAllinEmail', false);
            setLocalStorage('moduleReturn', 'RCAnnouncements');
            setLocalStorage('selectedLocationName', null);
            setLocalStorage('selectedYear', null);
            navigate(NavigateRoutes.ADMIN_EMAIL);
          }}
          name={t('NEW_ANNOUNEMENT')}
          />
      </Grid>
      <Grid container className={classes.announcementsContainer}>
        <Grid container className={classes.announcementsList}>
          <Grid className={classes.tableView}>
            <AnnouncementsTable
              key={announcements}
              tableData={announcements}
              onAnnouncementView={(row) => {
                viewAnnouncement(row);
              }}
              pageNext={nextPage}
              pagenum={pagenum}
              setPagenum={setPagenum}
              manabadiEmail={manabadiEmail}
              totalRows={totalRows}
            />
          </Grid>
        </Grid>
      </Grid>
      <AnnouncementDialog
        isOpen={showAnnouncementDialog}
        onDialogClose={() => setShowAnnouncementDialog(false)}
      />
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
    </Box>
  );

};