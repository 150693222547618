/* eslint-disable no-confusing-arrow */
/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { red } from '@mui/material/colors';
import moment from 'moment';
import useStyles from '../../../../../custom-hooks/useStyles';
import styles from './styles';

export default function AssignmentStatusTable({
  rows,
  onStatusView,
  onDeleteAssignment,
  selectedAssignments,
  setSelectedAssignments,
  setOneChecked,
}) {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const [sortedRows, setSortedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    let aux = false;
    aux = checkboxes.map((checkbox) => {
      if (checkbox.isChecked) {
        return true;
      }
      return false;
    });
    setOneChecked(aux);
  }, [checkboxes]);

  useEffect(() => {
    const sorted = [...rows].sort((a, b) => new Date(b.creationTime) - new Date(a.creationTime));
    setSortedRows(sorted);
    setCheckboxes(sorted.map((row) => ({ id: row.id, courseId: row.courseId, isChecked: false })));
  }, [rows]);

  const handleSelectAll = () => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      isChecked: !selectAll,
    }));
    setCheckboxes(updatedCheckboxes);
    setSelectAll(!selectAll);
    const updatedAssignments = [];
    if (!selectAll) {
      rows.forEach((row) => {
        if (!updatedAssignments.some((assignment) => assignment.id === row.id && assignment.courseId === row.courseId)) {
          updatedAssignments.push({ id: row.id, courseId: row.courseId });
        }
      });
    }
    setSelectedAssignments(updatedAssignments);
  };

  const handleCheckboxChange = (id, courseId) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      isChecked: checkbox.id === id && checkbox.courseId === courseId ? !checkbox.isChecked : checkbox.isChecked,
    }));
    setCheckboxes(updatedCheckboxes);
    const updatedAssignments = [...selectedAssignments];
    const assignmentIndex = updatedAssignments.findIndex(
      (assignment) => assignment.id === id && assignment.courseId === courseId,
    );
    if (assignmentIndex === -1 && updatedCheckboxes.some((checkbox) => checkbox.isChecked)) {
      updatedAssignments.push({ id, courseId });
    } else if (assignmentIndex !== -1 && !updatedCheckboxes[assignmentIndex].isChecked) {
      updatedAssignments.splice(assignmentIndex, 1);
    }
    setSelectedAssignments(updatedAssignments);
    setSelectAll(updatedCheckboxes.every((checkbox) => checkbox.isChecked));
  };

  return (
    <Grid className={classes.boxTable}>
      <TableContainer>
        <Table sx={12} aria-label="simple table">
          <TableHead className={classes.assignmentStatusTable}>
            <TableRow>
              <TableCell>{t('ASSIGNMENT_TITLE')}</TableCell>
              <TableCell align="left">{t('DESCRIPTION')}</TableCell>
              <TableCell aria-sort="descending" align="left">{t('CREATED_ON')}</TableCell>
              <TableCell align="left">{t('CLASS')}</TableCell>
              <TableCell align="center">{t('Actions')}</TableCell>
              <TableCell align="center">
                <input
                  style={{
                    margin: '0px 0px 0px 10px', cursor: 'pointer', fontSize: '18x', width: '18px', height: '28px',
                  }}
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.assignmentStatusTable}>
            {Array.isArray(sortedRows) && sortedRows.map((row, index) => (
              <TableRow
                key={row?.id}
                style={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row?.title}
                </TableCell>
                <TableCell align="left" className={classes.descriptionCell}>
                  {row?.description}
                </TableCell>
                <TableCell align="left">
                  {moment(row?.creationTime).format('MMM DD, YYYY HH:mm:ss a')}
                </TableCell>
                <TableCell align="left">
                  {row?.courseName}
                  {' '}
                  {row?.section}
                </TableCell>
                <TableCell align="center">
                  <ChatBubbleOutlineIcon
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => onStatusView(row)}
                  />
                  <DeleteForeverOutlinedIcon
                    style={{
                      marginLeft: '10px', cursor: 'pointer', color: red[500],
                    }}
                    onClick={() => onDeleteAssignment(row.id, row.courseId)}
                  />
                </TableCell>
                <TableCell align="center">
                  <input
                    style={{
                      margin: '0px 0px 0px 10px', cursor: 'pointer', fontSize: '18x', width: '18px', height: '28px',
                    }}
                    type="checkbox"
                    checked={checkboxes[index].isChecked}
                    onChange={() => handleCheckboxChange(row.id, row.courseId)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
