import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import RoleManagerTable from './role-manager-table';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import { useStyles } from './style';
import AddRoleDialog from './add/index';
import { getRoles } from '../../../store/actions/getRole';
import RegionExportDialogue from './export';
import CheckboxesAtom from '../../../components/atoms/checkbox';
import DialogAtom from '../../../components/atoms/dialog';

export default function RoleManager() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const roleStore = useSelector((state) => state.getRole);
  const csvLink = useRef();
  const [successMessage, setSuccessMessage] = useState(false);

  const [bDownloadReady, setDownloadReady] = useState(false);

  const [showExportDialog, setExportDialog] = useState(false);

  const [viewInactive, setViewInactive] = useState(false);

  const getRolesTableData = () => {
    let tableData = roleStore?.roles || [];
    if (!viewInactive) {
      tableData = tableData?.filter((rowData) => rowData.isActive === true);
    }
    return tableData;
  };

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    dispatch(getRoles());
  }, []);
  const [showAddRoleDialog, setShowAddRoleDialog] = useState('');
  const [selectedRole, setSelectedRole] = useState({});

  const renderSuccessDialog = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('ADD_ROLE_SUCCESS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setSuccessMessage(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid className={classes.roleRoot}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              {t('ROLE_MANAGER')}
            </Typography>
          </Grid>
          <Grid className={classes.dFlex}>
            <div className={classes.marginTop}>
              <ButtonAtom
                className={classes.addbutton}
                name={t('EXPORT_TO_CSV')}
                btntype={Buttons.PRIMARY}
                type={Buttons.PRIMARY}
                onClick={() => setExportDialog(true)}
              />
            </div>
            <ButtonAtom
              className={`${classes.addbutton} ${classes.marginTop}`}
              name={t('ADD')}
              btntype={Buttons.PRIMARY}
              type={Buttons.PRIMARY}
              onClick={() => {
                setSelectedRole({});
                setShowAddRoleDialog('add');
              }}
              icon={<AddIcon />}
            />
          </Grid>
        </Grid>
        <Grid className={classes.dFlexIcon}>
          <CheckboxesAtom
            label={t('VIEW_INACTIVE')}
            id="acceptCheckbox"
            checked={viewInactive}
            handleChange={(e) => {
              setViewInactive(e.target.checked);
            }}
          />
        </Grid>
        <Grid container className={classes.roleManagerContainer}>
          <Grid container className={classes.roleManagerList}>
            <Grid className={classes.tableView}>
              <RoleManagerTable
                tableData={getRolesTableData()}
                onEdit={(row) => {
                  const selectedPermissions = [];
                  (row?.permissions || []).forEach((per) => selectedPermissions.push(per.id));
                  setSelectedRole({ ...row, selectedPermissions });
                  setShowAddRoleDialog('edit');
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {showAddRoleDialog && (
        <AddRoleDialog
          open={showAddRoleDialog}
          selectedRole={selectedRole}
          handleClose={() => setShowAddRoleDialog('')}
          setSuccessMessage={setSuccessMessage}
        />
        )}
        {successMessage && (
          <DialogAtom
            isOpen={successMessage}
            dialogHeading={t('ADD_ROLE_SUCCESS_TITLE')}
            customClass={classes.DialogAtom}
            content={renderSuccessDialog()}
            secHandle={() => setSuccessMessage(false)}
          />
        )}
      </Grid>
      <RegionExportDialogue
        show={showExportDialog}
        setShow={() => setExportDialog(false)}
        data={roleStore?.roles || []}
      />
    </>
  );
}
