/* eslint-disable no-console */
import Constant from '../constant/index';
import { getGradesService, editGradesService } from '../services/auth';

export const getGrades = (setOpenDialogPermissionsError, setDialogErrorImportMessage) => (dispatch) => {
  getGradesService()
    .then((response) => {
      dispatch({
        type: Constant.GET_GRADES,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.permissions) {
        setOpenDialogPermissionsError(true);
        setDialogErrorImportMessage(`${error.message} needed permissions ${error.permissions}`);
      }
      console.log('error :', error);
    });
};

export const editGrades = (payload, refreshList) => {
  editGradesService(payload, refreshList)
    .then((response) => {
      console.log('response :', response);
      refreshList();
    })
    .catch((err) => {
      console.err(err);
    });
};

export default {
  getGrades,
  editGrades,
};
