import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import Dropdown from '../../../components/atoms/dropdown';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import {
  getAssignedLocations,
} from '../../../store/actions/getLocationCoordinator';
import MissingScore from './missing-score';
import StudentStatus from './student-status';
import AttendanceBreakDownSecond from './attendance-breakdown-second';
import {
  classesByYear, studentAttendDetails, studentsByLocationMarks, studentsByYearAndClassId, getclassesTimingsStudents, setSelectedYear, setSelectedClassRooms,
} from '../../../store/actions/getTeacherView';

import { getLocalStorage } from '../../../utils/localStorageMethod';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';
import userRoles from '../../../constant/userRoles';
import Loader from '../../../components/atoms/loader';
import { getCurrentYear } from '../../../utils/methods';

export default function TeacherDashboard() {
  const { t } = useTranslation('translation');
  const classes = useStyles(styles)();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(true);
  const [years, setYearsData] = useState([]);

  const [year, setYear] = useState('');
  const [currentYearCourse, setCurrentYear] = useState('');
  const [isSelectedClassRoom, setSelectedClassRoom] = useState('');
  const locationCoordinatorData = useSelector((state) => state?.getLocationCoordinator);
  const assignedLocations = locationCoordinatorData?.assignedLocations;
  const assignedYears = locationCoordinatorData?.assignedYears;
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getAdmin);
  const teacherPanel = useSelector((state) => state?.getTeacherView);
  const [assignedClassrooms, setAssignedClassRooms] = useState([]);
  const [attendanceMarksId, setAttendanceMarksId] = useState([]);
  const [classesTimings, setClassesTimings] = useState([]);
  const [isselectedYear, setSelectYear] = useState();

  useEffect(() => {
    setYearsData(assignedYears);
    const currentYear = getCurrentYear()?.id;
    const selectedYear = assignedYears.filter((opt) => opt?.id === currentYear.toString());
    if (teacherPanel?.selectedYear?.name) {
      setYear(teacherPanel?.selectedYear?.name);
    } else {
      setYear(selectedYear[0]?.id);
    }
  }, [assignedLocations, assignedYears]);

  const [isImpersonateUser, setIsImpersonateUser] = useState(false);
  const [impersonateToken, setImpersonateToken] = useState('');

  React.useEffect(() => {
    if (reduxStore?.impersonateUserEmail?.success) {
      setIsImpersonateUser(true);
      setImpersonateToken(reduxStore?.impersonateUserEmail?.tokenResponse?.JWT);
    }
  }, [reduxStore?.impersonateUserEmail]);

  const fetchClassesByYear = () => {
    if (!isselectedYear) {
      return;
    }
    const payload = {
      academicYear: year,
    };

    if (payload?.academicYear) {
      dispatch(classesByYear(payload, setLoading));
    }
  };

  useEffect(() => {
    const loadFalse = () => setLoading(false);
    const encryptedUserRole = getLocalStorage('userRole');
    const userRole = decryptedUserRoles[encryptedUserRole];
    if (isImpersonateUser || (impersonateToken !== '')) {
      dispatch(getAssignedLocations(loadFalse));
      fetchClassesByYear();
    } else if (userRole === userRoles.TEACHER) {
      dispatch(getAssignedLocations(loadFalse));
    }
  }, [isImpersonateUser, impersonateToken]);

  useEffect(() => {
    setAssignedClassRooms([]);
    fetchClassesByYear();
  }, [isselectedYear]);

  useEffect(() => {
    if (assignedClassrooms.length) {
      const classRoomData = assignedClassrooms?.find((dat) => dat?.id === teacherPanel?.selectedClassroom?.value);
      if (classRoomData?.id) {
        setSelectedClassRoom(teacherPanel?.selectedClassroom?.value);
        localStorage.setItem('CourseTeacher', classRoomData?.courseId);
        localStorage.setItem('LocationTeacher', classRoomData?.locationId);
      } else {
        setSelectedClassRoom(assignedClassrooms?.[0]?.id);
        localStorage.setItem('CourseTeacher', assignedClassrooms?.[0]?.courseId);
        localStorage.setItem('LocationTeacher', assignedClassrooms?.[0]?.locationId);
        dispatch(setSelectedClassRooms(assignedClassrooms[0]));
      }
    }
  }, [assignedClassrooms, teacherPanel?.selectedClassroom]);
  useEffect(() => {
    const payload = {
      academicYear: year,
    };

    if (payload?.academicYear) {
      dispatch(classesByYear(payload, setLoading));
    }
  }, [year]);

  useEffect(() => {
    const currentAcademicYear = getCurrentYear()?.id;
    setCurrentYear(currentAcademicYear);
    const index = assignedYears.findIndex((cur) => cur.name === currentAcademicYear);
    if (index >= 0) {
      setSelectYear(assignedYears[index]?.id);
    }
  }, [assignedYears]);

  useEffect(() => {
    setClassesTimings(teacherPanel.classesTimings);
  }, [teacherPanel.classesTimings]);

  useEffect(() => {
    const classRooms = teacherPanel?.classesByYear?.map((obj) => ({
      id: obj?.googleClassId,
      name: `${obj?.name}(${obj?.sectionName}) - ${obj?.locationName}`,
      courseId: obj?.courseId,
      locationId: obj?.locationId,
    }));
    setAssignedClassRooms(classRooms);
  }, [year, teacherPanel?.classesByYear]);

  useEffect(() => {
    if (teacherPanel.classesTimings.length) {
      teacherPanel.classesTimings.filter((item) => {
        if (item) setAttendanceMarksId([item.homework_panel_marks]);
        return item;
      });
    }
  }, [classesTimings]);

  useEffect(() => {
    if (isSelectedClassRoom && teacherPanel?.classesByYear && classesTimings) {
      const temData = teacherPanel?.classesByYear?.filter((data) => data.googleClassId === isSelectedClassRoom);

      const req = {
        courseId: temData[0]?.courseId,
        academicYear: year,
        locationId: temData[0]?.locationId,
        googleClassId: temData[0]?.googleClassId,
      };
      const payload = {
        academicYear: year,
        courseId: temData[0]?.courseId,
        locationId: temData[0]?.locationId,
        googleClassId: temData[0]?.googleClassId,
      };
      const listPayload = {
        academicYear: year,
        googleClassId: temData[0]?.googleClassId,
        locationId: temData[0]?.locationId,
        courseId: temData[0]?.courseId,
      };

      dispatch(studentAttendDetails(req));
      dispatch(studentsByLocationMarks(payload));
      dispatch(studentsByYearAndClassId(listPayload));
      dispatch(getclassesTimingsStudents(listPayload));
    }
  }, [isSelectedClassRoom]);

  const handleYearChange = (e) => {
    setYear(e.target.value);
    const payload = {
      id: e.target.value,
      name: e.target.value,
    };
    dispatch(setSelectedYear(payload));
  };

  const handleClassroomChange = (e) => {
    setSelectedClassRoom(e.target.value);
    const selectedCourse = assignedClassrooms?.find((c) => c.id === e?.target?.value);
    localStorage.setItem('CourseTeacher', selectedCourse?.courseId);
    localStorage.setItem('LocationTeacher', selectedCourse?.locationId);
    dispatch(setSelectedClassRooms(e.target));
  };

  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('TEACHERS_DASHBOARD')}
          </Typography>
        </Grid>
      </Grid>

      <Grid item className={classes.HeaderWrapper}>
        <div className={classes.dropdownWrap}>
          <div className={classes.assignyear}>
            <Dropdown
              minWidth="100%"
              label={t('YEAR')}
              labelId={t('YEAR')}
              id="year"
              name="year"
              value={year}
              handleChange={(e) => handleYearChange(e)}
              options={years}
              customClass={classes.year}
              variant="standard"
            />
          </div>
          <div className={classes.googleClasses}>
            <Dropdown
              minWidth="100%"
              id="googleClass"
              variant="standard"
              options={assignedClassrooms}
              value={isSelectedClassRoom}
              customClass={classes.dropdown}
              changeCss
              customFormControlCss={{ width: '100%' }}
              labelId="myClass"
              handleChange={(e) => handleClassroomChange(e)}
            />
          </div>
        </div>
      </Grid>
      <Grid lg={12} xl={12}>
        {
          (!isLoading && teacherPanel && assignedClassrooms.length > 0) && (
            <>
              <Grid container item justifyContent="space-between">
                <Grid xs={12} md={5.8} item className={classes.gridBorder}>
                  <MissingScore isSelectedClassRoom={isSelectedClassRoom} currentYear={currentYearCourse} year={year} teacherPanel={teacherPanel} />
                </Grid>
                <Grid xs={12} md={5.8} item className={classes.gridBorder}>
                  <StudentStatus isSelectedClassRoom={isSelectedClassRoom} currentYear={currentYearCourse} year={year} teacherPanel={teacherPanel} />
                </Grid>
              </Grid>
              <Grid container item justifyContent="space-between">
                <Grid lg={12} xs={12} md={11.6} item className={classes.gridBorder}>
                  <AttendanceBreakDownSecond isSelectedClassRoom={isSelectedClassRoom} currentYear={currentYearCourse} year={year} teacherPanel={teacherPanel} attendanceRange={attendanceMarksId} />
                </Grid>
              </Grid>
            </>
          )
        }
        {(isLoading) && (
          <Grid className={classes.noData}>
            <Loader message={t('LOADING')} />
          </Grid>
        )}
        {(!isLoading) && assignedClassrooms.length === 0 && (
          <Grid className={classes.noData}>
            <DoNotDisturbIcon />
            <Grid>
              <Typography variant="subtitle2" color="text.secondary">
                {t('NO_CLASSES_AVAILABLE')}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
