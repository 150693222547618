import React, { useEffect, useState } from 'react';
import {
  Box, Grid, Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';
import Dropdown from '../../../components/atoms/dropdown';
import MapPin from '../../../assets/images/map-pin.png';
import {
  getAssignedLocations,
  getLocationDetails,
  setSelectedYear,
  setSelectedLocation,
} from '../../../store/actions/getLocationCoordinator';
import CoursesReport from './courses-report';
import TeachersReportWrapper from './teachers-report';
import BarChartEnrollmentBreakdown from './bar-chart-enrollment-breakdown';
import DonutChartEnrollmentBreakDown from './donut-chart-enrollment-breakdown';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';
import userRoles from '../../../constant/userRoles';
import { getCurrentYear } from '../../../utils/methods';
import { AntSwitch } from '../../../utils/commonUiComponent';

export default function LocationDashboard() {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocationData] = useState([]);
  const [years, setYearsData] = useState([]);

  const [selectedLocation, setLocation] = useState('');
  const [year, setYear] = useState('');
  const reduxStore = useSelector((state) => state?.getAdmin);

  const locationCoordinatorData = useSelector((state) => state?.getLocationCoordinator);
  const selectedYearRedux = locationCoordinatorData?.selectedYear;
  const selectedLocationRedux = locationCoordinatorData?.selectedLocations;
  const assignedLocations = locationCoordinatorData?.assignedLocations;
  const assignedYears = locationCoordinatorData?.assignedYears;
  const locationDetails = locationCoordinatorData?.locationDetails;
  const dispatch = useDispatch();
  const [mailChecked, setMailChecked] = React.useState(true);
  const [studentCount, setStudentCount] = React.useState(0);
  const [locDetails, setLocDetails] = React.useState([]);

  const handleChange = (event) => {
    setMailChecked(event.target.checked);
  };
  const [isImpersonateUser, setIsImpersonateUser] = useState(false);
  const [impersonateToken, setImpersonateToken] = useState('');

  useEffect(() => {
    setLocationData(assignedLocations);
    if (assignedLocations?.length === 0) {
      setLocDetails([]);
    }
    if (selectedLocationRedux?.value) {
      setLocation(selectedLocationRedux?.value);
    } else {
      setLocation(assignedLocations?.[0]?.id);
    }
    setYearsData(assignedYears);

    const currentYear = getCurrentYear()?.id;
    const selectedYear = assignedYears?.filter((opt) => opt?.id === currentYear.toString());
    if (selectedYearRedux?.id) {
      setYear(selectedYearRedux?.id);
    } else {
      setYear(selectedYear?.[0]?.id);
    }
  }, [assignedLocations, assignedYears]);

  useEffect(() => {
    if (selectedLocation && year) {
      dispatch(getLocationDetails({ locationId: selectedLocation, year }));
    }
  }, [selectedLocation, year]);

  React.useEffect(() => {
    if (reduxStore?.impersonateUserEmail?.success) {
      setIsImpersonateUser(true);
      setImpersonateToken(reduxStore?.impersonateUserEmail?.tokenResponse?.JWT);
    }
  }, [reduxStore?.impersonateUserEmail]);

  useEffect(() => {
    const encryptedUserRole = getLocalStorage('userRole');
    const userRole = decryptedUserRoles[encryptedUserRole];
    const isImpersonateLC = JSON.parse(getLocalStorage('isImpersonateLC'));
    if (isImpersonateUser || (impersonateToken !== '')) {
      const loadFalse = () => setLoading(false);
      dispatch(getAssignedLocations(loadFalse));
    } else if (userRole === userRoles.LOCATION_COORDINATOR && !isImpersonateLC) {
      const loadFalse = () => setLoading(false);
      dispatch(getAssignedLocations(loadFalse));
    }
  }, [isImpersonateUser, impersonateToken]);
  let selectedLocationName = '';
  const selectedItems = locations?.filter((opt) => opt?.id === selectedLocation);
  if (selectedItems && selectedItems?.length) {
    selectedLocationName = selectedItems?.[0]?.shortName;
  }
  const handleYearChange = (e) => {
    setYear(e.target.value);
    const payload = {
      id: e.target.value,
      name: e.target.value,
    };
    dispatch(setSelectedYear(payload));
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    dispatch(setSelectedLocation(e.target));
  };

  useEffect(() => {
    setLocDetails(locationDetails);
  }, [locationCoordinatorData?.locationDetails]);

  useEffect(() => {
    setStudentCount(locationCoordinatorData?.stuCount);
  }, [locationCoordinatorData?.stuCount]);

  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('LOCATION_DASHBOARD')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.dropdowns}>
        <Grid xs={4} sm={2} md={2} item className={classes.year}>
          <Dropdown
            minWidth="100%"
            label={t('YEAR')}
            labelId={t('YEAR')}
            id="year"
            name="year"
            value={year}
            handleChange={(e) => handleYearChange(e)}
            options={years}
            customClass={classes.year}
            variant="standard"
          />
        </Grid>
        <Grid xs={8} sm={5} md={4} className={classes.locationDropdown}>
          <Dropdown
            minWidth="100%"
            label={t('LOCATION')}
            id="location"
            name="location"
            value={selectedLocation}
            handleChange={(e) => handleLocationChange(e)}
            options={locations}
            customClass={classes.locationDropdown}
            variant="standard"
            icon={<img src={MapPin} alt="" className={classes.mapPinImg} />}
          />
        </Grid>
        <Grid xs={12} sm={5} md={3} className={classes.switchGrid}>
          <Typography className={classes.mailTag}>
            {t('NOTIFICATIONS')}
          </Typography>
          <Stack className={classes.switchUi} direction="row" spacing={1} alignItems="center" justifyContent="center">
            <Typography className={`${classes.switchText} ${!mailChecked ? classes.inActiveText : null}`}>{t('INACTIVE_STATUS')}</Typography>
            <AntSwitch
              defaultChecked
              checked={mailChecked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'ant design' }}
            />
            <Typography className={`${classes.switchText} ${mailChecked ? classes.activeText : null}`}>{t('ACTIVE_STATUS')}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Box border={1} className={classes.locationDetailBox}>
        <Grid container item>
          <Grid xs={12} sm={6} md={2} lg={3} item className={classes.card}>
            <div className={classes.title}>
              {t('LOCATION')}
            </div>
            <p className={classes.cardContent}>{locDetails?.locationName}</p>
            <p className={classes.cardSubContent}>{locDetails?.locationAddress}</p>
          </Grid>
          <Grid className={classes.verticalDivider} />
          <Grid xs={5} sm={2.9} md={2} item className={classes.card}>
            <div className={classes.title}>
              {t('STUDENTS')}
            </div>
            <p className={classes.cardContent}>{studentCount || '--'}</p>

          </Grid>
          <Grid className={classes.verticalDivider} />
          <Grid xs={6} sm={2.9} md={2} item className={classes.card}>
            <div className={classes.title}>
              {t('Teachers')}
            </div>
            <p className={classes.cardContent}>{locDetails?.teacher}</p>
          </Grid>
          <Grid className={classes.verticalDivider} />
          <Grid xs={12} sm={4} md={2} item className={classes.card}>
            <div className={classes.title}>
              {t('Hours')}
            </div>
            <p className={classes.cardContent}>{locDetails?.scheduledHours}</p>
            <p className={classes.cardSubContent}>{locDetails?.scheduledDay}</p>
          </Grid>
          <Grid className={classes.verticalDivider} />
          <Grid xs={12} sm={6} md={2} item className={classes.cardLast}>
            <div className={classes.title}>
              {t('COORDINATOR_NAME')}
            </div>
            <p className={classes.cardContent}>{locDetails?.coordinatorName}</p>
            <p className={classes.cardSubContent}>{locDetails?.coordinatorEmail}</p>
            <p className={classes.cardSubContent}>{locDetails?.coordinatorPhoneNo}</p>
          </Grid>
        </Grid>
      </Box>
      <Grid container item justifyContent="space-between">
        <Grid xs={12} md={6} item className={classes.gridBorder}>
          <BarChartEnrollmentBreakdown location={selectedLocation} year={year} />
        </Grid>
        <Grid xs={12} md={5.8} item className={classes.gridBorder}>
          <DonutChartEnrollmentBreakDown location={selectedLocation} year={year} />
        </Grid>
      </Grid>

      <Grid container item justifyContent="space-between">
        <Grid xs={12} md={6} item className={classes.gridBorder}>
          <CoursesReport location={selectedLocation} year={year} />
        </Grid>
        <Grid xs={12} md={5.8} item className={classes.gridBorder}>
          <TeachersReportWrapper location={selectedLocation} year={year} selectedLocationName={selectedLocationName} />
        </Grid>
      </Grid>
    </Box>
  );
}
