import React from 'react';
import {
  Box, Grid,
} from '@mui/material';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';

import { Typography } from '@material-ui/core';
import { PerformantTextField } from '../../../components/atoms';
import ButtonAtom from '../../../components/atoms/button/index';
import { Buttons } from '../../../constant';

import useStyles from '../../../custom-hooks/useStyles';
import styles from './styles';
import { saveBonusMarks } from '../../../store/actions/getLocationCoordinator';

const textField = (
  label,
  id,
  type,
  handleChange,
  onBlur,
  value,
  error,
  max,
  min,
  required = true,
  disable = false,
  allowSpecialCharacter = false,
) => {
  const formattedValue = Math.max(Number(min), Math.min(Number(max), Number(value)));

  return (
    <PerformantTextField
      label={label}
      id={id}
      required={required}
      name={id}
      type={type}
      value={type === 'number' ? formattedValue : value}
      onBlur={onBlur}
      error={error}
      onChange={handleChange}
      disabled={disable}
      allowSpecialCharacter={allowSpecialCharacter}
      max={max}
      min={0}
      inputStyle={{
        paddingLeft: 5,
      }}
      variant="standard"
      onKeyDown={(event) => {
        if (type === 'number' && (event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e')) {
          event.preventDefault();
        }
      }}
    />
  );
};

function BonusMarks(props) {
  const {
    handleClose, studentData, afterSave, updateData,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(styles)();

  const validations = Yup.object({
    bonus: Yup.number()
      .required(t('BONUS_REQUIRED'))
      .typeError(t('BONUS_REQUIRED')).min(0, t('BONUS_INVALID'))
      .max(5, t('BONUS_INVALID')),
  });

  const formik = useFormik({
    initialValues: {
      bonus: updateData?.value || '',
    },
    validationSchema: validations,
    onSubmit: (values) => {
      const payload = {
        ...studentData,
        bonusMarks: values?.bonus,
      };

      saveBonusMarks(payload);
      if (afterSave) {
        setTimeout(() => {
          afterSave();
        }, 2000);
      }
    },
  });

  return (
    <Box className={classes.container}>
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom className={classes.categoryLabel}>
              {t('BONUS_MARKS_LABEL')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {textField(
              '',
              'bonus',
              'number',
              formik.handleChange,
              formik.handleBlur,
              formik.values.bonus,
              formik.errors.bonus,
              '',
              '',
              true,
              false,
              true,
              false,
            )}
          </Grid>
          <Grid item xs={12} justifyContent="center" className={classes.btnGrp}>
            <ButtonAtom
              btntype={Buttons.SECONDARY}
              name={t('CANCEL')}
              className={classes.secButtonNew}
              onClick={handleClose}
            />
            <ButtonAtom
              btntype={Buttons.PRIMARY}
              name={t('SAVE')}
              onClick={() => {
                formik.handleSubmit();
              }}
              className={[classes.activeButtonNew, classes.secButtonNew]}
            />
          </Grid>
        </Grid>
      </FormikProvider>

    </Box>
  );
}

export default BonusMarks;
