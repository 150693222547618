import { colors, fonts } from '../../../theme';

const style = ((theme) => ({
  phoneInput: {
    background: 'white',
    borderRadius: '4px',
    width: '100%',
    border: '0.1vw solid',
    '@media (max-width: 1199px)': {
      marginLeft: 0,
    },
    '@media (max-width: 899px) ': {
    },
    '@media (min-width: 1200px)': {
      background: 'white',
      borderRadius: '0.4vw',
      marginBottom: '0.2vw',
      width: '45vw',
      marginTop: '0.25vw !important',
    },
    '&:focus-visible': {
      outline: 'none !important',
    },
    '&:focus': {
      border: '0.15vw solid black',
      outline: 'none',
    },
    '&.PhoneInput--focus, &:focus-within': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
    '&:hover PhoneInput': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
    '& .PhoneInputInput': {
      borderRadius: '3px',
      padding: '0.8vw',
      width: '100%',
      color: `${colors.black} !important`,
      border: 'none',
      '@media (max-width: 1199px)': {
        padding: '20px 14px',
        fontSize: '1rem',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      '@media (min-width: 1200px)': {
        padding: '0 0 0 0.8vw',
        borderRadius: '0.4vw',
        height: '2.7vw',
        marginTop: '0.25vw !important',
      },
      '&:hover, &:focus': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw !important',
        },
        color: `${colors.placeHolderColor}!important`,
        letterSpacing: 1,
      },
    },
    '& .PhoneInputCountry': {
      marginRight: 0,
      paddingLeft: 5,
      display: 'none',
    },
    '& .PhoneInputCountrySelect': {
    },
    '& .PhoneInputCountryIcon': {
      border: 4,
      marginLeft: 10,
    },
    '& input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      color: '#696969 !important',
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '14px !important',
        color: `${colors.placeHolderColor} !important`,
        letterSpacing: 1,
        [theme.breakpoints.down('md')]: {
        },
      },
    },
    '& input:disabled': {
      backgroundColor: colors.disabledField,
    },
  },
  inputStyle: {
    borderColor: colors.primary,
  },
  errorBorder: {
    border: `0.1vw solid ${colors.errorText} !important`,
    '&:focus-within, &.PhoneInput--focus': {
      border: `0.2vw solid ${colors.primary} !important`,
    },
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
    width: '100%',
    margin: '0px',
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw !important',
      marginTop: '-4px !important',
      '@media (max-width: 800px)': {
        fontSize: '16px !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      fontSize: '0.9vw !important',
      marginTop: '4px !important',
      color: `${colors.primary} !important`,
      background: 'transparent',
      paddingLeft: '2px',
      marginLeft: '0px !important',
    },
    '& .MuiFormControl-root': {
      margin: '2px !important',
    },
  },
  formControl: {
    width: '100%',
    marginLeft: '0',
    marginRight: '0',
    '& .MuiInputLabel-root': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.placeHolderColor} !important`,
      background: 'transparent',
      padding: '0 5px',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        padding: '0.3vw !important',
        marginLeft: '10px',
        marginTop: '3px',
        transform: 'translate(15px, 10px) scale(1)',
        '&.MuiInputLabel-shrink': {
          fontSize: '0.7vw',
          background: '#fff',
          marginLeft: '2px !important',
          color: `${colors.primary} !important`,
          transform: 'translate(0.7vw, -0.9vw) scale(1)',
        },
      },
    },
  },
  customClass1: {
    width: '100%',
    margin: '0px',
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw',
      '@media (max-width: 600px)': {
        marginTop: '-2px !important',
        '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
          marginLeft: '0px !important',
        },
      },
      '@media (min-width: 1199px)': {
        marginTop: '0px',
      },
      '@media (max-width: 1199px)': {
        fontSize: '16px !important',
        marginLeft: '16px',
      },
      '@media (min-width: 1000px) and (max-width: 1199px)': {
        marginTop: '5px',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      fontSize: '0.7vw',
      marginTop: '0px !important',
      color: `${colors.primary} !important`,
      background: 'white',
      paddingLeft: '2px',
    },
    '& .MuiFormControl-root': {
      margin: '2px !important',
    },
    background: 'white',
    borderRadius: '4px',
    border: `0.1vw solid ${colors.primary}`,
    '&:hover': {
      marginTop: '-3px !important',
      border: `3px solid ${colors.primary} !important`,
      outline: 'none !important',
    },
    '@media (max-width: 1199px)': {
      marginLeft: 0,

    },
    '@media (max-width: 600px)': {
      background: 'white',
      borderRadius: '3.5px',
      height: '50px !important',
      '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
        marginLeft: '0px !important',
      },
      '& .MuiInputLabel-root': {
        marginLeft: '10px !important',
      },
    },
    '&:focus-visible': {
      height: '2.7vw !important',
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none !important',
    },
    '&:focus': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
    '&.PhoneInput--focus, &:focus-within': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
      '@media (min-width: 1200px)': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none !important',
      },
    },
    '& .PhoneInputInput': {
      borderRadius: '0.6vw !important',
      width: '100%',
      color: `${colors.black} !important`,
      border: 'none',
      '@media (max-width: 1199px)': {
        padding: '16.5px 14px',
        fontSize: '1rem',
        height: '2.7vw !important',
      },
      '@media (min-width: 1200px)': {
        padding: '0 0 0 0.8vw',
        height: '2.7vw !important',
      },
      '@media (max-width: 899px)': {
        padding: '19px 4px',
        fontSize: '1rem',
        height: '2.7vw !important',
      },
      '&:hover, &:focus': {
        height: '2.7vw !important',
        outline: 'none !important',
      },
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw !important',
        },
        color: `${colors.placeHolderColor}!important`,
        letterSpacing: 1,
      },
    },
    '& .PhoneInputCountry': {
      marginRight: 0,
      paddingLeft: 5,
      display: 'none',
    },
    '& .PhoneInputCountrySelect': {

    },
    '& .PhoneInputCountryIcon': {
      border: 4,
      marginLeft: 10,
    },
    '& input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
      },
      color: '#696969 !important',
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '14px !important',
        color: `${colors.placeHolderColor} !important`,
        letterSpacing: 1,
      },
    },
    '& input:disabled': {
      backgroundColor: colors.disabledField,
    },
  },
}));

export default style;
