import React from 'react';
import {
  Grid,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';

function DeleteAssignment({
  classes,
  setDeleteAssignmentOpen,
  assignmentId,
  onConfirmDeleteClick,
}) {
  const { t } = useTranslation();
  return (
    <Grid className={classes.addDialog}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        className={classes.addPopup}
      >
        <Grid item xs={3}>
          <span>
            {t('DELETE_ASSIGNMENT_TEXT')}
          </span>
        </Grid>
      </Box>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5vh' }}>
        <Grid item xs={5}>
          <ButtonAtom
            className={classes.popupBtn}
            name={t('CANCEL')}
            onClick={() => setDeleteAssignmentOpen(false)}
            btntype={Buttons.SECONDARY}
          />
        </Grid>
        <Grid item xs={3}>
          <ButtonAtom
            className={classes.popupBtn}
            name={t('CONFIRM')}
            type="submit"
            onClick={() => onConfirmDeleteClick(assignmentId)}
            btntype={Buttons.PRIMARY}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DeleteAssignment;
