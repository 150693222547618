import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CssBaseline from '@mui/material/CssBaseline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { CSVLink } from 'react-csv';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import {
  AppBar, Main, getUserObj, DrawerHeader,
} from './helperComponent';
import useDataTableParams from '../../../custom-hooks/useDataTableParams';
import Header from './header/header';
import ListView from './list-view/listView';
import StudentFilters from '../filters';
import AddUser from './addUser/addUser';
import EditUser from './editUser/edit';
import ButtonAtom from '../../../components/atoms/button';
import { getCurrentYear, getMaskedEmail } from '../../../utils/methods';
import { postForgotPassword } from '../../../store/actions/auth';
import { Loader, DialogAtom } from '../../../components/atoms';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorageMethod';
import { Buttons, NavigateRoutes } from '../../../constant';
import CheckboxesAtom from '../../../components/atoms/checkbox';
import Constant from '../../../store/constant';
import { useStudent } from '../../../custom-hooks';
import {
  getAllFilterForUserManager,
  getUserRoles,
  getAllFilteredUserManager,
} from '../../../store/actions/getUser';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';
import userRoles from '../../../constant/userRoles';
import UserManagerExportDialog from './export';
import useUser from '../../../custom-hooks/useUser';

export const DrawerWidth = 240;

export default function UserManager() {
  const [dataForDownload] = useState([]);
  const csvLink = useRef();
  const [bDownloadReady, setDownloadReady] = useState(false);
  const {
    nextPage, setIsFetching, handlePageNumberChange, isFetching, hasMore,
  } = useDataTableParams();
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const studentsData = useStudent()?.students || [];
  const userFilters = useSelector((state) => state.getUser.userFilters);
  const { state } = useLocation();

  const [open, setOpen] = useState(false);
  const [isNewUserProfile, setIsNewUserDialogOpen] = useState(false);
  const [isRecoveryDialogOpen, setIsRecoveryDialogOpen] = useState(false);
  const [isEditUserProfile, setIsEditUserProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [sortBoth, setSortBoth] = useState(false);
  const [recoverPasswordSuccess, setRecoverPasswordSuccess] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchText, setSearchText] = useState([]);
  const [searchTextUM, setSearchTextUM] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [error, setError] = useState('');
  const [userInfo, setUserInfo] = useState(0);
  const [userFormError, setUserFormError] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [isSelectAllCheck, setSelectAllCheck] = useState(false);
  const [viewInactive, setViewInactive] = useState(false);
  const [viewActiveInactive, setViewActiveInactive] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showExportDialog, setExportDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const inputRef = React.useRef(null);

  const [sortOrder, setSortOrder] = useState({
    studentN: 'both',
    roleNameN: 'both',
    genderN: 'both',
    emailN: 'both',
    addressN: 'both',
    statusN: 'both',
  });
  const expandedLabels = useSelector(
    (reduxState) => reduxState.getUser.expandedLabels,
  );
  const pageLimit = 100;
  localStorage.removeItem('userManager');
  localStorage.removeItem('selectedYear');
  localStorage.removeItem('selectedLocationName');
  localStorage.setItem('emailRecipients', null);
  localStorage.setItem('moduleReturn', 'UserManager');
  const encryptedUserRole = getLocalStorage('userRole');
  const loggedInUserRole = decryptedUserRoles[encryptedUserRole];
  const isAdmin = loggedInUserRole === userRoles.SYSTEM_ADMIN;
  const isSuperUser = loggedInUserRole === userRoles.SUPER_USER;
  const selectedFilter = useSelector(
    (reduxState) => reduxState.getUser.selectedFilter,
  );
  const sendPasswordRecoveryEmail = useCallback(async (email) => {
    const initialValues = {
      email,
    };
    dispatch(postForgotPassword(initialValues));
  }, []);

  const handleRecoverDialog = async () => {
    setIsRecoveryDialogOpen(false);
    await sendPasswordRecoveryEmail(selectedRecord?.manabadi_email).finally(
      () => {
        setTimeout(() => {
          setRecoverPasswordSuccess(true);
        }, 200);
      },
    );
  };

  const viewLogs = (onClose) => {
    onClose(false);
    navigate(NavigateRoutes.STUDENTS_LOGS, {
      state: {
        id: selectedRecord.id,
        lastRoute: NavigateRoutes.USER_MANAGER_VIEW,
      },
    });
  };

  useEffect(() => {
    const localStorageText = localStorage.getItem('searchTextUserManager') || '';
    if (searchText.length === 0 && (localStorageText !== '' && localStorageText !== null)) {
      const searchFilter = selectedFilter?.filter((obj) => obj.label === localStorageText);
      if (searchFilter?.length === 0) {
        selectedFilter.push({
          label: localStorageText,
          groupBy: localStorageText,
          filterKey: localStorageText,
        });
      }
      setLoading(true);
      setSearchText(localStorageText);
      setSearchTextUM('');
    }
  }, []);

  const getFilteredStudents = () => {
    setLoadingFilters(true);
    const filterPayload = { filters: {}, searchText: [], loggedInUserRole };

    filterPayload.filters = selectedFilter.reduce((obj, item) => {
      if (obj[item.filterKey]) {
        obj[item.filterKey].push(item.label);
      } else {
        obj[item.filterKey] = [item.label];
      }
      return obj;
    }, {});
    filterPayload.timePeriod = 'year';
    if (searchText.length > 0) {
      filterPayload.searchText.push(searchText);
    }
    setIsFetching(true);
    handlePageNumberChange(1);
    dispatch({
      type: Constant.SET_LOADING,
      payload: true,
    });
    dispatch(
      getAllFilteredUserManager(
        setLoadingFilters,
        pageNum,
        pageLimit,
        nextPage,
        setLoading,
        studentsData.length,
        setIsFetching,
        filterPayload,
      ),
    );
  };

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    if (state?.reload) {
      setLoading(true);
      getFilteredStudents();
    }
  }, [state]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleAddUserClick = () => {
    setIsNewUserDialogOpen(true);
  };

  const oncallUserClick = (selectedRow) => {
    setSelectedRecord(selectedRow);
  };

  const onClickMenu = (student) => {
    setSelectedRecord(student);
  };

  const onEditMenuClick = (selectedUser) => {
    const userObj = getUserObj(selectedUser);
    setUserInfo(userObj);
    setSelectedRecord(selectedUser);
  };

  const onSetFilterOptions = (fil) => {
    setFilterOptions(fil);
    const selectedFil = [];
    fil.forEach((fi) => {
      fi.options.forEach((fiOpt) => {
        if (fiOpt.checked) {
          selectedFil.push({
            label: fiOpt.label,
            groupBy: fi.label,
            filterKey: fi.filter,
          });
        }
      });
    });
    const localStorageText = localStorage.getItem('searchTextUserManager') || '';
    if (searchTextUM.length === 0 && localStorageText !== '' && localStorageText !== null) {
      const searchFilter = selectedFil?.filter((obj) => obj.label === localStorageText);
      if (searchFilter?.length === 0) {
        selectedFil.push({
          label: localStorageText,
          groupBy: localStorageText,
          filterKey: localStorageText,
        });
      }
    }
    dispatch({
      type: Constant.SET_USERS_SELECTED_FILTER,
      payload: selectedFil,
    });
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const renderRecoveryContent = () => (
    <Grid>
      <Grid item xs={12} className={classes.recoveryContent}>
        {t('SEND_RECOVERY')}
      </Grid>
      <Grid item xs={12} className={classes.dialogButtonsA}>
        <ButtonAtom
          name={t('CANCEL')}
          onClick={() => setIsRecoveryDialogOpen(false)}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          name={t('CONFIRM')}
          onClick={() => {
            handleRecoverDialog();
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderSuccessDialog = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('ADD_USER_SUCCESS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setSuccessMessage(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderSuccessContent = () => (
    <Grid>
      <Grid item xs={10} className={classes.dialogContent}>
        <h1 className={classes.dialogHeader}>{t('RECOVER_PASSWORD_EMAIL')}</h1>
        {t('PASSWORD_RESET_LINK', {
          email: getMaskedEmail(selectedRecord?.personal_email),
        })}
      </Grid>
      <Grid container className={classes.content} justifyContent="flex-end">
        <ButtonAtom
          name={t('GOT_IT')}
          onClick={() => setRecoverPasswordSuccess(false)}
          btntype={Buttons.SECONDARY}
        />
      </Grid>
    </Grid>
  );

  const isInSelectedFilter = (label, filterKey) => {
    const isPresent = selectedFilter.find((item) => (item.label?.toString() === label?.toString())
      && (item.filterKey?.toString() === filterKey?.toString()));
    return isPresent;
  };

  const initialFilterPayload = (isRefresh) => {
    if (!isRefresh) {
      const fil = userFilters?.map((filterOpt) => {
        const options = filterOpt?.options?.map((op) => {
          let checked = !!(
            filterOpt.filter === 'academic_year'
            && op[filterOpt.key] === getCurrentYear().id
          );
          checked = isInSelectedFilter(op[filterOpt.key], filterOpt.filter) ? true : checked;
          return ({
            label: (op[filterOpt.key] === true || op[filterOpt.key] === false || op[filterOpt.key]) ? op[filterOpt.key] : op.name,
            checked,
            value: (op[filterOpt.key] === true || op[filterOpt.key] === false || op[filterOpt.key]) ? op[filterOpt.key] : op.name,
            count: op.count,
            filterKey: filterOpt.filter,
            disabled: false,
          });
        });
        return {
          ...filterOpt,
          expanded: expandedLabels.includes(filterOpt.label),
          options,
          value: filterOpt.label,
        };
      });

      setFilterOptions(fil);
      if (fil?.length > 0) {
        onSetFilterOptions(fil);
      }
    }
    if (isRefresh) {
      setFilterOptions(filterOptions);
      if (filterOptions?.length > 0) {
        onSetFilterOptions(filterOptions);
      }
    }
  };

  const selectedUserData = useUser();

  const getUserTableData = () => {
    let usersData = [];
    if (selectedRows.length !== 0) {
      usersData = selectedRows;
    } else if (selectedRows.length === 0) {
      usersData = selectedUserData?.users || [];
    }
    let data = usersData.map((userData) => {
      const exportObj = {
        ProfilePhoto: userData.profile_photo,
        Name: userData.middle_name !== '' ? `${userData?.first_name} ${userData?.middle_name} ${userData?.last_name}` : `${userData?.first_name} ${userData?.last_name}`,
        ManabadiEmail: userData.manabadi_email,
        PersonalEmail: userData.personal_email,
        Address: userData.address ? userData.address.address : '',
        Status: userData.is_active,
        ContactNumber: userData.contact_number,
        Gender: userData.gender,
      };

      if (userData.roles.length > 1) {
        const RoleName = userData.roles.map((role) => role.name);
        exportObj.RoleName = RoleName.join(',');
      } else if (userData.roles.length === 1) {
        exportObj.RoleName = userData.roles[0].name;
      } else if (userData.roles.length === 0) {
        exportObj.RoleName = 'N/A';
      }

      return exportObj;
    });

    if (!viewInactive) {
      data = data.filter((userData) => (userData.Status !== null && userData.Status !== false));
    }

    return data;
  };

  React.useEffect(() => {
    if (localStorage.getItem('useFilters') === null) {
      localStorage.removeItem('filters');
    }
    if (localStorage.getItem('filters') !== null) {
      onSetFilterOptions(JSON.parse(localStorage.getItem('filters')));
      localStorage.removeItem('useFilters');
    }
    setLoading(true);
    localStorage.removeItem('sendSingleEmail');
    dispatch(getUserRoles());
    const data = {
      filters: selectedFilter.reduce((obj, item) => {
        if (obj[item.filterKey]) {
          obj[item.filterKey].push(item.label?.toString());
        } else {
          obj[item.filterKey] = [item.label?.toString()];
        }
        return obj;
      }, {}),
      loggedUserRole: loggedInUserRole,
    };
    dispatch(getAllFilterForUserManager(data));
  }, []);
  const [filterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    if (!filterChanged) {
      return;
    }
    const data = {
      filters: selectedFilter.reduce((obj, item) => {
        if (obj[item.filterKey]) {
          obj[item.filterKey].push(item.label?.toString());
        } else {
          obj[item.filterKey] = [item.label?.toString()];
        }
        return obj;
      }, {}),
      loggedUserRole: loggedInUserRole,
    };
    dispatch(getAllFilterForUserManager(data));
    setFilterChanged(false);
  }, [filterChanged]);

  React.useEffect(() => {
    initialFilterPayload();
  }, [userFilters]);

  React.useEffect(() => {
    if (filterOptions.length) {
      localStorage.setItem('filters', JSON.stringify(filterOptions));
    }
    getFilteredStudents();
  }, [selectedFilter?.length, searchText?.length]);

  return (
    <Box className={`${classes.gridContainer} ${classes.paddingRemove}`}>
      {!loading && (
        <Grid container>
          <Grid item lg={0.2} />
          <Grid item xs={12} lg={12} className={classes.filterSection}>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <AppBar position="fixed" open={open} />
              <Drawer
                sx={{
                  width: DrawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: DrawerWidth,
                    boxSizing: 'border-box',
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                <DrawerHeader>
                  <div>
                    <TuneOutlinedIcon className={classes.filterIcon} />
                    <span
                      style={{
                        verticalAlign: 'top',
                        marginLeft: '0.8vw',
                      }}
                    >
                      {t('SEARCH_BY_FILTERS')}
                    </span>
                    <ClearIcon
                      onClick={handleDrawerClose}
                      style={{
                        marginLeft: '2vw',
                      }}
                      className={classes.clearIcon}
                    />
                  </div>
                </DrawerHeader>
                <StudentFilters
                  loadingFilters={loadingFilters}
                  isUserManagerView
                  filterOptions={filterOptions}
                  setFilterChanged={setFilterChanged}
                  setFilterOptions={onSetFilterOptions}
                  hideAddToView
                  setSelectAllCheck={setSelectAllCheck}
                />
              </Drawer>
              <Main open={open} className={classes.mainSection}>
                <Grid className={classes.filterButton}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Header
                        xs={10}
                        md={10}
                        lg={10.5}
                        setFilterOptions={(opts) => {
                          setFilterOptions(opts);
                        }}
                        {...{
                          handleDrawer,
                          filterOptions,
                          setSearchText,
                          setSearchTextUM,
                          searchText,
                          setFilterChanged,
                          open,
                          inputRef,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12} className={classes.iconsPanel}>
                  <Grid className={classes.dFlex} item xs={11.5}>
                    <div>
                      <Button
                        className={classes.activeButton}
                        variant="contained"
                        color="primary"
                        onClick={() => setExportDialog(true)}
                      >
                        {t('EXPORT_TO_CSV')}
                      </Button>
                      <CSVLink
                        data={dataForDownload}
                        filename="user-list.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      />
                    </div>
                    {(isAdmin || isSuperUser) && (
                      <Button
                        id="submit"
                        className={classes.activeButton}
                        onClick={handleAddUserClick}
                        variant="contained"
                        color="primary"
                      >
                        {t('ADDUSER')}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={0.5} className={classes.bulkEmail}>
                    <Tooltip title={t('SEND_EMAIL')}>
                      <IconButton
                        className={classes.emailIcon}
                        onClick={() => {
                          localStorage.removeItem('mailFilter');
                          const emailList = getLocalStorage('adminBulkEmailUsers')?.split(',');
                          setLocalStorage('showSelectAllinEmail', false);
                          localStorage.setItem('sendSingleEmail', true);
                          localStorage.setItem('userManager', true);
                          if (emailList?.length >= 1 && emailList?.[0] !== '') {
                            dispatch({
                              type: Constant.MAIL_FILTER,
                              payload: 'User',
                            });
                            dispatch({
                              type: Constant.RECIPIENTS,
                              payload: emailList,
                            });
                            navigate(NavigateRoutes.ADMIN_EMAIL);
                          } else {
                            dispatch({
                              type: Constant.MAIL_FILTER,
                              payload: 'Role - User groups',
                            });
                            localStorage.setItem('showSelectAllinEmail', true);
                            localStorage.setItem('userManager', true);
                            navigate(NavigateRoutes.ADMIN_EMAIL);
                          }
                        }}
                      >
                        <EmailOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                {(isSuperUser || isAdmin) && (
                  <Grid className={classes.dFlexIcon}>
                    <CheckboxesAtom
                      label={t('VIEW_INACTIVE')}
                      id="acceptCheckbox"
                      checked={viewInactive}
                      handleChange={(e) => {
                        setViewInactive(e.target.checked);
                        setViewActiveInactive(true);
                      }}
                    />
                  </Grid>
                )}
                <Grid>
                  <span className={classes.errorText}>{error}</span>
                </Grid>
                <Grid>
                  <Grid container className={classes.userContainer}>
                    <Grid container className={classes.userListView}>
                      <ListView
                        oncallUserClick={(row) => oncallUserClick(row)}
                        recoveryDialogOpen={(row) => {
                          setSelectedRecord(row);
                          setIsRecoveryDialogOpen(true);
                        }}
                        setEditUserProfile={() => setIsEditUserProfile(true)}
                        onClickMenu={onClickMenu}
                        onEditMenuClick={onEditMenuClick}
                        setError={setError}
                        pageNum={pageNum}
                        setPageNum={setPageNum}
                        filterOptions={filterOptions}
                        nextPage={nextPage}
                        student={studentsData.length}
                        setSortOrder={setSortOrder}
                        sortOrder={sortOrder}
                        setSortBoth={setSortBoth}
                        sortBoth={sortBoth}
                        setIsFetching={setIsFetching}
                        isFetching={isFetching}
                        hasMore={hasMore}
                        viewInactive={viewInactive}
                        viewActiveInactive={viewActiveInactive}
                        setViewActiveInactive={setViewActiveInactive}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        loggedInUserRole={loggedInUserRole}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Main>
            </Box>
          </Grid>
          <UserManagerExportDialog
            show={showExportDialog}
            setShow={() => setExportDialog(false)}
            data={getUserTableData()}
          />
        </Grid>
      )}
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
      <DialogAtom
        isOpen={isRecoveryDialogOpen}
        dialogHeading={t('PASSWORD_RECOVERY')}
        customClass={classes.passwordDialogAtom}
        content={renderRecoveryContent()}
        secHandle={() => setIsRecoveryDialogOpen(false)}
      />
      <DialogAtom
        isOpen={recoverPasswordSuccess}
        dialogHeading={t('PASSWORD_RECOVERY')}
        customClass={classes.passwordDialogAtom}
        content={renderSuccessContent()}
        secHandle={() => setIsRecoveryDialogOpen(false)}
      />
      <DialogAtom
        isOpen={isNewUserProfile}
        dialogHeading={t('ADD_USER_INFO')}
        secHandle={() => setIsNewUserDialogOpen(false)}
        customClass={classes.userDialogAtom}
        content={(
          <AddUser
            classes={classes}
            userInfo={null}
            setUserInfo={setUserInfo}
            userFormError={userFormError}
            setUserFormError={setUserFormError}
            setDialogOpen={setIsNewUserDialogOpen}
            selectedRecord={null}
            source="addUser"
            setSuccessMessage={setSuccessMessage}
          />
        )}
      />
      <DialogAtom
        isOpen={isEditUserProfile}
        dialogHeading={t('EDIT_USER_INFORMATION')}
        customClass={classes.studentDialogAtom}
        content={(
          <EditUser
            classes={classes}
            viewLogs={viewLogs}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            userFormError={userFormError}
            setUserFormError={setUserFormError}
            setDialogOpen={setIsEditUserProfile}
            selectedRecord={selectedRecord}
            source="editUser"
            getFilteredStudents={getFilteredStudents}
          />
        )}
        secHandle={() => setIsEditUserProfile(false)}
      />
      {
        successMessage && (
          <DialogAtom
            isOpen={successMessage}
            dialogHeading={t('ADD_USER_SUCCESS_TITLE')}
            customClass={classes.DialogAtom}
            content={renderSuccessDialog()}
            secHandle={() => setSuccessMessage(false)}
          />
        )
      }
    </Box>
  );
}
