import _ from 'lodash';

const calculateSameYears = (arr, prop) => {
  const sum = arr.reduce((acc1, itm1) => {
    const updatedSum = itm1[prop] + acc1;
    return updatedSum;
  }, 0);
  return sum;
};

export function getValues(data, setKey) {
  const courseRegionSet = new Set();
  const output = [];
  const updatedArray = [];
  const years = new Set();
  const y = new Set();
  data.forEach((item) => y.add(item.year));
  data.forEach((itm) => {
    if (courseRegionSet.has(itm[setKey])) {
      return;
    }

    courseRegionSet.add(itm[setKey]);
    const filteredCourseRegions = data
      .filter((item) => !!item?.year)
      .filter(
        (courseRegionItem) => courseRegionItem[setKey] === itm[setKey],
      );
    const filteredCourseRegionsYears = filteredCourseRegions.map((item) => item.year);
    const notPresentYears = _.difference([...y], filteredCourseRegionsYears);
    notPresentYears.forEach((yr) => {
      filteredCourseRegions.push({ year: yr, region: itm[setKey], value: 0 });
    });
    const yearSet = new Set();
    filteredCourseRegions.forEach((input) => {
      let sumOfYearValue = 0;
      if (yearSet.has(input.year)) {
        return;
      }

      yearSet.add(input.year);
      const sameYr = filteredCourseRegions.filter(
        (yrItm) => yrItm.year === input.year,
      );
      sumOfYearValue = calculateSameYears(sameYr, 'value');
      if (years.has(input.year)) {
        const oldData = updatedArray.find((dat) => dat.name === input.year);
        oldData.data.push(sumOfYearValue);
      } else {
        years.add(input.year);
        updatedArray.push({ name: input.year, data: [sumOfYearValue] });
      }
    });
  });

  output.push({ categories: [...courseRegionSet], data: updatedArray });
  return output;
}

export function formatData(data, setKey) {
  const allYears = new Set();
  data?.forEach((itm) => {
    if (allYears.has(itm.year)) {
      return;
    }
    allYears.add(itm.year);
  });

  const yearData = [...allYears];
  const resultData = [];

  const courseRegionSet = new Set();
  try {
    data?.forEach((itm) => {
      if (courseRegionSet?.size && courseRegionSet?.has(itm[setKey])) {
        return;
      }
      courseRegionSet?.add(itm[setKey]);
      const filteredData = data.filter(
        (filteredItem) => filteredItem[setKey] === itm[setKey],
      );

      if (filteredData.length !== yearData.length) {
        const filterDataYears = filteredData.map((item) => item.year);
        const absentYear = yearData.filter(
          (yr) => !filterDataYears.includes(yr),
        );
        const blankObj = {
          value: 0,
          [setKey]: itm[setKey],
          year: absentYear[0],
        };
        filteredData.push(blankObj);
      }
      filteredData.forEach((fd) => {
        resultData.push(fd);
      });
    });
  } catch (err) {
    // Leave comment to avoid "Empty Blovk Statement" Error
  }

  return getValues(resultData, setKey);
}

function sortArr(data) {
  data.forEach((smY) => {
    smY.sortBy = Number(smY.year.split('-').join(''));
  });
  data.sort((a, b) => b.sortBy - a.sortBy);
}

function addUpBlankEntry(arr) {
  const toAddIsNewRow = arr[0].isNew === 0 && arr[0].isReturning > 0;
  let blankObj = {
    name: arr[0].name,
    flag: arr[0].flag,
    year: arr[0].year,
    sortBy: null,
  };
  if (toAddIsNewRow) {
    blankObj = {
      ...blankObj,
      isReturning: 0,
      isNew: 1,
      value: 1,
      isCustom: true,
    };
  } else {
    blankObj = {
      ...blankObj,
      isReturning: 1,
      isNew: 0,
      value: 1,
      isCustom: true,
    };
  }
  arr.push(blankObj);
}

export function formatBarArray(data, setKey) {
  const yearSet = new Set();
  const output = [];
  let barData = [
    { name: 'isNew', data: [] },
    { name: 'isReturning', data: [] },
  ];
  sortArr(data);
  data.forEach((itm) => {
    if (yearSet.has(itm[setKey])) {
      return;
    }

    yearSet.add(itm[setKey]);
    const sameYearValues = data.filter(
      (yearItm) => yearItm[setKey] === itm[setKey],
    );

    if (sameYearValues.length === 1) {
      addUpBlankEntry(sameYearValues);
    }

    sameYearValues.forEach((item) => {
      if (item.isNew > item.isReturning) {
        const value = item.isCustom ? item.isNew - 1 : item.isNew;
        barData[0].data.push(value);
      } else {
        const value = item.isCustom ? item.isReturning - 1 : item.isReturning;
        barData[1].data.push(value);
      }
    });
  });

  barData = barData.map((obj) => {
    obj.data = obj.data.reverse();
    return obj;
  });

  output.push({ categories: [...yearSet].reverse(), data: barData });
  return output;
}

export default { formatData, formatBarArray, getValues };
