import React from 'react';
import { ReactComponent as Svg } from '../images/file-text.svg';

function FileTextIcon() {
  return (
    <div className="App">
      <Svg />
    </div>
  );
}
export default FileTextIcon;
