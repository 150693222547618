import { colors, fonts } from '../../../../theme';

const style = (theme) => ({
  container: {
    backgroundColor: colors.white,
    padding: '30px 35px',
  },
  editorClass: {
    border: '1px solid #F1F1F1',
    height: '40vh',
    padding: '10px',
    fontWeight: 'Bold',
  },
  heading: {
    fontSize: 20,
    fontWeight: 600,
    marginRight: 2,
  },
  slash: {
    marginLeft: 2,
  },
  termsHeading: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 2,
  },
  actionButtons: {
    marginTop: 30,
  },
  alignGrid: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px !important',
  },
  addbutton: {
    minWidth: '7.5vw  !important',
  },
  alignContainerAns: {
    border: '2px red !important',
    '& .MuiGrid-root': {
      border: '2px red !important',
    },
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 30,
    width: '100%',
  },
  editBtn: {
    '@media (min-width: 1200px)': {
      width: '6vw !important',
    },
  },
  accordion: {
    background: colors.accordionBarColor,
    borderRadius: '0.5vw',
    fontFamily: fonts.fontType.roboto,
    marginTop: '1vw',
  },
  emailEditor: {
    width: '100%',
  },
  gridContainer: {
    padding: '2vw 2vw 2vw 3vw !important',
    minHeight: '65vh',
  },
  titleRow: {
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    '@media (min-width: 1200px)': {
      fontSize: '1.5vw',
      padding: '1vw 0.5vw 1vw 0',
    },
  },
  questionHeading: {
    marginTop: '1vw',
    paddingBottom: '10px',
    fontSize: '1vw !important',
    fontWeight: fonts.fontWeight.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px !important',
    },
  },
  joditError: {
    border: '1px red !important',
    padding: '8px !important',
  },
  answerHeading: {
    fontSize: '1vw !important',
    paddingTop: '1vw',
    fontWeight: fonts.fontWeight.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px !important',
    },
  },
  popupButtons: {
    marginTop: '2vw',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  questionTextField: {
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  },
  editButton: {
    minWidth: '1.5vw !important',
    color: colors.secondary,
    '@media (min-width: 1200px)': {
      '& .MuiSvgIcon-root': {
        width: '1.5vw !important',
        height: '1.5vw !important',
      },
      '& svg': {
        width: '1vw',
        color: colors.black,
        height: '1vw',
      },
    },
  },
  deleteButton: {
    minWidth: '1.5vw !important',
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      '& .MuiSvgIcon-root': {
        color: colors.errorText,
        width: '1.5vw !important',
        height: '1.5vw !important',
      },
      '& svg': {
        width: '1.5vw',
        height: '1.5vw',
      },
    },
  },
  pstuAccordion: {
    '@media (min-width: 1200px)': {
      fontSize: '1vw',
    },
    color: colors.black,
    '& .MuiTypography-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      color: colors.black,
    },
  },
});

export default style;
