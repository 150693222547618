/* eslint-disable */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import moment from 'moment';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';
import { Buttons } from '../../../../constant';
import ButtonAtom from '../../../../components/atoms/button';
import {
  getAnnouncementList,
} from '../../../../store/actions/getEmail';

export default function AnnouncementsTable({
  tableData,
  onAnnouncementView,
  pageNext,
  pagenum,
  setPagenum,
  totalRows,
  locationID,
  academicYEAR,
}) {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const returnJSXFragment = (tag) => tag;
  const reduxTotalCount = useSelector((state) => state?.getLocationCoordinator?.totalAnnouncementsCount);
  const dispatch = useDispatch();

  function getBody(row) {
    const bodyContent = row.body;
    const bodyArray = bodyContent.split('\n');
    let body = bodyArray[0];
    for (let i = 1; i < bodyArray.length; i++) {
      if (bodyArray[i].includes('<img src=')) {
        const img = `${bodyArray[i].split('style=')[0]}style="height: 150px;width: 150px"/>`;
        body += `\n${img}`;
      } else {
        body += `\n${bodyArray[i]}`;
      }
    }
    return body;
  }

  const loadMoreItems = (length) => {
    let page = '';
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    const payload = {
      locationId: locationID,
      academicYear: academicYEAR,
    };
    dispatch(getAnnouncementList(payload, page, 100, pageNext));
  };

  return (
    <Grid>
      <TableContainer>
        <Table sx={12} aria-label="simple table">
          <TableHead className={classes.announcementTable}>
            <TableRow>
              <TableCell>{t('EMAIL_SUBJECT')}</TableCell>
              <TableCell align="left">{t('BODY')}</TableCell>
              <TableCell aria-sort="descending" align="left">{t('CREATED_ON')}</TableCell>
              <TableCell align="left">{t('CREATED_BY')}</TableCell>
              <TableCell align="center">{t('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.announcementTable} aria-sort="descending">
            {tableData?.map((row) => (
              <TableRow
                key={row?.id}
                style={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row?.subject}
                </TableCell>
                <TableCell align="left">
                  <div
                    dangerouslySetInnerHTML={{ __html: returnJSXFragment(getBody(row)) }}
                  />
                </TableCell>
                <TableCell align="left" aria-sort="descending">
                  {moment(row?.createdAt).format('MMM DD, YYYY HH:mm:ss a')}
                </TableCell>
                <TableCell align="left">
                  {`${row?.user?.first_name} ${row?.user?.last_name}`}
                </TableCell>
                <TableCell align="center">
                  <ChatBubbleOutlineIcon onClick={() => onAnnouncementView(row)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {' '}
        </Table>
      </TableContainer>
      {(reduxTotalCount > 0 && totalRows > 0)
        && (
          <Grid container justifyContent="flex-end">
            <Grid className={classes.loadMoreItems}>
              <Grid xs={12} className={classes.totalRowCount}>
                <span>
                  {t('TOTAL_ANNOUNCEMENTS')}
                  {' '}
                  {totalRows}
                  {' '}
                  of
                  {' '}
                  {reduxTotalCount}
                </span>
              </Grid>
              {(totalRows !== reduxTotalCount)
                && (totalRows !== reduxTotalCount ? (
                  <Grid justifyContent="flex-end" className={classes.loadMore}>
                    <ButtonAtom
                      btntype={Buttons.PRIMARY}
                      onClick={() => loadMoreItems(totalRows)}
                      name={t('LOAD_MORE')}
                    />
                  </Grid>
                ) : (
                  <Grid justifyContent="flex-end" className={classes.loadMore}>
                  </Grid>
                )
                )}
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}
