import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  regionExportDialogAtom: {
    '& .MuiDialog-paper': {
      minWidth: '60vw !important',
      maxWidth: '60vw !important',
      '& .MuiFormControl-root': {
        margin: '0 !important',
      },
      '& .MuiDialogContent-root': {
        '& .MuiGrid-container': {
          justifyContent: 'space-between',
        },
        '@media (min-width: 1200px)': {
          padding: '1vw 2.3vw 10px',
          fontSize: '0.9vw',
          overflowX: 'hidden !important',
        },
      },
    },
    selectAll: {
      display: 'flex !important',
      justifyContent: 'flex-start',
      '& .MuiFormControlLabel-label': {
        color: 'rgba(0, 0, 0, 0.87) !important',
        width: '120px !important',
        border: '1px solid grey',
      },
    },
    checkbox: {
      display: 'flex !important',
      marginBottom: '12px',
      '& .MuiFormControlLabel-label': {
        color: 'rgba(0, 0, 0, 0.87) !important',
      },
      '& div>MuiBox-root:first-child': {
        width: '25%',
      },
    },
    checkboxContent: {
      '& .MuiFormControlLabel-root': {
        whiteSpace: 'nowrap',
      },
      '& css-1vgzwi7-MuiFormControl-root:last child': {
        margin: '0 !important',
      },
    },
    popupButton: {
      textAlign: 'right',
    },
    btnAlign: {
      display: 'flex',
      justifyContent: 'flex-end',
      border: '1px solid red',
    },
    actionCancelBtn: {
      color: '#1976d2 !important',
      background: 'white',
      boxSizing: 'border-box',
      fontFamily: 'Roboto, sans-serif',
      borderRadius: '1.5% !important',
      textTransform: 'none',
      boxShadow: 'none',
    },
  },
  btnAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  checkBoxCon: {
    paddingRight: '1vw',
    minWidth: 'max-content',
  },
  checkboxAlign: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default {
  useStyles,
};
