/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { memo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import ArrowDropdown from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import style from './style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '10%',
    },
  },
};

const Dropdown = memo(
  ({
    handleChange,
    label,
    required,
    icon,
    labelId,
    id,
    options,
    canDelete,
    onDelete,
    value,
    variant,
    error,
    errorText,
    customClass,
    customSelectClass,
    disabled,
    onBlur,
    onClose,
    customStyles,
    onOpen,
    floatingLabel,
    shrink,
    adminDashboard,
  }) => {
    const classes = style();
    const { t } = useTranslation();

    const getLabel = () => {
      if (adminDashboard) {
        return value ? '' : t(label) + (required ? ' *' : '');
      }
      return !shrink ? '' : t(label) + (required ? ' *' : '');
    };
    return (
      <FormControl
        className={`${classes[customClass]} ${classes.formControl}`}
        style={customStyles}
      >
        <InputLabel id={labelId} className={customSelectClass}>
          { t(label) }
        </InputLabel>
        <span>
          {icon || null}
          <Select
            labelId={labelId}
            id={id}
            {...(floatingLabel ? {} : { label: labelId ? label : false })}
            value={value}
            name={id}
            onChange={handleChange}
            onBlur={onBlur}
            variant={variant}
            error={!!error}
            IconComponent={ArrowDropdown}
            disabled={disabled}
            data-testid={id}
            onClose={onClose}
            MenuProps={MenuProps}
            onOpen={onOpen}
          >
            {options?.length ? (
              options.map((opt) => (
                <MenuItem
                  key={opt.id}
                  value={opt.id}
                  className={`${classes.menuItem} ${canDelete ? classes.menuFlex : ''}`}
                  title={opt.name}
                >
                  <p className={classes.ellipsis}>{t(opt.name)}</p>
                  {canDelete && opt.name.trim() && (
                  <span id="delete">
                    <DeleteIcon
                      fontSize="small"
                      title="Delete"
                      clickable
                      onClick={() => onDelete(opt.filterId)}
                      className={classes.deleteIcon}
                    />
                  </span>
                  ) }
                </MenuItem>
              ))
            ) : (
              <MenuItem className={classes.defaultMenu}>
                {t('NO_OPTIONS_AVAILABLE')}
              </MenuItem>
            )}
          </Select>
        </span>
        <span className={classes.errorText}>{error ? t(errorText) : ''}</span>
      </FormControl>
    );
  },
);

Dropdown.defaultProps = {
  options: [],
  value: '',
  variant: 'outlined',
  placeholder: '',
  error: false,
  errorText: '',
  disabled: false,
};

export default Dropdown;
