import constant from '../constant';
import {
  createActivitiesSchemaService,
  getActivitiesSchemaService,
  updateActivitiesSchemaService,
  updateCurricularActivitiesMarksService,
} from '../services/auth';

const getCurricularActivitiesSchema = (courseId, academicYear, quarter) => (dispatch) => {
  getActivitiesSchemaService(courseId, academicYear, quarter)
    .then((response) => {
      dispatch({
        type: constant.GET_CURRICULAR_ACTIVITIES_SCHEMA,
        payload: response?.data,
      });
    })
    .catch(() => {
    });
};

const updateCurricularActivitiesSchema = (requestObj, refreshSchema, setLoading, setError, setSuccessMsg) => (dispatch) => {
  updateActivitiesSchemaService(requestObj)
    .then((response) => {
      if (typeof response?.data === 'string') {
        refreshSchema();
        setLoading(false);
        setError(response.data);
      } else {
        dispatch({
          type: constant.GET_CURRICULAR_ACTIVITIES_SCHEMA,
          payload: response?.data,
        });
        refreshSchema();
        setLoading(false);
        setSuccessMsg('Co-Curricular Schema Created/Updated Successfully');
      }
    })
    .catch(() => {
    });
};

const createCurricularActivitiesSchema = (requestObj, refreshSchema, setLoading, setError, setSuccessMsg) => (dispatch) => {
  createActivitiesSchemaService(requestObj)
    .then((response) => {
      if (typeof response?.data === 'string') {
        setError(response.data);
      } else {
        dispatch({
          type: constant.GET_CURRICULAR_ACTIVITIES_SCHEMA,
          payload: response?.data,
        });
      }
      refreshSchema();
      setLoading(false);
      setSuccessMsg('Co-Curricular Schema Created/Updated Successfully');
    })
    .catch(() => {
    });
};

const updateCurricularActivitiesMarks = (requestObj, refreshList) => () => {
  const {
    studentId, courseName, quarterName,
    academicYear, studentAttendenceActivitiesDetailMarks, isPresent, isAttended, locationId,
  } = requestObj;
  const payload = {
    studentId,
    academicYear,
    courseId: courseName,
    quarter: quarterName,
    studentAttendenceActivitiesDetailMarks,
    isPresent,
    isAttended,
    locationId,
  };
  updateCurricularActivitiesMarksService(payload)
    .then(() => {
      refreshList();
    })
    .catch(() => {

    });
};

export {
  getCurricularActivitiesSchema,
  updateCurricularActivitiesSchema,
  createCurricularActivitiesSchema,
  updateCurricularActivitiesMarks,
};
