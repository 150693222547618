export const exportCourseColumns = [
  {
    id: 'name',
    label: 'Course Name',
  },
  {
    id: 'baseCourse',
    label: 'Base Course',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'fee',
    label: 'Fee',
  },
  {
    id: 'examFee',
    label: 'Exam Fee',
  },
  {
    id: 'registrationFee',
    label: 'Registration Fee',
  },
  {
    id: 'repeatingDiscount',
    label: 'Repeating Discount',
  },
  {
    id: 'siblingDiscount',
    label: 'Sibling Discount',
  },
  {
    id: 'nextPossibleCourse',
    label: 'Next Possible Course',
  },
];

export default {
  exportCourseColumns,
};
