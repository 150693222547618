import makeStyles from '@mui/styles/makeStyles';
import { colors, fonts } from '../../../theme';

const style = makeStyles(() => ({
  customInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.primary,
    },
  },
  customInputAptSuite: {
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        marginTop: '8px !important',
      },
    },
  },
  performTextField: {
    '& .Mui-error': {
      color: colors.errorText,
      fontFamily: 'inherit',
      letterSpacing: 'inherit',
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
      },
      marginLeft: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      '@media (min-width: 1200px)': {
        color: 'inherit !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        backgroundColor: colors.white,
        color: colors.placeHolderColor,
        transform: 'translate(0.7vw, 0.7vw)',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, -0.5vw)',
        fontSize: '0.7vw',
        backgroundImage: 'linear-gradient(to bottom, #fff, #E4E4E4)',
        color: colors.primary,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.7vw)',
        fontSize: '0.9vw',
        backgroundColor: colors.backgroundGrey,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '0.7vw',
      },
      color: colors.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiInputLabel-root': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw !important',
        },
        color: `${colors.placeHolderColor} !important`,
      },
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${colors.placeHolderColor} !important`,
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '& input': {
        color: colors.black,
        '@media (min-width: 1200px)': {
          paddingLeft: '1vw',
          paddingRight: '1vw',
          fontSize: '0.9vw',
        },
        '&.Mui-disabled': {
          '@media (min-width: 1200px)': {
            padding: '0.65vw',
          },
          backgroundColor: colors.backgroundGrey,
          color: colors.primary,
          WebkitTextFillColor: colors.primary,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& .MuiFormHelperText-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
        },
        marginLeft: 0,
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
      },
    },
    '& fieldset': {
      '& legend span': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}));

export default style;
