import { colors } from '../../../../theme';

const roleManagerStyle = () => ({
  roleManagerTable: {
    '&.MuiTableHead-root': {
      borderBottom: `0.15vw solid ${colors.actionIconsColor}`,
    },
    '& .MuiTableCell-head': {
      '@media (min-width: 1200px)': {
        fontSize: '1vw',
        padding: '1.5vw 0.5vw',
      },
      '@media (max-width: 899px)': {
        whiteSpace: 'nowrap',
      },
      color: colors.actionIconsColor,
      fontWeight: 600,
      width: '20%',
    },
    '& .MuiTableCell-body': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        padding: '1.5vw 0.5vw',
        width: '20%',
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          width: '1.5vw',
          height: '1.5vw',
        },
        color: colors.black,
      },
      '& .MuiIconButton-root:hover': {
        backgroundColor: colors.white,
      },
      '& .MuiIconButton-root': {
        padding: '0 0 0 1vw',
      },
      '& .MuiTypography-root': {
        display: 'block',
        '@media (max-width: 1199px)': {
          display: 'table',
          width: '100%',
        },
      },
      '& .MuiGrid-item': {
        paddingLeft: '0',
      },
    },
  },
  actionCell: {
    '& .MuiBox-root': {
      alignItems: 'end',
      justifyContent: 'flex-end',
    },
  },
  expandedData: {
    textAlign: 'right',
  },
  feeStructure: {
    '@media (min-width: 1200px)': {
      width: '19vw',
      fontSize: '0.9vw',
    },
  },
  feeName: {
    margin: '0',
    '@media (min-width: 1200px)': {
      minWidth: '15vw',
    },
    '@media (max-width: 1199px)': {
      minwidth: '70%',
    },
    textAlign: 'right',
    float: 'left',
  },
  feeValue: {
    margin: '0',
    '@media (min-width: 1200px)': {
      minWidth: '2vw',
      marginLeft: '0.5vw',
    },
    float: 'right',
  },
});

export default roleManagerStyle;
