import Constant from '../constant';

const initialState = {
  studentsByYearAndClassId: [],
  classesTimings: [],
  classesTimingsStudents: [],
  classesByYear: [],
  assignedTeachetPanelStudents: [],
  studentAttendanceDetails: [],
  makeUpMarks: [],
  studentsMark: [],
  selectedYear: [],
  selectedClassroom: [],
};

const getTeacherView = (state = initialState, action = {}) => {
  switch (action.type) {
    case Constant.STUDENTS_BY_YEAR_AND_CLASSID:
      return {
        ...state,
        studentsByYearAndClassId: action.payload,
      };
    case Constant.SET_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.payload,
      };
    case Constant.SET_SELECTED_CLASSROOMS:
      return {
        ...state,
        selectedClassroom: action.payload,
      };
    case Constant.CLASSES_TIMINGS:
      return {
        ...state,
        classesTimings: action.payload,
      };
    case Constant.CLASSES_TIMINGS_STUDENTS:
      return {
        ...state,
        classesTimingsStudents: action.payload,
      };
    case Constant.CLASSES_BY_YEAR:
      return {
        ...state,
        classesByYear: action.payload,
      };
    case Constant.GET_TEACHER_PANEL_STUDENTS:
      return {
        ...state,
        assignedTeachetPanelStudents: action.payload,
      };
    case Constant.MAKE_UP_MARKS:
      return {
        ...state,
        makeUpMarks: action.payload,
      };
    case Constant.STUDENT_ATTENDANCE_DETAILS:
      return {
        ...state,
        studentAttendanceDetails: action.payload,
      };
    case Constant.GET_STUDENTS_BY_LOCATION_MARKS:
      return {
        ...state,
        studentsMark: action.payload,
      };
    default:
      return state;
  }
};
export default getTeacherView;
