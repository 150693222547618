import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import DownLeftRightIcon from '../../../../assets/svg/downLeftRight';
import { GroupedAutoComplete, Dropdown } from '../../../../components/atoms';
import { useStyles } from './style';
import { getCurrentYear } from '../../../../utils/methods';
import {
  setFilterSelectedView,
  setGraphOption,
} from '../../../../store/actions/getAdmin';
import constant from '../../../../store/constant';
import { deleteFilterView } from '../../../../store/actions/getStudent';

const getFilterViewOptions = (studentFilterViews) => {
  let opt = [
    {
      name: ' ',
      id: '',
    },
  ];
  studentFilterViews.forEach((filView) => {
    opt.push({
      name: filView.name,
      id: filView.name,
      filterId: filView.id,
    });
  });
  opt = opt.length === 1 ? [] : opt;
  return opt;
};

function Header(props) {
  const {
    handleDrawer,
    selectedFilter,
    setSearchText,
    setFilterView,
    studentFilterViews,
    filterView,
    setSortBoth,
    setSelected,
    open,
    graphOption,
    handleGraphOption,
    filterYearOptions,
    setSelectAllCheck,
    setInputStatus,
    setFilterChanged,
    newAddedFilterView,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const currentYear = getCurrentYear();
  const allFilters = useSelector(
    (reduxState) => reduxState.getStudent.allFilters,
  );

  const resetSelectedFilter = () => {
    dispatch({
      type: constant.SET_SELECTED_FILTER,
      payload: [
        {
          label: currentYear.id,
          groupBy: 'Academic Year',
          filterKey: 'academic_year',
        },
        {
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        },
      ],
    });
  };
  const clearValues = () => {
    resetSelectedFilter();
    dispatch(setGraphOption('year'));
    if (handleGraphOption) handleGraphOption({ target: { value: 'year' } });
  };
  const [option, setOption] = useState('');

  const resetFilterView = (event) => {
    handleGraphOption(event);
    dispatch(newAddedFilterView([]));
    dispatch(setFilterSelectedView(''));
    dispatch(setGraphOption(event.target.value));
    setOption(event.target.value);

    if (setFilterView) setFilterView('');
    dispatch({
      type: constant.SET_SELECTED_FILTER,
      payload: [
        {
          label: currentYear.id,
          groupBy: 'Academic Year',
          filterKey: 'academic_year',
        },
        {
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        },
      ],
    });
  };
  const onGroupAutoCompleteSelection = (e, val) => {
    setInputStatus(true);
    setSelectAllCheck(false);
    setSortBoth(true);
    setSelected([]);
    setTimeout(() => {
      setSortBoth(false);
    }, 1000);
    const isExist = val?.filter((obj) => (obj.filterKey === 'academic_year'));
    if (isExist.length > 0) {
      const acedemicYears = val.filter(
        (item) => item.filterKey === 'academic_year',
      );
      const academicYear = acedemicYears[acedemicYears.length - 1];
      let newOptions = [];
      const statusData = val.filter((item) => item.filterKey === 'status');
      const status = statusData.length ? statusData[statusData.length - 1] : null;
      newOptions.push(academicYear);
      if (status) {
        newOptions.push(status);
      } else {
        newOptions.push({
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        });
      }
      const filtOpts = val?.filter(
        (i) => i?.filterKey !== 'academic_year' && i?.filterKey !== 'status',
      );

      newOptions = [
        ...newOptions,
        ...filtOpts,
      ];
      dispatch({
        type: constant.SET_SELECTED_FILTER,
        payload: newOptions,
      });
    }
    return true;
  };
  // eslint-disable-next-line no-unused-vars
  const getGroupOptions = () => {
    const studentFil = [...allFilters];
    const options = [];
    const year = parseInt(localStorage.getItem('academicYear').split('-')[0], 10);
    studentFil.forEach((stu) => {
      stu?.options?.forEach((stuOpt) => {
        const key = stu?.key;
        if (key !== 'isAttended' && key !== 'isAbsent') {
          options.push({
            label: typeof stuOpt[key] === 'string' ? stuOpt[key] : stuOpt[key]?.toString(),
            groupBy: stu.label?.toString(),
            filterKey: stu.filter,
          });
        } else {
          const isAfter2023 = year >= 2023;
          const isQuarter = ['Quarter 1', 'Quarter 2', 'Quarter 3'].includes(stuOpt[key]);

          if ((isAfter2023 && !isQuarter) || (!isAfter2023 && isQuarter)) {
            options.push({
              label: typeof stuOpt[key] === 'string' ? stuOpt[key] : stuOpt[key]?.toString(),
              groupBy: stu.label?.toString(),
              filterKey: stu.filter,
            });
          }
        }
      });
    });
    return options;
  };

  const studentData = useSelector((state) => state?.getStudent);
  const adminData = useSelector((state) => state?.getAdmin);
  const lastSelectedView = adminData.selectedView;

  const lastAddedView = studentData.newAddedStudentFilterView.name;

  const selectedView = filterView || lastSelectedView || lastAddedView;

  const [count, setCount] = useState(0);
  useEffect(() => {
    if (count === 0) {
      setCount(count + 1);
      return;
    }
    setOption(graphOption);
  }, [graphOption]);

  return (
    <div>
      <Grid container className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('DASHBOARD')}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.filterButton}>
        <Grid container>
          <Grid item xs={12} md={2} lg={1.5}>
            <Button variant="filter" onClick={handleDrawer} className={classes.filterBtn}>
              {!open ? <DownLeftRightIcon />

                : (
                  <div className="DownLeftIcon">
                    <ZoomOutMapOutlinedIcon />
                  </div>
                )}
              <TuneOutlinedIcon className={classes.filterIcon} />
              filter
            </Button>
          </Grid>
          <Grid item xs={12} md={10} lg={10.5} className={classes.ChipSection}>
            <GroupedAutoComplete
              isStudentFilter
              isDashboard
              options={getGroupOptions()}
              onSelection={onGroupAutoCompleteSelection}
              value={selectedFilter}
              label={t('SEARCH')}
              placeholder={t('SEARCH')}
              setFilterView={setFilterView}
              onInputChange={(e, newInputValue, reason) => {
                if (reason === 'clear') {
                  clearValues();
                }
                if (newInputValue.length > 2) {
                  setSearchText(newInputValue);
                } else {
                  setSearchText('');
                }
                if (setFilterChanged) setFilterChanged(true);
              }}
              enterNotAllowed
              allowUserInput
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid />
      <Grid container>
        <Grid item xs={12} md={2} lg={1.5} className={classes.alignGrid}>
          <Dropdown
            minWidth="100%"
            label={t('TIME_PERIOD')}
            id="filterYear"
            name="filterYear"
            value={option}
            adminDashboard
            handleChange={resetFilterView}
            className={classes.dropdownText}
            options={filterYearOptions}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={1.5} className={classes.alignGrid}>
          <Dropdown
            minWidth="100%"
            label={t('VIEWS')}
            id="filterView"
            name="filterView"
            className=""
            adminDashboard
            canDelete
            placeholder={t('VIEWS')}
            onDelete={(id) => {
              dispatch(deleteFilterView(id));
            }}
            value={selectedView}
            handleChange={(e) => {
              if (!e.target.value) {
                resetSelectedFilter();
              }
              setFilterView(e.target.value);
              handleGraphOption('year');
              dispatch(setGraphOption('year'));
              dispatch(setFilterSelectedView(e.target.value));

              const vi = studentFilterViews?.find(
                (fi) => fi.name === e.target.value,
              );
              if (vi?.queryData) onGroupAutoCompleteSelection({}, JSON.parse(vi?.queryData));
            }}
            options={getFilterViewOptions(studentFilterViews)}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Header;
