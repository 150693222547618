import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';
import { GetAllLocations, GetOnlyActiveLocations } from '../helper';
import { Buttons } from '../../../../constant';
import ButtonAtom from '../../../../components/atoms/button';

export default function LocationManagerTable(props) {
  const {
    openEditLocation,
    setSelectedRow,
    setDialogHeader,
    setIsEdit,
    locationStore,
    assignedLocations,
    setAssignedLocations,
    deactivatedStatus,
    loading,
    setLocationExistsError,
    pageNum,
    setPageNum,
    totalCount,
    isFetching,
    setIsFetching,
    hasMore,
    setHasMore,
    setCoordinatorError,
    setStartEndTimeError,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(styles)();

  useEffect(() => {
    const allLocs = [];
    assignedLocations.forEach((obj) => {
      allLocs.push(obj);
    });
    locationStore.locations?.forEach((obj) => {
      allLocs.push(obj);
    });
    setAssignedLocations(allLocs);
  }, [locationStore.locations]);

  const [locationsData, setLocationsData] = useState([]);

  const loadMoreItems = (length) => {
    setIsFetching(true);
    if (length < totalCount) {
      setPageNum(pageNum + 1);
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (assignedLocations?.length > 0) {
      if (assignedLocations?.length >= totalCount) {
        setHasMore(false);
      }
      if (assignedLocations?.length < totalCount) {
        setHasMore(true);
      }
      if (deactivatedStatus) {
        const locations = new GetAllLocations(assignedLocations);
        setLocationsData(locations);
        setIsFetching(false);
      } else {
        const locations = new GetOnlyActiveLocations(assignedLocations);
        setLocationsData(locations);
        setIsFetching(false);
      }
    } else {
      setLocationsData(assignedLocations);
    }
  }, [assignedLocations, deactivatedStatus]);

  const onEdit = (row) => {
    setLocationExistsError('');
    const selectedRow = [];
    selectedRow.push(row);
    setSelectedRow(selectedRow);
    setDialogHeader(t('EDIT_LOCATION'));
    setIsEdit(true);
    openEditLocation(true);
    setCoordinatorError('');
    setStartEndTimeError('');
  };
  const uniqueIdS = new Set();
  return (
    <>
      <TableContainer>
        <Table sx={12} aria-label="simple table">
          <TableHead className={classes.locationManagerTable}>
            <TableRow>
              <TableCell align="left" width="20%">{t('LOCATION_NAME')}</TableCell>
              <TableCell align="left" width="20%">{t('CO_ORDINATORS')}</TableCell>
              <TableCell align="left" width="10%">{t('CITY')}</TableCell>
              <TableCell align="left" width="25%">{t('ADDRESS')}</TableCell>
              <TableCell align="left" width="10%">{t('STATUS')}</TableCell>
              <TableCell align="center" width="10%">{t('ACTIONS')}</TableCell>
            </TableRow>
          </TableHead>
          {locationsData?.length > 0
            && (
              <TableBody className={classes.locationManagerTable}>
                {locationsData.map((row, index) => {
                  if (uniqueIdS.has(row?.id)) {
                    return null;
                  }
                  uniqueIdS.add(row?.id);
                  return (
                    <TableRow
                      key={`${row?.name}-${index}`}
                      sx={{
                        '&:last-child td, &:last-child th': { borderBottom: 0 },
                      }}
                    >
                      <TableCell align="left" component="th" scope="row">
                        {row?.name}
                      </TableCell>
                      <TableCell align="left">{row?.locationCoordinator}</TableCell>
                      <TableCell align="left">{row?.city || '--'}</TableCell>
                      <TableCell align="left">{row?.address}</TableCell>
                      <TableCell align="left">{`${row?.isActive ? t('ACTIVE_STATUS') : t('INACTIVE_STATUS')}`}</TableCell>
                      <TableCell align="center">
                        <EditIcon
                          className={classes.cursorPointer}
                          onClick={() => {
                            onEdit(row);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
        </Table>
      </TableContainer>
      <Grid>
        {(locationsData.length === 0 && !loading) && (
          <Grid className={classes.noData}>
            <Grid item xs={12}>
              <DoNotDisturbIcon />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                {t('NO_DATA')}
              </Typography>
            </Grid>
          </Grid>
        )}
        {(locationsData.length === 0 && loading) && (
          <Grid container className={classes.noData}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </Grid>
      { !loading && (
        <Grid className={classes.loadMoreItems}>
          <Grid xs={12} className={classes.totalRowCount}>
            <span>
              {t('TOTAL_LOCATIONS')}
              {' '}
              {uniqueIdS?.size}
              {' '}
              of
              {' '}
              {totalCount}
            </span>
          </Grid>
          <Grid justifyContent="flex-end" className={classes.loadMore}>
            {isFetching && <p className={classes.fetch}>{t('FETCHING_LOCATIONS')}</p>}
            {!isFetching && hasMore && (
              <ButtonAtom
                btntype={Buttons.PRIMARY}
                onClick={() => loadMoreItems(assignedLocations?.length)}
                name={t('LOAD_MORE')}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
