import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styles from './style';
import Chip from './chip';
import useStyles from '../../../../custom-hooks/useStyles';

export default function App(props) {
  const {
    selectedOptions, setSelectedOptions, coordinatorError, setCoordinatorError, t, name, id, locationStore,
    setPrimaryContactNumber,
  } = props;

  const { data } = props;
  const [options, setOptions] = useState([]);
  const classes = useStyles(styles)();
  const [listData, setListData] = useState([]);
  const state = locationStore.locationState;

  const setCoordinators = (coordinatorsData) => {
    const teacherIndex = coordinatorsData?.findIndex((i) => (i.isPrimary === true));
    if (teacherIndex >= 0) {
      setSelectedOptions(coordinatorsData);
    } else {
      const coordinators = coordinatorsData?.map((i, index) => {
        return {
          ...i,
          isPrimary: index === 0 ? true : i?.isPrimary,
        };
      });
      setSelectedOptions(coordinators);
    }
  };
  useEffect(() => {
    setListData(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    const optionList = state?.locationCoordinators?.map((i) => {
      return {
        ...i,
        name: `${i?.first_name} ${i?.last_name}`,
        value: `${i?.first_name} ${i?.last_name}`,
        manabadiEmail: i?.manabadi_email,
        isPrimary: i?.isPrimary === true ? i?.isPrimary : false,
        contactNumber: i?.contact_number,
        profilePhoto: i?.profile_photo,
      };
    });
    const newData = data?.map((i) => {
      return {
        ...i,
        name: `${i?.first_name} ${i?.last_name}`,
        value: `${i?.first_name} ${i?.last_name}`,
        manabadiEmail: i?.manabadi_email,
        isPrimary: i?.isPrimary === true ? i?.isPrimary : false,
        contactNumber: i?.contact_number,
        profilePhoto: i?.profile_photo,
      };
    });
    setOptions(optionList);
    if (newData) {
      setCoordinators(newData);
    }
  }, [state?.locationCoordinators, data]);

  useEffect(() => {
    setListData(listData);
  }, [listData]);

  const setPrimaryError = (dataArray) => {
    const isPrimary = dataArray?.findIndex(((obj) => obj?.isPrimary === true));
    if (isPrimary < 0) {
      setCoordinatorError(t('PRIMARY_COORDINATORS_REQUIRED'));
    } else {
      setCoordinatorError(false);
    }
  };

  const setValue = (item, opt) => {
    if (item?.id === opt?.id) {
      return item.isPrimary !== true;
    }
    return false;
  };

  useEffect(() => {
    if (selectedOptions.length) {
      const isPrimary = selectedOptions?.some(((obj) => obj?.isPrimary === true)) || false;
      const primaryCoordinator = selectedOptions?.filter((obj) => obj?.isPrimary === true);
      if (selectedOptions.length === 0) {
        setCoordinatorError(t('COORDINATORS_REQUIRED'));
        setPrimaryContactNumber('');
      } else if (selectedOptions.length === 1) {
        setCoordinatorError(t(''));
        selectedOptions[0].isPrimary = true;
        setPrimaryContactNumber(selectedOptions[0]?.contactNumber);
      } else if (selectedOptions?.length > 0 && !isPrimary) {
        setCoordinatorError(t('PRIMARY_COORDINATORS_REQUIRED'));
        setPrimaryContactNumber('');
      } else if (selectedOptions?.length > 0 && isPrimary) {
        setPrimaryContactNumber(primaryCoordinator?.[0]?.contactNumber);
      }
    }
  }, [selectedOptions]);

  const handleChange = (event, value, method, option) => {
    const objIndex = selectedOptions?.findIndex(((obj) => obj?.id === option?.option?.id));
    const isPrimary = selectedOptions?.findIndex(((obj) => obj?.isPrimary === true));
    const primaryCoordinator = selectedOptions?.filter((obj) => obj?.isPrimary === true);
    if (method !== 'remove-option' && objIndex !== -1) {
      selectedOptions[objIndex] = option?.option;
      setCoordinatorError(false);
    } else if (method === 'remove-option' && isPrimary !== objIndex && selectedOptions?.length > 1) {
      setSelectedOptions(value);
    } else if (method === 'remove-option' && selectedOptions?.length === 1 && isPrimary === objIndex) {
      setSelectedOptions(value);
      setCoordinatorError(t('COORDINATORS_REQUIRED'));
      setPrimaryContactNumber('');
    } else if (method === 'remove-option' && selectedOptions?.length <= 1) {
      setSelectedOptions(value);
      setCoordinatorError(t('COORDINATORS_REQUIRED'));
      setPrimaryContactNumber('');
    } else if (method === 'clear') {
      setSelectedOptions(value);
      setCoordinatorError(t('COORDINATORS_REQUIRED'));
      setPrimaryContactNumber('');
    } else if (value) {
      setCoordinators(value);
      setPrimaryContactNumber(primaryCoordinator?.[0]?.contactNumber);
      setCoordinatorError(false);
    }
  };

  const handleOnBlur = () => {
    const isPrimary = selectedOptions?.some(((obj) => obj?.isPrimary === true)) || false;
    if (selectedOptions.length === 0) {
      setCoordinatorError(t('COORDINATORS_REQUIRED'));
    } else if (selectedOptions?.length > 0 && !isPrimary) {
      setCoordinatorError(t('PRIMARY_COORDINATORS_REQUIRED'));
    } else {
      setCoordinatorError(false);
    }
  };

  const handleCheck = (opt) => {
    const updatedArray = listData?.map((i) => ({
      ...i,
      isPrimary: setValue(opt, i),
    }));
    const checkedArray = selectedOptions?.map((i) => ({
      ...i,
      isPrimary: setValue(opt, i),
    }));
    setPrimaryError(checkedArray);
    setListData(updatedArray);
    setSelectedOptions(checkedArray);
  };

  useEffect(() => () => {
    setCoordinatorError(t(''));
  }, []);

  return (
    <Autocomplete
      className={coordinatorError ? [classes.errorDropdown, classes.dropdown] : classes.dropdown}
      ListboxProps={
        {
          style: {
            maxHeight: '7vw',
          },
        }
      }
      multiple
      id={id}
      name={name}
      options={options || []}
      value={selectedOptions}
      onChange={handleChange}
      filterSelectedOptions
      autoComplete
      onClose={handleOnBlur}
      getOptionLabel={(option) => {
        const label = option?.name;
        const email = option?.manabadiEmail;
        return `${label} (${email})`;
      }}
      renderTags={(tagValue, getTagProps) => tagValue?.map((option, index) => (
        <Chip
          tagIndex={index}
          handleCheck={handleCheck}
          listData={listData}
          {...getTagProps({ index })}
        />
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Find Coordinators"
          onBlur={handleOnBlur}
        />
      )}
    />
  );
}
