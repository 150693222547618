import { colors } from '../../../../theme';

const style = (theme) => ({
  cell: {
    whiteSpace: 'normal',
  },
  dataGrid: {
    marginLeft: '0.3vw',
    marginRight: '0.3vw',
    borderLeft: 'none',
    borderRight: 'none',
    '& div[role="columnheader"]:last-child': {
      '& .MuiDataGrid-columnHeaderDraggableContainer': {
        flexDirection: 'row',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
    '& .MuiDataGrid-row': {
      '@media (min-width: 1200px)': {
        minHeight: 'auto !important',
        maxHeight: 'auto !important',
      },
    },
    '& .MuiDataGrid-root': {
      outline: 'none !important',
      border: 'none !important',
    },
    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
      width: '0 !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '4px solid #025EE6 !important',
      borderTop: 'none',
      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
      fontStyle: 'bold',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        maxHeight: '4vw !important',
      },
      lineHeight: '63px !important',
      color: colors.actionIconsColor,
      minHeight: '3vw !important',
      zIndex: '111 !important',
      backgroundColor: '#fff !important',
      '@media (max-width: 1500px)': {
        minHeight: '4vw !important',
        maxHeight: '6vw !important',
      },
      '@media (max-width: 900px)': {
        minHeight: '56px !important',
        maxHeight: '56px !important',
        lineHeight: '56px !important',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '0.9vw',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        fontSize: 11,
      },
    },
    '& .MuiDataGrid-virtualScroller': {
      '@media (min-width: 1200px)': {
        height: 'inherit !important',
        overflowY: 'hidden !important',
      },
      overflowX: 'hidden !important',
      marginTop: '4vw !important',
      '@media (max-width: 900px)': {
        marginTop: '65px !important',
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-colCellWrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-columnHeaderTitle[data-field="spreadsheetName"], .MuiDataGrid-columnHeaderTitle[data-field="reportType"], .MuiDataGrid-columnHeaderTitle[data-field="generatedBy"], .MuiDataGrid-columnHeaderTitle[data-field="generatedOn"]': {
      justifyContent: 'center',
    },
  },
  alert: {
    height: '3vw',
    display: 'flex',
    color: colors.red,
  },
  alertPrimary: {
    height: '3vw',
    display: 'flex',
    color: colors.green,
  },
  studentBox: {
    maxWidth: 'unset !important',
    '& .MuiGrid-item': {
      maxWidth: 'unset !important',
      textAlign: 'right !important',
    },
  },
});

export default style;
