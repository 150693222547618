import { colors, fonts } from '../../theme';

const registerstyle = ((theme) => ({
  mainContainer: {
    '@media (max-width: 1200px)': {
      justifyContent: 'center',
    },
    '& .MuiDataGrid-root': {
      border: '0.2vw solid #104F96',
      borderBottom: '0.1vw solid #ccc',
      borderBottomLeftRadius: 'revert',
      borderBottomRightRadius: 'revert',
    },
    '& .MuiDataGrid-cell': {
      '@media (min-width: 1200px)': {
        minHeight: '3vw !important',
        maxHeight: '4vw !important',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '1vw',
      fontWeight: 'bold',
      '@media (max-width: 1199px)': {
        fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: colors.secondary,
    },
    '& .MuiDataGrid-cell--textLeft': {
      justifyContent: 'center',
    },
    '& .studentNameHeader, .studentNameCell': {
      '@media (min-width: 1200px)': {
        width: '13vw !important',
        minWidth: '13vw !important',
        maxWidth: '13vw !important',
        fontSize: '1vw',
        justifyContent: 'flex-start',
      },
    },
    '& .studentNameCell, .classCell, .courseFeeCell, .registrationFeeCell, .pstuFeeCell, .totalFeeCell': {
      justifyContent: 'center',
      paddingLeft: '2vw',
    },
    '& .classHeader, .classCell': {
      '@media (min-width: 1200px)': {
        width: '10vw !important',
        minWidth: '10vw !important',
        maxWidth: '10vw !important',
        fontSize: '1vw',
      },
    },
    '& .courseFeeHeader, .courseFeeCell': {
      '@media (min-width: 1200px)': {
        width: '9vw !important',
        minWidth: '9vw !important',
        maxWidth: '9vw !important',
        fontSize: '1vw',
      },
    },
    '@media (min-width: 1200px)': {
      '& .registrationFeeHeader, .registrationFeeCell': {
        width: '11vw !important',
        minWidth: '11vw !important',
        maxWidth: '11vw !important',
        fontSize: '1vw',
      },
      '& .pstuFeeHeader, .pstuFeeCell': {
        width: '9vw !important',
        minWidth: '9vw !important',
        maxWidth: '9vw !important',
        fontSize: '1vw',
      },
      '& .discountHeader, .discountCell': {
        width: '9vw !important',
        minWidth: '9vw !important',
        maxWidth: '9vw !important',
        fontSize: '1vw',
      },
      '& .discountCell': {
        justifyContent: 'center',
        color: colors.green,
        paddingLeft: '2vw',
      },
      '& .totalFeeHeader, .totalFeeCell': {
        width: '11vw !important',
        minWidth: '10vw !important',
        maxWidth: '11vw !important',
        fontSize: '1vw',
      },
    },
  },
  mainContainer2: {
    paddingTop: 10,
    '@media (min-width: 1021px)': {
      paddingRight: 8,
    },
    '@media (max-width: 1200px)': {
      justifyContent: 'left',
    },
    '& .MuiDataGrid-root': {
      marginLeft: 0,
      border: '0.2vw solid #104F96',
      borderBottom: '0.1vw solid #ccc',
      borderBottomLeftRadius: 'revert',
      borderBottomRightRadius: 'revert',
    },
    '& .MuiDataGrid-cell': {
      '@media (min-width: 1200px)': {
        minHeight: '3vw !important',
        maxHeight: '4vw !important',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '1vw',
      fontWeight: 'bold',
      '@media (max-width: 1199px)': {
        fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: colors.secondary,
    },
    '& .MuiDataGrid-cell--textLeft': {
      justifyContent: 'center',
    },
  },
  errorText: {
    width: '20vw !important',
    marginBottom: '5px !important',
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    marginLeft: 10,
    display: 'block',
  },
  form: {
    width: '100%',
    marginTop: '2vw',
  },
  nextButton: {
    marginLeft: 0,
  },
  divWrapper: {
    background: '#E4F5FD',
    margin: 'auto',
    '@media (min-width: 1200px)': {
      width: '80%',
      padding: 0,
    },
    '@media (max-width: 1199px)': {
      width: '90%',
      padding: 0,
    },
    '@media (max-width: 499px)': {
      width: '98%',
      minHeight: 'inherit',
    },
    padding: 45,
  },
  gridContainer: {
    padding: '0vw !important',
  },
  parentGridContainer: {
    padding: '2vw 2vw !important',
  },
  headerContainer: {
    marginTop: '3px',
    maxWidth: '98%',
    '& .secButton': {
      minwidth: '1vw',
    },
  },
  headerLogo: {
    cursor: 'pointer',
    '@media (max-width: 499px)': {
      width: '100%',
      maxWidth: '380px',
    },
    '@media (min-width: 1200px)': {
      width: '20vw',
    },
  },
  header: {
    background: colors.newBGColor,
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      color: 'white',
      fontSize: '1vw',
      marginTop: '1vw',
      '@media (max-width: 1199px)': {
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
      },
      '@media (max-width: 499px)': {
        marginTop: '10px',
      },
    },
    '& .MuiStepLabel-iconContainer': {
      '& div': {
        '@media (min-width: 1200px)': {
          width: '4vw',
          height: '4vw',
        },
      },
      '& img': {
        '@media (min-width: 1200px)': {
          width: '3.5vw',
          height: '3vw',
        },
      },
    },
    '& .MuiStepConnector-line': {
      height: '0.01vw',
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: '0.1vw',
      marginTop: '1.5vw',
      '@media (max-width: 1199px)': {
        height: '1px',
        marginTop: '0',
      },
    },
    marginTop: 0,
    '@media (max-width: 1199px)': {
      padding: '15px',
    },
    '@media (max-width: 499px)': {
      padding: '10px 0',
    },
    '@media (min-width: 1200px)': {
      padding: '0vw 2vw 0vw 1vw',
    },
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    textAlign: 'left',
    color: colors.primary,
    padding: '10px 0px 10px 1vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    '@media (max-width: 1199px)': {
      fontSize: 'calc(18px + 6 * ((100vw - 320px) / 1199))',
    },
    '@media (min-width: 1200px)': {
      fontSize: '2vw',
    },
  },
  studentTitle: {
    fontWeight: fonts.fontWeight.bold,
    fontSize: '2vw',
    textAlign: 'left',
    color: colors.primary,
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  '.MuiSvgIcon-root': {
    fontSize: '0.5vw',
  },
  '& .MuiMenuItem-root': {
    fontSize: '0.9vw',
  },
  paymentDialogAtom: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        maxWidth: '70vw !important',
      },
    },
    '& #contributionCause': {
      paddingRight: '1.5vw !important',
    },
  },
  lastNameError: {
    color: colors.red,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
  },
  popupButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '2vw',
    '& .MuiButtonBase-root.MuiButton-root': {
      '@media (min-width: 1200px)': {
        height: '2.7vw !important',
        minWidth: '9vw !important',
      },
      marginRight: '15px',
      borderRadius: '4px',
    },
  },
}));

export default registerstyle;

export const customCss = {
  minWidth: '2vw',
  height: '3vw',
  padding: '1vw 1vw 1.2vw 1.2vw',
  borderRadius: '0.4vw !important',
  '& .MuiButtonRoot': {
    minWidth: '2vw !important',
  },
};
