/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';

import JoditEditor from '../welcome-email/editor-relate';

function Form({ setFieldValue, value, config }) {
  useEffect(() => {
  }, [value]);

  const handleWYSIWYGChange = (newTextAreaValue) => {
    setFieldValue(newTextAreaValue);
  };
  return (
    <div>
      <JoditEditor
        config={config}
        onChange={handleWYSIWYGChange}
        value={value || ''}
      />
    </div>
  );
}

export default Form;
