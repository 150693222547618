import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography,
  TableContainer, Table, TableHead,
  TableRow, TableBody, TableCell,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style';
import useStyles from '../../../../custom-hooks/useStyles';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import { getGrades, editGrades } from '../../../../store/actions/getGrades';
import TextFieldAtom from '../../../../components/atoms/text-field-with-label';
import { Loader } from '../../../../components/atoms';

const restrictKeys = (ev, allowDecimal = false) => {
  const restrictedSymbols = ['+', '-', 'e', '*'];

  if (!allowDecimal) {
    restrictedSymbols.push('.');
  }

  if (restrictedSymbols.includes(ev.key)) {
    ev.preventDefault();
  }
};

export default function Grading() {
  const classes = useStyles(styles)();
  const { t } = useTranslation('translation');
  const gradesData = useSelector((state) => state.getGrade);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGrades());
  }, []);

  const handleMaxLimits = (row, index) => {
    let value = 0;
    switch (index) {
      case 0:
        value = 9999;
        break;
      case row.length + 1:
        value = row[index].maxScore?.value || row[index].maxScore;
        break;
      default:
        value = row[index - 1].minScore?.value || row[index - 1].minScore;
        break;
    }
    return value;
  };

  const handleMinLimits = (row, index) => {
    let value = 0;
    switch (index) {
      case 0:
        value = row[index + 1].maxScore?.value || row[index + 1].maxScore;
        break;
      case row.length - 1:
        value = 0;
        break;
      default:
        value = row[index + 1].maxScore?.value || row[index + 1].maxScore;
        break;
    }
    return value;
  };

  const refreshList = () => {
    dispatch(getGrades());
  };

  const formatGradeData = (data) => {
    const formattedGrades = [];
    data.map((item, index) => formattedGrades.push({
      id: item.id,
      sNo: index + 1,
      grade: item.grade,
      gpa: item.gpa,
      maxScore: {
        value: item?.maxScore?.value || item?.maxScore,
        max: handleMaxLimits(data, index),
        min: handleMinLimits(data, index),
      },
      minScore: {
        value: item.minScore?.value === 0 ? 0 : item?.minScore?.value || item?.minScore,
        max: handleMaxLimits(data, index),
        min: handleMinLimits(data, index),

      },
      errorMaxScore: {
        status: item?.errorMaxScore?.status || false,
        text: item?.errorMaxScore?.text || null,
      },
      errorMinScore: {
        status: item?.errorMinScore?.status || false,
        text: item?.errorMinScore?.text || null,
      },
      errorGPA: {
        status: item?.errorGPA?.status || false,
        text: item?.errorGPA?.text || null,
      },
    }));

    return formattedGrades;
  };

  useEffect(() => {
    const data = formatGradeData(gradesData.gradeData);
    setRows(data);
    setLoading(false);
  }, [gradesData?.gradeData]);

  const handleErrorMessage = (errorId, required) => {
    let errorMsg = '';
    if (required) {
      switch (errorId) {
        case 'errorMaxScore':
          errorMsg = t('END_SCORE_REQUIRED');
          break;
        case 'errorMinScore':
          errorMsg = t('START_SCORE_REQUIRED');
          break;
        case 'errorGPA':
          errorMsg = t('GPA_REQUIRED');
          break;
        case 'specialCharacter':
          errorMsg = t('SPECIAL_CHARACTERS_NOT_ALLOWED');
          break;
        default:
          errorMsg = t('INVALID_NUMBER');
          break;
      }
    } else {
      switch (errorId) {
        case 'errorMaxScore':
          errorMsg = t('INVALID_END_SCORE');
          break;
        case 'errorMinScore':
          errorMsg = t('INVALID_START_SCORE');
          break;
        default:
          break;
      }
    }
    return errorMsg;
  };

  const handleChangeValue = (id, errorId, val, data) => {
    const minRangeOffset = 0;
    const updatedRows = [...rows];
    const foundIndex = rows.findIndex((x) => x.sNo === data.sNo);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(parseFloat(val))) {
      updatedRows[foundIndex][errorId] = { status: true, text: handleErrorMessage(errorId, true) };
      if (id === 'maxScore' && id === 'minScore') {
        updatedRows[foundIndex][id].value = parseFloat(val);
      } else {
        updatedRows[foundIndex][id] = val;
        updatedRows[foundIndex][errorId] = { status: true, text: handleErrorMessage(errorId, true) };
      }
    } else if (id === 'gpa') {
      if (/[A-Za-z_-]/.test(val)) {
        updatedRows[foundIndex][errorId] = { status: true, text: handleErrorMessage('specialCharacter', true) };
        updatedRows[foundIndex][id] = val;
      } else if (!/[^\w.]|_/g.test(val)) {
        updatedRows[foundIndex][errorId] = { status: false, text: null };
        updatedRows[foundIndex][id] = val;
      } else {
        updatedRows[foundIndex][errorId] = { status: true, text: handleErrorMessage('specialCharacter', true) };
        updatedRows[foundIndex][id] = val;
      }
    } else {
      updatedRows[foundIndex][errorId] = { status: false, text: null };
      switch (id) {
        case 'maxScore':
          if (updatedRows[foundIndex].minScore.value === parseFloat(val)) {
            updatedRows[foundIndex][errorId] = { status: true, text: handleErrorMessage(errorId, false) };
            updatedRows[foundIndex][id].value = parseFloat(val);
          } else if (updatedRows[foundIndex].maxScore.max > parseFloat(val) && updatedRows[foundIndex].maxScore.min < parseFloat(val)) {
            updatedRows[foundIndex][errorId] = { status: false, text: null };
            updatedRows[foundIndex][id].value = parseFloat(val);
          } else {
            updatedRows[foundIndex][errorId] = { status: true, text: handleErrorMessage(errorId, false) };
            updatedRows[foundIndex][id].value = parseFloat(val);
          }
          break;
        case 'minScore':
          if (parseFloat(val) === minRangeOffset) {
            updatedRows[foundIndex][errorId] = { status: false, text: null };
            updatedRows[foundIndex][id].value = 0;
          } else if (updatedRows[foundIndex].maxScore.value === parseFloat(val)) {
            updatedRows[foundIndex][errorId] = { status: true, text: handleErrorMessage(errorId, false) };
            updatedRows[foundIndex][id].value = parseFloat(val);
          } else if (updatedRows[foundIndex].minScore.max > parseFloat(val) && updatedRows[foundIndex].minScore.min < parseFloat(val)) {
            updatedRows[foundIndex][errorId] = { status: false, text: null };
            updatedRows[foundIndex][id].value = parseFloat(val);
          } else {
            updatedRows[foundIndex][errorId] = { status: true, text: handleErrorMessage(errorId, false) };
            updatedRows[foundIndex][id].value = parseFloat(val);
          }

          break;
        default:
          updatedRows[foundIndex][id] = val;
      }
    }
    const updatedData = formatGradeData(updatedRows);
    setRows(updatedData);
  };

  const handleSave = () => {
    const payload = [];
    // eslint-disable-next-line array-callback-return
    rows.map((grade) => {
      payload.push({
        gradeGpaInfoId: grade.id,
        grade: grade.grade.toString(),
        gpa: grade.gpa.toString(),
        minScore: grade.minScore.value.toString(),
        maxScore: grade.maxScore.value.toString(),
      });
    });
    dispatch(editGrades(payload, refreshList));
  };

  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item container sx={{ alignItems: 'center' }}>
          <Typography className={classes.headerTitle}>
            {t('GRADING')}
          </Typography>
        </Grid>
      </Grid>
      {loading ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : (
        <Grid mt={12} item container>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className={classes.registrationTable}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell colSpan={4}>S.No</TableCell>
                  <TableCell colSpan={1}>Grade</TableCell>
                  <TableCell colSpan={1}>Numerical Score</TableCell>
                  <TableCell colSpan={1}>Numerical Score</TableCell>
                  <TableCell colSpan={1}>GPA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>

                {rows.map((row) => (
                  <TableRow
                    key={row.sNo}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="td" scope="row" colSpan={4}>
                      {row.sNo}
                    </TableCell>
                    <TableCell className={classes.tableCellCourse} colSpan={1}>
                      <TextFieldAtom
                        className={classes.LateFeeAmount}
                        label={t('GRADES')}
                        value={row.grade}
                        disable
                        custominputProps={{ style: { textAlign: 'left' } }}
                        type="text"
                      />

                    </TableCell>
                    <TableCell className={classes.tableCells} colSpan={1}>
                      <TextFieldAtom
                        className={classes.LateFeeAmount}
                        label={t('START_SCORE')}
                        error={row.errorMinScore.status}
                        value={row.minScore.value}
                        onChange={(e) => {
                          handleChangeValue('minScore', 'errorMinScore', e.target.value, row);
                        }}
                        custominputProps={{ style: { textAlign: 'left' } }}
                        type="number"
                      />
                      {row.errorMinScore.status ? (
                        <span className={classes.errorText}>
                          {row.errorMinScore.text}
                        </span>
                      ) : null}
                    </TableCell>
                    <TableCell className={classes.tableCells} colSpan={1}>
                      <TextFieldAtom
                        className={classes.LateFeeAmount}
                        label={t('END_SCORE')}
                        onChange={(e) => handleChangeValue('maxScore', 'errorMaxScore', e.target.value, row)}
                        value={row.maxScore.value}
                        error={row.errorMaxScore.status}
                        custominputProps={{ style: { textAlign: 'left' } }}
                        type="number"
                      />
                      {row.errorMaxScore.status
                        ? (
                          <span className={classes.errorText}>
                            {
                              row.errorMaxScore.text
                            }
                          </span>
                        ) : null}
                    </TableCell>
                    <TableCell className={classes.LateFeeAmount} colSpan={1}>
                      <TextFieldAtom
                        className={classes.LateFeeAmount}
                        label={t('GPA')}
                        value={row.gpa}
                        onChange={(e) => handleChangeValue('gpa', 'errorGPA', e.target.value, row)}
                        error={row.errorGPA.status}
                        custominputProps={{ style: { textAlign: 'left' }, min: 0 }}
                        type="text"
                        onKeyPress={(e) => restrictKeys(e, true)}
                      />
                      {row.errorGPA.status
                        ? (
                          <span className={classes.errorText}>
                            {row.errorGPA.text}
                          </span>
                        ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <Grid mt={3} container justifyContent="flex-end">
        <ButtonAtom
          name={t('CANCEL')}
          btntype={Buttons.secondary}
          className={classes.secButton}
          onClick={() => { window.location.reload(); }}
        />
        <ButtonAtom
          name={t('SAVE')}
          btntype={Buttons.PRIMARY}
          className={classes.actionBtn}
          onClick={() => handleSave()}
        />
      </Grid>
    </Box>
  );
}
