/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable radix */
import React, { useEffect, useState, useMemo } from 'react';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './styles';
import useStyles from '../../../../custom-hooks/useStyles';
import DropdownWithLabelAcademicPanel from '../../../../components/atoms/dropdown-with-labelAcademicPanel';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import { DialogAtom } from '../../../../components/atoms';
import { getclassesTimings } from '../../../../store/actions/getTeacherView';
import { getClassDetails } from '../../../teacher-user/attendance/helper';
import { getAllCourses } from '../../../../store/actions/getStudent';
import {
  deleteAssignmentByIdOld,
  getAssignmentPerCourse,
  editOldAssignmentById,
  republishOldAssignment,
  getClassesAmount,
} from '../../../../store/actions/getAdmin';
import { getStudentResourceWRTCourseAP } from '../../../../store/actions/getResources';
import useDataTableParams from '../../../../custom-hooks/useDataTableParams';
import CardsAssignment from './cardsAssignment';
import PublishedAssignment from './publishAssignment';
import EmptyAssignment from './emptyAssignment';
import PublishedAttachmentError from './publishAttachmentError';
import DeleteAssignment from './deleteAssignment';
import EditAssignment from './editAssignment';
import { getCurrentYear } from '../../../../utils/methods';

function OldAssignment({
  setLoading, selectedCourse, setSelectedCourse, setNewAssignment,
}) {
  const {
    nextPage,
  } = useDataTableParams();
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let classesAmount = 0;
  const studentStore = useSelector((state) => state?.getStudent);
  const allCourses = studentStore?.courses;
  const reduxStore = useSelector((state) => state?.getAdmin);
  const assignmentsPerCourse = reduxStore?.assignments;
  const reduxClass = useSelector((state) => state?.getTeacherView);
  const events = reduxClass?.classesTimings;
  const locationCoorRedux = useSelector((state) => state?.getLocationCoordinator);
  let studentResourceWRT = locationCoorRedux?.studentResourceWRT;
  const studentResourceTotalCount = locationCoorRedux?.totalCount;
  const assignmentTopics = reduxStore?.topics;
  const [eventsClassData, setEventsClassData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [dataEdit, setDataEdit] = useState();

  const [selectedResource, setSelectedResource] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const [issetAddAssignmentOpen, setAddAssignmentOpen] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [mainCheckBox, setMainCheckBox] = useState(false);
  const [assignmentIdForDelete, setAssignmentIdForDelete] = useState('');
  const [isDeleteAssignmentOpen, setDeleteAssignmentOpen] = useState(false);
  const [isPublishedAssignmentOpen, setPublishedAssignmentOpen] = useState(false);
  const [isPublishedAssignmentEmpty, setPublishedAssignmentEmpty] = useState(false);
  const [isSelectMultipleEmpty, setSelectMultipleEmpty] = useState('');
  const [isAmountClasses, setAmountClasses] = useState('');
  const [isAttachmentErrorOpen, setAttachmentErrorOpen] = useState(false);
  const [loadingResources, setLoadingResources] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountNum, setTotalCountNum] = useState(0);

  const handlePopUp = (state, assignmentItem = null) => {
    setAddAssignmentOpen(state);
    setSelectedAssignment(assignmentItem);
  };

  useEffect(() => {
    setTotalCount(studentResourceTotalCount);
  }, [studentResourceTotalCount]);

  const topicsData = assignmentTopics.filter((d) => d.topic_name !== '' && d.topic_name !== null);
  const assignmentTopicsWithId = topicsData.map((topic) => ({
    id: topic.topic_name,
    name: topic.topic_name,
  }));

  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  useEffect(() => {
    setAssignments(assignmentsPerCourse);
    setLoading(false);
  }, [assignmentsPerCourse]);

  useEffect(() => {
    if (selectedCourse) {
      setLoading(true);
      dispatch(getAssignmentPerCourse(selectedCourse));
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (studentResourceWRT && Array.isArray(studentResourceWRT) && studentResourceWRT.length) {
      setTotalCountNum(studentResourceWRT.length);
      studentResourceWRT = studentResourceWRT.filter((e) => e.deactivateDate === null);
      setResourceData(studentResourceWRT);
    } else {
      setTotalCountNum(0);
      setResourceData([]);
    }
  }, [studentResourceWRT]);

  const selectedCourseName = useMemo(() => {
    if (!selectedCourse) {
      return '';
    }

    return allCourses.find((course) => course.id === selectedCourse)?.name;
  }, [selectedCourse]);

  const refreshList = () => {
    dispatch(getAssignmentPerCourse(selectedCourse));
  };

  const handleCheck = (event) => {
    let updatedList = [...isChecked];
    if (event.target.checked) {
      updatedList = [...isChecked, event.target.value];
    } else {
      updatedList.splice(isChecked.indexOf(event.target.value), 1);
    }
    setIsChecked(updatedList);
  };

  const handleMainCheckBox = (event) => {
    if (!event.target.checked) {
      setIsChecked([]);
      setSelectMultipleEmpty('');
    }
    setMainCheckBox(event.target.checked);
  };

  const deleteAssignmentOpener = (assignmentId) => {
    setAssignmentIdForDelete(assignmentId);
    setDeleteAssignmentOpen(true);
  };

  const multiPublish = async (payload) => {
    const cPayload = {
      courseId: selectedCourse,
      academicYear: getCurrentYear()?.id,
    };
    const gcP = async (body) => {
      return new Promise((resolve) => {
        const res = dispatch(getClassesAmount(body));
        resolve(res);
      });
    };
    const num = await gcP(cPayload);
    classesAmount = num.data;
    const totalClasses = classesAmount * payload.items.length;
    if (payload.items.length === 0) {
      setLoading(false);
      setSelectMultipleEmpty('You have to select at least one assignment');
    } else if (totalClasses > 800) {
      setLoading(false);
      setSelectMultipleEmpty('Sorry, maximum we can create 800 assignments in a single request');
    } else {
      const prevResource = [];
      let currentResource = '';
      const attachBody = [];
      const finalAttachedRes = { items: [] };
      const key = 'id';
      setLoading(true);
      const p1 = async (body) => {
        return new Promise((resolve) => {
          const res = dispatch(republishOldAssignment(body, setLoading, setMainCheckBox, setPublishedAssignmentOpen));
          resolve(res);
        });
      };
      const finding = async (whereFind, whichFind) => {
        return whereFind.find((e) => e === whichFind);
      };
      if (payload.items.length > 1) {
        for (let i = 0; i < payload.items.length; i++) {
          currentResource = payload.items[i].attachmentResourceName;
          if (currentResource.length === 0) {
            attachBody.push(payload.items[i]);
          }
          if (i > 0) {
            prevResource.push(payload.items[i - 1].attachmentResourceName);
            for (let e = 0; e < currentResource.length; e++) {
              const founded = await finding(prevResource[0], currentResource[e]);
              if (!founded) {
                attachBody.push(payload.items[i]);
              } else {
                setLoading(false);
                setMainCheckBox(false);
                setIsChecked([]);
                return setAttachmentErrorOpen(true);
              }
            }
          } else {
            attachBody.push(payload.items[i]);
          }
        }
        const uniqueAttached = [...new Map(attachBody.map((item) => [item[key], item])).values()];
        finalAttachedRes.items = uniqueAttached;
        await p1(finalAttachedRes);
      } else {
        await p1(payload);
      }
    }
    return true;
  };

  const handleOnPublish = () => {
    setLoading(true);
    const payload = { items: [] };
    for (let index = 0; index < isChecked.length; index++) {
      const selectedAssignmentItem = assignments.find((ele) => ele.id === isChecked[index]);

      const nowDate = moment().local();
      const scheduled = moment(selectedAssignmentItem.scheduledTime).local();
      let dateScheduledTime;
      let dueDateTime;

      if (scheduled < nowDate) {
        dateScheduledTime = moment(nowDate).add(1, 'hours');
        dueDateTime = moment(nowDate).add(1, 'months');
      } else {
        dateScheduledTime = moment(selectedAssignmentItem.scheduledTime).local();
        dueDateTime = moment(selectedAssignmentItem.dueDate).local();
        if (dueDateTime < nowDate) {
          dueDateTime = moment(nowDate).add(1, 'months');
        } else {
          dueDateTime = moment(selectedAssignmentItem.dueDate).local();
        }
      }
      if (typeof (selectedAssignmentItem.attachmentResourceName) !== 'object') {
        if (selectedAssignmentItem.attachmentResourceName === null || selectedAssignmentItem.attachmentResourceName === '' || selectedAssignmentItem.attachmentResourceName === undefined) {
          selectedAssignmentItem.attachmentResourceName = [];
        } else {
          selectedAssignmentItem.attachmentResourceName = selectedAssignmentItem.attachmentResourceName.split(',');
        }
      } else if (selectedAssignmentItem.attachmentResourceName === null || selectedAssignmentItem.attachmentResourceName === '' || selectedAssignmentItem.attachmentResourceName === undefined) {
        selectedAssignmentItem.attachmentResourceName = [];
      }
      if (typeof (selectedAssignmentItem.attachmentFileUrl) !== 'object') {
        if (selectedAssignmentItem.attachmentFileUrl === null || selectedAssignmentItem.attachmentFileUrl === '' || selectedAssignmentItem.attachmentFileUrl === undefined) {
          selectedAssignmentItem.attachmentFileUrl = [];
        } else {
          selectedAssignmentItem.attachmentFileUrl = selectedAssignmentItem.attachmentFileUrl.split(',');
        }
      } else if (selectedAssignmentItem.attachmentFileUrl === null || selectedAssignmentItem.attachmentFileUrl === '' || selectedAssignmentItem.attachmentFileUrl === undefined) {
        selectedAssignmentItem.attachmentFileUrl = [];
      }
      const aMaterial = [];

      for (let i = 0; i < selectedAssignmentItem.attachmentResourceName.length; i++) {
        aMaterial.push({
          link: {
            url: selectedAssignmentItem.attachmentFileUrl[i],
            title: selectedAssignmentItem.attachmentResourceName[i],
          },
        });
      }

      payload.items.push({
        id: selectedAssignmentItem.id,
        academicYear: getCurrentYear()?.id,
        topicName: selectedAssignmentItem.topicName,
        assignmentId: selectedAssignmentItem.assignmentId,
        attachmentResourceName: selectedAssignmentItem.attachmentResourceName,
        event: selectedAssignmentItem.event,
        courseId: selectedAssignmentItem.courseId,
        dueDate: dueDateTime.toISOString(),
        assignmentData: {
          title: selectedAssignmentItem.title,
          description: selectedAssignmentItem.description,
          materials: aMaterial,
          dueDate: {
            year: parseInt(dueDateTime.format('YYYY')),
            month: parseInt(dueDateTime.format('M')),
            day: parseInt(dueDateTime.format('D')),
          },
          dueTime: {
            hours: moment(dueDateTime).hours(),
            minutes: moment(dueDateTime).minutes(),
            seconds: moment(dueDateTime).seconds(),
          },
          state: 'DRAFT',
          scheduledTime: dateScheduledTime.toISOString(),
          maxPoints: selectedAssignmentItem.defaultScore,
          workType: 'ASSIGNMENT',
        },
      });
    }

    multiPublish(payload);
  };

  const handleNewAssignmentDisplay = () => {
    setPublishedAssignmentOpen(false);
    setNewAssignment(true);
  };
  const handleAssignmentEmpty = () => {
    setLoading(false);
    setSelectMultipleEmpty('');
  };
  const handleAttachmentErrorDisplay = () => {
    setAttachmentErrorOpen(false);
  };

  useEffect(async () => {
    if (!selectedCourse) {
      return;
    }
    const payload = {
      courseId: selectedCourse,
      academicYear: getCurrentYear().id,
    };
    dispatch(getclassesTimings(payload));
    const p1 = async (body) => {
      return new Promise((resolve) => {
        const res = dispatch(getClassesAmount(body));
        resolve(res);
      });
    };
    const num = await p1(payload);
    classesAmount = num.data;
    setAmountClasses(`This course has ${classesAmount.toString()} classes`);
    const payloadResousces = {
      courseId: selectedCourse,
      parentKey: selectedResource.selfKey,
    };
    setLoadingResources(true);
    dispatch(getStudentResourceWRTCourseAP(1, 20, nextPage, payloadResousces, setIsFetching, setLoadingResources));
  }, [selectedCourse]);

  useEffect(() => {
    if (!events || !Array.isArray(events)) {
      return;
    }
    setEventsClassData(getClassDetails(events));
  }, [events]);

  const onConfirmDeleteClick = async (assignmentId) => {
    setLoading(true);
    const p1 = new Promise((resolve) => {
      resolve(dispatch(deleteAssignmentByIdOld(assignmentId, selectedCourse)));
    });
    p1.then((res) => {
      setDeleteAssignmentOpen(false);
      refreshList();
      return res;
    });
  };

  const editAssignmentOld = (payload, assignmentId) => {
    setLoading(true);
    const p1 = new Promise((resolve) => {
      resolve(dispatch(editOldAssignmentById(payload, selectedCourse, setLoading, assignmentId)));
    });
    handlePopUp(false);
    p1.then((res) => {
      refreshList();
      return res;
    });
  };

  return (

    <Box container>
      <DialogAtom
        isOpen={issetAddAssignmentOpen}
        dialogHeading={t('EDIT_ASSIGNMENT')}
        customClass={classes.DialogAtomEditAssignment}
        content={(
          <EditAssignment
            classes={classes}
            handlePopUp={handlePopUp}
            assignmentTopicsWithId={assignmentTopicsWithId}
            eventsClassData={eventsClassData}
            resourceData={resourceData}
            setIsFetching={setIsFetching}
            isFetching={isFetching}
            nextPage={nextPage}
            studentResourceWRT={studentResourceWRT}
            selectedCourse={selectedCourse}
            selectedCourseName={selectedCourseName}
            selectedAssignment={selectedAssignment}
            setAddAssignmentOpen={setAddAssignmentOpen}
            setDataEdit={setDataEdit}
            setLoading={setLoading}
            assignments={assignments}
            editAssignmentOld={editAssignmentOld}
            dataEdit={dataEdit}
            loadingResources={loadingResources}
            totalCount={totalCount}
            totalCountNum={totalCountNum}
            setLoadingResources={setLoadingResources}
          />
        )}
        secHandle={() => handlePopUp(false)}
      />
      <DialogAtom
        isOpen={isDeleteAssignmentOpen}
        dialogHeading={t('DELETE_ASSIGNMENT')}
        customClass={classes.DialogAtom}
        content={(
          <DeleteAssignment
            assignmentId={assignmentIdForDelete}
            classes={classes}
            setDeleteAssignmentOpen={setDeleteAssignmentOpen}
            onConfirmDeleteClick={onConfirmDeleteClick}
          />
        )}
        secHandle={() => setDeleteAssignmentOpen(false)}
      />
      <DialogAtom
        isOpen={isPublishedAssignmentOpen}
        dialogHeading={t('PUBLISHED_SUCCESS')}
        customClass={classes.DialogAtom}
        content={(
          <PublishedAssignment
            classes={classes}
            handleNewAssignmentDisplay={handleNewAssignmentDisplay}
          />
        )}
        secHandle={() => setPublishedAssignmentOpen(false)}
      />
      <DialogAtom
        isOpen={isPublishedAssignmentEmpty}
        dialogHeading={t('EMPTY_PUBLISH')}
        customClass={classes.DialogAtom}
        content={(
          <EmptyAssignment
            classes={classes}
            handleAssignmentEmpty={handleAssignmentEmpty}
          />
        )}
        secHandle={() => setPublishedAssignmentOpen(false)}
      />
      <DialogAtom
        isOpen={isAttachmentErrorOpen}
        dialogHeading={t('ATTACHMENT_ERROR')}
        customClass={classes.DialogAtom}
        content={(
          <PublishedAttachmentError
            classes={classes}
            handleAttachmentErrorDisplay={handleAttachmentErrorDisplay}
          />
        )}
        secHandle={() => setAttachmentErrorOpen(false)}
      />
      <Box className={classes.topContainerBox}>
        <Grid item className={classes.headerGridItem}>
          <div>

            <Typography className={classes.headerTitle}>
              {t('OLD_ASSIGNMENT')}
            </Typography>
          </div>
        </Grid>
        <Grid item className={classes.functionalGridItem}>
          {
            (!mainCheckBox)
              ? (
                <div className={classes.dropdownDiv}>
                  <DropdownWithLabelAcademicPanel
                    id="courses"
                    name="courses"
                    disabled={false}
                    options={allCourses}
                    label={`${t('COURSES')}`}
                    placeholder="COURSES"
                    customStyles={classes.dropDownCont}
                    value={selectedCourse}
                    handleChange={(e) => {
                      setSelectedCourse(e.target.value);
                    }}
                  />
                </div>
              )
              : null
          }

          {
            (mainCheckBox)
              ? (
                <div className={classes.buttonDivPublish}>
                  <ButtonAtom
                    className={classes.popupBtn}
                    name={t('CANCEL')}
                    btntype={Buttons.SECONDARY}
                    onClick={() => {
                      setIsChecked([]);
                      setSelectMultipleEmpty('');
                      setMainCheckBox(false);
                    }}
                  />
                  <ButtonAtom
                    name={t('PUBLISH')}
                    onClick={handleOnPublish}
                    btntype={Buttons.PRIMARY}
                  />
                </div>
              )
              : null
          }
        </Grid>
      </Box>
      <Box className={classes.checkboxField}>
        <input
          type="checkbox"
          value={mainCheckBox}
          onChange={handleMainCheckBox}
          checked={mainCheckBox}
        />
        <span>{t('REPUBLISH_MULTIPLE')}</span>
        <div className={classes.MultipleSelectEmpty}>{isSelectMultipleEmpty}</div>
        <div className={classes.AmountGC}>{isAmountClasses}</div>
      </Box>
      <CardsAssignment
        classes={classes}
        assignments={assignments}
        mainCheckBox={mainCheckBox}
        deleteAssignmentOpener={deleteAssignmentOpener}
        handlePopUp={handlePopUp}
        handleCheck={handleCheck}
      />
    </Box>

  );
}

export default React.memo(OldAssignment);
