import { colors } from '../../../../theme';

const style = () => ({
  container: {
    backgroundColor: colors.white,
    padding: '30px 35px',
  },
  editorClass: {
    border: '1px solid #F1F1F1',
    height: '40vh',
    padding: '10px',
    fontWeight: 'Bold',
  },
  heading: {
    fontSize: 20,
    fontWeight: 600,
    marginRight: 2,
  },
  slash: {
    marginLeft: 2,
  },
  termsHeading: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 2,
  },
  actionButtons: {
    marginTop: 30,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 30,
    width: '100%',
  },
  editBtn: {
    '@media (min-width: 1200px)': {
      width: '6vw !important',
    },
  },
  emailEditor: {
    width: '100%',
  },
});

export default style;
