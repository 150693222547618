import { colors, fonts } from '../../../theme';

const style = (() => ({
  addressAutoComplete: {
    border: `0.1vw solid ${colors.primary}`,
    background: 'white',
    borderRadius: '4px',
    height: '1.3vw',
    padding: '0.7vw !important',
    fontFamily: `${fonts.fontType.roboto} !important`,
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
      fontFamily: `${fonts.fontType.roboto} !important`,
    },
    '@media (max-width: 800px)': {
      fontSize: '1rem',
      height: '50px !important',
    },
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
    '&:focus, &:focus-visible': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
    '&::placeholder': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      color: `${colors.placeHolderColor}!important`,
    },
  },
  addressAutoCompleteMobile: {
    border: `0.1vw solid ${colors.primary}`,
    background: 'white',
    borderRadius: '4px',
    height: '36px',
    marginTop: '0px',
    paddingLeft: '10px',
    '@media (max-width: 800px)': {
      fontSize: '15px',
      height: '50px',
      marginTop: '8px',
    },
    fontFamily: `${fonts.fontType.roboto} !important`,
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
    '&:focus, &:focus-visible': {
      border: `0.2vw solid ${colors.primary} !important`,
      outline: 'none',
    },
    '&::placeholder': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      color: `${colors.placeHolderColor}!important`,
    },
  },
  customClass1: {
    border: `0.1vw solid ${colors.primary}`,
    background: 'white',
    borderRadius: '0.3vw',
    marginTop: '-1px',
    height: '1.3vw',
    fontFamily: `${fonts.fontType.roboto} !important`,
    padding: '9px 0px 0px 12px',
    fontSize: '0.9vw',
    '&:hover': {
      border: `0.2vw solid ${colors.primary} !important`,
      height: '1.1vw !important',
      outline: 'none',
    },
    '&:focus, &:focus-visible': {
      border: `0.2vw solid ${colors.primary} !important`,
      height: '1.1vw !important',
      outline: 'none',
    },
    '&::placeholder': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
      color: `${colors.placeHolderColor}!important`,
    },
  },
  formControl: {
    width: '100%',
    marginLeft: '0',
    marginRight: '0',
    '& .MuiInputLabel-root': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.placeHolderColor} !important`,
      background: 'transparent',
      padding: '0 5px',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        padding: '0.3vw !important',
        marginLeft: '5px !important',
        transform: 'translate(0.3vw, 0.4vw) scale(1)',
        '&.MuiInputLabel-shrink': {
          fontSize: '0.7vw',
          background: '#fff',
          color: `${colors.primary} !important`,
          transform: 'translate(0.7vw, -0.9vw) scale(1)',
        },
      },
    },
  },
  addressStyle: {
    '& input': {
      borderColor: colors.primary,
    },
  },
  addressStyle1: {
    height: '36px !important',
    '@media (max-width:1199px)': {
      height: '52px !important',
    },
    '& .MuiInputLabel-root': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.placeHolderColor} !important`,
      background: 'transparent',
      padding: '0 5px',
      fontSize: '12px',
      marginLeft: '5px !important',
      transform: 'translate(0.3vw, 0.4vw) scale(1)',
      '&.MuiInputLabel-shrink': {
        fontSize: '12px',
        background: '#fff',
        color: `${colors.primary} !important`,
        transform: 'translate(0.7vw, -0.9vw) scale(1)',
      },
    },
    '& input': {
      borderColor: colors.primary,
      height: 'inherit',
    },
  },
  error: {
    border: `1px solid ${colors.errorText}`,
    '&:hover, &:focus, &:focus-visible': {
      marginTop: '-3px !important',
      border: `3px solid ${colors.primary} !important`,
      outline: 'none',
    },
  },
}));

export default style;
