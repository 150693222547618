/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import clsx from 'clsx';
import useStyles from '../../../custom-hooks/useStyles';
import style from './style';

function LabeledAddressAutoComplete({
  id,
  onChange, value, label, onPlaceSelected, error, onBlur, isStudentOrSibling,
}) {
  const classes = useStyles(style)();
  const baseClassName = isStudentOrSibling ? classes.addressAutoCompleteStudent : classes.addressAutoComplete;
  const classesAutoComplete = error ? clsx(baseClassName, classes.error) : baseClassName;
  return (
    <div>
      <FormControl
        className={classes.formControl}
        sx={{
          m: {
            lg: 1,
          },
          width: {
            xs: '100%',
            lg: 200,
          },
        }}
      >
        {' '}
        {value ? <InputLabel id={id}>{label}</InputLabel> : null}
        <Autocomplete
          label={label}
          placeholder={label}
          id={id}
          name={id}
          value={value}
          onPlaceSelected={onPlaceSelected}
          onChange={onChange}
          className={classesAutoComplete}
          options={{
            types: ['geocode', 'establishment'],
            // fields: ['address_components', 'geometry'],
          }}
          onBlur={onBlur}
        />
      </FormControl>
    </div>
  );
}

export default LabeledAddressAutoComplete;
