import { colors, fonts } from '../../../../theme';

const style = () => ({
  paymentDialogAtom: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        maxWidth: '70vw !important',
      },
    },
    '& #contributionCause': {
      paddingRight: '1.5vw !important',
    },
  },
  lastNameError: {
    color: colors.green,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
  },
  popupButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '2vw',
    '& .MuiButtonBase-root.MuiButton-root': {
      '@media (min-width: 1200px)': {
        height: '2.7vw !important',
        minWidth: '9vw !important',
      },
      marginRight: '15px',
      borderRadius: '4px',
    },
  },
  container: {
    padding: '2vw 2vw 2vw 3vw !important',
    minHeight: '65vh',
  },
  titleRow: {
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    '@media (min-width: 1200px)': {
      fontSize: '1.5vw',
      padding: '1vw 0.5vw 1vw 0',
    },
  },
  addAcademicBtn: {
    '@media (min-width: 1200px)': {
      minWidth: '13.021vw !important',
      height: '3vw !important',
      fontSize: '0.9vw',
      marginTop: '19px !important',
      marginLeft: '-2vw !important',
    },
    paddingRight: '1.7vw',
    textAlign: 'right',
  },
  viewLogs2: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    fontFamily: fonts.fontType.roboto,
    fontWeight: fonts.fontWeight.medium,
    display: 'flex',
    color: colors.actionIconsColor,
    paddingLeft: '0vw',
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingTop: '2vw',
  },
  dropdownWrapper: {
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      transform: 'translate(0vw, -1vw)',
      margin: '0px 10px !important',
    },
  },
  viewLogs: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    fontFamily: fonts.fontType.roboto,
    fontWeight: fonts.fontWeight.medium,
    display: 'flex',
    color: colors.actionIconsColor,
    paddingLeft: '3.5vw',
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingTop: '3vw',
  },
  logText: {
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      transform: 'translate(0vw, -1vw)',
      margin: '0px 10px !important',
      backgroundColor: 'white',
    },
    marginTop: '20px !important',
  },
  search: {
    '& .MuiTextField-root': {
      marginLeft: '7% !important',
    },
  },
  subject: {
    '& MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '11.5px 14px !important',
    },
  },
  editorClass: {
    border: '1px solid #F1F1F1',
    height: '40vh',
    padding: '10px',
    fontWeight: 'Bold',
  },
  heading: {
    fontSize: 20,
    fontWeight: 600,
    marginRight: 2,
  },
  slash: {
    marginLeft: 2,
  },
  termsHeading: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 2,
  },
  actionButtons: {
    marginTop: 30,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 30,
    width: '100%',
  },
  editBtn: {
    '@media (min-width: 1200px)': {
      width: '6vw !important',
    },
  },
  textEditor: {
    /* Estilos para el componente TextEditor */
    width: '100%',
    minHeight: '200px',
    border: '1px solid #ddd',
    padding: '10px',
    fontSize: '16px',
  },
  substitutionTagList: {
    '@media (min-width: 1200px)': {
      width: '100%',
      maxWidth: 360,
    },
    '@media (max-width: 1200px)': {
      width: '100%',
    },
    backgroundColor: '#fafafa',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    '& ul': { padding: 0 },
  },
  substitutionTagListHeader: {
    fontWeight: 'bold', color: 'black', fontSize: '20px', backgroundColor: '#dbf1fb', borderBottom: '2px inset', borderColor: '#b5b5b5',
  },
  listItemButton: {
    width: '100%',
  },
  listItem: {
    borderBottom: '2px inset', padding: '0', backgroundColor: '#f8f8f8',
  },
  emailEditor: {
    maxWidth: '90%',
    '& .toolbarClassName': {
      backgroundColor: colors.secondary,
    },
    '& .rdw-option-wrapper, .rdw-dropdown-wrapper': {
      border: `0.1vw solid ${colors.backgroundGrey}`,
      height: '1.5vw',
      padding: '0.5vw',
      fontSize: '0.9vw',
      '@media (max-width: 900px)': {
        border: 'none',
        height: 'auto',
        padding: '8px',
        fontSize: '12px',
      },
      '& .rdw-dropdown-selectedtext': {
        padding: '0 1.5vw 0 0.5vw',
      },
    },
    '@media (max-width: 600px)': {
      margin: '8px 0.8vw 0.8vw 0',
    },
  },
});

export default style;
