/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { InputLabel } from '@mui/material';
import useStyles from '../../../custom-hooks/useStyles';
import style from './style';

function AddressAutoComplete({
  onChange, value, label, onPlaceSelected, error, onBlur, className, variant,
  customClass, innerCustomClass, showLabel, id, shrink,
}) {
  const classes = useStyles(style)();
  return (
    <div>
      <FormControl
        className={!error ? `${classes[customClass]} ${className || classes.formControl}` : `${className || classes.formControl}`}
        sx={{
          m: {
            lg: 1,
          },
          width: 200,
        }}
        style={{ marginBottom: '-3px' }}
      >
        {(showLabel)
          && <InputLabel shrink={value && shrink} id={id}>{label}</InputLabel>}
        <Autocomplete
          label={label}
          placeholder={!value && label}
          value={value}
          onPlaceSelected={onPlaceSelected}
          onChange={onChange}
          className={clsx(
            innerCustomClass === '' || typeof innerCustomClass === 'undefined' ? !isMobile ? classes.addressAutoComplete : classes.addressAutoCompleteMobile : '',
            error ? classes.error : '',
            classes[innerCustomClass],
          )}
          options={{
            types: ['geocode', 'establishment'],
          }}
          id="addressAutoComplete"
          onBlur={onBlur}
          variant={variant}
        />
      </FormControl>
    </div>
  );
}

export default AddressAutoComplete;
