/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Divider, TableContainer, Table,
  TableRow, TableCell, TableHead, TableBody, TextField, Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DoneAll from '@mui/icons-material/DoneAll';
import useStyles from '../../../../../custom-hooks/useStyles';
import { DialogAtom } from '../../../../../components/atoms';
import { Buttons } from '../../../../../constant';
import ButtonAtom from '../../../../../components/atoms/button';
import styles from './styles';
import Loader from '../../../../../components/atoms/loader';
import { getAssignmentByClassroomInformation } from '../../../../../store/actions/getAdmin';

function AssignmentStatusDialog({ isOpen, onDialogClose, selectedAssignment, viewEditAssignment, setSelectedAssignmentToEdit, setClassroomIds}) {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [assignmentInfo, setAssignmentInfo] = useState();
  const [extraData, setExtraData] = useState();
  const [loading, setLoading] = useState();

  const filteredTeachers = extraData?.teachers?.teachers.filter(
    (row) => row?.profile?.emailAddress !== 'dheeraj.kumar@manabadi.siliconandhra.org'
  );

  useEffect(() => {
    if (selectedAssignment) {
      setLoading(true);
      const payload = {
        courseId: selectedAssignment.courseId,
        title: selectedAssignment.title,
        description: selectedAssignment.description,
        assignmentId: selectedAssignment.id,
        ownerId: selectedAssignment.creatorUserId,
      }
      dispatch(getAssignmentByClassroomInformation(payload, setAssignmentInfo, setExtraData, setLoading));
    }
  }, [selectedAssignment]);

  useEffect(() => {
    if (assignmentInfo) {
      assignmentInfo.description = selectedAssignment?.description;
      assignmentInfo.title = selectedAssignment?.title;
      assignmentInfo.defaultScore = selectedAssignment?.maxPoints;
      setSelectedAssignmentToEdit(assignmentInfo);
    }
  }, [assignmentInfo]);

  return ( 
      loading ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : (
        <DialogAtom
          isOpen={isOpen}
          secHandle={onDialogClose}
          dialogHeading={t('ASSIGNMENT_PUBLISHED')}
          customClass={classes.assignmentDialogAtom}
          content={(
            <Box>
              <Box className={classes.assignmentDetails}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <div className={classes.buttonDiv}>
                      <ButtonAtom
                        className={classes.buttonDiv}
                        name={t('REPUBLISH_ASSIGNMENT')}
                        onClick={() => {
                          setClassroomIds({ courseId: selectedAssignment.courseId, assignmentId: selectedAssignment.id });
                          viewEditAssignment(true);
                        } }
                        btntype={Buttons.PRIMARY} />
                    </div>
                  </Grid>
                </Grid>
                <Divider />
                <Box>
                  <Typography style={{ marginBottom: '10px' }}>
                    <b className={classes.menssageHeader}>{`${t('CLASS')}:`}</b>
                    {selectedAssignment?.section}
                    <Divider />
                  </Typography>
                  <Typography style={{ marginBottom: '10px' }}>
                    <b className={classes.menssageHeader}>{`${t('TITLE')}:`}</b>
                    {' '}
                    {selectedAssignment?.title}
                    <Divider />
                  </Typography>
                  <Typography style={{ marginBottom: '10px' }}>
                    <b className={classes.menssageHeader}>{`${t('STATUS')}:`}</b>
                    {' '}
                    {' '}
                    {selectedAssignment?.state}
                    {' '}
                    {selectedAssignment?.state === 'PUBLISHED' ? (<DoneAll className={classes.iconGreen} />) : (<DoneAll className={classes.iconRed} />)}
                    <Divider />
                  </Typography>
                  <Typography style={{ marginBottom: '10px' }}>
                    <b className={classes.menssageHeader}>{`${t('ASSIGNMENT_PUBLISHER')}:`}</b>
                    {' '}
                    {' '}
                    {extraData?.assignmentCreator?.profile?.name?.fullName}
                    <Divider />
                  </Typography>
                </Box>
                <Grid container className={classes.editorClassContainer}>
                  <Typography style={{ marginBottom: '10px' }}>
                    <b className={classes.menssageHeader}>{`${t('DESCRIPTION')}:`}</b>
                    <TextField
                      required
                      className={classes.descriptionField}
                      id="standard-multiline-static-required"
                      multiline
                      rows={10}
                      size="small"
                      name="description"
                      value={selectedAssignment?.description}
                      validationOnChange={false}
                      validateOnBlur={false} />
                  </Typography>
                </Grid>
                </Box><TableContainer className={classes.tableBox}>
                  <Table sx={12} aria-label="simple table" className={classes.analytics}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className={classes.sentTo}>{t('PRIMARY_TEACHER_NAME')}</TableCell>
                        <TableCell align="center" className={classes.read}>{t('PRIMARY_TEACHER_EMAIL')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.assignmentStatusTable}>
                      {filteredTeachers && Array.isArray(filteredTeachers) && filteredTeachers.map((row) => (
                        <TableRow key={row?.profile?.id}>
                          <TableCell component="th" scope="row" align="center">
                            {row?.profile?.name?.fullName}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row?.profile?.emailAddress}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
            </Box>
          )}
        />
      )
  );
}

export default AssignmentStatusDialog;
