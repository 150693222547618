/* eslint-disable no-param-reassign */
/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import {
  Box, Grid, Typography, Stack,
  TableContainer, Table, TableHead,
  TableRow, TableCell, TableBody,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import {
  parseISO,
} from 'date-fns/esm';
import moment from 'moment';
import styles from './style';
import { getCurrentYear, isValidSemesterYear } from '../../../utils/methods';
import useStyles from '../../../custom-hooks/useStyles';
import Dropdown from '../../../components/atoms/dropdown';
import ButtonAtom from '../../../components/atoms/button';
import TextFieldAtom from '../../../components/atoms/text-field-with-label';
import { Buttons, NavigateRoutes } from '../../../constant';
import DatePickerAtom from '../../../components/atoms/datepicker';
import {
  getAllAcademicYearsStatus,
  getAcademicYearDatesByYear,
  getAcademicYearDates,
  postAcademicYearsStatus,
  academicYearDates,
} from '../../../store/actions/getLocationCoordinator';
import Loader from '../../../components/atoms/loader';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import { AntSwitch } from '../../../utils/commonUiComponent';
import DialogAtomSheeet from '../../location-coordinator/marks-sync/dialogSheet';

const HEADERS = ['S.No', 'CURRICULUM', 'ACADEMIC_START_DATE',
  'ACADEMIC_END_DATE'];

export default function RegistrationDates() {
  const classes = useStyles(styles)();
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDobCutoff = getLocalStorage('currentDobCutoff');
  const [rows, setRows] = useState([]);
  const [rowsAcademic, setAcademicRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [academicEndDateError, setAcademicEndDateError] = useState(null);
  const [academicStartDateError, setAcademicStartDateError] = useState(null);
  const [dobCutoffDateError, setDobCutoffDateError] = useState(null);
  const [dobCutoffStartDate, setDobCutoffStartDate] = useState(null);
  const [dobCutoffEndDate, setDobCutoffEndDate] = useState(null);
  const [quarterStartDateError, setQuarterStartDateError] = useState(null);
  const [quarterEndDateError, setQuarterEndDateError] = useState(null);
  const [academicError, setAcademicError] = useState(null);
  const [isNewAcademicYear, setIsNewAcademicYear] = useState(false);
  const { locationCoordinator } = useSelector((state) => ({
    locationCoordinator: state?.getLocationCoordinator,
  }));
  const [assignedYears, setAssignedYears] = useState([]);
  const [previousAssignedYears, setPreviousAssignedYears] = useState([]);
  const [isacademicDates, setacademicDates] = useState({});
  const [selectedAssignedYear, setSelectedYear] = useState('');
  const [minAcademicDate, setMinAcademicDate] = useState('');
  const [maxAcademicDate, setMaxAcademicDate] = useState('');
  const [newAcademicYear, setNewAcademicYear] = useState('');
  const lastDateRef = useRef(null);
  const [minStartDate, setMinStartDate] = useState(null);
  const [maxEndDate, setMaxEndDate] = useState(null);
  const [isSetRowError, setRowError] = useState(false);
  const [validSemesterYear, setValidSemesterYear] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [cleanLog, setCleanLog] = useState(false);
  const assignedAcademicData = locationCoordinator?.assignedAcademicYearDatesByYear;
  const [ongoingYear, setOngoingYear] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogError, setOpenDialogError] = useState(false);
  const [dobCutoff, setDobCutoff] = useState('01/01/2023');
  const [refreshPage, setRefreshPage] = useState(false);
  const [hasLogText, setHasLogText] = useState(false);
  const formik = useFormik({
    initialValues: {
      logText: '',
    },
  });
  const sortByDate = (a, b) => {
    if (a.quarter < b.quarter) {
      return -1;
    }
    if (a.quarter > b.quarter) {
      return 1;
    }
    return 0;
  };

  const getQuarterValues = () => {
    const quarterNullValue = validSemesterYear ? [
      {
        quarter: 'S1',
        id: 1,
      },
      {
        quarter: 'S2',
        id: 2,
      },
    ] : [
      {
        quarter: 'Q1',
        id: 1,
      },
      {
        quarter: 'Q2',
        id: 2,
      },
      {
        quarter: 'Q3',
        id: 3,
      },
    ];

    return quarterNullValue;
  };

  const setQuarterDates = () => {
    const quarterNullValue = getQuarterValues();
    const mewSubRowdata = [];
    const years = newAcademicYear.id?.split('-') || selectedAssignedYear.split('-');
    quarterNullValue.forEach((newdetails, index) => {
      if (newdetails.id !== null) {
        mewSubRowdata.push({
          quarter: newdetails.quarter,
          sNo: (index + 1),
          startDate: '',
          endDate: '',
          id: index,
          valueChanged: false,
        });
      }
    });
    setRows(mewSubRowdata);
    setAcademicError(null);
  };

  const refreshAcademicDates = () => {
    setRows([]);
    setAcademicRows([]);
    const rowsCopyNewAcademic = {
      sNo: 1,
      startDate: null,
      endDate: null,
      academicYearName: 'Academic year',
      valueChanged: false,
    };
    setAcademicRows([rowsCopyNewAcademic]);
    setQuarterDates();
  };

  useEffect(() => {
    const loadFalse = () => setLoading(false);
    dispatch(getAllAcademicYearsStatus(loadFalse));
  }, []);

  useEffect(() => {
    if (locationCoordinator?.assignedAcademicYearDates?.id) {
      const loadFalse = () => setLoading(false);
      dispatch(getAllAcademicYearsStatus(loadFalse));
      const payload = {
        year: locationCoordinator?.assignedAcademicYearDates?.academicYear,
      };
      dispatch(getAcademicYearDatesByYear(payload));
      setIsNewAcademicYear(false);
    }
  }, [locationCoordinator?.assignedAcademicYearDates]);

  useEffect(() => {
    const year = selectedAssignedYear.split('-')[0] || '2023';
    const dobCutoff = currentDobCutoff !== null && currentDobCutoff !== undefined && currentDobCutoff !== 'Invalid date' ? currentDobCutoff : `01/01/${year}`;
    setDobCutoff(dobCutoff);
  }, [currentDobCutoff]);

  const handleDobCutoff = (date) => {
    if (moment(date).isAfter(moment(dobCutoffStartDate)) && moment(date).isSameOrBefore(moment(dobCutoffEndDate))) {
      setDobCutoff(date);
      setDobCutoffDateError(null);
    } else {
      setDobCutoff(date);
      setDobCutoffDateError(t('DOB_CUTOFF_ERROR'));
    }
  }

  useEffect(() => {
    if (selectedAssignedYear) {
      setSuccessMsg('');
      const payload = {
        year: selectedAssignedYear,
      };
      const splitedYear = selectedAssignedYear.split('-');
      if (splitedYear.length) {
        if (splitedYear[0]) {
          setMinStartDate(moment().set(
            {
              date: 1,
              months: 0,
              year: splitedYear[0],
              hour: 0,
              minute: 0,
              second: 0,
            },
          ));
        }

        if (splitedYear[1]) {
          setMaxEndDate(moment().set(
            {
              date: 31,
              months: 11,
              year: splitedYear[1],
              hour: 23,
              minute: 59,
              second: 59,
            },
          ));
        }
      }
      if (!isNewAcademicYear || newAcademicYear.id !== selectedAssignedYear) {
        dispatch(getAcademicYearDatesByYear(payload, setIsNewAcademicYear));
      }
    }
    if (selectedAssignedYear === getCurrentYear()?.id) {
      setOngoingYear(true);
    } else {
      setOngoingYear(false);
    }
  }, [selectedAssignedYear]);

  const getCurrentAcademicYearDates = () => {
    const currentYear = getCurrentYear()?.id;
    const selectedYear = assignedYears.filter((opt) => opt?.id === currentYear.toString());
    if (((assignedYears || []).length > 0)) {
      setSelectedYear(selectedYear[0]?.id);
      setValidSemesterYear(isValidSemesterYear(selectedYear[0]?.id));
    }
  };

  useEffect(() => {
    if (!isNewAcademicYear) {
      getCurrentAcademicYearDates();
    }
  }, [assignedYears]);

  useEffect(() => {
    if (!selectedAssignedYear) {
      return;
    }
    const minYear = selectedAssignedYear.split('-')[0];
    const maxYear = selectedAssignedYear.split('-')[1];
    const minDate = parseISO(`${minYear}-01-01`) || null;
    const maxDate = parseISO(`${maxYear}-12-31`) || null;
    setMinAcademicDate(minDate);
    setMaxAcademicDate(maxDate);
  }, [selectedAssignedYear]);

  useEffect(() => {
    if ((locationCoordinator?.academicYears || []).length > 0) {
      let years = locationCoordinator?.academicYears.map(
        (year) => ({ id: year, name: year }),
      );
      years = years.sort((a, b) => {
        const year1 = a.id.split('-')[0];
        const year2 = b.id.split('-')[0];
        return parseInt(year2, 10) - parseInt(year1, 10);
      });
      const newYear = years.slice(0, -1);
      setAssignedYears(newYear);
    } else {
      const obj = getCurrentYear();
      setAssignedYears([obj]);
    }
  }, [locationCoordinator?.academicYears]);

  useEffect(() => {
    if (rows.length === 0) {
      return;
    }
    const errValidations = rows.reduce((validationsObj, row) => {
      validationsObj[row.id] = {
        startDate: {
          isDirty: false,
          errors: [],
        },
        endDate: {
          isDirty: false,
          errors: [],
        },
        hasError: false,
      };
      return validationsObj;
    }, {});
    setErrors(errValidations);
  }, [rows]);

  const checkValidationForQuaterRows = () => {
    const validations = { ...errors };
    for (let i = 0; i < rows.length; i++) {
      if (i > 0) {
        //  For Start Date
        if (rows[i].startDate) {
          if (rows[i]?.valueChanged) {
            validations[rows[i].id].startDate.isDirty = true;
          }
          validations[rows[i].id].hasError = false;
          const validDate = moment(rows[i].startDate).isValid();
          if (validDate) {
            validations[rows[i].id].hasError = false;
            const startDate = moment(rows[i]?.startDate).format('MM/DD/YYYY');
            const endDate = moment(rows[i - 1]?.endDate).format('MM/DD/YYYY');
            const academicStartDate = moment(rowsAcademic[0].startDate).format('MM/DD/YYYY');
            const academicEndDate = moment(rowsAcademic[0].endDate).format('MM/DD/YYYY');
            const selectStartYear = startDate.split('/');
            const academicMinYear = academicStartDate.split('/');
            if (parseInt(selectStartYear[2], 10) >= parseInt(academicMinYear[2], 10)) {
              if (moment(rows[i]?.startDate).isBetween(moment(academicStartDate), moment(academicEndDate))
                && moment(startDate).isAfter(endDate)) {
                validations[rows[i].id].startDate.isDirty = false;
                validations[rows[i].id].hasError = false;
                setRowError(true);
              } else {
                validations[rows[i].id].startDate.errors.unshift(t('Selected Date is not valid'));
                validations[rows[i].id].hasError = true;
                setRowError(false);
              }
            } else {
              validations[rows[i].id].startDate.errors.unshift(t('Selected Date is not valid'));
              validations[rows[i].id].hasError = true;
              setRowError(false);
            }
          } else {
            validations[rows[i].id].startDate.errors.unshift(t('Invalid Date'));
            validations[rows[i].id].hasError = true;
          }
        } else {
          validations[rows[i].id].startDate.errors.unshift(t('Start date is required'));
          validations[rows[i].id].hasError = true;
        }

        //  For End date
        if (rows[i].endDate) {
          if (rows[i]?.valueChanged) {
            validations[rows[i].id].endDate.isDirty = true;
          }
          validations[rows[i].id].hasError = false;
          const validEndDate = moment(rows[i].endDate).isValid();
          if (validEndDate) {
            validations[rows[i].id].hasError = false;
            const endDate = moment(rows[i].endDate).format('MM/DD/YYYY');
            const startDate = moment(rows[i].startDate).format('MM/DD/YYYY');
            const academicStartDate = moment(rowsAcademic[0].startDate).format('MM/DD/YYYY');
            const academicEndDate = moment(rowsAcademic[0].endDate).format('MM/DD/YYYY');
            const selectEndDate = endDate.split('/');
            const selectedStartDate = startDate.split('/');
            if (parseInt(selectEndDate[2], 10) >= parseInt(selectedStartDate[2], 10)) {
              if (moment(endDate).isAfter(moment(academicStartDate))
                && (moment(endDate).isSameOrBefore(moment(academicEndDate)))
                && moment(endDate).isAfter(startDate)) {
                validations[rows[i].id].endDate.isDirty = false;
                validations[rows[i].id].hasError = false;
                setRowError(true);
              } else {
                validations[rows[i].id].endDate.errors.unshift(t('Selected Date is not valid'));
                validations[rows[i].id].hasError = true;
                setRowError(false);
              }
            } else {
              validations[rows[i].id].endDate.errors.unshift(t('Selected Date is not valid'));
              validations[rows[i].id].hasError = true;
              setRowError(false);
            }
          } else {
            validations[rows[i].id].endDate.errors.unshift(t('Invalid Date'));
            validations[rows[i].id].hasError = true;
          }
        } else {
          validations[rows[i].id].endDate.errors.unshift(t('End date is required'));
          validations[rows[i].id].hasError = true;
        }
      }
      if (i === 0) {
        // For start date
        if (rows[i].startDate) {
          if (rows[i]?.valueChanged) {
            validations[rows[i].id].startDate.isDirty = true;
          }
          const validDate = moment(rows[i].startDate).isValid();
          if (validDate) {
            validations[rows[i].id].hasError = false;
            const startDate = moment(rows[i].startDate).format('MM/DD/YYYY');
            const academicStartDate = moment(rowsAcademic[0].startDate).format('MM/DD/YYYY');
            const academicEndDate = moment(rowsAcademic[0].endDate).format('MM/DD/YYYY');
            const selectStartYear = startDate.split('/');
            const academicMinYear = academicStartDate.split('/');
            if (parseInt(selectStartYear[2], 10) >= parseInt(academicMinYear[2], 10)) {
              if (moment(startDate).isSameOrAfter(moment(academicStartDate))
                && moment(startDate).isSameOrBefore(moment(academicEndDate))) {
                validations[rows[i].id].startDate.isDirty = false;
                validations[rows[i].id].hasError = false;
                setRowError(true);
              } else {
                validations[rows[i].id].startDate.errors.unshift(t('Selected Date is not valid'));
                validations[rows[i].id].hasError = true;
                setRowError(false);
              }
            } else {
              validations[rows[i].id].startDate.errors.unshift(t('Selected Date is not valid'));
              validations[rows[i].id].hasError = true;
              setRowError(false);
            }
          } else {
            validations[rows[i].id].startDate.errors.unshift(t('Invalid Date'));
            validations[rows[i].id].hasError = true;
          }
        } else {
          validations[rows[i].id].startDate.errors.unshift(t('Start date is required'));
          validations[rows[i].id].hasError = true;
        }
        //  For End date
        if (rows[i].endDate) {
          if (rows[i]?.valueChanged) {
            validations[rows[i].id].endDate.isDirty = true;
          }
          const validEndDate = moment(rows[i].endDate).isValid();
          if (validEndDate) {
            validations[rows[i].id].hasError = false;
            const endDate = moment(rows[i].endDate).format('MM/DD/YYYY');
            const startDate = moment(rows[i].startDate).format('MM/DD/YYYY');
            const selectEndDate = endDate.split('/');
            const selectedStartDate = startDate.split('/');
            if (parseInt(selectEndDate[2], 10) >= parseInt(selectedStartDate[2], 10)) {
              if (moment(endDate).isAfter(moment(rowsAcademic[0].startDate))
                && (moment(endDate).isSameOrBefore(moment(rowsAcademic[0].endDate)))
                && moment(endDate).isAfter(startDate)) {
                validations[rows[i].id].endDate.isDirty = false;
                validations[rows[i].id].hasError = false;
                setRowError(true);
              } else {
                validations[rows[i].id].endDate.errors.unshift(t('Selected Date is not valid'));
                validations[rows[i].id].hasError = true;
                setRowError(false);
              }
            } else {
              validations[rows[i].id].endDate.errors.unshift(t('Selected Date is not valid'));
              validations[rows[i].id].hasError = true;
              setRowError(false);
            }
          } else {
            validations[rows[i].id].endDate.errors.unshift(t('Invalid Date'));
            validations[rows[i].id].hasError = true;
          }
        } else {
          validations[rows[i].id].endDate.errors.unshift(t('End date is required'));
          validations[rows[i].id].hasError = true;
        }
      }
    }
    setErrors(validations);
    return true;
  };

  const checkStartDate = async (validations, courseId) => {
    checkValidationForQuaterRows();
    return validations[courseId];
  };

  const checkEndDate = async (validations, courseId) => {
    await checkValidationForQuaterRows();
    return validations[courseId];
  };

  const showError = (courseId, validations = {}) => {
    if (Object.entries(errors).length === 0) {
      return null;
    }
    let validationErrors = Object.values(validations || errors[courseId]);
    validationErrors = validationErrors.reduce((errArray, err) => {
      if (!err?.errors) {
        return errArray;
      }
      return [...errArray, ...err.errors];
    }, []);

    return (validationErrors.length > 0) ? validationErrors[0] : null;
  };

  const validateRow = (row, action = 'all', selector = '') => {
    const validations = { ...errors };

    const {
      id,
    } = row;

    if (action === 'switch') {
      switch (selector) {
        case 'startDate':
          validations[id] = checkStartDate(validations, id);
          break;
        case 'endDate':
          validations[id] = checkStartDate(validations, id);
          validations[id] = checkEndDate(validations, id);
          break;
        default:
      }
    } else {
      validations[id] = checkStartDate(validations, id);
      validations[id] = checkEndDate(id);
    }
    const hasError = showError(id, validations[id]);
    return !!hasError;
  };

  const handleAcademicYear = () => {
    if (isNewAcademicYear) {
      setAcademicError(t('ACADEMIC_INFO_ERROR'));
      setSelectedYear(newAcademicYear.id);
    } else {
      setErrors({});
      setAcademicError(null);
      const years = assignedYears.map((y) => {
        const year = y.id.replace(/\s/g, '').split('-')[1];
        return Number(year);
      });
      const maxYear = Math.max(...years);
      const obj = { id: `${maxYear}-${maxYear + 1}`, name: `${maxYear}-${maxYear + 1}` };
      setSelectedYear(obj.id);
      setDobCutoff(`01/01/${maxYear}`);
      setPreviousAssignedYears(assignedYears);
      setAssignedYears([...assignedYears, obj]);
      setIsNewAcademicYear(true);
      setNewAcademicYear(obj);
      setValidSemesterYear(isValidSemesterYear(obj.id));
      refreshAcademicDates();
    }
  };
  const showMinDate = (startDate, quarter) => {
    if (!startDate) {
      return minAcademicDate - 1;
    }
    let date = '';
    if (quarter === 'Q1') {
      const date1 = new Date(startDate);
      date = moment(date1).add(0, 'day').toDate();
    } else if (quarter === 'Q2') {
      const dates = rows.find((item) => item.quarter === 'Q1');
      const date1 = new Date(dates?.endDate);
      date = moment(date1).add(1, 'day').toDate();
    } else if (quarter === 'Q3') {
      const dates = rows.find((item) => item.quarter === 'Q2');
      const date1 = new Date(dates?.endDate);
      date = moment(date1).add(1, 'day').toDate();
    } else if (quarter === 'S1') {
      const date1 = new Date(startDate);
      date = moment(date1).add(0, 'day').toDate();
    } else if (quarter === 'S2') {
      const dates = rows.find((item) => item.quarter === 'S1');
      const date1 = new Date(dates?.endDate);
      date = moment(date1).add(1, 'day').toDate();
    }
    return date;
  };

  const showMinEndDate = (startDate, quarter) => {
    if (!startDate) {
      return minAcademicDate;
    }
    let date = '';
    if (quarter === 'Q1') {
      const dates = rows.find((item) => item.quarter === 'Q1');
      const date1 = new Date(dates?.startDate);
      date = moment(date1).add(1, 'day').toDate();
    } else if (quarter === 'Q2') {
      const dates = rows.find((item) => item.quarter === 'Q2');
      const date1 = new Date(dates?.startDate);
      date = moment(date1).add(1, 'day').toDate();
    } else if (quarter === 'Q3') {
      const dates = rows.find((item) => item.quarter === 'Q3');
      const date1 = new Date(dates?.startDate);
      date = moment(date1).add(1, 'day').toDate();
    }
    return date;
  };

  const academicShowMinDate = (startDate) => {
    if (!isacademicDates.startDate) {
      return minAcademicDate;
    }
    const date1 = new Date(startDate);
    const date = moment(date1).add(1, 'day').toDate();
    return date;
  };

  const formatDate = (date) => {
    const d = date?.split('T00:00:00.000Z')?.[0];
    return moment(d.split('T00:00:00.000Z')[0]).format('MM/DD/YYYY');
  };

  const handleClose = (id, selector) => {
    if (!isNewAcademicYear) {
      const validations = { ...errors };
      for (let i = 0; i < rows.length && errors.length > 0; i++) {
        validations[rows[i].id].startDate.isDirty = true;
        validations[rows[i].id].endDate.isDirty = true;
      }
    }
    const quaterAcademicRow = rows[id];
    quaterAcademicRow.valueChanged = true;
    if (selector === 'startDate') {
      quaterAcademicRow.startDate = lastDateRef.current;

    } else {
      quaterAcademicRow.endDate = lastDateRef.current;
    }
    rows[id] = quaterAcademicRow;
    const validatedRows = rows.map((row) => validateRow(row, 'all'));
    if (validatedRows.find((value) => value === true)) {
      return;
    }
    const mainAcademic = rowsAcademic[0];
    setAcademicRows([mainAcademic]);
    setRows(rows);
  };

  const handleChange = (id, newValue, selector, actualDate) => {
    if (newValue) {
      if (!isNewAcademicYear) {
        const validations = { ...errors };
        for (let i = 0; i < rows.length && errors.length > 0; i++) {
          validations[rows[i].id].startDate.isDirty = true;
          validations[rows[i].id].endDate.isDirty = true;
        }
      }
      const quaterAcademicRow = rows[id];
      quaterAcademicRow[selector] = moment(newValue).set({ hour: 2, minute: 0, second: 0 }).toDate();
      quaterAcademicRow.valueChanged = true;
      rows[id] = quaterAcademicRow;
      const validatedRows = rows.map((row) => validateRow(row, 'all'));
      if (validatedRows.find((value) => value === true)) {
        return;
      }
      const mainAcademic = rowsAcademic[0];
      setAcademicRows([mainAcademic]);
      lastDateRef.current = actualDate;
      setRows(rows);
    }
  };

  const academicHandleChange = (newValue, selector, endDate) => {
    if (newValue) {
      if (!isNewAcademicYear) {
        const validations = { ...errors };
        for (let i = 0; i < rows.length && errors.length > 0; i++) {
          validations[rows[i].id].startDate.isDirty = true;
          validations[rows[i].id].endDate.isDirty = true;
        }
      }
      const newAcademicValue = moment(newValue, 'MM/DD/YYYY').isValid();
      let mainAcademic = {
        academicYearName: 'Academic year',
        endDate: null,
        sNo: 0,
        startDate: null,
        valueChanged: false,
      };
      // eslint-disable-next-line prefer-destructuring
      mainAcademic = rowsAcademic[0] ? rowsAcademic[0] : [];

      mainAcademic.valueChanged = true;
      if (selector === 'startDate') {
        if (!newAcademicValue) {
          setAcademicStartDateError(null);
          setQuarterStartDateError(t('INVALID_DATE'));
        } else {
          mainAcademic[selector] = newValue;
          const academicStartDate = moment(newValue).format('MM/DD/YYYY');
          setDobCutoffStartDate(academicStartDate);
          const minYear = moment(minStartDate).format('MM/DD/YYYY');
          const maxYear = moment(maxEndDate).format('MM/DD/YYYY');

          const academicStartYear = academicStartDate.split('/');
          const academicMinYear = minYear.split('/');
          if (parseInt(academicStartYear[2], 10) >= parseInt(academicMinYear[2], 10)) {
            if (moment(academicStartDate).isAfter(minYear) && moment(academicStartDate).isSameOrBefore(maxYear)) {
              if (moment(academicStartDate) >= moment(endDate).set({ hour: 0, minute: 0, second: 0 })) {
                setQuarterStartDateError(null);
                setAcademicStartDateError(t('ENDDATE_GREATER_THAN_STARTDATE'));
              } else {
                setQuarterStartDateError(null);
                setAcademicStartDateError(null);
              }
            } else {
              setQuarterStartDateError(null);
              setAcademicStartDateError(t('Please choose date between selected year'));
            }
          } else {
            setQuarterStartDateError(null);
            setAcademicStartDateError(t('Please choose date between selected year'));
          }
        }
      }
      if (selector === 'endDate') {
        if (!newAcademicValue) {
          setAcademicEndDateError(null);
          setQuarterEndDateError(t('INVALID_DATE'));
        } else {
          mainAcademic[selector] = newValue;
          const academicEndDate = moment(newValue).format('MM/DD/YYYY');
          setDobCutoffEndDate(academicEndDate);
          const minYear = moment(minStartDate).format('MM/DD/YYYY');
          const maxYear = moment(maxEndDate).format('MM/DD/YYYY');

          const academicEndYear = academicEndDate.split('/');
          const academicMinYear = minYear.split('/');
          if (parseInt(academicEndYear[2], 10) >= parseInt(academicMinYear[2], 10)) {
            if (moment(academicEndDate).isAfter(minYear) && moment(academicEndDate).isSameOrBefore(maxYear)) {
              if (moment(academicEndDate) <= moment(rowsAcademic[0].startDate).set({ hour: 23, minute: 58, second: 58 })) {
                setQuarterEndDateError(null);
                setAcademicEndDateError(t('ENDDATE_GREATER_THAN_STARTDATE'));
              } else {
                setQuarterEndDateError(null);
                setAcademicEndDateError(null);
              }
            } else {
              setQuarterEndDateError(null);
              setAcademicEndDateError(t('Please choose date between selected year'));
            }
          } else {
            setQuarterEndDateError(null);
            setAcademicEndDateError(t('Please choose date between selected year'));
          }
        }
      }

      const validatedRows = rows.map((row) => validateRow(row, 'all'));
      if (validatedRows.find((value) => value === true)) {
        return;
      }
      setAcademicRows([mainAcademic]);
    }
  };

  useEffect(() => {
    if (cleanLog) {
      formik.setFieldValue('logText', '');
      setCleanLog(false);
    }
  }, [cleanLog]);

  useEffect(() => {
    if (formik.values.logText !== '' && formik.values.logText.length > 2) {
      setHasLogText(true);
    } else {
      setHasLogText(false);
    }
  }, [formik.values.logText]);

  useEffect(() => {
    if (assignedAcademicData?.id) {
      setacademicDates(assignedAcademicData);
      const rowsCopyAcademic = {
        sNo: 1,
        startDate: formatDate(assignedAcademicData?.startDate),
        endDate: formatDate(assignedAcademicData?.endDate),
        id: assignedAcademicData.id,
        academicYearName: 'Academic year',
      };

      setAcademicRows([rowsCopyAcademic]);
      const newData = assignedAcademicData?.academicYearQuarterDates;
      const setAscending = newData.sort(sortByDate);
      const subRowdata = [];
      setAscending.forEach((details, index) => {
        if (details.id !== null) {
          subRowdata.push({
            quarter: details.quarter,
            sNo: (index + 1),
            startDate: formatDate(details?.startDate),
            endDate: formatDate(details?.endDate),
            id: details.id,
          });
        }
      });
      if (subRowdata.length > 0) {
        setRows(subRowdata);
      } else {
        setQuarterDates();
      }
      setAcademicError(null);
      academicHandleChange(formatDate(assignedAcademicData?.startDate), 'startDate', rowsCopyAcademic.endDate);
      academicHandleChange(formatDate(assignedAcademicData?.endDate), 'endDate', rowsCopyAcademic.startDate);
    } else {
      refreshAcademicDates();
    }
  }, [assignedAcademicData]);

  const save = () => {
    const validations = { ...errors };
    for (let i = 0; i < rows.length; i++) {
      if (!(rows[i]?.valueChanged)) {
        validations[rows[i].id].startDate.isDirty = true;
        validations[rows[i].id].startDate.errors.unshift(t('Start date is required'));
        validations[rows[i].id].hasError = true;
        validations[rows[i].id].endDate.isDirty = true;
        validations[rows[i].id].endDate.errors.unshift(t('End date is required'));
        validations[rows[i].id].hasError = true;
        setRowError(false);
      }
    }
    const validatedRows = rows.map((row) => validateRow(row, 'all'));
    if (validatedRows.find((value) => value === true)) {
      return;
    }
    if (isNewAcademicYear) {
      const academicYearQuarterDates = rows.map((row) => {
        return {
          quarter: row?.quarter,
          startDate: row?.startDate ? moment(row?.startDate).format('YYYY-MM-DD') : '',
          endDate: row?.endDate ? moment(row?.endDate).format('YYYY-MM-DD') : '',
        };
      });
      const newPayload = {
        academicYear: selectedAssignedYear,
        startDate: moment(rowsAcademic[0].startDate).format('YYYY-MM-DD'),
        endDate: moment(rowsAcademic[0].endDate).format('YYYY-MM-DD'),
        academicYearQuarterDates,
      };
      if (dobCutoff) {
        newPayload.dobCutoff = dobCutoff;
      }
      if (isSetRowError && dobCutoffDateError === null) {
        if (rowsAcademic[0].startDate && rowsAcademic[0].endDate) {
          dispatch(academicYearDates(newPayload));
        }
      }
    } else {
      let payload = {};
      if (!validSemesterYear) {
        payload = {
          academicYearDates: {
            startDate: moment(rowsAcademic[0].startDate).format('YYYY-MM-DD'),
            endDate: moment(rowsAcademic[0].endDate).format('YYYY-MM-DD'),
          },
          Q1: {
            startDate: rows[0]?.startDate ? moment(rows[0].startDate).format('YYYY-MM-DD') : '',
            endDate: rows[0]?.endDate ? moment(rows[0].endDate).format('YYYY-MM-DD') : '',
          },
          Q2: {
            startDate: rows[1]?.startDate ? moment(rows[1].startDate).format('YYYY-MM-DD') : '',
            endDate: rows[1]?.endDate ? moment(rows[1].endDate).format('YYYY-MM-DD') : '',
          },
          Q3: {
            startDate: rows[2]?.startDate ? moment(rows[2].startDate).format('YYYY-MM-DD') : '',
            endDate: rows[2]?.endDate ? moment(rows[2].endDate).format('YYYY-MM-DD') : '',
          },
          logText: formik.values.logText,
        };
      } else {
        payload = {
          academicYearDates: {
            startDate: moment(rowsAcademic[0].startDate).format('YYYY-MM-DD'),
            endDate: moment(rowsAcademic[0].endDate).format('YYYY-MM-DD'),
          },
          S1: {
            startDate: rows[0]?.startDate ? moment(rows[0].startDate).format('YYYY-MM-DD') : '',
            endDate: rows[0]?.endDate ? moment(rows[0].endDate).format('YYYY-MM-DD') : '',
          },
          S2: {
            startDate: rows[1]?.startDate ? moment(rows[1].startDate).format('YYYY-MM-DD') : '',
            endDate: rows[1]?.endDate ? moment(rows[1].endDate).format('YYYY-MM-DD') : '',
          },
          logText: formik.values.logText,
        };
      }

      setLoading(true);
      let iserror = false;
      rows.forEach((obj) => {
        if (errors[obj.id]?.startDate?.isDirty) {
          iserror = true;
        } else if (errors[obj.id]?.endDate?.isDirty) {
          iserror = true;
        } else if (dobCutoffDateError !== null) {
          iserror = true;
        }
      });
      if (dobCutoff) {
        payload.academicYearDates.dobCutoff = dobCutoff;
      }
      if (!iserror) {
        dispatch(getAcademicYearDates(payload, selectedAssignedYear, getCurrentAcademicYearDates, setLoading, setSuccessMsg, setCleanLog));
      } else {
        setLoading(false);
      }
    }
  };

  const viewLogs = () => {
    navigate(NavigateRoutes.STUDENTS_LOGS, {
      state: {
        id: ' ',
        lastRoute: NavigateRoutes.ACADEMIC_DATES,
        logVal: false,
        isAcademicDates: true,
      },
    });
  };

  const statusYear = () => {
    const years = previousAssignedYears.length > 0 ? previousAssignedYears : assignedYears;
    if ((!(selectedAssignedYear === getCurrentYear()?.id)) && (years.filter((year) => year.id === selectedAssignedYear).length > 0)) {
      setOngoingYear(true);
      const userId = localStorage.getItem('userId');
      const payload = {
        currentYear: selectedAssignedYear,
        lastCurrentYear: getCurrentYear()?.id,
        userId: userId,
      };
      const loadFalse = () => setLoading(false);
      dispatch(postAcademicYearsStatus(payload, loadFalse, setRefreshPage));
      handleCancelDialog();
    } else if (!(years.filter((year) => year.id === selectedAssignedYear).length > 0)) {
      setOpenDialogError(true);
    }
  };

  useEffect(() => {
    if (refreshPage) {
      window.location.reload(false);
      setRefreshPage(false);
    }
  }, [refreshPage]);

  const handleCancelDialog = () => {
    setOpenDialog(false);
    setOpenDialogError(false);
    setLoading(false);
  };

  const confirmYearStatus = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('CONFIRM_YEAR_STATUS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={handleCancelDialog}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={statusYear}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const confirmYearStatusError = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classes.alert} role="alert">
            {t('YEAR_STATUS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={handleCancelDialog}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={handleCancelDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.gridContainer}>
      <Grid container className={classes.titleRow}>
        <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
          <Typography className={classes.headerTitle}>
            {t('SETTINGS')}
          </Typography>
          <Typography className={classes.subHeaderTitle}>
            {' / '}
          </Typography>
          <Typography className={classes.subHeaderTitle}>
            {t('ACADEMIC_DATES')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.dropdownWrapper}>
        <Grid item xs={4} lg={8} md={4}>
          <Dropdown
            id="year"
            variant="standard"
            name="year"
            value={selectedAssignedYear || ''}
            options={assignedYears}
            customClass={classes.dropdown}
            handleChange={(e) => {
              setSelectedYear(e.target.value);
              setValidSemesterYear(isValidSemesterYear(e.target.value));
            }}
            customSelectClass={classes.select}
            changeCss
            customFormControlCss={{ width: '100%' }}
            label="year"
            shrink={false}
          />
          { <div className={classes.datePicke}>
            <DatePickerAtom
              wrapperClassName={classes.datePicke}
              label={`${t('DoB Cutoff')}*`}
              minWidth="100%"
              id={`DoB-Cutoff`}
              name={`DoB-Cutoff`}
              value={dobCutoff}
              type="date"
              onChange={(newDate) => handleDobCutoff(newDate)}
              minDate={minAcademicDate}
              maxDate={maxAcademicDate}
            />
            {dobCutoffDateError && (
              <Typography className={classes.errorText}>{dobCutoffDateError}</Typography>
            )}
          </div> }
        </Grid>
        { <Grid item xs={4} lg={2} md={4}>
          <Typography className={classes.switchHeading}>{t('SELECTED_YEAR')}</Typography>
          <Stack className={classes.switchUi} spacing={1}>
            <Typography className={`${classes.switchText} ${!ongoingYear ? classes.inActiveText : null}`}>{t('INACTIVE_STATUS')}</Typography>
            <AntSwitch checked={ongoingYear} onChange={() => setOpenDialog(!openDialog)} inputProps={{ 'aria-label': 'ant design' }} />
            <Typography className={`${classes.switchText} ${ongoingYear ? classes.activeText : null}`}>{t('ACTIVE_STATUS')}</Typography>
          </Stack>
        </Grid> }
        <Grid className={classes.addAcademicBtn} xs={4} md={4} lg={2}>
          <ButtonAtom
            name={t('ADD_ACADEMIC_YEAR')}
            btntype={Buttons.PRIMARY}
            type={Buttons.PRIMARY}
            onClick={() => handleAcademicYear()}
            icon={<AddIcon />}
          />
        </Grid>
      </Grid>
      {
        academicError && (
          <Grid item mt={1} container justifyContent="flex-start">
            <Typography className={classes.academicErrorText} sx={{ paddingLeft: '0 !important' }}>{academicError}</Typography>
          </Grid>
        )
      }
      {
        loading ? (
          <Grid>
            <Loader message={t('LOADING')} />
          </Grid>
        )
          : (
            <>
              <Grid mt={6} item container>
                <TableContainer>
                  <Table sx={{ minWidth: 650, height: '150px' }} aria-label="simple table" className={classes.registrationTable}>
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        {
                          HEADERS.map((h) => (<TableCell>{t(h)}</TableCell>))
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {rowsAcademic && rowsAcademic.map((row, index) => (
                        <TableRow>
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="td" scope="row">
                              {row?.sNo}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row?.academicYearName}
                            </TableCell>
                            <TableCell className={classes.tableCells}>
                              <div className={classes.datePicker}>
                                <DatePickerAtom
                                  wrapperClassName={classes.datePicker}
                                  label={`${t('START_DATE')}*`}
                                  minWidth="100%"
                                  id={`startDate-${row?.sNo}`}
                                  name={`startDate-${row?.sNo}`}
                                  value={row?.startDate}
                                  type="date"
                                  onChange={(newDate) => academicHandleChange(newDate, 'startDate', row?.endDate)}
                                  minDate={minAcademicDate}
                                  maxDate={maxAcademicDate}
                                />
                                {quarterStartDateError && (
                                  <Typography className={classes.errorText}>{quarterStartDateError}</Typography>
                                )}
                                {academicStartDateError && (
                                  <Typography className={classes.errorText}>{academicStartDateError}</Typography>
                                )}
                              </div>
                            </TableCell>
                            <TableCell className={classes.tableCells}>
                              <div className={classes.datePicker}>
                                <DatePickerAtom
                                  wrapperClassName={classes.datePicker}
                                  label={`${t('END_DATE')}*`}
                                  minWidth="100%"
                                  id={`endDate-${row?.sNo}`}
                                  name={`endDate-${row?.sNo}`}
                                  value={row?.endDate}
                                  type="date"
                                  onChange={(newDate) => academicHandleChange(newDate, 'endDate', row?.startDate)}
                                  minDate={academicShowMinDate(row?.startDate)}
                                  maxDate={maxAcademicDate}
                                />
                                {quarterEndDateError && (
                                  <Typography className={classes.errorText}>{quarterEndDateError}</Typography>
                                )}
                                {academicEndDateError && (
                                  <Typography className={classes.errorText}>{academicEndDateError}</Typography>
                                )}
                              </div>

                            </TableCell>
                          </TableRow>
                        </TableRow>
                      ))}
                      {(rowsAcademic[0]?.startDate && rowsAcademic[0]?.endDate && !academicStartDateError && !academicEndDateError && !quarterStartDateError && !quarterEndDateError) && (
                        <TableRow style={{ borderBottom: '1px solid #858585' }}>
                          <TableCell colSpan={4}>
                            <p>{validSemesterYear ? t('SEMESTER_DATES') : t('QUARTERLY_DATES')}</p>
                            {successMsg && (
                              <Box>
                                <Typography className={classes.successText} variant="text">
                                  {successMsg}
                                </Typography>
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {(rowsAcademic[0]?.startDate && rowsAcademic[0]?.endDate && !academicStartDateError
                        && !academicEndDateError && !quarterStartDateError && !quarterEndDateError)
                        && (rows.map((row, index) => (
                          <TableRow
                            style={{ borderLeft: '1px solid #858585', borderRight: '1px solid #858585' }}
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:first-child td, &:first-child th': { border: 0 } }}
                          >
                            <TableCell component="td" scope="row">
                              {row?.sNo}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row?.quarter}
                            </TableCell>
                            <TableCell className={classes.tableCells}>
                              <DatePickerAtom
                                wrapperClassName={classes.datePicker}
                                label={`${t('START_DATE')}*`}
                                minWidth="100%"
                                id={`startDate-${row?.sNo}`}
                                name={`startDate-${row?.sNo}`}
                                value={row?.startDate}
                                type="date"
                                error={errors[row?.id]?.startDate?.isDirty && (((errors[row?.id]?.startDate?.errors)
                                  || []).length > 0)}
                                onChange={(newDate) => handleChange(index, newDate, 'startDate', row?.startDate)}
                                minDate={showMinDate(rowsAcademic[0].startDate, row?.quarter)}
                                maxDate={moment(rowsAcademic[0].endDate).toDate()}
                              />
                              {errors && errors[row?.id]?.startDate?.isDirty && (
                                <Typography className={classes.errorText}>{errors[row?.id]?.startDate?.errors[0]}</Typography>
                              )}
                            </TableCell>
                            <TableCell className={classes.tableCells}>
                              <DatePickerAtom
                                wrapperClassName={classes.datePicker}
                                label={`${t('END_DATE')}*`}
                                minWidth="100%"
                                id={`endDate-${row?.sNo}`}
                                name={`endDate-${row?.sNo}`}
                                value={row?.endDate}
                                type="date"
                                error={errors[row?.id]?.endDate?.isDirty
                                  && ((
                                    (errors[row?.id]?.endDate?.errors)
                                    || []).length > 0)}
                                onChange={(newDate) => handleChange(index, newDate, 'endDate', row?.endDate)}
                                minDate={showMinEndDate(row?.startDate, row?.quarter)}
                                maxDate={moment(rowsAcademic[0].endDate).toDate()}
                              />
                              {errors && errors[row?.id]?.endDate?.isDirty && (
                                <Typography className={classes.errorText}>{errors[row?.id]?.endDate?.errors[0]}</Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        )))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item xs={12} lg={7}>
                  {!isNewAcademicYear && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6} className={classes.logText}>
                          <TextFieldAtom
                            id="logText"
                            variant="outlined"
                            label={t('CHANGE_LOG')}
                            sx={{
                              '@media (min-width: 1200px)': {
                                maxWidth: '100%',
                              },
                            }}
                            placeholder="Log Text"
                            required
                            value={formik.values.logText}
                            onChange={(val) => formik.setFieldValue('logText', val.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} className={classes.viewLogs}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                              <FindInPageOutlinedIcon />
                            </Grid>
                            <Grid item onClick={() => viewLogs()}>
                              <div>{t('VIEW_LOGS')}</div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                  )}
                </Grid>
                <Grid item xs={12} lg={5} container justifyContent="flex-end">
                  <ButtonAtom
                    name={t('CANCEL')}
                    btntype={Buttons.SECONDARY}
                    className={classes.secButton}
                    onClick={() => { window.location.reload(); }}
                  />
                  <ButtonAtom
                    name={t('SAVE')}
                    disabled={!hasLogText}
                    btntype={Buttons.PRIMARY}
                    className={classes.actionBtn}
                    onClick={() => save()}
                  />
                </Grid>
              </Grid>
            </>
          )
      }
      <DialogAtomSheeet
        isOpen={openDialog}
        dialogHeading={t('ACADEMIC_DATES')}
        content={confirmYearStatus()}
        secHandle={() => setOpenDialog(false)}
      />
      <DialogAtomSheeet
        isOpen={openDialogError}
        dialogHeading={t('ACADEMIC_DATES')}
        content={confirmYearStatusError()}
        secHandle={() => setOpenDialogError(false)}
      />
    </Box>
  );
}
