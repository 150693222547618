import { fonts } from '../../../../theme';

const detailstyle = () => ({
  noData: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: fonts.fontWeight.semi,
    fontFamily: fonts.fontType.roboto,
    color: '#055BD8',
    padding: '1vw',
    borderBottom: '2px solid #CFC7C7',
  },
  chartWrapper: {
    '& .svg': {
      height: 9,
    },
    width: '95%',
    height: '75vw',
    display: 'flex',
    justifyContent: 'center',
    '@media (min-width: 1200px)': {
      height: '15vw',
      width: '35vw',
      padding: '10px 10px 10px 28px',
    },
    '@media (max-width: 899px)': {
      fontSize: '14px',
    },
    '@media (max-width: 599px)': {
      fontSize: '12px',
    },
    '@media (max-width: 399px)': {
      fontSize: '11px',
    },
    '& .recharts-wrapper': {
      '& .recharts-legend-wrapper': {
        '& ul': {
          '& li': {
            minWidth: '6vw',
            display: 'inline-flex !important',
            flexDirection: 'row',
            alignItems: 'center',
          },
          '& .recharts-legend-item-text': {
            color: '#000000 !important',
            marginLeft: '5px',
          },
          '& .recharts-surface': {
            height: '2vw',
          },
        },
      },
    },
  },
  circleAlign: {
    float: 'left',
    marginTop: '3px',
    marginRight: '3px',
  },
  legendItem: {
    margin: '0 20px 20px 0',
    flex: '0 0 7vw',
  },
  test: {
    fontSize: '40px',
  },
});
export default detailstyle;
