import { Grid } from '@mui/material';
import React, { useState } from 'react';
import {
  PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer,
} from 'recharts';
import useStyles from '../../../../custom-hooks/useStyles';
import { colors } from '../../../../theme';
import { CustomTooltip } from './commonComponent';
import style from './style';

const COLORS = [
  colors.torchRed,
  colors.yellow,
  colors.purple,
  colors.vibrantGreen,
  colors.reddishMagenta,
  colors.borderGrey,
  colors.pinkShade,
  colors.skyBlue,
  colors.persianBlue,
  colors.darkBrown,
  colors.seaGreen,
  colors.purpleShade,
  colors.dullGreen,
  colors.rosePink,
  colors.blackGreen,
];

function CourseEnrollmentBreakdown({ label, data }) {
  if (!Array.isArray(data)) {
    return null;
  }
  const [posData, setposData] = useState({});
  // eslint-disable-next-line no-param-reassign
  data = data.reduce((obj, item) => {
    const { name, value } = item;
    if (obj[name]) {
      obj[name] = {
        ...obj[name],
        value: obj[name].value + value,
      };
    } else {
      obj[name] = item;
    }
    return obj;
  }, {});

  // eslint-disable-next-line no-param-reassign
  data = Object.values(data);
  const classes = useStyles(style)();
  const totalValue = data.reduce((sum, item) => {
    // eslint-disable-next-line no-param-reassign
    sum += parseInt(item.value, 10);
    return sum;
  }, 0);
  // eslint-disable-next-line no-param-reassign
  data = data.map((item) => {
    // eslint-disable-next-line no-param-reassign
    item.totalValue = totalValue;
    return item;
  });
  return (
    <Grid conatiner className={classes.regionPiechart}>
      <div className={classes.heading}>{label}</div>
      <ResponsiveContainer className={classes.courseBreakDownChart}>
        <PieChart
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Pie
            data={data}
            color="#000000"
            dataKey="value"
            nameKey="name"
            innerRadius={40}
            fill="#8884d8"
            minAngle={7}
            onMouseMove={(point) => setposData(point)}
          >
            {data?.length
            && data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                innerRadius={37}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip
            content={(props) => CustomTooltip({
              ...props,
              coordinate: {
                x: ((posData?.tooltipPosition?.x || 0) - 35), y: (posData?.tooltipPosition?.y || 0) - 10,
              },
            })}
            position={{ y: (posData?.tooltipPosition?.y || 0) }}
          />
          <Legend iconType="circle" />
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  );
}

export default CourseEnrollmentBreakdown;
