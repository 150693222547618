import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useFormik } from 'formik';
import {
  FacebookOutlined,
  Twitter,
  Instagram,
  YouTube,
  Email,
  Phone,
} from '@mui/icons-material';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { getDonations } from '../../store/actions/getPayment';
import { setLocalStorage } from '../../utils/localStorageMethod';
import logo from '../../assets/images/logo.png';
import style from './style';
import { websiteDonationService } from '../../store/services/auth';
import useStyles from '../../custom-hooks/useStyles';
import usePayment from '../../custom-hooks/usePayment';
import Loader from '../../components/atoms/loader';

const validationSchema = Yup.object({
  firstName: Yup.string().when('isAnonymous', {
    is: false,
    then: Yup.string().matches(/^[A-Za-z]+$/, 'Only alphabets are allowed').required('First Name is required'),
    otherwise: Yup.string(),
  }),
  lastName: Yup.string().when('isAnonymous', {
    is: false,
    then: Yup.string().matches(/^[A-Za-z]+$/, 'Only alphabets are allowed').required('Last Name is required'),
    otherwise: Yup.string(),
  }),
  phone: Yup.string().when('isAnonymous', {
    is: false,
    then: Yup.string().required('Phone number is required').matches(/^\d{10}$/, 'Phone number must be 10 digits'),
    otherwise: Yup.string(),
  }),
  email: Yup.string().email('Invalid email format').when('isAnonymous', {
    is: false,
    then: Yup.string().required('Email is required'),
  }).required('Email is required'),
  donationAmount: Yup.number().required('Donation amount is required').positive('Amount must be greater than zero'),
  donationCause: Yup.string().required('Donation cause is required'),
});

export default function DonationForm() {
  const classes = useStyles(style)();
  const dispatch = useDispatch();
  const paymentData = usePayment();
  const donationsForOptions = paymentData?.donations;
  const [loading, setLoading] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(getDonations());
  }, [dispatch]);

  useEffect(() => {
  }, [donationsForOptions]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      donationAmount: '',
      notes: '',
      donationCause: '',
      isAnonymous: false,
    },
    validate: (values) => {
      const errors = {};
      if (parseFloat(values.donationAmount) < 1) {
        errors.donationAmount = 'Minimum donation amount is $1';
      }
      return errors;
    },
    validationSchema,
    onSubmit: (values) => {
      const {
        firstName, lastName, phone, email, donationAmount, donationCause, donationNotes,
      } = values;
      const dataToSubmit = isAnonymous
        ? { donationAmount, donationCause }
        : {
          firstName, lastName, phone, email, donationAmount, donationCause, donationNotes,
        };
      setLoading(true);
      try {
        const donationCauseObj = donationsForOptions?.find((i) => i?.id === values.donationCause);
        const redBody = {
          causeOfContribution: donationCauseObj?.name,
          amount: parseFloat(dataToSubmit.donationAmount),
          notes: dataToSubmit.donationNotes,
          isAnonymous,
          ...(isAnonymous ? {} : {
            firstName: dataToSubmit.firstName,
            lastName: dataToSubmit.lastName,
            phone: dataToSubmit.phone,
            email: dataToSubmit.email,
          }),
        };
        websiteDonationService(redBody).then((response) => {
          if (response?.success && response?.data?.approveLink) {
            setLocalStorage('orderId', response?.data?.orderId);
            setLocalStorage('isWebsiteDonation', true);
            setLocalStorage('websiteDonationName', isAnonymous ? 'Donater' : `${values.firstName} ${values.lastName}`);
            setLocalStorage('websiteDonationFirstName', values.firstName);
            setLocalStorage('websiteDonationLastName', values.lastName);
            setLocalStorage('websiteDonationPhone', values.phone);
            setLocalStorage('websiteDonationIsAnonymous', isAnonymous);
            setLocalStorage('websiteDonationEmail', values.email);
            setLocalStorage('websiteDonationCause', donationCauseObj?.name);
            setLocalStorage('paymentFor', 'websiteDonation');
            window.location.assign(response?.data?.approveLink);
            setLoading(false);
          }
        }).catch(() => {
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);
      }
    },
  });

  const handleInputChange = (e, regex) => {
    const { value } = e.target;
    if (regex.test(value) || value === '') {
      formik.handleChange(e);
    }
  };
  const limitDecimal = (e) => {
    let { value } = e.target;
    value = parseFloat(value).toFixed(2);
    return (value).isNaN ? '' : value;
  };

  return (
    <Grid container className={classes.divWrapper}>
      <Grid container className={classes.HeaderWrapper}>
        <Grid item xs={0.5} lg={0.5} md={0.5} />
        <Grid item xs={4} lg={3} md={3}>
          <img src={logo} alt="ManBadiLogo" className={classes.headerLogo} />
        </Grid>
        <Grid item xs={1.5} lg={3} md={3}>
          <Link href="tel:1-844-626-2234" underline="none">
            <Box display="flex" alignItems="center" style={{ float: 'right' }}>
              <Phone sx={{ marginRight: '8px', color: '#104F96' }} />
              {!isSmallScreen && <Typography variant="subtitle2">1 (844) 626-BADI (2234)</Typography>}
            </Box>
          </Link>
        </Grid>
        <Grid item xs={1.5} lg={3} md={3}>
          <Link href="mailto:info@manabadi.siliconandhra.org" underline="none">
            <Box display="flex" alignItems="center" style={{ float: 'right' }}>
              <Email sx={{ marginRight: '8px', color: '#104F96' }} />
              {!isSmallScreen && <Typography variant="subtitle2">info@manabadi.siliconandhra.org</Typography>}
            </Box>
          </Link>
        </Grid>
        <Grid item xs={4} lg={2} md={2}>
          <Box className={classes.iconWrapper}>
            <Link href="https://www.facebook.com/SiliconAndhraManaBadi/" target="_blank" rel="noopener noreferrer">
              <FacebookOutlined sx={{ marginRight: 1, color: '#104F96' }} />
            </Link>
            <Link href="https://twitter.com/manabadi" target="_blank" rel="noopener noreferrer">
              <Twitter sx={{ marginRight: 1, color: '#104F96' }} />
            </Link>
            <Link href="https://www.instagram.com/siliconandhramanabadi/" target="_blank" rel="noopener noreferrer">
              <Instagram sx={{ marginRight: 1, color: '#104F96' }} />
            </Link>
            <Link href="https://www.youtube.com/siliconandhramanabadi" target="_blank" rel="noopener noreferrer">
              <YouTube sx={{ marginRight: 1, color: '#104F96' }} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={0.5} lg={0.5} md={0.5} />
      </Grid>
      <Grid container className={classes.formWrapper}>
        <Grid item xs={12} className={classes.headerBox1}>
          <div className={classes.header1}>Welcome to our donation page!</div>
          <div className={classes.header2}>Our mission is to teach children Telugu and preserve our cultural heritage. Your contribution will make a huge difference.</div>
        </Grid>
        <Grid item xs={12} lg={6.5} md={6} />
        <Grid item xs={12} lg={5} md={6} className={classes.cardRight}>
          <Box className={classes.headerBox2}>
            <Typography className={classes.header3}>Make a Difference Today</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Paper elevation={1} className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} md={6} className={classes.performTextField}>
                  <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label={isAnonymous ? 'First Name' : 'First Name *'}
                    value={formik.values.firstName}
                    onBlur={formik.handleBlur}
                    onChange={(e) => handleInputChange(e, /^[A-Za-z]*$/)}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.performTextField}>
                  <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label={isAnonymous ? 'Last Name' : 'Last Name *'}
                    value={formik.values.lastName}
                    onBlur={formik.handleBlur}
                    onChange={(e) => handleInputChange(e, /^[A-Za-z]*$/)}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={`${classes.performTextField} ${classes.adornment}`}>
                  <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label={isAnonymous ? 'Phone Number' : 'Phone Number *'}
                    value={formik.values.phone}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                      inputProps: { maxLength: 10 },
                    }}
                    onChange={(e) => handleInputChange(e, /^[0-9]*$/)}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.performTextField}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email * "
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.performTextField}>
                  <FormControl fullWidth>
                    <InputLabel id="donationCauseLabel">Donation Cause *</InputLabel>
                    <Select
                      labelId="donationCauseLabel"
                      id="donationCause"
                      name="donationCause"
                      label="Donation Cause *"
                      value={formik.values.donationCause}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.donationCause && Boolean(formik.errors.donationCause)}
                    >
                      {donationsForOptions?.map((donation) => (
                        <MenuItem key={donation.id} value={donation.id}>
                          {donation.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.donationCause && formik.errors.donationCause ? (
                      <Typography variant="caption" color="error" className={classes.errorText}>
                        {formik.errors.donationCause}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={`${classes.performTextField} ${classes.adornment}`}>
                  <TextField
                    fullWidth
                    id="donationAmount"
                    name="donationAmount"
                    label="Donation Amount *"
                    placeholder="0.00"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    value={formik.values.donationAmount}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                        formik.setFieldValue('donationAmount', value);
                      }
                    }}
                    error={formik.touched.donationAmount && Boolean(formik.errors.donationAmount)}
                    helperText={formik.touched.donationAmount && formik.errors.donationAmount}
                    onBlur={(e) => {
                      const amount = limitDecimal(e);
                      if (parseFloat(amount) < 1 || amount === 'NaN') {
                        formik.setFieldValue('donationAmount', '');
                      } else {
                        formik.setFieldValue('donationAmount', amount);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === 'e' || event.key === 'E' || event.key === '+' || event.key === '-'
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />

                </Grid>
                <Grid item xs={12} className={classes.performTextField}>
                  <TextField
                    fullWidth
                    id="notes"
                    name="notes"
                    label="Notes "
                    multiline
                    minRows={1}
                    variant="outlined"
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={isAnonymous}
                        onChange={(e) => {
                          setIsAnonymous(e.target.checked);
                          formik.setFieldValue('isAnonymous', e.target.checked);
                        }}
                      />
                  )}
                    label="Make my donation anonymous"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button color="success" variant="contained" fullWidth type="submit">
                    {loading ? (
                      <>
                        <Loader />
                        <span style={{ marginLeft: '8px' }}>Processing....</span>
                      </>
                    ) : (
                      'Donate'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
          <Typography className={classes.header4}>Thank you for your generosity and commitment to our mission.</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
