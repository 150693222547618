export const StudentInfo = [
  {
    id: 'StudentInfo/id',
    label: 'Student ID',
  },
  {
    id: 'StudentInfo/studentInfo?.first_name',
    label: 'Student Name',
  },
  {
    id: 'StudentInfo/studentInfo?.manabadi_email',
    label: 'Student MB Email',
  },
  {
    id: 'StudentInfo/dateOfBirth',
    label: 'Student Age',
  },
  {
    id: 'StudentInfo/studentInfo?.gender',
    label: 'Student Gender',
  },
  {
    id: 'StudentInfo/studentInfo?.profile_photo',
    label: 'Student Image',
  },
  {
    id: 'StudentInfo/enrolled_courses?.tShirtSize',
    label: 'Student T-Shirt Size',
  },
  {
    id: 'StudentInfo/enrolled_courses?.extraCurricularActivities',
    label: 'Extracurricular',
  },
  {
    id: 'StudentInfo/enrolled_courses?.academic_grade',
    label: 'Academic Grade',
  },
];

export const RegionalPanelStudentInfo = [
  {
    id: 'StudentInfo/studentInfo?.first_name',
    label: 'Student Name',
  },
  {
    id: 'StudentInfo/studentInfo?.manabadi_email',
    label: 'Student MB Email',
  },
  {
    id: 'StudentInfo/dateOfBirth',
    label: 'Student Age',
  },
  {
    id: 'StudentInfo/studentInfo?.gender',
    label: 'Student Gender',
  },
  {
    id: 'StudentInfo/studentInfo?.profile_photo',
    label: 'Student Image',
  },
  {
    id: 'StudentInfo/enrolled_courses?.tShirtSize',
    label: 'Student T-Shirt Size',
  },
  {
    id: 'StudentInfo/enrolled_courses?.extraCurricularActivities',
    label: 'Extracurricular',
  },
  {
    id: 'StudentInfo/enrolled_courses?.academic_grade',
    label: 'Academic Grade',
  },
];

export const GeoRegion = [
  {
    id: 'GeoRegion/enrolled_courses?.location?.region?.geo_region',
    label: 'Geo Region',
  },
  {
    id: 'GeoRegion/enrolled_courses?.location?.region?.name',
    label: 'Region Name',
  },
];

export const RegionCoordinator = [
  {
    id: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.first_name',
    label: 'Primary RC',
  },
  {
    id: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.contact_number',
    label: 'Primary RC Phone Number',
  },
  {
    id: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.manabadiEmail',
    label: 'Primary RC Manabadi Email',
  },
  {
    id: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.personalEmail',
    label: 'Primary RC Personal Email',
  },
  {
    id: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.first_name',
    label: 'Support RC',
  },
  {
    id: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.contact_number',
    label: 'Support RC Phone Number',
  },
  {
    id: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.manabadiEmail',
    label: 'Support RC Manabadi Email',
  },
  {
    id: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.personalEmail',
    label: 'Support RC Personal Email',
  },
];

export const RegionalPanelRegionCoordinator = [
  {
    id: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.first_name',
    label: 'Primary RC',
  },
  {
    id: 'RegionCoordinator/enrolled_courses?.location?.region?.region_coordinator?.user?.contact_number',
    label: 'Primary RC Phone Number',
  },
  {
    id: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.first_name',
    label: 'Support RC',
  },
  {
    id: 'RegionCoordinatorSupport/enrolled_courses?.location?.region?.region_coordinators?.user?.contact_number',
    label: 'Support RC Phone Number',
  },
];

export const CenterInfo = [
  {
    id: 'CenterInfo/enrolled_courses?.location?.locationAddress?.address',
    label: 'Location Address',
  },
  {
    id: 'CenterInfo/enrolled_courses?.location?.name',
    label: 'Location Name',
  },
  {
    id: 'CenterInfo/enrolled_courses?.location?.locationAddress?.state',
    label: 'Location State',
  },
];

export const LocationCoordinator = [
  {
    id: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.first_name',
    label: 'Primary LC',
  },
  {
    id: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.contact_number',
    label: 'Primary LC Phone Number',
  },
  {
    id: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.manabadiEmail',
    label: 'Primary LC Manabadi Email',
  },
  {
    id: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.personalEmail',
    label: 'Primary LC Personal Email',
  },
  {
    id: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.first_name',
    label: 'Support LC',
  },
  {
    id: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.contact_number',
    label: 'Support LC Phone Number',
  },
  {
    id: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.manabadiEmail',
    label: 'Support LC Manabadi Email',
  },
  {
    id: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.personalEmail',
    label: 'Support LC Personal Email',
  },
];

export const RegionalPanelLocationCoordinator = [
  {
    id: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.first_name',
    label: 'Primary LC',
  },
  {
    id: 'LocationCoordinator/enrolled_courses?.location?.location_coordinator[0]?.user?.contact_number',
    label: 'Primary LC Phone Number',
  },
  {
    id: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.first_name',
    label: 'Support LC',
  },
  {
    id: 'LocationCoordinatorSupport/enrolled_courses?.location?.location_coordinators[0]?.user?.contact_number',
    label: 'Support LC Phone Number',
  },
];

export const ClassTeacher = [
  {
    id: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.firstName',
    label: 'Primary Teacher',
  },
  {
    id: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.contactNumber',
    label: 'Primary Teacher Phone Number',
  },
  {
    id: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.manabadiEmail',
    label: 'Primary Teacher Manabadi Email',
  },
  {
    id: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.personalEmail',
    label: 'Primary Teacher Personal Email',
  },
  {
    id: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.firstName',
    label: 'Support Teacher',
  },
  {
    id: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.contactNumber',
    label: 'Support Teacher Phone Number',
  },
  {
    id: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.manabadiEmail',
    label: 'Support Teacher Manabadi Email',
  },
  {
    id: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.personalEmail',
    label: 'Support Teacher Personal Email',
  },
];

export const RegionalPanelClassTeacher = [
  {
    id: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.firstName',
    label: 'Primary Teacher',
  },
  {
    id: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.contactNumber',
    label: 'Primary Teacher Phone Number',
  },
  {
    id: 'ClassTeacher/enrolled_courses?.google_class?.class_teacher?.user?.manabadiEmail',
    label: 'Primary Teacher Manabadi Email',
  },
  {
    id: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.firstName',
    label: 'Support Teacher',
  },
  {
    id: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.contactNumber',
    label: 'Support Teacher Phone Number',
  },
  {
    id: 'ClassTeacherSecondary/enrolled_courses?.google_class?.class_teachers?.user?.manabadiEmail',
    label: 'Support Teacher Manabadi Email',
  },
];

export const CourseInfo = [
  {
    id: 'CourseInfo/enrolled_courses?.course?.name',
    label: 'Course Name',
  },
  {
    id: 'CourseInfo/enrolled_courses?.google_class?.section',
    label: 'Section',
  },
];

export const ExamDetails = [
  {
    id: 'ExamDetails/enrolled_courses?.location?.exam_center?.address?.address',
    label: 'Exam Location',
  },
  {
    id: 'ExamDetails/marks?.Q1?.obtainedMarks',
    label: 'Q1 Marks',
  },
  {
    id: 'ExamDetails/marks?.Q2?.obtainedMarks',
    label: 'Q2 Marks',
  },
  {
    id: 'ExamDetails/marks?.Q3?.obtainedMarks',
    label: 'Q3 Marks',
  },
  {
    id: 'ExamDetails/homeworkMarks?.Q1?.obtainedMarks',
    label: 'Q1 Homework',
  },
  {
    id: 'ExamDetails/homeworkMarks?.Q2?.obtainedMarks',
    label: 'Q2 Homework',
  },
  {
    id: 'ExamDetails/homeworkMarks?.Q3?.obtainedMarks',
    label: 'Q3 Homework',
  },
  {
    id: 'ExamDetails/bonus',
    label: 'Bonus Marks',
  },
  {
    id: 'ExamDetails/enrolled_courses?.averageScore',
    label: 'Annual Score',
  },
  {
    id: 'ExamDetails/enrolled_courses?.hallTicketUrl',
    label: 'Student Hall Ticket Link',
  },
  {
    id: 'ExamDetails/enrolled_courses?.hallTicketNumber',
    label: 'Student Hall Ticket Number',
  },
  {
    id: 'ExamDetails/enrolled_courses?.result',
    label: 'Student Result',
  },
];

export const ExamDetailsSemesterBase = [
  {
    id: 'ExamDetails/enrolled_courses?.location?.exam_center?.address?.address',
    label: 'Exam Location',
  },
  {
    id: 'ExamDetails/marks?.S1?.obtainedMarks',
    label: 'S1 Exam',
  },
  {
    id: 'ExamDetails/marks?.S2?.obtainedMarks',
    label: 'S2 Exam',
  },
  {
    id: 'ExamDetails/marks?.S3?.obtainedMarks',
    label: 'Supplementary/PSTU',
  },
  {
    id: 'ExamDetails/homeworkMarks?.S1?.obtainedMarks',
    label: 'S1 Homework',
  },
  {
    id: 'ExamDetails/homeworkMarks?.S2?.obtainedMarks',
    label: 'S2 Homework',
  },
  {
    id: 'ExamDetails/quizMarks?.S1?.obtainedMarks',
    label: 'S1 Quiz',
  },
  {
    id: 'ExamDetails/quizMarks?.S2?.obtainedMarks',
    label: 'S2 Quiz',
  },
  {
    id: 'ExamDetails/assignment?.S1?.obtainedMarks',
    label: 'S1 Assignment',
  },
  {
    id: 'ExamDetails/assignment?.S2?.obtainedMarks',
    label: 'S2 Assignment',
  },
  {
    id: 'ExamDetails/cocurricular?.S1?.obtainedMarks',
    label: 'S1 Co-curricular',
  },
  {
    id: 'ExamDetails/cocurricular?.S2?.obtainedMarks',
    label: 'S2 Co-curricular',
  },
  {
    id: 'ExamDetails/enrolled_courses?.averageScore',
    label: 'Annual Score',
  },
  {
    id: 'ExamDetails/enrolled_courses?.hallTicketUrl',
    label: 'Student Hall Ticket Link',
  },
  {
    id: 'ExamDetails/enrolled_courses?.hallTicketNumber',
    label: 'Student Hall Ticket Number',
  },
  {
    id: 'ExamDetails/enrolled_courses?.result',
    label: 'Student Result',
  },
  {
    id: 'ExamDetails/enrolled_courses?.attendanceS1',
    label: 'S1 Class Attendance',
  },
  {
    id: 'ExamDetails/enrolled_courses?.attendanceS2',
    label: 'S2 Class Attendance',
  },
];

export const ParentInfo = [
  {
    id: 'ParentInfo/parent1Info?.first_name',
    label: 'Parent 1 Name',
  },
  {
    id: 'ParentInfo/parent1Info?.personal_email',
    label: 'Parent 1 Email',
  },
  {
    id: 'ParentInfo/parent1Info?.contact_number',
    label: 'Parent 1 Phone Number',
  },
  {
    id: 'ParentInfo/parent2Info?.first_name',
    label: 'Parent 2 Name',
  },
  {
    id: 'ParentInfo/parent2Info?.personal_email',
    label: 'Parent 2 Email',
  },
  {
    id: 'ParentInfo/parent2Info?.contact_number',
    label: 'Parent 2 Phone Number',
  },
  {
    id: 'ParentInfo/parent1Info?.user_extended?.name_of_company',
    label: 'Parent 1 Company',
  },
  {
    id: 'ParentInfo/parent1Info?.user_extended?.profession',
    label: 'Parent 1 Profession',
  },
  {
    id: 'ParentInfo/parent2Info?.user_extended?.name_of_company',
    label: 'Parent 2 Company',
  },
  {
    id: 'ParentInfo/parent2Info?.user_extended?.profession',
    label: 'Parent 2 Profession',
  },
  {
    id: 'ParentInfo/parent1Info?.address?.address',
    label: 'Home Address',
  },
];

export const RegionalPanelParentInfo = [
  {
    id: 'ParentInfo/parent1Info?.first_name',
    label: 'Parent 1 Name',
  },
  {
    id: 'ParentInfo/parent1Info?.contact_number',
    label: 'Parent 1 Phone Number',
  },
  {
    id: 'ParentInfo/parent2Info?.first_name',
    label: 'Parent 2 Name',
  },
  {
    id: 'ParentInfo/parent2Info?.contact_number',
    label: 'Parent 2 Phone Number',
  },
];

export default {
  CenterInfo,
  CourseInfo,
  ExamDetails,
  ParentInfo,
  ClassTeacher,
  StudentInfo,
  RegionalPanelStudentInfo,
  ExamDetailsSemesterBase,
  RegionCoordinator,
  LocationCoordinator,
  GeoRegion,
  RegionalPanelRegionCoordinator,
  RegionalPanelLocationCoordinator,
  RegionalPanelClassTeacher,
  RegionalPanelParentInfo,
};
