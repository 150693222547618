/* eslint-disable array-callback-return */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Grid, TextField, Typography,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';

import { DropdownHomeWork } from '../../../../components/atoms';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import useStyles from '../../../../custom-hooks/useStyles';

import useAcademicPanel from '../../../../custom-hooks/useAcademicPanel';
import { getAcademicSchema, getQuarterDetails, saveAcademicSchema } from '../../../../store/actions/academic-panel';
import { styles, materialStyles } from './styles';
import { getCurrentYear, isValidSemesterYear } from '../../../../utils/methods';

const QUARTER_OPTIONS = [
  { id: 'Q1', key: 'QUARTER 1' },
  { id: 'Q2', key: 'QUARTER 2' },
  { id: 'Q3', key: 'QUARTER 3' },
];

const getCourseOptions = (courses) => courses.map((course) => ({
  id: course.id,
  name: course.name,
}));

const isValidDate = (value) => {
  const val = moment(value).format('yyyy-MM-DD');
  const now = moment().format('yyyy-MM-DD');
  if (moment(val).isBefore(moment(now))) {
    return false;
  }
  return true;
};

const restrictKeys = (ev, allowDecimal = false) => {
  const restrictedSymbols = ['+', '-', 'e', '*'];

  if (!allowDecimal) {
    restrictedSymbols.push('.');
  }

  if (restrictedSymbols.includes(ev.key)) {
    ev.preventDefault();
  }
};

const useGetCourseRows = (ques) => {
  const [rows, setRow] = useState([]);

  useEffect(() => {
    setRow([]);
    [...Array(ques)].map((_, i) => setRow((oldRows) => [...oldRows, i]));
  }, [ques]);

  return { rows, setRow };
};

function RenderCourseField({
  classes,
  questions,
  setTotalQuestions,
  handleSave,
  courseFieldData,
  courseFieldErrors,
  courseFieldTouched,
  changeAcademicPanelMarks,
  showSaveBtn,
  schemaCanBeEdited,
  refreshSchema,
  setError,
  setSuccessMsg,
  setErrorSchemaRemoved,
  setSaveClicked,
}) {
  const { t } = useTranslation();
  const { rows, setRow } = useGetCourseRows(questions);
  const [showRows, setShowRows] = useState(false);
  const [showCategoryError, setShowCategoryError] = useState(false);
  const [categoryRows, setCategoryRows] = useState([]);

  useEffect(() => {
    const categorySet = new Set();
    const categorySetRows = [];
    let hasDuplicate = false;
    courseFieldData.map((data) => {
      const category = data?.category || '';
      if (categorySet.has(category)) {
        if (category !== '') {
          hasDuplicate = true;
          categorySetRows.push(category);
        }
      } else {
        categorySet.add(category);
      }
    });
    if (hasDuplicate) {
      setShowCategoryError(true);
      setCategoryRows(categorySetRows);
    } else {
      setShowCategoryError(false);
    }
  }, [rows, courseFieldData]);

  useEffect(() => {
    if (!rows || !rows.length) {
      setShowRows(false);
    } else {
      setShowRows(true);
    }
  }, [rows]);

  const deleteRow = (id) => {
    setTotalQuestions(questions - 1, id);

    setRow((oldRows) => oldRows.filter((rowID) => rowID !== id));
  };

  const onCrossClick = (rowNo) => {
    if (!schemaCanBeEdited) {
      return;
    }

    deleteRow(rowNo);
  };

  const handleChange = (e, rowNo) => {
    const { value, name } = e.target;

    changeAcademicPanelMarks(rowNo, name, value);
  };

  return (
    <Box marginTop={2}>
      {showRows && (
        <>
          <Box style={styles.courseTableHeadingCont}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography style={styles.courseTableHeading}>
                  {t('CATEGORY')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={styles.courseTableHeading}>
                  {t('MARKS')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography style={styles.courseTableHeading}>
                  {t('NOTES')}
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
          <Box marginTop={2}>
            {rows.map((id) => (
              <Box key={id} marginTop={2}>
                <Grid container spacing={2}>
                  <Grid item xs={4} lg={3} md={3} className={classes.formControlGrid}>
                    <TextField
                      label={t('CATEGORY')}
                      id="category"
                      required
                      name="category"
                      type="number"
                      disabled={!schemaCanBeEdited}
                      value={courseFieldData[id]?.category || ''}
                      onChange={(e) => handleChange(e, id)}
                      labelId="category"
                      size="small"
                      onKeyPress={(e) => restrictKeys(e, true)}
                      inputProps={{ min: '0', style: styles.textFieldLabel }}
                      InputLabelProps={{ style: styles.textFieldLabel }}
                      error={courseFieldErrors && courseFieldErrors[id]?.category && courseFieldTouched && courseFieldTouched[id]?.category ? courseFieldErrors[id]?.category : false}
                      helperText={courseFieldErrors && courseFieldErrors[id]?.category && courseFieldTouched && courseFieldTouched[id]?.category ? courseFieldErrors[id]?.category : ''}
                    />
                  </Grid>
                  <Grid item xs={3} lg={3} md={3} className={classes.formControlGrid}>
                    <TextField
                      label={t('MARKS')}
                      id="marks"
                      required
                      name="marks"
                      type="number"
                      disabled={!schemaCanBeEdited}
                      value={courseFieldData[id]?.marks || ''}
                      onChange={(e) => handleChange(e, id)}
                      labelId="marks"
                      size="small"
                      onKeyPress={(e) => restrictKeys(e, true)}
                      inputProps={{ min: '0', style: styles.textFieldLabel }}
                      InputLabelProps={{ style: styles.textFieldLabel }}
                      error={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : false}
                      helperText={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : ''}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3} md={3} className={classes.formControlGrid}>
                    <TextField
                      label={t('NOTES')}
                      id="notes"
                      required
                      name="notes"
                      type="text"
                      disabled={!schemaCanBeEdited}
                      value={courseFieldData[id]?.notes || ''}
                      onChange={(e) => handleChange(e, id)}
                      labelId="notes"
                      size="small"
                      inputProps={{ style: styles.textFieldLabel }}
                      InputLabelProps={{ style: styles.textFieldLabel }}
                      error={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : false}
                      helperText={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : ''}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3} md={3} className={classes.formCrossRow}>
                    <Box>
                      <ClearIcon
                        style={!schemaCanBeEdited ? { color: '#bdbbbb' } : {}}
                        onClick={() => onCrossClick(id)}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {showCategoryError && (
                  categoryRows.map((cat, index) => {
                    if (cat === courseFieldData[id]?.category) {
                      const hasDisplayedError = categoryRows.slice(0, index).some((c) => c === cat);
                      if (hasDisplayedError) {
                        return null;
                      }
                      return (
                        <Box style={styles.errorCont}>
                          <Typography style={styles.errorText} variant="text">
                            {t('CATEGORY_NUMBER_ERROR')}
                          </Typography>
                        </Box>
                      );
                    }
                    return null;
                  })
                )}
              </Box>
            ))}
          </Box>
        </>
      )}

      {showSaveBtn && !showCategoryError
        && (
          <Box marginTop={2} alignItems="flex-end" style={styles.saveBtnCont}>
            <ButtonAtom
              btntype={Buttons.SECONDARY}
              onClick={() => {
                refreshSchema();
                setSuccessMsg('');
                setError('');
                setErrorSchemaRemoved('');
              }}
              name={t('CANCEL')}
              type="submit"
            />

            <ButtonAtom
              btntype={Buttons.PRIMARY}
              onClick={() => {
                setSuccessMsg('');
                setError('');
                setErrorSchemaRemoved('');
                setSaveClicked(true);
                handleSave();
              }}
              name={t('SAVE')}
              type="submit"
            />
          </Box>
        )}
    </Box>
  );
}

function RenderFieldsGrid({
  courseOptions,
  academicYearOptions,
  setLoading,
  selectedCourse,
  setSelectedCourse,
}) {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [errorMarks, setErrorMarks] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorSchemaRemoved, setErrorSchemaRemoved] = useState('');
  const [updateSchema, setUpdateSchema] = useState(false);
  const [academicMarksCopy, setAcademicMarksCopy] = useState([]);
  const currentAcademicYear = getCurrentYear()?.id;
  const [validSemesterYear, setValidSemesterYear] = useState(false);
  const [semesterOptions, setSemesterOptions] = useState([]);
  const dispatch = useDispatch();
  const [saveClicked, setSaveClicked] = useState(false);

  const validationSchema = Yup.object({
    schemaLabel: Yup.string(t('SCHEMA_LABEL_REQUIRED'))
      .required(t('SCHEMA_LABEL_REQUIRED')),
    passingCriteria: Yup.number(t('PASSING_CRITERIA_REQUIRED'))
      .required(t('PASSING_CRITERIA_REQUIRED'))
      .when(
        'maxMarks',
        (maxMarks, schema) => (maxMarks ? schema.max(Yup.ref('maxMarks'), t('INVALID_PASSING_CRITERIA')) : schema),
      ),
    weightage: Yup.number(t('WEIGHTAGE_REQUIRED'))
      .required(t('WEIGHTAGE_REQUIRED'))
      .max(100, t('INVALID_WEIGHTAGE')),
    totalQuestions: Yup.number(t('TOTAL_QUESTIONS_REQUIRED'))
      .required(t('TOTAL_QUESTIONS_REQUIRED')),
    maxMarks: Yup.number(t('MAX_MARKS_REQUIRED'))
      .required(t('MAX_MARKS_REQUIRED')),
    academicPanelMarks: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().test('category required test', t('CATEGORY_REQUIRED'), (value, context) => {
          const { path } = context;
          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);
          if (academicMarksCopy[currIndex]?.deletedAt) {
            return true;
          }
          if (!value) {
            return false;
          }

          return true;
        }),
        marks: Yup.number().test('marks required test', t('MARKS_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (academicMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        notes: Yup.string().test('notes required test', t('NOTES_REQUIRED'), (value) => {
          if (!value) {
            return false;
          }

          return true;
        }),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      courses: '',
      academicYear: '',
      quarter: 'Q1',
      schemaLabel: '',
      passingCriteria: '',
      weightage: '',
      totalQuestions: '',
      maxMarks: '',
      academicPanelMarks: [],
      semester: 'S1',
    },
    validationSchema,
    // eslint-disable-next-line no-use-before-define
    onSubmit: handleSave,
  });

  useEffect(() => {
    if (academicMarksCopy?.length !== formik?.touched?.academicPanelMarks?.length) {
      formik?.touched?.academicPanelMarks?.pop();
    }
  }, [academicMarksCopy]);

  useEffect(() => {
    if (courseOptions && courseOptions.length) {
      formik.setTouched({}, false);

      formik.setFieldValue('courses', selectedCourse || courseOptions[0].id);
    }
  }, [courseOptions, selectedCourse]);

  useEffect(() => {
    if (academicYearOptions && academicYearOptions.length) {
      formik.setTouched({}, false);

      formik.setFieldValue('academicYear', currentAcademicYear);
      if (validSemesterYear) {
        formik.setFieldValue('semester', 'S1');
      } else {
        formik.setFieldValue('quarter', 'Q1');
      }
    }
  }, [academicYearOptions]);
  function formatNumber(numStr) {
    const num = parseFloat(numStr);

    if (Number.isInteger(num)) {
      return numStr;
      // eslint-disable-next-line no-else-return
    } else {
      const parts = numStr.split('.');
      if (parts[1]?.length > 2) {
        return num.toFixed(2);
        // eslint-disable-next-line no-else-return
      } else {
        return numStr;
      }
    }
  }
  const changeAcademicPanelMarks = (row, key, value) => {
    const clonedMarks = [...formik.values.academicPanelMarks];
    if (clonedMarks.length <= row) {
      if (key === 'marks') {
        clonedMarks[row] = {
          [key]: formatNumber(value),
        };
      } else {
        clonedMarks[row] = {
          [key]: value,
        };
      }
    }
    if (key === 'marks') {
      clonedMarks[row][key] = formatNumber(value);
    } else {
      clonedMarks[row][key] = value;
    }

    formik.setFieldValue('academicPanelMarks', clonedMarks);
    setAcademicMarksCopy(clonedMarks);
  };

  const refreshSchema = () => {
    if (!formik.values.courses || !formik.values.academicYear || !formik.values.quarter) {
      return;
    }

    setValidSemesterYear(isValidSemesterYear(formik.values.academicYear));
    setLoading(true);
    setError('');
    setErrorMarks('');

    const payload = {
      courseId: formik.values.courses,
      academicYear: academicYearOptions.find((year) => year.id === formik.values.academicYear)?.name,
      quarter: isValidSemesterYear(formik.values.academicYear) ? formik.values.semester : formik.values.quarter,
    };

    dispatch(getAcademicSchema(payload));

    formik.setTouched({}, false);
  };

  function handleSave(values) {
    setLoading(true);

    const payload = {
      courseId: values.courses,
      academicYear: academicYearOptions.find((year) => year.id === values.academicYear)?.name,
      maximumMarks: values.maxMarks,
      schemaLabel: values.schemaLabel,
      weightage: values.weightage,
      totalQuestions: values.totalQuestions,
      academicPanelMarks: values.academicPanelMarks.map((ele) => ({
        category: ele.category,
        marks: ele.marks,
        notes: ele.notes,
      })),
      ...(updateSchema ? { academicPanelId: updateSchema } : {}),
    };
    if (validSemesterYear) {
      payload.quarter = values.semester;
      payload.passingCriteria = values.passingCriteria;
    } else {
      payload.quarter = values.quarter;
      payload.passingCriteria = values.passingCriteria;
    }

    const loadFalse = () => setLoading(false);

    saveAcademicSchema(payload, updateSchema, loadFalse, setError, setSuccessMsg, refreshSchema);
  }

  useEffect(() => {
    formik.values.semester = 'S1';
    formik.values.quarter = 'Q1';
  }, [formik.values.academicYear]);

  useEffect(() => {
    const options = courseOptions?.filter((obj) => obj.id === formik.values.courses);
    const optionSelected = options?.[0]?.name;
    if (optionSelected === 'BalaBadi - I' || optionSelected === 'BalaBadi - II'
      || optionSelected === 'Telugu-1' || optionSelected === 'Telugu-3') {
      setSemesterOptions([
        { id: 'S1', key: 'SEMESTER 1' },
        { id: 'S2', key: 'SEMESTER 2' },
      ]);
    } else if (optionSelected === 'Prakasam' || optionSelected === 'Prabhasam'
      || optionSelected === 'Telugu-2' || optionSelected === 'Telugu-4') {
      setSemesterOptions([
        { id: 'S1', key: 'SEMESTER 1' },
        { id: 'S2', key: 'SEMESTER 2' },
        { id: 'S3', key: 'PSTU' },
      ]);
    } else {
      setSemesterOptions([
        { id: 'S1', key: 'SEMESTER 1' },
        { id: 'S2', key: 'SEMESTER 2' },
        { id: 'S3', key: 'SUPPLEMENTARY' },
      ]);
    }
    setSaveClicked(false);
    refreshSchema();
  }, [formik.values.courses, formik.values.academicYear, formik.values.quarter, formik.values.semester]);

  const { schema, quarterDetails } = useAcademicPanel();
  if (schema) {
    schema.forEach((item) => {
      item.academic_panel_marks.sort((a, b) => (a.category - b.category));
    });
  }

  useEffect(() => {
    if (!formik.values.academicYear) {
      return;
    }

    formik.setTouched({}, false);

    dispatch(getQuarterDetails(academicYearOptions.find((year) => year.id === formik.values.academicYear)?.name));
  }, [formik.values.academicYear]);

  const prefillSchema = (data) => {
    if (!data.length) {
      formik.setFieldValue('schemaLabel', '');
      formik.setFieldValue('passingCriteria', '');
      formik.setFieldValue('weightage', '');
      formik.setFieldValue('totalQuestions', '');
      formik.setFieldValue('maxMarks', '');
      formik.setFieldValue('academicPanelMarks', []);
      setAcademicMarksCopy([]);
      setUpdateSchema(false);
      setLoading(false);
      setErrorSchemaRemoved('');
      setError('');
      setErrorMarks('');
      setSuccessMsg('');

      return;
    }

    const prefillData = data[0];

    formik.setFieldValue('schemaLabel', prefillData?.schemaLabel || '');
    formik.setFieldValue('passingCriteria', prefillData?.passingCriteria >= 0 ? prefillData?.passingCriteria : 0);
    formik.setFieldValue('weightage', prefillData?.weightage >= 0 ? prefillData?.weightage : 0);
    formik.setFieldValue('totalQuestions', prefillData?.totalQuestions || 0);
    formik.setFieldValue('maxMarks', prefillData?.maximumMarks >= 0 ? prefillData?.maximumMarks : 0);
    formik.setFieldValue('academicPanelMarks', prefillData?.academic_panel_marks || []);
    setTimeout(() => {
      formik.setFieldTouched('academicPanelMarks', prefillData?.academic_panel_marks.map(() => ({
        category: false,
        marks: false,
        notes: false,
      })));
    }, 1000);

    if (prefillData?.academic_panel_marks?.length > 0) {
      setErrorSchemaRemoved('');
    } else {
      setErrorSchemaRemoved(validSemesterYear ? t('SEMESTER_SCHEMA_REMOVED') : t('QUARTER_SCHEMA_REMOVED'));
      setSuccessMsg('');
    }

    setAcademicMarksCopy(prefillData?.academic_panel_marks || []);
    setUpdateSchema(prefillData.id);

    setLoading(false);
  };

  useEffect(() => {
    if (schema === null) {
      return;
    }

    prefillSchema(schema);
  }, [schema]);

  const deleteAcademiMarksEntry = (index) => {
    setAcademicMarksCopy((oldMarks) => {
      const clonedMarks = [...oldMarks];
      clonedMarks.splice(index, 1);
      formik.setFieldValue('academicPanelMarks', clonedMarks, false);
      return clonedMarks;
    });
  };

  useEffect(() => {
    if (formik.values.totalQuestions < formik.values.academicPanelMarks.length) {
      const countDiff = formik.values.academicPanelMarks.length - formik.values.totalQuestions;

      for (let i = 1; i <= countDiff; i++) {
        deleteAcademiMarksEntry(formik.values.academicPanelMarks.length - i);
      }
    } else if (formik.values.totalQuestions > formik.values.academicPanelMarks.length && formik.values.totalQuestions < 21) {
      const countDiff = formik.values.totalQuestions - formik.values.academicPanelMarks.length;
      const newEntries = [];

      for (let i = 1; i <= countDiff; i++) {
        newEntries.push({
          category: '',
          marks: '',
          notes: '',
        });
      }

      formik.setFieldValue('academicPanelMarks', [...formik.values.academicPanelMarks, ...newEntries]);
    }
  }, [formik.values.totalQuestions]);

  const setTotalQuestions = (questions, index) => {
    formik.setFieldValue('totalQuestions', questions);
    deleteAcademiMarksEntry(index);
  };

  const calculateCourseTotal = () => {
    if (!formik.values.academicPanelMarks.length) {
      return null;
    }
    const total = formik.values.academicPanelMarks.reduce((oldVal, currentEle) => (
      oldVal + (currentEle?.marks ? parseFloat(currentEle.marks) : 0)
    ), 0);

    return total;
  };

  const courseFieldTotal = useMemo(
    () => calculateCourseTotal() || 0,
    [formik.values.academicPanelMarks, formik.values.totalQuestions],
  );

  const checkIfSchemeCanBeEdited = () => {
    let editable = false;
    if (quarterDetails) {
      const { academicYearQuarterDates } = quarterDetails;

      academicYearQuarterDates.forEach((quarterItem) => {
        if (quarterItem.quarter === formik.values.quarter || quarterItem.quarter === formik.values.semester) {
          editable = isValidDate(quarterItem.endDate);
        } else if (formik.values.semester === 'S3') {
          editable = isValidDate(quarterDetails.endDate);
        }
      });
    }

    return editable;
  };

  const schemaCanBeEdited = useMemo(checkIfSchemeCanBeEdited, [quarterDetails, formik.values.quarter, formik.values.semester]);

  useEffect(() => {
    if (formik.values.quarter === '') {
      setError('');
      setSuccessMsg('');
    } else if (!schemaCanBeEdited && schemaCanBeEdited !== null) {
      const errormsg = validSemesterYear ? t('SEMESTER_ERROR') : t('QUARTER_ERROR');
      setError(errormsg);
      setErrorSchemaRemoved('');
      setSuccessMsg('');
    } else {
      setError('');
    }
  }, [schemaCanBeEdited, formik.values.quarter, selectedCourse]);

  const dependenciesForMarksValidation = formik.values.academicPanelMarks.map(
    (ele) => ele.marks,
  );

  useEffect(() => {
    const totalMarks = calculateCourseTotal();

    if (totalMarks === null || formik.values.maxMarks === '') {
      setErrorMarks('');
      return;
    }

    if (totalMarks !== formik.values.maxMarks) {
      setErrorMarks(t('MAX_MARKS_ERROR'));
      setErrorSchemaRemoved('');
      setSuccessMsg('');
    } else {
      setErrorMarks('');
    }
  }, [dependenciesForMarksValidation, formik.values.maxMarks]);
  const classes = useStyles(materialStyles)();

  useEffect(() => {
    const { totalQuestions } = formik.values;
    if (totalQuestions > 21) {
      formik.values.totalQuestions = 20;
    }
  }, [formik.values.totalQuestions]);

  return (
    <Box>
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <DropdownHomeWork
              label={`${t('COURSE')}`}
              required
              id="courses"
              name="courses"
              labelId="courses"
              disabled={false}
              value={formik.values.courses}
              handleChange={(e) => {
                formik.handleChange(e);
                setSelectedCourse(e.target.value);
              }}
              onBlur={formik.handleBlur}
              options={courseOptions}
              customStyles={styles.dropDownCont}
              shrink
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DropdownHomeWork
              label={`${t('ACADEMICYEAR')}`}
              required
              id="academicYear"
              name="academicYear"
              labelId="academicYear"
              disabled={false}
              value={formik.values.academicYear}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={academicYearOptions}
              customStyles={styles.dropDownCont}
              shrink
            />
          </Grid>
          {validSemesterYear && (
            <Grid item xs={12} sm={6} md={3}>
              <DropdownHomeWork
                label={`${t('SEMESTER')}`}
                required
                id="semester"
                name="semester"
                labelId="semester"
                disabled={false}
                value={formik.values.semester}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={semesterOptions.map((sem) => ({
                  id: sem.id,
                  name: sem.key,
                }))}
                customStyles={styles.dropDownCont}
                shrink
              />
            </Grid>
          )}
          {!validSemesterYear && (
            <Grid item xs={12} sm={6} md={3}>
              <DropdownHomeWork
                label={`${t('QUARTER')}`}
                required
                id="quarter"
                name="quarter"
                labelId="quarter"
                disabled={false}
                value={formik.values.quarter}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={QUARTER_OPTIONS.map((quarter) => ({
                  id: quarter.id,
                  name: quarter.key,
                }))}
                customStyles={styles.dropDownCont}
                shrink
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
            <TextField
              label={t('LABEL')}
              id="schemaLabel"
              labelId="schemaLabel"
              name="schemaLabel"
              required
              type="text"
              disabled={!schemaCanBeEdited}
              value={formik.values.schemaLabel}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              inputProps={{ style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
              helperText={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
            />
          </Grid>
          {/* row 2 */}
          <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
            <TextField
              label={t('PASSING_CRITERIA')}
              id="passingCriteria"
              name="passingCriteria"
              labelId="passingCriteria"
              required
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.passingCriteria}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              onKeyPress={restrictKeys}
              size="small"
              inputProps={{ min: '0', step: '1', style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.passingCriteria ? formik.errors.passingCriteria : undefined}
              helperText={formik.touched.passingCriteria ? formik.errors.passingCriteria : undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
            <TextField
              className={classes.formControl}
              label={t('WEIGHTAGE')}
              id="weightage"
              name="weightage"
              labelId="weightage"
              required
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.weightage}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              onKeyPress={(e) => restrictKeys(e, true)}
              inputProps={{ min: '0', max: '100', style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.weightage ? formik.errors.weightage : undefined}
              helperText={formik.touched.weightage ? formik.errors.weightage : undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
            <TextField
              label={t('TOTAL_QUESTIONS')}
              required
              id="totalQuestions"
              name="totalQuestions"
              labelId="totalQuestions"
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.totalQuestions < 21 ? formik.values.totalQuestions : 20}
              onKeyPress={restrictKeys}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              inputProps={{ min: '0', max: '20', style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.totalQuestions ? formik.errors.totalQuestions : undefined}
              helperText={formik.touched.totalQuestions ? formik.errors.totalQuestions : undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
            <TextField
              label={t('MAXIMUM_MARKS')}
              id="maxMarks"
              labelId="maxMarks"
              name="maxMarks"
              required
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.maxMarks}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              onKeyPress={restrictKeys}
              size="small"
              inputProps={{ min: '0', style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
              helperText={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
            />
          </Grid>
        </Grid>
        {error && (
          <Box style={styles.errorCont}>
            <Typography style={styles.errorText} variant="text">
              {error}
            </Typography>
          </Box>
        )}
        {errorMarks && (
          <Box style={styles.errorCont}>
            <Typography style={styles.errorText} variant="text">
              {errorMarks}
            </Typography>
          </Box>
        )}

        {successMsg && (
          <Box style={styles.successCont}>
            <Typography style={styles.successText} variant="text">
              {successMsg}
            </Typography>
          </Box>
        )}

        {errorSchemaRemoved && (
          <Box style={styles.errorCont}>
            <Typography style={styles.errorText} variant="text">
              {saveClicked === true ? errorSchemaRemoved : ''}
            </Typography>
          </Box>
        )}

        <RenderCourseField
          questions={formik.values.totalQuestions || 0}
          {...{ setTotalQuestions, changeAcademicPanelMarks, classes }}
          handleSave={formik.handleSubmit}
          refreshSchema={refreshSchema}
          courseFieldData={formik.values.academicPanelMarks}
          courseFieldErrors={formik.errors.academicPanelMarks}
          courseFieldTouched={formik.touched.academicPanelMarks}
          showSaveBtn={courseFieldTotal === formik.values.maxMarks && schemaCanBeEdited}
          schemaCanBeEdited={schemaCanBeEdited}
          setErrorSchemaRemoved={setErrorSchemaRemoved}
          setSuccessMsg={setSuccessMsg}
          setError={setError}
          setSaveClicked={setSaveClicked}
        />
      </FormikProvider>
    </Box>
  );
}

function Scheme({
  courses,
  academicYears,
  setLoading,
  selectedCourse,
  setSelectedCourse,
}) {
  return (
    <Box m={3}>
      <RenderFieldsGrid
        courseOptions={getCourseOptions(courses)}
        academicYearOptions={academicYears}
        {...{ setLoading, selectedCourse, setSelectedCourse }}
      />
    </Box>
  );
}

export default React.memo(Scheme);
