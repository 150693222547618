// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import {
  Grid, Box, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/fontawesome-free-solid';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import OptionsMenu from '../Menu';
import ButtonAtom from '../../../../components/atoms/button';
import {
  DialogAtom, TextField, Button, Datepicker,
} from '../../../../components/atoms';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from '../../../resources/style';
import { Buttons } from '../../../../constant';

import { updateHelpVideo, deactivateHelpVideos, getHelpVideosPagination } from '../../../../store/actions/getResources';

function DropdownIndicator(props) {
  const { sortType } = props;
  return (
    <div style={{ display: 'grid', gridGap: '1px', paddingLeft: '10px' }}>
      {
        sortType === 'both' && (
          <FontAwesomeIcon icon={faSort} color="rgba(0, 0, 0, 0.54)" />
        )
      }
      {
        sortType === 'asc' && (
          <FontAwesomeIcon icon={faSortUp} color="#C12900" />
        )
      }
      {
        sortType === 'desc' && (
          <FontAwesomeIcon icon={faSortDown} color="#C12900" />
        )
      }
    </div>
  );
}

export default function ListView({
  refreshData,
  sortOrder,
  setSortOrder,
  resources,
  pageNext,
  pagenum,
  setPagenum,
  totalRows,
  isSysAdminOrSuperUser,
  isDeactivateChecked,
  totalActiveResources,
}) {
  const classes = useStyles(styles)();
  const [deactivateDateValue, setDeactivateDateValue] = useState(new Date());
  const [isSetEditFileOpenDialog, setEditFileOpenDialog] = useState(false);
  const [isSetDeactivateFileOpenDialog, setDeactivateFileOpenDialog] = useState(false);

  const [dateError, setDateError] = useState('');
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [openVideo, setOpenVideo] = useState();
  const [tableData, setTableData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const reduxTotalCount = useSelector((state) => state?.getLocationCoordinator?.totalHVideosCount);
  const counter = (isDeactivateChecked === true) ? reduxTotalCount : totalActiveResources;

  useEffect(() => {
    if (sortedData.length === 0) {
      setTableData(resources);
    } else {
      setTableData(sortedData);
    }
  }, [resources, sortedData]);

  const handleToggle = (row) => {
    setOpenVideo(row.url);
    setOpen(!open);
  };
  const [selectedRow, setSelectedRow] = useState([]);
  const [isEditedFileName, setEditedFileName] = useState('');
  const grabVideoDetails = async (row) => {
    try {
      const curRow = await row;
      return setSelectedRow(curRow);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  const openEditFileDialog = () => {
    setEditedFileName(selectedRow.name);
    setEditFileOpenDialog(true);
  };
  const editFile = () => {
    const callback = () => {
      refreshData();
    };
    const paylaod = {
      id: selectedRow?.id,
      name: isEditedFileName,
    };
    dispatch(updateHelpVideo(paylaod, callback));
    setEditFileOpenDialog(false);
  };

  const isValidDate = (value) => {
    const val = moment(value).format('yyyy-MM-DD');
    const now = moment().format('yyyy-MM-DD');
    if (moment(val).isBefore(moment(now))) {
      return false;
    }
    return true;
  };

  const openDeactivateFileDialog = () => {
    setDateError('');
    setDeactivateFileOpenDialog(true);
  };

  const deactivateFile = () => {
    const callback = () => {
      refreshData();
    };
    if (isValidDate(deactivateDateValue)) {
      const paylaod = {
        id: selectedRow?.id,
        deactivateDate: deactivateDateValue,
      };
      dispatch(deactivateHelpVideos(paylaod, callback));
      setDeactivateFileOpenDialog(false);
    }
  };

  const loadMoreItems = (length) => {
    let page = '';
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    const payload = {
      userRole: 'LOCATION_COORDINATOR',
    };
    dispatch(getHelpVideosPagination(payload, page, 100, pageNext));
  };
  const renderDeactivateFileContent = () => (
    <Grid>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <Datepicker
            className={classes.popupTextField}
            required
            value={deactivateDateValue}
            minDate={new Date()}
            onChange={(newValue) => {
              setDeactivateDateValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            id="outlined-required"
            label="Deactivate Date"
          />
        </div>
        <span className={classes.errorText}>{dateError}</span>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <Button
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => setDeactivateFileOpenDialog(false)}
          btntype={Buttons.SECONDARY}
        />
        <Button
          className={classes.popupBtn}
          name={t('DEACTIVATE')}
          btntype={Buttons.PRIMARY}
          onClick={() => deactivateFile()}
        />
      </Grid>
    </Grid>
  );

  const getNameString = (name) => {
    /* eslint-disable no-else-return */
    /* eslint-disable prefer-template */
    if (name.length > 24) {
      return name.substring(0, 24) + '...';
    } else {
      return name;
    }
  };

  const renderEditFileContent = () => (
    <Grid>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className={classes.popupTextField}
            required
            id="outlined-required"
            label="File Name"
            value={isEditedFileName}
            onChange={(e) => setEditedFileName(e.target.value)}
            defaultValue="BalaBadi-I"
          />
        </div>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <Button
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => setEditFileOpenDialog(false)}
          btntype={Buttons.SECONDARY}
        />
        <Button
          className={classes.popupBtn}
          name={t('UPDATE')}
          btntype={Buttons.PRIMARY}
          onClick={() => editFile()}
        />
      </Grid>
    </Grid>
  );

  const sorting = (filter, status) => {
    if (sortOrder[status] === 'desc') {
      const sorted = [...tableData].sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));

      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'both',
      }));
      setTableData([]);
      setSortedData(sorted);
    } else if (sortOrder[status] === 'asc') {
      if (filter === 'createdBy') {
        const sorted = [...tableData].sort((a, b) => b.createdUser.firstName.localeCompare(a.createdUser.firstName));
        setTableData([]);
        setSortedData(sorted);
      } else if (filter === 'updatedBy') {
        const sorted = [...tableData].sort((a, b) => b.updatedUser?.firstName?.localeCompare(a.updatedUser?.firstName));
        setTableData([]);
        setSortedData(sorted);
      } else {
        const sorted = [...tableData].sort((a, b) => b[filter].localeCompare(a[filter]));
        setTableData([]);
        setSortedData(sorted);
      }
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'desc',
      }));
    } else if (sortOrder[status] === 'both') {
      if (filter === 'createdBy') {
        const sorted = [...tableData].sort((a, b) => a.createdUser?.firstName?.localeCompare(b.createdUser?.firstName));
        setTableData([]);
        setSortedData(sorted);
      } else if (filter === 'updatedBy') {
        const sorted = [...tableData].sort((a, b) => a.updatedUser?.firstName?.localeCompare(b.updatedUser?.firstName));
        setTableData([]);
        setSortedData(sorted);
      } else {
        const sorted = [...tableData].sort((a, b) => a[filter].localeCompare(b[filter]));
        setTableData([]);
        setSortedData(sorted);
      }
      setSortOrder((prevState) => ({
        ...prevState,
        [status]: 'asc',
      }));
    }
  };

  return (
    <Grid container className={classes.studentDetails}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerTableHelpSite}>
                <TableSortLabel
                  active
                  onClick={() => sorting('name', 'resourceN')}
                  IconComponent={() => <DropdownIndicator sortType={sortOrder?.resourceN} />}
                  className={classes.helpSiteLabels}
                >
                  {t('FILE_NAME')}
                </TableSortLabel>

              </TableCell>

              <TableCell className={classes.longHeaderTableHelpSite}>
                <TableSortLabel
                  active
                  onClick={() => sorting('createdAt', 'createdOn')}
                  IconComponent={() => <DropdownIndicator sortType={sortOrder?.createdOn} />}
                  className={classes.helpSiteLabels}
                >
                  {t('CREATED_ON')}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.longHeaderTableHelpSite}>
                <TableSortLabel
                  active
                  onClick={() => sorting('createdBy', 'createdBy')}
                  IconComponent={() => <DropdownIndicator sortType={sortOrder?.createdBy} />}
                  className={classes.helpSiteLabels}
                >
                  {t('CREATED_BY')}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.longHeaderTableHelpSite}>
                <TableSortLabel
                  active
                  onClick={() => sorting('updatedAt', 'updatedOn')}
                  IconComponent={() => <DropdownIndicator sortType={sortOrder?.updatedOn} />}
                  className={classes.helpSiteLabels}
                >
                  {t('MODIFIED_ON')}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.longHeaderTableHelpSite}>
                <TableSortLabel
                  active
                  onClick={() => sorting('updatedBy', 'updatedBy')}
                  IconComponent={() => <DropdownIndicator sortType={sortOrder?.updatedBy} />}
                  className={classes.helpSiteLabels}
                >
                  {t('MODIFIED_BY')}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.headerTableHelpSite}>
                <TableSortLabel
                  active
                  onClick={() => sorting('size', 'sizeN')}
                  IconComponent={() => <DropdownIndicator sortType={sortOrder?.sizeN} />}
                  className={classes.helpSiteLabels}
                >
                  {t('FILE_SIZE')}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.headerTableHelpSite}>{t('STATUS')}</TableCell>
              {
                (isSysAdminOrSuperUser)
                && (
                  <TableCell className={classes.headerTableHelpSite}>{t('ACTIONS')}</TableCell>
                )
              }

            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((params) => (
              <TableRow
                key={params?.name}
              >
                <TableCell style={{ padding: '4px' }}>
                  <Tooltip title={`${params?.name}`} placement="bottom-start">
                    <Grid display="flex" onClick={() => handleToggle(params)}>
                      <span>
                        <OndemandVideoOutlinedIcon sx={{ color: '#1034ad' }} />
                      </span>
                      <Typography style={{ fontSize: '13px', marginLeft: '10px' }}>
                        {getNameString(params?.name)}
                      </Typography>
                    </Grid>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ padding: '4px' }}>
                  <Grid display="flex">
                    <Typography style={{ fontSize: '13px' }}>{moment(params?.createdAt).format('MMM DD, YYYY HH:mm:ss a')}</Typography>
                  </Grid>
                </TableCell>
                <TableCell style={{ padding: '4px' }}>
                  <Grid display="flex">
                    <Typography style={{ fontSize: '13px' }}>{`${params?.createdUser?.firstName} ${params?.createdUser?.lastName}`}</Typography>
                  </Grid>
                </TableCell>
                <TableCell style={{ padding: '4px' }}>
                  <Grid display="flex">
                    <Typography style={{ fontSize: '13px' }}>{moment(params?.updatedAt).format('MMM DD, YYYY HH:mm:ss a')}</Typography>
                  </Grid>
                </TableCell>
                <TableCell style={{ padding: '4px' }}>
                  <Grid display="flex">
                    {(params?.updatedUser !== null) ? (
                      <Typography style={{ fontSize: '13px' }}>{`${params?.updatedUser?.firstName} ${params?.updatedUser?.lastName}`}</Typography>

                    ) : (
                      <Typography style={{ fontSize: '13px' }}>N/A</Typography>
                    )}
                  </Grid>
                </TableCell>
                <TableCell style={{ padding: '4px' }}>{params?.size}</TableCell>
                <TableCell style={{ padding: '4px' }}>
                  <Grid display="flex">
                    <Typography style={{ fontSize: '13px' }}>
                      {
                        params?.isActive === true ? 'Active' : 'Inactive'
                      }
                    </Typography>
                  </Grid>
                </TableCell>
                {
                  (isSysAdminOrSuperUser)
                  && (
                    <TableCell style={{ padding: '4px' }}>
                      <OptionsMenu
                        openDeactivateFileDialog={openDeactivateFileDialog}
                        openEditFileDialog={openEditFileDialog}
                        grabVideoDetails={grabVideoDetails}
                        row={params}
                      />
                    </TableCell>
                  )
                }

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ReactModal
        isOpen={open}
        onRequestClose={handleToggle}
        className={classes.reactModal}
        overlayClassName={classes.reactOverlay}
      >
        <video
          className="video-js"
          src={openVideo}
          autoPlay
          controls
          style={{
            height: 'auto', width: '100%',
          }}
        >
          <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
        </video>
      </ReactModal>
      <DialogAtom
        isOpen={isSetEditFileOpenDialog}
        dialogHeading={t('EDIT_FILE')}
        customClass={classes.editFileDialogAtom}
        content={renderEditFileContent()}
        secHandle={() => setEditFileOpenDialog(false)}
      />
      <DialogAtom
        isOpen={isSetDeactivateFileOpenDialog}
        dialogHeading={t('DEACTIVATE_FILE')}
        customClass={classes.deactivateFileDialogAtom}
        content={renderDeactivateFileContent()}
        secHandle={() => setDeactivateFileOpenDialog(false)}
      />
      {(reduxTotalCount > 0 && totalRows > 0)
        && (
          <Grid container justifyContent="flex-end">

            <Grid className={classes.loadMoreItems}>
              <Grid xs={12} className={classes.totalRowCount}>
                <span>
                  {t('TOTAL_RESOURCES')}
                  {' '}
                  {totalRows}
                  {' '}
                  of
                  {' '}
                  {reduxTotalCount}
                </span>
              </Grid>
              {(counter !== totalRows)
                && (
                  <Grid justifyContent="flex-end" className={classes.loadMore}>
                    <ButtonAtom
                      btntype={Buttons.PRIMARY}
                      onClick={() => loadMoreItems(totalRows)}
                      name={t('LOAD_MORE')}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}
