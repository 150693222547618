import { colors, fonts } from '../../../theme';

const parentInfoStyle = () => ({
  outerContainer: {
    marginBottom: '2vw',
  },
  alignTextGrid: {
    align: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  paddingLeft16: {
    paddingLeft: '16px',
  },
  editParentInfoClass: {
    '& .MuiGrid-root': {
      '@media (min-width: 1200px)': {
        maxWidth: '42vw !important',
      },
    },
  },
  alignTextGridPhone: {
    '& .MuiInputLabel-root': {
      marginTop: '0px !important',
    },
  },
  alignTextGridPhoneEdit: {
    '& .MuiInputLabel-root': {
      marginTop: '-3px !important',
    },
  },
  disabledButton: {
    borderRadius: '4px',
    '@media (min-width: 1200px)': {
      height: '2.5vw !important',
      padding: '0.8vw',
      minWidth: '13vw !important',
      marginLeft: '1.2vw',
      marginRight: '1vw',
      borderRadius: '0.4vw !important',
      fontSize: '1vw',
    },
    boxShadow: '0 4px 4px -2px hsl(0deg 0% 65% / 25%)',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    textTransform: 'none !important',
    background: `${colors.disabledPrimary} !important`,
  },
  addressCSS: {
    '& .MuiInputLabel-root': {
      '@media (max-width: 599px)': {
        transform: 'translate(8px, 23px) scale(1) !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (max-width: 599px)': {
        transform: 'translate(8px, 0px) scale(1) !important',
        fontSize: '12px !important',
        display: 'none',
      },
      '& .MuiFormControl-root': {
        margin: '0px !important',
      },
    },
  },
  editParentInfoClassDialog: {
    flexWrap: 'nowrap',
    '@media (max-width: 600px)': {
      flexWrap: 'inherit !important',
    },
    '& .MuiGrid-root': {
      '@media (min-width: 1200px)': {
        maxWidth: '30vw !important',
      },
    },
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: fonts.fontType.roboto,
    margin: '0',
    paddingTop: '0px',
    '& .MuiInputBase-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '& label.Mui-focused': {
      color: `${colors.primary} !important`,
      outline: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
    },
    '@media (min-width: 1200px)': {
      '& .MuiInputBase-input': {
        '@media (min-width: 1200px)': {
          padding: '0.7vw 0vw 0.9vw 0vw',
        },
      },
      '& .MuiOutlinedInput-root': {
        '@media (min-width: 1200px)': {
          width: '100%',
          fontSize: '0.9vw',
        },
      },
    },
  },
  errorText: {
    color: colors.errorText,
    margin: '3px 14px 0px 0px !important',
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    '@media (max-width: 1200px)': {
      fontSize: '0.7rem',
      marginTop: 10,
    },
    display: 'block',
  },
  heading: {
    color: colors.footerBottomColor,
    padding: '0 0 0.5vw 0',
    fontWeight: 500,
    width: '97%',
    fontSize: '1.5vw',
    marginTop: '1.5vw',
    borderRadius: '0.4vw',
    marginLeft: '1vw',
    '@media (max-width: 1199px)': {
      fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
    },
    '@media (max-width: 899px)': {
      marginTop: '20px',
    },
  },
  button: {
    justifyContent: 'flex-end',
    width: '98%',
    '@media (max-width: 899px)': {
      marginBottom: '5vw',
      width: '100%',
    },
  },
  autoComplete: {
    paddingTop: '0 !important',
    marginTop: '0 !important',
    '& #addressAutoComplete': {
      width: '96%',
      'text-overflow': 'ellipsis',
      marginTop: '0 !important',
      '@media (min-width: 300px)': {
        height: '3.5em !important',
        fontSize: '1rem',

      },
      '@media (min-width: 800px)': {
        fontSize: '1rem',
        height: '3.5em !important',
      },
      '@media (min-width: 1200px)': {
        height: '1.35vw !important',
        fontSize: '0.9vw',
      },
    },
  },
  textArea: {
    width: '100%',
    margin: '0px',
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw !important',
      marginTop: '-4px !important',
      '@media (max-width: 800px)': {
        fontSize: '16px !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      fontSize: '0.9vw !important',
      marginTop: '4px !important',
      color: `${colors.primary} !important`,
      background: 'white',
      paddingLeft: '2px',
    },
    '& .MuiFormControl-root': {
      margin: '2px !important',
    },
  },
  alignGridTitle: {
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '20%',
        flexBasis: '20%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        marginLeft: '0px !important',
        width: '100%',
        marginRight: '0.8vw !important',
        marginBottom: '5px !important',
        outline: 'none',
        '& .MuiFormControl-root': {
          margin: '0 !important',
          width: '100%',
        },
      },
    },
  },
  checkboxColor: {
    color: `${colors.primary} !important`,
    '& .MuiSvgIcon-root': {
      color: `${colors.primary} !important`,
    },
    '& .MuiFormControl-root': {
      margin: '0.5vw 10px',
    },
  },
  checkboxEditparent: {
    color: `${colors.primary} !important`,
    '& .MuiSvgIcon-root': {
      color: `${colors.primary} !important`,
    },
    '& .MuiFormControl-root': {
      margin: '0.5vw 12px !important',
    },
  },
  alignGridFirstName: {
    width: '100%',
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '30%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormControl-root': {
          margin: '0 0vw !important',
          width: '100%',
          padding: '0 0vw',
        },
      },
    },
  },

  alignGridMiddleName: {
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '20%',
        flexBasis: '20%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        marginLeft: '0vw !important',
        width: '100%',
        marginRight: '0px !important',
        '& .MuiFormControl-root': {
          margin: '0 !important',
          width: '100%',
        },
      },
    },
  },
  activeButton: {
    color: '#f3f8fe !important',
    borderRadius: '4px',
    '@media (min-width: 1200px)': {
      height: '2.5vw !important',
      padding: '0.8vw',
      minWidth: '13vw !important',
      marginLeft: '1.2vw',
      marginRight: '1vw',
      borderRadius: '0.4vw !important',
      fontSize: '1vw',
    },
    boxShadow: '0 4px 4px -2px hsl(0deg 0% 65% / 25%)',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    textTransform: 'none !important',
    background: `${colors.newBGColor} !important`,
  },
  secButton: {
    color: '#1976d2 !important',
    border: '0.1px solid #1976d2',
    borderRadius: '4px',
    '@media (min-width: 1200px)': {
      height: '2.5vw !important',
      padding: '0.8vw',
      minWidth: '13vw !important',
      marginLeft: '1.2vw',
      fontSize: '1vw',
      borderRadius: '0.4vw !important',
    },
    background: 'white',
    boxShadow: '0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%) !important',
    boxSizing: 'border-box',
    fontFamily: 'Roboto, sans-serif',
    textTransform: 'none',
    '& .MuiSvgIcon-root': {
      width: '0.8vw',
      height: '0.8vw',
    },
  },
  footerBottom: {
    background: colors.footerBottomColor,
    height: '0.1vw',
    display: 'block',
    width: '100%',
    marginTop: '2vw',
  },
  alignGridLastName: {
    '@media (min-width: 1200px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        width: '30%',
        flexBasis: '30%',
        maxWidth: 'initial',
      },
      '& .MuiFormControl-root': {
        marginLeft: '0vw !important',
        width: '100%',
        marginRight: '0px !important',
        '& .MuiFormControl-root': {
          margin: '0 !important',
          width: '100%',
        },
      },
    },
  },
  form: {
    marginTop: '0 !important',
  },
  speakTelugu: {
    marginLeft: '-1px !important',
  },
  readTelugu: {
    marginLeft: '-1px !important',
  },
  checkboxForStudent: {
    marginLeft: '-0.2% !important',
    '& .MuiFormControlLabel-root': {
      marginLeft: '2px !important',
    },
  },
  viewLogs: {
    display: 'flex',
    color: colors.actionIconsColor,
    textDecoration: 'underline',
    cursor: 'pointer',
    '@media (min-width: 1200px)': {
      marginLeft: '-4vw',
    },
    '@media (max-width: 600px)': {
      marginLeft: '-5vw',
    },
    paddingTop: '1.8vw',
  },
  alignRight: {
    textAlign: 'right',
  },
  commonFooterButton: {
    minWidth: '2vw !important',
    height: '2vw !important',
    padding: '1.2vw 1.2vw 1.2vw 1.2vw !important',
    '@media (max-width: 1199px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
      borderRadius: '12px !important',
    },
    '@media (max-width: 499px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
    },
    '& svg': {
      '@media (max-width: 1199px)': {
        width: '16px !important',
        height: '16px !important',
      },
    },
  },
  commonButtonNew: {
    minWidth: '2vw !important',
    height: '3vw !important',
    padding: '1vw 1vw 1.2vw 1.2vw !important',
    '@media (max-width: 1199px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
      borderRadius: '12px !important',
    },
    '@media (max-width: 499px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
    },
    '& svg': {
      '@media (max-width: 1199px)': {
        width: '16px !important',
        height: '16px !important',
      },
    },
  },
  paymentDialogAtom: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        maxWidth: '70vw !important',
      },
    },
    '& #contributionCause': {
      paddingRight: '1.5vw !important',
    },
  },
  lastNameError: {
    color: colors.red,
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
  },
  popupButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '2vw',
    '& .MuiButtonBase-root.MuiButton-root': {
      '@media (min-width: 1200px)': {
        height: '2.7vw !important',
        minWidth: '9vw !important',
      },
      marginRight: '15px',
      borderRadius: '4px',
    },
  },
});

export default parentInfoStyle;
