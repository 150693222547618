import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearProgressBar({
  totalPercent, value, customClass, maximumMarks, percentage, maxPerc,
}) {
  const val = Number.isInteger(value) ? value : value.toFixed(2);
  return (
    <span className={customClass}>
      <LinearProgress
        variant="determinate"
        maxvalue={maxPerc}
        value={percentage}
      />
      <span className={customClass}>
        {totalPercent && `${val}/${maximumMarks}`}
      </span>

    </span>
  );
}
