import Constant from '../constant';

const initialState = {
  examCenterData: [],
  totalExamCenters: 0,
};

const getExamCenterManager = (state = initialState, action = {}) => {
  switch (action.type) {
    case Constant.GET_EXAM_CENTER_MANAGER:
      return {
        ...state,
        examCenterData: action.payload,
      };
    case Constant.TOTAL_EXAM_CENTER_COUNT:
      return {
        ...state,
        totalExamCenters: action.payload,
      };
    case Constant.GET_PAGINATED_EXAM_CENTER:
      return {
        ...state,
        examCenterData: state.examCenterData?.concat(action.payload),
      };
    default:
      return state;
  }
};
export default getExamCenterManager;
