/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  TextField,
  Chip,
  Tooltip,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { red } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../assets/svg';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

function CardsAssignment({
  classes,
  resourceData,
  assignments,
  handlePopUp,
  PopUpModeEdit,
  deleteAssignmentOpener,
  viewAssignmentOpener,
}) {
  const { t } = useTranslation();

  const textFieldInlineStyles = {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
  };

  useEffect(() => {
    const attachedResource = resourceData.find((item) => item.resourceName === assignments?.attachmentResourceName);
  }, [resourceData]);

  function getFileExtension(fileName) {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      return fileName.slice(lastDotIndex);
    }
    return '';
  }

  return (
    <Box>
      <Grid container spacing={4} className={classes.bottomGridContainer}>
        {
          assignments.map((card) => {
            if (card.academicYear !== localStorage.getItem('academicYear')) {
              return null;
            }

            if (!card) return null;

            return (
              <Grid item xs={6} lg={4} md={6}>
                <Card className={classes.mainCard}>
                  <Box className={classes.cardContainerBox}>
                    <Box className={classes.cardTopBox}>
                      <Grid
                        container
                        className={classes.firstGridContainer}
                      >
                        <Grid item>
                          <CardContent className={classes.zeroPadding}>
                            <Typography className={classes.cardTitleContainer}>
                              {card.title}
                            </Typography>
                          </CardContent>
                        </Grid>
                        <Grid item>
                          <Grid item >
                            <CardActions disableSpacing className={classes.zeroPadding}>
                              <IconButton onClick={() => handlePopUp(true, card, PopUpModeEdit)} aria-label="add to favorites" className={classes.iconButton}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                aria-label="share"
                                className={`${classes.zeroPaddingDelete} ${classes.deleteIcon}`}
                                onClick={() => { deleteAssignmentOpener(card.id); }}
                              >
                                <DeleteForeverOutlinedIcon sx={{ color: red[500] }} />
                              </IconButton>
                              <IconButton onClick={() => { viewAssignmentOpener(true, card); }} aria-label="add to favorites" className={classes.iconButton}>
                                <RemoveRedEyeOutlinedIcon color="primary" />
                              </IconButton>
                            </CardActions>
                          </Grid>
                          <Grid item />
                        </Grid>
                      </Grid>
                      <Grid container className={classes.secondGridContainer}>
                        <CardContent className={classes.zeroPadding}>
                          <Typography variant="caption">
                            {card.academicYear}
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Box>
                    <Box className={classes.cardBottomBox}>
                      <Grid container>
                        <TextField
                          required
                          id="standard-multiline-static-required"
                          multiline
                          disabled
                          rows={10}
                          size="small"
                          inputProps={{ min: '0', style: textFieldInlineStyles }}
                          InputLabelProps={{ style: textFieldInlineStyles }}
                          name="description"
                          value={card.description}
                          validationOnChange={false}
                          validateOnBlur={false}
                        />
                      </Grid>
                      {card?.attachmentResourceName ? (
                      <Grid container className={classes.formControlGrid}>
                        <Grid item >
                          <span className={classes.textFields}>{t('ATTACH_RESOURCES')}</span>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                            <div className={classes.tagContainer}>
                              {card?.attachmentResourceName.split(',').map((attachmentName, index) => {
                                const trimmedName = attachmentName.trim();
                                const isTruncated = trimmedName.length > 30;

                                return (
                                  <Tooltip title={trimmedName}>
                                    <Chip
                                      label={isTruncated ? `${trimmedName.substr(0, 30)}...${getFileExtension(trimmedName)}` : trimmedName}
                                      key={index}
                                      className={isTruncated ? classes.truncateText : ''}
                                    />
                                  </Tooltip>
                                );
                              })}
                            </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      ) : (
                        <Grid container className={classes.formControlGrid}>
                          <Grid item >
                            <span className={classes.textFields}>{t('ATTACH_RESOURCES')}</span>
                          </Grid>
                          <Grid container spacing={0}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <div className={classes.tagContainer}>
                                  <DoNotDisturbIcon color="primary" />
                                  <Typography className={classes.itemLabels}>{t('NO_DATA')}</Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </Box>
                </Card>
              </Grid>
            );
          })
        }
      </Grid>
    </Box>
  );
}

export default CardsAssignment;
