import { colors, fonts } from '../../../theme';

const style = ((theme) => ({
  formControl: {
    width: '100%',
    '@media (min-width: 1200px)': {
      margin: '0.8vw',
    },
    '@media (max-width: 1199px)': {
      marginRight: 0,
      marginLeft: 0,
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.black} !important`,
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      color: `${colors.placeHolderColor} !important`,
    },
    '& .MuiOutlinedInput-root': {
      background: '#fff',
      color: colors.placeHolderColor,
      fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
        height: '44px',
        borderRadius: '0.4vw',
      },
      outline: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText} !important`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText} !important`,
      },
      '& input': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199)) !important',
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw !important',
          padding: '0.7vw',
        },
        '@media (max-width: 499px)': {
          borderRadius: '3px',
        },
        color: `${colors.black}!important`,
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {

        },
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },
  performTextField: {
    '& .Mui-error': {
      color: colors.errorText,
      fontFamily: 'inherit',
      letterSpacing: 'inherit',
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
      },
      marginLeft: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      '@media (min-width: 1200px)': {
        color: 'inherit !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        backgroundColor: colors.white,
        color: colors.placeHolderColor,
        transform: 'translate(0.7vw, 0.9vw)',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, -0.5vw)',
        fontSize: '0.7vw',
        color: colors.primary,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.7vw)',
        fontSize: '0.9vw',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '0.7vw',
        color: colors.primary,
      },
    },
    '& .MuiOutlinedInput-root': {
      height: '2.9vw !important',
      marginBottom: '5px',
      '& .MuiInputLabel-root': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw !important',
        },
        color: `${colors.placeHolderColor} !important`,
      },
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${colors.placeHolderColor} !important`,
      },
      '& input': {
        color: colors.black,
        '@media (min-width: 1200px)': {
          paddingLeft: '1vw',
          paddingRight: '1vw',
          fontSize: '0.9vw',
        },
        '&.Mui-disabled': {
          '@media (min-width: 1200px)': {
            padding: '0.65vw',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary} !important`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary} !important`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText} !important`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
      },
      '& .MuiFormHelperText-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
        },
        marginLeft: 0,
        fontSize: '0.9vw',
      },
    },
    '& fieldset': {
      '& legend span': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}));

export default style;
