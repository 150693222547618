/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

import {
  DialogAtom,
  PerformantTextFieldSectionSync,
} from '../../../../components/atoms';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';
import {
  getRolePermisstion,
} from '../../../../store/actions/getRole';
import {
  getStudentSectionsService,
} from '../../../../store/services/auth';
import Dropdown from '../../../../components/atoms/dropdown-with-labelSectionSync';
import { getCurrentYear } from '../../../../utils/methods';

export default function updateSection(props) {
  const {
    open, handleClose, data, academicYear, handleSubmit,
  } = props;
  const currentSelectedYear = academicYear?.split('-');
  const currentTime = getCurrentYear()?.id?.split('-');

  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const [roleNameError] = useState('');

  const [options, setOptions] = useState([]);
  const sectionOptions = [];
  useEffect(() => {
    dispatch(getRolePermisstion());
  }, []);
  const validationSchema = Yup.object({
    studentName: Yup.string(t('ROLE_NAME_REQUIRED')).required(
      t('ROLE_NAME_REQUIRED'),
    ),
    rolePermissions: Yup.array()
      .min(1, t('ROLE_PERMISSIONS_REQUIRED'))
      .required(t('ROLE_PERMISSIONS_REQUIRED')),
  });

  useEffect(async () => {
    const payload = {
      locationId: data?.locationId,
      courseId: data?.data?.courseId,
      academicYear: localStorage.getItem('academicYear'),
    };
    await getStudentSectionsService(payload).then((res) => {
      res.data.forEach((response) => {
        sectionOptions.push(response);
      });
      let isSectionIn = false;
      for (let section = 0; section < sectionOptions.length; section++) {
        if (sectionOptions[section].section === data?.data?.section) {
          isSectionIn = true;
        }
      }
      if (!isSectionIn) {
        sectionOptions.push({ id: data?.data?.sectionId, section: data?.data?.section });
      }
      setOptions(sectionOptions);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      studentName: data?.data?.studentInfo,
      parentName: data?.data?.parent1Info,
      courseName: data?.data?.course_name,
      section: data?.data?.section,
    },
    validationSchema,
    onSubmit: () => {
    },
  });

  const setPreviousFieldTouch = (key) => {
    const allFields = ['studentName', 'parentName', 'courseName', 'section'];

    const index = allFields.indexOf(key);
    if (index > -1) {
      const obj = {};
      for (let i = 0; i <= index; i++) {
        const element = allFields[i];
        obj[element] = true;
      }
      formik.setTouched({ ...formik.touched, ...obj }, true);
    }
  };

  const getErrorText = (key, errorText) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={classes.errorText}>
      {formik.errors[key]}
    </span>
  ) : errorText ? (
    <span className={classes.errorText}>{errorText}</span>
  ) : null);

  return (
    <div>
      <DialogAtom
        isOpen={open}
        dialogHeading={t('CHANGE_SECTION')}
        onClose={handleClose}
        dialogActions
        disabled={academicYear !== getCurrentYear()?.id}
        primaryButton={t('SAVE')}
        customClass={classes.syncDialog}
        secButton={t('CANCEL')}
        content={(
          <FormikProvider value={formik}>
            <Grid container>
              <Grid
                item
                xs={12}
              >
                <div className={classes.message}>
                  {academicYear !== getCurrentYear()?.id ? getErrorText('', t('PREVIOUS_YEAR_CHANGE_SECTION')) : null}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={`${classes.alignGrid} ${classes.dialContact}`}
              >
                <PerformantTextFieldSectionSync
                  label={`${t('STUDENT_NAME')}`}
                  id="studentName"
                  required
                  name="studentName"
                  type="text"
                  disabled
                  value={formik.values.studentName}
                  onBlur={() => setPreviousFieldTouch('studentName')}
                  error={roleNameError || getErrorText('studentName')}
                  onChange={formik.handleChange}
                  labelId="studentName"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={`${classes.alignGrid} ${classes.dialContact}`}
              >
                <PerformantTextFieldSectionSync
                  label={`${t('PARENT_NAME')}`}
                  id="parentName"
                  required
                  name="parentName"
                  type="text"
                  disabled
                  value={formik.values.parentName}
                  onBlur={() => setPreviousFieldTouch('parentName')}
                  error={roleNameError || getErrorText('parentName')}
                  onChange={formik.handleChange}
                  labelId="parentName"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={`${classes.alignGrid} ${classes.dialContact}`}
              >
                <PerformantTextFieldSectionSync
                  label={`${t('COURSE_NAME')}`}
                  id="courseName"
                  required
                  name="courseName"
                  type="text"
                  disabled
                  value={formik.values.courseName}
                  onBlur={() => setPreviousFieldTouch('courseName')}
                  error={roleNameError || getErrorText('courseName')}
                  onChange={formik.handleChange}
                  labelId="courseName"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={`${classes.alignGrid} ${classes.dialContact} ${classes.section}`}
              >
                <Dropdown
                  id="section"
                  name="section"
                  handleChange={formik.handleChange}
                  label="Select Section"
                  required
                  options={options}
                  disabled={currentSelectedYear[1] < currentTime[1]}
                  value={formik.values.section}
                  onBlur={() => setPreviousFieldTouch('section')}
                />
              </Grid>

            </Grid>
          </FormikProvider>
        )}
        primaryHandle={() => handleSubmit(data?.data?.id, formik.values.section, options, data?.data?.courseId)}
        secHandle={handleClose}
      />
    </div>
  );
}
