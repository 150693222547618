import React from 'react';
import {
  Grid,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';

function PublishedAttachmentError(
  {
    classes,
    handleAttachmentErrorDisplay,
  },
) {
  const { t } = useTranslation();
  return (
    <Grid className={classes.addDialog}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        className={classes.addPopup}
      >
        <Grid item xs={3}>
          <span>
            {`${t('ATTACHMENT_ERROR_TEXT')}`}
          </span>
        </Grid>
      </Box>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5vh' }}>
        <Grid item xs={3}>
          <ButtonAtom
            className={classes.popupBtn}
            name="Ok"
            onClick={() => handleAttachmentErrorDisplay()}
            btntype={Buttons.PRIMARY}
            type="submit"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PublishedAttachmentError;
