/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Grid, Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import style from './style';
import useStyles from '../../../custom-hooks/useStyles';
import Loader from '../../../components/atoms/loader';

export default function SimpleAccordion() {
  // eslint-disable-next-line no-unused-vars
  const [expanded, setExpanded] = React.useState('panel1');
  const { t } = useTranslation('translation');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [pstuFaqs, setPstuFaqs] = useState([]);
  const classes = useStyles(style)();
  const reduxStore = useSelector((state) => state?.getAdmin);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setPstuFaqs(reduxStore?.fetchAllPstuFaqs);
    setLoading(false);
  }, [reduxStore?.fetchAllPstuFaqs]);
  return (
    <div className={classes.pstuAccordion}>
      {!loading && (
        <div className={classes.pstuAccordion}>
          {pstuFaqs && pstuFaqs.map((data, index) => (
            <Accordion
              key={data.id}
              expanded={expanded === data.id}
              onChange={handleChange(data.id)}
              className={classes.accordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Grid container spacing={1} className={classes.alignContainer}>

                  <Grid
                    item
                    xs={10.5}
                    className={classes.alignGridAns}
                  >
                    <Typography>
                      <strong>{`Q${index + 1}. ${data.question}`}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} className={classes.alignContainer}>
                  <Grid item xs={10.5} className={classes.alignGridAns}>
                    <Typography component="div" variant="body1">
                      <span dangerouslySetInnerHTML={{ __html: data.answer }} />
                    </Typography>
                  </Grid>

                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
      {loading ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )
        : null}
      <br />
      <Typography>
        If you have any further questions, please call SRIDEVI GANTI @ 510-364-2493 or email
        {' '}
        <strong>sridevi.ganti@manabadi.siliconandhra.org</strong>
      </Typography>
    </div>
  );
}
