/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
import React, {
  useEffect, useState, memo, useRef,
} from 'react';
import {
  Box, Divider, Grid,
} from '@mui/material';
import _ from 'lodash';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import {
  Button,
  Checkbox,
  AddressAutoComplete,
  PhoneInputRegistration,
} from '../../../components/atoms';
import { Buttons } from '../../../constant';
import useStyles from '../../../custom-hooks/useStyles';
// eslint-disable-next-line import/no-named-as-default
import styles from './style';
import style from '../style';
import commonStyle from '../../../utils/commonClasses';
import { titleOptions } from './constant';
import { getParentPlaceInformation } from '../../../utils/mapMethod';
import { getEmailAvailabilityService } from '../../../store/services/auth';
import { removeSpecialChar } from '../../../utils/methods';
import Loader from '../../../components/atoms/loader';
import PerformantTextField from '../../../components/atoms/PerformantTextFieldRegistration';
import useParent from '../../../custom-hooks/useParent';
import PerfromantDropdown from '../../../components/atoms/PerfromantDropDownRegistration';
import PerformantMultiValueDropdownRegistration from '../../../components/atoms/PerformantMultiValueDropDownRegistration';
import DialogAtom from '../../../components/atoms/dialog';
import ButtonAtom from '../../../components/atoms/button';

function ParentInfo({
  primaryButton,
  isEditParentDialog,
  isStudent,
  changeLog,
  loading,
  setLoading,
  handleStepper,
  setParentInfo,
  parentInfo,
  source = 'register',
  onCancel,
  onFormSubmit,
  onViewLogs,
  editParentClasses,
  isEnrollStudent,
  setParentValid,
  setParentFormik,
  setSource,
  setNewParentAddressEnroll,
  setPlaceSelected,
  isStudentDashboard = false,
}) {
  const { t } = useTranslation();
  const selectedParentData = useParent();

  const volunteerOptions = selectedParentData?.volunteers.map((i) => ({
    name: i.name,
    id: i.name,
  }));

  const hearAboutOptions = selectedParentData?.hearAboutUs.map((op) => ({
    ...op,
    id: op.name,
  }));
  const commonClasses = useStyles(commonStyle)();
  const {
    parentOneContact,
    parentTwoContact,
    parentTwoTitle,
    parentTwoEmail,
    parentTwoFirstName,
    parentTwoMiddleName,
    parentTwoLastName,
    parentTwoProfession,
    parentTwoCompany,
  } = parentInfo;
  const registerClasses = editParentClasses || useStyles(style)();
  const [parentOneCellphone, setParentOneCellphone] = useState(parentOneContact);
  const [parentTwoCellphone, setParentTwoCellphone] = useState(parentTwoContact);
  const [parentTwoInfo, setParentTwoInfo] = useState({
    parentTwoTitle: parentTwoTitle || '',
    parentTwoEmail: parentTwoEmail || '',
    parentTwoFirstName: parentTwoFirstName || '',
    parentTwoMiddleName: parentTwoMiddleName || '',
    parentTwoLastName: parentTwoLastName || '',
    parentTwoContact: parentTwoContact === '+1' ? '' : parentTwoContact || '',
    parentTwoProfession: parentTwoProfession || '',
    parentTwoCompany: parentTwoCompany || '',
  });
  const [parentOneEmailAlreadyExist, setParentOneEmailAlreadyExist] = useState(false);
  const [parentTwoEmailAlreadyExist, setParentTwoEmailAlreadyExist] = useState(false);
  const [isOpenErrorParents, setIsOpenErrorParents] = useState(false);
  const [homeAddressInfo, setHomeAddressInfo] = useState(
    parentInfo?.homeAddressInfo || {},
  );
  const [parentOneDefaultCountry, setParentOneDefaultCountry] = useState(
    parentInfo?.parentOneDefaultCountry || 'US',
  );
  const [parentTwoDefaultCountry, setParentTwoDefaultCountry] = useState(
    parentInfo?.parentTwoDefaultCountry || 'US',
  );
  const isParentTwoFilled = () => {
    const isAnyKeyHasValue = Object.keys(parentTwoInfo).some(
      (k) => parentTwoInfo[k],
    );
    return isAnyKeyHasValue;
  };
  const [readTelugu, setReadTeulgu] = useState(parentInfo?.readTelugu || false);
  const [speakTelugu, setSpeakTeulgu] = useState(
    parentInfo?.speakTelugu || false,
  );
  const textField = (
    label,
    id,
    type,
    handleChange,
    onBlur,
    value,
    error,
    setValue = () => { },
    required = true,
    allowSpecialCharacter = true,
    customClass = '',
  ) => {
    const inputRef = useRef();

    useEffect(() => {
      if (!value && inputRef?.current?.value) {
        setValue?.(id, inputRef?.current?.value);
      }
    }, [inputRef?.current?.value]);

    return (
      <PerformantTextField
        label={`${label} ${required ? '' : ''}`}
        id={id}
        required={required}
        name={id}
        type={type}
        inputRef={inputRef}
        value={value}
        onBlur={onBlur}
        error={error}
        inputStyle={{
          WebkitBoxShadow: '0 0 0 1000px white inset',
        }}
        onChange={(e) => {
          handleChange(e);
          if (Object.keys(parentTwoInfo).includes(id)) {
            setParentTwoInfo({ ...parentTwoInfo, [id]: e.target.value });
          }
        }}
        allowSpecialCharacter={allowSpecialCharacter}
        customClass={customClass}
      />
    );
  };
  const phoneInput = (
    label,
    id,
    handleChange,
    onBlur,
    onCountryChange,
    defaultCountry,
    value,
    error,
    setValue = () => { },
    required = true,
  ) => {
    const inputRef = useRef();

    useEffect(() => {
      if (!value && inputRef?.current?.value) {
        setValue?.(id, inputRef?.current?.value);
      }
    }, [inputRef?.current?.value]);

    return (
      <PhoneInputRegistration
        customClass="customClass1"
        label={label}
        inputRef={inputRef}
        inputProps={{
          style: {
            WebkitBoxShadow: '0 0 0 1000px white inset',
          },
        }}
        id={id}
        required={required}
        name={id}
        value={value}
        onBlur={onBlur}
        shrink
        showLabel
        onCountryChange={onCountryChange}
        defaultCountry={defaultCountry}
        error={error && Object.keys(error).length}
        onChange={(e) => {
          handleChange(e);
        }}
      />
    );
  };

  const classes = useStyles(styles)();
  const setTitle = (title) => (
    <Grid container className={classes.heading}>
      <Grid item xs={12}>
        {title}
      </Grid>
    </Grid>
  );
  const parentValidations = {
    title: Yup.string(t('TITLE_REQUIRED'))
      .min(2, t('TITLE_REQUIRED'))
      .required(t('TITLE_REQUIRED')),
    email: Yup.string(t('EMAIL_REQUIRED'))
      .email(t('EMAIL_INVALID'))
      .required(t('EMAIL_REQUIRED')),
    firstName: Yup.string(t('FIRSTNAME_REQUIRED'))
      .matches(/^[aA-zZ\s]+$/, t('NOT_SPECIAL_CHAR'))
      .min(2, t('FIRSTNAME_MIN'))
      .required(t('FIRSTNAME_REQUIRED')),
    lastName: Yup.string(t('LASTNAME_REQUIRED'))
      .matches(/^[aA-zZ\s]+$/, t('NOT_SPECIAL_CHAR'))
      .min(2, t('LASTNAME_MIN'))
      .required(t('LASTNAME_REQUIRED')),
    contact: Yup.string(t('CONTACTNO_REQUIRED'))
      .required(t('CONTACTNO_REQUIRED'))
      .test('isValidPhoneNumber', t('INVALID_PHONE'), (value) => (value ? isValidPhoneNumber(value) : false)),
    profession: Yup.string(t('PROFESSION_REQUIRED')).required(
      t('PROFESSION_REQUIRED'),
    ),
    company: Yup.string(t('NAMEOFCOMPANY_REQUIRED')).required(
      t('NAMEOFCOMPANY_REQUIRED'),
    ),
  };
  const parentOneValidations = Yup.object({
    parentOneTitle: parentValidations?.title,
    parentOneEmail: parentValidations?.email,
    parentOneFirstName: parentValidations?.firstName,
    parentOneLastName: parentValidations?.lastName,
    parentOneContact: parentValidations?.contact,
    homeAddress: Yup.string(t('HOMEADDRESS_REQUIRED'))
      .required(t('HOMEADDRESS_REQUIRED'))
      .test('isValidAddress', t('INVALID_ADDRESS'), (value) => (value ? value === homeAddressInfo?.add : false)),
    volunteer: isStudentDashboard ? null : Yup.array()
      .min(1, t('VOLUNTEER_REQUIRED'))
      .required(t('VOLUNTEER_REQUIRED')).nullable(t('VOLUNTEER_REQUIRED')),

    hearAboutUs: isStudentDashboard ? null : Yup.array()
      .min(1, t('HEARABOUT_REQUIRED'))
      .required(t('HEARABOUT_REQUIRED')).nullable(t('HEARABOUT_REQUIRED')),
  });

  const parentTwoValidations = Yup.object({
    parentTwoTitle: parentValidations?.title,
    parentTwoEmail: parentValidations?.email,
    parentTwoFirstName: parentValidations?.firstName,
    parentTwoLastName: parentValidations?.lastName,
    parentTwoContact: parentValidations?.contact,
  });
  const adminValidations = Yup.object({
    changeLog: Yup.string(t('CHANGE_LOG_REQUIRED')).required(
      t('CHANGE_LOG_REQUIRED'),
    ),
  });

  const concatenatedSchema = parentOneValidations.concat(parentTwoValidations);
  const reqConcatSchema = source === 'editParent' && changeLog
    ? concatenatedSchema.concat(adminValidations)
    : concatenatedSchema;
  const reqParentOneSchema = source === 'editParent' && changeLog
    ? parentOneValidations.concat(adminValidations)
    : parentOneValidations;

  const validationSchema = isParentTwoFilled()
    ? reqConcatSchema
    : reqParentOneSchema;

  const formik = useFormik({
    initialValues: parentInfo || {
      parentOneTitle: '',
      parentOneFirstName: '',
      parentOneMiddleName: '',
      parentOneLastName: '',
      parentOneEmail: '',
      parentOneProfession: '',
      parentOneContact: '',
      parentOneCompany: '',
      parentTwoTitle: '',
      parentTwoFirstName: '',
      parentTwoLastName: '',
      parentTwoMiddleName: '',
      parentTwoEmail: '',
      parentTwoProfession: '',
      parentTwoContact: '',
      parentTwoCompany: '',
      homeAddress: '',
      aptSuite: '',
      volunteer: [],
      hearAboutUs: [],
      changeLog: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const selectedHearAboutOptions = [];
      values?.hearAboutUs?.forEach((selectedVal) => {
        const selectedHearAbout = _.find(
          hearAboutOptions,
          (ha) => ha.id === selectedVal,
        );
        selectedHearAboutOptions.push(selectedHearAbout);
      });
      if (values.parentTwoContact === '+1') values.parentTwoContact = ''; // todo: review empty phone value gets converted to '+1'
      const info = {
        ...values,
        homeAddressInfo,
        selectedHearAboutOptions,
        speakTelugu,
        readTelugu,
        parentOneDefaultCountry,
        parentTwoDefaultCountry,
      };
      setLoading(true);
      const aPromises = [];
      aPromises.push(getEmailAvailabilityService(values?.parentOneEmail));
      if (isParentTwoFilled()) {
        aPromises.push(getEmailAvailabilityService(values?.parentTwoEmail));
      }
      Promise.all(aPromises)
        .then((aResponses) => {
          if (aResponses[0]?.email_found && formik.values?.parentOneEmail !== formik.initialValues?.parentOneEmail) {
            setParentOneEmailAlreadyExist(t('EMAIL_ALREADY_EXIST'));
            setLoading(false);
            if (source === 'editParent') {
              setIsOpenErrorParents(true);
            }
            return;
          }
          if (aResponses[1]?.email_found && formik.values?.parentTwoEmail !== formik.initialValues?.parentTwoEmail && formik.values?.parentTwoEmail !== formik.initialValues?.parentOneEmail) {
            setParentTwoEmailAlreadyExist(t('EMAIL_ALREADY_EXIST'));
            setLoading(false);
            if (source === 'editParent') {
              setIsOpenErrorParents(true);
            }
            return;
          }
          if (isStudent) {
            onFormSubmit(info, setLoading);
          } else if (source === 'editParent') {
            // used for edit operation not for new registration
            setParentInfo(info);
            if (onFormSubmit) {
              onFormSubmit(info);
            }
          } else {
            setParentInfo({
              ...info,
            });
            setLoading(false);
            handleStepper(1);
            window.scrollTo(0, 0);
          }
        })
        .catch(() => { });
    },
  });

  useEffect(() => {
    if (setSource) setSource(source);
  }, [source]);
  useEffect(() => {
    if (source === 'editParent') {
      formik.setValues({
        parentOneTitle: '',
        parentOneFirstName: '',
        parentOneMiddleName: '',
        parentOneLastName: '',
        parentOneEmail: '',
        parentOneProfession: '',
        parentOneContact: '',
        parentOneCompany: '',
        parentTwoTitle: '',
        parentTwoFirstName: '',
        parentTwoLastName: '',
        parentTwoMiddleName: '',
        parentTwoEmail: '',
        parentTwoProfession: '',
        parentTwoContact: '',
        parentTwoCompany: '',
        homeAddress: '',
        aptSuite: '',
        volunteer: [],
        hearAboutUs: [],
        changeLog: '',
      });
      formik.setValues(parentInfo);
    }
  }, [parentInfo]);
  const setPreviousFieldTouch = (key, isFormFor) => {
    let allFields = [
      'parentOneTitle',
      'parentOneFirstName',
      'parentOneMiddleName',
      'parentOneLastName',
      'parentOneEmail',
      'parentOneContact',
      'parentOneProfession',
      'parentOneCompany',
    ];
    if (isFormFor === 'parentTwo' || (isFormFor === 'parentOne' && isParentTwoFilled())) {
      allFields = allFields.concat([
        'parentTwoTitle',
        'parentTwoFirstName',
        'parentTwoMiddleName',
        'parentTwoLastName',
        'parentTwoEmail',
        'parentTwoContact',
        'parentTwoProfession',
        'parentTwoCompany',
      ]);
    }
    allFields = allFields.concat([
      'homeAddress',
      'aptSuite',
      'volunteer',
      'hearAboutUs',
    ]);
    if (changeLog) {
      allFields = allFields.concat(['changeLog']);
    }
    const index = allFields.indexOf(key);
    if (index > -1) {
      const obj = {};
      for (let i = 0; i <= index; i += 1) {
        const element = allFields[i];
        obj[element] = true;
      }
      formik.setTouched({ ...formik.touched, ...obj }, true);
    }
  };
  const handleParentOneCellphone = (phone) => {
    setParentOneCellphone(phone);
    formik.setFieldValue('parentOneContact', phone || '');
  };
  const handleParentTwoCellphone = (phone) => {
    setParentTwoInfo({ ...parentTwoInfo, parentTwoContact: phone });
    setParentTwoCellphone(phone);
    formik.setFieldValue('parentTwoContact', phone || '');
  };
  const handleParentOneDefaultCountry = (value) => {
    setParentOneDefaultCountry(value);
  };
  const handleParentTwoDefaultCountry = (value) => {
    setParentTwoDefaultCountry(value);
  };
  const setHeaderTitle = () => (
    <Grid container spacing={0} justifyContent="space-between">
      <Grid item xs={11}>
        <div className={registerClasses.headerTitle}>
          {t('PARENT_INFORMATION')}
        </div>
      </Grid>
      <Grid item justifyContent="flex-end" xs={1} className={classes.alignRight}>
        <Button
          btntype={Buttons.SECONDARY}
          icon={<ArrowForwardIosIcon />}
          className={classes.commonButtonNew}
          onClick={() => handleStepper(0, formik.handleSubmit)}
        />
      </Grid>
    </Grid>
  );
  const getErrorText = (key, errorText) => {
    if (isStudentDashboard && (key === 'volunteer' || key === 'hearAboutUs')) {
      return null;
    }
    if (formik.touched[key] && formik.errors[key]) {
      if (formik.errors[key].startsWith('parentOneContact must be a `string` type')) {
        return (
          <span data-testid={key} className={classes.errorText}>
            {t('CONTACTNO_REQUIRED')}
          </span>
        );
      }
      return (
        <span data-testid={key} className={classes.errorText}>
          {formik.errors[key]}
        </span>
      );
    }
    if (errorText) {
      return (
        <span className={classes.errorText}>
          {errorText}
        </span>
      );
    }
    return null;
  };

  function showPhoneError(touched, values, flag) {
    if (touched && !values) {
      return getErrorText(flag);
    } if (values && isValidPhoneNumber(values)) {
      return true;
    } if (touched) {
      return getErrorText(null, t('INVALID_PHONE'));
    }
    return true;
  }
  const setReadCheckBox = () => {
    setReadTeulgu(!readTelugu);
  };
  const setSpeakCheckBox = () => {
    setSpeakTeulgu(!speakTelugu);
  };
  const readTeluguClass = () => {
    if (source === 'editParent' && !isStudent) return (`${classes.readTelugu} ${classes.alignTextGrid} ${classes.checkboxEditparent}  ${classes.checkboxColor}`);
    if (isStudent) { return ` ${classes.checkboxForStudent} ${classes.alignTextGrid} ${classes.checkboxColor}`; }
    return ` ${classes.alignTextGrid} ${classes.checkboxColor}`;
  };

  const speakTeluguClass = () => {
    if (source === 'editParent' && !isStudent) return (` ${classes.speakTelugu} ${classes.alignTextGrid} ${classes.checkboxEditparent} ${classes.checkboxColor}`);
    if (isStudent) {
      return ` ${classes.checkboxForStudent} ${classes.alignTextGrid} ${classes.checkboxColor}`;
    }

    return ` ${classes.alignTextGrid} ${classes.checkboxColor}`;
  };

  useEffect(() => {
    if (isEnrollStudent) {
      setParentFormik(formik);
      if (Object.keys(formik?.errors).length) {
        setParentValid(false);
      } else {
        setParentValid(true);
      }
      const values = formik?.values;
      const selectedHearAboutOptions = [];
      values?.hearAboutUs?.forEach((selectedVal) => {
        const selectedHearAbout = _.find(
          hearAboutOptions,
          (ha) => ha.id === selectedVal,
        );
        selectedHearAboutOptions.push(selectedHearAbout);
      });
      const info = {
        ...values,
        homeAddressInfo,
        selectedHearAboutOptions,
        speakTelugu,
        readTelugu,
        parentOneDefaultCountry,
        parentTwoDefaultCountry,
      };
      setParentInfo(info);
    }
  }, [
    formik?.touched,
    speakTelugu,
    readTelugu,
    parentOneDefaultCountry,
    parentTwoDefaultCountry,
  ]);
  let buttonClass;
  if (source === 'editParent') {
    buttonClass = formik.isValid && formik.dirty ? classes.activeButton : classes.disabledButton;
  } else {
    buttonClass = commonClasses.activeButton;
  }
  useEffect(() => {
    if (isEnrollStudent) {
      setParentFormik(formik);
      if (Object.keys(formik?.errors).length) {
        setParentValid(false);
      } else {
        setParentValid(true);
      }
      const values = formik?.values;
      const selectedHearAboutOptions = [];
      values?.hearAboutUs?.forEach((selectedVal) => {
        const selectedHearAbout = _.find(
          hearAboutOptions,
          (ha) => ha.id === selectedVal,
        );
        selectedHearAboutOptions.push(selectedHearAbout);
      });
    }
  }, [
    formik?.errors,
    formik?.values,
  ]);
  useEffect(() => {
    formik.validateField('homeAddress');
  }, [homeAddressInfo]);
  const onCustomOnChange = (e) => {
    formik.handleChange(e);
  };
  return (
    <Box className={registerClasses.parentGridContainer}>
      {source === 'register' ? (
        <Grid
          container
          className={registerClasses.headerContainer}
          xs={12}
          justifyContent="space-between"
        >
          {setHeaderTitle()}
        </Grid>
      ) : (
        ''
      )}
      <FormikProvider value={formik}>
        <form
          name="tenantUserForm"
          noValidate
          autoComplete="off"
          className={`${registerClasses.form} ${classes.form}`}
        >
          <Grid container className={registerClasses.mainContainer}>
            {setTitle(t('PARENT_ONE'))}
            <div className={commonClasses.borderBottom} />
            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid item xs={12} md={6} lg={3} className={classes.alignGridTitle}>
                <PerfromantDropdown
                  minWidth="100%"
                  label={t('TITLE')}
                  labelId={t('TITLE')}
                  id="parentOneTitle"
                  name="parentOneTitle"
                  value={formik.values.parentOneTitle}
                  onBlur={() => setPreviousFieldTouch('parentOneTitle', 'parentOne')}
                  error={getErrorText('parentOneTitle')}
                  required
                  handleChange={formik.handleChange}
                  options={titleOptions}
                  className={classes.dropdownText}
                />
                {getErrorText('parentOneTitle')}
              </Grid>
              <Tooltip
                title={t('HOVER_FIRSTNAME')}
                placement="bottom-end"
                arrow
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  className={classes.alignGridFirstName}
                >
                  {textField(
                    t('FIRST_NAME'),
                    'parentOneFirstName',
                    'text',
                    formik.handleChange,
                    () => setPreviousFieldTouch('parentOneFirstName', 'parentOne'),
                    removeSpecialChar(formik.values.parentOneFirstName),
                    getErrorText('parentOneFirstName'),
                    formik.setFieldValue,
                    true,
                    false,
                  )}
                </Grid>
              </Tooltip>
              <Tooltip title={t('HOVER_MIDDLENAME')} placement="bottom" arrow>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  className={classes.alignGridMiddleName}
                >
                  {textField(
                    t('MIDDLE_NAME'),
                    'parentOneMiddleName',
                    'text',
                    formik.handleChange,
                    () => setPreviousFieldTouch('parentOneMiddleName', 'parentOne'),
                    removeSpecialChar(formik.values.parentOneMiddleName),
                    false,
                    formik.setFieldValue,
                    false,
                    false,
                  )}
                </Grid>
              </Tooltip>
              <Tooltip title={t('HOVER_LASTNAME')} placement="bottom-end" arrow>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  className={classes.alignGridLastName}
                >
                  {textField(
                    t('LAST_NAME'),
                    'parentOneLastName',
                    'text',
                    formik.handleChange,
                    () => setPreviousFieldTouch('parentOneLastName', 'parentOne'),
                    removeSpecialChar(formik.values.parentOneLastName),
                    getErrorText('parentOneLastName'),
                    formik.setFieldValue,
                    true,
                    false,
                  )}
                </Grid>
              </Tooltip>
            </Grid>

            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.alignTextGrid}
                style={{ paddingBottom: '7px' }}
              >
                {textField(
                  t('EMAIL'),
                  'parentOneEmail',
                  'email',
                  onCustomOnChange,
                  (e) => {
                    if (e.target.value !== formik.initialValues?.parentOneEmail) {
                      getEmailAvailabilityService(e.target.value)
                        .then((parentOneResponse) => {
                          if (parentOneResponse?.email_found) {
                            setParentOneEmailAlreadyExist(t('EMAIL_ALREADY_EXIST'));
                          } else {
                            setParentOneEmailAlreadyExist(false);
                          }
                        });
                      setPreviousFieldTouch('parentOneEmail', 'parentOne');
                    } else {
                      setParentOneEmailAlreadyExist(false);
                    }
                  },
                  formik.values.parentOneEmail,
                  parentOneEmailAlreadyExist || getErrorText('parentOneEmail'),
                  formik.setFieldValue,
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={`${classes.alignTextGridPhone} ${source === 'editParent' ? classes.alignTextGridPhoneEdit : ''}`}
              >
                {phoneInput(
                  `${t('CONTACT_NO')} *`,
                  'parentOneContact',
                  handleParentOneCellphone,
                  () => setPreviousFieldTouch('parentOneContact'),
                  handleParentOneDefaultCountry,
                  parentOneDefaultCountry,
                  formik?.values?.parentOneContact,
                  getErrorText('parentOneContact')
                  || (formik?.touched?.parentOneContact
                    && formik?.values?.parentOneContact
                    && !isValidPhoneNumber(formik?.values?.parentOneContact)
                    && getErrorText(null, t('INVALID_PHONE'))),
                )}
                {showPhoneError(formik?.touched?.parentOneContact, formik?.values.parentOneContact, 'parentOneContact')}
              </Grid>

            </Grid>

            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.alignTextGrid}
              >
                {textField(
                  t('PROFESSION'),
                  'parentOneProfession',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('parentOneProfession', 'parentOne'),
                  formik.values.parentOneProfession,
                  false,
                  formik.setFieldValue,
                  '',
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.alignTextGrid}
              >
                {textField(
                  t('NAME_OF_COMPANY'),
                  'parentOneCompany',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('parentOneCompany', 'parentOne'),
                  formik.values.parentOneCompany,
                  false,
                  formik.setFieldValue,
                  '',
                )}
              </Grid>
            </Grid>

            {/* Parent 2 */}
            {setTitle(t('PARENT_TWO'))}
            <div className={commonClasses.borderBottom} />
            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                className={classes.alignGridTitle}
              >
                <PerfromantDropdown
                  minWidth="100%"
                  label={t('TITLE')}
                  showLabel
                  id="parentTwoTitle"
                  name="parentTwoTitle"
                  value={formik.values.parentTwoTitle}
                  onBlur={() => setPreviousFieldTouch('parentTwoTitle', 'parentTwo')}
                  error={getErrorText('parentTwoTitle')}
                  required={isParentTwoFilled()}
                  handleChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue('parentTwoTitle', e.target.value);
                    setParentTwoInfo({
                      ...parentTwoInfo,
                      parentTwoTitle: e.target.value,
                    });
                  }}
                  options={titleOptions}
                />
                {getErrorText('parentTwoTitle')}
              </Grid>
              <Tooltip
                title={t('HOVER_FIRSTNAME')}
                placement="bottom-end"
                arrow
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  className={classes.alignGridFirstName}
                >
                  {textField(
                    t('FIRST_NAME'),
                    'parentTwoFirstName',
                    'text',
                    formik.handleChange,
                    () => setPreviousFieldTouch('parentTwoFirstName', 'parentTwo'),
                    removeSpecialChar(formik.values.parentTwoFirstName),
                    getErrorText('parentTwoFirstName'),
                    formik.setFieldValue,
                    isParentTwoFilled(),
                    false,
                  )}
                </Grid>
              </Tooltip>
              <Tooltip title={t('HOVER_MIDDLENAME')} placement="bottom" arrow>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  className={classes.alignGridMiddleName}
                >
                  {textField(
                    t('MIDDLE_NAME'),
                    'parentTwoMiddleName',
                    'text',
                    formik.handleChange,
                    () => setPreviousFieldTouch('parentTwoMiddleName', 'parentTwo'),
                    removeSpecialChar(formik.values.parentTwoMiddleName),
                    false,
                    formik.setFieldValue,
                    false,
                    false,
                  )}
                </Grid>
              </Tooltip>
              <Tooltip title={t('HOVER_LASTNAME')} placement="bottom-end" arrow>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  className={classes.alignGridLastName}
                >
                  {textField(
                    t('LAST_NAME'),
                    'parentTwoLastName',
                    'text',
                    formik.handleChange,
                    () => setPreviousFieldTouch('parentTwoLastName', 'parentTwo'),
                    removeSpecialChar(formik.values.parentTwoLastName),
                    getErrorText('parentTwoLastName'),
                    formik.setFieldValue,
                    isParentTwoFilled(),
                    false,
                  )}
                </Grid>
              </Tooltip>
            </Grid>

            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.alignTextGrid}
                style={{ paddingBottom: '7px' }}
              >
                {textField(
                  t('EMAIL'),
                  'parentTwoEmail',
                  'email',
                  formik.handleChange,
                  (e) => {
                    if (e.target.value !== '' && e.target.value !== formik.initialValues?.parentTwoEmail && e.target.value !== formik.initialValues?.parentOneEmail) {
                      getEmailAvailabilityService(e.target.value)
                        .then((parentOneResponse) => {
                          if (parentOneResponse?.email_found) {
                            setParentTwoEmailAlreadyExist(t('EMAIL_ALREADY_EXIST'));
                          } else {
                            setParentTwoEmailAlreadyExist(false);
                          }
                        });
                      setPreviousFieldTouch('parentTwoEmail', 'parentTwo');
                    } else {
                      setParentTwoEmailAlreadyExist(false);
                    }
                  },
                  formik.values.parentTwoEmail,
                  parentTwoEmailAlreadyExist || getErrorText('parentTwoEmail'),
                  formik.setFieldValue,
                  isParentTwoFilled(),
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={`${classes.alignTextGridPhone} ${source === 'editParent' ? classes.alignTextGridPhoneEdit : ''}`}
              >
                {phoneInput(
                  `${t('CONTACT_NO')} ${isParentTwoFilled() ? '*' : ''}`,
                  'parentTwoContact',
                  handleParentTwoCellphone,
                  () => {
                    formik.setTouched(
                      { ...formik.touched, parentTwoContact: true },
                      true,
                    );
                    setPreviousFieldTouch('parentTwoContact', 'parentTwo');
                  },
                  handleParentTwoDefaultCountry,
                  parentTwoDefaultCountry,
                  formik?.values?.parentTwoContact,
                  getErrorText('parentTwoContact')
                  || (formik?.touched?.parentTwoContact
                    && formik?.values?.parentTwoContact
                    && !isValidPhoneNumber(formik?.values?.parentTwoContact)
                    && getErrorText(null, t('INVALID_PHONE'))),
                )}
                {showPhoneError(formik?.touched?.parentTwoContact, formik?.values.parentTwoContact, 'parentTwoContact')}
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.alignTextGrid}
              >
                {textField(
                  t('PROFESSION'),
                  'parentTwoProfession',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('parentTwoProfession', 'parentTwo'),
                  formik.values.parentTwoProfession,
                  false,
                  formik.setFieldValue,
                  false,
                  isParentTwoFilled(),
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.alignTextGrid}
              >
                {textField(
                  t('NAME_OF_COMPANY'),
                  'parentTwoCompany',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('parentTwoCompany', 'parentTwo'),
                  formik.values.parentTwoCompany,
                  false,
                  formik.setFieldValue,
                  false,
                  isParentTwoFilled(),
                )}
              </Grid>
            </Grid>
            {/* Additional Info */}
            {setTitle(t('ADDITIONAL_INFO'))}
            <div className={commonClasses.borderBottom} />
            <Grid
              container
              spacing={2}
              className={classes.innerContainer}
              style={{ paddingBottom: '7px' }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.addressCSS}

              >
                <AddressAutoComplete
                  shrink
                  showLabel
                  className={`${classes.textArea} ${source === 'editParent' ? classes.autoComplete : ''}`}
                  label={`${t('HOMEADDRESS')} `}
                  id="homeAddress"
                  required
                  name="homeAddress"
                  value={formik.values.homeAddress}
                  error={getErrorText('homeAddress')}
                  onPlaceSelected={async (e) => {
                    const res = await getParentPlaceInformation(e.formatted_address);
                    formik.setFieldValue(
                      'homeAddress',
                      e.formatted_address,
                      true,
                    );
                    if (setNewParentAddressEnroll) {
                      setNewParentAddressEnroll({ ...res, add: e.formatted_address });
                    }
                    if (setPlaceSelected) {
                      setPlaceSelected(placeSelected => placeSelected + 1);
                    }
                    setHomeAddressInfo({ ...res, add: e.formatted_address });
                  }}
                  onChange={(e) => {
                    formik.setFieldValue('homeAddress', e.target.value);
                  }}
                  onBlur={() => setPreviousFieldTouch('homeAddress', 'parentOne')}
                />
                {getErrorText('homeAddress')}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
              >
                {textField(
                  t('APISUITE'),
                  'aptSuite',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('aptSuite', 'parentOne'),
                  formik.values.aptSuite,
                  false,
                  formik.setFieldValue,
                  '',
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className={source === 'editParent' && (isEditParentDialog)
                ? `${classes.innerContainer} ${classes.editParentInfoClassDialog}` : classes.innerContainer}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
              >
                <PerformantMultiValueDropdownRegistration
                  label={`${t('VOLUNTEER')} `}
                  options={volunteerOptions}
                  id="volunteer"
                  name="volunteer"
                  handleChange={formik.handleChange}
                  onBlur={() => setPreviousFieldTouch('volunteer', 'parentOne')}
                  value={formik.values.volunteer}
                  error={getErrorText('volunteer')}
                  required={!isStudentDashboard}
                  customClassSelect="customClass1"
                />
                {getErrorText('volunteer')}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                style={{ marginTop: '2px !important' }}
              >
                <PerformantMultiValueDropdownRegistration
                  label="HEARABOUT"
                  options={hearAboutOptions}
                  id="hearAboutUs"
                  name="hearAboutUs"
                  handleChange={formik.handleChange}
                  onBlur={() => setPreviousFieldTouch('hearAboutUs', 'parentOne')}
                  value={formik.values.hearAboutUs}
                  error={getErrorText('hearAboutUs')}
                  required={!isStudentDashboard}
                  customClassSelect="customClass1"
                />
                {getErrorText('hearAboutUs')}
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2} className={classes.innerContainer}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={
                  speakTeluguClass()
                }
              >
                <Checkbox
                  label={t('SPEAK_TELGU')}
                  checked={speakTelugu}
                  handleChange={setSpeakCheckBox}
                  styleObj={{
                    border: 0,
                    padding: 0,
                    margin: 0,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSpeakCheckBox();
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={
                  readTeluguClass()
                }
              >
                <Checkbox
                  label={t('READ_TELGU')}
                  checked={readTelugu}
                  handleChange={setReadCheckBox}
                  styleObj={{
                    border: 0,
                    padding: 0,
                    margin: 0,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setReadCheckBox();
                    }
                  }}
                />
              </Grid>
            </Grid>
            {source === 'editParent' && changeLog ? (
              <Grid container className={`${classes.innerContainer} ${classes.paddingLeft16}`}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.alignTextGrid}
                >
                  {textField(
                    t('CHANGE_LOG'),
                    'changeLog',
                    'text',
                    formik.handleChange,
                    () => setPreviousFieldTouch('parentTwoCompany', 'parentTwo'),
                    formik.values.changeLog,
                    getErrorText('changeLog'),
                    formik.setFieldValue,
                    true,
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className={classes.innerContainer}
                item
                xs={12}
                md={12}
                lg={12}
              >
                <Grid
                  className={classes.innerContainer}
                  item
                  xs={12}
                  md={12}
                  lg={12}
                />
              </Grid>
            )}
            {source !== 'editParent' ? (
              <Grid item xs={12} className={classes.outerContainer}>
                <div className={classes.footerBottom} />
              </Grid>
            ) : null}
            <Grid container className={classes.button}>
              {source === 'editParent' && onViewLogs && (
                <Grid item xs={5} md={3} lg={2} justifyContent="flex-start">
                  <span className={classes.viewLogs} onClick={onViewLogs}>
                    <FindInPageOutlinedIcon style={{ height: 16 }} />
                    <span style={{ marginTop: -4 }}>{t('VIEW_LOGS')}</span>
                  </span>
                </Grid>
              )}
              {isEnrollStudent ? null : (
                <Grid container xs={6} md={9} lg={9} className={classes.button}>
                  <Grid>
                    {source === 'editParent' ? (
                      <Button
                        btntype={Buttons.SECONDARY}
                        className={classes.secButton}
                        onClick={onCancel}
                        name={t('CANCEL')}
                      />
                    ) : (
                      <Button
                        btntype={Buttons.SECONDARY}
                        icon={<ArrowForwardIosIcon />}
                        className={classes.commonFooterButton}
                        onClick={() => handleStepper(0, formik.handleSubmit)}
                      />
                    )}
                    <Button
                      id="submit"
                      className={buttonClass}
                      onClick={formik.handleSubmit}
                      name={primaryButton || t('SAVE_CONTINUE')}
                      disabled={!formik.isValid || !formik.dirty}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            {loading && (
              <Grid>
                <Loader message={t('LOADING')} />
              </Grid>
            )}
          </Grid>
        </form>
        <DialogAtom
          isOpen={isOpenErrorParents}
          onClose={() => setIsOpenErrorParents(false)}
          dialogHeading={t('EMAIL_INVALID')}
          customClass={classes.paymentDialogAtom}
          content={(
            <Grid container spacing={0} justifyContent="space-between">
              <Grid item xs={12}>
                <div className={classes.lastNameError}>
                  {t('PARENT_EMAIL_ALREADY_EXIST')}
                </div>
              </Grid>
              <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>

                <div className={classes.popupBtn}>
                  <ButtonAtom
                    name={t('OK')}
                    onClick={() => {
                      setIsOpenErrorParents(false);
                    }}
                    btntype={Buttons.PRIMARY}
                  />
                </div>
              </Grid>
            </Grid>
          )}
          secHandle={() => setIsOpenErrorParents(false)}
        />
      </FormikProvider>
    </Box>
  );
}

export default memo(ParentInfo);
