import { colors, fonts } from '../../../theme';

const studentinfostyle = (() => ({
  activeButtonStudent: {
    color: '#f3f8fe !important',
    minWidth: '11vw !important',
    '@media (min-width: 1200px)': {
      height: '2.5vw',
      fontSize: '1vw',
      padding: '0.8vw',
      minWidth: '15vw',
      borderRadius: '0.4vw !important',
    },
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    boxShadow: '0 4px 4px -2px hsl(0deg 0% 65% / 25%)',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    marginLeft: '1.2vw !important',
    marginRight: '1vw',
    textTransform: 'none !important',
    background: `${colors.newBGColor} !important`,
    '@media (max-width: 900px)': {
      height: 'auto !important',
    },
  },
  lastNameError: {
    color: colors.red,
  },
  secButtonStudent: {
    color: '#1976d2 !important',
    minWidth: '11vw !important',
    border: '0.1px solid #1976d2',
    '@media (min-width: 1200px)': {
      height: '2.5vw',
      padding: '0.8vw',
      minWidth: '15vw',
      borderRadius: '0.4vw !important',
      fontSize: '1vw',
    },
    fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    background: 'white',
    boxShadow: '0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%) !important',
    boxSizing: 'border-box',
    fontFamily: 'Roboto, sans-serif',
    marginLeft: '1.2vw',
    textTransform: 'none',
    '& .MuiSvgIcon-root': {
      '@media (min-width: 1200px)': {
        width: '0.8vw',
        height: '1.8vw',
      },
    },
  },
  datePickerCustom: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        marginTop: '0.3vw',
        fontSize: '0.9vw',
        '&.MuiInputBase-root': {
          height: '2.9vw',
          lineHeight: '1',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&:hover fieldset': {
          border: `0.2vw solid ${colors.primary} !important`,
          outline: 'none',
        },
      },
      '@media (max-width: 1199px)': {
        borderRadius: 3,
      },
      fontFamily: fonts.fontType.roboto,
      borderRadius: '0.4vw',
      outline: 'none',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          width: '2vw',
          height: '2vw',
        },
      },
    },
    '& .MuiFormControl-root.MuiTextField-root': {
      '@media (min-width: 1200px)': {
        width: '100% !important',
      },
    },
    '& .MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
        transform: 'translate(1.5vw, -0.6vw) scale(1)',
        marginLeft: '-0.459vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: colors.primary,
      },
      fontFamily: fonts.fontType.roboto,
      '& .Mui-error': {
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: 'inline-table',
    },
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            '@media (min-width: 1200px)': {
              fontFamily: `${fonts.fontType.roboto} !important`,
              fontSize: '0.9vw !important',
              color: `${colors.placeHolderColor}!important`,
              letterSpacing: 1,
            },
            '@media (max-width: 1199px)': {
              fontSize: '3vw !important',
              fontFamily: `${fonts.fontType.roboto} !important`,
              color: `${colors.placeHolderColor}!important`,
              letterSpacing: 1,
            },
          },
        },
      },
    },
  },
  testContain: {
    width: '100%',
  },
  viewLogs: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    fontFamily: fonts.fontType.roboto,
    fontWeight: fonts.fontWeight.medium,
    display: 'flex',
    color: `${colors.actionIconsColor} !important`,
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingTop: '1vw',
  },
  form: {
    width: '100%',
    marginTop: '2vw',
  },
  dialogButtons: {
    position: 'absolute',
    padding: 0,
    bottom: 5,
    height: '50px',
  },
  dialogAtom: {
    '& .MuiDialogContent-root': {
      padding: '1vw !important',
    },
    '& .MuiDialogContent-root .MuiGrid-root': {
      maxWidth: '99% !important',
    },
  },
  btnGroup: {
    margin: '1.1vw 1% 1% 0.9vw',
    '& .MuiButton-root': {
      '@media (min-width: 1200px)': {
        margin: '1%',
        height: '2.7vw',
        fontSize: '0.8vw',
        borderRadius: '0.4vw !important',
        '& .MuiSvgIcon-root': {
          fontSize: '0.8vw',
        },
      },
      '@media (max-width: 900px)': {
        marginBottom: '10vw',
      },
    },
  },
  datePicker: {
    marginTop: '4%',
    width: '90%',
  },
  errorText: {
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    display: 'block',
    marginTop: '0.3vw',
    '@media (max-width: 1199px)': {
      fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
    },
  },
  heading: {
    color: colors.footerBottomColor,
    fontWeight: 500,
    width: '100%',
    fontSize: '1.5vw',
    marginTop: '1vw',
    borderRadius: '0.4vw',
    '@media (max-width: 1199px)': {
      fontSize: 'calc(14px + 6 * ((100vw - 320px) / 1199))',
    },
  },
  accTitle: {
    display: 'inline-block',
    width: '100%',
    '& .MuiAccordion-root': {
      border: `0 solid ${colors.primary}`,
      boxShadow: 'none',
    },
    '& .MuiAccordion-root .MuiButtonBase-root.MuiAccordionSummary-root': {
      width: '100%',
      borderRadius: '0.5vw',
      background: colors.secondary,
    },
    '& .MuiAccordionSummary-content': {
      margin: '1vw 0vw !important',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      '& svg': {
        '@media (min-width: 1200px)': {
          width: '2vw',
          height: '2vw',
          marginRight: '0.7vw',
        },
      },
    },
  },
  studentTitle: {
    '@media (min-width: 1200px)': {
      fontSize: '1.8vw',
    },
    color: colors.primary,
    float: 'left !important',
    paddingLeft: '1vw',
    fontWeight: 500,
  },
  siblingAddress: {
    width: '100%',
    margin: '0px',
    '& .MuiInputLabel-root': {
      color: `${colors.placeHolderColor} !important`,
    },
    '& .MuiFormControl-root': {
      margin: '8px 0px !important',
    },
  },
  siblingAddressText: {
    '& .MuiInputLabel-root': {
      color: `${colors.placeHolderColor} !important`,
      marginLeft: '15px !important',
    },
  },
  removeTitle: {
    float: 'right !important',
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    padding: '0vw 0.6vw 0vw 0vw',
  },
  buttonCircle: {
    border: '3px solid',
    borderRadius: '50%',
    marginLeft: 15,
    height: '50%',
    width: '4%',
    marginTop: 5,
  },
  accordianHeader: {
    display: 'flex',
  },
  mapContainerGrid: {
    maxWidth: '97%',
    marginTop: '10px',
    marginLeft: 'auto',
    '@media (min-width: 1200px)': {
      margin: '1.5vw 0vw 1.5vw 0.5vw',
      maxWidth: '33vw',
    },
    '& #example-map': {
      borderRadius: '1vw',
      height: '23vw !important',
      '@media (max-width: 899.99px)': {
        height: '300px !important',
      },
    },
  },
  mapContainerDescGrid: {
    marginBottom: '15px',
    padding: '0.5vw 0.5vw 2vw 0.7vw',

  },
  footerBottom: {
    background: colors.footerBottomColor,
    height: '0.1vw',
    display: 'block',
    width: '100%',
    marginTop: '2vw',
    marginLeft: '1.5vw',
  },
  innerContainer: {
    '& label.Mui-focused': {
      color: '#104F96 !important',
    },
  },
  alignGrid: {
    padding: 0,
    '@media (min-width: 1200px)': {
      maxWidth: '33.2%',
      height: '5vw',
    },
    '& .MuiOutlinedInput-root': {
      '&:focus': {
        border: '0.15vw solid #104F96',
        outline: 'none',
      },
    },
    '& #extraCurricularActivities': {
      border: `0.1vw solid ${colors.outline}`,
      color: `${colors.placeHolderColor} !important`,
      padding: '0.4vw',
      fontSize: '0.9vw',
      transform: 'translate(0.3vw, 0.4vw) scale(1)',
      '@media (max-width: 1199px)': {
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
        left: '15px',
      },
      fontFamily: fonts.fontType.roboto,
      letterSpacing: '0.1vw',
    },
    '& .MuiOutlinedInput-root .MuiSelect-select': {
      transform: 'translate(0vw, 0.1vw)',
    },
    '& .MuiOutlinedInput-root .MuiSelect-select.MuiSelect-multiple': {
      transform: 'translate(0vw, 0vw)',
    },
    '& #addressAutoComplete': {
      '@media (min-width: 1200px)': {
        width: '89.98%',
      },
    },
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      '& .MuiFormControl-root': {
        margin: 'auto',
      },
      '& div': {
        margin: 0,
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiFormControl-root': {
        margin: 0,
        width: '100% !important',
      },
    },
    '& .MuiTextField-root': {
      '@media (min-width: 1200px)': {
        width: '100% !important',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.1vw solid ${colors.primary} `,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.2vw solid ${colors.primary} !important`,
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      '&.Mui-focused fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
        outline: 'none',
      },
      '@media (min-width: 1200px)': {
        marginTop: 0,
        borderRadius: '0.4vw',
        width: '100%',
      },
    },
  },
  alignGridDropDown: {
    '@media (min-width: 1200px)': {
      maxWidth: '33.3% !important',
    },
  },
  '.MuiDialogActions-root': {
    minWidth: '100px !important',
  },
  headerTitle: {
    paddingLeft: '0vw !important',
  },
  datePickerGrid: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.1vw solid #104F96 !important',
    },
    '& .MuiOutlinedInput-root:hover, & .MuiOutlinedInput-root:focus': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0.1vw solid #104F96 !important',
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '0.2vw solid #104F96 !important',
    },
    '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '0.2vw solid #104F96',
    },
  },
  locationHeading: {
    display: 'flex',
    padding: '0.5vw 0',
    fontSize: '1vw',
  },
  locationValue: {
    paddingLeft: '1%',
    color: 'gray',
  },
  locationHeader: {
    '@media (min-width: 1200px)': {
      padding: '1vw 0',
      fontSize: '1.5vw',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: '1.2vw solid #104F96 !important',
    },
    '&:hover fieldset': {
      border: '1.2vw solid #104F96 !important',
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '12px !important',
        color: `${colors.placeHolderColor}!important`,
        letterSpacing: 1,
      },
    },
  },
  imageAlign: {
    textAlign: 'center !important',
    '& > span': {
      marginLeft: '0px !important',
    },
  },
  headerContainer: {
    '@media (min-width: 1200px)': {
      height: '6vw',
    },
  },
  borderBottom: {
    width: '121%',
    height: 5,
    display: 'block',
    marginBottom: '1vw',
    background: colors.secondary,
  },
  label: {
    display: 'flex',
    fontWeight: fonts.fontWeight.medium,
    '@media (min-width: 1200px)': {
      fontSize: '1vw',
    },
    color: colors.black,
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'manual',
  },
  collon: {
    marginLeft: '-0.5vw !important',
  },
  value: {
    display: 'flex',
    fontWeight: fonts.fontWeight.low,
    '@media (min-width: 1200px)': {
      fontSize: '1vw',
    },
    color: 'gray',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'manual',
  },
  previewValue: {
    marginLeft: '0.3vw !important',
  },
  dataPadding: {
    paddingTop: '0.2vw',
    paddingBottom: '0.2vw',
    display: 'flex',
    flexDirection: 'row',
  },
  commonButtonNew: {
    minWidth: '2vw !important',
    height: '2.7vw !important',
    padding: '1vw 1vw 1.2vw 1.2vw !important',
    '@media (max-width: 1199px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
      borderRadius: '6px !important',
      marginLeft: '15px',
    },
    '@media (max-width: 499px)': {
      height: 'auto !important',
      padding: '5px 8px !important',
      minWidth: 'auto !important',
    },
    '& svg': {
      '@media (max-width: 1199px)': {
        width: '16px !important',
        height: '16px !important',
      },
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  academicYear: {
    '& .MuiOutlinedInput-root': {
      height: '2.9vw !important',
      '@media (max-width: 1199px)': {
        height: '52px !important',
      },
    },
    '& input.MuiOutlinedInput-input.Mui-disabled': {
      '-webkit-text-fill-color': `${colors.placeHolderColor} !important`,
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '10px !important',
        color: colors.primary,
        marginLeft: '0px !important',
      },
    },
  },
}));

export default studentinfostyle;
