import { colors, fonts } from '../../../theme';

const style = (theme) => ({
  noData: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  mapPinImg: {
    '@media (min-width: 1200px)': {
      width: '1vw',
      height: '1vw',
    },
    '@media (max-width: 1200px)': {
      marginLeft: '5vw',
    },
    transform: 'translate(0.9vw, -0.1vw)',
  },
  gridContainer: {
    padding: '2vw 2vw 2vw 3vw !important',
  },
  yearDropdown: {
    '& .MuiSelect-select': {
      fontFamily: fonts.fontType.roboto,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        width: '8vw !important',
      },
    },
    '& .MuiSvgIcon-root': {
      width: '1vw',
      height: '1vw',
    },
    '& .MuiSelect-nativeInput': {
      fontSize: '0.9vw',
    },
    '& .MuiInput-root:before': {
      borderBottom: '0.15vw solid #104F96 !important',
    },
  },
  year: {
    textAlign: 'start',
    '& .MuiInput-root:before': {
      borderBottom: '0.15vw solid #104F96 !important',
    },
    '& img': {
      width: '1vw',
      height: '1vw',
      transform: 'translate(1vw, 0.1vw)',
    },
    '@media (min-width: 1200px)': {
      marginLeft: '8px',
    },
    '&.MuiFormControl-root': {
      width: '100% !important',
      '& span': {
        width: '100% !important',
      },
      '& .MuiInputLabel-root': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        fontSize: '1vw',
        letterSpacing: 1,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '1vw',
          padding: '0.7vw !important',
          width: '8vw !important',
        },
      },
      '& .MuiOutlinedInput-root': {
        background: 'white',
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
      },
      '& .MuiSvgIcon-root': {
        fontSize: '0.9vw',
        width: '1vw',
      },
      '& input': {
        marginTop: '2vw',
        boxSizing: 'border-box',
        fontSize: '0.9vw',
        fontFamily: fonts.fontType.roboto,
        color: '#696969 !important',
        '&::placeholder': {
          textTransform: 'uppercase',
          color: `${colors.placeHolderColor} !important`,
          opacity: 1,
          letterSpacing: 2,
          fontFamily: fonts.fontType.roboto,
          fontWeight: 400,
        },
      },

    },

  },
  locationDropdown: {
    textAlign: 'start',
    '@media (min-width: 1200px)': {
      minWidth: '15vw !important',
      maxWidth: '15vw !important',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiInputBase-root.MuiInput-root': {
      '@media (min-width: 1200px)': {
        minWidth: '30vw !important',
        maxWidth: '30vw !important',
      },
      '@media (max-width: 600px)': {
        width: '140px',
        paddingLeft: '10px',

      },
      '@media (max-width: 1199px) and (min-width: 601px)': {
        width: '250px',
        paddingLeft: '10px',
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiInputLabel-root': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        fontSize: '0.9vw',
        letterSpacing: 1,
        transform: 'translate(35px, 5px)',
      },
      '& .MuiInput-root:before': {
        borderBottom: '0.15vw solid #104F96 !important',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          paddingLeft: '20px',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-root': {
        background: 'white',
        height: '2.7vw !important',
        lineHeight: '1vw',
        borderRadius: '0.4vw',
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          width: '1vw',
          height: '1vw',
        },
      },
      '& input': {
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        boxSizing: 'border-box',
        fontSize: '0.9vw',
        fontFamily: fonts.fontType.roboto,
        color: '#696969 !important',
        '&::placeholder': {
          textTransform: 'uppercase',
          color: `${colors.placeHolderColor} !important`,
          opacity: 1,
          letterSpacing: 2,
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontFamily: fonts.fontType.roboto,
          fontWeight: 400,
        },
      },
    },

  },
  courseDropdown: {
    textAlign: 'start',
    marginLeft: '20vw',
    marginRight: '3vw',
    '@media (min-width: 1200px)': {
      minWidth: '10vw !important',
      maxWidth: '10vw !important',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiInputBase-root.MuiInput-root': {
      '@media (min-width: 1200px)': {
        minWidth: '10vw !important',
        maxWidth: '10vw !important',
        paddingLeft: '10px',
      },
      '@media (max-width: 600px)': {
        width: '150px',
        paddingLeft: '10px',
      },
      '@media (max-width: 1199px) and (min-width: 601px)': {
        // width: '50%',
        paddingLeft: '10px',
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiInputLabel-root': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.primary} !important`,
        fontSize: '0.9vw',
        letterSpacing: 1,
        transform: 'translate(35px, 5px)',
      },
      '& .MuiInput-root:before': {
        borderBottom: '0.15vw solid #104F96 !important',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: fonts.fontType.roboto,
        color: `${colors.black} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
        },
        backgroundColor: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-root': {
        background: 'white',
        height: '2.7vw !important',
        lineHeight: '1vw',
        borderRadius: '0.4vw',
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.primary}`,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `0.2vw solid ${colors.errorText}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `0.1vw solid ${colors.errorText}`,
        },
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          width: '1vw',
          height: '1vw',
        },
      },
      '& input': {
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        boxSizing: 'border-box',
        fontSize: '0.9vw',
        fontFamily: fonts.fontType.roboto,
        color: '#696969 !important',
        '&::placeholder': {
          textTransform: 'uppercase',
          color: `${colors.placeHolderColor} !important`,
          opacity: 1,
          letterSpacing: 2,
          padding: '0.7vw 1vw 0.7vw 0.7vw',
          fontFamily: fonts.fontType.roboto,
          fontWeight: 400,
        },
      },
    },

  },
  studentBox: {
    maxWidth: 'unset !important',
    '& .MuiGrid-item': {
      maxWidth: 'unset !important',
      textAlign: 'right !important',
    },
  },
  selectAll: {
    marginLeft: '15%',
    display: 'flex !important',
    justifyContent: 'flex-start',
    '& .MuiFormControlLabel-label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      width: '120px !important',
    },
  },
  checkbox: {
    display: 'flex !important',
    marginBottom: '12px',
    '& .MuiFormControlLabel-label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
    },
    '& div>MuiBox-root:first-child': {
      width: '25%',
    },
  },
  checkboxHead: {
    display: 'flex',
    margin: '5px 0 0px 0px !important',
    color: '#104F96',
    minWidth: '15%',
    position: 'relative',
    '& p': {
      margin: '0',
      '@media (max-width: 1200px)': {
        paddingRight: '15px',
      },
      '& span': {
        '@media (min-width: 1200px)': {
          position: 'absolute',
          right: '30px',
        },
      },
    },
  },
  checkboxContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '75%',
    '& .MuiFormControlLabel-root': {
      minWidth: '230px',
    },
    '& css-1vgzwi7-MuiFormControl-root:last child': {
      width: '0% !important',
      margin: '0 !important',
    },
  },
  dialogButtons: {
    justifyContent: 'flex-end',
    padding: '0 10px',
    '& .MuiButton-root': {
      '@media (min-width: 1200px)': {
        minWidth: '10vw !important',
        height: '2.7vw !important',
        fontSize: '0.8vw',
      },
    },
  },
  alertprimary: {
    height: '3vw',
    display: 'flex',
    color: colors.green,
  },
  popupButton: {
    display: 'flex',
    justifyContent: 'end',
    '& .MuiButtonBase-root.MuiButton-root': {
      '@media (min-width: 1200px)': {
        height: '3vw !important',
        minWidth: '9vw !important',
      },
      marginRight: '15px',
      borderRadius: '4px',
    },
  },
  location: {
    minWidth: '38vw',
    '& .MuiInputBase-root.MuiInput-root': {
      minWidth: '35vw !important',
      maxWidth: '35vw !important',
    },
    '& .MuiInputLabel-root': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.primary} !important`,
      fontSize: '1.2vw',
      letterSpacing: 1,
    },
    '& .MuiInput-root:before': {
      borderBottom: `0.1vw solid ${colors.actionIconsColor}`,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      textAlign: 'left',
      padding: '0.3vw 1vw 1vw 1vw',
      fontFamily: fonts.fontType.roboto,
      transform: 'translate(8px, 3px)',
      backgroundColor: 'inherit',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    '& .MuiOutlinedInput-root': {
      background: 'white',
      height: '2.7vw !important',
      lineHeight: '1vw',
      borderRadius: '0.4vw',
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: '0.9vw',
      width: '1vw',
      height: '1vw',
      right: '-0.5vw',
    },
    '& input': {
      padding: '0.7vw 1vw 0.7vw 0.7vw',
      boxSizing: 'border-box',
      fontSize: '0.9vw',
      fontFamily: fonts.fontType.roboto,
      color: '#696969 !important',
      '&::placeholder': {
        textTransform: 'uppercase',
        color: `${colors.placeHolderColor} !important`,
        opacity: 1,
        letterSpacing: 2,
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        fontFamily: fonts.fontType.roboto,
        fontWeight: 400,
      },
    },

  },
  titleRow: {
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    fontSize: '1.5vw',
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  agoText: {
    justifyContent: 'center',
    fontSize: 12,
    color: colors.primary,
    display: 'flex',
    marginLeft: '1.3vw',
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'left',
    },
    '& b': {
      marginLeft: 5,
    },
  },
  filterCSV: {
    '& ul': {
      padding: 0,
      marginTop: 50,
    },
    '& li': {
      listStyle: 'none',
      display: 'inline-block',
      padding: '5px 16px',
      color: colors.primary,
      fontSize: 16,
      fontWeight: '500',
      '@media (min-width: 631px)': {
        borderRight: `solid 1px ${colors.borderColor}`,
      },
      '& span': {
        color: colors.black,
        paddingBottom: 10,
      },
    },
  },
  manageBottomScroll: {
    '& .MuiDataGrid-root': {
      height: 'auto !important',
      '& .MuiDataGrid-virtualScrollerContent': {
        '@media (min-width: 1800px)': {
          paddingBottom: '60% !important',
          height: '100%',
        },
        '@media (min-width: 3000px)': {
          paddingBottom: '90% !important',
          height: '100%',
        },
      },
      '& .MuiDataGrid-columnHeadersInner, & .MuiDataGrid-virtualScrollerRenderZone': {
        paddingLeft: '0 !important',
      },
      '& .MuiDataGrid-virtualScrollerRenderZone': {
        position: 'absolute',
        '& .MuiDataGrid-row': {
          maxHeight: '52px !important',
          minHeight: '52px !important',
        },
      },
      '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
        width: '20vw !important',
        minWidth: '20vw !important',
        maxWidth: '20vw !important',
      },
      '& .MuiDataGrid-virtualScroller': {
        overflow: 'hidden !important',
        minHeight: '100px !important',
        height: 'auto !important',
        '@media (max-width: 900px)': {
          overflow: 'auto !important',
        },
      },
      '& .commonHeader, .commonCell': {
        '@media (max-width: 1199px)': {
          width: '150px !important',
          minWidth: '150px !important',
          maxWidth: '150px !important',
          borderRadius: '50%',
          minHeight: '3vw !important',
        },
      },
    },
  },
});

export default style;
