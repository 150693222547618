/* eslint-disable radix */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Grid, TextField, Typography,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import * as Yup from 'yup';

import { DropdownHomeWork, Loader } from '../../../../components/atoms';
import ButtonAtom from '../../../../components/atoms/button';
import DatePickerAtom from '../../../../components/atoms/datepicker';
import { Buttons } from '../../../../constant';

import styles, { materialStyles } from './styles';

import useStudent from '../../../../custom-hooks/useStudent';
import { getAllCourses } from '../../../../store/actions/getStudent';
import { getAssignedLocations } from '../../../../store/actions/getLocationCoordinator';
import useLocationCoordinator from '../../../../custom-hooks/useLocationCoordinator';

import useAcademicPanel from '../../../../custom-hooks/useAcademicPanel';
import { getQuarterDetails } from '../../../../store/actions/academic-panel';
import useStyles from '../../../../custom-hooks/useStyles';
import { getHomeworkSchema, saveHomeworkSchema } from '../../../../store/actions/homework-schema';
import useHomeworkSchema from '../../../../custom-hooks/useHomeworkSchema';
import { getCurrentYear, isValidSemesterYear } from '../../../../utils/methods';

const QUARTER_OPTIONS = [
  { id: 'Q1', key: 'QUARTER1' },
  { id: 'Q2', key: 'QUARTER2' },
  { id: 'Q3', key: 'QUARTER3' },
];

const SEMESTER_OPTIONS = [
  { id: 'S1', key: 'SEMESTER 1' },
  { id: 'S2', key: 'SEMESTER 2' },
];

const todayLimitStart = new Date();
const todayLimitEnd = new Date();
todayLimitStart.setHours(0, 0, 0);
todayLimitEnd.setHours(23, 59, 0);

const getCourseOptions = (courses) => courses.map((course) => ({
  id: course.id,
  name: course.name,
}));

const isValidDate = (value, date = null) => {
  const val = moment(value).format('yyyy-MM-DD');
  const now = date ? moment(date).format('yyyy-MM-DD') : moment().format('yyyy-MM-DD');
  if (moment(val).isBefore(moment(now))) {
    return false;
  }
  return true;
};

const isValidDateRange = (start, end, date = null) => {
  const startDate = moment(start).utc(false).format('yyyy-MM-DD');
  const endDate = moment(end).utc(false).format('yyyy-MM-DD');
  const now = date ? moment(date).format('yyyy-MM-DD') : moment().format('yyyy-MM-DD');

  return moment(now).isBetween(startDate, endDate, undefined, '[]');
};

const restrictKeys = (ev, allowDecimal = false) => {
  const restrictedSymbols = ['+', '-', 'e', '*'];

  if (!allowDecimal) {
    restrictedSymbols.push('.');
  }

  if (restrictedSymbols.includes(ev.key)) {
    ev.preventDefault();
  }
};

const findValidPrevNextIndices = (data, index, prev = true) => {
  if (data[index]?.deletedAt) {
    return findValidPrevNextIndices(data, prev ? index - 1 : index + 1, prev);
  }

  return index;
};

const useGetCourseRows = (ques) => {
  const [rows, setRow] = useState([]);

  useEffect(() => {
    setRow([]);
    [...Array(ques)].map((_, i) => setRow((oldRows) => [...oldRows, i]));
  }, [ques]);

  return { rows, setRow };
};

function RenderCourseField({
  questions,
  setTotalQuestions,
  handleSave,
  courseFieldData,
  courseFieldErrors,
  courseFieldTouched,
  changeAcademicPanelMarks,
  showSaveBtn,
  schemaCanBeEdited,
  quarterDates,
  refreshSchema,
  setError,
  setSuccessMsg,
  setErrorSchemaRemoved,
  setErrorMarks,
  setSaveClicked,
}) {
  const { t } = useTranslation();
  const classes = useStyles(materialStyles)();
  const { rows, setRow } = useGetCourseRows(questions);
  const [showRows, setShowRows] = useState(false);

  useEffect(() => {
    if (!rows || !rows.length) {
      setShowRows(false);
    } else {
      setShowRows(true);
    }
  }, [rows]);

  const deleteRow = (id) => {
    setTotalQuestions(questions - 1, id);
    setRow((oldRows) => oldRows.filter((rowID) => rowID !== id));
  };

  const onCrossClick = (rowNo) => {
    if (!schemaCanBeEdited) {
      return;
    }
    deleteRow(rowNo);
  };

  const handleChange = (e, rowNo, target) => {
    const { value, name } = target || e.target;
    changeAcademicPanelMarks(rowNo, name, value);
  };

  const { startDate, endDate } = quarterDates || {
    startDate: new Date(),
    endDate: new Date(),
  };

  const quarterMinLimit = typeof startDate === 'string' ? new Date(startDate.slice(0, -1)) : startDate;
  const quarterMaxLimit = typeof endDate === 'string' ? new Date(endDate.slice(0, -1)) : endDate;

  return (
    <Box marginTop={2} minWidth="auto" style={styles.courseTableBox}>
      {showRows && (
        <>
          <Box style={styles.courseTableHeadingCont} minWidth="600px">
            <Grid container spacing={3} style={{ flexFlow: 'row' }}>
              <Grid item xs={2}>
                <Typography style={styles.courseTableHeading}>
                  {t('WEEKS')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={styles.courseTableHeading}>
                  {t('MARKS')}
                </Typography>
              </Grid>
              <Grid item xs={2} md={3} lg={3}>
                <Typography style={styles.courseTableHeading}>
                  {t('NOTES')}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={2}>
                <Typography style={styles.courseTableHeading}>
                  {t('START_DATE')}
                </Typography>
              </Grid>
              <Grid item xs={2} md={3} lg={2}>
                <Typography style={styles.courseTableHeading}>
                  {t('END_DATE')}
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
          <Box marginTop={2}>
            {rows.map((id) => {
              const validPrevIndex = findValidPrevNextIndices(courseFieldData, id - 1);

              const endDateMinLimit = new Date(courseFieldData[id]?.startDate)
                .setDate(new Date(courseFieldData[id]?.startDate).getDate() + 1);
              const startDateMinLimit = validPrevIndex >= 0 ? new Date(courseFieldData[validPrevIndex]?.endDate)
                .setDate(new Date(courseFieldData[validPrevIndex]?.endDate).getDate() + 1) : quarterMinLimit;

              if (courseFieldData[id]?.deletedAt) {
                return null;
              }

              return (
                <Box key={id} marginTop={2} minWidth={650}>
                  <Grid container spacing={3} style={{ flexFlow: 'row' }}>
                    {!courseFieldData[id]?.deletedAt && (
                      <>
                        <Grid item xs={2.5} md={3} lg={2} className={classes.formControl}>
                          <TextField
                            label={t('WEEKS')}
                            id="week"
                            required
                            name="week"
                            type="text"
                            disabled={!schemaCanBeEdited}
                            value={courseFieldData[id]?.week || ''}
                            onChange={(e) => handleChange(e, id)}
                            labelId="week"
                            size="small"
                            inputProps={{ min: '0', style: styles.textFieldLabel }}
                            InputLabelProps={{ style: styles.textFieldLabel }}
                            error={courseFieldErrors && courseFieldErrors[id]?.week && courseFieldTouched && courseFieldTouched[id]?.week ? courseFieldErrors[id]?.week : false}
                            helperText={courseFieldErrors && courseFieldErrors[id]?.week && courseFieldTouched && courseFieldTouched[id]?.week ? courseFieldErrors[id]?.week : ''}
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.formControl}>
                          <TextField
                            label={t('MARKS')}
                            id="marks"
                            required
                            name="marks"
                            type="number"
                            disabled={!schemaCanBeEdited}
                            value={courseFieldData[id]?.marks || ''}
                            onChange={(e) => handleChange(e, id)}
                            labelId="marks"
                            size="small"
                            onKeyPress={(e) => restrictKeys(e, true)}
                            inputProps={{ min: '0', style: styles.textFieldLabel }}
                            InputLabelProps={{ style: styles.textFieldLabel }}
                            error={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : false}
                            helperText={courseFieldErrors && courseFieldErrors[id]?.marks && courseFieldTouched && courseFieldTouched[id]?.marks ? courseFieldErrors[id]?.marks : ''}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.formControl}>
                          <TextField
                            label={t('NOTES')}
                            id="notes"
                            required
                            name="notes"
                            type="text"
                            disabled={!schemaCanBeEdited}
                            value={courseFieldData[id]?.notes || ''}
                            onChange={(e) => handleChange(e, id)}
                            labelId="notes"
                            size="small"
                            inputProps={{ style: styles.textFieldLabel }}
                            InputLabelProps={{ style: styles.textFieldLabel }}
                            error={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : false}
                            helperText={courseFieldErrors && courseFieldErrors[id]?.notes && courseFieldTouched && courseFieldTouched[id]?.notes ? courseFieldErrors[id]?.notes : ''}
                          />
                        </Grid>
                        <Grid item xs={4.5} md={3} lg={2} className={classes.dateControl}>
                          <DatePickerAtom
                            label={`${t('START_DATE')}*`}
                            minWidth="100%"
                            id="startDate"
                            name="startDate"
                            // eslint-disable-next-line no-nested-ternary
                            value={courseFieldData[id]?.startDate
                              ? moment(courseFieldData[id]?.startDate).format('MM/DD/YYYY') : ''}
                            type="date"
                            error={courseFieldErrors && courseFieldTouched && courseFieldTouched[id]?.startDate ? courseFieldErrors[id]?.startDate : false}
                            onChange={(newDate) => {
                              handleChange(null, id, {
                                name: 'startDate',
                                value: moment(newDate).format('MM/DD/YYYY'),
                              });
                            }}
                            textFieldProps={{
                              sx: {
                                svg: { fill: '#104F96', paddingTop: '0.3vw' },
                              },
                              size: 'small',
                            }}
                            InputLabelProps={{ style: styles.textFieldLabel }}
                            minDate={id && startDateMinLimit ? startDateMinLimit : quarterMinLimit}
                            maxDate={quarterMaxLimit}
                          />
                          {courseFieldErrors && courseFieldErrors[id]?.startDate && courseFieldTouched && courseFieldTouched[id]?.startDate && (
                            <span className={classes.errorText}>{courseFieldErrors[id]?.startDate}</span>
                          )}
                        </Grid>
                        <Grid item xs={4.5} md={3} lg={2} className={classes.dateControl}>
                          <DatePickerAtom
                            label={`${t('END_DATE')}*`}
                            minWidth="100%"
                            id="endDate"
                            name="endDate"
                            value={courseFieldData[id]?.endDate
                              ? moment(courseFieldData[id]?.endDate).format('MM/DD/YYYY') : ''}
                            type="date"
                            error={courseFieldErrors && courseFieldTouched && courseFieldTouched[id]?.endDate ? courseFieldErrors[id]?.endDate : false}
                            onChange={(newDate) => handleChange(null, id, {
                              name: 'endDate',
                              value: moment(newDate).format('MM/DD/YYYY'),
                            })}
                            textFieldProps={{
                              sx: {
                                svg: { fill: '#104F96', paddingTop: '0.3vw' },
                              },
                              size: 'small',
                            }}
                            InputLabelProps={{ style: styles.textFieldLabel }}
                            minDate={courseFieldData[id]?.startDate && endDateMinLimit
                              ? endDateMinLimit
                              : quarterMinLimit}
                            maxDate={quarterMaxLimit}
                          />
                          {courseFieldErrors && courseFieldErrors[id]?.endDate && courseFieldTouched && courseFieldTouched[id]?.endDate && (
                            <span className={classes.errorText}>{courseFieldErrors[id]?.endDate}</span>
                          )}
                        </Grid>
                        <Grid item xs={1}>
                          <Box mt={2} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                            <ClearIcon onClick={() => onCrossClick(id)} />
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              );
            })}
          </Box>
        </>
      )}

      {showSaveBtn
        && (
          <Box marginTop={2} alignItems="flex-end" style={styles.saveBtnCont}>
            <ButtonAtom
              btntype={Buttons.SECONDARY}
              onClick={() => {
                setSuccessMsg('');
                setError('');
                setErrorSchemaRemoved('');
                setErrorMarks('');
                refreshSchema();
              }}
              name={t('CANCEL')}
              type="submit"
            />

            <ButtonAtom
              btntype={Buttons.PRIMARY}
              onClick={() => {
                setSuccessMsg('');
                setError('');
                setErrorSchemaRemoved('');
                setErrorMarks('');
                handleSave();
                setSaveClicked(true);
              }}
              name={t('SAVE')}
              type="submit"
            />
          </Box>
        )}
    </Box>
  );
}

function RenderFieldsGrid({
  courseOptions,
  academicYearOptions,
  setLoading,
  selectedCourse,
  setSelectedCourse,
}) {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [errorMarks, setErrorMarks] = useState('');
  const [errorSchemaRemoved, setErrorSchemaRemoved] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [updateSchema, setUpdateSchema] = useState(false);
  const [academicYearCopy, setAcademicYearCopy] = useState('');
  const [homeworkMarksCopy, sethomeworkMarksCopy] = useState('');
  const [quarterCopy, setQuarterCopy] = useState('');
  const [validSemesterYear, setValidSemesterYear] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const { quarterDetails } = useAcademicPanel();
  const currentAcademicYear = getCurrentYear()?.id;

  const handleSave = (values, resetTouched = () => { }) => {
    setLoading(true);

    const createHomeworkPanelMarks = values?.homeworkPanelMarks?.map((ele) => ({
      week: ele.week,
      marks: ele.marks,
      notes: ele.notes,
      startDate: ele.startDate,
      endDate: ele.endDate,
    }));
    const updateHworkPanelMarks = values?.homeworkPanelMarks?.map((ele) => ({
      week: ele.week || 'week',
      marks: ele.marks || 0,
      notes: ele.notes || 'notes',
      startDate: ele.startDate || new Date().toISOString(),
      endDate: ele.endDate || new Date().toISOString(),
      homeworkPanelId: updateSchema,
      deletedAt: ele?.deletedAt,
      deletedBy: ele?.deletedBy,
    }));
    const payload = {
      courseId: values.courses,
      academicYear: academicYearOptions.find((year) => year.id === values.academicYear)?.name,
      maximumMarks: values.maxMarks,
      quarter: validSemesterYear ? values.semester : values.quarter,
      schemaLabel: values.schemaLabel,
      weightage: values.weightage,
      homeworkPanelMarks: updateSchema ? updateHworkPanelMarks : createHomeworkPanelMarks,
      ...(updateSchema ? { homeworkPanelId: updateSchema } : {}),
    };

    const loadFalse = () => {
      resetTouched();
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    saveHomeworkSchema(payload, updateSchema, loadFalse, setError, setSuccessMsg, validSemesterYear, setErrorSchemaRemoved, setUpdateSchema);
  };

  const checkValidDateByQuarter = (date) => {
    let editable = false;
    if (!quarterDetails) {
      return quarterDetails;
    }
    const { academicYearQuarterDates } = quarterDetails;

    academicYearQuarterDates.forEach((quarterItem) => {
      if (quarterItem.quarter === quarterCopy) {
        editable = isValidDateRange(quarterItem.startDate, quarterItem.endDate, date);
      }
    });

    return editable;
  };

  const getQuarterStartEndDate = () => {
    if (quarterDetails) {
      const { academicYearQuarterDates } = quarterDetails;
      return academicYearQuarterDates.find((quarterItem) => quarterItem.quarter === quarterCopy);
    }

    return null;
  };

  const validationSchema = Yup.object({
    schemaLabel: Yup.string(t('SCHEMA_LABEL_IS_REQUIRED'))
      .required(t('SCHEMA_LABEL_IS_REQUIRED')),
    weightage: Yup.number(t('WEIGHTAGE_IS_REQUIRED'))
      .required(t('WEIGHTAGE_IS_REQUIRED'))
      .max(100, t('INVALID_WEIGHTAGE')),
    totalWeeks: Yup.number(t('TOTAL_WEEKS_REQUIRED'))
      .required(t('TOTAL_WEEKS_REQUIRED')),
    maxMarks: Yup.number(t('MAX_MARKS_ARE_REQUIRED'))
      .required(t('MAX_MARKS_ARE_REQUIRED')),
    homeworkPanelMarks: Yup.array().of(
      Yup.object().shape({
        week: Yup.string().test('week required test', t('WEEKS_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (homeworkMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        marks: Yup.number().test('marks required test', t('MARKS_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (homeworkMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        notes: Yup.string().test('notes required test', t('NOTES_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (homeworkMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        startDate: Yup.date().nullable().test('startDate required test', t('START_DATE_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (homeworkMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }).typeError(t('INVALID_DATE'))
          .test(
            'startDate in academicYear range',
            t('INVALID_DATE'),
            (value, context) => {
              if (academicYearCopy) {
                const [startYear, endYear] = academicYearCopy.split('-');
                const startDateObj = new Date(startYear, 0, 1);
                const endDateObj = new Date(endYear, 11, 31);

                const { path } = context;

                const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

                if (homeworkMarksCopy[currIndex]?.deletedAt) {
                  return true;
                }

                const prevIndex = findValidPrevNextIndices(homeworkMarksCopy, currIndex - 1);

                if (homeworkMarksCopy && Array.isArray(homeworkMarksCopy) && homeworkMarksCopy.length && homeworkMarksCopy[prevIndex]) {
                  return (moment(value).isSameOrAfter(startDateObj) && moment(value).isSameOrBefore(endDateObj)
                    && moment(new Date(homeworkMarksCopy[currIndex].startDate)).isAfter(homeworkMarksCopy[prevIndex].endDate));
                }

                return (moment(value).isSameOrAfter(startDateObj) && moment(value).isSameOrBefore(endDateObj));
              }

              return true;
            },
          )
          .test(
            'startDate in quarter range',
            validSemesterYear ? t('START_DATE_OUT_SEMESTER_RANGE') : t('START_DATE_OUT_QUARTER_RANGE'),
            (value, context) => {
              const { path } = context;

              const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

              if (homeworkMarksCopy[currIndex]?.deletedAt) {
                return true;
              }

              return checkValidDateByQuarter(value);
            },
          ),
        endDate: Yup.date().nullable().test('startDate required test', t('END_DATE_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (homeworkMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }).typeError(t('INVALID_DATE'))
          .when(
            'startDate',
            (startDate, schema) => {
              if (!startDate) {
                return schema;
              }

              const date = new Date(startDate.getTime());

              date.setDate(date.getDate() + 1);
              date.setHours(0, 0, 0);

              return (date
                ? schema.min(date, t('END_DATE_LESS_THAN_START_DATE'))
                : schema);
            },
          )
          .test(
            'endDate in academicYear range',
            t('INVALID_DATE'),
            (value, context) => {
              if (academicYearCopy) {
                const [startYear, endYear] = academicYearCopy.split('-');
                const startDateObj = new Date(startYear, 0, 1);
                const endDateObj = new Date(endYear, 11, 31);

                const { path } = context;

                const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

                if (homeworkMarksCopy[currIndex]?.deletedAt) {
                  return true;
                }

                const nextIndex = findValidPrevNextIndices(homeworkMarksCopy, currIndex + 1, false);

                if (
                  homeworkMarksCopy
                  && Array.isArray(homeworkMarksCopy)
                  && homeworkMarksCopy.length
                  && homeworkMarksCopy[nextIndex]
                  && homeworkMarksCopy[nextIndex].startDate
                ) {
                  return (moment(value).isBetween(startDateObj, endDateObj)
                    && moment(
                      typeof homeworkMarksCopy[nextIndex].startDate === 'string'
                        ? new Date(homeworkMarksCopy[nextIndex].startDate)
                        : homeworkMarksCopy[nextIndex].startDate,
                    ).isAfter(homeworkMarksCopy[currIndex].endDate));
                }

                return moment(value).isBetween(startDateObj, endDateObj);
              }

              return true;
            },
          )
          .test(
            'endDate in quarter range',
            validSemesterYear ? t('END_DATE_OUT_SEMESTER_RANGE') : t('END_DATE_OUT_QUARTER_RANGE'),
            (value, context) => {
              const { path } = context;

              const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

              if (homeworkMarksCopy[currIndex]?.deletedAt) {
                return true;
              }

              return checkValidDateByQuarter(value);
            },
          ),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      courses: '',
      academicYear: '',
      quarter: 'Q1',
      schemaLabel: '',
      passingCriteria: '',
      weightage: '',
      totalWeeks: '',
      maxMarks: '',
      homeworkPanelMarks: [],
      semester: 'S1',
    },
    validationSchema,
    // eslint-disable-next-line no-use-before-define
    onSubmit: (values) => handleSave(values, resetHomeworkPanelTouchedValues),
  });

  useEffect(() => {
    if (homeworkMarksCopy?.length !== formik?.touched?.homeworkPanelMarks?.length) {
      formik?.touched?.homeworkPanelMarks?.pop();
    }
  }, [homeworkMarksCopy]);

  function resetHomeworkPanelTouchedValues() {
    formik.setFieldTouched('homeworkPanelMarks', formik.values.homeworkPanelMarks.map(() => ({
      week: false,
      marks: false,
      notes: false,
      startDate: false,
      endDate: false,
    })));
  }

  useEffect(() => {
    if (courseOptions && courseOptions.length) {
      formik.setTouched({}, false);

      formik.setFieldValue('courses', selectedCourse || courseOptions[0].id);
    }
  }, [courseOptions, selectedCourse]);

  useEffect(() => {
    if (formik.values.academicYear) {
      setAcademicYearCopy(formik.values.academicYear);
    }
    setValidSemesterYear(isValidSemesterYear(formik.values.academicYear));
  }, [formik.values.academicYear]);

  useEffect(() => {
    if (formik.values.quarter) {
      setQuarterCopy(formik.values.quarter);
    }
  }, [formik.values.quarter]);

  useEffect(() => {
    if (formik.values.semester) {
      setQuarterCopy(formik.values.semester);
    }
  }, [formik.values.semester]);

  useEffect(() => {
    if (academicYearOptions && academicYearOptions.length) {
      formik.setTouched({}, false);

      formik.setFieldValue('academicYear', currentAcademicYear);

      if (validSemesterYear) {
        formik.setFieldValue('semester', 'S1');
      } else {
        formik.setFieldValue('quarter', 'Q1');
      }
    }
  }, [academicYearOptions]);
  // ===================================

  const changeAcademicPanelMarks = (row, key, value) => {
    const clonedMarks = [...formik.values.homeworkPanelMarks];

    const dateValue = null;

    if (clonedMarks.length <= row) {
      clonedMarks[row] = {
        [key]: dateValue || value,
      };
    }

    if (key === 'week' || key === 'notes') {
      clonedMarks[row][key] = value;
    } else if (key === 'marks') {
      clonedMarks[row][key] = value;
    } else if (key === 'startDate' || key === 'endDate') {
      clonedMarks[row][key] = value;
    }
    formik.setFieldValue('homeworkPanelMarks', clonedMarks);

    sethomeworkMarksCopy(clonedMarks);
  };

  // get/update/save schema ======================================================
  const dispatch = useDispatch();

  const refreshSchema = () => {
    if (!formik.values.courses || !formik.values.academicYear || !formik.values.quarter) {
      return;
    }

    setLoading(true);

    dispatch(getHomeworkSchema({
      courseId: formik.values.courses,
      academicYear: academicYearOptions.find((year) => year.id === formik.values.academicYear)?.name,
      quarter: validSemesterYear ? formik.values.semester : formik.values.quarter,
    }));

    formik.setTouched({}, false);
  };

  useEffect(() => {
    setSaveClicked(false);
    setSuccessMsg('');
    setErrorSchemaRemoved('');
    refreshSchema();
  }, [formik.values.courses, formik.values.quarter, formik.values.semester, validSemesterYear]);

  const { schema } = useHomeworkSchema();

  useEffect(() => {
    if (!formik.values.academicYear) {
      return;
    }

    formik.setTouched({}, false);

    dispatch(getQuarterDetails(academicYearOptions.find((year) => year.id === formik.values.academicYear)?.name));
  }, [formik.values.academicYear]);

  const prefillSchema = (data) => {
    if (!data.length) {
      formik.setFieldValue('schemaLabel', '');
      formik.setFieldValue('passingCriteria', '');
      formik.setFieldValue('weightage', '');
      formik.setFieldValue('totalWeeks', '');
      formik.setFieldValue('maxMarks', '');
      formik.setFieldValue('homeworkPanelMarks', []);
      sethomeworkMarksCopy([]);
      setUpdateSchema(false);
      setLoading(false);
      setSuccessMsg('');

      return;
    }

    const prefillData = data[0];

    formik.setFieldValue('schemaLabel', prefillData?.schemaLabel || '');
    formik.setFieldValue('weightage', (prefillData?.weightage >= 0 ? prefillData?.weightage : 0));
    formik.setFieldValue('totalWeeks', Array.isArray(prefillData?.homework_panel_marks) && prefillData?.homework_panel_marks.length
      ? prefillData?.homework_panel_marks.length
      : 0);
    formik.setFieldValue('maxMarks', prefillData?.maximumMarks || 0);
    formik.setFieldValue('homeworkPanelMarks', prefillData?.homework_panel_marks || []);

    setTimeout(() => {
      formik.setFieldTouched('homeworkPanelMarks', prefillData?.homework_panel_marks.map(() => ({
        week: false,
        marks: false,
        notes: false,
        startDate: false,
        endDate: false,
      })));
    }, 1000);

    if (prefillData?.homework_panel_marks?.length > 0) {
      setErrorSchemaRemoved('');
    } else {
      setErrorSchemaRemoved(validSemesterYear ? t('SEMESTER_SCHEMA_REMOVED') : t('QUARTER_SCHEMA_REMOVED'));
      setSuccessMsg('');
    }
    sethomeworkMarksCopy(prefillData?.homework_panel_marks || []);
    setUpdateSchema(prefillData.id);

    setLoading(false);
  };

  useEffect(() => {
    if (schema === null) {
      return;
    }
    prefillSchema(schema);
  }, [schema]);

  // =============================================================================

  // remove academic marks entry as number of question or rows change;
  const deleteAcademiMarksEntry = (index) => {
    sethomeworkMarksCopy((oldMarks) => {
      const clonedMarks = [...oldMarks];
      clonedMarks.splice(index, 1);
      formik.setFieldValue('homeworkPanelMarks', clonedMarks, false);
      return clonedMarks;
    });
  };

  const deletedRows = useMemo(() => {
    if (formik.values.homeworkPanelMarks.length) {
      let count = 0;
      formik.values.homeworkPanelMarks.forEach((ele) => {
        if (ele?.deletedAt) {
          count += 1;
        }
      });

      return count;
    }

    return null;
  }, [formik.values.homeworkPanelMarks]);

  useEffect(() => {
    const marksLength = formik.values.homeworkPanelMarks.length - deletedRows;

    if (!formik.values.totalWeeks && formik.values.totalWeeks !== 0) {
      return;
    }

    if (formik.values.totalWeeks < marksLength) {
      const countDiff = marksLength - formik.values.totalWeeks;

      for (let i = 1; i <= countDiff; i++) {
        deleteAcademiMarksEntry(marksLength - i);
      }
    }

    if (formik.values.totalWeeks > formik.values.homeworkPanelMarks.length && formik.values.totalWeeks < 21) {
      const countDiff = formik.values.totalWeeks - formik.values.homeworkPanelMarks.length;

      const newEntries = [];

      for (let i = 1; i <= countDiff; i++) {
        newEntries.push({
          week: '',
          marks: '',
          notes: '',
          startDate: '',
          endDate: '',
        });
      }

      const allEntries = [...formik.values.homeworkPanelMarks, ...newEntries];
      formik.setFieldValue('homeworkPanelMarks', allEntries);
      sethomeworkMarksCopy(allEntries);
    }
  }, [formik.values.totalWeeks]);

  const setTotalQuestions = (questions, index) => {
    formik.setFieldValue('totalWeeks', questions);
    deleteAcademiMarksEntry(index);
  };

  const calculateCourseTotal = () => {
    if (!formik.values.homeworkPanelMarks.length) {
      return null;
    }

    return formik.values.homeworkPanelMarks.reduce((oldVal, currentEle) => (
      oldVal + (currentEle?.marks && !currentEle?.deletedAt ? parseFloat(currentEle.marks) : 0)
    ), 0);
  };

  const courseFieldTotal = useMemo(
    () => calculateCourseTotal() || 0,
    [formik.values.homeworkPanelMarks, formik.values.totalWeeks],
  );

  const checkIfSchemeCanBeEdited = () => {
    let editable = false;
    if (!quarterDetails) {
      return quarterDetails;
    }
    const { academicYearQuarterDates } = quarterDetails;

    academicYearQuarterDates.forEach((quarterItem) => {
      if (quarterItem.quarter === formik.values.quarter || quarterItem.quarter === formik.values.semester) {
        editable = isValidDate(quarterItem.endDate);
      }
    });

    return editable;
  };

  const schemaCanBeEdited = useMemo(checkIfSchemeCanBeEdited, [quarterDetails, formik.values.quarter, formik.values.semester]);

  useEffect(() => {
    if (formik.values.quarter === '') {
      setError('');
      setSuccessMsg('');
    } else if (!schemaCanBeEdited && schemaCanBeEdited !== null) {
      const errormsg = validSemesterYear ? t('SEMESTER_ERROR') : t('QUARTER_ERROR');
      setError(errormsg);
      setErrorSchemaRemoved('');
      setErrorMarks('');
      setSuccessMsg('');
    } else {
      setError('');
    }
  }, [schemaCanBeEdited, formik.values.quarter, selectedCourse]);

  const dependenciesForMarksValidation = formik.values.homeworkPanelMarks.map(
    (ele) => ele.marks,
  );

  useEffect(() => {
    /* eslint-disable no-else-return */
    const totalMarks = calculateCourseTotal();
    if ((formik.values.totalWeeks === 0) && (formik.values.maxMarks >= 1)) {
      setErrorMarks(t('TOTAL_WEEKS_ERROR'));
      setError('');
      setErrorSchemaRemoved('');
      setSuccessMsg('');
      return;
    }

    if (totalMarks === 0 || totalMarks === null || formik.values.maxMarks === '') {
      setErrorMarks('');
      return;
    }

    if (totalMarks !== formik.values.maxMarks) {
      setErrorMarks(t('MAX_MARKS_ERROR'));
      setSuccessMsg('');
      setError('');
      setErrorSchemaRemoved('');
    } else {
      setErrorMarks('');
    }
  }, [dependenciesForMarksValidation, formik.values.maxMarks]);
  const classes = useStyles(materialStyles)();

  useEffect(() => {
    const { totalWeeks } = formik.values;
    if (totalWeeks > 21) {
      formik.values.totalWeeks = 20;
    }
  }, [formik.values.totalWeeks]);

  return (
    <Box className={classes.formControlBox}>
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={5.5} lg={3} md={3} className={classes.formControl}>
            <DropdownHomeWork
              label={`${t('COURSE')}`}
              required
              id="courses"
              name="courses"
              labelId="courses"
              disabled={false}
              value={formik.values.courses}
              handleChange={(e) => {
                formik.handleChange(e);
                setSelectedCourse(e.target.value);
              }}
              onBlur={formik.handleBlur}
              options={courseOptions}
              customStyles={styles.dropDownCont}
              floatingLabel
              shrink
            />
          </Grid>
          <Grid item xs={5.5} lg={3} md={3} className={classes.formControl}>
            <DropdownHomeWork
              label={`${t('ACADEMICYEAR')}`}
              required
              id="academicYear"
              name="academicYear"
              labelId="academicYear"
              disabled={false}
              value={formik.values.academicYear}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={academicYearOptions}
              customStyles={styles.dropDownCont}
              floatingLabel
              shrink
            />
          </Grid>
          {validSemesterYear && (
            <Grid item xs={5.5} lg={3} md={3}>
              <DropdownHomeWork
                label={`${t('SEMESTER')} `}
                required
                id="semester"
                name="semester"
                labelId="semester"
                disabled={false}
                value={formik.values.semester}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={SEMESTER_OPTIONS.map((sem) => ({
                  id: sem.id,
                  name: sem.key,
                }))}
                customStyles={styles.dropDownCont}
                shrink
              />
            </Grid>
          )}
          {!validSemesterYear && (
            <Grid item xs={5.5} lg={3} md={3} className={classes.formControl}>
              <DropdownHomeWork
                label={`${t('QUARTER')}`}
                required
                id="quarter"
                name="quarter"
                labelId="quarter"
                disabled={false}
                value={formik.values.quarter}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={QUARTER_OPTIONS.map((quarter) => ({
                  id: quarter.id,
                  name: quarter.key,
                }))}
                customStyles={styles.dropDownCont}
                floatingLabel
                shrink
              />
            </Grid>
          )}
          <Grid item xs={5.5} lg={3} md={3} className={classes.formControl}>
            <TextField
              label={t('LABEL')}
              id="schemaLabel"
              labelId="schemaLabel"
              name="schemaLabel"
              required
              type="text"
              disabled={!schemaCanBeEdited}
              value={formik.values.schemaLabel}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              inputProps={{ style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
              helperText={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
            />
          </Grid>
          {/* row 2 */}
          <Grid item xs={3.5} lg={3} md={3} className={classes.formControl}>
            <TextField
              label={t('WEIGHTAGE')}
              id="weightage"
              name="weightage"
              labelId="weightage"
              required
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.weightage}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              onKeyPress={(e) => restrictKeys(e, true)}
              inputProps={{ min: '0', max: '100', style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.weightage ? formik.errors.weightage : undefined}
              helperText={formik.touched.weightage ? formik.errors.weightage : undefined}
            />
          </Grid>
          <Grid item xs={3.5} lg={3} md={3} className={classes.formControl}>
            <TextField
              label={t('TOTAL_WEEKS')}
              required
              id="totalWeeks"
              name="totalWeeks"
              labelId="totalWeeks"
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.totalWeeks < 21 ? formik.values.totalWeeks : 20}
              onKeyPress={restrictKeys}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              inputProps={{ min: '0', max: '20', style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.totalWeeks ? formik.errors.totalWeeks : undefined}
              helperText={formik.touched.totalWeeks ? formik.errors.totalWeeks : undefined}
            />
          </Grid>
          <Grid item xs={4} lg={3} md={3} className={classes.formControl}>
            <TextField
              label={t('MAXIMUM_MARKS')}
              id="maxMarks"
              labelId="maxMarks"
              name="maxMarks"
              required
              type="number"
              disabled={!schemaCanBeEdited}
              value={formik.values.maxMarks}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              onKeyPress={restrictKeys}
              size="small"
              inputProps={{ min: '0', style: styles.textFieldLabel }}
              InputLabelProps={{ style: styles.textFieldLabel }}
              error={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
              helperText={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
            />
          </Grid>
        </Grid>
        {error && (
          <Box style={styles.errorCont}>
            <Typography style={styles.errorText} variant="text">
              {error}
            </Typography>
          </Box>
        )}
        {errorMarks && (
          <Box style={styles.errorCont}>
            <Typography style={styles.errorText} variant="text">
              {errorMarks}
            </Typography>
          </Box>
        )}
        {successMsg && (
          <Box style={styles.successCont}>
            <Typography style={styles.successText} variant="text">
              {successMsg}
            </Typography>
          </Box>
        )}

        {errorSchemaRemoved && (
          <Box style={styles.errorCont}>
            <Typography style={styles.errorText} variant="text">
              {saveClicked === true ? errorSchemaRemoved : ''}
            </Typography>
          </Box>
        )}
        <RenderCourseField
          questions={formik.values.totalWeeks || 0}
          {...{ setTotalQuestions, changeAcademicPanelMarks }}
          handleSave={formik.handleSubmit}
          refreshSchema={refreshSchema}
          courseFieldData={formik.values.homeworkPanelMarks}
          courseFieldErrors={formik.errors.homeworkPanelMarks}
          courseFieldTouched={formik.touched.homeworkPanelMarks}
          showSaveBtn={courseFieldTotal === formik.values.maxMarks && schemaCanBeEdited}
          schemaCanBeEdited={schemaCanBeEdited}
          quarterDates={getQuarterStartEndDate()}
          setError={setError}
          setErrorSchemaRemoved={setErrorSchemaRemoved}
          setSuccessMsg={setSuccessMsg}
          setErrorMarks={setErrorMarks}
          setSaveClicked={setSaveClicked}
        />
      </FormikProvider>
    </Box>
  );
}

function HomeworkSchema() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedCourse, setSelectedCourse] = useState('');
  const [loading, setLoading] = useState(true);

  const classes = useStyles(materialStyles)();

  useEffect(() => {
    setLoading(true);
    const loadFalse = () => setLoading(false);

    dispatch(getAllCourses());
    dispatch(getAssignedLocations(loadFalse));
  }, []);

  const { courses } = useStudent();
  const { assignedYears } = useLocationCoordinator();

  return (
    <>
      <Box padding={3}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              {t('HOMEWORK_SCHEMA')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.contentCont}>
        <RenderFieldsGrid
          courseOptions={getCourseOptions(courses)}
          academicYearOptions={assignedYears}
          {...{ setLoading, selectedCourse, setSelectedCourse }}
        />
        {loading && (
          <Grid>
            <Loader message={t('LOADING')} />
          </Grid>
        )}
      </Box>
    </>
  );
}

export default React.memo(HomeworkSchema);
