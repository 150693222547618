export const exportRegionsColumns = [
  {
    id: 'name',
    label: 'Role Name',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'permissions',
    label: 'Permissions',
  },
];

export default {
  exportRegionsColumns,
};
