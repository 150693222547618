/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ExamCenterManagerTable from './exam-center-manager-table/index';
import CheckboxAtom from '../../../components/atoms/checkbox';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons, NavigateRoutes } from '../../../constant';
import { useStyles } from './style';
import { getExamCenterManager, createExamCenter, editExamCenter } from '../../../store/actions/getExamCenterManager';
import { DialogAtom, Loader } from '../../../components/atoms';
import AddCreateExamCenter from './Add/index';
import ExamCenterExportDialog from './export';
import useDataTableParams from '../../../custom-hooks/useDataTableParams';
import TextFieldAtom from '../../../components/atoms/textfield';

export default function ExamCenterManager() {
  const {
    nextPage,
  } = useDataTableParams();
  const examCenterManager = useSelector((state) => state.getExamCenterManager);
  const examCenterData = examCenterManager?.examCenterData;
  const { t } = useTranslation();
  const classes = useStyles();
  const csvLink = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dataForDownload, setDataForDownload] = useState([]);
  const [isDeactivateChecked, setIsDeactivateChecked] = useState(false);
  const [nameExistErrorMessage, setNameExistErrorMessage] = useState('');
  const [showAddExamCenterDialog, setShowAddExamCenterDialog] = useState(false);
  const [showEditExamCenterDialog, setShowEditExamCenterDialog] = useState(false);
  const [status, setStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [showExportDialog, setExportDialog] = useState(false);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const [viewInactive, setViewInactive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [totalActiveResources, setTotalActiveResources] = useState(0);
  const [totalInactiveResources, setTotalInactiveResources] = useState(0);
  const [pagenum, setPagenum] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [locationAddressInfo, setLocationAddressInfo] = useState({});
  const now = moment().format('DD-MM-yyyy');
  const currentTime = moment().format('hh:mm');
  const name = 'Exam-Center-Manager';
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(getExamCenterManager(setLoading, 1, 100, nextPage, searchText));
  }, []);

  useEffect(() => {
    dispatch(getExamCenterManager(setLoading, 1, 100, nextPage, searchText));
  }, [searchText]);

  const handleInactive = () => {
    setIsDeactivateChecked(!isDeactivateChecked);
  };

  useEffect(() => {
    if (examCenterData) {
      if (isDeactivateChecked) {
        setFilteredResources(examCenterData);
      } else {
        const arr = [];
        const arrInactive = [];
        examCenterData.map((hv) => {
          if (hv?.isActive === true) {
            arr.push(hv);
          } else {
            arrInactive.push(hv);
          }
          return arr;
        });
        setFilteredResources(arr);
        setTotalInactiveResources(arrInactive.length);
        setTotalActiveResources(arr.length);
      }
    }
  }, [examCenterData, isDeactivateChecked]);

  useEffect(() => {
    setTableData(filteredResources);
  }, [filteredResources]);

  useEffect(() => {
    setTotalRows(filteredResources?.length);
  }, [filteredResources]);

  const refreshList = () => {
    dispatch(getExamCenterManager(setLoading, 1, 100, nextPage, searchText));
  };

  const nameExistError = (message) => {
    setNameExistErrorMessage(message);
  };

  const createPayload = (values) => {
    if (values.aptSuite !== '' && values.aptSuite !== undefined) {
      return {
        name: values.name,
        city: locationAddressInfo.city,
        isActive: values.isActive,
        address: {
          ...locationAddressInfo,
          aptSuite: values.aptSuite,
        },
      };
    }
    return {
      name: values.name,
      city: locationAddressInfo.city,
      isActive: values.isActive,
      address: {
        ...locationAddressInfo,
      },
    };
  };

  const handleCreateExamCenter = (values) => {
    setNameExistErrorMessage('');
    const payload = createPayload(values);
    setLoading(true);
    dispatch(createExamCenter(payload, refreshList, nameExistError, setShowAddExamCenterDialog, setLoading, setSuccessMessage));
    setIsDeactivateChecked(isDeactivateChecked);
  };
  const handleEditExamCenter = (values) => {
    setNameExistErrorMessage('');
    const payload = {
      name: values.name,
      address: {
        ...locationAddressInfo,
        aptSuite: values.aptSuite,
        address: values.address,
      },
      isActive: values.isActive,
      addressId: values.addressId,
      id: values.id,
      logMessage: values.changeLog,
    };
    setLoading(true);
    dispatch(editExamCenter(payload, refreshList, nameExistError, setShowEditExamCenterDialog, setLoading));
    setIsDeactivateChecked(isDeactivateChecked);
  };
  const editExamCenterSchema = Yup.object({
    changeLog: Yup.string(t('CHANGE_LOG_REQUIRED')).required(
      t('CHANGE_LOG_REQUIRED'),
    ),
  });
  const validationSchema = Yup.object({
    name: Yup.string(t('EXAM_CENTER_NAME_REQUIRED'))
      .required(t('EXAM_CENTER_NAME_REQUIRED'))
      .typeError(t('EXAM_CENTER_NAME_REQUIRED')),
    address: Yup.string(t('EXAM_CENTER_ADDRESS_REQUIRED'))
      .required(t('EXAM_CENTER_ADDRESS_REQUIRED'))
      .typeError(t('EXAM_CENTER_ADDRESS_REQUIRED')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      aptSuite: '',
      isActive: false,
    },
    validationSchema: isEdit ? validationSchema.concat(editExamCenterSchema) : validationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        handleEditExamCenter(values);
      } else {
        handleCreateExamCenter(values);
      }
    },
  });
  useEffect(() => {
    const row = selectedRow?.[0];
    formik.values.name = row?.name;
    formik.values.address = row?.address;
    formik.values.aptSuite = row?.aptSuite;
    formik.values.isActive = row?.isActive === 'Active';
    if (selectedRow.length > 0) {
      setIsEdit(true);
      setStatus(row?.status === 'Active');
      formik.values.addressId = row?.addressId;
      formik.values.id = row?.id;
      formik.values.changeLog = '';
    }
  }, [selectedRow]);

  const getErrorText = (key, errorText) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={classes.errorText}>
      {formik.errors[key]}
    </span>
  ) : errorText ? (<span className={classes.errorText}>{errorText}</span>) : null);

  const viewLogs = () => {
    navigate(NavigateRoutes.EXAMCENTER_LOGS, {
      state: {
        id: selectedRow[0]?.id,
        lastRoute: NavigateRoutes.EXAM_CENTER_MANAGER_VIEW,
      },
    });
  };

  const renderSuccessDialog = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('ADD_EXAM_CENTER_SUCCESS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setSuccessMessage(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
      <Grid className={classes.roleRoot}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              {t('EXAM_CENTER_MANAGER')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldAtom
              id="locationSearch"
              customFormControlCss={{ margin: '0.8vw 0' }}
              name="locationSearch"
              type="text"
              minWidth="80%"
              placeholder={t('SEARCH')}
              disabled={false}
              onChange={(e) => {
                setSearchText(e.target.value);
                setViewInactive(false);
                setPagenum(1);
              }}
              value={searchText}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.dFlex}  item xs={8} lg={10.5} md={10.5}>
            <div>
              <ButtonAtom
                className={classes.addbutton}
                name={t('EXPORT_TO_CSV')}
                btntype={Buttons.PRIMARY}
                type={Buttons.PRIMARY}
                onClick={() => setExportDialog(true)}
              />
              <CSVLink
                data={dataForDownload}
                filename="region-list.csv"
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            </div>
            <ButtonAtom
              className={classes.addbutton}
              name={t('ADD')}
              btntype={Buttons.PRIMARY}
              type={Buttons.PRIMARY}
              onClick={() => {
                setSelectedRow([]);
                setIsEdit(false);
                setShowAddExamCenterDialog(!showAddExamCenterDialog);
                setNameExistErrorMessage('');
              }}
              icon={<AddIcon className={classes.addIconSize} />}
            />
          </Grid>
          <Grid item xs={1.5}>
            <div className={classes.deactiveCheckbox}>
              <CheckboxAtom
                label={t('VIEW_INACTIVE')}
                id="acceptCheckbox"
                checked={isDeactivateChecked}
                customClasses={classes.viewInactive}
                handleChange={() => handleInactive()}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.roleManagerContainer}>
          <Grid container className={classes.roleManagerList}>
            <Grid className={classes.tableView}>
              <ExamCenterManagerTable
                tableData={tableData}
                setSelectedRow={setSelectedRow}
                setShowEditExamCenterDialog={setShowEditExamCenterDialog}
                setNameExistErrorMessage={setNameExistErrorMessage}
                pageNext={nextPage}
                pagenum={pagenum}
                setPagenum={setPagenum}
                totalRows={totalRows}
                setLoading={setLoading}
                totalActiveResources={totalActiveResources}
                isDeactivateChecked={viewInactive}
                totalInactiveResources={totalInactiveResources}
              />
            </Grid>
          </Grid>
        </Grid>

      <DialogAtom
        isOpen={showAddExamCenterDialog}
        dialogHeading={t('ADD_EXAM_CENTER')}
        customClass={classes.addCourseDialogAtom}
        content={(
          <AddCreateExamCenter
            source="createExamCenter"
            formik={formik}
            nameExistErrorMessage={nameExistErrorMessage}
            setShowAddExamCenterDialog={setShowAddExamCenterDialog}
            showAddExamCenterDialog={showEditExamCenterDialog}
            getErrorText={getErrorText}
            status={status}
            setStatus={setStatus}
            setLocationAddressInfo={setLocationAddressInfo}
          />
        )}
        secHandle={() => setShowAddExamCenterDialog(false)}
      />

      <DialogAtom
        isOpen={showEditExamCenterDialog}
        dialogHeading={t('UPDATE_EXAM_CENTER')}
        customClass={classes.addCourseDialogAtom}
        content={(
          <AddCreateExamCenter
            source="createExamCenter"
            formik={formik}
            setShowAddExamCenterDialog={setShowEditExamCenterDialog}
            showAddExamCenterDialog={showEditExamCenterDialog}
            getErrorText={getErrorText}
            status={status}
            setStatus={setStatus}
            setLocationAddressInfo={setLocationAddressInfo}
            nameExistErrorMessage={nameExistErrorMessage}
            {...{ loading, setLoading, viewLogs }}
          />
        )}
        secHandle={() => setShowEditExamCenterDialog(false)}

      />
      <ExamCenterExportDialog
        show={showExportDialog}
        setShow={() => setExportDialog(false)}
        data={tableData}
      />
      {loading ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : null}
      {
        successMessage && (
          <DialogAtom
            isOpen={successMessage}
            dialogHeading={t('ADD_EXAM_CENTER_SUCCESS_TITLE')}
            customClass={classes.DialogAtom}
            content={renderSuccessDialog()}
            secHandle={() => setSuccessMessage(false)}
          />
        )
      }
    </Grid>
  );
}
