import { CubeProvider } from '@cubejs-client/react';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../../components/atoms';
import DisplayChart from './charts/displayChart';
import chartTypes from './charts/types';
import { getCubejsApi } from '../../../utils/cube';

export default function gridView({
  selectedOption, selectedFilter, locationIds, loading1,
}) {
  const [loading, setLoading] = useState(true);
  const option = selectedOption === '' ? 'year' : selectedOption;

  const apiReqLoading = useSelector(
    (reduxState) => reduxState.getStudent.loading,
  );
  const displayChartAccordingToType = (type, width) => {
    if (selectedFilter.some((filter) => filter.filterKey === 'academic_year' && filter.label !== null)) {
      return (
        <Grid item xs={12} sm={width || 6} md={width || 6}>
          <CubeProvider cubejsApi={getCubejsApi()}>
            <DisplayChart type={type} {...{ setLoading }} locationIds={locationIds} selectedFilter={selectedFilter} loading={loading} />
          </CubeProvider>
        </Grid>
      );
    }
    return null;
  };

  const yearMonthWeekGraphs = () => (
    <>
      {displayChartAccordingToType(chartTypes.RETURNING_BREAKDOWN)}
      {displayChartAccordingToType(chartTypes.COURSE_ENROLLMENT_BREAKDOWN)}
      {displayChartAccordingToType(chartTypes.GEO_REGION_BREAK_DOWN)}
      {displayChartAccordingToType(chartTypes.MAP_VIEW)}
      {(option === 'month' || option === 'year' || !option)
        && displayChartAccordingToType(chartTypes.ENROLLMENT_BREAKDOWN_BY_WEEK, 12)}
    </>
  );

  const acrossYearGraphs = () => (
    <>
      {displayChartAccordingToType(chartTypes.ENROLLMENT_GROWTH_ACROSS_YEAR, 6)}
      {displayChartAccordingToType(
        chartTypes.RETURNING_BREAKDOWN_ACROSS_YEAR,
        6,
      )}
      {displayChartAccordingToType(
        chartTypes.ENROLLMENT_BREAKDOWN_ACROSS_YEAR,
        12,
      )}
      {displayChartAccordingToType(
        chartTypes.COURSE_ENROLLMENT_BREAKDOWN_ACROSS_YEAR,
        6,
      )}
      {displayChartAccordingToType(
        chartTypes.COURSE_ENROLLMENT_BREAKDOWN_BY_REGION_ACROSS_YEAR,
        6,
      )}
    </>
  );
  return (
    <div>
      {loading || apiReqLoading || loading1 ? <Loader message="" /> : null}
      {option === 'none' ? null : (
        <Grid container spacing={2} pb={6}>
          {option === 'acrossyear'
            ? acrossYearGraphs()
            : yearMonthWeekGraphs()}
        </Grid>
      )}
    </div>
  );
}
