/* eslint-disable */
import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  TextField,
  Chip,
  Tooltip,
} from '@mui/material';
import { red } from '@mui/material/colors';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { EditIcon } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

function CardsAssignment({
  classes,
  assignments,
  mainCheckBox,
  deleteAssignmentOpener,
  handlePopUp,
  handleCheck,
}) {
  const { t } = useTranslation();
  const textFieldInlineStyles = {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
  };

  function getFileExtension(fileName) {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      return fileName.slice(lastDotIndex);
    }
    return '';
  }
  const handleEditClick = (card) => {
    handlePopUp(true, card);
  };

  const handleDeleteClick = (cardId) => {
    deleteAssignmentOpener(cardId);
  };

  const filteredAssignments = assignments.filter((card) => card.academicYear !== localStorage.getItem('academicYear'));

  return (
    <Box style={{ marginTop: '10px' }}>
      <Grid container spacing={2} className={classes.bottomGridContainer}>
        {filteredAssignments.map((card, index) => (
          <Grid item xs={6} lg={4} md={6} key={index}>
            <Grid item xs={6} lg={4} md={6}>
              <Card className={classes.mainCard}>
                <Box className={classes.cardContainerBox}>
                  <Box className={classes.cardTopBox}>
                    <Grid
                      container
                      className={classes.firstGridContainer}
                    >
                      <Grid item>
                        <CardContent className={classes.zeroPadding}>
                          <Typography className={classes.cardTitleContainer}>
                            {card.title}
                          </Typography>
                        </CardContent>
                      </Grid>
                      <Grid item>
                        <Grid item>
                          {
                            (mainCheckBox)
                              ? (
                                <CardActions disableSpacing className={classes.zeroPadding}>
                                  <div key={index}>
                                    <input
                                      type="checkbox"
                                      value={card.id}
                                      onChange={handleCheck}
                                    />
                                  </div>
                                </CardActions>
                              )
                              : (
                                <CardActions disableSpacing className={classes.zeroPadding}>
                                  <IconButton
                                    onClick={() => handleEditClick(card)}
                                    className={classes.iconButton}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    className={`${classes.zeroPaddingDelete} ${classes.deleteIcon}`}
                                    onClick={() => handleDeleteClick(card.id)}
                                  >
                                    <DeleteForeverOutlinedIcon sx={{ color: red[500] }} />
                                  </IconButton>
                                </CardActions>
                              )
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.secondGridContainer}>
                      <CardContent className={classes.zeroPadding}>
                        <Typography variant="caption">
                          {card.academicYear}
                        </Typography>
                      </CardContent>
                    </Grid>
                  </Box>
                  <Box className={classes.cardBottomBox}>
                    <Grid container >
                      <TextField
                        required
                        id="standard-multiline-static-required"
                        multiline
                        disabled
                        rows={10}
                        size="small"
                        inputProps={{ min: '0', style: textFieldInlineStyles }}
                        InputLabelProps={{ style: textFieldInlineStyles }}
                        name="description"
                        value={card.description}
                        validationOnChange={false}
                        validateOnBlur={false}
                      />
                    </Grid>
                    {card?.attachmentResourceName ? (
                      <Grid container className={classes.formControlGrid}>
                        <Grid item>
                          <span className={classes.textFields}>{t('ATTACH_RESOURCES')}</span>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <div className={classes.tagContainer}>
                              {Array.isArray(card.attachmentResourceName) ? 
                                card.attachmentResourceName[0]?.split(',').map((attachmentName, index) => {
                                  const trimmedName = attachmentName.trim();
                                  const isTruncated = trimmedName.length > 30;
                                  return (
                                    <Tooltip title={trimmedName} key={index}>
                                      <Chip
                                        label={isTruncated ? `${trimmedName.substr(0, 30)}...${getFileExtension(trimmedName)}` : trimmedName}
                                        className={isTruncated ? classes.truncateText : ''}
                                      />
                                    </Tooltip>
                                  );
                                }) : 
                                (card.attachmentResourceName ? card.attachmentResourceName.split(',').map((attachmentName, index) => {
                                  const trimmedName = attachmentName.trim();
                                  const isTruncated = trimmedName.length > 30;
                                  return (
                                    <Tooltip title={trimmedName} key={index}>
                                      <Chip
                                        label={isTruncated ? `${trimmedName.substr(0, 30)}...${getFileExtension(trimmedName)}` : trimmedName}
                                        className={isTruncated ? classes.truncateText : ''}
                                      />
                                    </Tooltip>
                                  );
                                }) : null)
                              }
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container className={classes.formControlGrid}>
                        <Grid item>
                          <span className={classes.textFields}>{t('ATTACH_RESOURCES')}</span>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <div className={classes.tagContainer}>
                                <DoNotDisturbIcon color="primary" />
                                <Typography className={classes.itemLabels}>{t('NO_DATA')}</Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default React.memo(CardsAssignment);