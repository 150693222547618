/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentStatus from './assignment-status';
import NewAssignment from './new-assignment';
import OldAssignment from './old-assignment';
import styles from './styles';
import useStyles from '../../../custom-hooks/useStyles';
import useStudent from '../../../custom-hooks/useStudent';
import { getAllCourses } from '../../../store/actions/getStudent';
import { getAssignedLocations } from '../../../store/actions/getLocationCoordinator';
import { Loader } from '../../../components/atoms';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';

function HomeworkAssignments() {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];

  const [showScheme, setShowScheme] = useState(true);
  const [showAssignment, setshowAssignment] = useState(Boolean);
  const [showAssignmentStatus, setShowAssignmentStatus] = useState(Boolean);
  const [loading, setLoading] = useState(true);

  const [expanded, setExpanded] = useState(true);

  const [newAssignment, setNewAssignment] = useState(true);

  const [selectedCourse, setSelectedCourse] = useState('');

  useEffect(() => {
    setLoading(true);
    const loadFalse = () => setLoading(false);

    dispatch(getAllCourses());
    dispatch(getAssignedLocations(loadFalse));
  }, []);

  const { courses } = useStudent();

  const handleSchemaDisplay = () => {
    setShowAssignmentStatus(false);
    setshowAssignment(false);
    setShowScheme(true);
    setExpanded(false);
  };

  const handleAssignmentStatusDisplay = () => {
    setShowAssignmentStatus(true);
    setshowAssignment(false);
    setShowScheme(false);
    setExpanded(false);
  };

  const handleNewAssignmentDisplay = () => {
    setShowAssignmentStatus(false);
    setNewAssignment(true);
    setShowScheme(false);
    setshowAssignment(false);
  };
  const handleOldAssignmentDisplay = () => {
    setShowAssignmentStatus(false);
    setNewAssignment(false);
    setShowScheme(false);
    setshowAssignment(true);
  };

  const lookForAccordianStateChange = (e, expand) => {
    setExpanded(expand);

    if (expand) {
      setShowScheme(false);
      setNewAssignment(true);
    } else {
      setShowScheme(false);
    }
  };

  return (
    <Box p={2}>
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
      <Box padding={3}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              Homework Assignments
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box class={classes.contentCont}>
        <Box class={classes.sidebar}>
          <Accordion
            square
            expanded={expanded}
            disableGutters
            onChange={lookForAccordianStateChange}
            className={
              showAssignment || newAssignment
                ? classes.selectedSectionTitle
                : classes.sectionTitle
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accodianSummary}
            >
              <Box onClick={handleSchemaDisplay}>
                <Typography
                  className={
                    showScheme
                    && (showAssignment || newAssignment)
                    && !showAssignmentStatus
                      ? classes.selectedSectionTitle
                      : classes.sectionTitle
                  }
                >
                  {t('ASSIGNMENT')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianDetails}>
              <Box
                onClick={handleNewAssignmentDisplay}
                mb={1}
                paddingY={1}
                paddingX={2}
                className={classes.accordianItem}
              >
                <Typography
                  className={
                    !showAssignment
                    && newAssignment
                    && !showAssignmentStatus
                      ? classes.selectedSectionTitle
                      : classes.sectionTitle
                  }
                >
                  New Assignments
                </Typography>
              </Box>
              <Box onClick={handleOldAssignmentDisplay} paddingX={2}>
                <Typography
                  className={
                    showAssignment
                    && !newAssignment
                    && !showAssignmentStatus
                      ? classes.selectedSectionTitle
                      : classes.sectionTitle
                  }
                >
                  Old Assignments
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          {userRole === 'System Admin' ? (
          <Box
            onClick={handleAssignmentStatusDisplay}
            m={3}
            class={`${classes.sectionTitleCont} ${
              showAssignmentStatus ? classes.selectedSection : ''
            }`}
          >
            <Typography
              className={
                showAssignmentStatus && !showScheme
                  ? classes.selectedSectionTitle
                  : classes.sectionTitle
              }
            >
              {t('ASSIGNMENT_STATUS')}
            </Typography>
          </Box>
          ) : null}
        </Box>
        <Box class={classes.content}>
          {showAssignmentStatus ? (
            <AssignmentStatus
              {...{
                courses,
                setLoading,
                selectedCourse,
                setSelectedCourse,
              }}
            />
          ) : (
            <Box className={classes.assignmentBox}>
              {newAssignment ? (
                <NewAssignment
                  {...{
                    courses,
                    setLoading,
                    selectedCourse,
                    setSelectedCourse,
                  }}
                />
              ) : (
                <OldAssignment
                  {...{
                    courses,
                    setLoading,
                    selectedCourse,
                    setSelectedCourse,
                    setNewAssignment,
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default HomeworkAssignments;
