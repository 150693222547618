/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import CheckboxAtom from '../../../components/atoms/checkbox/index';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons, NavigateRoutes } from '../../../constant';
import { useStyles } from './style';
import CourseManagerTable from './course-manager-table/index';
import { DialogAtom } from '../../../components/atoms';
import AddCourse from './Add/index';
import {
  getAllCoursesforManager, createCourse, editCourse,
} from '../../../store/actions/getCourseManager';
import { GetAllCourses, GetOnlyActiveCourses } from './helper';
import CourseExportDialogue from './export';
import useDataTableParams from '../../../custom-hooks/useDataTableParams';

export default function CourseManager() {
  const {
    nextPage,
  } = useDataTableParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const csvLink = useRef();
  const [isDeactivateChecked, setIsDeactivateChecked] = useState(false);
  const [showAddCourseDialog, setShowAddCourseDialog] = useState(false);
  const [showEditCourseDialog, setShowEditCourseDialog] = useState(false);
  const [showExportDialog, setExportDialog] = useState(false);
  const courseManager = useSelector((state) => state.getCourseManager);
  const [coursesData, setCoursesData] = useState([]);
  const [baseCourse, setBaseCourse] = useState(false);
  const [status, setStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const [courseExistErrorMessage, setCourseExistErrorMessage] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [createdByUserId, setCreatedByUserId] = useState('');
  const navigate = useNavigate();
  const reduxTotalCount = useSelector((state) => state?.getCourseManager?.TotalCoursesManager);
  const [pagenum, setPagenum] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [newTotal, setNewTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    setCreatedBy(localStorage.username);
    setCreatedByUserId(localStorage.userId);
  }, [localStorage]);

  useEffect(() => {
  }, [createdBy, createdByUserId]);

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    dispatch(getAllCoursesforManager(pagenum, 20, nextPage));
  }, []);

  const handleInactive = () => {
    setIsDeactivateChecked(!isDeactivateChecked);
  };

  useEffect(() => {
    if (isDeactivateChecked) {
      setNewTotal(courseManager?.coursesData.length);
      const courses = new GetAllCourses(courseManager?.coursesData);
      setCoursesData(courses);
    } else {
      const onlyActive = courseManager?.coursesData.filter((active) => active.isActive === true);
      setNewTotal(onlyActive.length);
      const courses = new GetOnlyActiveCourses(courseManager?.coursesData);
      setCoursesData(courses);
    }
  }, [isDeactivateChecked, courseManager?.coursesData]);

  const refreshAfterCreated = () => {
    setPagenum(1);
    dispatch(getAllCoursesforManager(1, 20, nextPage));
  };

  const courseExistError = (message) => {
    setCourseExistErrorMessage(message);
  };

  const handleCreateCourse = (values) => {
    const payload = {
      name: values.courseName,
      fee: values.fee,
      registrationFee: values.registrationFee,
      examFee: values.examFee,
      repeatingDiscount: values.repeatingDiscount,
      siblingDiscount: values.siblingDiscount,
      isActive: status,
      baseCourse,
      minimumAge: 5,
      maximumAge: 5,
      nextCourses: values.nextPossibleCourse,
    };
    dispatch(createCourse(payload, refreshAfterCreated, courseExistError, setShowAddCourseDialog, setSuccessMessage, setBaseCourse, setStatus));
    setIsDeactivateChecked(isDeactivateChecked);
  };

  const handleEditCourse = (values) => {
    const payload = {
      name: values.courseName,
      fee: values.fee,
      registrationFee: values.registrationFee,
      examFee: values.examFee,
      repeatingDiscount: values.repeatingDiscount,
      siblingDiscount: values.siblingDiscount,
      isActive: status,
      baseCourse,
      minimumAge: 5,
      maximumAge: 5,
      nextCourses: values.nextPossibleCourse,
      courseId: values.courseId,
      logMessage: values.changeLogs,
      // eslint-disable-next-line object-shorthand
      createdBy: createdBy,
      // eslint-disable-next-line object-shorthand
      createdByUserId: createdByUserId,
    };
    dispatch(editCourse(payload, refreshAfterCreated, courseExistError, setShowEditCourseDialog, setBaseCourse, setStatus));
  };

  const addSchema = Yup.object({
    courseName: Yup.string(t('COURSE_NAME_REQUIRED'))
      .required(t('COURSE_NAME_REQUIRED'))
      .typeError(t('COURSE_NAME_REQUIRED')),
    fee: Yup.string(t('INVALID_NUMBER'))
      .matches(/^\d*\.?\d*$/, t('FEE_REQUIRED'))
      .required(t('FEE_REQUIRED'))
      .typeError(t('INVALID_NUMBER')),
    examFee: Yup.string(t('EXAM_FEE_REQUIRED'))
      .matches(/^\d*\.?\d*$/, t('EXAM_FEE_REQUIRED'))
      .required(t('EXAM_FEE_REQUIRED'))
      .typeError(t('INVALID_NUMBER')),
    registrationFee: Yup.string(t('REGISTRATION_FEE_REQUIRED'))
      .matches(/^\d*\.?\d*$/, t('REGISTRATION_FEE_REQUIRED'))
      .required(t('REGISTRATION_FEE_REQUIRED'))
      .typeError(t('INVALID_NUMBER')),
    repeatingDiscount: Yup.string(t('REPEATING_DISCOUNT_REQUIRED'))
      .matches(/^\d*\.?\d*$/, t('REPEATING_DISCOUNT_REQUIRED'))
      .required(t('REPEATING_DISCOUNT_REQUIRED'))
      .typeError(t('INVALID_NUMBER')),
    siblingDiscount: Yup.string(t('SIBLING_DISCOUNT_REQUIRED'))
      .matches(/^\d*\.?\d*$/, t('SIBLING_DISCOUNT_REQUIRED'))
      .required(t('SIBLING_DISCOUNT_REQUIRED'))
      .typeError(t('INVALID_NUMBER')),
  });

  const editSchema = Yup.object({
    changeLogs: Yup.string(t('CHANGE_LOG_REQUIRED')).required(t('CHANGE_LOG_REQUIRED'))
      .typeError(t('CHANGE_LOG_REQUIRED')),
  });

  const formik = useFormik({
    initialValues: {
      courseName: '',
      fee: 0.00,
      examFee: 0.00,
      registrationFee: 0.00,
      repeatingDiscount: 0.00,
      siblingDiscount: 0.00,
      nextPossibleCourse: [],
      status: false,
      baseCourse: false,
      changeLogs: '',
    },
    validationSchema: isEdit ? addSchema.concat(editSchema) : addSchema,
    onSubmit: (values) => {
      if (isEdit) {
        handleEditCourse(values);
      } else {
        handleCreateCourse(values);
      }
    },
  });

  useEffect(() => {
    const row = selectedRow?.[0];
    formik.values.courseName = row?.courseName;
    formik.values.fee = row?.fee ? parseFloat(row?.fee, 10).toFixed(2) : 0.00;
    formik.values.examFee = row?.examFee ? parseFloat(row?.examFee, 10).toFixed(2) : 0.00;
    formik.values.registrationFee = row?.registrationFee ? parseFloat(row?.registrationFee, 10).toFixed(2) : 0.00;
    formik.values.repeatingDiscount = row?.repeatingDiscount ? parseFloat(row?.repeatingDiscount, 10).toFixed(2) : 0.00;
    formik.values.siblingDiscount = row?.siblingDiscount ? parseFloat(row?.siblingDiscount, 10).toFixed(2) : 0.00;
    formik.values.status = row?.status === 'Active';
    formik.values.baseCourse = row?.baseCourse === 'Yes';
    formik.values.courseId = row?.courseId;
    formik.values.nextPossibleCourse = [];
    row?.fromCourse?.forEach((obj) => {
      formik.values.nextPossibleCourse.push(obj.toCourseId);
    });

    if (selectedRow.length > 0) {
      setIsEdit(true);
      setStatus(row?.status === 'Active');
      setBaseCourse(row?.baseCourse === 'Yes');
    }
  }, [selectedRow]);

  useEffect(() => {
    const hRows = courseManager?.coursesData.length;
    if (reduxTotalCount > 20) {
      setHasMore(true);
    }
    if (hRows === reduxTotalCount) {
      setHasMore(false);
    }
  }, [reduxTotalCount, hasMore, courseManager?.coursesData]);

  const getErrorText = (key, errorText) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={classes.errorText}>
      {formik.errors[key]}
      {' '}
    </span>
  ) : errorText ? (<span className={classes.errorText}>{errorText}</span>) : null);

  const viewLogs = () => {
    navigate(NavigateRoutes.COURSE_MANAGER_LOGS, {
      state: {
        id: selectedRow[0]?.courseId,
        lastRoute: NavigateRoutes.COURSE_MANAGER_VIEW,
      },
    });
  };
  const loadMoreItems = (length) => {
    let page = 0;
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    setTotalRows(totalRows + 20);
    dispatch(getAllCoursesforManager(page, 20, nextPage));
  };

  const renderSuccessDialog = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('ADD_COURSE_SUCCESS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setSuccessMessage(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid>
      <Grid className={classes.roleRoot}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              {t('COURSE_MANAGER')}
            </Typography>
          </Grid>
          <Grid className={classes.dFlex}>
            <div className={classes.marginTop}>
              <ButtonAtom
                className={classes.addbutton}
                name={t('EXPORT_TO_CSV')}
                btntype={Buttons.PRIMARY}
                type={Buttons.PRIMARY}
                onClick={() => setExportDialog(true)}
              />
              <ButtonAtom
                className={classes.addbutton}
                name={t('ADD')}
                btntype={Buttons.PRIMARY}
                type={Buttons.PRIMARY}
                onClick={() => {
                  setSelectedRow([]);
                  setIsEdit(false);
                  setShowAddCourseDialog(!showAddCourseDialog);
                  setCourseExistErrorMessage('');
                  formik.setTouched({}, false);
                }}
                icon={<AddIcon />}
              />
            </div>
          </Grid>
        </Grid>
        <div className={classes.deactiveCheckbox}>
          <CheckboxAtom
            label={t('VIEW_INACTIVE')}
            id="acceptCheckbox"
            checked={isDeactivateChecked}
            customClasses={classes.viewInactive}
            handleChange={() => handleInactive()}
          />
        </div>
        <Grid container className={classes.roleManagerContainer}>
          <Grid container className={classes.roleManagerList}>
            <Grid className={classes.tableView}>
              <CourseManagerTable
                tableData={coursesData}
                setSelectedRow={setSelectedRow}
                setShowEditCourseDialog={setShowEditCourseDialog}
                setCourseExistErrorMessage={setCourseExistErrorMessage}
                formik={formik}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogAtom
        isOpen={showAddCourseDialog}
        dialogHeading={t('ADD_COURSE')}
        customClass={classes.addCourseDialogAtom}
        content={(
          <AddCourse
            source="createCourse"
            formik={formik}
            setShowAddCourseDialog={setShowAddCourseDialog}
            showAddCourseDialog={showAddCourseDialog}
            getErrorText={getErrorText}
            baseCourse={baseCourse}
            setBaseCourse={setBaseCourse}
            status={status}
            setStatus={setStatus}
            coursesData={coursesData}
            courseExistErrorMessage={courseExistErrorMessage}
          />
        )}
        secHandle={() => {
          setShowAddCourseDialog(false);
          setStatus(false);
          setBaseCourse(false);
        }}
      />
      <DialogAtom
        isOpen={showEditCourseDialog}
        dialogHeading={t('EDIT_COURSE')}
        customClass={classes.addCourseDialogAtom}
        content={(
          <AddCourse
            source="createCourse"
            formik={formik}
            setShowAddCourseDialog={setShowEditCourseDialog}
            showAddCourseDialog={showEditCourseDialog}
            getErrorText={getErrorText}
            baseCourse={baseCourse}
            setBaseCourse={setBaseCourse}
            status={status}
            setStatus={setStatus}
            coursesData={coursesData}
            courseExistErrorMessage={courseExistErrorMessage}
            isEdit
            viewLogs={viewLogs}
          />
        )}
        secHandle={() => setShowEditCourseDialog(false)}
      />
      <CourseExportDialogue
        show={showExportDialog}
        setShow={() => setExportDialog(false)}
        data={coursesData}
      />
      {(reduxTotalCount > 0)
      && (
      <Grid container justifyContent="flex-end">
        <Grid className={classes.loadMoreItems}>
          <Grid xs={12} className={classes.totalRowCount}>
            <span>
              {t('TOTAL_COURSES')}
              {' '}
              {newTotal}
              {' '}
              of
              {' '}
              {reduxTotalCount}
            </span>
            <br />
            <br />
            {(reduxTotalCount !== totalRows)
          && (
          <Grid justifyContent="flex-end" className={classes.loadMore}>
            {hasMore && (
              <ButtonAtom
                btntype={Buttons.PRIMARY}
                onClick={() => loadMoreItems(coursesData?.length)}
                name={t('LOAD_MORE')}
              />
            )}
          </Grid>
          )}
          </Grid>
        </Grid>
      </Grid>
      )}
      {
        successMessage && (
          <DialogAtom
            isOpen={successMessage}
            dialogHeading={t('ADD_COURSE_SUCCESS_TITLE')}
            customClass={classes.DialogAtom}
            content={renderSuccessDialog()}
            secHandle={() => setSuccessMessage(false)}
          />
        )
      }
    </Grid>
  );
}
