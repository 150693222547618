import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FormikProvider } from 'formik';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import logsIcon from '../../../../assets/images/logs-icon.png';
import { AntSwitch } from '../../../../utils/commonUiComponent';
import style from './style';
import { PerformantTextField, AddressAutoComplete, Loader } from '../../../../components/atoms';
import { getPlaceInformationWithCity } from '../../../../utils/mapMethod';

function AddCreateExamCenter({
  formik, setShowAddExamCenterDialog, showAddExamCenterDialog, getErrorText, setLocationAddressInfo, viewLogs, loading, nameExistErrorMessage,
}) {
  const { t } = useTranslation();
  const classes = style();
  const locationAddressBlur = (e) => {
    formik.handleBlur(e);
    formik.setTouched({ ...formik.touched, address: true, changeLog: true }, true);
  };
  useEffect(() => formik.setErrors({}), []);
  return (
    <Box className={classes.gridContainer}>
      <FormikProvider value={formik}>
        <form
          name="createExamCenter"
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={3} justifyContent="flex-start">
            <Grid item xs={6}>
              <PerformantTextField
                label={t('EXAM_CENTER_NAME')}
                id="name"
                required
                name="name"
                type="text"
                disabled={false}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={getErrorText('examCenterName')}
                onChange={formik.handleChange}
                labelId={t('EXAM_CENTER_NAME')}
                allowSpecialCharacter
              />
              {nameExistErrorMessage !== '' ? <span className={classes.errorText}>{nameExistErrorMessage}</span> : null}
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.switchHeading}>{t('STATUS')}</Typography>
              <Stack className={classes.switchUi} spacing={1}>
                <Typography className={formik.values.isActive ? classes.switchText : classes.switchTextRed}>
                  {t('INACTIVE_STATUS')}
                </Typography>
                <AntSwitch
                  checked={formik.values.isActive}
                  id="isActive"
                  name="isActive"
                  onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                  inputProps={{ 'aria-label': 'ant design' }}
                />
                <Typography className={formik.values.isActive ? classes.switchTextGreen : classes.switchText}>{t('ACTIVE_STATUS')}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.addressGrid}>
              <AddressAutoComplete
                label={`${t('EXAM_CENTER_ADDRESS')}`}
                id="address"
                required
                name="address"
                value={formik.values.address}
                error={getErrorText('address')}
                onPlaceSelected={async (e) => {
                  const res = await getPlaceInformationWithCity(e.formatted_address);
                  formik.setFieldValue(
                    'address',
                    e.formatted_address,
                    true,
                  );
                  setLocationAddressInfo({ ...res });
                }}
                onChange={(e) => {
                  formik.setFieldValue('address', e.target.value);
                }}
                onBlur={locationAddressBlur}
                customClass="addressStyle"
                shrink
                showLabel
              />
              <span className={classes.errorText}>{getErrorText('address')}</span>

            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <PerformantTextField
                label={t('APT_SUITE')}
                id="aptSuite"
                name="aptSuite"
                type="text"
                disabled={false}
                value={formik.values.aptSuite}
                onBlur={formik.handleBlur}
                error={getErrorText('aptSuite')}
                onChange={formik.handleChange}
                labelId={t('APT_SUITE')}
                allowSpecialCharacter
              />
            </Grid>
            {
              showAddExamCenterDialog
              && (
                <>
                  <Grid item xs={12}>
                    <PerformantTextField
                      label={t('CHANGE_LOG')}
                      id="changeLog"
                      required
                      name="changeLog"
                      type="text"
                      disabled={false}
                      value={formik.values.changeLog}
                      onBlur={formik.handleBlur}
                      error={getErrorText('changeLog')}
                      onChange={formik.handleChange}
                      labelId={t('CHANGE_LOG')}
                      allowSpecialCharacter
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <div
                      className={classes.logsIconSize}
                      onClick={() => viewLogs()}
                    >
                      <img src={logsIcon} width="20" alt="logsIcon" />
                      <span>{t('VIEW_LOGS')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.btnAlign}>
                    <ButtonAtom
                      name={t('CANCEL')}
                      onClick={() => setShowAddExamCenterDialog(!showAddExamCenterDialog)}
                      btntype={Buttons.SECONDARY}
                      className={classes.inViewLogButton}
                    />
                    <ButtonAtom
                      name={t('SAVE')}
                      onClick={formik.handleSubmit}
                      btntype={Buttons.PRIMARY}
                      className={classes.inViewLogButton}
                    />
                  </Grid>
                </>
              )
            }
            {
              !showAddExamCenterDialog
              && (
                <>
                  <Grid item xs={6} />
                  <Grid item xs={6} className={classes.btnAlign}>
                    <ButtonAtom
                      name={t('CANCEL')}
                      onClick={() => setShowAddExamCenterDialog(showAddExamCenterDialog)}
                      btntype={Buttons.SECONDARY}
                      className={classes.inViewLogButton}
                    />
                    <ButtonAtom
                      name={t('SAVE')}
                      type="submit"
                      onClick={formik.handleSubmit}
                      btntype={Buttons.PRIMARY}
                      className={classes.inViewLogButton}
                    />
                  </Grid>
                </>
              )
            }
          </Grid>
        </form>
      </FormikProvider>
      {
        loading && (
          <Grid>
            <Loader message={t('LOADING')} />
          </Grid>
        )
      }
    </Box>
  );
}
export default AddCreateExamCenter;
