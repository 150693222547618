import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatBarArray } from './formatData';
import { colors } from '../../../../theme';
import useStyles from '../../../../custom-hooks/useStyles';
import style from './style';
import useAdmin from '../../../../custom-hooks/useAdmin';

export default function EnrollmentBreakDownByWeek(props) {
  const { data, label } = props;
  const classes = useStyles(style)();
  const newData = data?.length && formatBarArray(data, 'name');
  const resultData = newData && newData?.length > 0 ? newData[0] : [];
  const series = resultData?.data?.map((i) => {
    return {
      ...i,
      name: i?.name === 'isNew' ? 'New Students' : 'Returning Students',
    };
  });
  const defaultOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
    },
    legend: {
      itemStyle: {
        color: '#000000 !important',
        fontSize: '14px !important',
        fontWeight: '400 !important',
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      },
    },
    title: {
      align: 'left',
      text: '',
      style: {
        padding: 0,
        fontWeight: 600,
        color: colors.primaryBlueColor,
        paddingBottom: 20,
      },
    },
    xAxis: {
      labels: {
        padding: 0,
        style: {
          fontSize: '8px',
          fontWeight: 'bold',
        },
      },
      categories: resultData?.categories,
      crosshair: true,
    },
    yAxis: {
      labels: {
        format: '{value}',
      },
      min: 0,
      title: {
        text: ' ',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>'
          + '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    colors: [
      colors.mustardShade,
      colors.reddishMagenta,
      colors.rum,
      colors.highLand,
      colors.violet,
      colors.persimmon,
      colors.rosePink,
      colors.primary,
      colors.secondary,
    ],
    plotOptions: {
      series: {
        pointWidth: 10,
      },
      column: {
        pointPadding: 0.2,
        size: '95%',
        borderWidth: 0,
        events: {
          legendItemClick() {
            return false;
          },
        },
      },
      allowPointSelect: false,
    },
    series,
  };
  const chartRef = useRef(null);
  const adminData = useAdmin();
  useEffect(() => {
    if (!adminData?.filterOpened) {
      return;
    }
    setTimeout(() => {
      if (chartRef?.current?.chart) {
        chartRef.current.chart.reflow();
      }
    }, 300);
  }, [adminData?.filterOpened]);
  const chartOptions = { ...defaultOptions, ...props };
  return (
    <div className={classes.highchartContainer}>
      <div className={`${classes.heading} ${classes.enrollmentbreakdownHeading}`}>
        {label}
      </div>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
    </div>
  );
}
