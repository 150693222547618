import { fonts, colors } from '../../../../theme';

const styles = (theme) => ({
  popUpCont: {
    width: '40vw',
    height: '60vh',
    position: 'relative',
    '@media (max-width: 600px)': {
      width: '80vw',
    },
  },
  attachementIcon: {
    marginTop: '-40px',
    marginLeft: '195px',
    '@media (max-height: 850px)': {
      marginTop: '-30px',
    },
    '@media (max-height: 780px)': {
      marginTop: '-40px',
    },
    '@media (max-width: 1400px)': {
      marginTop: '-60px',
    },
    '@media (max-width: 1700px) and (max-height: 780px) ': {
      marginTop: '-30px',
    },
    '@media (max-width: 1200px) and (max-height: 780px) ': {
      marginTop: '-30px',
    },
  },

  attachementIconThird: {
    marginTop: '0px',
    marginLeft: '-10px',
    '@media (max-height: 850px)': {
      marginTop: '5px',
      marginLeft: '-10px',
    },
    '@media (max-height: 780px)': {
      marginTop: '-30px',
      marginLeft: '100px',
    },
    '@media (max-width: 1400px)': {
      marginTop: '-10px',
      marginLeft: '100px',
    },
    '@media (max-width: 1700px) and (max-height: 780px) ': {
      marginTop: '-25px',
      marginLeft: '100px',
    },
    '@media (max-width: 1200px) and (max-height: 780px) ': {
      marginTop: '-10px',
      marginLeft: '100px',
    },
  },

  attachementIconSecondary: {
    '@media (max-height: 850px)': {
      marginTop: '10px',
    },
  },
  divButtons: {
    width: '14vw !important',
    display: 'flex !important',
    '@media (max-width: 600px)': {
      width: 'auto !important',
    },
  },
  pText: {
    marginLeft: '5px !important',
    marginTop: '10px !important',
    fontSize: '8px',
  },

  icon: {
    color: colors.primary,
  },

  attachementFormCont: {
    marginBottom: '20px',
  },
  popUpHeader: {
    height: '3vh',
    display: 'flex',
    padding: '.75vw 1vw .75vw 2vw',
    marginBottom: 8,
    fontFamily: fonts.fontType.roboto,
    backgroundColor: colors.secondary,
    '@media (min-width: 1200px)': {
      fontSize: '0.5vw',
    },
    fontWeight: 800,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },

  resourceItem: {
    width: '30vw',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },

  flexSeprator: {
    marginRight: '1vw',
  },

  resourceName: {
    fontSize: '0.95vw',
    marginBottom: 5,
  },

  otherDetails: {
    fontSize: '0.8vw',
    marginBottom: 5,
  },

  addBtnCont: {
    textAlign: 'right',
    right: 20,
    paddingTop: 20,
  },

  addBtn: {
    fontSize: '1vw',
  },

  resourceList: {
    height: '32vh',
    padding: '10px !important',
    'overflow-y': 'scroll',
  },
  ChipsList: {
    overflow: 'auto',
    maxHeight: '100px',
    '@media (max-height: 850px)': {
      marginTop: '15px',
    },
  },
  customCheckBox: {
    fontSize: 20,
    margin: 0,
    background: 'url(../../../../assets/images/check-icon.png) 0 0 no-repeat',
    '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
      fontSize: '1vw !important',
      marginTop: '0.3vw !important',
      color: colors.textPrimary,
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
      },
    },
    '& .MuiSvgIcon-root': {
      width: '1.5vw',
      height: '1.5vw',
      [theme.breakpoints.down('lg')]: {
        width: 14,
        height: 14,
      },
    },
    '& .MuiCheckbox-root': {
      '&.Mui-checked': {
        position: 'relative',
        '&::after': {
          position: 'absolute',
          top: '0.06vw',
          content: '""',
          border: `solid 2px ${colors.primary}`,
          zIndex: 99999,
          width: '0.9vw',
          height: '0.9vw',
          borderRadius: 3,
          background: colors.white,
          [theme.breakpoints.down('lg')]: {
            top: 8,
            left: 8,
            width: 12,
            height: 12,
          },
        },
        '&::before': {
          position: 'absolute',
          top: '0.15vw',
          left: '0.2vw',
          content: '""',
          width: '0.8vw',
          height: '0.3vw',
          color: colors.primary,
          borderBottom: `solid 2px ${colors.primary}`,
          borderLeft: `solid 2px ${colors.primary}`,
          transform: 'rotate(-45deg)',
          zIndex: 111111,
          background: colors.white,
          boxShadow: `3px 4px 2px ${colors.white}`,
          [theme.breakpoints.down('lg')]: {
            top: 10,
            left: 14,
            color: '#104F96',
            width: 9,
            height: 4,
          },
        },
      },
      '&.Mui-checked.Mui-disabled': {
        position: 'relative',
        '&::after': {
          position: 'absolute',
          top: '0.06vw',
          content: '""',
          border: `solid 2px ${colors.primary}`,
          zIndex: 99999,
          width: '0.9vw',
          height: '0.9vw',
          borderRadius: 3,
          background: colors.backgroundGrey,
          [theme.breakpoints.down('lg')]: {
            top: 8,
            left: 8,
            width: 12,
            height: 12,
          },
        },
        '&::before': {
          position: 'absolute',
          top: '0.15vw',
          left: '0.2vw',
          content: '""',
          width: '0.8vw',
          height: '0.3vw',
          color: colors.primary,
          borderBottom: `solid 2px ${colors.primary}`,
          borderLeft: `solid 2px ${colors.primary}`,
          transform: 'rotate(-45deg)',
          zIndex: 111111,
          background: colors.backgroundGrey,
          boxShadow: `3px 4px 2px ${colors.white}`,
          [theme.breakpoints.down('lg')]: {
            top: 10,
            left: 14,
            color: '#104F96',
            width: 9,
            height: 4,
          },
        },
      },
    },
    '& .MuiTypography-root': {
      marginLeft: 0,
    },
  },
  divWrapper: {
    width: '100% !important',
  },
  HeaderWrapper: {
    boxShadow: 'none',
    left: '0',
    top: '0',
    padding: '0 1vw',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  courseData: {
    padding: '1.5vw 2vw 1vw 0vw',
  },
  slash: {
    marginRight: '1vw',
  },
  toggleButtonGrp: {
    border: '0.1vw solid #ccc',
    borderRadius: '0.4vw',
    maxHeight: '2.5vw',
    paddingRight: 1,
    [theme.breakpoints.down('md')]: {
      maxHeight: 'inherit',
    },
  },
  fileViewer: {
    '& .pg-viewer-wrapper': {
      overflowY: 'hidden',
    },
  },
  dataGrid: {
    '.MuiDataGrid-root': {
      outline: 'none !important',
      border: 'none !important',
    },
    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
      width: '0 !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid #025EE6 !important',
      borderTop: 'none',
      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
      fontStyle: 'bold',
      fontSize: '1vw',
      lineHeight: '131.19%',
      color: '#025EE6',
      minHeight: '3vw !important',
      maxHeight: '3vw !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      '@media (min-width: 1200px)': {
        marginTop: '3vw !important',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '1vw',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell': {
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
      outlineOffset: 0,
    },
    '& .actionCell': {
      paddingLeft: '2vw',
    },
  },
  toggleButtonList: {
    paddingLeft: 52,
    borderRadius: '0.8vw 0vw 0vw 0.8vw',
    border: '0 !important',
    '&.MuiButtonBase-root.MuiToggleButton-root': {
      width: '4vw !important',
      lineHeight: 'normal',
      padding: '0.9vw 0.5vw 0.5vw 0.5vw',
      [theme.breakpoints.down('md')]: {
        width: '35px !important',
        height: '35px !important',
        padding: '0 5px !important',
      },
      '&:hover': {
        background: colors.toggleButtonBG,
        borderRadius: '0.4vw 1vw 1vw 0.4vw',
        '& svg': {
          color: colors.white,
        },
      },
      '&.Mui-selected': {
        background: colors.toggleButtonBG,
        borderRadius: '0.4vw 1vw 1vw 0.4vw',
        '& svg': {
          color: colors.white,
        },
      },
    },
  },
  '.MuiSvgIcon-root': {
    width: '1.5vw',
    height: '1.5vw',
  },
  toggleButtonGrid: {
    borderRadius: '0.8vw 0vw 0vw 0.8vw',
    border: '0 !important',
    '&.MuiButtonBase-root.MuiToggleButton-root': {
      lineHeight: 'normal',
      width: '4vw !important',
      padding: '0.5vw !important',
      [theme.breakpoints.down('md')]: {
        width: '35px !important',
        height: '35px !important',
        padding: '0 5px !important',
      },
      '&:hover': {
        background: colors.toggleButtonBG,
        borderRadius: '1vw 0.4vw 0.4vw 1vw',
        '& svg': {
          color: colors.white,
        },
      },
      '&.Mui-selected': {
        background: colors.toggleButtonBG,
        borderRadius: '1vw 0.4vw 0.4vw 1vw',
        '& svg': {
          color: colors.white,
        },
      },
    },
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    fontSize: '1.5vw',
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  titleRow: {
    justifyContent: 'space-between',
    padding: '0 1vw',
  },
  addbutton: {
    minWidth: '7.5vw  !important',
  },
  gridPadding: {
    padding: '24px',
    minHeight: 'calc(67vh)',
    '@media (max-width: 600px)': {
      padding: '14px',
    },
  },
  divstule: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  studentcourse: {
    '@media (min-width: 1200px)': {
      display: 'contents',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '0.7vw 1.5vw !important',
      '@media (min-width: 1200px)': {
        width: '9vw',
      },
      '@media (max-width: 1200px)': {
        width: '100px',
      },
    },
  },
  popupTextFieldFile: {
    '& .MuiOutlinedInput-root': {
      height: '2.4vw !important',
      background: '#fff',
      borderRadius: '0.4vw',
      color: colors.placeHolderColor,
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& input': {
        '@media (min-width: 1200px) and (max-width: 1599px)': {
          padding: '0.7vw',
          fontSize: '0.9vw !important',
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.black}!important`,
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px !important',
        },
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '0.9vw !important',
      color: `${colors.primary} !important`,
      [theme.breakpoints.down('md')]: {
        fontSize: '0.9vw !important',
      },
    },
  },
  popupTextFieldFolder: {
    '& .Mui-error': {
      color: colors.errorText,
      fontFamily: `${fonts.fontType.roboto} !important`,
      letterSpacing: 'inherit',
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
      },
      marginLeft: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      '@media (min-width: 1200px)': {
        color: 'inherit !important',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.5vw)',
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '0.9vw',
        backgroundColor: colors.white,
        color: colors.placeHolderColor,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, -0.5vw)',
        fontSize: '0.7vw',
        backgroundImage: 'linear-gradient(to bottom, #fff, #E4E4E4)',
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: colors.primary,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.7vw)',
        fontSize: '0.9vw',
        backgroundColor: colors.backgroundGrey,
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '0.7vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: colors.primary,
      },
    },
    '& .MuiOutlinedInput-root': {
      height: '2.4vw !important',
      '& .MuiInputLabel-root': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw !important',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
        color: `${colors.placeHolderColor} !important`,
      },
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${colors.placeHolderColor} !important`,
      },
      '& input': {
        color: colors.black,
        '@media (min-width: 1200px)': {
          paddingLeft: '1vw',
          paddingRight: '1vw',
          fontSize: '0.9vw',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
        '&.Mui-disabled': {
          '@media (min-width: 1200px)': {
            padding: '0.65vw',
          },
          backgroundColor: colors.backgroundGrey,
          color: colors.primary,
          WebkitTextFillColor: colors.primary,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& .MuiFormHelperText-root': {
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
        marginLeft: 0,
        fontSize: 'calc(12px + 6 * ((100vw - 320px) / 1199))',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
    },
    '& fieldset': {
      '& legend span': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  popupTextFieldMap: {
    margin: '15px 0 !important',
    width: '100% !important',
  },
  popupButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '2vw',
    '& .MuiButtonBase-root.MuiButton-root': {
      '@media (min-width: 1200px)': {
        height: '3vw !important',
        minWidth: '9vw !important',
      },
      marginRight: '15px',
      borderRadius: '4px',
    },
  },
  popupSelectDriveButton: {
    display: 'flex',
    justifyContent: 'end',
    margin: '-37px 0 20px',
    '@media (min-width: 1650px)': {
      margin: '-22px 0 20px',
      marginBottom: '1vw',
    },
    '@media (min-width: 1350px)': {
      margin: '-12px 0',
      marginBottom: '1vw',
    },
    '& .MuiButtonBase-root.MuiButton-root': {
      '@media (min-width: 1200px)': {
        height: '3vw !important',
        minWidth: '9vw !important',
      },
      marginRight: '15px',
      borderRadius: '4px',
    },
  },
  uploadBtn: {
    marginleft: '1vw',
    borderRadius: '0.5vw',
  },
  deactiveCheckbox: {
    '& .MuiFormControl-root': {
      margin: '1vw',
      minWidth: '3vw',
      '& .MuiFormControlLabel-labelPlacementEnd': {
        marginBottom: '-3vw',
      },
    },
  },
  dropdownWrap: {
    display: 'flex',
    width: '100%',
    '& .MuiFormControl-root': {
      width: 'unset !important',
    },
    '& .MuiInputBase-root.MuiInput-root:before': {
      borderBottom: `0.15vw solid ${colors.primary}`,
    },
    '& svg:first-child': {
      margin: '0 0 -7px 0',
      color: colors.primary,
    },
    '& .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '0',
    },
  },
  uploadVideoBtn: {
    color: 'black',
    background: 'transparent',
    boxShadow: 'unset',
    borderRadius: 'unset !important',
    padding: 'unset',
    fontSize: '0.9vw',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'unset',
    },
  },
  dialogfooter: {
    paddingRight: '1.8vw',
    margin: '-1vw 0 0 0',
    color: `${colors.black} !important`,
    fontSize: 'calc(8px + 6 * ((100vw - 320px) / 1199)) !important',
  },
  alertprimary: {
    height: '2vw',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    color: colors.green,
  },
  alertFetching: {
    height: '2vw',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    color: colors.green,
  },
  errorPrimary: {
    height: '2vw',
    '@media (max-width: 567px)': {
      height: '45px',
    },
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    color: colors.red,
  },
  errorText: {
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    marginLeft: '0.6vw',
  },
  DialogAtom: {
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
    },
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        minWidth: '38vw !important',
        maxWidth: '50vw !important',
        minHeight: '24.5vw !important',
        maxHeight: '24vw !important',
      },
      overflowX: 'hidden !important',
      paddingBottom: '0',
      '@media (min-width: 1200px) and (max-width: 1560px)': {
        minHeight: '17vw !important',
        maxHeight: '30vw !important',
      },
      '@media (max-width: 991px)': {
      },
      '@media (min-width: 1200px) and (max-width: 1650px)': {
        minHeight: '15.5vw !important',
        maxHeight: '23.5vw !important',
      },
      '@media (min-width: 1200px) and (max-width: 1350px)': {
        minHeight: '28.5vw !important',
        minWidth: '45vw !important',
      },
    },
    '& .MuiDialogContent-root .MuiGrid-root': {
      '@media (min-width: 1200px)': {
        maxWidth: '65vw !important',
      },
    },
    '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
      fontSize: '0.9vw',
      color: colors.placeHolderColor,
      marginLeft: '0vw !important',
      fontFamily: fonts.fontType.roboto,
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
      '@media (max-width: 1199px)': {
        fontSize: 'calc(10px + 6 * ((100vw - 320px) / 1199))',
      },
    },
    '& .MuiFormControl-root': {
      margin: '0 !important',
    },
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '-40px',
    height: '3vw',
    '@media (max-width: 1199px)': {
      paddingTop: '20px',
    },
    '& .MuiFormControlLabel-label': {
      color: `${colors.black} !important`,
      fontSize: 'calc(8px + 6 * ((100vw - 320px) / 1199)) !important',
    },
    '& .MuiTypography-root': {
      margin: '1vw 0vw 0.9vw 0vw',
    },
  },
  itemLabels: {
    paddingLeft: '5px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.9vw',
    '@media (max-width: 600px)': {
      fontSize: '3vw !important',
    },
  },
  addButton: {
    fontSize: '12px',
    padding: 0,
    minWidth: '2vw !important',
    height: '1.8vw !important',
    '@media (max-width: 1199px)': {
      minWidth: '50px !important',
      minHeight: '20px',
    },
  },
  Menubox: {
    '& .MuiList-root.MuiMenu-list': {
      paddingTop: 'unset',
      paddingBottom: 'unset',
      '& .MuiMenuItem-gutters': {
        display: 'flex',
        '& .MuiSvgIcon-fontSizeMedium': {
          margin: '0.2VW 0.3VW',
        },
      },
    },
    '& ul': {
      '& svg': {
        '@media (min-width: 1200px)': {
          width: '1.2vw',
          height: '1.2vw',
        },
      },
      '& label:first-child': {
        '& svg': {
          transform: 'rotate(90deg)',
        },
      },
    },
  },
  popupBtnDisable: {
    opacity: '.6',
    '& button': {
      cursor: 'not-allowed',
    },
  },
  helpSite: {
    width: '100%',
  },
  noData: {
    textAlign: 'center',
    marginTop: '3vw',
  },
});

export default styles;
