/* eslint-disable */
import React from 'react';
import { Card} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import BannerSpotRegistration from '../../../assets/images/BannerSpotRegistration.png';
const HeaderWithIcons = () => {
  const classes = useStyles(styles)();
  return (
    <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
        <img
            className={classes.customBanner}
            component="img"
            src={BannerSpotRegistration}
            alt="bannerImage"
          />
        </CardContent>
    </Card>
  );
}

export default HeaderWithIcons;
