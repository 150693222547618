import { colors, fonts } from '../../../../theme';

const addRegionStyle = ((theme) => ({
  viewLogs: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
      position: 'absolute',
      bottom: 25,
    },
    fontFamily: fonts.fontType.roboto,
    fontWeight: fonts.fontWeight.medium,
    display: 'flex',
    color: colors.actionIconsColor,
    paddingLeft: '3.5vw',
    cursor: 'pointer',
    paddingTop: '1vw',
    '& div': {
      marginTop: '0.2vw',
      marginLeft: '0.2vw',
    },
  },
  coor: {
    width: '100%',
  },
  switchText: {
    fontSize: '0.9vw !important',
    textAlign: 'center',
    color: colors.blackShade,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px !important',
    },
  },
  inactiveText: {
    color: colors.redShade,
  },
  activeText: {
    color: colors.greenShade,
  },
  switchHeading: {
    fontSize: '1vw !important',
    fontWeight: fonts.fontWeight.bold,
    textAlign: 'center',
    paddingBottom: 5,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px !important',
    },
  },
  switchUi: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    '& p': {
      margin: '0 8px !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px !important',
      },
    },
    '& span': {
      marginTop: '0 !important',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px !important',
      },
      marginLeft: '0 !important',
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked+.MuiSwitch-track': {
        background: colors.greenShade,
      },
    },
    '& .MuiSwitch-thumb': {
      width: 10,
      height: 10,
    },
  },
  title: {
    color: colors.primary,
    padding: '.75vw 1vw .75vw 2vw',
    marginBottom: 8,
    fontFamily: fonts.fontType.roboto,
    backgroundColor: colors.secondary,
    '@media (min-width: 1200px)': {
      fontSize: '1vw',
    },
    fontWeight: 600,
  },
  errorText: {
    color: colors.errorText,
    fontSize: '0.7vw',
    display: 'block',
    marginTop: '0.3vw',
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
  },
  alignGrid: {
    padding: 0,
    position: 'relative',
    '& #extraCurricularActivities': {
      color: `${colors.placeHolderColor} !important`,
      padding: '0.4vw',
      fontSize: '0.9vw',
      transform: 'translate(0.3vw, 0.4vw) scale(1)',
      fontFamily: fonts.fontType.roboto,
      letterSpacing: '0.1vw',
    },
    '&.MuiGrid-root.MuiGrid-item': {
      paddingTop: '13px',
    },
    '& #addressAutoComplete': {
      width: '100%',
      marginTop: '9px',
    },
    '& .PhoneInput': {
      width: '97%',
    },
    '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
      paddingRight: 0,
    },
    '& .MuiInput-input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
    },
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      '& .MuiFormControl-root': {
        margin: 'auto',
        width: '98%',
      },
      '& div': {
        margin: 0,
      },
    },
    '& .MuiFormControl-root': {
      margin: '0.5vw 0',
      '& .MuiFormControl-root': {
        margin: 0,
        width: '100% !important',
      },
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      marginTop: 0,
      borderRadius: '0.4vw',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        height: '2.7vw',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '1vw',
      [theme.breakpoints.down('lg')]: {
        padding: '7px 14px',
        fontSize: 14,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw',
      transform: 'translate(0.3vw, 0.1vw) scale(1)',
      cursor: 'pointer',
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        transform: 'translate(14px, 3px) scale(1)',
      },
      [theme.breakpoints.down('md')]: {
        transform: 'translate(14px, 5px) scale(1)',
      },
    },
  },
  alignGridMobile: {
    padding: 0,
    position: 'relative',
    '& #extraCurricularActivities': {
      color: `${colors.placeHolderColor} !important`,
      padding: '0.4vw',
      fontSize: '0.9vw',
      transform: 'translate(0.3vw, 0.4vw) scale(1)',
      fontFamily: fonts.fontType.roboto,
      letterSpacing: '0.1vw',
    },
    '&.MuiGrid-root.MuiGrid-item': {
      paddingTop: '13px',
    },
    '& #addressAutoComplete': {
      width: '100%',
      marginTop: '9px',
    },
    '& .PhoneInput': {
      width: '97%',
    },
    '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
      paddingRight: 0,
    },
    '& .MuiInput-input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
    },
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      '& .MuiFormControl-root': {
        margin: 'auto',
        width: '98%',
      },
      '& div': {
        margin: 0,
      },
    },
    '& .MuiFormControl-root': {
      margin: '0.5vw 0',
      '& .MuiFormControl-root': {
        margin: 0,
        width: '100% !important',
      },
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      marginTop: 0,
      borderRadius: '0.4vw',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        height: '2.7vw',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '1vw',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        height: '40px !important',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '7px 14px',
        fontSize: 14,
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 14px',
        fontSize: 14,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw',
      cursor: 'pointer',
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
      },
    },
  },
  alignGridCurses: {
    padding: 0,
    position: 'relative',
    '& #extraCurricularActivities': {
      color: `${colors.placeHolderColor} !important`,
      padding: '0.4vw',
      fontSize: '0.9vw',
      transform: 'translate(0.3vw, 0.4vw) scale(1)',
      fontFamily: fonts.fontType.roboto,
      letterSpacing: '0.1vw',
    },
    '&.MuiGrid-root.MuiGrid-item': {
      paddingTop: '13px',
    },
    '& #addressAutoComplete': {
      width: '100%',
      marginTop: '9px',
    },
    '& .PhoneInput': {
      width: '97%',
    },
    '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
      paddingRight: 0,
    },
    '& .MuiInput-input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
    },
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      '& .MuiFormControl-root': {
        margin: 'auto',
        width: '98%',
      },
      '& div': {
        margin: 0,
      },
    },
    '& .MuiFormControl-root': {
      margin: '0.5vw 0',
      '& .MuiFormControl-root': {
        margin: 0,
        width: '100% !important',
      },
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      marginTop: 0,
      borderRadius: '0.4vw',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        height: '2.7vw',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '1vw',
      [theme.breakpoints.down('lg')]: {
        padding: '7px 14px',
        fontSize: 14,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw',
      cursor: 'pointer',
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        marginTop: '-3px',
      },
      '@media (max-width: 600px)': {
        marginTop: '0px',
      },
    },
  },
  alignGridRegionName: {
    padding: 0,
    position: 'relative',
    '& #extraCurricularActivities': {
      color: `${colors.placeHolderColor} !important`,
      padding: '0.4vw',
      fontSize: '0.9vw',
      transform: 'translate(0.3vw, 0.4vw) scale(1)',
      fontFamily: fonts.fontType.roboto,
      letterSpacing: '0.1vw',
    },
    '&.MuiGrid-root.MuiGrid-item': {
      paddingTop: '13px',
    },
    '& #addressAutoComplete': {
      width: '100%',
      marginTop: '9px',
    },
    '& .PhoneInput': {
      width: '97%',
    },
    '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
      paddingRight: 0,
    },
    '& .MuiInput-input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
    },
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      '& .MuiFormControl-root': {
        margin: 'auto',
        width: '98%',
      },
      '& div': {
        margin: 0,
      },
    },
    '& .MuiFormControl-root': {
      margin: '0.3vw 0',
      '& .MuiFormControl-root': {
        margin: 0,
        width: '100% !important',
      },
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      marginTop: 0,
      borderRadius: '0.4vw',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        height: '2.7vw',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '1vw',
      [theme.breakpoints.down('lg')]: {
        padding: '7px 14px',
        fontSize: 14,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw',
      cursor: 'pointer',
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        marginTop: '-2px',
      },
    },
  },
  alignGridRegionNameMobile: {
    padding: 0,
    position: 'relative',
    '& #extraCurricularActivities': {
      color: `${colors.placeHolderColor} !important`,
      padding: '0.4vw',
      fontSize: '0.9vw',
      transform: 'translate(0.3vw, 0.4vw) scale(1)',
      fontFamily: fonts.fontType.roboto,
      letterSpacing: '0.1vw',
    },
    '&.MuiGrid-root.MuiGrid-item': {
      paddingTop: '13px',
    },
    '& #addressAutoComplete': {
      width: '100%',
      marginTop: '9px',
    },
    '& .PhoneInput': {
      width: '97%',
    },
    '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
      paddingRight: 0,
    },
    '& .MuiInput-input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
    },
    '@media (max-width: 1023px), @media (min-width: 375px)': {
      '& .MuiFormControl-root': {
        margin: 'auto',
        width: '98%',
      },
      '& div': {
        margin: 0,
      },
    },
    '& .MuiFormControl-root': {
      margin: '0.5vw 0',
      '& .MuiFormControl-root': {
        margin: 0,
        width: '100% !important',
      },
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      marginTop: 0,
      borderRadius: '0.4vw',
      padding: '9.2px 12px 9.2px 12px !important',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        height: '2.7vw',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '1vw',
      [theme.breakpoints.down('lg')]: {
        padding: '7px 14px',
        fontSize: 14,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.9vw',
      cursor: 'pointer',
      [theme.breakpoints.down('lg')]: {
        fontSize: 16,
        marginTop: '-2px',
      },
    },
  },
  '.MuiDialogActions-root': {
    minWidth: '100px !important',
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-input': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      border: `${colors.blue}!important`,
      '&::placeholder': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontSize: '12px !important',
        color: `${colors.placeHolderColor}!important`,
        letterSpacing: 1,
      },
    },
  },
  maxWidthAssign: {
    maxWidth: '50% !important',
    '@media (max-width: 600px)': {
      maxWidth: '100% !important',
      paddingTop: '20px !important',
    },
  },
  coordinatorHeading: {
    color: colors.primary,
    fontSize: 11,
    padding: '0 5px',
    backgroundColor: colors.white,
    position: 'absolute',
    left: 20,
    top: '4px',
  },
  dropDownSelect: {
    '&::placeholder': {
      fontSize: '0.9vw',
    },
    '& label': {
      fontSize: '0.9vw',
      top: '-6px',
    },
    '& .MuiInputLabel-root': {
      color: colors.placeHolderColor,
      '&.MuiInputLabel-shrink': {
        top: '0 !important',
        color: colors.primary,
      },
    },
    width: '50%',
    margin: '0.3vw 0',
    '& img': {
      position: 'absolute',
      top: 9,
      left: 0,
      '& + .MuiInput-root': {
        '& .MuiSelect-select': {
          paddingLeft: 25,
          fontSize: '0.9vw',
        },
      },
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      borderRadius: '0.4vw',
      height: '2.7vw',
      fontSize: '0.9vw',
    },
  },
  dropDownSelectMobile: {
    '& .MuiInputLabel-root': {
      '&.MuiInputLabel-shrink': {
        top: '0 !important',
      },
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      '& .MuiSelect-select': {
        padding: '13px 12px 13px 12px !important',
      },
    },
  },
}));

export default addRegionStyle;
