/* eslint-disable */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
import React, { useState, useMemo } from 'react';
import { Grid, Box } from '@mui/material';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import DataGridProTable from '../../../components/atoms/data-grid-pro';
import { useGridApiRef as useApiRef } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '../../../components/atoms/dropdown';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import MapPin from '../../../assets/images/map-pin.png';
import Loader from '../../../components/atoms/loader';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import { useEffect } from 'react';

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      input: {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          display: 'none',
        },
      },
    },
  },
});

const useStylesLabel = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none !important',
    },
    '&&:after': {
      borderBottom: 'none !important',
    },
  },
});

export default function SyncFilters({
  assignedYears,
  isselectedYear,
  locationData,
  isSelectedlocation,
  courseData,
  isSelectedCourse,
  handleYearDropDownChange,
  handlelocationDropDownChange,
  handleClassWeekDropDownChange,
  loading,
  nameSheet,
  createAtSheet,
  sendSheet,
  showDataMarks,
  showNameSheet,
  importSheet,
  exportSheetTime,
  importSheetTime,
  systemBased,
  showS3,
  sBorderBottomQ1,
  sBorderBottomQ2,
  sBorderBottomQ3,
  sBorderBottomS1,
  sBorderBottomS2,
  sBorderBottomS3,
  handleChange,
  marksType,
  systemBasedType,
  sBorderBottomExamMarks,
  sBorderBottomHomeworkMarks,
  sBorderBottomAssignmentMarks,
  sBorderBottomCoCurricularMarks,
  sBorderBottomQuiz1Marks,
  sBorderBottomQuiz2Marks
}) {
  const { t } = useTranslation();
  const classes1 = useStyles(styles)();
  const classes = useStylesLabel();
  const apiRef = useApiRef();
  const [pageSize, setPageSize] = useState(25);
  const [dataMarks, setDataMarks] = useState({});
  const [columns, setColumns] = useState([]);
  const showS3Marks = false;

  const columnsA = [
    {
      field: 'studentName',
      headerName: 'Column A',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type="text"
            defaultValue={i.row.studentName}
            InputProps={{
              classes,
              style: { fontWeight: i.row.studentName === 'Student Name' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'totalMarks',
      headerName: 'Column B',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type="text"
            defaultValue={i.row.totalMarks}
            InputProps={{
              classes,
              style: { fontWeight: i.row.totalMarks === 'Total Marks' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'isAbsent',
      headerName: 'Column C',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <input
          type={i.row.isAbsent === 'Is Absent' ? 'text' : 'checkbox'}
          disabled={false}
          value={i.row.isAbsent}
          checked={i.row.isAbsent}
          className={classes1.checkAbsent}
          readOnly
        />
      ),
    },
    {
      field: 'mark3',
      headerName: 'Column D',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark3 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark3}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark3 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark4',
      headerName: 'Column E',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark4 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark4}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark4 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark5',
      headerName: 'Column F',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark5 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark5}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark5 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark6',
      headerName: 'Column G',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark6 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark6}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark6 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark7',
      headerName: 'Column H',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark7 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark7}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark7 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark8',
      headerName: 'Column I',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark8 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark8}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark8 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark9',
      headerName: 'Column J',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark9 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark9}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark9 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark10',
      headerName: 'Column K',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark10 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark10}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark10 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark11',
      headerName: 'Column L',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark11 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark11}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark11 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark12',
      headerName: 'Column M',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark12 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark12}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark12 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark13',
      headerName: 'Column N',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark13 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark13}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark13 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark14',
      headerName: 'Column O',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark14 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark14}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark14 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark15',
      headerName: 'Column P',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark15 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark15}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark15 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark16',
      headerName: 'Column Q',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark16 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark16}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark16 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark17',
      headerName: 'Column R',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark17 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark17}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark17 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark18',
      headerName: 'Column S',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark18 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark18}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark18 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark19',
      headerName: 'Column T',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark19 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark19}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark19 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark20',
      headerName: 'Column U',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark20 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark20}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark20 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark21',
      headerName: 'Column V',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark21 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark21}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark21 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark22',
      headerName: 'Column W',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark22 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark22}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark22 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark23',
      headerName: 'Column X',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark23 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark23}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark23 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark24',
      headerName: 'Column Y',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark24 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark24}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark24 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark25',
      headerName: 'Column Z',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark25 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark25}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark25 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark26',
      headerName: 'Column AA',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark26 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark26}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark26 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark27',
      headerName: 'Column AB',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark27 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark27}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark27 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark28',
      headerName: 'Column AC',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark28 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark28}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark28 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark29',
      headerName: 'Column AD',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark29 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark29}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark29 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark30',
      headerName: 'Column AE',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark30 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark30}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark30 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark31',
      headerName: 'Column AF',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark31 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark31}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark31 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark32',
      headerName: 'Column AG',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark32 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark32}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark32 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark33',
      headerName: 'Column AH',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark33 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark33}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark33 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark34',
      headerName: 'Column AI',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark34 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark34}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark34 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark35',
      headerName: 'Column AJ',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark35 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark35}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark35 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark36',
      headerName: 'Column AK',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark36 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark36}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark36 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark37',
      headerName: 'Column AL',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark37 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark37}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark37 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark38',
      headerName: 'Column AM',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark38 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark38}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark38 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark39',
      headerName: 'Column AN',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark39 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark39}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark39 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark40',
      headerName: 'Column AO',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark40 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark40}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark40 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark41',
      headerName: 'Column AP',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark41 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark41}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark41 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark42',
      headerName: 'Column AQ',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark42 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark42}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark42 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark43',
      headerName: 'Column AR',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark43 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark43}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark43 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark44',
      headerName: 'Column AS',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark44 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark44}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark44 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark45',
      headerName: 'Column AT',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark45 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark45}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark45 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark46',
      headerName: 'Column AU',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark46 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark46}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark46 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark47',
      headerName: 'Column AV',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark47 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark47}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark47 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark48',
      headerName: 'Column AW',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark48 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark48}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark48 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark49',
      headerName: 'Column AX',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark49 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark49}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark49 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark50',
      headerName: 'Column AY',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark50 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark50}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark50 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark51',
      headerName: 'Column AZ',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark51 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark51}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark51 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark52',
      headerName: 'Column BA',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark52 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark52}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark52 === 'string' ? 'bold' : 'normal' },
            }}
            disabled="false"
          />
        </MuiThemeProvider>
      ),
    },
  ];

  const columnsB = [
    {
      field: 'studentName',
      headerName: 'Column A',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type="text"
            defaultValue={i.row.studentName}
            InputProps={{
              classes,
              style: { fontWeight: i.row.studentName === 'Student Name' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'totalMarks',
      headerName: 'Column B',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type="text"
            defaultValue={i.row.totalMarks}
            InputProps={{
              classes,
              style: { fontWeight: i.row.totalMarks === 'Total Marks' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark2',
      headerName: 'Column C',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark2 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark2}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark2 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark3',
      headerName: 'Column D',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark3 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark3}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark3 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark4',
      headerName: 'Column E',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark4 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark4}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark4 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark5',
      headerName: 'Column F',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark5 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark5}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark5 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark6',
      headerName: 'Column G',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark6 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark6}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark6 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark7',
      headerName: 'Column H',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark7 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark7}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark7 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark8',
      headerName: 'Column I',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark8 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark8}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark8 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark9',
      headerName: 'Column J',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark9 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark9}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark9 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark10',
      headerName: 'Column K',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark10 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark10}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark10 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark11',
      headerName: 'Column L',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark11 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark11}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark11 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark12',
      headerName: 'Column M',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark12 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark12}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark12 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark13',
      headerName: 'Column N',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark13 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark13}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark13 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark14',
      headerName: 'Column O',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark14 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark14}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark14 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark15',
      headerName: 'Column P',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark15 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark15}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark15 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark16',
      headerName: 'Column Q',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark16 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark16}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark16 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark17',
      headerName: 'Column R',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark17 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark17}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark17 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark18',
      headerName: 'Column S',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark18 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark18}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark18 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark19',
      headerName: 'Column T',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark19 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark19}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark19 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark20',
      headerName: 'Column U',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark20 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark20}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark20 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark21',
      headerName: 'Column V',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark21 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark21}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark21 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark22',
      headerName: 'Column W',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark22 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark22}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark22 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark23',
      headerName: 'Column X',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark23 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark23}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark23 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark24',
      headerName: 'Column Y',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark24 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark24}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark24 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark25',
      headerName: 'Column Z',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark25 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark25}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark25 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark26',
      headerName: 'Column AA',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark26 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark26}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark26 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark27',
      headerName: 'Column AB',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark27 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark27}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark27 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark28',
      headerName: 'Column AC',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark28 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark28}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark28 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark29',
      headerName: 'Column AD',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark29 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark29}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark29 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark30',
      headerName: 'Column AE',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark30 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark30}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark30 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark31',
      headerName: 'Column AF',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark31 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark31}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark31 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark32',
      headerName: 'Column AG',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark32 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark32}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark32 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark33',
      headerName: 'Column AH',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark33 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark33}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark33 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark34',
      headerName: 'Column AI',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark34 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark34}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark34 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark35',
      headerName: 'Column AJ',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark35 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark35}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark35 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark36',
      headerName: 'Column AK',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark36 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark36}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark36 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark37',
      headerName: 'Column AL',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark37 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark37}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark37 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark38',
      headerName: 'Column AM',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark38 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark38}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark38 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark39',
      headerName: 'Column AN',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark39 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark39}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark39 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark40',
      headerName: 'Column AO',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark40 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark40}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark40 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark41',
      headerName: 'Column AP',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark41 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark41}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark41 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark42',
      headerName: 'Column AQ',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark42 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark42}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark42 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark43',
      headerName: 'Column AR',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark43 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark43}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark43 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark44',
      headerName: 'Column AS',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark44 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark44}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark44 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark45',
      headerName: 'Column AT',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark45 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark45}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark45 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark46',
      headerName: 'Column AU',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark46 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark46}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark46 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark47',
      headerName: 'Column AV',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark47 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark47}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark47 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark48',
      headerName: 'Column AW',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark48 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark48}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark48 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark49',
      headerName: 'Column AX',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark49 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark49}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark49 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark50',
      headerName: 'Column AY',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark50 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark50}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark50 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark51',
      headerName: 'Column AZ',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark51 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark51}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark51 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    },
    {
      field: 'mark52',
      headerName: 'Column BA',
      minWidth: 250,
      align: 'center',
      disableClickEventBubbling: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (i) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            type={typeof i.row.mark52 === 'string' ? 'text' : 'number'}
            defaultValue={i.row.mark52}
            InputProps={{
              classes,
              style: { fontWeight: typeof i.row.mark52 === 'string' ? 'bold' : 'normal' },
            }}
            disabled={false}
          />
        </MuiThemeProvider>
      ),
    }
  ];

  useEffect(() => {
    if (showDataMarks.length > 0) {
      setDataMarks(showDataMarks.slice(1));
      if (showDataMarks[0]) {
        let column = marksType === 'EXAM MARKS' ? columnsA : columnsB;
        let columns = [];
        for (const item of column) {
          const field = item.field;
          if (showDataMarks[0][field]) {
            item.headerName = showDataMarks[0][field];
            columns.push(item);
          }
        }
        setColumns(columns)
      }
    }
  }, [showDataMarks]);

  const displayExportText = useMemo(() => {
    if (exportSheetTime) {
      return `Last edit was ${exportSheetTime}`;
    } else {
      return 'Sheet has not been exported';
    }
  }, [exportSheetTime]);

  const displayImportText = useMemo(() => {
    if (importSheetTime) {
      return `Last edit was ${importSheetTime}`;
    } else {
      return 'Sheet has not been imported';
    }
  }, [importSheetTime]);

  const displayCreatedText = useMemo(() => {
    if (createAtSheet) {
      return `${createAtSheet}`;
    } else {
      return 'Sheet has not been imported';
    }
  }, [createAtSheet]);

  return (
    <>
      <Grid container spacing={2} direction="row" display="flex" alignItems="center" className={classes1.dropdowns}>
        <Grid item xs={3} md={3} lg={1.5} className={classes1.yearMarksSyncLocation}>
          <Dropdown
            minWidth="100%"
            id="year"
            name="year"
            variant="standard"
            label={t('YEAR')}
            options={assignedYears}
            value={isselectedYear}
            customClass={classes?.year}
            changeCss
            customFormControlCss={{ width: '100%' }}
            labelId={t('YEAR')}
            handleChange={handleYearDropDownChange}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={4} className={classes1.location}>
          <Dropdown
            id="locationId"
            name="locationId"
            variant="standard"
            maxWidth="50%"
            label={t('LOCATION')}
            labelId={t('LOCATION')}
            options={locationData}
            value={isSelectedlocation}
            customClass={classes?.year}
            handleChange={handlelocationDropDownChange}
            icon={<img src={MapPin} alt="" className={classes1.mapPinImg} />}
          />
        </Grid>
        <Grid item xs={3} md={2} lg={1.5} className={classes1.yearMarksSyncLocation}>
          <Dropdown
            id="classWeek"
            name="classWeek"
            variant="standard"
            minWidth="50%"
            label={t('CLASS_WEEK')}
            labelId={t('CLASS_WEEK')}
            options={courseData}
            value={isSelectedCourse}
            customClass={classes?.year}
            handleChange={handleClassWeekDropDownChange}
          />
        </Grid>
        <Grid item xs={0.5} md={1} lg={0.5} />
        <Grid item xs={4} md={2} lg={2}>
          <ButtonAtom
            btntype={Buttons.PRIMARY}
            className={classes1.secButton}
            name={t('EXPORT_TO_SHEET')}
            onClick={sendSheet}
          />
          <span className={classes1.agoText}>
            {displayExportText}
          </span>
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <ButtonAtom
            btntype={Buttons.PRIMARY}
            className={classes1.secButton}
            name={t('IMPORT_FROM_SHEET')}
            onClick={importSheet}
          />
          <span className={classes1.agoText}>
            {displayImportText}
          </span>
        </Grid>
      </Grid>
      <Grid style={{ paddingTop: '2rem' }}>
        <Grid item xs={12} md={12} className={classes1.year}>
          <Box className={classes1.direction}>
            <Grid mt={1} xs={12}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  { systemBased === 'Semester' ?
                    <div>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 'none',
                          borderTop: 'none',
                          borderRight: 'none',
                          paddingBottom: '10px',
                          borderBottom: `${sBorderBottomS1}px solid`,
                          width: '150px',
                          color: '#1976d2',
                        }}
                        onClick={() => handleChange('S1', marksType)}
                      >
                        MARKS S1
                      </Button>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 'none',
                          borderTop: 'none',
                          borderRight: 'none',
                          paddingBottom: '10px',
                          borderBottom: `${sBorderBottomS2}px solid`,
                          width: '150px',
                          color: '#1976d2',
                        }}
                        onClick={() => handleChange('S2', marksType)}
                      >
                        MARKS S2
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            borderLeft: 'none',
                            borderTop: 'none',
                            borderRight: 'none',
                            paddingBottom: '10px',
                            borderBottom: `${sBorderBottomS3}px solid`,
                            width: '150px',
                            color: '#1976d2',
                          }}
                          onClick={() => handleChange('S3', 'EXAM MARKS')}
                        >
                          PSTU
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            borderLeft: 'none',
                            borderTop: 'none',
                            borderRight: 'none',
                            paddingBottom: '10px',
                            borderBottom: `${sBorderBottomS3}px solid`,
                            width: '150px',
                            color: '#1976d2',
                          }}
                          onClick={() => handleChange('S3', 'EXAM MARKS')}
                        >
                          Supplementary
                        </Button>
                      : <div></div>
                      }
                    </div>
                  :
                    <div>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 'none',
                          borderTop: 'none',
                          borderRight: 'none',
                          paddingBottom: '10px',
                          borderBottom: `${sBorderBottomQ1}px solid`,
                          width: '150px',
                          color: '#1976d2',
                        }}
                        onClick={() => handleChange('Q1', marksType)}
                      >
                        MARKS Q1
                      </Button>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 'none',
                          borderTop: 'none',
                          borderRight: 'none',
                          paddingBottom: '10px',
                          borderBottom: `${sBorderBottomQ2}px solid`,
                          width: '150px',
                          color: '#1976d2',
                        }}
                        onClick={() => handleChange('Q2', marksType)}
                      >
                        MARKS Q2
                      </Button>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                          borderLeft: 'none',
                          borderTop: 'none',
                          borderRight: 'none',
                          paddingBottom: '10px',
                          borderBottom: `${sBorderBottomQ3}px solid`,
                          width: '150px',
                          color: '#1976d2',
                        }}
                        onClick={() => handleChange('Q3', marksType)}
                      >
                        MARKS Q3
                      </Button>
                    </div>
                  }
                </ButtonGroup>
                <Divider />
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  { systemBased === 'Semester' ?
                    <div>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 'none',
                          borderTop: 'none',
                          borderRight: 'none',
                          paddingBottom: '10px',
                          paddingTop: '10px',
                          borderBottom: `${sBorderBottomExamMarks}px solid`,
                          width: '200px',
                          color: '#1976d2',
                        }}
                        onClick={() => handleChange(systemBasedType, 'EXAM MARKS')}
                      >
                        EXAM MARKS
                      </Button>
                      { systemBasedType !== 'S3' && (
                        <>
                          <Button
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderLeft: 'none',
                              borderTop: 'none',
                              borderRight: 'none',
                              paddingBottom: '10px',
                              paddingTop: '10px',
                              borderBottom: `${sBorderBottomHomeworkMarks}px solid`,
                              width: '200px',
                              color: '#1976d2',
                            }}
                            onClick={() => handleChange(systemBasedType, 'HOMEWORK MARKS')}
                          >
                            HOMEWORK MARKS
                          </Button>
                          <Button
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: 0,
                              borderLeft: 'none',
                              borderTop: 'none',
                              borderRight: 'none',
                              paddingBottom: '10px',
                              paddingTop: '10px',
                              borderBottom: `${sBorderBottomAssignmentMarks}px solid`,
                              width: '200px',
                              color: '#1976d2',
                            }}
                            onClick={() => handleChange(systemBasedType, 'ASSIGNMENT MARKS')}
                          >
                            ASSIGNMENT MARKS
                          </Button>
                          <Button
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: 0,
                              borderLeft: 'none',
                              borderTop: 'none',
                              borderRight: 'none',
                              paddingBottom: '10px',
                              paddingTop: '10px',
                              borderBottom: `${sBorderBottomQuiz1Marks}px solid`,
                              width: '200px',
                              color: '#1976d2',
                            }}
                            onClick={() => handleChange(systemBasedType, 'QUIZ 1 MARKS')}
                          >
                            {systemBasedType === 'S1' ? 'QUIZ 1 MARKS' : isselectedYear === '2023-2024' ? 'QUIZ 3 MARKS' : 'QUIZ 2 MARKS'}
                          </Button>
                          {isselectedYear === '2023-2024' && (
                            <Button
                              style={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                                borderLeft: 'none',
                                borderTop: 'none',
                                borderRight: 'none',
                                paddingBottom: '10px',
                                paddingTop: '10px',
                                borderBottom: `${sBorderBottomQuiz2Marks}px solid`,
                                width: '200px',
                                color: '#1976d2',
                              }}
                              onClick={() => handleChange(systemBasedType, 'QUIZ 2 MARKS')}
                            >
                            {systemBasedType === 'S1' ? 'QUIZ 2 MARKS' : 'QUIZ 4 MARKS'}
                            </Button>
                          )}
                          <Button
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderLeft: 'none',
                              borderTop: 'none',
                              borderRight: 'none',
                              paddingBottom: '10px',
                              paddingTop: '10px',
                              borderBottom: `${sBorderBottomCoCurricularMarks}px solid`,
                              width: '250px',
                              color: '#1976d2',
                            }}
                            onClick={() => handleChange(systemBasedType, 'CO-CURRICULAR MARKS')}
                          >
                            CO-CURRICULAR MARKS
                          </Button>
                        </>
                    )}
                    </div>
                    :
                    <div>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 'none',
                          borderTop: 'none',
                          borderRight: 'none',
                          paddingBottom: '10px',
                          paddingTop: '10px',
                          borderBottom: `${sBorderBottomExamMarks}px solid`,
                          width: '200px',
                          color: '#1976d2',
                        }}
                        onClick={() => handleChange(systemBasedType, 'EXAM MARKS')}
                      >
                        EXAM MARKS
                      </Button>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 'none',
                          borderTop: 'none',
                          borderRight: 'none',
                          paddingBottom: '10px',
                          paddingTop: '10px',
                          borderBottom: `${sBorderBottomHomeworkMarks}px solid`,
                          width: '200px',
                          color: '#1976d2',
                        }}
                        onClick={() => handleChange(systemBasedType, 'HOMEWORK MARKS')}
                      >
                        HOMEWORK MARKS
                      </Button>
                    </div>
                  }
                </ButtonGroup>
                <Divider />
              </Box>
            </Grid>
            {loading ? (
              <Grid>
                <Loader message={t('LOADING')} />
              </Grid>
            ) : (
              <>
                <Grid mb={1}>
                  <Grid className={classes1.filterCSV} mb={3}>
                    <ul>
                      <li>
                        {t('SPREADSHEET')}
                        :
                        {' '}
                        <span>{nameSheet}</span>
                      </li>
                      <li>
                        {t('SHEET')}
                        :
                        {' '}
                        <span>{showNameSheet}</span>
                      </li>
                      <li>
                        {t('CREATED_ON')}
                        :
                        {' '}
                        <span>
                          {displayCreatedText}
                        </span>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
                {showDataMarks.length === 0 && (
                  <div style={{ textAlign: 'center' }}>
                    {t('MARK_SHEET_EMPTY')}
                  </div>
                )}
                {showDataMarks.length !== 0 && (
                  <div className={classes1.manageBottomScroll}>
                  <DataGridProTable
                    data={dataMarks}
                    columns={columns}
                    autoHeight
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    disableSelectionOnClick
                    ColumnUnsorted
                    ColumnSortedAscending
                    ColumnSortedDescending
                    apiRef={apiRef}
                    pageSize={pageSize}
                    pagination={true}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 75, 100]}
                  />
                </div>
                )}
                {(systemBasedType === 'Q1' && marksType === 'EXAM MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Homework_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'Q2' && marksType === 'EXAM MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Homework_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'Q3' && marksType === 'EXAM MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Homework_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'Q1' && marksType === 'HOMEWORK MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Homework_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'Q2' && marksType === 'HOMEWORK MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Homework_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'Q3' && marksType === 'HOMEWORK MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_Q2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: '1rem',
                        }}
                      >
                        Marks_Q3_Homework_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S1' && marksType === 'EXAM MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S2' && marksType === 'EXAM MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S3' && marksType === 'EXAM MARKS' && showS3Marks) && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'white',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'white',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S1' && marksType === 'HOMEWORK MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S2' && marksType === 'HOMEWORK MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S1' && marksType === 'ASSIGNMENT MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S2' && marksType === 'ASSIGNMENT MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S1' && marksType === 'QUIZ 1 MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S2' && marksType === 'QUIZ 1 MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S1' && marksType === 'QUIZ 2 MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'white',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S2' && marksType === 'QUIZ 2 MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'white',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S1' && marksType === 'CO-CURRICULAR MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {(systemBasedType === 'S2' && marksType === 'CO-CURRICULAR MARKS') && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 80,
                      marginTop: '2rem',
                      backgroundColor: 'silver',
                      overflowX: 'scroll !important'
                    }}
                  >
                    <ButtonGroup variant="contained" className={classes1.responsive} aria-label="outlined primary button group" style={{ boxShadow: 'none', paddingTop: '1px' }}>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" size="large" style={{ height: '60px' }}>
                        <MenuIcon fontSize="inherit" />
                      </IconButton>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Exam_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Exam_Marks
                      </Button>
                      { showS3 ==='PSTU' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          PSTU_Exam_Marks
                        </Button>
                      : showS3 === 'Supplementary' && showS3Marks ?
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '1rem',
                          }}
                        >
                          Supplementary_Exam_Marks
                        </Button>
                      : <div></div>
                      }
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Homework_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Assignment_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Quiz1_Marks
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S1_Quiz2_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        {isselectedYear === '2023-2024' ? 'Marks_S2_Quiz3_Marks' : 'Marks_S2_Quiz2_Marks'}
                      </Button>
                      {isselectedYear === '2023-2024' && (
                        <Button
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            height: '58px',
                            backgroundColor: 'silver',
                            color: 'black',
                            border: 'none',
                            padding: '1rem',
                          }}
                        >
                          Marks_S2_Quiz4_Marks
                        </Button>
                      )}
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'silver',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S1_Co-Curricular_Marks
                      </Button>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        style={{
                          height: '58px',
                          backgroundColor: 'white',
                          color: 'black',
                          border: 'none',
                          padding: '1rem',
                        }}
                      >
                        Marks_S2_Co-Curricular_Marks
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
