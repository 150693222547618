import React, { useCallback } from 'react';
import {
  Grid, IconButton,
  MenuItem,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import {
  useNavigate,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  impersonateUser,
} from '../../../../store/actions/getAdmin';
import MailIcon from '../../../../assets/svg/mailIcon';
import UserIcon from '../../../../assets/svg/userIcon';
import MenuWrapper from './menuWrapper';
import Constant from '../../../../store/constant';
import { NavigateRoutes } from '../../../../constant';
import { getLocalStorage, setLocalStorage } from '../../../../utils/localStorageMethod';
import userRoles from '../../../../constant/userRoles';

import { colors } from '../../../../theme';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';

const useStyles = makeStyles(() => ({
  menuItemIcon: {
    '@media (min-width: 1200px)': {
      width: '1.2vw',
      marginRight: '0.3vw',
    },
    verticalAlign: 'middle',
    marginRight: '0.3vw',
    overflow: 'auto',
  },
  studentCardMenuIcon: {
    color: `${colors.primary} !important`,
    '&.MuiButtonBase-root.MuiIconButton-root': {
      '@media (min-width: 1200px)': {
        padding: '0.1vw 0.8vw !important',
        fontSize: '2vw',
      },
      '@media (max-width: 1199px)': {
        padding: '0 10px',
      },
      display: 'block',
    },
  },
}));

function MenuComponent({
  menuViewType,
  t,
  rowInfo,
  onUpdateParentClick,
  recoveryDialogOpen,
  paymentDialogOpen,
  setSwapCourseOrLocation,
  setEditStudentProfile,
  showStudentLogs,
  onClickMenu,
  onClickCancelEnrollMenu,
  onClickEditExamDetails,
  onEditMenuClick,
  selectedFilter,
  MarksDetailsOpen,
  setOpen,
  filterOptions,
  onClickRepeatStatus,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const isAdmin = userRole === userRoles.SYSTEM_ADMIN;
  const isRC = userRole === userRoles.REGION_COORDINATOR;
  const isSuperUser = userRole === userRoles.SUPER_USER;
  localStorage.removeItem('sendSingleEmail');
  localStorage.removeItem('moduleReturn');

  const impersonateStudentUser = useCallback(
    async (row) => {
      dispatch(impersonateUser({
        email: row?.studentInfo?.manabadiEmail,
      }));
    },
    [],
  );

  const handleImpersonate = async () => {
    await impersonateStudentUser(rowInfo).finally(() => {
      localStorage.setItem('impersonateUser', JSON.stringify({ state: { showView: true, email: rowInfo?.studentInfo?.manabadiEmail } }));
      localStorage.setItem('isAdminUser', isAdmin);
      dispatch({
        type: Constant.SET_VIEW,
        payload: 'cardView',
      });
      navigate(NavigateRoutes.STUDENT_DASHBOARD, { state: { showView: true, email: rowInfo?.studentInfo?.manabadiEmail, filterOptions } });
    });
  };
  return (
    <Grid>
      <MenuWrapper menuViewType={menuViewType} setOpen={setOpen} row={rowInfo}>
        {isAdmin || isSuperUser ? (
          <MenuItem
            onClick={() => paymentDialogOpen(rowInfo)}
            className={classes.menuItem}
          >
            <MonetizationOnOutlinedIcon className={classes.menuItemIcon} />
            {t('SHOW_PAYMENT_INFO')}
          </MenuItem>
        ) : ''}
        {isAdmin || isSuperUser ? (
          <>
            <MenuItem
              onClick={() => recoveryDialogOpen(rowInfo)}
              className={classes.menuItem}
            >
              <LockOpenOutlinedIcon className={classes.menuItemIcon} />
              {t('PASSWORD_RECOVERY')}
            </MenuItem>
            <MenuItem onClick={() => onUpdateParentClick(rowInfo)} className={classes.menuItem}>
              <EditOutlinedIcon className={classes.menuItemIcon} />
              {t('EDIT_PARENTINFO')}
            </MenuItem>
          </>
        ) : ''}

        {isRC ? (
          <MenuItem
            onClick={() => recoveryDialogOpen(rowInfo)}
            className={classes.menuItem}
          >
            <LockOpenOutlinedIcon className={classes.menuItemIcon} />
            {t('PASSWORD_RECOVERY')}
          </MenuItem>
        ) : ''}

        {isAdmin || isSuperUser ? (
          <MenuItem
            onClick={() => {
              onEditMenuClick(rowInfo);
              setEditStudentProfile(true);
            }}
            className={classes.menuItem}
          >
            <EditOutlinedIcon className={classes.menuItemIcon} />
            {t('EDIT_STUDENT_PROFILE')}
          </MenuItem>
        ) : ''}

        <MenuItem
          onClick={() => {
            onClickMenu(rowInfo);
            setSwapCourseOrLocation(true);
          }}
          className={classes.menuItem}
        >
          <SwapHorizOutlinedIcon className={classes.menuItemIcon} />
          {t('SWAP_COURSE_LOCATION')}
        </MenuItem>

        {isAdmin || isSuperUser ? (
          <MenuItem
            onClick={() => showStudentLogs(rowInfo)}
            className={classes.menuItem}
          >
            <TextSnippetOutlinedIcon className={classes.menuItemIcon} />
            {t('SHOW_STUDENT_LOGS')}
          </MenuItem>
        ) : ''}
        {isAdmin || isSuperUser ? (
          <MenuItem
            onClick={() => onClickCancelEnrollMenu(rowInfo)}
            className={classes.menuItem}
          >
            <TextSnippetOutlinedIcon className={classes.menuItemIcon} />
            {t('CANCEL_ENROLLMENT')}
          </MenuItem>
        ) : ''}

        {isAdmin || isSuperUser ? (
          <MenuItem
            onClick={() => onClickEditExamDetails(rowInfo)}
            className={classes.menuItem}
          >
            <EditOutlinedIcon className={classes.menuItemIcon} />
            {t('EDIT_EXAM_DETAILS')}
          </MenuItem>
        ) : ''}

        {menuViewType !== 'grid' && (isAdmin || isSuperUser) ? (
          <MenuItem
            onClick={() => MarksDetailsOpen(rowInfo)}
            className={classes.menuItem}
          >
            <BookmarksOutlinedIcon className={classes.menuItemIcon} />
            {t('MARKS_DETAILS')}
          </MenuItem>
        ) : ''}
        {((isAdmin || isSuperUser) && (
          (rowInfo?.enrolled_courses?.result === 'PASS')
          || (rowInfo?.enrolled_courses?.result === 'ATTENDED')
          || (rowInfo?.enrolled_courses?.result === 'REPEAT'))) ? (
            <MenuItem
              onClick={() => {
                onClickMenu(rowInfo);
                onClickRepeatStatus();
              }}
              className={classes.menuItem}
            >
              <EditOutlinedIcon className={classes.menuItemIcon} />
              {t('MARK_REPEAT')}
            </MenuItem>
          ) : ''}

      </MenuWrapper>
      {menuViewType === 'grid' && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {(isAdmin || isRC || isSuperUser) && (
            <Tooltip title={t('SEND_EMAIL')} placement="right">
              <IconButton
                onClick={() => {
                  const emailStudents = [];
                  const emailParents = [];
                  emailParents.push(rowInfo.parent1Info?.personalEmail?.toString());
                  emailParents.push(rowInfo.parent2Info?.personalEmail?.toString());
                  emailStudents.push(rowInfo.studentInfo?.manabadiEmail?.toString());
                  const filtersList = [];
                  selectedFilter?.forEach((obj) => {
                    filtersList.push(obj);
                  });
                  setLocalStorage('showSelectAllinEmail', false);
                  setLocalStorage('sendSingleEmail', true);
                  setLocalStorage('moduleReturn', 'student');
                  dispatch({ type: Constant.RECIPIENTS, payload: emailStudents });
                  dispatch({ type: Constant.STUDENT_FILTERS, payload: [] });
                  dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
                  dispatch({ type: Constant.MAIL_PARENTS, payload: emailParents });
                  navigate(NavigateRoutes.ADMIN_EMAIL);
                }}
                aria-label="mail"
                className={classes.studentCardMenuIcon}
              >
                <MailIcon />
              </IconButton>
            </Tooltip>
          )}
          {(isAdmin || isSuperUser) && (
            <Tooltip title={t('IMPERSONATE')} placement="right">
              <IconButton
                aria-label="impersonate"
                className={classes.studentCardMenuIcon}
                onClick={() => handleImpersonate()}
              >
                <UserIcon />
              </IconButton>
            </Tooltip>
          )}

        </>
      )}
    </Grid>

  );
}
function areEqual(prevProps, nextProps) {
  /*
      return true if passing nextProps to render would return
      the same result as passing prevProps to render,
      otherwise return false
      */
  return prevProps.rowInfo === nextProps.rowInfo;
}
export default React.memo(MenuComponent, areEqual);
