import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import styles from '../style';
import useStyles from '../../../../custom-hooks/useStyles';
import GoogleCalendarChart from './googleCalendarChart';

function EnrollmentBreakdownAcrossYear({ data, selectedFilter }) {
  const enrollGraphOption = selectedFilter?.find((i) => i.filterKey === 'academic_year')?.label;
  const yearOption = enrollGraphOption?.split('-');
  const currentYear = yearOption?.length && yearOption[1];
  const newYearOptions = [];
  for (let i = 0; i < 4; i++) {
    newYearOptions.push(Number(currentYear) - i);
  }
  const chartData = data?.length && data?.filter(
    (obj) => Number(obj.year) === Number(newYearOptions[0])
      || Number(newYearOptions[1])
      || Number(newYearOptions[2])
      || Number(newYearOptions[3]),
  );
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  return (
    <Grid container>
      <div className={classes.heading}>
        {t('ENROLLMENT_BREAKDOWN1')}
      </div>
      <Grid container>
        <Grid item xs={12} lg={12} md={12} id="google-calendar" className={classes.calenderDiv}>
          <GoogleCalendarChart data={chartData} />
          <ReactTooltip />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EnrollmentBreakdownAcrossYear;
