import makeStyles from '@mui/styles/makeStyles';
import { fonts, colors } from '../../../theme';

export const useStyles = makeStyles((theme) => ({
  locationRoot: {
    marginInline: '2vw',
  },
  dialogButtons: {
    justifyContent: 'flex-end',
    padding: '0 10px',
    '& .MuiButton-root': {
      '@media (min-width: 1200px)': {
        minWidth: '10vw !important',
        height: '2.7vw !important',
        fontSize: '0.8vw',
      },
    },
  },
  popupButton: {
    display: 'flex',
    justifyContent: 'end',
    '& .MuiButtonBase-root.MuiButton-root': {
      '@media (min-width: 1200px)': {
        height: '3vw !important',
        minWidth: '9vw !important',
      },
      marginRight: '15px',
      borderRadius: '4px',
      marginTop: '20px',
    },
  },
  titleRow: {
    justifyContent: 'space-between',
    marginTop: '0.9vw',
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    fontSize: '1.5vw',
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  dFlex: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dFlexIcon: {
    display: 'flex',
    justifyContent: 'end',
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  addbutton: {
    minWidth: '7.5vw  !important',
  },
  addIconSize: {
    width: '2vw',
    height: '1.4vw',
    [theme.breakpoints.down('md')]: {
      width: 16,
      height: 16,
    },
  },
  deactiveCheckbox: {
    '@media (min-width: 1200px)': {
      height: '4vw',
    },
    '& .MuiFormControl-root:first-child': {
      margin: '1vw',
      minWidth: '3vw',
      marginRight: '-3.5vw',
      '& .MuiFormControlLabel-labelPlacementEnd': {
        marginBottom: '-3vw',
      },
    },
    textAlign: 'inherit',
  },
  locationManagerContainer: {
    color: colors.black,
    width: 'auto',
    fontFamily: fonts.fontType.roboto,
    marginTop: '0.5vw',
  },
  addCourseDialogAtom: {
    '& .MuiDialogContent-root .MuiGrid-root': {
      '@media (min-width: 1200px)': {
        minWidth: '0vw !important',
        width: '60vw !important',
      },
      position: 'relative',
    },
    '& .MuiButton-root.MuiButton-textPrimary': {
      '@media (min-width: 1200px)': {
        minWidth: '11vw !important',
        marginLeft: '1vw !important',
      },
    },
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.7vw',
      },
      borderRadius: '0.4vw',
      '&  .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
    },
    '& .MuiDialog-paper .MuiOutlinedInput-input': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        padding: '0.7vw',
      },
      '@media (max-width: 1200px)': {
        borderRadius: '5px',
        padding: '16.5px 14px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.primary,
      '@media (max-width: 599px)': {
        borderRadius: '5px',
      },
    },

    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  errorText: {
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
    '@media (max-width: 1200px)': {
      fontSize: '0.75rem',
    },
    color: colors.errorText,
  },
}));

export default {
  useStyles,
};
