import React from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Grid,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { useDispatch } from 'react-redux';
import style from './style';
import useStyles from '../../custom-hooks/useStyles';
import {
  setLocalStorage,
} from '../../utils/localStorageMethod';
import { NavigateRoutes } from '../../constant';
import ButtonAtom from '../../components/atoms/button';
import ManabadiHeader from '../common/view-header/manabadi-header/manabadi-header';
import useAdmin from '../../custom-hooks/useAdmin';
import encryptedUserRoles from '../../constant/encryptedUserRoles';
import Constant from '../../store/constant';

export default function TeacherUser() {
  const classes = useStyles(style)();
  const navigate = useNavigate();
  const { state } = useAdmin();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      {
        JSON.parse(localStorage.getItem('impersonateUser'))
        && (
          <Grid className={classes.headerViewingDiv}>
            <div className={classes.headerViewing}>
              <p>Viewing as</p>
              <p><span>{state?.email ? state?.email : JSON.parse(localStorage.getItem('impersonateUser')).state.email}</span></p>
              <ButtonAtom
                className={classes.exitBtn}
                onClick={() => {
                  setLocalStorage('userRole', encryptedUserRoles.SYSTEM_ADMIN);
                  setLocalStorage('username', state?.adminUserName ? state?.adminUserName : JSON.parse(localStorage.getItem('impersonateUser')).state.adminUserName);
                  setLocalStorage('profilePhoto', state?.profilePic ? state?.profilePic : JSON.parse(localStorage.getItem('impersonateUser')).state.profilePic);
                  localStorage.removeItem('impersonateToken');
                  localStorage.removeItem('tempUserId');
                  localStorage.removeItem('impersonateUser');
                  localStorage.removeItem('tempUserRole');
                  setLocalStorage('tempUserRole', encryptedUserRoles.SYSTEM_ADMIN);
                  dispatch({
                    type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.TOTAL_COUNT,
                    payload: 0,
                  });
                  dispatch({
                    type: Constant.GET_ALL_TEACHER_RECOURCES,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.GET_PAGINATED_TEACHER_RECOURCES,
                    payload: [],
                  });
                  dispatch({
                    type: Constant.TOTAL_COUNT,
                    payload: 0,
                  });
                  navigate(NavigateRoutes.USER_MANAGER_VIEW, { state: { previousFilters: state?.filterOptions } });
                }}
                name={t('EXIT')}
                icon={<ExitToApp />}
              />
            </div>
          </Grid>
        )
      }
      <Grid className={classes.divWrapper}>
        <ManabadiHeader />
      </Grid>
    </Stack>
  );
}
