import makeStyles from '@mui/styles/makeStyles';
import { colors, fonts } from '../../../theme';

const style = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& span': {
      '&:nth-child(2)': {
        position: 'relative',
        zIndex: 1,
        '& .MuiInputBase-root': {
          background: 'rgb(255 255 255 / 0%)',
        },
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        backgroundColor: colors.white,
        color: colors.placeHolderColor,
        transform: 'translate(0.7vw, 0.9vw)',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink': {
      color: `${colors.primary} !important`,
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, -0.5vw)',
        fontSize: '0.7vw',
        color: colors.primary,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
      '@media (min-width: 1200px)': {
        transform: 'translate(0.7vw, 0.7vw)',
        fontSize: '0.9vw',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      color: `${colors.primary} !important`,
      '@media (min-width: 1200px)': {
        transform: 'translate(0.9vw, -0.5vw)',
        fontSize: '0.7vw',
        color: colors.primary,
      },
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontFamily: fonts.fontType.roboto,
      color: `${colors.black} !important`,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        padding: '0.5vw !important',
        paddingRight: '20px !important',
        minHeight: '0.5em !important',
        fontFamily: `${fonts.fontType.roboto} !important`,
      },
    },
    '& .MuiOutlinedInput-root': {
      background: 'white',
      '@media (min-width: 1200px)': {
        height: '2.9vw !important',
        lineHeight: '1vw',
        borderRadius: '-0.6vw',
      },
      outline: 'none',
      width: '100%',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.primary}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.15vw solid ${colors.primary}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.15vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.errorText} !important`,
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        '@media (min-width: 1200px)': {
          fontSize: '0.9vw',
          width: '1vw',
          height: '1vw',
          right: '0.5vw',
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
      },
    },
    '& input': {
      padding: '0.7vw 1vw 0.7vw 0.7vw',
      boxSizing: 'border-box',
      fontSize: '0.9vw',
      fontFamily: `${fonts.fontType.roboto} !important`,
      color: '#696969 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.9vw',
      },
      '&::placeholder': {
        textTransform: 'uppercase',
        color: `${colors.placeHolderColor} !important`,
        opacity: 1,
        padding: '0.7vw 1vw 0.7vw 0.7vw',
        fontFamily: `${fonts.fontType.roboto} !important`,
        fontWeight: '300',
      },
    },
  },
  menuItem: {
    borderBottom: '0.1vw solid #eee !important',
    padding: '0.5vw !important',
    maxWidth: 500,
    minWidth: '100%',
    whiteSpace: 'break-spaces',
    justifyContent: 'start !important',
    display: 'block !important',
    fontFamily: fonts.fontType.roboto,
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    '@media (max-width: 800px)': {
      padding: '3vw !important',
    },
    '&:last-child': {
      borderBottom: 'none !important',
    },
  },
  defaultMenu: {
    fontSize: '0.9vw',
    '@media (max-width: 600px)': {
      fontSize: '1rem',
    },
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw !important',
    },
    padding: '0.7vw !important',
  },
  dropdownUi: {
    marginLeft: 0,
  },
  errorText: {
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    bottom: -20,
    position: 'absolute',
    fontFamily: fonts.fontType.roboto,
    display: 'block',
  },
}));

export default style;
