import { ApiRoutes } from '../../constant';
import request from '../../utils/request';

// Homework Panel Services
// eslint-disable-next-line import/prefer-default-export
export const getHomeworkSchemaService = (body) => request.post(`${ApiRoutes.GET_HOMEWORK_SCHEMA}`, body);

export const saveHomeworkSchemaService = (body, update = false) => {
  const requestMethod = update ? request.put : request.post;

  return requestMethod(ApiRoutes.SAVE_HOMEWORK_SCHEMA, body);
};
