import React, { useEffect, useState } from 'react';
import {
  Table, Grid, FormControl, RadioGroup, Radio,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../custom-hooks/useStyles';
import { getCubejsApi } from '../../../../utils/cube';
import styles from './style';
import { getAllCourses } from '../../../../store/actions/getStudent';
import constant from '../../../../store/constant';
import { getAllLocationCourse } from '../../../../store/actions/getLocationCoordinator';

const categories = ['notScored', 'absent', 'score65', 'score75', 'score100'];

const getCategory = (item) => {
  const marks = item['EnrolledCourseQuarterwise.locationPanelScoreTotalMarks'];
  const isPresent = item['EnrolledCourseQuarterwise.locationPanelScoreIsPresent'];
  if (marks === null && isPresent === null) {
    return 'notScored';
  }
  if (!isPresent) {
    return 'absent';
  }
  switch (true) {
    case marks >= 0 && marks <= 64:
      return 'score65';
    case marks > 64 && marks <= 75:
      return 'score75';
    case marks > 75:
      return 'score100';
    default:
      return 'notScored';
  }
};

function CoursesReport({ location, year }) {
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const locationStore = useSelector((state) => state?.getLocationCoordinator);

  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [selectedQuarter, setQuarter] = useState('Q1');
  const [availableQuarters] = useState(['Q1', 'Q2', 'Q3']);
  const [SelectedSemester, setSemester] = useState('S1');
  const [SelectedSemeste, setSemeste] = useState('S1');
  const [availableSemesters] = useState(['S1', 'S2', 'PSTU', 'Supplementary']);
  const [availableCourses, setCourses] = useState([]);

  useEffect(() => {
    if (year < '2023-2024') {
      dispatch(getAllCourses());
      dispatch({
        type: constant.SET_SELECTED_QUARTER,
        payload: 'Q1',
      });
    } else {
      dispatch(getAllCourses());
      dispatch({
        type: constant.SET_SELECTED_QUARTER,
        payload: 'S1',
      });
    }
  }, [year]);

  const query = {
    measures: ['EnrolledCourseQuarterwise.count'],
    dimensions: [
      'EnrolledCourseQuarterwise.enrolledCourseStudentId',
      'EnrolledCourseQuarterwise.courseName',
      'EnrolledCourseQuarterwise.locationPanelScoreIsPresent',
      'EnrolledCourseQuarterwise.locationPanelScoreQuarter',
      'EnrolledCourseQuarterwise.locationPanelScoreTotalMarks',
    ],
    order: { 'EnrolledCourseQuarterwise.count': 'desc' },
    filters: [
      { member: 'EnrolledCourseQuarterwise.locationId', operator: 'equals', values: [location] },
      { member: 'EnrolledCourseQuarterwise.academicYear', operator: 'equals', values: [year] },
      { member: 'EnrolledCourseQuarterwise.locationPanelScoreQuarter', operator: 'equals', values: ['S1'] }],
  };

  useEffect(() => {
    if (locationStore?.locationCourses?.length > 0) {
      setData({});
      query.filters = query.filters.map((item) => {
        if (item.member === 'EnrolledCourseQuarterwise.locationPanelScoreQuarter') {
          if (year < '2023 - 2024') {
            item.values = [selectedQuarter];
          } else {
            item.values = [SelectedSemester];
          }
        }

        return item;
      });
      setCourses(locationStore?.locationCourses?.map((course) => course.name));
      getCubejsApi()
        .load(query)
        .then((resultSet) => {
          if ((resultSet?.rawData() || []).length > 0) {
            const obje = {};
            resultSet?.rawData().map((item) => {
              const obj = {
                quarter: item['EnrolledCourseQuarterwise.locationPanelScoreQuarter'],
                courseName: item['EnrolledCourseQuarterwise.courseName'],
                count: parseInt(item['EnrolledCourseQuarterwise.count'], 10),
                marks: item['EnrolledCourseQuarterwise.locationPanelScoreTotalMarks'],
                category: getCategory(item),
                isPresent: item['EnrolledCourseQuarterwise.locationPanelScoreIsPresent'],
              };
              if (
                obje[obj.quarter]
                && obje[obj.quarter][`${obj.category}-${obj.courseName}`]
              ) {
                obje[obj.quarter][`${obj.category}-${obj.courseName}`]
                  += obj.count;
              } else if (!obj.quarter && obj.category === 'notScored') {
                const keyName = `${obj.category}-${obj.courseName}`;
                obje.Q1 = {
                  ...obje.Q1,
                  [keyName]: (obje.Q1 && obje.Q1[keyName] ? obje.Q1[keyName] : 0) + obj.count,
                };
                obje.Q2 = {
                  ...obje.Q2,
                  [keyName]: (obje.Q2 && obje.Q2[keyName] ? obje.Q2[keyName] : 0) + obj.count,
                };
                obje.Q3 = {
                  ...obje.Q3,
                  [keyName]: (obje.Q3 && obje.Q3[keyName] ? obje.Q3[keyName] : 0) + obj.count,
                };
              } else if (obj.quarter === 'S3') {
                const Supplementary = ['Pravesam', 'Prasunam', 'Pramodam'];
                const PSTU = ['Prabhasam', 'Prakasam', 'Telugu-2', 'Telugu-4'];
                const courseValues = SelectedSemeste === 'Supplementary' ? Supplementary : PSTU;
                if (courseValues.includes(obj.courseName)) {
                  obje[obj.quarter] = {
                    ...obje.S3,
                    [`${obj.category}-${obj.courseName}`]: obj.count,
                  };
                }
              } else {
                obje[obj.quarter] = {
                  ...obje[obj.quarter],
                  [`${obj.category}-${obj.courseName}`]: obj.count,
                };
              }

              return obj;
            });
            setData(obje);
          }
        });
    }
  }, [locationStore.locationCourses, locationStore?.locationCourses?.length, selectedQuarter, SelectedSemester, SelectedSemeste]);

  useEffect(() => {
    dispatch(getAllLocationCourse({
      locationId: location,
    }));
  }, [location, year]);

  const renderHeader = () => {
    if (
      !locationStore?.locationCourses
      || locationStore?.locationCourses?.length === 0
      || Object.entries(data).length === 0
    ) {
      return null;
    }

    return (locationStore?.locationCourses || []).map((course, index) => (
      <TableCell
        key={`${course.name}-${index} `}
        className={classes.tableHeader}
        align="right"
      >
        {course.name}
      </TableCell>
    ));
  };

  const renderQuarterRadioBox = () => {
    if (year < '2023 - 2024') {
      if (!availableQuarters || availableQuarters.length === 0) {
        return null;
      }
      return availableQuarters.map((quarter) => (
        <FormControlLabel
          onChange={(e) => {
            setQuarter(e.target.name);
            dispatch({
              type: constant.SET_SELECTED_QUARTER,
              payload: e.target.name,
            });
          }}
          key={quarter}
          name={quarter}
          checked={selectedQuarter === quarter}
          control={<Radio size="small" />}
          label={quarter}
        />
      ));
    }
    return availableSemesters.map((semester) => {
      const isPSTU = semester === 'PSTU';
      const isSupplementary = semester === 'Supplementary';
      let isChecked;
      if (isPSTU) {
        isChecked = 'PSTU';
      } else if (isSupplementary) {
        isChecked = 'Supplementary';
      } else {
        isChecked = semester;
      }

      return (
        <FormControlLabel
          onChange={(e) => {
            const selectedSemesters = e.target.name === 'PSTU' || e.target.name === 'Supplementary' ? 'S3' : e.target.name;
            setSemeste(e.target.name);
            setSemester(selectedSemesters);
            dispatch({
              type: constant.SET_SELECTED_QUARTER,
              payload: selectedSemesters,
            });
          }}
          key={semester}
          name={semester}
          checked={isChecked === SelectedSemeste}
          control={<Radio size="small" />}
          label={semester}
        />
      );
    });
  };
  const renderBody = () => {
    if (
      !data
      || locationStore?.locationCourses?.length === 0
      || Object.entries(data).length === 0
      || !data[year < '2023 - 2024' ? selectedQuarter : SelectedSemester]
    ) {
      return <div className={classes.noData}>{t('NO_DATA')}</div>;
    }

    return categories.map((category, i) => (
      <TableRow key={category + i} className={classes.tableRow}>
        <TableCell component="th" scope="row">
          {t(category.toUpperCase())}
        </TableCell>
        {availableCourses.map((course, index) => (
          <TableCell
            key={`${category}-${course}-${index}`}
            align="center"
            className={
              data[year < '2023 - 2024' ? selectedQuarter : SelectedSemester][`${category}-${course}`]
              && classes[`${category}`]
            }
          >
            {data[year < '2023 - 2024' ? selectedQuarter : SelectedSemester][`${category}-${course}`]}
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const renderTable = () => {
    if (Object.entries(data).length === 0 || !data[year < '2023 - 2024' ? selectedQuarter : SelectedSemester]) {
      return <div className={classes.noData}>{t('NO_DATA')}</div>;
    }

    return (
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell />
              {renderHeader()}
            </TableRow>
          </TableHead>
          <TableBody>{renderBody()}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Grid container item direction="column">
      <div className={`${classes.testScoreTitle} `}>{t('TEST_SCORE')}</div>
      <Grid className={classes.radioButtonWrapper}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="top"
          >
            {renderQuarterRadioBox()}
          </RadioGroup>
        </FormControl>
      </Grid>
      <div className={classes.horizontalLine} />
      {renderTable()}
    </Grid>
  );
}

export default function CoursesReportWrapper({ location, year }) {
  return <CoursesReport location={location} year={year} />;
}
