/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Constant from '../../../../store/constant/index';
import styles from './styles';
import useStyles from '../../../../custom-hooks/useStyles';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import { DialogAtom } from '../../../../components/atoms';
import DropdownWithLabelAcademicPanel from '../../../../components/atoms/dropdown-with-labelAcademicPanel';
import { getclassesTimings } from '../../../../store/actions/getTeacherView';
import {
  getAssignmentPerCourse,
  deleteAssignmentById,
  getAllTopics,
  editAssignmentById,
} from '../../../../store/actions/getAdmin';
import { getStudentResourceWRTCourseAP } from '../../../../store/actions/getResources';
import CardsAssignment from './cardsAssignment';
import AddAssignment from './addAssignment';
import ViewAssignment from './viewAssignment';
import DeleteAssignment from './deleteAssignment';
import SavePublishAssignment from './savePublishAssignment';
import { getClassDetails } from '../../../teacher-user/attendance/helper';
import useDataTableParams from '../../../../custom-hooks/useDataTableParams';
import { getCurrentYear } from '../../../../utils/methods';

const getCourseOptions = (courses) => courses.map((course) => ({
  id: course.id,
  name: course.name,
}));

const PopUpModeAdd = 'add';
const PopUpModeEdit = 'edit';

function NewAssignment({
  selectedCourse,
  setSelectedCourse,
  courses,
  setLoading,
}) {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    nextPage,
  } = useDataTableParams();

  const reduxStore = useSelector((state) => state?.getAdmin);
  const reduxClass = useSelector((state) => state?.getTeacherView);
  const locationCoorRedux = useSelector((state) => state?.getLocationCoordinator);
  const assignmentsPerCourse = reduxStore?.assignments;
  const events = reduxClass?.classesTimings;
  const assignmentTopics = reduxStore?.topics;
  let studentResourceWRT = locationCoorRedux?.studentResourceWRT;
  const studentResourceWRTFolder = locationCoorRedux?.studentResourceWRTFolder;
  const studentResourceTotalCount = locationCoorRedux?.totalCount;
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [popUpMode, setPopUpMode] = useState(PopUpModeAdd);
  const [issetAddAssignmentOpen, setAddAssignmentOpen] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [assignmentIdForDelete, setAssignmentIdForDelete] = useState('');
  const [isViewAssignmentOpen, setViewAssignmentOpen] = useState(false);
  const [selectedAssignmentView, setSelectedAssignmentView] = useState(null);
  const [isDeleteAssignmentOpen, setDeleteAssignmentOpen] = useState(false);
  const [isSavePublishAssignmentOpen, setSavePublishAssignmentOpen] = useState(false);
  const [eventsClassData, setEventsClassData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedResource, setSelectedResource] = useState([]);
  const [loadingResources, setLoadingResources] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountNum, setTotalCountNum] = useState(0);
  localStorage.setItem('academicYear', localStorage.getItem('currentAcademicYear'));

  const courseOptions = courses.length ? getCourseOptions(courses) : [];
  const topicsData = assignmentTopics.filter((d) => d.topic_name !== '' && d.topic_name !== null);
  const assignmentTopicsWithId = topicsData.map((topic) => ({
    id: topic.topic_name,
    name: topic.topic_name,
  }));

  const selectedCourseName = useMemo(() => {
    if (!selectedCourse) {
      return '';
    }

    return courseOptions.find((course) => course.id === selectedCourse)?.name;
  }, [selectedCourse]);

  const handlePopUp = (state, assignmentItem = null, mode = PopUpModeAdd) => {
    if (mode === PopUpModeAdd) {
      setAddAssignmentOpen(state);
    } else if (!state) {
      setAddAssignmentOpen(state);
    }
    if (!state) {
      setTimeout(() => {
        setPopUpMode(mode);
      }, 300);
    } else {
      setPopUpMode(mode);
    }
    setSelectedAssignment(assignmentItem);
  };

  const deleteAssignment = async (assignmentId) => {
    async function del() {
      dispatch(deleteAssignmentById(assignmentId, selectedCourse, true));
      return true;
    }
    await del();
  };

  const handlePopUpCancel = () => {
    handlePopUp(false);
  };

  const handlePopUpSave = async (payload, Course, selectedAssignmentId, loadFalse) => {
    if (popUpMode === PopUpModeEdit) {
      // eslint-disable-next-line
      async function del() {
        dispatch(editAssignmentById(payload, Course, loadFalse, selectedAssignmentId));
        return true;
      }
      await del();
    }
  };

  const deleteAssignmentOpener = (assignmentId) => {
    setAssignmentIdForDelete(assignmentId);
    setDeleteAssignmentOpen(true);
  };

  const viewAssignmentOpener = (state, assignmentItem) => {
    setViewAssignmentOpen(state);
    setSelectedAssignmentView(assignmentItem);
  };

  useEffect(() => {
    setAssignments(assignmentsPerCourse);
    setLoading(false);
  }, [assignmentsPerCourse]);

  useEffect(() => {
    if (!courseOptions || !courseOptions.length) {
      return;
    }
    setSelectedCourse(selectedCourse || courseOptions[0]?.id || '', false);
  }, [courseOptions]);

  useEffect(() => {
    if (selectedCourse) {
      setLoading(true);
      dispatch(getAssignmentPerCourse(selectedCourse, true));
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (!events || !Array.isArray(events)) {
      return;
    }
    setEventsClassData(getClassDetails(events));
  }, [events]);

  useEffect(() => {
    if (studentResourceWRT && Array.isArray(studentResourceWRT) && studentResourceWRT.length) {
      setTotalCountNum(studentResourceWRT.length);
      studentResourceWRT = studentResourceWRT.filter((e) => e.deactivateDate === null);
      setResourceData(studentResourceWRT);
    } else {
      setTotalCountNum(0);
      setResourceData([]);
    }
  }, [studentResourceWRT]);

  useEffect(() => {
    setTotalCount(studentResourceTotalCount);
  }, [studentResourceTotalCount]);

  useEffect(() => {
    if (!selectedCourse) {
      return;
    }
    const payload = {
      courseId: selectedCourse,
      academicYear: getCurrentYear()?.id,
    };
    dispatch(getclassesTimings(payload));
    const payloadResousces = {
      courseId: selectedCourse,
      parentKey: selectedResource.selfKey,
    };
    setLoadingResources(true);
    dispatch(getStudentResourceWRTCourseAP(1, 20, nextPage, payloadResousces, setIsFetching, setLoadingResources));
  }, [selectedCourse]);

  useEffect(() => {
    dispatch(getAllTopics());
  }, []);

  const refreshList = () => {
    setLoading(true);
    dispatch(getAssignmentPerCourse(selectedCourse, true));
  };

  const deleteAssignmentDialog = async (assignmentId) => {
    async function del() {
      dispatch(deleteAssignmentById(assignmentId, selectedCourse, true));
      setDeleteAssignmentOpen(false);
      return true;
    }
    await del();
  };

  useEffect(() => {
    if (selectedAssignment) {
      setAddAssignmentOpen(true);
    }
  }, [selectedAssignment]);
  return (
    <Box container>
      <DialogAtom
        isOpen={issetAddAssignmentOpen}
        dialogHeading={popUpMode === PopUpModeAdd ? t('ADD_ASSIGNMENT') : t('EDIT_ASSIGNMENT')}
        customClass={classes.DialogAtomAddAssignment}
        content={(
          <AddAssignment
            classes={classes}
            eventsClassData={eventsClassData}
            assignmentTopicsWithId={assignmentTopicsWithId}
            resourceData={resourceData}
            setIsFetching={setIsFetching}
            selectedCourseName={selectedCourseName}
            selectedCourse={selectedCourse}
            studentResourceWRT={studentResourceWRT}
            studentResourceWRTFolder={studentResourceWRTFolder}
            nextPage={nextPage}
            isFetching={isFetching}
            handlePopUpCancel={handlePopUpCancel}
            handlePopUpSave={handlePopUpSave}
            setAddAssignmentOpen={setAddAssignmentOpen}
            setSavePublishAssignmentOpen={setSavePublishAssignmentOpen}
            setLoading={setLoading}
            handlePopUp={handlePopUp}
            refreshList={refreshList}
            selectedAssignment={selectedAssignment}
            loadingResources={loadingResources}
            totalCount={totalCount}
            totalCountNum={totalCountNum}
            setLoadingResources={setLoadingResources}
            popUpMode={popUpMode}
            editFromStatus={false}
          />
        )}
        secHandle={handlePopUpCancel}
      />
      <DialogAtom
        isOpen={isViewAssignmentOpen}
        dialogHeading={t('VIEW_ASSIGNMENT')}
        customClass={classes.DialogAtomAddAssignment}
        content={(
          <ViewAssignment
            classes={classes}
            setAddAssignmentOpen={setAddAssignmentOpen}
            setLoading={setLoading}
            selectedCourseName={selectedCourseName}
            selectedCourse={selectedCourse}
            handlePopUp={handlePopUp}
            refreshList={refreshList}
            selectedAssignmentView={selectedAssignmentView}
            resourceData={resourceData}
            setViewAssignmentOpen={setViewAssignmentOpen}
          />
        )}
        secHandle={() => setViewAssignmentOpen(false)}
      />
      <DialogAtom
        isOpen={isSavePublishAssignmentOpen}
        dialogHeading={t('PUBLISHED_SUCCESS')}
        customClass={classes.DialogAtom}
        content={(
          <SavePublishAssignment
            classes={classes}
            setSavePublishAssignmentOpen={setSavePublishAssignmentOpen}
          />
        )}
        secHandle={() => setSavePublishAssignmentOpen(false)}
      />
      <DialogAtom
        isOpen={isDeleteAssignmentOpen}
        dialogHeading={t('DELETE_ASSIGNMENT')}
        customClass={classes.DialogAtom}
        content={(
          <DeleteAssignment
            assignmentId={assignmentIdForDelete}
            classes={classes}
            setDeleteAssignmentOpen={setDeleteAssignmentOpen}
            deleteAssignmentDialog={deleteAssignmentDialog}
            deleteFromStatus={false}
          />
        )}
        secHandle={() => setDeleteAssignmentOpen(false)}
      />
      <Grid container className={classes.topContainerBox}>
        <Grid item xs={12} md={6} lg={3} className={classes.headerGridItem}>
          <div>
            <Typography className={classes.headerTitle}>
              {t('NEW_ASSIGNMENT')}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={5} className={classes.functionalGridItem}>
          <div className={classes.dropdownDiv}>
            <DropdownWithLabelAcademicPanel
              id="courses"
              name="courses"
              disabled={false}
              options={courseOptions}
              label={`${t('COURSES')}`}
              placeholder="COURSES"
              customStyles={classes.dropDownCont}
              value={selectedCourse}
              handleChange={(e) => {
                const { value } = e.target;
                setSelectedCourse(value);
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                  payload: null,
                });
              }}
            />
          </div>
          <div className={classes.buttonDiv}>
            <ButtonAtom
              className={classes.buttonDiv}
              name={t('ADD_ASSIGNMENT')}
              onClick={() => handlePopUp(true)}
              btntype={Buttons.PRIMARY}
              icon={<AddIcon />}
            />
          </div>
        </Grid>
      </Grid>
      <CardsAssignment
        classes={classes}
        resourceData={resourceData}
        assignments={assignments}
        PopUpModeEdit={PopUpModeEdit}
        deleteAssignmentOpener={deleteAssignmentOpener}
        viewAssignmentOpener={viewAssignmentOpener}
        handlePopUp={handlePopUp}
      />
    </Box>
  );
}

export default NewAssignment;
