/* eslint-disable array-callback-return */
import React from 'react';
import {
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Legend,
  Bar,
  Tooltip,
} from 'recharts';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';
import Chart from '../../../common/bar-chart';
import { fonts } from '../../../../theme';

export default function MissingScore({ teacherPanel, year }) {
  const classes = useStyles(styles)();
  const { t } = useTranslation();

  let dataQuarters = [
    {
      name: 'Q1',
      score: 0,
      notScore: 0,
    },
    {
      name: 'Q2',
      score: 0,
      notScore: 0,
    },
    {
      name: 'Q3',
      score: 0,
      notScore: 0,
    },
  ];

  let dataSemesters = [
    {
      name: 'S1',
      score: 0,
      notScore: 0,
    },
    {
      name: 'S2',
      score: 0,
      notScore: 0,
    },
    {
      name: 'S3',
      score: 0,
      notScore: 0,
    },
  ];

  if (teacherPanel?.studentsByYearAndClassId?.length) {
    const result = teacherPanel.studentsByYearAndClassId.reduce((obj, item) => {
      if (!item || !item?.marks) {
        return obj;
      }
      const { marks } = item;
      Object.entries(marks)?.forEach(([key, value]) => {
        if (obj[key]) {
          obj[key] = {
            name: key,
            score: (value?.obtainedMarks !== 'N/A') && value?.academicPanelId ? (obj[key].score + 1) : obj[key].score,
            notScore: value?.obtainedMarks === 'N/A' || ((value?.obtainedMarks === 0) && (!value?.academicPanelId)) ? (obj[key].notScore + 1) : obj[key].notScore,
          };
        } else {
          obj[key] = {
            name: key,
            score: (value?.obtainedMarks !== 'N/A') && value?.academicPanelId ? 1 : 0,
            notScore: value?.obtainedMarks === 'N/A' || ((value?.obtainedMarks === 0) && (!value?.academicPanelId)) ? 1 : 0,
          };
        }
      });
      return obj;
    }, {});
    if (year < '2023 - 2024') {
      const quartersChart = [];
      dataQuarters.map((item) => {
        Object.values(result).map((quarter) => {
          if (item.name === quarter.name) {
            quartersChart.push(quarter);
          }
        });
      });
      dataQuarters = quartersChart;
    } else {
      const semestersChart = [];
      dataSemesters.map((item) => {
        Object.values(result).map((semester) => {
          if (item.name === semester.name) {
            semestersChart.push(semester);
          }
        });
      });
      dataSemesters = semestersChart;
    }
  }
  return (
    <Grid container item direction="column">
      <div className={`${classes.title} `}>
        {t('MISSING_SCORE_BREAKDOWN')}
      </div>
      <div className={classes.horizontalLine} />
      <div className={classes.chartWrapper}>
        <Chart
          xAxisDataKey="name"
          yAxisLabel={t('STUDENTS')}
          chartData={year < '2023 - 2024' ? dataQuarters : dataSemesters}
          renderBars={() => {
            return (
              <>
                <Bar dataKey="score" fill="#0745BA" />
                <Bar dataKey="notScore" fill=" #F45D18" />
              </>
            );
          }}
          renderLegend={() => {
            return (
              <Legend
                className={classes.legend}
                verticalAlign="top"
                height={50}
                align="left"
                wrapperStyle={{
                  padding: '10px',
                  fontSize: '16px',
                  fontFamily: fonts.fontType.roboto,
                }}
                iconType="circle"
                formatter={(value) => t(value.toUpperCase())}
              />
            );
          }}
          renderTooltip={() => {
            return (
              <Tooltip
                formatter={(value, name) => {
                  return [value, t(name.toUpperCase())];
                }}
              />
            );
          }}
        />
      </div>
    </Grid>
  );
}
