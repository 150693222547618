/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@mui/material';
import { CSVLink } from 'react-csv';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ButtonAtom from '../../../components/atoms/button';
import { useStyles } from './style';
import { Buttons, NavigateRoutes } from '../../../constant';
import CheckboxAtom from '../../../components/atoms/checkbox/index';
import LocationManagerTable from './location-manager-table';
import { DialogAtom } from '../../../components/atoms';
import AddEditLocation from './add/index';
import { getAllCourses } from '../../../store/actions/getStudent';
import {
  createLocation, getAllExamCenters,
  getAllLocationCoordinators, getAllPaginatedLocations, updateLocation,
} from '../../../store/actions/getLocations';
import { getActiveRegions } from '../../../store/actions/getRegion';
import TextFieldAtom from '../../../components/atoms/textfield';
import LocationExportDialog from './export';
import { createPayload, EditDataObject, getEmptyLocation } from './helper';

export default function LocationManager() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [dataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();
  const [isDeactivateChecked, setStatus] = useState(false);
  const [showLocationDialog, setShowLocationDialog] = useState(false);
  const [addLocloading, setAddLocLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const reduxStore = useSelector((state) => state.getStudent);
  const locationStore = useSelector((state) => state.getLocations);
  const [assignedLocations, setAssignedLocations] = useState([]);
  const regionState = useSelector((state) => state.getRegion);
  const [dialogHeader, setDialogHeader] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [locationAddressInfo, setLocationAddressInfo] = useState('');
  const [shippingAddressInfo, setShippingAddressInfo] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [locationAddressId, setLocationAddressId] = useState('');
  const [shippingAddressId, setShippingAddressId] = useState('');
  const [actualActiveCourses, setActualActiveCourses] = useState('');
  const dispatch = useDispatch();
  const [showExportDialog, setExportDialog] = useState(false);
  const [locationActive, setLocationActive] = useState(false);
  const [examCenterActive, setExamCenterActive] = useState(false);
  const [locationExistsError, setLocationExistsError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(false);
  const [locationActiveError, setLocationActiveError] = useState(false);
  const [examCenterActiveError, setExamCenterActiveError] = useState(false);
  const [primaryContactNumber, setPrimaryContactNumber] = useState('');
  const pageLimit = 100;
  const [pageNum, setPageNum] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [coordinatorError, setCoordinatorError] = useState(false);
  const [startEndTimeError, setStartEndTimeError] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [activeRegions, setActiveRegions] = useState(false);
  const [isStartTime, setStartTime] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isEndTime, setEndTime] = useState('');

  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const resetAllErrors = () => {
    setLocationActiveError('');
    setExamCenterActiveError('');
    setLocationExistsError('');
    setCoordinatorError('');
    setStartEndTimeError('');
  };

  useEffect(() => {
    const activeRegion = regionState.activeRegions?.map((region) => ({
      id: region.id,
      name: region.name,
    }));
    setActiveRegions(activeRegion);
  }, [regionState]);
  const refreshList = () => {
    resetAllErrors();
    setHasMore(true);
    if (pageNum > 1) {
      setPageNum(1);
    } else {
      dispatch(
        getAllPaginatedLocations(
          1,
          pageLimit,
          setTotalCount,
          searchValue,
          refreshList,
          '',
          '',
          setAddLocLoading,
          setLoading,
        ),
      );
    }
    setShowLocationDialog(false);
  };

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  useEffect(() => {
    dispatch(getAllPaginatedLocations(pageNum, pageLimit, setTotalCount, searchValue, '', '', '', '', setLoading));
    setIsInitialLoad(false);
  }, [pageNum]);

  useEffect(() => {
    if (!isInitialLoad) {
      setLoading(true);
      dispatch(getAllPaginatedLocations(1, pageLimit, setTotalCount, searchValue, refreshList, true, setAssignedLocations, '', setLoading));
    }
  }, [searchValue]);

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const getLocationTableData = () => {
    let tableData = assignedLocations?.map((location) => {
      const coOrd = (location?.location_coordinators || []).find((coordinator) => coordinator.isPrimary === true);
      const activeCoursesObj = (location?.location_courses || []).filter((activecourses) => activecourses.isActive === true);
      const exportObj = {
        name: location.name,
        locationCoordinator: coOrd?.user?.first_name ? `${coOrd?.user?.first_name} ${coOrd?.user?.last_name}` : '',
        city: location?.locationAddress?.city,
        address: location?.locationAddress?.address,
        locationAddressAptSuite: location?.locationAddress?.aptSuite,
        shippingAddress: location?.shippingAddress?.address,
        shippingAddressAptSuite: location?.shippingAddress?.aptSuite,
        isActive: location?.isActive,
        examCenter: location?.exam_center?.name,
        isActiveExamCenter: location?.exam_center?.isActive,
        classTiming: location?.classTiming,
        startTime: location?.startTime,
        endTime: location?.endTime,
        region: location?.region?.name,
        coordinator: location?.location_coordinators,
        contactNumber: coOrd?.user?.contact_number,
        activeCourses: activeCoursesObj?.course?.name,
        id: location?.id,
        addressId: location?.locationAddress?.id,
        shippingAddressId: location?.shippingAddress?.id,
      };
      const activeCourses = activeCoursesObj.map((obj) => obj.course.name);
      exportObj.activeCourses = activeCourses.join(',');
      return exportObj;
    });
    if (!isDeactivateChecked) {
      tableData = tableData?.filter((rowData) => (rowData.isActive !== null && rowData.isActive !== false));
    }
    return tableData;
  };

  useEffect(() => {
    setLoading(true);
    setAssignedLocations([]);
    dispatch(getAllCourses());
    dispatch(getAllExamCenters());
    dispatch(getAllLocationCoordinators());
    dispatch(getActiveRegions());
  }, []);

  const validateStartTime = (e) => {
    return moment(e).isValid();
  };

  const validateEndTime = (e) => {
    let isValidAfterStartTime;
    if (moment(e).isValid()) {
      if (moment(e).isAfter(moment(isStartTime))) {
        isValidAfterStartTime = true;
      } else {
        isValidAfterStartTime = false;
      }
    } else {
      isValidAfterStartTime = false;
    }
    return isValidAfterStartTime;
  };

  const addLocation = (payload, setSuccessMessage) => {
    setAddLocLoading(true);
    setAssignedLocations([]);
    dispatch(createLocation(payload, refreshList, setLocationExistsError, setAddLocLoading, setSuccessMessage));
  };

  const editSchema = Yup.object({
    changeLog: Yup.string(t('CHANGE_LOG_REQUIRED')).required(
      t('CHANGE_LOG_REQUIRED'),
    ),
  });

  const addSchema = Yup.object({
    locationName: Yup.string(t('LOCATION_NAME_REQUIRED'))
      .required(t('LOCATION_NAME_REQUIRED'))
      .typeError(t('LOCATION_NAME_REQUIRED')),
    locationAddress: Yup.string(t('LOCATION_ADDRESS_REQUIRED'))
      .required(t('LOCATION_ADDRESS_REQUIRED'))
      .typeError(t('LOCATION_ADDRESS_REQUIRED')),
    shippingAddress: Yup.string(t('SHIPPING_ADDRESS_REQUIRED'))
      .required(t('SHIPPING_ADDRESS_REQUIRED'))
      .typeError(t('SHIPPING_ADDRESS_REQUIRED')),
    examCenter: examCenterActive ? Yup.string(t('EXAM_CENTER_REQUIRED'))
      .required(t('EXAM_CENTER_REQUIRED')).typeError(t('EXAM_CENTER_REQUIRED')) : null,
    region: Yup.string(t('REGION_REQUIRED'))
      .required(t('REGION_REQUIRED')).typeError(t('REGION_REQUIRED')),
    classTiming: Yup.string(t('DAY_OF_CLASS_REQUIRED'))
      .required(t('DAY_OF_CLASS_REQUIRED')).typeError(t('DAY_OF_CLASS_REQUIRED')),
    startTime: Yup.string(t('START_TIME_REQUIRED'))
      .required(t('START_TIME_REQUIRED'))
      .typeError(t('START_TIME_REQUIRED'))
      .test('isValidStartTime', t('INVALID_START_TIME'), (value) => (value ? validateStartTime(value) : false)),
    endTime: Yup.string(t('END_TIME_REQUIRED'))
      .required(t('END_TIME_REQUIRED'))
      .typeError(t('END_TIME_REQUIRED'))
      .test('isValidStartEndTime', t(''), (value) => (value ? validateEndTime(value) : false)),
    activeCourses: Yup.array()
      .min(1, t('ACTIVE_COURSES_REQUIRED'))
      .required(t('ACTIVE_COURSES_REQUIRED')),
  });

  const formik = useFormik({
    initialValues: getEmptyLocation(),
    validationSchema: isEdit ? addSchema.concat(editSchema) : addSchema,
    onSubmit: (values) => {
      if (!coordinatorError) {
        setAddLocLoading(true);
        setLoading(true);
        const payload = createPayload(values, selectedOptions, locationActive, examCenterActive, locationAddressInfo, shippingAddressInfo, actualActiveCourses);
        if (isEdit) {
          payload.location.id = selectedRow?.[0]?.id;
          payload.locationAddress.id = locationAddressId;
          payload.shippingAddress.id = shippingAddressId;
          payload.logMessage = values.changeLog;
          dispatch(updateLocation(payload, refreshList, setLocationExistsError, setAssignedLocations, setLoading));
          formik.values.changeLog = '';
        } else {
          setLocationActiveError('');
          setExamCenterActiveError('');
          addLocation(payload, setSuccessMessage);
        }
        formik.setTouched({}, false);
      }
    },
  });

  const viewLogs = () => {
    navigate(NavigateRoutes.LOCATION_MANAGER_LOGS, {
      state: {
        id: selectedRow[0]?.id,
        lastRoute: NavigateRoutes.LOCATION_MANAGER_VIEW,
      },
    });
  };

  useEffect(() => {
    if ((formik?.touched?.coOrdinator && !selectedOptions?.length)) {
      setCoordinatorError(t('COORDINATORS_REQUIRED'));
    }
    const isPrimary = selectedOptions && selectedOptions?.findIndex(((obj) => obj?.isPrimary === true));
    if (formik?.isSubmitting && selectedOptions?.length > 0 && isPrimary < 0) {
      setCoordinatorError(t('PRIMARY_COORDINATORS_REQUIRED'));
    }

    if (formik?.isSubmitting && formik.values.endTime !== '' && formik.values.startTime !== '') {
      const isValidEndTime = moment(formik.values.endTime).isAfter(moment(formik.values.startTime));
      if (!isValidEndTime) {
        setStartEndTimeError(t('ENDTIME_GREATER_THAN_STARTTIME'));
      }
    }
  }, [formik?.touched, formik?.values]);

  useEffect(() => {
    EditDataObject(formik, selectedRow, setLocationActive, setExamCenterActive, setPrimaryContactNumber, setLocationAddressId, setShippingAddressId, setActualActiveCourses);
  }, [selectedRow]);

  // eslint-disable-next-line no-nested-ternary
  const getErrorText = (key, errorText) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={classes.errorText}>
      {formik.errors[key]}
    </span>
  ) : errorText ? (
    <span className={classes.errorText}>{errorText}</span>
  ) : null);

  const renderSuccessDialog = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('ADD_LOCATION_SUCCESS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setSuccessMessage(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid className={classes.locationRoot}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              {t('LOCATION_MANAGER')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldAtom
              id="locationSearch"
              customFormControlCss={{ margin: '0.8vw 0' }}
              name="locationSearch"
              type="text"
              minWidth="80%"
              placeholder={t('SEARCH')}
              disabled={false}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setStatus(false);
                setPageNum(1);
              }}
              value={searchValue}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.dFlex} item xs={8} lg={10.5} md={10.5}>
            <div>
              <ButtonAtom
                className={classes.addbutton}
                name={t('EXPORT_TO_CSV')}
                btntype={Buttons.PRIMARY}
                type={Buttons.PRIMARY}
                onClick={() => setExportDialog(true)}
              />
              <CSVLink
                data={dataForDownload}
                filename="location-list.csv"
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            </div>
            <ButtonAtom
              className={classes.addbutton}
              name={t('ADD')}
              btntype={Buttons.PRIMARY}
              type={Buttons.PRIMARY}
              onClick={() => {
                setPrimaryContactNumber('');
                setCoordinatorError('');
                setStartEndTimeError('');
                formik.setTouched({}, false);
                setSelectedRow([]);
                setDialogHeader(t('ADD_LOCATION'));
                setIsEdit(false);
                setShowLocationDialog(!showLocationDialog);
              }}
              icon={<AddIcon className={classes.addIconSize} />}
            />
          </Grid>
          <Grid item xs={1.5}>
            <div className={classes.deactiveCheckbox}>
              <CheckboxAtom
                label={t('VIEW_INACTIVE')}
                id="acceptCheckbox"
                handleChange={() => {
                  setStatus(!isDeactivateChecked);
                }}
                checked={isDeactivateChecked}
              />
            </div>
          </Grid>
        </Grid>
        <Grid className={classes.locationManagerContainer}>
          <LocationManagerTable
            openEditLocation={setShowLocationDialog}
            setSelectedRow={setSelectedRow}
            setDialogHeader={setDialogHeader}
            setIsEdit={setIsEdit}
            searchValue={searchValue}
            locationStore={locationStore}
            assignedLocations={assignedLocations}
            setAssignedLocations={setAssignedLocations}
            deactivatedStatus={isDeactivateChecked}
            tableData={getLocationTableData()}
            loading={loading}
            setLocationExistsError={setLocationExistsError}
            pageNum={pageNum}
            setPageNum={setPageNum}
            totalCount={totalCount}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            hasMore={hasMore}
            setHasMore={setHasMore}
            setCoordinatorError={setCoordinatorError}
            setStartEndTimeError={setStartEndTimeError}
          />
        </Grid>
        <LocationExportDialog
          show={showExportDialog}
          setShow={() => setExportDialog(false)}
          data={getLocationTableData()}
        />
      </Grid>
      <DialogAtom
        isOpen={showLocationDialog}
        dialogHeading={dialogHeader}
        customClass={classes.addCourseDialogAtom}
        content={(
          <AddEditLocation
            loading={addLocloading}
            setShowLocationDialog={setShowLocationDialog}
            showLocationDialog={showLocationDialog}
            selectedRow={selectedRow}
            getErrorText={getErrorText}
            formik={formik}
            state={reduxStore}
            locationState={locationStore}
            activeRegions={activeRegions}
            setLocationExistsError={setLocationExistsError}
            setLocationAddressInfo={setLocationAddressInfo}
            setShippingAddressInfo={setShippingAddressInfo}
            locationActive={locationActive}
            setLocationActive={setLocationActive}
            examCenterActive={examCenterActive}
            setExamCenterActive={setExamCenterActive}
            locationExistsError={locationExistsError}
            isEdit={isEdit}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            locationActiveError={locationActiveError}
            examCenterActiveError={examCenterActiveError}
            primaryContactNumber={primaryContactNumber}
            setPrimaryContactNumber={setPrimaryContactNumber}
            coordinatorError={coordinatorError}
            setCoordinatorError={setCoordinatorError}
            startEndTimeError={startEndTimeError}
            setStartEndTimeError={setStartEndTimeError}
            viewLogs={viewLogs}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            setSuccessMessage={setSuccessMessage}
          />
        )}
        secHandle={() => {
          if (primaryContactNumber) {
            setPrimaryContactNumber('');
            setSelectedOptions([]);
          }
          formik.setTouched({}, false);
          resetAllErrors();
          setShowLocationDialog(false);
        }}
      />
      {
        successMessage && (
          <DialogAtom
            isOpen={successMessage}
            dialogHeading={t('ADD_LOCATION_SUCCESS_TITLE')}
            customClass={classes.DialogAtom}
            content={renderSuccessDialog()}
            secHandle={() => setSuccessMessage(false)}
          />
        )
      }
    </>
  );
}
