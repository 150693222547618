import Constant from '../constant';

const initialState = {
  gradeData: [],
};

const getGrades = (state = initialState, action = {}) => {
  switch (action.type) {
    case Constant.GET_GRADES:
      return {
        ...state,
        gradeData: action.payload,
      };
    default:
      return state;
  }
};

export default getGrades;
