import { colors, fonts } from '../../../../../theme';

const assignmentDialog = (theme) => ({
  formControlGrid: {
    marginBottom: '5px',
    width: '100%',
    '@media (max-width: 1199px)': {
      marginRight: 0,
      paddingLeft: '5px !important',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.black} !important`,
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '0.9vw !important',
      color: `${colors.primary} !important`,
      position: 'absolute',
      top: '0%',
      left: '-2%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      zIndex: 1,
      background: '#fff',
      padding: '0 4px',
      transition: 'transform 200ms, font-size 200ms',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -10px) scale(0.8)',
    },
    '& .MuiInputAdornment-root': {
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      '&:focus-within .MuiInputLabel-root': {
        transform: 'translate(-4px, -50%) scale(0.8)',
        fontSize: '0.8vw',
      },
      background: '#fff',
      borderRadius: '0.4vw',
      '@media (max-width: 600px)': {
        height: '55px !important',
      },
      color: colors.placeHolderColor,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
        height: '2.7vw',
      },
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& input': {
        '@media (min-width: 1200px) and (max-width: 1599px)': {
          padding: '0.7vw',
          fontSize: '0.9vw !important',
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.black}!important`,
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px !important',
        },
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },
  assignmentStatusTable: {
    '&.MuiTableHead-root': {
      borderBottom: `0.15vw solid ${colors.actionIconsColor}`,
      marginLeft: '15px',
    },
    '& .MuiTableCell-head': {
      '@media (min-width: 1200px)': {
        fontSize: '1vw',
      },
      padding: '1.5vw 0.5vw',
      color: colors.actionIconsColor,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    '& .MuiTableCell-body': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        width: '2vw',
      },
      '@media (max-width: 576px)': {
        minWidth: '190px',
      },
      padding: '1.5vw 0.5vw',
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          width: '1.5vw',
          height: '1.5vw',
        },
      },
    },
  },
  boxTable: {
    marginLeft: '20px',
  },
  headerTitle: {
    color: `${colors.primary}`,
    fontWeight: fonts.fontWeight.bold,
    '@media (min-width: 1200px)': {
      fontSize: '1.0vw',
      marginTop: '-25px',
      marginBottom: '10px',
    },
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  buttonDiv: {
    padding: '1vw',
    '@media (max-width:900px)': {
      '& .MuiButtonBase-root.MuiButton-root': {
        maxHeight: '55px !important',
        fontSize: 'smaller !important',
      },
      width: '120px',
    },
  },
  threeBoxes: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    marginLeft: '24%',
  },
  assignmentDialogAtom: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    '& .MuiDialogContent-root': {
      '@media (min-width: 1200px)': {
        minWidth: '50vw',
        maxWidth: '50vw',
      },
    },
  },
  firstBox: {
    border: `1px solid ${colors.black}`,
    padding: '10px',
    height: '2%',
    width: '23%',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    marginRight: '2vw',
  },
  insideBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  mailID: {
    border: `1px solid ${colors.black}`,
    marginTop: '1vw',
    padding: '1vw',
    overflow: 'scroll',
    backgroundColor: colors.bgGrey,
  },
  sentIt: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
  },
  iconGreen: {
    color: colors.lightGreen,
  },
  textGreen: {
    color: colors.lightGreen,
  },
  textPrimary: {
    color: colors.primary,
  },
  iconPrimary: {
    color: colors.primary,
  },
  textGrey: {
    color: colors.grey,
  },
  iconGrey: {
    color: colors.grey,
  },
  iconRed: {
    color: colors.errorText,
  },
  textRed: {
    color: colors.errorText,
  },
  assignmentDetails: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    '& .MuiTypography-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        height: '1.5vw',
        '& svg': {
          width: '1.3vw',
          height: '1.3vw',
        },
      },
    },
  },
  analytics: {
    '& .MuiTableCell-root': {
      padding: '0.5vw',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
      },
      border: 'none',
      width: '35%',
    },
    '& .MuiTableCell-head': {
      fontWeight: 600,
    },
  },
  tableBox: {
    '@media (min-width: 1200px)': {
      marginTop: '40% ',
    },
    '@media (min-width: 1400px)': {
      marginTop: '35% ',
    },
    '@media (min-width: 1600px)': {
      marginTop: '30% ',
    },
    '@media (min-width: 2000px)': {
      marginTop: '25% ',
    },
  },
  read: {
    color: colors.primary,
  },
  sentTo: {
    color: colors.primary,
  },
  menssageHeader: {
    color: colors.primary,
    '@media (min-width: 1200px)': {
      width: '12vw',
    },
    float: 'left',
    margin: 0,
  },
  descriptionField: {
    color: colors.primary,
    width: '100%',
    paddingRight: '550px',
    '& .MuiInputBase-input': {
      width: '100%',
    },
  },
  messageDetail: {
    float: 'right',
    margin: 0,
  },
  boxDetails: {
    marginLeft: '1vw',
  },
});

export default assignmentDialog;
