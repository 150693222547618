/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import Constant from '../constant';

import {
  getAcademicSchemaService, getAcademicYearQuarterDetails, saveAcademicAssignmentService, saveAcademicSchemaService,
} from '../services/academic-panel';

const getAcademicSchema = (requestData, setLoading = () => {}) => (dispatch) => {
  getAcademicSchemaService(requestData)
    .then((response) => {
      dispatch({
        type: Constant.GET_ACADEMIC_SCHEMA,
        payload: response?.data,
      });
      setLoading(false);
    })
    .catch((error) => {
      console.log('error :', error);
      setLoading(false);
    });
};

const saveAcademicSchema = async (payload, update, loadFalse, setError, setSuccessMsg, refreshSchema = () => {}) => {
  const errMsgText = 'Failed to update Academic Schema - Marks are already assigned';
  const { success, data } = await saveAcademicSchemaService(payload, update).catch((err) => {
    console.log('err in saving schema', err);
  });

  const errMsg = 'Cannot update academic panel schema';

  if (success) {
    if (typeof data === 'string' && data?.includes(errMsg)) {
      setError(errMsgText);
    } else {
      refreshSchema();
      setSuccessMsg('Academic Schema Created / Updated Successfully');
    }
  }

  loadFalse();
};

const saveAcademicAssignment = async (payload, courseId, setLoading, loadFalse) => {
  try {
    const res = await saveAcademicAssignmentService(payload, courseId);
    if (loadFalse) {
      loadFalse();
    }
    setLoading(false);
    return Promise.resolve(res);
  } catch (error) {
    console.log('err in saving assignment', error);
    setLoading(false);
    return Promise.reject(error);
  }
};

const getQuarterDetails = (year) => (dispatch) => {
  getAcademicYearQuarterDetails(year)
    .then((response) => {
      dispatch({
        type: Constant.GET_QUARTER_DETAILS,
        payload: response?.data || false,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export {
  getAcademicSchema,
  saveAcademicSchema,
  getQuarterDetails,
  saveAcademicAssignment,
};
