/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';
import style from '../../register/style';
import {
  PerformantTextField,
  Loader,
  PerformantDropdown,
  Checkbox,
} from '../../../components/atoms';
import { updateStuRepeatStatus } from '../../../store/actions/getAdmin';
import { getLocalStorage } from '../../../utils/localStorageMethod';

function RepeatStatus(props) {
  const {
    repeatStatusInfo,
    setRepeatStatusOpen,
    refreshList,
    setCustomForm,
    isCancelButtonDisable,
    isForInactiveStudent,
  } = props;
  const { t } = useTranslation();
  const [isAccept, setIsAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const registerClasses = useStyles(style)();
  const classes = useStyles(styles)();
  const [newResult, setNewResult] = useState('');

  const textField = (label, id, type, handleChange, onBlur, value, error, required = true, disable = false) => (
    <PerformantTextField
      label={label}
      id={id}
      required={required}
      name={id}
      type={type}
      value={value}
      onBlur={onBlur}
      error={error}
      onChange={handleChange}
      disabled={disable}
    />
  );

  const validationArray = [
    t('STUDENT_NAME_REQUIRED'),
    t('ACADEMIC_YEAR_REQUIRED'),
    t('ACTUAL_STATUS_REQUIRED'),
    t('COURSE_REQUIRED'),
    t('LOCATION_REQUIRED'),
    t('CHANGE_LOG_REQUIRED'),
    t('NEW_STATUS_REQUIRED'),
  ];

  const resultArray = [
    { id: 1, name: 'Pass' },
    { id: 2, name: 'Attended' },
    { id: 3, name: 'Repeat' },
  ];

  const repeatStatusValidations = Yup.object({
    studentName: Yup.string().required(validationArray[0]).typeError(validationArray[0]),
    academicYear: Yup.string().required(validationArray[1]).typeError(validationArray[1]),
    actualResult: Yup.string().required(validationArray[2]).typeError(validationArray[2]),
    newResult: Yup.string().required(validationArray[6]).typeError(validationArray[6]),
    course: Yup.string().required(validationArray[3]).typeError(validationArray[3]),
    location: Yup.string().required(validationArray[4]).typeError(validationArray[4]),
    changeLogs: Yup.string().required(validationArray[5]).typeError(validationArray[5]),
    isAccept: Yup.boolean().oneOf([true], 'Message'),
  });

  const dispatch = useDispatch();
  const validationSchema = repeatStatusValidations;

  const upperCaseFirstLetter = (string) => {
    const newString = string?.toString().toLowerCase();
    return newString?.charAt(0).toUpperCase() + newString?.slice(1);
  };

  const formik = useFormik({
    initialValues: {
      studentName: repeatStatusInfo?.studentName,
      academicYear: repeatStatusInfo?.acedemicYear,
      actualResult: upperCaseFirstLetter(repeatStatusInfo?.result),
      newResult: '',
      course: repeatStatusInfo?.courseFrom,
      location: repeatStatusInfo?.locationFrom,
      changeLogs: repeatStatusInfo?.changeLogs,
      isAccept: false,
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const payloadObj = {
        studentId: repeatStatusInfo?.studentId,
        newResult: newResult.toUpperCase(),
        actualResult: repeatStatusInfo?.result,
        changeLogs: values?.changeLogs,
        enrolledCourse: { course: { name: values?.course }, academicYear: values?.academicYear },
        userId: getLocalStorage('userId'),
        academicYear: values?.academicYear,
      };
      dispatch(updateStuRepeatStatus(payloadObj, setRepeatStatusOpen, setLoading, refreshList));
    },
  });

  const handleAcceptanceCriteria = () => {
    setIsAccept(!isAccept);
    formik.setFieldValue('isAccept', !isAccept);
  };

  useEffect(() => {
    setCustomForm(formik);
  }, [formik?.values]);

  const setPreviousFieldTouch = (key) => {
    const allFields = [
      'studentName',
      'academicYear',
      'actualResult',
      'newResult',
      'course',
      'location',
      'changeLogs',
    ];
    const index = allFields.indexOf(key);
    if (index > -1) {
      const obj = {};
      for (let i = 0; i <= index; i++) {
        const element = allFields[i];
        obj[element] = true;
      }
      formik.setTouched({ ...formik.touched, ...obj }, true);
    }
  };

  const getErrorText = (key, errorText) => (
    (formik.touched[key] && formik.errors[key]) ? (
      <span data-testid={key} className={classes.errorText}>
        {formik.errors[key]}
      </span>
    ) : errorText ? (
      <span className={classes.errorText}>{errorText}</span>
    ) : null
  );

  return (
    <Box>
      <FormikProvider value={formik}>
        <form
          name="tenantUserForm"
          noValidate
          autoComplete="off"
          className={`${registerClasses.form} ${classes.form}`}
        >
          <Grid container className={registerClasses.mainContainer}>
            <Grid xs={12} className={classes.previousYear}>
              {isCancelButtonDisable
                ? isForInactiveStudent
                  ? getErrorText('', t('INACTIVE_STUDENT_ENROLLMENTS'))
                  : getErrorText('', t('PREVIOUS_YEAR_ENROLLMENTS'))
                : null}
            </Grid>
            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid item xs={12} md={6} lg={6}>
                {textField(
                  t('STUDENT_NAME'),
                  'studentName',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('studentName'),
                  formik.values.studentName,
                  getErrorText('studentName'),
                  true,
                  true,
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                {textField(
                  t('ACADEMIC_YEAR'),
                  'academicYear',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('academicYear'),
                  formik.values.academicYear,
                  getErrorText('academicYear'),
                  true,
                  true,
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid item xs={12} md={6} lg={6}>
                {textField(
                  t('ACTUAL_RESULT'),
                  'actualResult',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('actualResult'),
                  formik.values.actualResult,
                  getErrorText('actualResult'),
                  true,
                  true,
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <PerformantDropdown
                  labelId={t('NEW_RESULT')}
                  label={`${t('NEW_RESULT')} `}
                  id="newResult"
                  name="newResult"
                  options={resultArray}
                  value={formik.values.newResult}
                  handleChange={(e) => {
                    setNewResult(resultArray[e.target.value - 1].name);
                  }}
                  onBlur={() => setPreviousFieldTouch('newResult')}
                  error={getErrorText('newResult')}
                  hasError={!!getErrorText('newResult')}
                  required
                  shrink
                />
                {getErrorText('newResult')}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid item xs={12} md={6} lg={6}>
                {textField(
                  t('LOCATION'),
                  'location',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('location'),
                  formik.values.location,
                  getErrorText('location'),
                  true,
                  true,
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                {textField(
                  t('COURSE'),
                  'course',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('course'),
                  formik.values.course,
                  getErrorText('course'),
                  true,
                  true,
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={[classes.changeLogs, classes.innerContainer]}>
            <Grid item xs={12} md={12} lg={12}>
              {textField(
                t('CHANGE_LOG'),
                'changeLogs',
                'text',
                formik.handleChange,
                () => setPreviousFieldTouch('changeLogs'),
                formik.values.changeLogs,
                getErrorText('changeLogs'),
                true,
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} className={classes.activeLabel}>
              <Checkbox
                label=""
                checked={formik.values.isAccept}
                handleChange={handleAcceptanceCriteria}
              />
              <p className={classes.activeLabelP}>{t('APPROVE_REPEAT')}</p>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>

      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
    </Box>
  );
}

export default RepeatStatus;
