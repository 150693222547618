import makeStyles from '@mui/styles/makeStyles';
import { colors, fonts } from '../../../theme';

const style = makeStyles((theme) => ({
  menuItem: {
    borderBottom: '0.1vw solid #eee !important',
    padding: '0.5vw !important',
    maxWidth: 500,
    minWidth: '100%',
    whiteSpace: 'break-spaces',
    justifyContent: 'start !important',
    display: 'block !important',
    fontFamily: fonts.fontType.roboto,
    fontSize: '0.9vw',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    '&:last-child': {
      borderBottom: 'none !important',
    },
  },
  defaultMenu: {
    fontSize: '0.9vw',
    padding: '0.7vw !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  errorText: {
    color: colors.errorText,
    fontSize: '0.9vw',
    bottom: -20,
    position: 'absolute',
    fontFamily: fonts.fontType.roboto,
    display: 'block',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  dropDownSelect: {
    '&::placeholder': {
      fontSize: '0.9vw',
    },
    width: '50%',
    '& img': {
      position: 'absolute',
      top: 9,
      left: 0,
      '& + .MuiInput-root': {
        '& .MuiSelect-select': {
          paddingLeft: 25,
        },
      },
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      '& .MuiSelect-select': {
        '@media (min-width: 1200px)': {
          padding: '0.7vw 1.7vw 0.7vw 0.7vw',
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0.1vw solid',
        borderColor: `${colors.primary}`,
      },
    },
  },
  dropDownSelectMobile: {
    '&::placeholder': {
      fontSize: '0.9vw',
    },
    width: '50%',
    '& img': {
      position: 'absolute',
      top: 9,
      left: 0,
      '& + .MuiInput-root': {
        '& .MuiSelect-select': {
          paddingLeft: 25,
        },
      },
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      '& .MuiSelect-select': {
        '@media (min-width: 1200px)': {
          padding: '7px 25px 10px 12px !important',
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0.1vw solid',
        borderColor: `${colors.primary}`,
      },
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: '12px',
      fontFamily: fonts.fontType.roboto,
      color: 'black !important',
    },
    '& .MuiInputLabel-root': {
      '&.MuiInputLabel-shrink': {
        color: colors.primary,
      },
    },
  },
  withLeftIcon: {
    '& .MuiInputLabel-root:not(Mui-focused)': {
      transform: 'translate(25px, 6px) scale(1)',
    },
  },
}));

export default style;
