import React, { useState, useEffect } from 'react';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';

function Chip(props) {
  const {
    handleCheck, tagIndex, listData, onDelete,
  } = props;
  const classes = useStyles(styles)();
  const [copyMessage, setCopyMessage] = useState('');

  const handleCopyText = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyMessage(`${type} copied!`);
      setTimeout(() => setCopyMessage(''), 1500);
    }).catch(() => {
      setCopyMessage('Failed to copy text: ');
    });
  };

  const handleDelete = (index) => {
    onDelete(index);
  };

  const handleCheckClick = (id) => {
    handleCheck(id);
  };

  useEffect(() => {}, [listData]);

  return (
    <div className="app">
      {listData?.map((opt, index) => (
        <div key={opt?.id}>
          {opt?.name && index === tagIndex ? (
            <div className={opt?.checked === true
              ? classes.checkListContainer
              : classes.nonPrimaryCheckListContainer}
            >
              <Tooltip title="Copy this name" placement="top">
                <span
                  className={classes.itemName}
                  onClick={() => handleCopyText(opt?.name, 'Name')}
                >
                  {opt?.name}
                </span>
              </Tooltip>
              <span>
                <span className={classes.crossIcon}>
                  <CloseIcon onClick={() => handleDelete(index)} />
                </span>
                {opt.checked === true ? (
                  <span className={classes.checkListIcon}>
                    <StarRateIcon onClick={() => handleCheckClick(opt?.id)} />
                  </span>
                ) : (
                  <span className={classes.checkListIcon}>
                    <StarOutlineIcon onClick={() => handleCheckClick(opt?.id)} />
                  </span>
                )}
              </span>
              <Tooltip title="Copy this email" placement="top">
                <p
                  className={classes.itemEmail}
                  onClick={() => handleCopyText(opt?.manabadiEmail, 'Email')}
                >
                  {opt?.manabadiEmail}
                </p>
              </Tooltip>
            </div>
          ) : null}
        </div>
      ))}
      {copyMessage && <div className={classes.copyMessage}>{copyMessage}</div>}
    </div>
  );
}

export default Chip;
