export const titleOptions = [
  {
    name: ' ', id: '',
  },
  {
    name: 'MR', id: 'Mr',
  },
  {
    name: 'MRS', id: 'Mrs',
  },
  {
    name: 'MISS', id: 'Miss',
  },
  {
    name: 'MS', id: 'Ms',
  },
];

export default titleOptions;
