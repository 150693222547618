/* eslint-disable */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';
import RegionTableCell from './region-table-cell';
import { GetSortOrder } from '../../../../components/atoms/tabel/utils';
import { Buttons } from '../../../../constant';
import ButtonAtom from '../../../../components/atoms/button';
import { getRegions } from '../../../../store/actions/getRegion';

export default function RegionManagerTable(props) {
  const {
    tableData,
    onEdit,
    pageNext,
    pagenum,
    setPagenum,
    totalRows,
    setLoading,
    totalActiveResources,
    isDeactivateChecked,
    totalInactiveResources,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const reduxTotalCount = useSelector((state) => state?.getRegion?.totalRegionCount);
  const dispatch = useDispatch();
  const counter = (isDeactivateChecked === true) ? reduxTotalCount : totalActiveResources;

  const getRow = (row) => {
    row?.region_courses?.forEach((res) => {
      (res.level = res?.course?.level);
    });
    row?.region_courses?.sort(GetSortOrder('level'));
    return row;
  };

  const loadMoreItems = (length) => {
    let page = '';
    if (length < reduxTotalCount) {
      page = pagenum + 1;
      setPagenum(page);
    }
    dispatch(getRegions(setLoading, page, 100, pageNext));
  };

  return (
    <Grid>
      <TableContainer>
        <Table sx={12} aria-label="simple table">
          <TableHead className={classes.regionManagerTable}>
            <TableRow>
              <TableCell align="left" width="15%">{t('REGION')}</TableCell>
              <TableCell align="left" width="15%">{t('GEO_REGION')}</TableCell>
              <TableCell align="left" width="10%">{t('COUNTRY')}</TableCell>
              <TableCell align="left" width="22%">{t('CO_ORDINATOR')}</TableCell>
              <TableCell align="left" width="9%">{t('STATUS')}</TableCell>
              <TableCell align="left" width="14%">{t('COURSES')}</TableCell>
              <TableCell align="center" width="15%">{t('ACTIONS')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.regionManagerTable}>
            {tableData?.map((row) => (
              <TableRow
                key={row?.name}
                sx={{
                  '&:last-child td, &:last-child th': { borderBottom: 0 },
                }}
              >
                <TableCell align="left" component="th" scope="row" width="15%">
                  {row?.name}
                </TableCell>
                <TableCell align="left" component="th" scope="row" width="15%">
                  {row?.geoRegion}
                </TableCell>
                <TableCell align="left" width="10%">{`${row?.country}`}</TableCell>
                <TableCell align="left" width="22%">
                  {row?.region_coordinators?.map((ele, i) => (`${ele?.user?.first_name} ${ele.user?.last_name}${i !== row.region_coordinators.length - 1 ? ', ' : ''}`))}
                </TableCell>
                <TableCell align="left" width="9%">{row?.isActive ? 'Active' : 'Inactive'}</TableCell>
                <RegionTableCell
                  rowData={getRow(row)}
                  onEdit={onEdit}
                  upButtonClass={classes.upbutton}
                />
              </TableRow>
            ))}
          </TableBody>
          {' '}
        </Table>
      </TableContainer>
      {(reduxTotalCount > 0 && totalRows > 0)
      && (
        <Grid container justifyContent="flex-end">

          <Grid className={classes.loadMoreItems}>
            <Grid xs={12} className={classes.totalRowCount}>
              <span>
                {t('TOTAL_REGIONS')}
                {' '}
                {totalRows}
                {' '}
                of
                {' '}
                {reduxTotalCount}
              </span>
            </Grid>
            {(counter !== (totalActiveResources + totalInactiveResources) && (reduxTotalCount !== (totalActiveResources + totalInactiveResources))) 
              && (totalRows !== reduxTotalCount ? (
                <Grid justifyContent="flex-end" className={classes.loadMore}>
                  <ButtonAtom
                    btntype={Buttons.PRIMARY}
                    onClick={() => loadMoreItems(totalRows)}
                    name={t('LOAD_MORE')}
                  />
                </Grid>
              ) : (                
                <Grid justifyContent="flex-end" className={classes.loadMore}>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
